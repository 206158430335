<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="mappedItems"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Campaign List</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <v-btn
            v-if="hasPermission('create lead::campaigns')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Campaign
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Campaign"
            permission="create lead::campaigns"
            :x-small="false"
            button-class="mb-2"
          />

          <CampaignFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            :lead-providers="leadProviders"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.biddable_cost="{ item }">
      {{ item.biddable_cost ? 'Yes' : 'No' }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('edit lead::campaigns')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="edit lead::campaigns"
        :x-small="false"
        button-class="mr-10"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import CampaignFormModal from './CampaignFormModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    CampaignFormModal,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Partner', value: 'company_name' },
      { text: 'Campaign Name', value: 'campaign_name' },
      { text: 'Cost', value: 'cost' },
      { text: 'Biddable Cost', value: 'biddable_cost' },
      { text: 'Product Type', value: 'product_type' },
      { text: 'Enabled', value: 'enabled' },
      { text: 'Onfido Enabled', value: 'onfido_enabled' },
      { text: 'Prove Enabled', value: 'prove_enabled' },
      { text: 'Docusign ID Check', value: 'docusign_id_check' },
      { text: 'Skip Landing Page', value: 'skip_landing_page' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'start',
        width: 183,
      },
    ],

    dialogDelete: false,
    dialogForm: false,
    editedItem: {
      id: null,
    },
    leadProviders: [],
  }),

  computed: {
    mappedItems() {
      return (this.items || []).map((item) => ({
        ...item,
        enabled: !!item.enabled,
        docusign_id_check: !!item.docusign_id_check,
        onfido_enabled: !!item.onfido_enabled,
        prove_enabled: !!item.prove_enabled,
        skip_landing_page: !!item.skip_landing_page,
      }));
    },
  },

  created() {
    this.$axios
      .get('/api/v1/lead-provider', {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        const { data } = res.data;

        this.leadProviders = data.map((item) => ({
          value: item.id,
          text: item.company_name,
        }));
      });
  },

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemDeleted() {
      this.items = [];
      this.updateTable();
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/campaign', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
