<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col>
        <h1>TBot Loan Generator</h1>
        <p>Fill out the form sections below to generate a new test lead.</p>
      </v-col>
      <v-col
        v-if="!dataLoading && !showError"
        class="d-flex align-end justify-end"
      >
        <v-btn
          class="blue darken-1 mb-4"
          dark
          :loading="formLoading"
          @click="submitLead"
        >
          Submit Lead
        </v-btn>
      </v-col>
    </v-row>

    <template
      v-if="dataLoading"
    >
      <p>Loading data</p>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </template>

    <v-row
      v-else
    >
      <v-col
        v-if="!showError"
      >
        <v-stepper
          v-model="step"
          non-linear
        >
          <v-stepper-header>
            <v-stepper-step
              editable
              step="1"
            >
              Lead Data
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              step="1"
            >
              <WaterfallTestLeadDataForm
                :lead-data="leadData"
                :campaigns="campaigns"
                :lead-providers="leadProviders"
                :us-states="usStates"
                :customers="customers"
                :is-return-campaign="isReturnCampaign"
                :loan-statuses="loanStatuses"
                :loan-sub-statuses="loanSubStatuses"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>

      <v-col
        v-if="showError"
        cols="12"
      >
        <v-alert
          type="error"
          dark
          border="left"
        >
          Failed to retrieve data needed to create lead.
          <ul>
            <li
              v-for="(msg, index) in errorMessages"
              :key="index"
            >
              {{ msg }}
            </li>
          </ul>
        </v-alert>
      </v-col>

      <v-dialog
        v-model="showModal"
      >
        <v-card
          flat
          class="pa-2"
        >
          <v-card-title>Result</v-card-title>
          <v-card-text>
            <v-alert
              v-show="formError"
              type="error"
              dark
              border="left"
            >
              {{ formError }}
            </v-alert>

            <vue-json-pretty
              :data="apiResponse"
              :show-line="false"
              :show-double-quotes="false"
              virtual
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import { mapActions, mapGetters } from 'vuex';
import WaterfallTestLeadDataForm from '@/views/Tickets/TestSuite/WaterfallTestLeadDataForm.vue';

import stateList from '@/data/states.json';

export default {
  components: {
    WaterfallTestLeadDataForm,
    VueJsonPretty,
  },
  data() {
    return {
      dataLoading: true,
      showError: false,
      errorMessages: [],
      customers: [],
      campaigns: [],
      leadProviders: [],
      usStates: [],
      lastpaydate: '',
      nextpaydate: '',
      secondpaydate: '',
      step: 1,
      formLoading: false,
      showModal: false,
      apiResponse: {},
      formError: '',
      loanStatuses: [],
      loanSubStatuses: [],
    };
  },
  computed: {
    isReturnCampaign() {
      const campaign = this.campaigns.find((camp) => camp.value === this.leadData.campaign);
      if (campaign && campaign.text.includes('Return')) {
        if (this.customers.length > 0) {
          this.updateLeadData({
            return_customer: 1,
            firstname: this.customers[0].firstname,
            lastname: this.customers[0].lastname,
            email: this.customers[0].email,
            customer_guid: this.customers[0].value,
            homephone: this.customers[0].homephone,
          });
        }
        return true;
      }
      this.updateLeadData({
        return_customer: 0,
        customer_guid: null,
      });
      return false;
    },
    ...mapGetters('testSuite', ['leadData']),
  },
  async created() {
    this.usStates = stateList.map((state) => state.id);
    this.$axios
      .get('/api/v1/waterfall/test')
      .then((res) => {
        const { data } = res;
        this.customers = data.customers.map((customer) => ({
          value: customer.guid,
          text: `${customer.firstname} ${customer.lastname}`,
          firstname: customer.firstname,
          lastname: customer.lastname,
          email: customer.email,
          homephone: customer.homephone,
        }));

        this.campaigns = data.campaigns.map((campaign) => ({
          value: campaign.id,
          text: `${campaign.id} (${campaign.campaign_name} - ${campaign.product_type})`,
        }));

        if (this.campaigns.length === 0) {
          this.errorMessages.push('No Campaigns found.');
        } else {
          this.leadData.campaign = this.campaigns[0].value;
        }

        this.leadProviders = data.lead_providers.map((provider) => ({
          value: provider.api_key,
          text: `${provider.api_key} (${provider.category})`,
        }));

        if (this.leadProviders.length === 0) {
          this.errorMessages.push('No Lead Providers found.');
        } else {
          this.leadData.api_key = this.leadProviders[0].value;
        }

        if (this.errorMessages.length > 0) {
          this.showError = true;
        }

        this.dataLoading = false;

        this.leadData.lastpaydate = data.last_pay_date;
        this.leadData.nextpaydate = data.next_pay_date;
        this.leadData.secondpaydate = data.second_pay_date;

        this.leadData.address = data.address;
        this.leadData.homephone = data.homephone;
        this.leadData.ssn = data.ssn;
        this.leadData.accountnumber = data.accountnumber;

        // populate loan statuses
        this.loanStatuses = data.loan_statuses;
        this.loanSubStatuses = data.loan_sub_statuses;

        // update vuex store
        this.setLeadData(this.leadData);
      });
  },
  methods: {
    ...mapActions('testSuite', ['setLeadData', 'updateLeadData']),
    ...mapActions('notification', ['setNotification']),

    submitLead() {
      this.formLoading = true;
      this.formError = '';
      if (this.leadData.return_customer === 1 && this.leadData.customer_guid) {
        // make sure email and phone match to get past fraud check
        const activeCustomer = this.customers.find(
          (customer) => customer.value === this.leadData.customer_guid,
        );
        this.leadData.email = activeCustomer.email;
        this.leadData.homephone = activeCustomer.homephone;
      }

      if (this.leadData.initialAmount > this.leadData.requestamount) {
        this.setNotification({
          message: 'The initial amount is incorrect, must be less than request amount',
          color: 'red',
        });
        this.formLoading = false;
        return;
      }

      this.$axios
        .post('api/v1/support/make-test-suite', this.leadData)
        .then((res) => {
          this.apiResponse = res.data;
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.apiResponse = error.response.data;
            this.formError = 'Please fix the following errors';
          } else {
            this.setNotification({
              message: 'There was an error processing the lead, please try again',
              color: 'red',
            });
            console.error(error);
          }
        })
        .then(() => {
          this.showModal = true;
          this.formLoading = false;
        });
    },
  },
};
</script>

<style></style>
