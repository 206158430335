<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :server-items-length="totalFiles"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar
          class="mb-5"
          flat
        >
          <v-toolbar-title>
            FinCen Files
          </v-toolbar-title>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5 btn-bg-deep-blue"
                dark
                small
                tile
                v-bind="attrs"
                v-on="on"
              >
                Filter
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'fincen-files' }"
                exact
              >
                <v-list-item-title>all</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-files', query: { folder: 'new' } }"
                exact
              >
                <v-list-item-title>new</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-files', query: { folder: 'processing' } }"
                exact
              >
                <v-list-item-title>processing</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-files', query: { folder: 'complete' } }"
                exact
              >
                <v-list-item-title>complete</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-files', query: { folder: 'error' } }"
                exact
              >
                <v-list-item-title>error</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer />
          <v-file-input
            v-model="fincenFile"
            class="mt-7 pt-4"
            show-size
            single-line
            dense
            truncate-length="10"
            label="Select File to Upload"
          />
          <v-btn
            class="ml-3 pa-5"
            color="primary"
            dark
            small
            tile
            :disabled="isFileUploadDisabled"
            @click="uploadFile()"
          >
            Upload<br>Selected
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text
        v-if="mostRecentFile != null"
        :class="mostRecentFileMessageClass"
      >
        most recent upload: {{ mostRecentFile.original_name }}
        ({{ formatDateTime(mostRecentFile.last_modified) }}) | status: {{ mostRecentFile.folder }}
        <span v-if="mostRecentFile.folder === 'error' && mostRecentFile.last_error != null">
          <br>{{ mostRecentFile.last_error.description }}
        </span>
      </v-card-text>
    </template>

    <template v-slot:item.folder="{ item }">
      <template v-if="item.folder === 'error'">
        <v-dialog width="70%">
          <template v-slot:activator="{ on }">
            <v-badge
              v-if="item.folder === 'error' && item.last_error != null"
              color="error"
              inline
              dot
            >
              <span
                style="cursor: pointer"
                class="mt-1 mb-1 mr-1"
                v-on="on"
              >
                {{ item.folder }}
              </span>
            </v-badge>
            <span v-else>{{ item.folder }}</span>
          </template>
          <v-card>
            <v-tooltip>
              <template v-slot:activator="{ on }">
                <v-card-title>
                  error: {{ item.original_name }} | {{ item.uploaded_name }}
                </v-card-title>
                <v-card-text
                  class="d-flex justify-center mt-5 text-h6"
                  v-on="on"
                >
                  {{ item.last_error.description }}
                </v-card-text>
              </template>
            </v-tooltip>
          </v-card>
        </v-dialog>
      </template>
      <template v-else>
        <span>
          {{ item.folder }}
        </span>
      </template>
    </template>

    <template v-slot:item.last_modified="{ item }">
      {{ formatDateTime(item.last_modified) }}
    </template>

    <template v-slot:item.size="{ item }">
      {{ formatFileSizeKB(item.size) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        v-if="item.batch != null"
        class="mt-1 mb-1 mr-1"
        color="primary"
        dark
        small
        tile
        :to="{ name: 'fincen-persons', query: { batchId: item.batch.id } }"
      >
        Batch
      </v-btn>
      <v-btn
        class="mt-1 mb-1"
        color="primary"
        dark
        small
        tile
        @click="downloadFile(item.id)"
      >
        Download
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  name: 'FincenFilesIndex',
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    headers: [
      { text: 'Status', value: 'folder' },
      { text: 'Original File Name', value: 'original_name', sortable: false },
      { text: 'Uploaded File Name', value: 'uploaded_name' },
      { text: 'Last Modified', value: 'last_modified' },
      { text: 'File Size', value: 'size' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    folder: 'all',
    totalFiles: 0,
    fincenFile: null,
    sortBy: 'last_modified',
    sortDesc: true,
    mostRecentFile: null,
  }),
  computed: {
    isFileUploadDisabled() {
      return this.fincenFile === null;
    },
    mostRecentFileMessageClass() {
      if (this.mostRecentFile === null) {
        return '';
      }
      const { folder } = this.mostRecentFile;
      if (folder === 'error') {
        return 'pa-5 text-center text-xl-h5 error';
      }
      if (folder === 'processing') {
        return 'pa-5 text-center text-xl-h5 warning';
      }
      if (folder === 'new') {
        return 'pa-5 text-center text-xl-h5 info';
      }
      return 'pa-5 text-center text-xl-h5 success';
    },
  },
  watch: {
    '$route.query': {
      handler: 'updateTable',
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async uploadFile() {
      if (this.fincenFile === null) {
        console.log('No upload file detected.');
        return false;
      }
      this.loading = true;
      const formData = new FormData();

      // max filesize for upload in MB
      const maxFilesizeMB = 1;

      if (this.fincenFile.size > (maxFilesizeMB * 1000000)) {
        const errMsg = 'The file you are trying to upload is too large. Please upload a file smaller than '
          + `${maxFilesizeMB} MB.<br /><br /><b>NOTE:</b> If you need to upload a larger file, please break `
          + `it down into several smaller files that are each smaller than ${maxFilesizeMB} MB in size.`;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
        this.loading = false;
        return false;
      }

      formData.append('fincenFile', this.fincenFile);
      try {
        await this.$axios.post('api/v1/fincen-file/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

        const successMsg = 'The file upload was successful. Please check back periodically for potential '
          + 'matches.<br /><br /><b>NOTE:</b> only one file can be processed at a time. Subsequent attempts '
          + 'to upload a new file while one is already queued or processing will result in an error.';

        this.options.sortBy = ['id'];
        this.options.sortDesc = [true];
        this.updateTable();

        this.setNotification({
          message: successMsg,
          color: 'green',
        });
      } catch (err) {
        console.log(err);
        const errMsg = err.response.data.error
          ? err.response.data.error
          : 'Something went wrong, unable to upload FinCen File. Please contact your admin.';
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
        this.loading = false;
      }

      return true;
    },
    async downloadFile(fileId) {
      this.loading = true;
      try {
        await this.$axios
          .get(`api/v1/fincen-file/download?fileId=${fileId}`)
          .then((res) => {
            const blob = new Blob([res.data.fileContent], { type: 'text/csv' });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = res.data.fileName;
            link.click();

            link.remove();
          });
      } catch (err) {
        const errMsg = err.response.data.error
          ? err.response.data.error
          : 'Something went wrong, unable to download FinCen File. Please contact your admin.';
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }

      this.loading = false;
      return true;
    },
    updateTable() {
      this.loading = true;

      if (this.$route.query.folder === 'all') {
        this.folder = '';
      } else {
        this.folder = this.$route.query.folder;
      }

      return this.$axios
        .get('api/v1/fincen-file', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            folder: this.folder,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.files.data;
          this.totalFiles = res.data.files.total;
          this.mostRecentFile = res.data.mostRecentFile;
        });
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    },
    formatFileSizeKB(bytes) {
      return (`${(bytes / 1024).toFixed(2)} kB`);
    },
  },
};
</script>
