import { render, staticRenderFns } from "./OpsTicketDialogComments.vue?vue&type=template&id=47a02764&scoped=true&"
import script from "./OpsTicketDialogComments.vue?vue&type=script&lang=js&"
export * from "./OpsTicketDialogComments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a02764",
  null
  
)

export default component.exports