<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>OFAC Email Notification Settings</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <v-btn
            v-if="hasPermission('create ofac::email-notification')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            Add Email
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="Add Email"
            permission="create ofac::email-notification"
            :x-small="false"
            button-class="mb-2"
          />

          <OfacEmailNotificationSettingsStatusModal
            v-model="dialogStatus"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogStatus')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <OfacEmailNotificationDeleteModal
            v-model="dialogDelete"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogDelete')"
            @item-deleted="itemSaved()"
            @click:outside="clearState()"
          />

          <OfacEmailNotificationFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            :available-users="availableUsers"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.active="{ item }">
      {{ item.active === 0 ? 'Inactive' : 'Active' }}
    </template>

    <template v-slot:item.actions="{ item }">
      <div class="d-flex justify-space-between">
        <v-chip
          v-if="hasPermission('edit ofac::email-notification')"
          small
          :color="`${item.active ? 'red' : 'green'}`"
          text-color="white"
          class="mr-2"
          @click="toggleStatus(item)"
        >
          {{ item.active ? 'Deactivate' : 'Activate' }}
        </v-chip>
        <disabled-for-lack-of-role-btn
          v-else
          :text="item.active ? 'Deactivate' : 'Activate'"
          permission="edit ofac::email-notification"
          button-class="mt-2 mb-2 mr-4"
        />

        <div class="d-flex justify-space-around">
          <v-icon
            v-if="hasPermission('edit ofac::email-notification')"
            :id="item"
            class="ml-2 mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <disabled-for-lack-of-role-btn
            v-else
            text=""
            icon="mdi-pencil"
            :text-button="true"
            permission="edit ofac::email-notification"
            button-class="mt-2 mb-2"
          />
          <v-icon
            v-if="hasPermission('edit ofac::email-notification')"
            class="red--text ml-2 mr-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <disabled-for-lack-of-role-btn
            v-else
            text=""
            icon="mdi-delete"
            :text-button="true"
            permission="edit ofac::email-notification"
            button-class="mt-2 mb-2"
          />
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import OfacEmailNotificationSettingsStatusModal from '@/views/OfacMonitoring/OfacEmailNotificationSettings/OfacEmailNotificationSettingsStatusModal.vue';
import OfacEmailNotificationDeleteModal from '@/views/OfacMonitoring/OfacEmailNotificationSettings/OfacEmailNotificationDeleteModal.vue';
import OfacEmailNotificationFormModal from '@/views/OfacMonitoring/OfacEmailNotificationSettings/OfacEmailNotificationFormModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'OfacMonitoringEmailNotificationSettingsIndex',

  components: {
    OfacEmailNotificationSettingsStatusModal,
    OfacEmailNotificationDeleteModal,
    OfacEmailNotificationFormModal,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'User', value: 'user_name' },
      { text: 'Email Address', value: 'email' },
      { text: 'Status', value: 'active' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: 183,
      },
    ],
    dialogStatus: false,
    dialogDelete: false,
    dialogForm: false,
    dialogEmailForm: false,
    editedItem: {
      id: null,
    },
    availableUsers: [],
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemDeleted() {
      this.items = [];
      this.updateTable();
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    toggleStatus(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogStatus = true;
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/ofac-email', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;

          this.availableUsers = res.data.availableUsers.map((item) => ({
            text: `${item.first_name} ${item.last_name}`,
            value: item.id,
          }));
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
