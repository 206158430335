<template>
  <div>
    <v-container fuild>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Customer FullStory
            </v-card-title>
            <div
              v-if="loadingSessions"
            >
              <Loader />
            </div>
            <div
              v-else
            >
              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="blue"
              >
                <v-tab
                  v-for="itemTab in itemTabs"
                  :key="itemTab.id"
                >
                  {{ itemTab.name }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card>
                    <LoanProCustomerFSLeads
                      :sessions="sessions"
                    />
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <LoanProCustomerFSEmail
                        :sessions="emailSessions"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Loader from '@/components/ui/Loader.vue';
import LoanProCustomerFSLeads from './LoanProCustomerFSLeads.vue';
import LoanProCustomerFSEmail from './LoanProCustomerFSEmail.vue';

export default {
  name: 'LoanProCustomerFullStory',
  components: {
    Loader,
    LoanProCustomerFSLeads,
    LoanProCustomerFSEmail,
  },
  data() {
    return {
      sessions: [],
      emailSessions: [],
      loadingSessions: false,
      sessionSelected: {},
      tab: null,
      itemTabs: [
        {
          id: 0,
          name: 'By Lead',
        },
        {
          id: 1,
          name: 'By Email',
        },
      ],
    };
  },
  mounted() {
    this.getCustomerSessions();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async getCustomerSessions() {
      this.loadingSessions = true;
      try {
        const response = await this.$axios.get(`api/v1/loanpro-customer/${this.$route.params.customer_id}/fullstory`);

        if (response.status === 200 && response.data) {
          this.sessions = response.data.sessionsByLeads;
          this.emailSessions = response.data.sessionsByEmail;
          this.loadingSessions = false;
        } else {
          this.loadingSessions = false;
          this.showNotification(
            `Oops something happens, please refresh and try again ${response.data.message}`,
            'red',
          );
        }
      } catch (err) {
        this.showNotification(
          `Oops something happens, please refresh and try again ${err}`,
          'red',
        );
      }
    },
    showNotification(notificationMessage, notificationColor) {
      this.setNotification({
        message: notificationMessage,
        color: notificationColor,
      });
    },
    openFullStoryModal(session) {
      this.sessionSelected = session;
      this.showFullStoryModal = true;
    },
    closeFullStoryModal() {
      this.sessionSelected = {};
      this.showFullStoryModal = false;
    },
  },
};
</script>

<style scoped>

</style>
