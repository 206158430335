<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(menu, index) in menus">
        <v-tab
          v-if="hasPermission(menu.permission)"
          :key="index"
          :to="{ name: menu.route }"
        >
          {{ menu.label }}
        </v-tab>
        <v-tab
          v-else
          :key="index"
        >
          <disabled-for-lack-of-role-btn
            :text="menu.label"
            :text-button="true"
            :permission="menu.permission"
            :x-small="false"
          />
        </v-tab>
      </template>
    </v-tabs>
    <v-card class="mt-10 transparent">
      <router-view />
    </v-card>
  </v-container>
</template>

<script>
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'OfacMonitoringIndex',

  components: {
    DisabledForLackOfRoleBtn,
  },

  data() {
    return {
      menus: [
        {
          label: 'Review All Outstanding Matches',
          route: 'ofac-matches',
          permission: 'view ofac::matches-list',
        },
        {
          label: 'Report List',
          route: 'ofac-reports',
          permission: 'view ofac::report-list',
        },
        {
          label: 'Email Notification Settings',
          route: 'email-notifications-settings',
          permission: 'view ofac::email-notification-settings',
        },
      ],
    };
  },
};
</script>
