<template>
  <v-card-text>
    <div>
      <v-alert
        v-if="!sessions.length"
        color="orange lighten"
        dark
      >
        This customer does not have any Leads with FullStories associated!
      </v-alert>
      <v-simple-table
        v-else
        fixed-header
      >
        <thead>
          <tr>
            <th class="text-left">
              Lead Guid
            </th>
            <th class="text-right">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(session, i) in sessions"
            :key="i"
          >
            <td>{{ session.lead.lead_guid }}</td>
            <td
              class="text-right"
            >
              <v-btn
                class="btn-bg-deep-blue"
                @click="openFullStoryModal(session)"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-eye
                </v-icon>
                View Sessions
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <LoanProCustomerFullStoryModal
      v-model="showFullStoryModal"
      :sessions="sessionSelected.sessions"
      :lead="sessionSelected.lead"
      @close-modal="closeFullStoryModal()"
    />
  </v-card-text>
</template>

<script>
import LoanProCustomerFullStoryModal from './LoanProCustomerFullStoryModal.vue';

export default {
  name: 'LoanProCustomerFSLeads',

  components: {
    LoanProCustomerFullStoryModal,
  },

  props: {
    sessions: {
      type: Array,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      showFullStoryModal: false,
      sessionSelected: {},
    };
  },

  methods: {
    openFullStoryModal(session) {
      this.sessionSelected = session;
      this.showFullStoryModal = true;
    },
    closeFullStoryModal() {
      this.sessionSelected = {};
      this.showFullStoryModal = false;
    },
  },
};
</script>

<style scoped>

</style>
