<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-if="hasPermission('view prescreen::prospect')"
          v-bind="attrs"
          v-on="on"
        >
          mdi-information-outline
        </v-icon>
        <disabled-for-lack-of-role-btn
          v-else
          text=""
          icon="mdi-information-outline"
          :text-button="true"
          permission="view prescreen::prospect"
          :x-small="false"
          button-class="mr-10"
        />
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Prospect More Details
        </v-card-title>

        <v-card-text class="pa-5">
          <json-formatter-wrapper :data="prospect" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DisabledForLackOfRoleBtn from '../../../DisabledForLackOfRoleBtn.vue';
import JsonFormatterWrapper from '../../JsonFormatterWrapper.vue';

export default {
  components: {
    JsonFormatterWrapper,
    DisabledForLackOfRoleBtn,
  },
  props: {
    prospect: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
