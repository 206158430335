<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :server-items-length="totalBatches"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>
            FinCen Batches
          </v-toolbar-title>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5 btn-bg-deep-blue"
                dark
                small
                tile
                v-bind="attrs"
                v-on="on"
              >
                Filter
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'fincen-batches' }"
                exact
              >
                <v-list-item-title>all</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-batches', query: { status: 'new' } }"
                exact
              >
                <v-list-item-title>new</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-batches', query: { status: 'viewed' } }"
                exact
              >
                <v-list-item-title>viewed</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-batches', query: { status: 'processing' } }"
                exact
              >
                <v-list-item-title>processing</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-batches', query: { status: 'complete' } }"
                exact
              >
                <v-list-item-title>complete</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'fincen-batches', query: { status: 'error' } }"
                exact
              >
                <v-list-item-title>error</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
    </template>
    <template v-slot:item.timestamp="{ item }">
      {{ formatDateTime(item.timestamp) }}
    </template>

    <template v-slot:item.status="{ item }">
      <template v-if="item.status === 'error'">
        <v-dialog width="70%">
          <template v-slot:activator="{ on }">
            <v-badge
              v-if="item.status === 'error' && item.last_error != null"
              color="error"
              inline
              dot
            >
              <span
                style="cursor: pointer"
                class="mt-1 mb-1 mr-1"
                v-on="on"
              >
                {{ item.status }}
              </span>
            </v-badge>
            <span v-else>{{ item.status }}</span>
          </template>
          <v-card>
            <v-tooltip>
              <template v-slot:activator="{ on }">
                <v-card-title>
                  error: batch number {{ item.batch_num }} | {{ formatDateTime(item.timestamp) }}
                </v-card-title>
                <v-card-text
                  class="d-flex justify-center mt-5 text-h6"
                  v-on="on"
                >
                  {{ item.last_error.description }}
                </v-card-text>
              </template>
            </v-tooltip>
          </v-card>
        </v-dialog>
      </template>
      <template v-else>
        <span>
          {{ item.status }}
        </span>
      </template>
    </template>

    <template
      v-slot:item.actions="{ item }"
    >
      <v-btn
        v-if="item.persons.length > 0"
        color="primary"
        dark
        small
        tile
        :to="{ name: 'fincen-persons', query: { batchId: item.id } }"
        f
      >
        Review Matches
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  name: 'FincenBatchesIndex',
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'File ID', value: 'file_id' },
      { text: 'Batch Number', value: 'batch_num' },
      { text: 'Batch Date/Time', value: 'timestamp' },
      { text: 'Status', value: 'status' },
      { text: 'Persons', value: 'persons.length' },
      { text: 'Processed', value: 'processed_count' },
      { text: 'Invalid', value: 'error_count' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    totalBatches: 0,
    sortBy: 'id',
    sortDesc: true,
  }),
  watch: {
    '$route.query': {
      handler: 'updateTable',
    },
  },
  methods: {
    updateTable() {
      this.loading = true;
      return this.$axios
        .get('api/v1/fincen-batch', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            status: this.$route.query.status,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.data.length === 0) {
            return;
          }
          this.items = res.data.data;
          this.totalBatches = res.data.total;
        });
    },
    formatDateTime(timestamp) {
      if (timestamp === null) {
        return '';
      }
      return DateTime.fromSeconds(timestamp).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    },
  },
};
</script>
