<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="companyName"
                  required
                  label="Company Name"
                  :error-messages="companyNameErrors"
                  @input="$v.companyName.$touch()"
                  @blur="$v.companyName.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="category"
                  :items="categories"
                  label="Category"
                  :error-messages="categoryErrors"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="providerType"
                  :items="providerTypes"
                  label="Provider Type"
                  :error-messages="providerTypeErrors"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  required
                  label="First Name"
                  :error-messages="firstNameErrors"
                  @input="$v.firstName.$touch()"
                  @blur="$v.firstName.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  required
                  label="Last Name"
                  :error-messages="lastNameErrors"
                  @input="$v.lastName.$touch()"
                  @blur="$v.lastName.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="username"
                  required
                  label="Username"
                  :error-messages="usernameErrors"
                  @input="$v.username.$touch()"
                  @blur="$v.username.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  required
                  label="Email"
                  :error-messages="emailErrors"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="password"
                  required
                  label="Password"
                  :error-messages="passwordErrors"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="passwordConfirm"
                  required
                  label="Confirm Password"
                  :error-messages="passwordConfirmErrors"
                  @input="$v.passwordConfirm.$touch()"
                  @blur="$v.passwordConfirm.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="apiKey"
                  label="Api Key"
                  required
                  disabled
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          category: '',
          provider_type: '',
          company_name: '',
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          password: '',
          api_key: '',
        };
      },
    },
  },
  data() {
    return {
      categories: ['Walkup', 'Return', 'Purchased', 'Prescreen'],
      providerTypes: ['Direct', 'Lead'],
      id: null,
      companyName: '',
      category: '',
      providerType: '',
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
      apiKey: this.editedItem.api_key || uuidv4(),
      status: null,
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return this.isNewItem ? 'Add New Lead Provider' : 'Edit Lead Provider';
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    categoryErrors() {
      const errors = [];

      if (!this.$v.category.$dirty) {
        return errors;
      }
      if (!this.$v.category.required) {
        errors.push('Category is required.');
      }
      return errors;
    },
    providerTypeErrors() {
      const errors = [];

      if (!this.$v.providerType.$dirty) {
        return errors;
      }
      if (!this.$v.providerType.required) {
        errors.push('Provider type is required.');
      }
      return errors;
    },
    companyNameErrors() {
      const errors = [];

      if (!this.$v.companyName.$dirty) {
        return errors;
      }
      if (!this.$v.companyName.required) {
        errors.push('Company name is required.');
      }
      return errors;
    },
    firstNameErrors() {
      const errors = [];

      if (!this.$v.firstName.$dirty) {
        return errors;
      }
      if (!this.$v.firstName.required) {
        errors.push('First name is required.');
      }
      return errors;
    },
    lastNameErrors() {
      const errors = [];

      if (!this.$v.lastName.$dirty) {
        return errors;
      }
      if (!this.$v.lastName.required) {
        errors.push('Last name is required.');
      }
      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.required) {
        errors.push('Email is required.');
      }
      if (!this.$v.email.email) {
        errors.push('Must be a valid email.');
      }
      return errors;
    },
    usernameErrors() {
      const errors = [];

      if (!this.$v.username.$dirty) {
        return errors;
      }
      if (!this.$v.username.required) {
        errors.push('Username is required.');
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.required) {
        errors.push('Password is required.');
      }
      if (!this.$v.password.minLength) {
        errors.push(`Password must have at least ${this.$v.password.$params.minLength.min} characters.`);
      }
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];

      if (!this.$v.passwordConfirm.$dirty) {
        return errors;
      }
      if (!this.$v.passwordConfirm.sameAsPassword) {
        errors.push('Passwords must match.');
      }
      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.category = this.editedItem.category;
      this.providerType = this.editedItem.provider_type;
      this.companyName = this.editedItem.company_name;
      this.firstName = this.editedItem.first_name;
      this.lastName = this.editedItem.last_name;
      this.username = this.editedItem.username;
      this.email = this.editedItem.email;
      this.password = this.editedItem.password;
      this.passwordConfirm = this.editedItem.password;
      this.apiKey = this.editedItem.api_key || uuidv4();
      this.status = this.editedItem.status;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;

        let request = '';

        let data = {
          status: this.status,
          category: this.category,
          providerType: this.providerType,
          companyName: this.companyName,
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirm,
        };

        if (this.isNewItem) {
          data = {
            ...data,
            status: 1,
            apiKey: this.apiKey,
          };

          request = this.$axios.post('/api/v1/lead-provider', data);
        } else {
          request = this.$axios.put(`/api/v1/lead-provider/${this.editedItem.id}`, data);
        }

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.company_name} has been saved.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');
    },
  },
  validations: {
    companyName: { required },
    category: { required },
    providerType: { required },
    firstName: { required },
    lastName: { required },
    username: { required },
    email: { email, required },
    password: { minLength: minLength(5), required },
    passwordConfirm: {
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>

<style></style>
