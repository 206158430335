<template>
  <v-dialog
    v-if="leadId > 0"
    value="true"
    max-width="1200"
    scrollable
    @input="$emit('update:leadId', null)"
  >
    <v-card max-height="85vh">
      <v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title>Details for lead: {{ leadId }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="$emit('update:leadId', null)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="isLoading">
        <div class="text-center ma-15">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>

      <template v-else>
        <v-tabs v-model="tabs">
          <v-tab>Submitted Lead</v-tab>
          <v-tab>Waterfall Results</v-tab>
          <v-tab>FullStory sessions</v-tab>
          <v-tab>Loanpro Tracking</v-tab>
          <v-tab>Prove Trackings</v-tab>
        </v-tabs>

        <v-divider />

        <v-card-text>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-card
                flat
                class="pa-5"
              >
                <JsonFormatterWrapper :data="leadDetails.submittedLead" />
                <LeadConfirmDecryptButtons
                  v-if="hasPermission('view pii')"
                  :form-processing="isLoading"
                  @decrypt="loadDecrypted($event)"
                />
              </v-card>
            </v-tab-item>
            <!-- ./ Submitted Lead -->

            <v-tab-item>
              <WaterfallResults :data="waterfallLog" />
            </v-tab-item>
            <!-- ./ Waterfall Results -->

            <v-tab-item>
              <FullStorySessions :sessions="leadDetails.fullStorySessions" />
            </v-tab-item>
            <!-- ./ FullStory sessions -->

            <v-tab-item>
              <v-card flat>
                <v-card-title> Loanpro Tracking </v-card-title>
                <JsonFormatterWrapper
                  :data="leadDetails.loanproTracking"
                  class="pa-5 pt-0"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <ProveTrackings :items="leadDetails.proveTrackings" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider />
        <v-divider />
        <v-card-actions>
          <v-card-text class="pl-5 pb-0">
            <h2 class="pb-4">
              Client Response
            </h2>
            <JsonFormatterWrapper :data="clientResponse" />
          </v-card-text>
        </v-card-actions>
      </template>

      <v-card-actions />
    </v-card>
  </v-dialog>
</template>

<script>

import JsonFormatterWrapper from '../JsonFormatterWrapper.vue';
import FullStorySessions from './FullStorySessions.vue';
import WaterfallResults from './WaterfallResults.vue';
import LeadConfirmDecryptButtons from './LeadConfirmDecryptButtons.vue';
import ProveTrackings from './ProveTrackings.vue';

export default {
  name: 'LeadDetails',

  components: {
    FullStorySessions,
    WaterfallResults,
    JsonFormatterWrapper,
    LeadConfirmDecryptButtons,
    ProveTrackings,
  },

  props: {
    leadId: {
      type: Number,
    },
  },

  data() {
    return {
      tabs: null,
      show: true,
      leadDetails: {
        submittedLead: {},
        waterfallResults: {},
        fullStorySessions: [],
        loanproTracking: {},
        proveTrackings: [],
      },
      isLoading: true,
    };
  },

  computed: {
    waterfallLog() {
      return JSON.parse(this.leadDetails.waterfallResults?.log_data ?? '{}');
    },

    clientResponse() {
      return JSON.parse(this.waterfallLog?.client_raw_response ?? '{}');
    },
  },

  async created() {
    const { status, data } = await this.$axios.get(
      `api/v1/lead/${this.leadId}/details`,
    );

    if (status === 200) {
      this.leadDetails = Object.assign(this.leadDetails, data);
    }

    this.isLoading = false;
  },

  methods: {
    loadDecrypted(reason) {
      this.isLoading = true;

      return this.$axios
        .post(`api/v1/lead/${this.leadId}/decrypt-details`, { reason })
        .then((res) => {
          if (res.status === 200) {
            this.leadDetails = Object.assign(this.leadDetails, res.data);
          }

          this.isLoading = false;
        });
    },
  },

};
</script>

<style scoped>
.v-dialog__content {
  align-items: start;
  margin-top: 15px;
}
</style>
