<template>
  <v-card
    class="ma-5"
    flat
  >
    <v-alert
      v-if="sessionsError"
      color="red darken-4"
      class="mt-5"
      dark
    >
      This lead does not have FullStory associated!
    </v-alert>
    <v-simple-table
      v-else
      fixed-header
    >
      <thead>
        <tr>
          <th class="text-left">
            Session Time
          </th>
          <th class="text-left">
            Fullstory Url
          </th>
        </tr>
      </thead>
      <tbody v-if="isEmpty">
        <tr class="text-center title">
          <td colspan="2">
            No Data Available
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="(session, i) in sessions.sessions"
          :key="i"
        >
          <td>{{ sessionTime(session) }}</td>
          <td>
            <a
              :href="session.fsUrl"
              target="__blank"
            >{{ session.fsUrl }}</a>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'FullStorySessions',

  props: {
    sessions: {
      type: [Array, Object],
      default: () => [],
    },
  },

  computed: {
    isEmpty() {
      return this.sessions.length === 0;
    },

    sessionsError() {
      return this.sessions && this.sessions.error;
    },
  },

  methods: {
    sessionTime(session) {
      if (session.createdTime) {
        return DateTime.fromSeconds(Number(session.createdTime), { zone: 'America/Chicago' })
          .toLocaleString(DateTime.DATETIME_MED);
      }
      return '';
    },
  },
};
</script>
