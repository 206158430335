<template>
  <v-card
    flat
    tile
    class="transparent"
  >
    <v-card-title class="pt-12 pb-7">
      <h2 class="headline">
        LEAD STREAM
      </h2>

      <v-spacer />

      <div class="d-flex align-start justify-end">
        <v-select
          :value="selectedFinalResultFilter"
          class="mr-3"
          :items="finalResultFilters"
          solo
          flat
          dense
          hide-details
          @change="onFinalResultFilterChange"
        />

        <v-select
          :value="selectedRowPerPage"
          class="mr-3"
          :items="rowsPerPage"
          solo
          flat
          dense
          hide-details
          style="display: none"
          @change="onRowPerPageChange"
        />

        <v-btn
          depressed
          tile
          class="mr-3 btn-bg-regal-gray"
          @click.stop="onFinalResultFilterChange('all')"
        >
          <v-icon class="mr-2">
            mdi-filter-remove-outline
          </v-icon>
          Clear Filters
        </v-btn>

        <v-btn
          depressed
          tile
          class="mr-3 btn-bg-deep-blue"
          @click.stop="showLeadSearchForm = true"
        >
          <v-icon class="mr-2">
            mdi-account-search
          </v-icon>
          Lead Search
        </v-btn>

        <v-btn
          depressed
          tile
          class="btn-bg-gunmetal"
          @click.stop="refreshLeads()"
        >
          <v-icon class="mr-2">
            mdi-refresh
          </v-icon>
          Refresh Leads
        </v-btn>
      </div>
    </v-card-title>

    <ag-grid-vue
      :style="'width: 100%; height: ' + tableHeight"
      class="ag-theme-alpine"
      :grid-options="gridConfig.gridOptions"
      :column-defs="gridConfig.columnDefs"
      :default-col-def="gridConfig.defaultColDef"
      :row-data="rowData"
      :pagination="true"
      row-selection="single"
      @grid-ready="onGridReady"
    />

    <LeadDetails
      v-if="selectedLeadId"
      :lead-id.sync="selectedLeadId"
      @close="selectedLeadId = null"
    />

    <LeadSearchForm
      v-if="showLeadSearchForm"
      :show.sync="showLeadSearchForm"
      :inputs="searchInputs"
      :selected="['id']"
      @search="onLeadSearch"
    />
  </v-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import CheckColumnRenderer from '../CheckColumnRenderer.vue';
import ActionComponent from '../ActionComponent.vue';
import LeadSearchForm from '../LeadSearchForm.vue';
import LeadDetails from './LeadDetails.vue';
import gridFormatter from '../aggrid-formatter';

export default {
  name: 'LeadStreamIndex',
  components: {
    ActionComponent,
    CheckColumnRenderer,
    LeadSearchForm,
    LeadDetails,
  },

  props: {
    height: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showLeadSearchForm: false,
      searchInputs: [
        { label: 'Lead ID', name: 'id' },
        { label: 'Lead Guid', name: 'lead_guid' },
        { label: 'First Name', name: 'firstname' },
        { label: 'Last Name', name: 'lastname' },
        { label: 'Guid', name: 'guid' },
        { label: 'Email', name: 'email' },
        { label: 'Phone Number', name: 'homephone' },
        {
          label: 'Final Result',
          name: 'final_result',
          items: [
            { text: 'All', value: 'all' },
            { text: 'Accepted', value: 'accepted' },
            { text: 'Outbided', value: 'outbid' },
            { text: 'Rejected', value: 'lead_rejected' },
            { text: 'Declined', value: 'declined' },
          ],
        },
        {
          label: 'Final Disposition',
          name: 'final_disposition',
          items: ['Pending', 'Funded', 'Expired'],
        },
      ],
      selectedLeadId: null,
      gridConfig: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        defaultColDef: null,
      },
      rowData: null,
      finalResultFilters: [
        { text: 'All', value: 'all' },
        { text: 'Accepted', value: 'accepted' },
        { text: 'Outbided', value: 'outbid' },
        { text: 'Rejected', value: 'lead_rejected' },
        { text: 'Declined', value: 'declined' },
      ],
      selectedFinalResultFilter: 'all',
      savedSearch: null,
      rowsPerPage: [
        { text: '100 rows', value: 100 },
        { text: '500 rows', value: 500 },
        { text: '1000 rows', value: 1000 },
        { text: '2000 rows', value: 2000 },
      ],
      selectedRowPerPage: 100,
    };
  },

  computed: {
    tableHeight() {
      const newTableHeight = this.height - 275;
      return `${newTableHeight}px`;
    },
  },

  watch: {
    rowData() {
      if (this.rowData) {
        this.gridConfig.gridApi.sizeColumnsToFit();
        this.gridConfig.gridColumnApi.autoSizeAllColumns(false);
      }
    },
  },

  beforeMount() {
    this.gridConfig.gridOptions = {};

    this.gridConfig.columnDefs = [
      {
        headerName: 'Date',
        field: 'create_date',
        pinned: 'left',
        valueFormatter: gridFormatter.date,
      },
      { headerName: 'Id', field: 'id', pinned: 'left' },
      { headerName: 'First Name', field: 'firstname', pinned: 'left' },
      { headerName: 'Last Name', field: 'lastname', pinned: 'left' },
      {
        headerName: 'Waterfall Result',
        field: 'final_result',
        cellClass: this.waterfallResultClass,
      },
      { headerName: 'Final Disposition', field: 'final_disposition' },
      { headerName: 'Reason', field: 'failed_class' },
      { headerName: 'State', field: 'state' },
      {
        headerName: 'Amount',
        field: 'requestamount',
        valueFormatter: gridFormatter.currency,
      },
      { headerName: 'Provider', field: 'company_name' },
      { headerName: 'Campaign', field: 'campaign_name' },
      {
        headerName: 'Time',
        field: 'total_execution_time',
        valueFormatter: gridFormatter.number,
      },
      {
        headerName: 'Redir',
        field: 'redirect',
        cellRendererFramework: 'CheckColumnRenderer',
      },
      { headerName: 'ID Ver Attempt', field: 'id_verif_attempt' },
      {
        headerName: 'ID Ver Result',
        field: 'id_check_result',
        cellRendererFramework: 'CheckColumnRenderer',
      },
      {
        headerName: 'Doc Sig Result',
        field: 'docusign_result',
        cellClass: this.docSignResultClass,
      },
      { headerName: 'Docusign Status', field: 'ds_fallback_status' },
      {
        headerName: 'Is MLA',
        field: 'is_mla',
        cellRendererFramework: 'CheckColumnRenderer',
      },
      { headerName: 'LP CustomerId', field: 'customer_id' },
      { headerName: 'LP LoanId', field: 'loan_id' },
      { headerName: 'Loan Display Id', field: 'loan_display_id' },
      {
        headerName: 'Amount Funded',
        field: 'amount_funded',
        valueFormatter: gridFormatter.currency,
      },
      {
        headerName: 'Action',
        field: 'id',
        pinned: 'right',
        cellRendererFramework: 'ActionComponent',
        onCellClicked: this.onCellClicked,
      },
    ];

    this.gridConfig.defaultColDef = {
      sortable: true,
      resizable: true,
      flex: 1,
      autoHeight: true,
    };
  },

  async mounted() {
    this.gridConfig.gridApi = this.gridConfig.gridOptions.api;
    this.gridConfig.gridColumnApi = this.gridConfig.gridOptions.columnApi;
  },

  methods: {
    refreshLeads() {
      if (this.savedSearch !== null) {
        this.onLeadSearch(this.savedSearch);
      } else {
        this.getLeadStream();
      }
    },

    waterfallResultClass(params) {
      switch (params.value) {
        case 'accepted':
          return 'green--text';
        case 'lead_rejected':
          return 'amber--text';
        default:
          return 'red--text';
      }
    },

    docSignResultClass(params) {
      switch (params.value) {
        case 'signed':
        case 'sent':
          return 'green--text';
        default:
          return 'red--text';
      }
    },

    idCheckClass(params) {
      switch (params.value) {
        case 'passed':
          return 'green--text';
        default:
          return 'red--text';
      }
    },

    async onGridReady() {
      await this.getLeadStream();

      this.gridConfig.gridApi.sizeColumnsToFit();
      this.gridConfig.gridColumnApi.autoSizeAllColumns(false);
    },

    async onFinalResultFilterChange(value) {
      this.savedSearch = null;
      this.selectedFinalResultFilter = value;
      await this.getLeadStream();
    },

    async onRowPerPageChange(value) {
      this.selectedRowPerPage = value;
      await this.refreshLeads();
    },

    getLeadStream() {
      this.gridConfig.gridApi.showLoadingOverlay();

      return this.$axios
        .post('api/v1/lead/stream', {
          leadType: this.selectedFinalResultFilter,
          perPage: this.selectedRowPerPage,
        })
        .then(({ status, data: { data } }) => {
          if (status === 200 && data) {
            this.rowData = data;
          }
        });
    },

    onLeadSearch(search) {
      if (search.final_result) {
        this.selectedFinalResultFilter = search.final_result;
      } else {
        this.selectedFinalResultFilter = 'all';
      }

      this.savedSearch = search;

      this.gridConfig.gridApi.showLoadingOverlay();

      return this.$axios
        .post('api/v1/lead/search', {
          searchArray: search,
          perPage: this.selectedRowPerPage,
        })
        .then(({ status, data: { data } }) => {
          if (status === 200 && data) {
            this.rowData = data;
          }
          this.showLeadSearchForm = false;
        });
    },

    onCellClicked({ value: selectedLeadId }) {
      this.selectedLeadId = Number(selectedLeadId);
    },
  },
};
</script>
<style>
.vjs-tree .vjs-value__string {
  color: seagreen;
}
</style>
