<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-if="hasPermission('edit lead::lead-providers')"
          small
          :color="chipColor"
          text-color="white"
          v-bind="attrs"
          @click.stop="showConfirmation()"
          v-on="on"
        >
          {{ chipText }}
          <v-dialog
            v-model="dialogDisable"
            max-width="380"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                Disable Lead Provider?
              </v-card-title>
              <v-card-text class="mt-5">
                Are you sure you want to Disable this Lead Provider?<br><br>
                If you do, the Lead Provider will be notified that we are not
                taking leads from them.
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary darken-1"
                  text
                  @click="toggle()"
                >
                  Confirm
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogDisable = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogEnable"
            max-width="380"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                Enable Lead Provider?
              </v-card-title>
              <v-card-text class="mt-5">
                Are you sure you want to Enable this Lead Provider?
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary darken-1"
                  text
                  @click="toggle()"
                >
                  Confirm
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogEnable = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-chip>
        <disabled-for-lack-of-role-btn
          v-else
          :text="chipText"
          permission="edit lead::lead-providers"
          :button="false"
          :chip="true"
          chip-color="error"
        />
      </template>
      <span>Click to {{ chipText.toLowerCase() }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'LeadProviderToggle',

  components: {
    DisabledForLackOfRoleBtn,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialogDisable: false,
      dialogEnable: false,
    };
  },

  computed: {
    status() {
      return !!this.item.status;
    },
    chipColor() {
      return this.status ? 'green' : 'red';
    },
    chipText() {
      return this.status ? 'Disable' : 'Enable';
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    showConfirmation() {
      if (this.chipText === 'Disable') {
        this.dialogDisable = true;
      } else {
        this.dialogEnable = true;
      }
      // this.toggle();
    },

    toggle() {
      const status = this.status ? 0 : 1;

      this.$axios.post(`api/v1/lead-provider/${this.item.id}/change-status`, { status })
        .then((response) => {
          this.setNotification({
            message: response.data.message,
            color: 'green',
          });

          this.$emit('provider-toggled');
        })
        .catch(() => {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        });

      this.dialogDisable = false;
      this.dialogEnable = false;
    },
  },
};
</script>

<style scoped>

</style>
