<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-select
          v-model="leadData.api_key"
          :items="leadProviders"
          label="API Key"
          outlined
        />
      </v-col>

      <v-col
        cols="12"
        md="2"
        sm="2"
      >
        <v-text-field
          v-model="leadData.ipaddress"
          label="IP Address"
          outlined
        />
      </v-col>

      <v-col
        cols="12"
        md="2"
        sm="2"
      >
        <v-text-field
          v-model="leadData.affid"
          label="Affiliate Id"
          outlined
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="4"
      >
        <v-select
          v-model="leadData.campaign"
          :items="campaigns"
          label="Campaign"
          outlined
        />
      </v-col>

      <v-col
        v-if="isReturnCampaign"
        md="12"
        sm="12"
        offset-md="3"
      >
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-alert
            v-if="customers.length > 0"
            text
            type="info"
            border="left"
          >
            Please choose the customer you want to return as.
          </v-alert>
          <v-alert
            v-else
            text
            type="error"
            border="left"
          >
            There are no customers to return as. Create a "Purchased" or "Walkup" lead first.
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-select
            v-model="leadData.customer_guid"
            :items="customers"
            label="Customer"
            @change="setReturningCustomer"
          />
        </v-col>
      </v-col>
    </v-row>
    <!--    Loan Information-->
    <v-card
      class="my-2"
      elevation="10"
    >
      <v-card-title>
        Loan Information
        <v-row class="justify-end">
          <v-col cols="2">
            <v-checkbox
              v-model="leadData.fundLoan"
              label="Fund Loan"
            />
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="leadData.generateZendeskTicket"
              label="Generate Zendesk Ticket"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row
          v-if="isLineOfCredit"
        >
          <v-col
            cols="12"
          >
            <v-alert
              border="top"
              color="blue lighten-1"
              dark
            >
              Note: If you are trying to create a loan with funds available the
              Request Amount should be 200 greater than Initial amount.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="2"
            sm="6"
          >
            <v-text-field
              v-model="leadData.requestamount"
              label="Request Amount"
              type="number"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="2"
            sm="6"
          >
            <v-text-field
              v-model="leadData.initialAmount"
              label="Initial Amount"
              type="number"
              outlined
              :disabled="!isLineOfCredit"
            />
          </v-col>

          <v-col
            cols="12"
            md="2"
            sm="6"
          >
            <v-text-field
              v-model="leadData.loanRate"
              label="Loan Rate"
              type="number"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            sm="6"
          >
            <v-text-field
              v-model="leadData.contract_date"
              label="Contract Date"
              type="date"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <v-select
              v-model="leadData.loan_status_id"
              :items="loanStatuses"
              item-text="title"
              item-value="id"
              label="Loan Status"
              outlined
              @change="getLoanSubStatus"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <v-select
              v-model="leadData.loan_sub_status_id"
              :items="computedLoanSubStatuses"
              item-text="title"
              item-value="id"
              label="Loan Sub Status"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--    End Loan Information-->
    <!--    Customer Information-->
    <v-card
      class="my-2"
      elevation="10"
    >
      <v-card-title>Customer Information</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.firstname"
              label="First Name"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.lastname"
              label="Last Name"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.homephone"
              label="Home Phone"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.email"
              label="Email"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.address"
              label="Address Line 1"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.address2"
              label="Address Line 2"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="leadData.city"
              label="City"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-select
              v-model="leadData.state"
              label="State"
              :items="usStates"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="leadData.zip"
              label="Zip"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-select
              v-model="leadData.rentorown"
              label="Rent or Own?"
              :items="['O', 'R']"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="leadData.addressmonths"
              label="Address Months"
              type="number"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="leadData.addressyears"
              label="Address Years"
              type="number"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            lg="3"
            sm="6"
          >
            <v-select
              v-model="leadData.dlstate"
              label="Drivers License State"
              :items="usStates"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            lg="3"
            sm="6"
          >
            <v-text-field
              v-model="leadData.dlnumber"
              label="Drivers License Number"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            lg="2"
            sm="4"
          >
            <v-select
              v-model="leadData.ismilitary"
              label="Military?"
              :items="['true', 'false']"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            lg="2"
            sm="4"
          >
            <v-select
              v-model="leadData.iscitizen"
              label="Citizen?"
              :items="['true', 'false']"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            lg="2"
            sm="4"
          >
            <v-select
              v-model="leadData.otheroffers"
              label="Other Offers?"
              :items="['true', 'false']"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            lg="2"
            md="4"
            sm="6"
          >
            <v-text-field
              v-model="leadData.ssn"
              label="SSN"
              type="number"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            lg="2"
            md="4"
            sm="6"
          >
            <v-text-field
              v-model="leadData.dob"
              label="Date of Birth"
              type="date"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--    End Customer Information-->
    <!--    Employment information-->
    <v-card
      class="my-2"
      elevation="10"
    >
      <v-card-title>Employment Information</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="9"
          >
            <v-row>
              <v-col
                cols="12"
                lg="4"
                sm="6"
              >
                <v-text-field
                  v-model="leadData.empname"
                  label="Employer Name"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                lg="4"
                sm="4"
              >
                <v-text-field
                  v-model="leadData.empphone"
                  label="Employer Phone"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                lg="4"
                sm="2"
              >
                <v-text-field
                  v-model="leadData.empphoneext"
                  label="Ext."
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="leadData.hiredate"
                  label="Hire Date"
                  type="date"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="leadData.empmonths"
                  label="Employed Months"
                  type="number"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="leadData.empyears"
                  label="Employed Years"
                  type="number"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="leadData.netmonthly"
                  label="Net Monthly"
                  type="number"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="leadData.grossmonthly"
                  label="Gross Monthly"
                  type="number"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                sm="2"
              >
                <v-select
                  v-model="leadData.incometype"
                  label="Income Type?"
                  :items="incomeTypes"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                sm="2"
              >
                <v-select
                  v-model="leadData.paytype"
                  label="Pay Type?"
                  :items="payTypes"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                sm="2"
              >
                <v-select
                  v-model="leadData.payfrequency"
                  label="Pay Frequency"
                  :items="filterPayFrequencies"
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="2"
            offset-md="1"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="leadData.lastpaydate"
                  label="Last Pay Date"
                  type="date"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="leadData.nextpaydate"
                  label="Next Pay Date"
                  type="date"
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="leadData.secondpaydate"
                  label="Second Pay Date"
                  type="date"
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--    End Employment information-->
    <!--    Bank information-->
    <v-card
      class="my-2"
      elevation="10"
    >
      <v-card-title>
        Bank Information
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.bankname"
              label="Bank Name"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.routingnumber"
              label="Routing Number"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="leadData.accountnumber"
              label="Account Number"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="1"
          >
            <v-select
              v-model="leadData.accounttype"
              label="Account Type"
              :items="accountTypes"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="1"
          >
            <v-text-field
              v-model="leadData.bankmonths"
              label="Bank Months"
              type="number"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="1"
          >
            <v-text-field
              v-model="leadData.bankyears"
              label="Bank Years"
              type="number"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--    End Bank information-->
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    leadData: {
      type: Object,
      default() {
        return {};
      },
    },
    leadProviders: {
      type: Array,
      default() {
        return [];
      },
    },
    campaigns: {
      type: Array,
      default() {
        return [];
      },
    },
    usStates: {
      type: Array,
      default() {
        return [];
      },
    },
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    isReturnCampaign: {
      type: Boolean,
      default: false,
    },
    loanStatuses: {
      type: Array,
      default() {
        return [];
      },
    },
    loanSubStatuses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      incomeTypes: [
        'E', 'D', 'S', 'U', 'P', 'O',
      ],
      payTypes: [
        'D', 'P', 'C',
      ],
      payFrequencies: [
        'W', 'B', 'S', 'M',
      ],
      accountTypes: [
        'C', 'S',
      ],
      computedLoanSubStatuses: [],
    };
  },

  computed: {
    isLineOfCredit() {
      return this.campaigns.some((campaign) => campaign.value === this.leadData.campaign && campaign.text.includes('lineOfCredit'));
    },
    filterPayFrequencies() {
      if (this.isLineOfCredit) {
        this.updateLeadData({
          payfrequency: 'B',
        });
        return this.payFrequencies.filter((freq) => freq !== 'W');
      }
      this.updateLeadData({
        payfrequency: 'W',
      });
      return this.payFrequencies;
    },
  },

  watch: {
    'leadData.campaign': {
      deep: true,
      handler() {
        if (!this.isLineOfCredit) {
          this.updateLeadData({
            initialAmount: this.leadData.requestamount,
          });
        }
      },
    },
  },

  mounted() {
    this.getLoanSubStatus(this.leadData.loan_status_id);
  },
  methods: {
    ...mapActions('testSuite', ['updateLeadData']),

    setReturningCustomer(val) {
      const customer = this.customers.find((customerN) => customerN.value === val);
      this.updateLeadData({
        return_customer: 1,
        customer_guid: val,
        firstname: (customer) ? customer.firstname : this.leadData.firstname,
        lastname: (customer) ? customer.lastname : this.leadData.lastname,
        email: (customer) ? customer.email : this.leadData.email,
        homephone: (customer) ? customer.homephone : this.leadData.homephone,
      });
    },
    getLoanSubStatus(parentId) {
      this.computedLoanSubStatuses = this.loanSubStatuses.filter((st) => st.parent === parentId);
    },
  },
};
</script>
