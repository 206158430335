<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
  >
    <template #activator="{ on: dialog }">
      <v-tooltip top>
        <template #activator="{ on: tooltip }">
          <v-btn
            v-if="hasPermission('create ticket::add-payment')"
            color="primary"
            class="mr-2"
            dark
            fab
            v-on="{...tooltip, ...dialog}"
            @click="populateDefaultValues()"
          >
            <v-icon dark>
              mdi-credit-card-plus
            </v-icon>
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text=""
            icon="mdi-credit-card-plus"
            permission="create ticket::add-payment"
            :x-small="false"
            button-class="mr-2"
          />
        </template>
        <span>Add a New Debit Card</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Add a New Debit Card
      </v-card-title>

      <v-card-text
        class="mt-6"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row
            cols="12"
          >
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="card.cardholder_name"
                label="Cardholder Name"
                :rules="cardHolderNameRules"
                dense
                filled
                clearable
                required
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="card.card_number"
                label="Card Number"
                :rules="cardNumberRules"
                dense
                filled
                clearable
                required
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="cvc"
                label="CVC"
                :rules="cardCvcRules"
                dense
                filled
                clearable
                required
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="card.expiration_date"
                label="Card Exp. Date (MM/YYYY)"
                :rules="cardExpRules"
                placeholder="MM/YYYY"
                dense
                filled
                clearable
                required
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="card.address"
                label="Address"
                :rules="addressRules"
                dense
                filled
                clearable
                required
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="card.city"
                label="City"
                :rules="cityRules"
                :disabled="zipcodeValidated"
                dense
                filled
                required
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-select
                v-model="card.state"
                label="State"
                :rules="stateRules"
                :disabled="zipcodeValidated"
                dense
                filled
                required
                :items="states"
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="card.zipcode"
                label="Zip Code"
                :rules="zipcodeRules"
                dense
                filled
                clearable
                required
                @input="getCityState()"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <FuturePaymentQuestionDialog
        v-model="futureDialog"
        account-type="debit card"
        :ticket="ticket"
        @use-for-future-payments="save(true)"
        @no-future-payments="save(false)"
      />

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          class="mr-2"
          @click="closeDialog()"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="!loading"
          color="primary"
          dark
          :disabled="loading"
          @click.stop="askFutureQuestion()"
        >
          Add Debit Card
        </v-btn>
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DateTime } from 'luxon';
import stateList from '@/data/states.json';

import FuturePaymentQuestionDialog from './FuturePaymentQuestionDialog.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketAddDebitCard',
  components: {
    FuturePaymentQuestionDialog,
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zipcodeValidated: true, // force zip code attempt first
      futureDialog: false,
      valid: true,
      dialog: false,
      loading: false,
      cvc: '',
      card: {
        cardholder_name: '',
        card_number: '',
        expiration_date: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: 'USA',
      },
      addressRules: [
        (v) => !!v || 'Address is required',
      ],
      cityRules: [
        (v) => !!v || 'City is required',
      ],
      stateRules: [
        (v) => !!v || 'State is required',
        (v) => (v && v.length >= 2) || 'State is required',
      ],
      zipcodeRules: [
        (v) => !!v || 'Zip Code is required',
        (v) => (v && v.length === 5) || 'Zip Code should be 5 digits long',
      ],
      cardHolderNameRules: [
        (v) => !!v || 'Cardholder name is required',
        (v) => (v && v.length >= 2) || 'Cardholder name is required',
      ],
      cardNumberRules: [
        (v) => !!v || 'Card Number is required',
        (v) => (v && v.length === 16) || 'Card Number must be 16 digits',
      ],
      cardCvcRules: [
        (v) => !!v || 'Card CVC is required',
        (v) => (v && v.length === 3) || 'Card CVC must be 3 digits',
      ],
      cardExpRules: [
        (v) => !!v || 'Card Exp. is required',
        (v) => /(0?[1-9]|1[0-2])\/(\d{4})/.test(v) || 'Card Exp. must be a valid date, (MM/YYYY).',
        (v) => (v && v.length === 7) || 'Card Exp. must be a valid date, (MM/YYYY).',
      ],
    };
  },
  computed: {
    states() {
      return stateList.map((state) => {
        const stateForSelect = {
          text: state.name,
          value: state.id,
        };
        return stateForSelect;
      });
    },
    ...mapState('loanProCustomer', ['customer']),
    ...mapState('settlement', ['disclosuresSent']),
    ...mapState('loanProLoan', ['todayScheduledPayment']),
  },
  async created() {
    this.populateDefaultValues();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProCustomer', ['getPaymentProfiles']),
    ...mapActions('autopays', ['updateAutopayPayment', 'reversePayment', 'makePayment']),
    ...mapActions('settlement', ['disclosuresNotSent']),
    async askFutureQuestion() {
      await this.$refs.form.validate();
      if (this.valid) {
        this.disclosuresNotSent();
        this.futureDialog = true;
      }
    },
    async getCityState() {
      if (
        this.card.zipcode === null
        || this.card.zipcode.length !== 5
      ) {
        this.card.city = '';
        this.card.state = '';

        // force zip lookup before allowing manual entry
        this.zipcodeValidated = true;
      } else {
        try {
          const res = await this.$axios
            .get(`api/v1/admin/zip-code/lookup?zipCode=${this.card.zipcode}`);

          // zip lookup succeeded, do not allow manual entry
          this.zipcodeValidated = true;
          this.card.city = res.data.city;
          this.card.state = res.data.state_abbr;
        } catch (error) {
          // no city/state found in db, allow manual entry
          this.zipcodeValidated = false;
        }
      }
    },
    // This method will make multiple API calls
    async save(future) {
      await this.$refs.form.validate();
      if (this.valid) {
        try {
          this.futureDialog = false;
          await this.createPaymentProfile(future);
        } catch (e) {
          await this.returnErrorNotification();
        }
      }
    },
    async createPaymentProfile(future) {
      this.loading = true;
      const formattedExpDate = this.card.expiration_date.replace('/', '');
      // 1st API call, Generate token with form information
      const res = await this.$axios
        .post('api/v1/admin/pci-wallet', {
          endpoint: 'credit-card',
          httpMethod: 'post',
          payload: {
            ...this.card,
            expiration_date: formattedExpDate,
          },
        });
      if (!res.data.result) {
        await this.returnErrorNotification(`${res.data.message}.`);
      } else if (res.data.result) {
        // format bank account title:
        const lastFour = this.card.card_number
          .substr(this.card.card_number.length - 4);
        const dateInSeconds = DateTime.local().toSeconds();
        const dateInSecondsString = `${dateInSeconds}`;
        const dateWithNoDecimals = dateInSecondsString.split('.');
        const accountTitle = `DebitCard${dateWithNoDecimals[0]} - ${lastFour}`;
        // 2nd API call, Send token to bank account API
        const bankResponse = await this.$axios
          .post('api/v1/admin/customer/debit-card', {
            title: accountTitle,
            token: res.data.data.token,
            customerId: this.ticket.lpCustomer.id,
            ticketId: this.ticket.zendesk.id,
          });
        if (bankResponse.status === 200) {
          // 3rd API call, done through store, Grab all Payment Profiles to verify
          // that this payment profile was created successfully
          await this.getPaymentProfiles();
          const newPaymentProfile = (this.customer?.paymentProfiles || [])
            .find((item) => item.title === accountTitle);
          if (newPaymentProfile) {
            // return success notification
            if (future) {
              // if theres an autopay for today and the time is before 3pm (15:00)
              // reverse that autopay and create a new autopay with the new payment account
              if ((this.todayScheduledPayment?.id || null) && DateTime.local() < DateTime.local().startOf('day').plus({ hours: 15 })) {
                // call reverse endpoint
                const reverseRes = await this.reversePayment(this.todayScheduledPayment.id);
                if (reverseRes.status === 200) {
                  // make new payment for reversed payment using the new payment info
                  await this.makePayment({
                    paymentProfile: newPaymentProfile.id,
                    paymentDate: this.todayScheduledPayment.apply_date,
                    paymentAmount: this.todayScheduledPayment.amount,
                    paymentTypeId: this.todayScheduledPayment.payment_type_id,
                  });
                }
              }
              const updateResponse = await this.updateAutopayPayment(
                {
                  loanId: this.ticket.active_loan.id,
                  selectedAccountId: newPaymentProfile.id,
                },
              );
              if (updateResponse.status === 200) {
                // update the payment profiles list to reflect new primary
                await this.getPaymentProfiles();
                await this.returnSuccessNotification('We successfully created the Payment Profile. Note: This profile set as the payment profile for the existing recurring autopay.', accountTitle);
              } else {
                // still update the list to show the newly added profile
                await this.getPaymentProfiles();
                await this.returnErrorNotification('In order to set a primary payment method, there must be an active autopay. Please set up an autopay and try again.');
                this.dialog = false;
              }
            } else {
              await this.returnSuccessNotification('We successfully created the Payment Profile. Note: This profile was not set as the primary payment account.', accountTitle);
            }
          } else {
            // return error notification
            await this.returnErrorNotification('We were unable to locate the Payment Profile. Please try again. If this message persists, contact your supervisor.');
          }
        } else {
          // return error notification
          await this.returnErrorNotification('We were unable to create the Payment Profile. Please try again. If this message persists, contact your supervisor.');
        }
      } else {
        // return error notification
        await this.returnErrorNotification('We were unable to create a Credit Card token with PCI wallet. Please try again. If this message persists, contact your supervisor.');
      }
    },
    async returnErrorNotification(message = 'Something went wrong') {
      // add comment to Zendesk controller
      await this.$axios
        .post('api/v1/zendesk/add-ticket-comment', {
          ticketId: this.$route.params.id,
          comment: `${message}`,
        });
      this.setNotification({
        message,
        color: 'red',
      });
      this.loading = false;
    },
    async returnSuccessNotification(message = 'We have successfully added this Payment Method', accountTitle) {
      // add comment to Zendesk controller
      await this.$axios
        .post('api/v1/zendesk/add-ticket-comment', {
          ticketId: this.$route.params.id,
          comment: `${message} Here is the Bank Account title: ${accountTitle}`,
        });
      this.setNotification({
        message,
        color: 'green',
      });
      this.loading = false;
      this.$refs.form.resetValidation();
      // emit a success event with new payment accountTitle
      this.$emit('success', accountTitle);
      this.dialog = false;
    },
    closeDialog() {
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
    populateDefaultValues() {
      // clear everything to be safe
      this.card.expiration_date = '';
      this.card.card_number = '';
      this.card.cardholder_name = '';
      this.card.address = '';
      this.card.city = '';
      this.card.state = '';
      this.card.zipcode = '';
      this.cvc = '';

      // set customer values where appropriate
      this.card.cardholder_name = `${this.ticket?.customer?.firstname || ''} ${this.ticket?.customer?.lastname || ''}`;
      this.card.address = this.ticket?.customer?.address || '';
      this.card.city = this.ticket?.customer?.city || '';
      this.card.state = this.ticket?.customer?.state || '';
      this.card.zipcode = this.ticket?.customer?.zip || '';
    },
  },
};
</script>
