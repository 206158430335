<template>
  <v-container fluid>
    <h3>Lead Data</h3>
    <v-row>
      <v-col
        cols="12"
        md="5"
        sm="12"
      >
        <v-select
          v-model="leadData.api_key"
          :items="leadProviders"
          label="API Key"
        />
      </v-col>

      <v-col
        cols="12"
        md="2"
        sm="6"
      >
        <v-text-field
          v-model="leadData.ipaddress"
          label="IP Address"
        />
      </v-col>

      <v-col
        cols="12"
        md="2"
        sm="6"
      >
        <v-text-field
          v-model="leadData.affid"
          label="Affiliate Id"
        />
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-select
          v-model="leadData.campaign"
          :items="campaigns"
          label="Campaign"
        />
      </v-col>

      <v-col
        v-if="isReturnCampaign"
        md="12"
        sm="12"
        offset-md="3"
      >
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-alert
            v-if="customers.length > 0"
            text
            type="info"
            border="left"
          >
            Please choose the customer you want to return as.
          </v-alert>
          <v-alert
            v-else
            text
            type="error"
            border="left"
          >
            There are no customers to return as. Create a "Purchased" or "Walkup" lead first.
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-select
            v-model="leadData.customer_guid"
            :items="customers"
            label="Customer"
          />
        </v-col>
      </v-col>

      <v-col
        cols="12"
        lg="3"
        sm="6"
      >
        <v-text-field
          v-model="leadData.test"
          label="Test"
        />
      </v-col>

      <v-col
        cols="12"
        lg="3"
        sm="6"
      >
        <v-text-field
          v-model="leadData.testtype"
          label="Test Type"
        />
      </v-col>

      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="6"
      >
        <v-text-field
          v-model="leadData.requestamount"
          label="Request Amount"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="6"
      >
        <v-text-field
          v-model="leadData.ssn"
          label="SSN"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="6"
      >
        <v-text-field
          v-model="leadData.dob"
          label="Date of Birth"
          type="date"
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="leadData.firstname"
          label="First Name"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="leadData.lastname"
          label="Last Name"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="leadData.homephone"
          label="Home Phone"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="leadData.email"
          label="Email"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="leadData.address"
          label="Address Line 1"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="leadData.address2"
          label="Address Line 2"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.city"
          label="City"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-select
          v-model="leadData.state"
          label="State"
          :items="usStates"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.zip"
          label="Zip"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-select
          v-model="leadData.rentorown"
          label="Rent or Own?"
          :items="['O', 'R']"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.addressmonths"
          label="Address Months"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.addressyears"
          label="Address Years"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        lg="3"
        sm="6"
      >
        <v-select
          v-model="leadData.dlstate"
          label="Drivers License State"
          :items="usStates"
        />
      </v-col>

      <v-col
        cols="12"
        lg="3"
        sm="6"
      >
        <v-text-field
          v-model="leadData.dlnumber"
          label="Drivers License Number"
        />
      </v-col>

      <v-col
        cols="12"
        lg="2"
        sm="4"
      >
        <v-select
          v-model="leadData.ismilitary"
          label="Military?"
          :items="['true', 'false']"
        />
      </v-col>

      <v-col
        cols="12"
        lg="2"
        sm="4"
      >
        <v-select
          v-model="leadData.iscitizen"
          label="Citizen?"
          :items="['true', 'false']"
        />
      </v-col>

      <v-col
        cols="12"
        lg="2"
        sm="4"
      >
        <v-select
          v-model="leadData.otheroffers"
          label="Other Offers?"
          :items="['true', 'false']"
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row>
      <v-col
        cols="12"
        lg="4"
        sm="6"
      >
        <v-text-field
          v-model="leadData.empname"
          label="Employer Name"
        />
      </v-col>

      <v-col
        cols="12"
        lg="4"
        sm="4"
      >
        <v-text-field
          v-model="leadData.empphone"
          label="Employer Phone"
        />
      </v-col>

      <v-col
        cols="12"
        lg="4"
        sm="2"
      >
        <v-text-field
          v-model="leadData.empphoneext"
          label="Ext."
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="leadData.hiredate"
          label="Hire Date"
          type="date"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="leadData.empmonths"
          label="Employed Months"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="leadData.empyears"
          label="Employed Years"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="leadData.netmonthly"
          label="Net Monthly"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="leadData.grossmonthly"
          label="Gross Monthly"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="leadData.incometype"
          label="Income Type?"
          :items="incomeTypes"
        />
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="leadData.paytype"
          label="Pay Type?"
          :items="payTypes"
        />
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="leadData.payfrequency"
          label="Pay Frequency"
          :items="payFrequencies"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-text-field
          v-model="leadData.lastpaydate"
          label="Last Pay Date"
          type="date"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-text-field
          v-model="leadData.nextpaydate"
          label="Next Pay Date"
          type="date"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-text-field
          v-model="leadData.secondpaydate"
          label="Second Pay Date"
          type="date"
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.bankname"
          label="Bank Name"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.routingnumber"
          label="Routing Number"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.accountnumber"
          label="Account Number"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-select
          v-model="leadData.accounttype"
          label="Account Type"
          :items="accountTypes"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.bankmonths"
          label="Bank Months"
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="leadData.bankyears"
          label="Bank Years"
          type="number"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    leadData: {
      type: Object,
      default() {
        return {};
      },
    },
    leadProviders: {
      type: Array,
      default() {
        return [];
      },
    },
    campaigns: {
      type: Array,
      default() {
        return [];
      },
    },
    usStates: {
      type: Array,
      default() {
        return [];
      },
    },
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    isReturnCampaign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      incomeTypes: [
        'E', 'D', 'S', 'U', 'P', 'O',
      ],
      payTypes: [
        'D', 'P', 'C',
      ],
      payFrequencies: [
        'W', 'B', 'S', 'M',
      ],
      accountTypes: [
        'C', 'S',
      ],
    };
  },
};
</script>
