<template>
  <div class="content-holder">
    <OfferDecision
      v-if="activeView === views.OFFER_DECISION"
      :offer="offerData"
      :loan-data="loanData"
      @settlement-decision="onSettlementDecision"
      @back-make-offer="onBackMakeOffer"
    />
    <MakeOffer
      v-if="activeView === views.MAKE_OFFER"
      :is-covid-collection="isCovidCollection"
      :today-payoff-balance="todayPayoffBalance"
      :payment-accounts="paymentAccounts"
      :ticket="ticket"
      @settlement-offer="onSettlementOffer"
      @reload-payment-profiles="$emit('reload-payment-profiles')"
      @back-ticket-settlement="$emit('back-ticket-settlement')"
    />
    <ConfirmSettlement
      v-if="activeView === views.CONFIRM_SETTLEMENT"
      :settlement-offer="offerData"
      :loan-settings-id="loanSettingsId"
      :today-payoff-balance="todayPayoffBalance"
      :loan-id="loanId"
      :customer-id="customerId"
      :payment-accounts="paymentAccounts"
      :ticket="ticket"
      @offer-confirmed="onOfferConfirmed"
      @back-offer-decision="onBackOfferDecision"
    />
    <SettlementConfirmation
      v-if="activeView === views.SETTLEMENT_CONFIRMATION"
      v-bind="{ ...offerData }"
      :display-id="displayId"
      :full-name="fullName"
    />
  </div>
</template>

<script>

import { mapActions } from 'vuex';

import MakeOffer from './MakeOffer.vue';
import OfferDecision from './OfferDecision.vue';
import ConfirmSettlement from './ConfirmSettlement.vue';
import SettlementConfirmation from './SettlementConfirmation.vue';

export default {
  name: 'SettlementOffer',

  components: {
    MakeOffer,
    OfferDecision,
    ConfirmSettlement,
    SettlementConfirmation,
  },

  props: {
    ticket: {
      type: Object,
      required: true,
    },
    todayPayoffBalance: {
      type: Number,
      required: true,
    },
    isCovidCollection: {
      type: Boolean,
      required: false,
    },
    loanSettingsId: {
      type: Number,
      required: true,
    },
    displayId: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    customerId: {
      type: Number,
      required: true,
    },
    loanId: {
      type: Number,
      required: true,
    },
    paymentAccounts: {
      type: Array,
      required: true,
    },
    loanData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      views: {
        OFFER_DECISION: 'OFFER DECISION',
        MAKE_OFFER: 'MAKE OFFER',
        CONFIRM_SETTLEMENT: 'CONFIRM_SETTLEMENT',
        SETTLEMENT_CONFIRMATION: 'SETTLEMENT_CONFIRMATION',
      },
      activeView: '',
      offerData: {},
      originalOfferData: {},
      showConfirmation: false,
    };
  },

  async created() {
    // chose de default view
    this.activeView = this.views.MAKE_OFFER;
  },

  methods: {
    ...mapActions('settlement', ['getPaymentSchedule', 'paymentSchedulesNotSent', 'disclosuresNotSent']),

    onSettlementOffer(offerData) {
      this.originalOfferData = offerData;
      this.offerData = offerData;
      this.activeView = this.views.OFFER_DECISION;
    },

    async onSettlementDecision(offerData) {
      await this.getPaymentSchedule({ offer: offerData });
      this.offerData = offerData;
      this.activeView = this.views.CONFIRM_SETTLEMENT;
    },

    onOfferConfirmed(data) {
      this.offerData = data;
      this.activeView = this.views.SETTLEMENT_CONFIRMATION;
    },

    onBackOfferDecision() {
      this.offerData = this.originalOfferData;
      this.activeView = this.views.OFFER_DECISION;
      this.paymentSchedulesNotSent();
      this.disclosuresNotSent();
    },

    onBackMakeOffer() {
      this.activeView = this.views.MAKE_OFFER;
      this.paymentSchedulesNotSent();
      this.disclosuresNotSent();
    },
  },
};
</script>
