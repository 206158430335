<template>
  <v-row>
    <v-col
      cols="9"
      md="9"
    >
      <v-data-table
        dense
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        class="elevation-1"
        :footer-props.sync="footerProps"
        @update:options="updateTable()"
      >
        <template v-slot:top>
          <v-card-title>
            <v-toolbar flat>
              <v-toolbar-title>Lead Queue</v-toolbar-title>

              <v-spacer />

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @input="searchTable()"
              />
            </v-toolbar>
          </v-card-title>
        </template>

        <template v-slot:item.autopay_added="{ item }">
          {{ item.autopay_added == 1 ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.docs_uploaded="{ item }">
          {{ item.docs_uploaded == 1 ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.activated="{ item }">
          {{ item.activated == 1 ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.created="{ item }">
          {{ formatDateTime(item.created) }}
        </template>

        <template v-slot:item.updated="{ item }">
          {{ formatDateTime(item.updated) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            small
            tile
            text
            :to="{ name: 'lead-queue-show', params: { id: item.lead_id } }"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-eye
            </v-icon>
            VIEW
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col
      cols="3"
      md="3"
    >
      <BarGraph
        :data="loanproGraphData"
        title="Loanpro API"
        class="mb-4"
      />
      <BarGraph
        :data="pciwalletGraphData"
        title="PCI-wallet API"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTime } from 'luxon';
import BarGraph from '@/components/BarGraph.vue';
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  name: 'LeadAmortizationIndex',
  components: {
    BarGraph,
  },
  mixins: [
    dataTableMixin,
  ],
  data() {
    return {
      headers: [
        { text: 'Lead ID', value: 'lead_id' },
        { text: 'Customer ID', value: 'customer_id' },
        { text: 'Loan ID', value: 'loan_id' },
        { text: 'Autopay', value: 'autopay_added' },
        { text: 'Docs Uploaded', value: 'docs_uploaded' },
        { text: 'Loan Activated', value: 'activated' },
        { text: 'Created', value: 'created' },
        { text: 'Updated', value: 'updated' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loanproGraphData: [],
      pciwalletGraphData: [],
    };
  },
  created() {
    this.fetchQueueCount();
  },
  methods: {
    ...mapActions('sidebar', ['setQueueCount']),
    updateTable() {
      this.loading = true;
      return this.$axios
        .get('api/v1/lead-queue', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;

          this.fetchLoanproData();
          this.fetchPciData();
        });
    },
    fetchLoanproData() {
      return this.$axios
        .get('api/v1/lead-queue/loanpro-calls', {
          params: {
            search: this.search,
          },
        })
        .then((res) => {
          this.loanproGraphData = res.data;
        });
    },
    fetchPciData() {
      return this.$axios
        .get('api/v1/lead-queue/pci-calls', {
          params: {
            search: this.search,
          },
        })
        .then((res) => {
          this.pciwalletGraphData = res.data;
        });
    },
    fetchQueueCount() {
      return this.$axios
        .get('api/v1/lead-queue/count')
        .then((res) => {
          this.setQueueCount(res.data);
        });
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    },
  },
};
</script>
