<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(menu, index) in menus">
        <v-tab
          :key="index"
          :to="{ name: menu.route }"
        >
          {{ menu.label }}
        </v-tab>
      </template>
    </v-tabs>
    <v-card class="mt-10 transparent">
      <router-view />
    </v-card>
  </v-container>
</template>

<script>
export default {

  computed: {
    menus() {
      const menu = [];

      if (this.hasPermission('view ops_tickets::dashboard')) {
        menu.push({
          label: 'Ops Ticket',
          icon: 'mdi-account-group',
          route: 'ops-ticket',
        });
      }

      if (this.hasPermission('view ticket::any-ticket')) {
        menu.push({
          label: 'Helpdesk',
          icon: 'mdi-account-group',
          route: 'helpdesk',
        });
      }

      return menu;
    },
  },
};
</script>

<style></style>
