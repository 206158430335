import Vue from 'vue';
import axios from 'axios';

// Vuelidate validation library https://vuelidate.js.org/
import Vuelidate from 'vuelidate';
import VueApexCharts from 'vue-apexcharts';

import { datadogRum } from '@datadog/browser-rum';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Bugsnag from '@bugsnag/js';
import VueClipboard from 'vue-clipboard2';
import Filters from '@/filters';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import '@fontsource/roboto'; // Defaults to weight 400.

if (process.env.VUE_APP_DATADOG_APPLICATION_ID && process.env.VUE_APP_DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'admin-portal',
    env: process.env.NODE_ENV,
    version: process.env.VUE_APP_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

datadogRum.startSessionReplayRecording();

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.VUE_APP_VERSION,
  plugins: [new BugsnagPluginVue()],
  onError(event) {
    event.addMetadata('brand', { name: process.env.VUE_APP_PL_NAME });
  },
});
Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueApexCharts);

Vue.use(Filters);

Vue.component('apexchart', VueApexCharts);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.prototype.$axios = axios.create({
  baseURL: process.env.VUE_APP_UNDERWRITING_URL,
  withCredentials: true,
});

Vue.prototype.$axios.interceptors.request.use(
  (config) => {
    const c = { ...config };
    c.headers['X-ROUTE-CONTEXT'] = btoa(JSON.stringify({
      name: router.history.current.name,
      params: router.history.current.params,
    }));
    return c;
  },
  (error) => Promise.reject(error),
);

Vue.prototype.$axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // bugsnag capture unhandled errors
    Bugsnag.notify(error);
    // log user out if 401 or 419 is returned (or 503 for maintenance mode)
    if (error.response && [419, 401, 503].includes(error.response.status)) {
      if ([419, 401].includes(error.response.status)) {
        Vue.prototype.$axios
          .get('/sanctum/csrf-cookie')
          .then(() => store.dispatch('currentUser/logUserOut'))
          .then(() => {
            if (router.currentRoute.name !== 'login') {
              router.push({ name: 'login' });
            }
          });
      } else if (store.state.auth.isAuth) {
        store.dispatch('currentUser/logUserOut').then(() => {
          if (error.response.status === 503) {
            router.push({ name: 'Maintenance' });
          }

          if (router.currentRoute.name !== 'login') {
            router.push({ name: 'login' });
          }
        });
      }
    }

    return Promise.reject(error);
  },
);

Vue.mixin({
  methods: {
    hasPermission(permission) {
      let hasAccess = false;
      const permissions = store.state.currentUser.user.permission;
      for (let i = 0; i < permissions?.length; i += 1) {
        if (permissions[i].name === permission) {
          hasAccess = true;
          break;
        }
      }
      return hasAccess;
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  vuetify,
}).$mount('#app');
