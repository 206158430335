<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Lead Provider List</v-toolbar-title>

          <v-spacer />

          <v-btn
            v-if="hasPermission('create lead::lead-providers')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Provider
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Provider"
            permission="create lead::lead-providers"
            :x-small="false"
            button-class="mb-2"
          />

          <LeadProviderFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.contact="{ item }">
      {{ item.first_name }} {{ item.last_name }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('edit lead::lead-providers')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="edit lead::lead-providers"
        :x-small="false"
        button-class="mr-10"
      />

      <LeadProviderToggle
        :item="item"
        @provider-toggled="updateTable"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import LeadProviderToggle from './LeadProviderToggle.vue';
import LeadProviderFormModal from './LeadProviderFormModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    LeadProviderFormModal,
    LeadProviderToggle,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Company', value: 'company_name' },
      { text: 'Category', value: 'category' },
      { text: 'Type', value: 'provider_type' },
      { text: 'Contact', value: 'contact', sortable: false },
      { text: 'Username', value: 'username', sortable: false },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Api Key', value: 'api_key', sortable: false },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'start',
        width: 183,
      },
    ],

    dialogDelete: false,
    dialogForm: false,
    editedItem: {
      id: null,
    },
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemDeleted() {
      this.items = [];
      this.updateTable();
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/lead-provider', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
