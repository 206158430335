<template>
  <div>
    <v-card>
      <v-card-title>
        Lead Dashboard Report
      </v-card-title>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="fromDate"
                required
                label="From Date"
                type="date"
                :error-messages="fromDateErrors"
                @input="$v.fromDate.$touch()"
                @blur="$v.fromDate.$touch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="toDate"
                required
                label="To Date"
                type="date"
                :error-messages="toDateErrors"
                @input="$v.toDate.$touch()"
                @blur="$v.toDate.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="returnCustomer"
                :items="returnCustomerOptions"
                label="Customer Type"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="usState"
                :items="usStates"
                label="States"
              />
            </v-col>
          </v-row>

          <v-btn
            class="btn-bg-deep-blue"
            :disabled="formProcessing"
            :loading="reportGenerating"
            @click="generateReport()"
          >
            Generate Report
          </v-btn>
        </v-form>
      </v-container>
    </v-card>

    <template
      v-if="reportLoaded"
    >
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props.sync="footerProps"
        :search="search"
        class="elevation-1 mt-2"
      >
        <template v-slot:top>
          <v-card-title>
            <v-toolbar flat>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-toolbar>
          </v-card-title>
        </template>

        <template v-slot:item.passPercent="{ item }">
          {{ parseFloat(item.passPercent).toFixed(2) }}%
        </template>

        <template v-slot:item.redirectedRate="{ item }">
          {{ parseFloat(item.redirectedRate).toFixed(2) }}%
        </template>

        <template v-slot:item.clickThroughPct="{ item }">
          {{ parseFloat(item.clickThroughPct).toFixed(2) }}%
        </template>

        <template v-slot:item.conversion="{ item }">
          {{ parseFloat(item.conversion).toFixed(2) }}%
        </template>

        <template v-slot:item.dataCostPerLead="{ item }">
          {{ parseFloat(item.dataCostPerLead).toFixed(2) }}
        </template>

        <template v-slot:item.lead_cost_fee="{ item }">
          {{ parseFloat(item.lead_cost_fee).toFixed(2) }}
        </template>

        <template v-slot:item.totalCostPerLead="{ item }">
          {{ parseFloat(item.totalCostPerLead).toFixed(2) }}
        </template>

        <template v-slot:item.acqCostDollar="{ item }">
          {{ parseFloat(item.acqCostDollar).toFixed(2) }}
        </template>

        <template v-slot:item.acqCostPct="{ item }">
          {{ parseFloat(item.acqCostPct).toFixed(2) }}%
        </template>

        <template v-slot:item.acqCostPct="{ item }">
          {{ parseFloat(item.acqCostPct).toFixed(2) }}%
        </template>

        <template v-slot:item.fpd="{ item }">
          {{ parseFloat(item.fpd).toFixed(2) }}
        </template>

        <template v-slot:item.first_payment_amount="{ item }">
          {{ parseFloat(item.first_payment_amount).toFixed(2) }}
        </template>

        <template v-slot:item.first_payment_reversed_amount="{ item }">
          {{ parseFloat(item.first_payment_reversed_amount).toFixed(2) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                x-small
                v-on="on"
              >
                Options
              </v-btn>
            </template>

            <v-list>
              <v-list-item-group>
                <v-list-item
                  @click="goToAffiliateReport(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Affiliate Report
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="downloadLeadReport(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Download Lead Report
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>

        <!-- Totals Row -->
        <template slot="body.append">
          <tr>
            <th>Totals</th>
            <th>&mdash;</th>
            <th>{{ formatTotal(reportTotals.total_leads) }}</th>
            <th>{{ formatTotal(reportTotals.accepted_leads) }}</th>
            <th>{{ formatTotal(reportTotals.passPercent) }}%</th>
            <th>{{ formatTotal(reportTotals.redirect_count) }}</th>
            <th>{{ formatTotal(reportTotals.redirectedRate) }}%</th>
            <th>{{ formatTotal(reportTotals.click_through_count) }}</th>
            <th>{{ formatTotal(reportTotals.clickThroughPct) }}%</th>
            <th>{{ formatTotal(reportTotals.count_funded) }}</th>
            <th>{{ formatTotal(reportTotals.funded_amount) }}</th>
            <th>{{ formatTotal(reportTotals.conversion) }}%</th>
            <th>{{ formatTotal(reportTotals.dataCostPerLead) }}</th>
            <th>{{ formatTotal(reportTotals.lead_cost_fee) }}</th>
            <th>{{ formatTotal(reportTotals.totalCostPerLead) }}</th>
            <th>{{ formatTotal(reportTotals.acqCostDollar) }}</th>
            <th>{{ formatTotal(reportTotals.acqCostPct) }}%</th>
            <th>{{ formatTotal(reportTotals.fpd) }}</th>
            <th>{{ formatTotal(reportTotals.first_payment_amount) }}</th>
            <th>{{ formatTotal(reportTotals.first_payment_count) }}</th>
            <th>{{ formatTotal(reportTotals.first_payment_reversed_amount) }}</th>
            <th>{{ formatTotal(reportTotals.first_payment_reversed_count) }}</th>
          </tr>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';

import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';

import stateList from '@/data/states.json';

export default {
  mixins: [
    dataTableMixin,
  ],

  data() {
    return {
      fromDate: DateTime.local().toISODate(),
      toDate: DateTime.local().toISODate(),
      returnCustomer: null,
      returnCustomerOptions: [
        {
          value: null,
          text: 'All Customers',
        },
        {
          value: 0,
          text: 'New Customers',
        },
        {
          value: 1,
          text: 'Return Customers',
        },
      ],
      usState: null,
      usStates: [],
      reportData: [],
      reportTotals: {},
      reportLoaded: false,
      formProcessing: false,
      reportGenerating: false,
      headers: [
        { text: 'Source', value: 'lead_provider.company_name' },
        { text: 'Camp', value: 'campaign_obj.campaign_name' },
        { text: 'Presented', value: 'total_leads' },
        { text: 'Accept', value: 'accepted_leads' },
        { text: 'Accept Rate', value: 'passPercent' },
        { text: 'Redir', value: 'redirect_count' },
        { text: 'Redir Rate', value: 'redirectedRate' },
        { text: 'Clk Through Cnt', value: 'click_through_count' },
        { text: 'Clk Through %', value: 'clickThroughPct' },
        { text: 'Orig #', value: 'count_funded' },
        { text: 'Orig $', value: 'funded_amount' },
        { text: 'Conv', value: 'conversion' },
        { text: 'Data Cost', value: 'dataCostPerLead' },
        { text: 'Lead Cost', value: 'lead_cost_fee' },
        { text: 'Total Cost', value: 'totalCostPerLead' },
        { text: 'Acq Cost $', value: 'acqCostDollar' },
        { text: 'Acq Cost %', value: 'acqCostPct' },
        { text: 'FPD $%', value: 'fpd' },
        { text: '1st pmt $', value: 'first_payment_amount' },
        { text: '1st pmt cnt', value: 'first_payment_count' },
        { text: '1st pmt reverse $', value: 'first_payment_reversed_amount' },
        { text: '1st pmt reverse cnt', value: 'first_payment_reversed_count' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },

  computed: {
    fromDateErrors() {
      const errors = [];

      if (!this.$v.fromDate.$dirty) {
        return errors;
      }
      if (!this.$v.fromDate.required) {
        errors.push('From Date is required.');
      }
      return errors;
    },
    toDateErrors() {
      const errors = [];

      if (!this.$v.toDate.$dirty) {
        return errors;
      }
      if (!this.$v.toDate.required) {
        errors.push('To Date is required.');
      }
      return errors;
    },
  },

  created() {
    this.usStates = stateList.map((state) => state.id);

    this.usStates.unshift({
      value: null,
      text: 'All States',
    });
  },

  methods: {
    generateReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .get('api/v1/reports/lead-dashboard-report', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
              returnCustomer: this.returnCustomer,
              state: this.usState,
            },
          })
          .then((res) => {
            vm.items = Object.values(res.data.reportData);
            vm.reportTotals = { ...res.data.reportTotals };
            vm.reportLoaded = true;
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },
    formatTotal(value) {
      return value ? parseFloat(value).toFixed(2) : 0;
    },
    downloadLeadReport(item) {
      this.$axios
        .get('api/v1/reports/leads-report', {
          params: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            returnCustomer: this.returnCustomer,
            state: this.usState,
            leadProviderId: item.lead_provider_id,
            campaignId: item.campaign,
          },
        }, {
          responseType: 'blob',
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: 'text/csv' });
          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(blob);
          link.download = 'export.csv';
          link.click();

          link.remove();
        });
    },

    goToAffiliateReport(item) {
      this.$router.push({
        name: 'affiliate-report',
        params: {
          leadProviderId: item.lead_provider_id,
          leadProviderName: item.lead_provider.company_name,
          campaignId: item.campaign,
          campaignName: item.campaign_obj.campaign_name,
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
      });
    },
  },

  validations: {
    fromDate: { required },
    toDate: { required },
  },
};
</script>

<style></style>
