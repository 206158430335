<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Fraud Claim
        </v-card-title>
        <v-card-text
          class="ma-5 py-5"
        >
          <p class="muted text-advices">
            Click the action below to fill fraud claim questionnaire
          </p>
          <v-row
            v-if="Object.keys(ticket).length > 0"
            class="my-5"
          >
            <template
              v-if="['Pending - Fraud - Identity Theft', 'Fraud - Identity Theft'].includes(
                ticket.active_loan.current_status.loan_sub_status_text) === false
              "
            >
              <fraud-doc-un-signed
                v-if="fraudItemToReview.lp_loan_doc === null"
                :customer="ticket.lpCustomer"
                :ticket-id="Number(ticket.zendesk.id)"
              />
              <fraud-before-funding
                v-if="ticket.active_loan.current_status.loan_status_text === 'Loan Documents'
                  && ticket.active_loan.current_status.loan_sub_status_text === 'Loan Docs Signed'"
                :customer="ticket.lpCustomer"
                :ticket-id="Number(ticket.zendesk.id)"
                :loan-id="ticket.active_loan.id"
                :display-id="ticket.active_loan.display_id"
              />
              <fraud-after-funding
                v-if="ticket.active_loan.current_status.loan_status_text === 'Charge Off'
                  && ticket.active_loan.current_status.loan_sub_status_text === 'Sold - NCA'"
                :loan-id="ticket.active_loan.id"
                :customer="ticket.lpCustomer"
                :ticket-id="Number(ticket.zendesk.id)"
                :options="{ncaCharge: true}"
                :lead-guid="ticket.active_loan.lead_guid"
                title="Nca charge off fraud"
                disabled="true"
              />
              <fraud-after-funding
                v-if="ticket.active_loan.current_status.loan_status_text !== 'Loan Documents'
                  && ticket.active_loan.current_status.loan_sub_status_text !== 'Sold - NCA'"
                :loan-id="ticket.active_loan.id"
                :customer="ticket.lpCustomer"
                :ticket-id="Number(ticket.zendesk.id)"
                :options="{ncaCharge: false}"
                :lead-guid="ticket.active_loan.lead_guid"
                title="Fraud After Funding"
              />
            </template>
            <v-alert
              v-else
              type="info"
            >
              Loan status is now in collections. Ticket has been assigned, fraud claim completed
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style>
.fraud-claim-dialog-btn {
  padding: 2rem 1rem !important;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import FraudBeforeFunding from './FraudBeforeFunding.vue';
import FraudAfterFunding from './FraudAfterFunding.vue';
import FraudDocUnSigned from './FraudDocUnSigned.vue';

export default {
  name: 'FraudClaim',
  components: {
    FraudBeforeFunding,
    FraudAfterFunding,
    FraudDocUnSigned,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemsToReview: {},
      isLoanFunded: false,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['fraudItemToReview']),
  },
  watch: {
    async ticket() {
      await this.getFraudItemsToReview();
    },
  },
  async created() {
    if (Object.keys(this.ticket).length > 0) {
      await this.getFraudItemsToReview();
    }
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProLoan', ['getFraudItemsToReview']),
  },
};
</script>
