<template>
  <div>
    <v-card :loading="loading">
      <div class="d-flex align-center justify-space-between">
        <v-card-title> Statements Report </v-card-title>
      </div>
      <v-card-text>
        <template v-if="loanType === 'Line of Credit'">
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.period_start="{ item }">
              {{ formatDate(item.period_start) }}
            </template>

            <template v-slot:item.period_end="{ item }">
              {{ formatDate(item.period_end) }}
            </template>

            <template v-slot:item.change="{ item }">
              {{ formatAmount(item.change) }}
            </template>

            <template v-slot:item.adjust="{ item }">
              {{ formatAmount(item.adjust) }}
            </template>

            <template v-slot:item.viewed="{ item }">
              {{ item.logs.length > 0 ? 'Yes' : 'No' }}
            </template>

            <template v-slot:item.revised="{ item }">
              {{ item.revision ? 'Yes' : 'No' }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                tile
                color="primary"
                small
                text
                @click="showJsonModal('JSON', item)"
              >
                <v-icon
                  dark
                  left
                  class="mr-1"
                >
                  mdi-eye
                </v-icon>
                SHOW
              </v-btn>

              <v-btn
                tile
                color="success"
                small
                text
                @click="downloadPDF(item)"
              >
                <v-icon
                  dark
                  left
                  class="mr-1"
                >
                  mdi-download
                </v-icon>
                PDF
              </v-btn>

              <v-btn
                v-if="item.logs.length > 0 && !item.revision"
                tile
                color="primary"
                small
                text
                @click="confirmRevision(item)"
              >
                <v-icon
                  dark
                  left
                  class="mr-1"
                >
                  mdi-marker
                </v-icon>
                REVISE
              </v-btn>
            </template>
          </v-data-table>
        </template>
        <template v-if="loanType === 'INSTALLMENT'">
          <v-alert
            type="warning"
          >
            Statement is not available for loan type Installment
          </v-alert>
        </template>
      </v-card-text>
    </v-card>
    <JsonModal
      v-model="jsonModal"
      :title="jsonTitle"
      :json="json"
      @close-modal="closeJsonModal()"
      @click:outside="closeJsonModal()"
    />
    <v-dialog
      v-model="confirmModal"
      max-width="500px"
      @click:outside="confirmModal = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Are you sure?</span>
        </v-card-title>

        <v-card-text>
          <p>
            Clicking confirm will mark the statement as revised.
          </p>
          <v-row>
            <v-col>
              <v-textarea
                v-model="notes"
                outlined
                label="Notes"
                placeholder="Please explain why this statement is being revised."
                rows="3"
                no-resize
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="reviseStatement()"
          >
            Confirm
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="confirmModal = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'vuex';
import JsonModal from '@/components/JsonModal.vue';

export default {
  name: 'StatementsReport',
  components: {
    JsonModal,
  },
  data() {
    return {
      confirmModal: false,
      loading: false,
      json: {},
      jsonTitle: '',
      jsonModal: false,
      headers: [
        { text: 'Loan ID', value: 'loan_id' },
        { text: 'Period Start', value: 'period_start' },
        { text: 'Period End', value: 'period_end' },
        { text: 'Change', value: 'change' },
        { text: 'Adjust', value: 'adjust' },
        { text: 'Viewed By Customer', value: 'viewed' },
        { text: 'Revised', value: 'revised' },
        { text: '', value: 'actions' },
      ],
      items: [],
      selectedItem: null,
      notes: '',
      loanType: null,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['activeLoan']),
  },
  watch: {
    activeLoan() {
      this.generateReport();
    },
  },
  created() {
    this.generateReport();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    generateReport() {
      this.loanType = this.getLoanType();
      if (this.loanType !== 'Line of Credit') {
        return;
      }
      const vm = this;
      this.loading = true;
      this.$axios
        .get('api/v1/reports/statements/', {
          params: {
            loanId: this.activeLoan.id,
          },
        })
        .then((res) => {
          vm.items = Object.values(res.data);
          vm.loading = false;
        })
        .then(() => {
          vm.loading = false;
        });
    },
    formatDate(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_MED);
    },
    formatAmount(number) {
      const amount = parseFloat(number).toFixed(2);

      if (amount === '0.00') {
        return '-';
      }

      if (amount < 0) {
        return `-$${Math.abs(amount)}`;
      }

      return `$${amount}`;
    },
    closeJsonModal() {
      this.jsonModal = false;
      this.$nextTick(() => {
        this.json = {};
      });
    },
    showJsonModal(title, json) {
      this.json = json;
      this.jsonTitle = title;
      this.jsonModal = true;
    },
    downloadPDF(statement) {
      this.$axios
        .get(`api/v1/reports/statements/${statement.id}`, { responseType: 'blob' })
        .then((res) => {
          const date = DateTime.fromISO(statement.period_end).toUTC().toFormat('yyyy-MM-dd');
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `statement-${statement.loan_id}-${statement.id}-${date}.pdf`;
          link.click();
          link.remove();

          this.setNotification({
            message: 'Statement pdf downloaded',
            color: 'green',
          });
        })
        .catch(async (error) => {
          const responseObj = await error.response.data.text();

          const jsonResponse = JSON.parse(responseObj);
          this.setNotification({
            message: `PDF could not be retrieved.If error persist please contact Dev Department. Details: ${jsonResponse.error}`,
            color: 'red',
          });
        });
    },
    confirmRevision(statement) {
      this.selectedItem = statement;
      this.confirmModal = true;
    },
    reviseStatement() {
      const statement = this.selectedItem;
      this.$axios
        .put(`api/v1/reports/statements/${statement.id}`, {
          notes: this.notes,
        })
        .then(() => {
          this.confirmModal = false;
          this.generateReport();
        });
    },
    getLoanType() {
      const loanType = (this.activeLoan.loan_setup.loan_type).split('.')[2];
      return loanType === 'creditLimit' ? 'Line of Credit' : loanType.toUpperCase();
    },
  },
};
</script>
