<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-2"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Customers</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search By Customer Info and Loan Display #"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <!--  -->
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        v-if="hasPermission('view loan-pro-customers::profile')"
        x-small
        class="mr-2 btn-bg-deep-blue"
        :to="{name: 'loanpro-customer-profile', params:{customer_id: item.id}}"
      >
        <v-icon
          left
          dark
        >
          mdi-account-outline
        </v-icon>
        View
      </v-btn>
      <disabled-for-lack-of-role-btn
        v-else
        text="View"
        permission="view loan-pro-customers::profile"
        icon="mdi-account"
        button-class="mr-2"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'LoanProCustomersIndex',

  components: {
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Custom ID/GUID', value: 'custom_id' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },

  methods: {
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/loanpro-customer', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
  },

};
</script>

<style>

</style>
