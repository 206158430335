<template>
  <div class="text-center">
    <v-menu
      top
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          outlined
          small
          class="px-0"
          v-on="on"
        >
          <v-icon class="mx-0 px-0">
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-if="ticket.active_loan.loan_setup.loan_type === 'loan.type.creditLimit'"
        >
          <advances-modal />
        </v-list-item>
        <v-list-item>
          <payment-schedule-modal
            :loan-id="loanId"
            :loan-type="loanType"
          />
        </v-list-item>
        <v-list-item>
          <payments-made
            :loan-id="loanId"
          />
        </v-list-item>
      </v-list>
      <!--      We can add more menu option below-->
    </v-menu>
  </div>
</template>

<script>
import PaymentScheduleModal from './PaymentScheduleModal.vue';
import PaymentsMade from './PaymentsMade.vue';
import AdvancesModal from './AdvancesModal.vue';

export default {
  name: 'LoanActionMenu',
  components: {
    PaymentScheduleModal,
    PaymentsMade,
    AdvancesModal,
  },
  props: {
    loanId: {
      type: Number,
      required: false,
      default: null,
    },
    loanType: {
      type: String,
      required: false,
      default: 'installment',
    },
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      paymentSchedules: [],
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Principal Balance', value: 'principal_balance' },
        { text: 'charge amount', value: 'charge_amount' },
        { text: 'adb', value: 'adb' },
        { text: 'adb days', value: 'adb_days' },
        { text: 'Period', value: 'period' },
        { text: 'Period Start', value: 'period_start' },
        { text: 'Period End', value: 'period_end' },
        { text: 'Date', value: 'date' },
        { text: 'Last updated', value: 'lastUpdated' },
      ],
    };
  },
  created() {
    this.$axios
      .post('api/v1/support/ticket/loan/payment-schedule', {
        loanId: this.loanId,
        ticketId: this.$route.params.id,
      })
      .then((payScheduleRes) => {
        this.paymentSchedules = payScheduleRes.data;
      })
      .catch();
  },
};
</script>
