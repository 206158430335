<template>
  <v-container fluid>
    <h3>Clarity Data</h3>
    <v-row>
      <v-col
        v-for="(method, index) in clarityData"
        :key="`method_${index}`"
        cols="12"
        sm="6"
      >
        <h4>{{ index }} </h4>
        <v-row>
          <v-col
            cols="6"
          >
            <v-select
              v-model="method.result"
              label="Result"
              :items="['pass', 'fail']"
            />
          </v-col>

          <v-col
            cols="6"
          >
            <v-text-field
              v-model="method.message"
              label="Message"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'WaterfallTestClarityDataForm',
  props: {
    clarityData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      booleanOptions: [
        {
          value: 'true',
          text: 'True',
        },
        {
          value: '',
          text: 'False',
        },
      ],
      clearFraud: {},
    };
  },
  created() {
    this.clearFraud = this.clarityData.clear_fraud;
  },
};
</script>
