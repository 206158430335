<template>
  <v-dialog
    v-model="show"
    scrollable
    @click:outside="close()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Extended Summary</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <h2
                class="mb-2"
              >
                JSON Payload
              </h2>
              <v-card-text>
                <JsonFormatterWrapper :data="jsonPayload" />
              </v-card-text>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <h2
                class="mb-2"
              >
                Agreed Loan Data
              </h2>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        colspan="2"
                      >
                        LMS Loan Setup
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(entry, key) in amortizationSetup"
                      :key="key"
                    >
                      <td>{{ key }}</td>
                      <td>{{ entry }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col
              cols="6"
              md="6"
            >
              <h2
                class="mb-2"
              >
                Current Loan Data
              </h2>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        colspan="2"
                      >
                        LOANPRO Loan Setup
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(entry, key) in loanproSetup"
                      :key="key"
                    >
                      <td>{{ key }}</td>
                      <td>{{ entry }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        colspan="2"
                      >
                        LMS Payment Schedules
                      </th>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                      >
                        Date
                      </th>
                      <th
                        class="text-left"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(entry, key) in amortizationSchedules"
                      :key="key"
                    >
                      <td>{{ formatDate(entry.paymentTime) }}</td>
                      <td>{{ entry.paymentAmount }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col
              cols="6"
              md="6"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        colspan="2"
                      >
                        LOANPRO Payment Schedules
                      </th>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                      >
                        Date
                      </th>
                      <th
                        class="text-left"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(entry, key) in loanproSchedules"
                      :key="key"
                    >
                      <td>{{ formatDate(entry.paymentTime) }}</td>
                      <td>{{ entry.paymentAmount }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import JsonFormatterWrapper from '../JsonFormatterWrapper.vue';

export default {
  name: 'LeadAmortizationExtendedSummaryModal',
  components: {
    JsonFormatterWrapper,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
        };
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    jsonPayload() {
      return this.item.json_payload ? JSON.parse(this.item.json_payload) : {};
    },
    amortizationSetup() {
      return this.item.amortization_setup ? JSON.parse(this.item.amortization_setup) : {};
    },
    amortizationSchedules() {
      return this.item.amortization_schedules ? JSON.parse(this.item.amortization_schedules) : [];
    },
    loanproSetup() {
      return this.item.loanpro_setup ? JSON.parse(this.item.loanpro_setup) : {};
    },
    loanproSchedules() {
      return this.item.loanpro_schedules ? JSON.parse(this.item.loanpro_schedules) : [];
    },
  },
  methods: {
    close() {
      this.$emit('close-modal');
    },
    formatDate(date) {
      return DateTime.fromSeconds(date).toUTC().toFormat('yyyy-MM-dd');
    },
  },
};
</script>

<style></style>
