<template>
  <div class="text-center">
    <template
      v-if="bankruptcyButton"
    >
      <v-btn
        small
        color="error"
        :disabled="formProcessing"
        @click="showConfirmButtons"
      >
        Bankruptcy
      </v-btn>
    </template>

    <template
      v-if="confirmButtons"
    >
      <p class="mb-1">
        Are you sure?
      </p>
      <v-btn
        small
        color="secondary"
        class="mr-2"
        :disabled="formProcessing"
        @click="showBankruptcyButton"
      >
        Cancel
      </v-btn>
      <v-btn
        small
        color="primary"
        :disabled="formProcessing"
        @click="confirmBankruptcy"
      >
        Confirm
      </v-btn>
    </template>

    <template
      v-if="processingMessage"
    >
      <p>Processing...</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LoanProCustomersLoansConfirmBankruptcyButtons',

  props: {
    loanId: {
      type: Number,
      default: null,
    },
    formProcessing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      bankruptcyButton: true,
      confirmButtons: false,
      processingMessage: false,
    };
  },

  methods: {
    confirmBankruptcy() {
      this.showProcessingMessage();
      this.$emit('declare-bankruptcy', this.loanId);
    },
    showConfirmButtons() {
      this.bankruptcyButton = false;
      this.confirmButtons = true;
      this.processingMessage = false;
    },
    showBankruptcyButton() {
      this.bankruptcyButton = true;
      this.confirmButtons = false;
      this.processingMessage = false;
    },
    showProcessingMessage() {
      this.bankruptcyButton = false;
      this.confirmButtons = false;
      this.processingMessage = true;
    },
  },
};
</script>

<style>

</style>
