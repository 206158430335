<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Reschedule A Payment
        </v-card-title>
        <v-card-text>
          <template>
            <v-data-table
              :headers="headers"
              :items="filterAutopays"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:item.date="{ item }">
                {{ formatDate(item.apply_date) }}
              </template>
              <template v-slot:item.process_datetime="{ item }">
                {{ formatDate(item.process_datetime) }}
              </template>
              <template v-slot:item.charge_amount="{ item }">
                ${{ item.amount }}
              </template>
              <template v-slot:item.status="{ item }">
                {{ (item.status).split('.')[2] }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="hasPermission('view ticket::reschedule-payment')"
                  x-small
                  class="mr-2"
                  @click="selectedPayment = item;
                          setPaymentDate(selectedPayment.apply_date);
                          dateModal = true"
                >
                  <v-icon
                    left
                    dark
                  >
                    mdi-calendar-arrow-right
                  </v-icon>
                  Reschedule
                </v-btn>
                <disabled-for-lack-of-role-btn
                  v-else
                  text="Reschedule"
                  icon="mdi-calendar-arrow-right"
                  permission="view ticket::reschedule-payment"
                  :x-small="true"
                  button-class="mr-2"
                />
              </template>
            </v-data-table>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingModel"
            :to="{ name: 'ticket-show', params: { id: $route.params.id } }"
          >
            Cancel
          </v-btn>
        </v-card-actions>

        <v-dialog
          v-if="selectedPayment"
          v-model="dateModal"
          max-width="500px"
          @click:outside="dateModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Select New Payment Date</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  class="mt-3"
                >
                  <v-text-field
                    v-model="paymentDate"
                    label="Payment Date"
                    :max="nextPaymentDate"
                    :min="minPaymentDate"
                    type="date"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                v-if="hasPermission('view ticket::reschedule-payment')"
                color="blue darken-1"
                text
                @click="dateModal = false; confirmModal = true"
              >
                Reschedule
              </v-btn>
              <disabled-for-lack-of-role-btn
                v-else
                text="Reschedule"
                permission="view ticket::reschedule-payment"
                :x-small="false"
                :text-button="true"
              />

              <v-btn
                color="blue darken-1"
                text
                @click="dateModal = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-if="selectedPayment"
          v-model="confirmModal"
          max-width="500px"
          @click:outside="confirmModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Are you sure?</span>
            </v-card-title>

            <v-card-text>
              Clicking confirm will reschedule the following payment:
              <v-list dense>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Original Payment Date</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatDate(selectedPayment.apply_date) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>New Payment Date</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatDate(paymentDate) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Amount</v-list-item-title>
                    <v-list-item-subtitle>
                      ${{ selectedPayment.amount }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                v-if="hasPermission('create ticket::reschedule-payment')"
                color="blue darken-1"
                text
                @click="reschedulePayment(selectedPayment)"
              >
                Confirm
              </v-btn>
              <disabled-for-lack-of-role-btn
                v-else
                text="Reschedule"
                permission="create ticket::reschedule-payment"
                :x-small="false"
                :text-button="true"
              />

              <v-btn
                color="blue darken-1"
                text
                @click="confirmModal = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="paymentModal"
          max-width="500px"
          @click:outside="paymentModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Payment Rescheduled</span>
            </v-card-title>

            <v-card-text>
              <v-list dense>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Autopay ID</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentData.id }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Autopay Name</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentData.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Payment Date</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatDate(paymentData.date) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Payment Amount</v-list-item-title>
                    <v-list-item-subtitle>
                      ${{ paymentData.amount }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Additional Payment</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentData.additional_payment ? 'Yes' : 'No' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <p>Would you like to perform another task or mark this ticket as solved?</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                color="blue darken-1"
                text
                @click="anotherTask()"
              >
                Another Task
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="resolveTicket()"
              >
                Resolve Ticket
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'vuex';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketReschedulePayment',
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    paymentSchedules: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dateModal: false,
      confirmModal: false,
      loadingModel: false,
      paymentModal: false,
      paymentProfile: null,
      paymentProfiles: [],
      selectedPayment: null,
      paymentData: {},
      paymentDate: DateTime.local().toISODate(),
      nextPaymentDate: DateTime.local().toISODate(),
      minPaymentDate: DateTime.local().toISODate(),
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Apply Date', value: 'apply_date', sortable: false },
        { text: 'Process Date', value: 'process_datetime', sortable: false },
        { text: 'Scheduled Amount', value: 'charge_amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState('loanProLoan', ['autopays']),
    filterAutopays() {
      let nAutopays = this.autopays;
      const loanPaymentFrequency = this.ticket?.active_loan?.loan_setup?.payment_frequency || null;
      if (loanPaymentFrequency && (loanPaymentFrequency).split('.')[2] === 'semiMonthly') {
        // sort array so that the earliest apply_date stays at the bottom
        nAutopays.sort((a, b) => new Date(b.apply_date) - new Date(a.apply_date));
        const indexToDel = nAutopays.findIndex((autopay) => autopay.name === 'Scheduled Installment' && autopay.recurring_frequency === 'autopay.recurringFrequency.monthly');
        delete nAutopays[indexToDel];
      }
      // filter autopays where status is failed or pending
      nAutopays = nAutopays.filter((autopay) => ['pending'].includes(autopay.status.split('.')[2]));
      // sort array by earliest apply_date
      nAutopays.sort((a, b) => new Date(a.apply_date) - new Date(b.apply_date));
      return nAutopays.length === 0 ? nAutopays : [nAutopays[0]];
    },
    paymentDateErrors() {
      const errors = [];

      return errors;
    },
  },
  async created() {
    if (this.ticket?.active_loan || null) {
      await this.getAutopays();
    }
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProLoan', ['getAutopays']),

    reschedulePayment(payment) {
      this.confirmModal = false;
      this.loadingModel = true;

      this.$axios
        .post(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment/reschedule/${payment.id}`, {
          ticketId: this.$route.params.id,
          paymentDate: this.paymentDate,
        })
        .then((res) => {
          if (res.data.success) {
            this.paymentModal = true;
            this.loadingModel = false;
            this.paymentData = res.data;
            this.setNotification({
              message: res.data.message,
              color: 'green',
            });
          } else {
            this.setNotification({
              message: res.data.message,
              color: 'red',
            });
          }
        })
        .catch((error) => {
          let msg = error.response.data.message;

          if (error.response.data.errors) {
            Object.values(error.response.data.errors).forEach((value) => {
              msg += `${value[0]}\n`;
            });
          }

          this.setNotification({
            message: msg,
            color: 'red',
          });
        });
    },
    anotherTask() {
      this.paymentModal = false;
      this.$router.push({
        name: 'ticket-show',
        params: {
          id: this.$route.params.id,
        },
      });
    },
    resolveTicket() {
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.loadingModel = false;

          this.$router.push({
            name: 'tickets',
          });
        });
    },
    formatDate(date) {
      if (DateTime.fromSQL(date).isValid) {
        return DateTime.fromSQL(date).toFormat('MM-dd-yyyy');
      }

      return date;
    },
    setPaymentDate() {
      // set payment date
      // this.paymentDate = DateTime.fromSQL(date).toFormat('yyyy-MM-dd');
      // this.minPaymentDate = DateTime.fromSQL(date).toFormat('yyyy-MM-dd');
      this.paymentDate = DateTime.local().toFormat('yyyy-MM-dd');
      this.minPaymentDate = DateTime.local().toFormat('yyyy-MM-dd');
      // next payment date
      this.nextPaymentDate = DateTime.fromSQL(this.ticket.active_loan.current_status.next_two_payment_date).toFormat('yyyy-MM-dd');
    },
  },
};
</script>
