<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    @click:outside="close()"
  >
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">LendingTree Product Model</span>
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>Min Score</th>
              <th>Max Score</th>
              <th>Rate</th>
              <th>Max Loan Amount</th>
              <th>Loan Term</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in items"
              :key="index"
            >
              <td
                :class="itemClass(index)"
              >
                <v-text-field
                  v-model="row.min_vantage_score"
                  type="number"
                  :error-messages="rowErrorMessages[index].min_vantage_score"
                  @input="rowErrorMessages[index].min_vantage_score = null"
                />
              </td>
              <td
                :class="itemClass(index)"
              >
                <v-text-field
                  v-model="row.max_vantage_score"
                  type="number"
                  :error-messages="rowErrorMessages[index].max_vantage_score"
                  @input="rowErrorMessages[index].max_vantage_score = null"
                />
              </td>
              <td
                :class="itemClass(index)"
              >
                <v-text-field
                  v-model="row.rate"
                  type="number"
                  :error-messages="rowErrorMessages[index].rate"
                  @input="rowErrorMessages[index].rate = null"
                />
              </td>
              <td
                :class="itemClass(index)"
              >
                <v-text-field
                  v-model="row.max_loan_amount"
                  type="number"
                  :error-messages="rowErrorMessages[index].max_loan_amount"
                  @input="rowErrorMessages[index].max_loan_amount = null"
                />
              </td>
              <td
                :class="itemClass(index)"
              >
                <v-text-field
                  v-model="row.loan_term"
                  type="number"
                  :error-messages="rowErrorMessages[index].loan_term"
                  @input="rowErrorMessages[index].loan_term = null"
                />
              </td>
              <td
                :class="itemClass(index)"
              >
                <v-btn
                  color="red"
                  icon
                  tile
                  elevation="2"
                  @click="removeRow(index)"
                >
                  <v-icon>
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </td>
              <td
                :class="itemClass(index)"
              >
                <v-btn
                  color="green"
                  icon
                  tile
                  elevation="2"
                  @click="saveRow(index)"
                >
                  <v-icon>
                    mdi-check
                  </v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <v-btn
                  color="primary"
                  block
                  @click="addRow()"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  Add New Row
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-dialog
          v-model="confirmModal"
          max-width="300px"
          @click:outside="closeConfirm"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Are you sure?</span>
            </v-card-title>

            <v-card-text
              class="mt-6"
            >
              Clicking "Delete" will delete this row from the LendingTree Product Model.
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="blue darken-1"
                text
                @click="closeConfirm"
              >
                Cancel
              </v-btn>

              <v-spacer />

              <v-btn
                color="blue darken-1"
                text
                @click="deleteRow()"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>

.newItemRow {
  background-color: #ffffdd;
}

</style>

<script>

import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      items: [],
      deletedItems: [],
      rowErrorMessages: [],
      confirmModal: false,
      confirmIndex: null,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  created() {
    this.fetchProductModels();
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    itemClass(index) {
      if (typeof (this.items[index]).id === 'undefined') {
        return 'newItemRow';
      }

      return '';
    },

    removeRow(index) {
      if (this.items[index].id) {
        // if this row is a saved item then we want to confirm
        this.confirmIndex = index;
        this.confirmModal = true;
      } else { // if not just remove the element from the dom
        this.items.splice(index, 1);
        this.rowErrorMessages.splice(index, 1);
      }
    },

    closeConfirm() {
      this.confirmIndex = null;
      this.confirmModal = false;
    },

    deleteRow() {
      const index = this.confirmIndex;
      this.$axios
        .delete(`/api/v1/lendingtree/product-model/${this.items[index].id}`)
        .then(() => {
          this.fetchProductModels();

          return {
            message: 'Data has been deleted.',
            color: 'green',
          };
        })
        .catch((error) => {
          let message = 'There was an error processing your request.';
          let color = 'red';

          if (error.response && error.response.status === 422) {
            color = 'red';

            const errorData = error.response.data.errors;

            if (errorData) {
              message = 'Please fix the errors';
              const errorKeys = Object.keys(errorData);

              errorKeys.forEach((key) => {
                this.rowErrorMessages[index][key] = errorData[key].pop();
              });
            }
          }

          return {
            message,
            color,
          };
        })
        .then((notification) => {
          this.loading = false;

          this.setNotification(notification);
        });

      this.closeConfirm();
    },

    addRow() {
      this.items.push({
        min_vantage_score: null,
        max_vantage_score: null,
        rate: null,
        max_loan_amount: null,
        loan_term: null,
      });

      this.rowErrorMessages.push({
        min_vantage_score: null,
        max_vantage_score: null,
        rate: null,
        max_loan_amount: null,
        loan_term: null,
      });
    },

    fetchProductModels(newIndex = undefined) {
      this.$axios.get('/api/v1/lendingtree/product-model')
        .then((response) => {
          const oldItems = this.items;
          this.items = response.data;

          oldItems.forEach((item, idx) => {
            if (typeof (item.id) === 'undefined' && newIndex !== idx) {
              // keep any new (and potentially not yet saved) rows in the table
              this.items.push(item);
            }
          });

          this.items.forEach(() => {
            this.rowErrorMessages.push({
              min_vantage_score: null,
              max_vantage_score: null,
              rate: null,
              max_loan_amount: null,
              loan_term: null,
            });
          });
        });
    },

    updateRow(index) {
      const item = { ...this.items[index] };

      this.$axios
        .put(`/api/v1/lendingtree/product-model/${this.items[index].id}`, {
          ...item,
        })
        .then(() => {
          this.fetchProductModels();

          return {
            message: 'Data has been updated.',
            color: 'green',
          };
        })
        .catch((error) => {
          let message = 'There was an error processing your request.';
          let color = 'red';

          if (error.response && error.response.status === 422) {
            color = 'red';

            const errorData = error.response.data.errors;

            if (errorData) {
              message = 'Please fix the errors';
              const errorKeys = Object.keys(errorData);

              errorKeys.forEach((key) => {
                this.rowErrorMessages[index][key] = errorData[key].pop();
              });
            }
          }

          return {
            message,
            color,
          };
        })
        .then((notification) => {
          this.loading = false;

          this.setNotification(notification);
        });
    },

    saveRow(index) {
      let item = { };

      if (this.items[index].id) {
        this.updateRow(index);
        return;
      }

      item = { ...this.items[index] };

      this.loading = true;

      this.$axios
        .post('/api/v1/lendingtree/product-model', {
          ...item,
        })
        .then(() => {
          // pass the row index into fetchProductModels so it knows which row is the newly added one
          this.fetchProductModels(index);

          return {
            message: 'Data has been saved.',
            color: 'green',
          };
        })
        .catch((error) => {
          let message = 'There was an error processing your request.';
          let color = 'red';

          if (error.response && error.response.status === 422) {
            color = 'red';

            const errorData = error.response.data.errors;

            if (errorData) {
              message = 'Please fix the errors';
              const errorKeys = Object.keys(errorData);

              errorKeys.forEach((key) => {
                this.rowErrorMessages[index][key] = errorData[key].pop();
              });
            }
          }

          return {
            message,
            color,
          };
        })
        .then((notification) => {
          this.loading = false;

          this.setNotification(notification);
        });
    },

    close() {
      this.fetchProductModels();
      this.$emit('close-modal');
    },
  },
};
</script>
