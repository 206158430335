<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Ticket Bank Update Funding
        </v-card-title>
        <v-card-text>
          <v-card
            class="mt-4"
          >
            <v-card-text>
              <reusable-communication-by-email-template
                v-if="hasPermission('create ticket::bank-update-funding')"
                :customer="ticket.lpCustomer"
                :ticket-id="ticket.zendesk.id * 1"
                title="Bank Update for Funding"
                action="bank-update-funding"
                tag="communication_bank_update_funding"
                :extra="{}"
                @success="ticketSuccessfulActionDialog = true"
              />
              <disabled-for-lack-of-role-btn
                v-else
                text="Bank Update for Funding"
                permission="create ticket::bank-update-funding"
                :x-small="false"
                :x-large="true"
                :block="true"
                button-class="fraud-claim-dialog-btn"
              />
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <ticket-successful-action
      :ticket-successful-action-dialog="ticketSuccessfulActionDialog"
      :ticket-id="$route.params.id"
      :title="title"
      @closed="ticketSuccessfulActionDialog = false"
    />
  </v-row>
</template>

<script>
import ReusableCommunicationByEmailTemplate from './Communications/ReusableCommunicationByEmailTemplate.vue';
import TicketSuccessfulAction from './TicketSuccessfulAction.vue';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketBankUpdateFunding',
  components: {
    ReusableCommunicationByEmailTemplate,
    TicketSuccessfulAction,
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ticketSuccessfulActionDialog: false,
      title: 'Bank Update for Funding Macro was sent successfully.',
    };
  },
};
</script>
