<template>
  <card-layout>
    <template v-slot:title>
      Profile Information
    </template>
    <template v-slot:description>
      Update your accounts profile information
    </template>

    <template v-slot:form>
      <v-form ref="form">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="userCopy.first_name"
              :rules="nameRules"
              :counter="10"
              label="First name"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="userCopy.last_name"
              :rules="nameRules"
              :counter="10"
              label="Last name"
              required
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:button>
      <v-btn
        absolute
        right
        dark
        @click="updateProfile()"
      >
        Save
      </v-btn>
    </template>
  </card-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CardLayout from './CardLayout.vue';

export default {
  components: {
    CardLayout,
  },
  data: () => ({
    valid: false,
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => v.length <= 20 || 'Name must be less than 20 characters',
      (v) => v.length >= 2 || 'Name must be more than 1 character',
    ],
    userCopy: {},
  }),
  computed: {
    ...mapState('currentUser', ['user']),
  },
  beforeMount() {
    // creating a copy of the current user Vuex store
    // this will prevent unintentionally changing the
    // state of the current user name
    Object.assign(this.userCopy, this.user);
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    async updateProfile() {
      if (this.$refs.form.validate()) {
        await this.$axios.put(`api/v1/user/${this.userCopy.id}`, {
          firstName: this.userCopy.first_name,
          lastName: this.userCopy.last_name,
        })
          .then((response) => {
            if (response.status === 200) {
              this.setNotification({
                message: 'Profile Information Updated',
                color: 'green',
              });
            }
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          });
      }
    },
  },
};
</script>
