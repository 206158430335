<template>
  <div>
    <h1>Sub Classification</h1>

    <v-row>
      <v-col
        v-if="addSubClassification"
        cols="12"
        sm="12"
      >
        <OpsTicketSubClassificationAddEdit
          :active-sub-classification="activeSubClassification"
          @sub-classification-list="showSubClassificationList"
        />
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="12"
      >
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          calculate-widths
          class="elevation-2 mt-5"
          :footer-props.sync="footerProps"
          @update:options="updateTable()"
        >
          <template v-slot:top>
            <v-card-title>
              <v-btn
                depressed
                color="primary"
                @click="toggleSubClassification()"
              >
                Add New Sub Classification
              </v-btn>
            </v-card-title>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              x-small
              class="mr-2 btn-bg-deep-blue"
              @click="editSubClassification(item)"
            >
              <v-icon
                left
                dark
              >
                mdi-account-outline
              </v-icon>
              Edit
            </v-btn>
            <v-btn
              :loading="deletingSubClassification"
              x-small
              class="mr-2"
              color="error"
              @click="deleteSubClassification(item)"
            >
              <v-icon
                left
                dark
              >
                mdi-trash-can-outline
              </v-icon>
              Delete
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import OpsTicketSubClassificationAddEdit from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketSubClassificationAddEdit.vue';
import { mapActions } from 'vuex';

export default {
  name: 'OpsTicketSubClassificationDatatable',
  components: { OpsTicketSubClassificationAddEdit },

  mixins: [dataTableMixin],

  data: () => ({
    headers: [
      { text: 'ID', value: 'id', width: '5%' },
      { text: 'Name', value: 'name', width: '15%' },
      { text: 'Description', value: 'description', width: '50%' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '30%',
      },
    ],
    deletingSubClassification: false,
    addSubClassification: false,
    activeSubClassification: null,
    loading: false,
  }),

  methods: {
    ...mapActions('notification', ['setNotification']),
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/ops-tickets/sub-classification', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
            paginate: true,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data?.data || [];

          this.totalItems = res.data?.meta.total || 0;
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    deleteSubClassification(item) {
      this.deletingSubClassification = true;

      return this.$axios.delete(`api/v1/ops-tickets/sub-classification/${item.id}`).then(() => {
        this.deletingSubClassification = false;

        this.updateTable();
      })
        .catch((error) => {
          this.deletingSubClassification = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    toggleSubClassification() {
      this.addSubClassification = true;
    },

    editSubClassification(item) {
      this.addSubClassification = true;
      this.activeSubClassification = item;
    },

    showSubClassificationList() {
      this.addSubClassification = false;
    },
  },
};
</script>
