<template>
  <v-app>
    <maintenance v-if="settings.inMaintenance" />
    <v-dialog
      v-else-if="loading"
      v-model="loading"
      persistent
      fullscreen
      content-class="loading-dialog"
    >
      <v-container
        fill-height
      >
        <v-layout
          row
          justify-center
          align-center
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="#2b3243"
            indeterminate
          />
        </v-layout>
      </v-container>
    </v-dialog>
    <no-settings
      v-else-if="!brandSettings && !loading"
    />
    <v-slide-x-reverse-transition v-else>
      <router-view />
    </v-slide-x-reverse-transition>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Maintenance from './views/Maintenance.vue';
import NoSettings from './views/NoSettings.vue';

export default {
  name: 'App',
  components: {
    Maintenance,
    NoSettings,
  },
  data() {
    return {
      loading: false,
      jiraRoutesAllowed: ['tickets'],
    };
  },

  computed: {
    ...mapState('app', ['env', 'settings']),
    ...mapState('settings', ['settings']),
    brandSettings() {
      return Object.keys(this.$store.state.settings.settings).length;
    },
    viewJiraWidgetSettings() {
      return this.settings?.brand?.view_jira_widget || false;
    },
  },

  watch: {
    $route(to) {
      if (this.showJiraWidget(to)) {
        this.registerJiraWidget();
      } else {
        this.removeJiraWidget();
      }
    },
  },

  async created() {
    this.loading = true;
    await this.getSystemSettings();
    await this.getSettings();
    if (this.isLoggedIn && !this.settings.inMaintenance) {
      await this.getUser();
    }
    this.loading = false;
    if (this.showJiraWidget(this.$route)) {
      this.registerJiraWidget();
    }
  },

  methods: {
    ...mapActions('app', ['getSystemSettings']),
    ...mapActions('currentUser', ['getUser']),
    ...mapActions('settings', ['getSettings']),

    registerJiraWidget() {
      const scriptElement = document.getElementById('jira-widget-script');
      if (typeof scriptElement === 'undefined' || scriptElement === null) {
        this.initJiraWidgetElement(() => {
          const domContentLoadedEvent = document.createEvent('Event');
          domContentLoadedEvent.initEvent('DOMContentLoaded', true, true);
          window.document.dispatchEvent(domContentLoadedEvent);
        });
      } else {
        const iframe = document.querySelector('#jsd-widget');
        iframe.style.display = 'block';
      }
    },

    removeJiraWidget() {
      const iframe = document.querySelector('#jsd-widget');
      if (iframe) {
        iframe.style.display = 'none';
      }
    },

    initJiraWidgetElement(callback) {
      const scriptTag = document.createElement('script');

      scriptTag.type = 'text/javascript';
      scriptTag.setAttribute('data-jsd-embedded', null);
      scriptTag.setAttribute('data-key', this.settings.brand.view_jira_widget_data_key);
      scriptTag.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
      scriptTag.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      scriptTag.id = 'jira-widget-script';

      scriptTag.onload = () => {
        callback();
      };

      document.getElementsByTagName('body')[0].appendChild(scriptTag);
    },

    isAnAllowedRoute(route) {
      return this.jiraRoutesAllowed.includes(route.name);
    },

    showJiraWidget(route) {
      return (this.viewJiraWidgetSettings && this.isAnAllowedRoute(route));
    },
  },

};
</script>

<style>
html, body {
  font-family: 'Roboto', sans-serif;
}
</style>
