<template>
  <v-dialog
    v-if="errorMode"
    v-model="showNotification"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title id="error-card-title">
        <span>
          Oops! Something went wrong
        </span>
        <v-spacer />

        <v-btn
          icon
          color="white"
          @click.stop="showNotification = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-3">
        <p class="text-subtitle-1">
          {{ notification.message }}
        </p>
      </v-card-text>

      <v-card-actions>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Session ID</v-list-item-title>
            <v-list-item-subtitle>  {{ datadogRumSessionId }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar
    v-else
    v-model="showNotification"
    top
    right
    :color="notification.color"
  >
    <template v-slot>
      <span>
        {{ notification.message }}
      </span>
    </template>

    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        icon
        v-bind="attrs"
        @click.stop="showNotification = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';
import { datadogRum } from '@datadog/browser-rum';

export default {
  data() {
    return {
      showNotification: false,
      datadogRumSessionId: null,
    };
  },

  computed: {
    ...mapState('notification', ['notification']),

    errorMode() {
      return this.notification.color === 'red';
    },
  },

  watch: {
    notification() {
      this.showNotification = true;
      if (datadogRum.getInternalContext() !== undefined) {
        this.datadogRumSessionId = datadogRum.getInternalContext().session_id;
      }
    },
  },
};
</script>

<style scoped>
#error-card-title {
  background: #B71C1C!important;
  color: white !important;
}
</style>
