import app from './app';
import loanProCustomer from './loanProCustomer';
import loanProLoan from './loanProLoan';
import currentUser from './currentUser';
import notification from './notification';
import sidebar from './sidebar';
import testSuite from './TestSuite';
import ticket from './ticket';
import privateLabel from './privateLabel';
import settlement from './settlement';
// eslint-disable-next-line import/extensions
import autopays from './autopays';
import tbotActions from './static/tbotActions';
import userPermission from './userPermission';
import advances from './advances';
import settings from './settings';
import systemSettingsMenu from './systemSettingsMenu';
import prescreenProspect from './PrescreenProspectTestSuite';

export default {
  app,
  currentUser,
  loanProCustomer,
  loanProLoan,
  notification,
  sidebar,
  testSuite,
  ticket,
  privateLabel,
  settlement,
  autopays,
  tbotActions,
  userPermission,
  advances,
  settings,
  systemSettingsMenu,
  prescreenProspect,
};
