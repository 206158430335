<template>
  <v-flex class="login-form">
    <v-snackbar
      v-model="show"
      top
      right
      :color="notification.color"
    >
      {{ notification.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-form
      ref="form"
      @submit.prevent="submit"
    >
      <v-card
        class="rounded-0"
        style="box-shadow: 2px 2px 4px 0px #2a3244 !important;"
      >
        <v-col class="col-12 text-center text-uppercase font-weight-bold btn-bg-gunmetal py-5">
          {{ productName }}
        </v-col>
      </v-card>
      <v-card
        class="mx-auto pa-5"
        outlined
        tile
        elevation="6"
      >
        <v-card-title class="my-1 py-7">
          <v-img
            contain
            height="38px"
            position="center"
            :src="require(`@/assets/hyphen/logo.png`)"
          />
        </v-card-title>

        <v-card-text
          v-if="!emailSendSuccessful"
          class="mt-6 pb-0"
        >
          <p>
            Enter the email address associated with your
            <span class="brand-text">{{ brandName }}</span> account.
          </p>
          <v-alert
            v-if="formError"
            color="red"
            type="error"
          >
            {{ formError }}
          </v-alert>
          <v-text-field
            v-model="email"
            prepend-inner-icon="mdi-email"
            label="Email"
            height="70px"
            outlined
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          />
          <!-- ./username -->
        </v-card-text>
        <v-card-text v-else>
          <p>
            Check you email to process your new password.
          </p>
          <p>
            Thank you.
          </p>
        </v-card-text>

        <v-card-actions class="mb-3 px-4">
          <v-btn
            v-if="!emailSendSuccessful"
            type="submit"
            color="primary"
            large
            block
            :loading="formProcessing"
            :icon="formProcessing"
          >
            <v-icon
              light
              left
            >
              mdi-email-fast-outline
            </v-icon> Send Recovery Password
          </v-btn>
          <v-btn
            v-else
            color="primary"
            large
            block
            to="{ name: 'login'}"
          >
            Ok
          </v-btn>
        </v-card-actions>
        <div class="text-center">
          <span class="text-caption font-weight-thin text-center">{{ version }}</span>
        </div>
      </v-card>
    </v-form>
  </v-flex>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

export default {
  data() {
    return {
      email: '',
      emailSendSuccessful: false,
      formError: '',
      formProcessing: false,
      show: false,
    };
  },
  computed: {
    ...mapState('notification', ['notification']),
    ...mapGetters('app', ['assetPath', 'brandName', 'name', 'version']),
    ...mapGetters('settings', ['productName']),
  },
  watch: {
    notification(value) {
      this.show = value;
    },
  },
  validations: {
    email: { required, email },
  },

  methods: {
    ...mapActions('currentUser', ['logUserIn']),
    checkFormErrors() {
      let error;
      if (!this.$v.email.$dirty) {
        error = false;
      }
      if (!this.$v.email.email) {
        this.formError = 'Must be valid e-mail';
        error = true;
      }
      if (!this.$v.email.email) {
        this.formError = 'E-mail is required';
        error = true;
      }
      return error;
    },
    setFormState(processing = true, message = '') {
      this.formProcessing = processing;
      this.formError = message;
    },
    async submit() {
      if (!this.checkFormErrors()) {
        this.setFormState();
        try {
          await this.$axios.get('/sanctum/csrf-cookie');
          await this.$axios.post('/api/forgot-password', { email: this.email });

          // this.$router.push({ name: 'dashboard-home' });
          this.setFormState(true);
          this.emailSendSuccessful = true;
        } catch (error) {
          if (error.response.data.errors) {
            const errors = Object.keys(error.response.data.errors)
              .map((e) => error.response.data.errors[e]);
            this.setFormState(
              false,
              String(errors.shift()),
            );
          } else {
            this.setFormState(
              false,
              error.response.data.message,
            );
          }
        }
      }
    },
    resetForm() {
      this.email = '';
    },
  },
};
</script>

<style>
.login-form {
  /*max-width: 480px;*/
  max-width: 35vw;
  margin-bottom: 15vh;
}
.login-form .v-card {
  box-shadow: 2px 2px 4px 0px grey !important;
}
.login-form .v-btn.primary {
  background-color: #205e86 !important;
  height: 3.5rem !important;
  font-size: 1.1rem !important;
  font-weight: 800;
}
.login-form .v-btn.primary .v-icon {
  font-size: 2.2rem !important;
  font-weight: 400 !important;
  margin-right: 1rem;
  -webkit-text-stroke: 1px #205e86;
}
.login-form .v-input__icon {
  width: 40px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.login-form .v-input__icon .v-icon.v-icon {
  color: #4d4d4d;
  font-size: 2rem;
  -webkit-text-stroke: 1px #ffffff;
}
.login-form .v-input .v-label {
  padding: 0.5rem 1rem;
  height: auto;
}
.login-form .v-label--active {
  padding: 0rem 1rem !important;
}
.login-form .v-input input {
  padding: 0rem 1rem;
  background-color: #e9eaec;
  min-height: 45px !important;
  max-height: 50px;
}
.login-form .v-input__slot {
  background: transparent !important;
}
.brand-text {
  text-transform: capitalize;
}
</style>
