<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        :loading="isLoading"
        outlined
        tile
      >
        <v-card-title>
          Settlement
        </v-card-title>
        <v-card-text
          v-if="!isLoading"
        >
          <v-card-text
            v-if="!isSettlementAvailable"
          >
            <v-alert
              type="warning"
            >
              <p>This loan is not eligible for settlement.</p>
            </v-alert>
          </v-card-text>
          <PayFullBalance
            v-else-if="showPayFullBalance"
            :today-payoff-balance="todayPayoffBalance"
            :loan-settings-id="loanSettingsId"
            :payment-accounts="paymentAccounts"
            :display-id="displayId"
            :full-name="fullName"
            :customer-id="ticket.lpCustomer.id"
            :loan-id="ticket.active_loan.id"
            :ticket="ticket"
            @back-ticket-settlement="backPayFullBalance()"
          />
          <SettlementOffer
            v-else-if="showSettlementOffer"
            :is-covid-collection="isCovidCollection"
            :today-payoff-balance="todayPayoffBalance"
            :loan-settings-id="loanSettingsId"
            :display-id="displayId"
            :full-name="fullName"
            :customer-id="ticket.lpCustomer.id"
            :loan-id="ticket.active_loan.id"
            :payment-accounts="paymentAccounts"
            :loan-data="loanData"
            :ticket="ticket"
            @pay-full-balance="showPayFullBalance = true"
            @reload-payment-profiles="reloadPaymentProfiles()"
            @back-ticket-settlement="backSettlementOffer()"
          />
          <DebtSettlement
            v-else
            :is-covid-collection="isCovidCollection"
            :today-payoff-balance="todayPayoffBalance"
            @pay-full-balance="showPayFullBalance = true"
            @settlement-offer="showSettlementOffer = true"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import LpLoan from '@/services/LpLoanService';

import PayFullBalance from '../LoanSettlement/PayFullBalance.vue';
import SettlementOffer from '../LoanSettlement/SettlementOffer.vue';
import DebtSettlement from '../LoanSettlement/DebtSettlement.vue';

export default {
  name: 'TicketSettlement',
  components: {
    PayFullBalance,
    SettlementOffer,
    DebtSettlement,
  },

  data() {
    return {
      ticket: {},
      isLoading: true,
      showPayFullBalance: false,
      showSettlementOffer: false,
      isCovidCollection: false,
      todayPayoffBalance: null,
      loanSettingsId: null,
      paymentAccounts: {},
      fullName: null,
      displayId: null,
      loanData: {
        payoff: 0,
        dueInterest: 0,
        principalBalance: 0,
      },
    };
  },

  computed: {
    ...mapState('settlement', ['isSettlementAvailable', 'disclosuresSent', 'paymentSchedulesSent']),
  },

  async created() {
    await this.getTicket();
    await this.getPaymentProfiles();

    this.determineAvailability({
      loanStatusId: this.ticket.active_loan.current_status.loan_status_id,
      loanSubStatusId: this.ticket.active_loan.current_status.loan_sub_status_id,
    });

    this.todayPayoffBalance = this.ticket.active_loan.current_status.payoff;
    this.isCovidCollection = LpLoan.isCovidCollection(
      this.ticket.active_loan.current_status.loan_status_id,
      this.ticket.active_loan.current_status.loan_sub_status_id,
    );
    this.loanSettingsId = this.ticket.active_loan.settings_id;
    this.displayId = this.ticket.active_loan.display_id;

    this.fullName = `${this.ticket.lpCustomer.first_name} ${this.ticket.lpCustomer.last_name}`;

    this.loanData.payoff = this.ticket.active_loan.current_status.payoff;
    this.loanData.dueInterest = this.ticket.active_loan.current_status.due_interest;
    this.loanData.principalBalance = this.ticket.active_loan.current_status.principal_balance;

    this.isLoading = false;

    // add comment to Zendesk controller
    await this.$axios
      .post('api/v1/zendesk/add-ticket-comment', {
        ticketId: this.$route.params.id,
        comment: 'borrower called to create payment plan',
      });
  },

  methods: {
    ...mapActions('settlement', ['determineAvailability', 'disclosuresNotSent', 'paymentSchedulesNotSent']),

    async reloadPaymentProfiles() {
      await this.getPaymentProfiles();
    },

    async getTicket() {
      this.loadingModel = true;

      const res = await this.$axios
        .post('api/v1/support/ticket', {
          ticketId: this.$route.params.id,
        });

      this.ticket = res.data.data;
    },

    async getPaymentProfiles() {
      const res = await this.$axios
        .post(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment-profiles`, {
          ticketId: this.$route.params.id,
        });

      this.paymentAccounts = [...res.data.data];
    },

    backPayFullBalance() {
      this.showPayFullBalance = false;
      this.paymentSchedulesNotSent();
      this.disclosuresNotSent();
    },

    backSettlementOffer() {
      this.showSettlementOffer = false;
      this.paymentSchedulesNotSent();
      this.disclosuresNotSent();
    },

    revokeAutopay() {
      this.$router.push({
        name: 'ticket-revoke-autopay',
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
};
</script>
