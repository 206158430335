<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="name"
                  required
                  label="Token Name"
                  :error-messages="nameErrors"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="description"
                  required
                  label="Description"
                  :error-messages="descriptionErrors"
                  @input="$v.description.$touch()"
                  @blur="$v.description.$touch()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          name: '',
          description: '',
        };
      },
    },
  },
  data() {
    return {
      id: null,
      name: '',
      description: '',
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      const newOrUpdate = this.isNewItem ? 'New' : 'Update';
      return `${newOrUpdate} Token`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Token Name is required.');
      }

      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.description.$dirty) {
        return errors;
      }
      if (!this.$v.description.required) {
        errors.push('Description is required.');
      }
      if (!this.$v.description.maxLength) {
        errors.push(`Description must not exceed ${this.$v.description.$params.maxLength.max} letters.`);
      }

      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.name = this.editedItem.name;
      this.description = this.editedItem.description;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';

        const data = {
          name: this.name,
          description: this.description,
        };

        if (this.isNewItem) {
          request = this.$axios.post('/api/v1/app-token', data);
        } else {
          request = this.$axios.put(`/api/v1/app-token/${this.editedItem.id}`, data);
        }

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.name} has been saved.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.name = '';
      this.description = '';
      this.$emit('close-modal');
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(45),
    },
    description: {
      required,
      maxLength: maxLength(250),
    },
  },
};
</script>

<style></style>
