<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="name"
                  required
                  label="Name"
                  :error-messages="nameErrors"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="description"
                  required
                  label="Description"
                  :error-messages="descriptionErrors"
                  @input="$v.description.$touch()"
                  @blur="$v.description.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="version"
                  required
                  label="Version"
                  :error-messages="versionErrors"
                  @input="$v.version.$touch()"
                  @blur="$v.version.$touch()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import {
  required, decimal, maxLength, minLength,
} from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          name: '',
          description: '',
          version: '',
        };
      },
    },
  },
  data() {
    return {
      id: null,
      name: '',
      description: '',
      version: '',
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      const newOrUpdate = this.isNewItem ? 'New' : 'Update';
      return `${newOrUpdate} Scoring Model`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Name is required.');
      }
      if (!this.$v.name.maxLength) {
        errors.push(`Name must not exceed ${this.$v.name.$params.maxLength.max} letters.`);
      }
      if (!this.$v.name.minLength) {
        errors.push(`Name must be more than ${this.$v.name.$params.minLength.min} letters.`);
      }
      return errors;
    },
    typeErrors() {
      const errors = [];

      if (!this.$v.type.$dirty) {
        return errors;
      }
      if (!this.$v.type.required) {
        errors.push('Type is required.');
      }
      if (!this.$v.type.maxLength) {
        errors.push(`Type must not exceed ${this.$v.type.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.description.$dirty) {
        return errors;
      }
      if (!this.$v.description.required) {
        errors.push('Description is required.');
      }
      if (!this.$v.description.maxLength) {
        errors.push(`Description must not exceed ${this.$v.description.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    versionErrors() {
      const errors = [];

      if (!this.$v.version.$dirty) {
        return errors;
      }
      if (!this.$v.version.required) {
        errors.push('Version is required.');
      }
      if (!this.$v.version.decimal) {
        errors.push('Version must be a number.');
      }
      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.name = this.editedItem.name;
      this.description = this.editedItem.description;
      this.version = this.editedItem.version;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';
        const data = {
          name: this.name,
          description: this.description,
          version: this.version,
          status: 0,
        };

        if (this.isNewItem) {
          request = this.$axios.post('/api/v1/scoring-model', data);
        } else {
          request = this.$axios.put(`/api/v1/scoring-model/${this.editedItem.id}`, data);
        }

        request
          .then(() => {
            this.$emit('item-saved');

            this.setNotification({
              message: 'Scoring model has been saved.',
              color: 'green',
            });

            this.close();
          })
          .catch((err) => {
            let message = 'There was an error processing your request.';
            if (err.response && err.response.data.errors.name) {
              [message] = err.response.data.errors.name;
            }
            if (err.response && err.response.data.errors.version) {
              [message] = err.response.data.errors.version;
            }
            if (err.response && err.response.data.errors.isLocked) {
              [message] = err.response.data.errors.isLocked;
            }
            this.setNotification({
              message,
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50),
    },
    description: {
      required,
      maxLength: maxLength(100),
    },
    version: { required, decimal },
  },
};
</script>

<style></style>
