<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="1200px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasPermission('view prescreen::state-paddings')"
          v-bind="attrs"
          dark
          class="py-6 btn-bg-deep-blue"
          v-on="on"
        >
          <v-icon>mdi-format-list-bulleted-square</v-icon>
          State Paddings
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          text="State Paddings"
          icon="mdi-format-list-bulleted-square"
          :text-button="true"
          permission="view prescreen::state-paddings"
          :x-small="false"
          button-class="mr-10"
        />
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Prescreen State Date Paddings
        </v-card-title>

        <v-card-text class="pa-5">
          <v-row>
            <v-col
              v-for="(padding, index) in paddings"
              :key="`padding-${index}`"
              class="col-1 mt-0"
            >
              {{ padding.state_abbr }}
              <v-text-field
                v-model="paddings[index]['num_days']"
                type="number"
                min="0"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="hasPermission('update prescreen::state-padding')"
            :loading="loading"
            class="btn-bg-deep-blue"
            @click="updatePaddings"
          >
            Update Paddings
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="Update Paddings"
            icon=""
            :text-button="true"
            permission="update prescreen::state-padding"
            :x-small="false"
            button-class="mr-10"
          />

          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    DisabledForLackOfRoleBtn,
  },
  data() {
    return {
      dialog: false,
      paddings: [],
      loading: false,
    };
  },
  created() {
    this.getPaddings();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    getPaddings() {
      this.$axios
        .get('/api/v1/prescreen/state-paddings')
        .then((res) => {
          this.paddings = res.data;
        });
    },
    updatePaddings() {
      this.loading = true;
      this.$axios
        .post('/api/v1/prescreen/state-paddings', {
          paddings: this.paddings,
        })
        .then((res) => {
          this.loading = false;
          this.setNotification({
            message: res.data.message,
            color: 'success',
          });
        })
        .catch((error) => {
          this.loading = false;

          let msg = error.response.data.message;

          if (error.response.status === 422) {
            if (error.response.data.errors) {
              Object.values(error.response.data.errors).forEach((val) => {
                msg += `\n ${val}`;
              });
            }
          }

          this.setNotification({
            message: msg,
            color: 'red',
          });
        });
    },
  },
};
</script>
