<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-data-table
            dense
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            :footer-props.sync="footerProps"
            @update:options="updateTable()"
          >
            <template v-slot:top>
              <v-card-title>
                <v-toolbar flat>
                  <v-toolbar-title>Statements</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
            </template>

            <template v-slot:item.period_start="{ item }">
              {{ formatDate(item.period_start) }}
            </template>

            <template v-slot:item.period_end="{ item }">
              {{ formatDate(item.period_end) }}
            </template>

            <template v-slot:item.change="{ item }">
              {{ formatAmount(item.change) }}
            </template>

            <template v-slot:item.adjust="{ item }">
              {{ formatAmount(item.adjust) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                tile
                color="primary"
                small
                text
                @click="showJsonModal('JSON', item)"
              >
                <v-icon
                  dark
                  left
                  class="mr-1"
                >
                  mdi-eye
                </v-icon>
                SHOW
              </v-btn>

              <v-btn
                tile
                color="primary"
                small
                text
                @click="downloadPDF(item)"
              >
                <v-icon
                  dark
                  left
                  class="mr-1"
                >
                  mdi-download
                </v-icon>
                PDF
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <JsonModal
      v-model="jsonModal"
      :title="jsonTitle"
      :json="json"
      @close-modal="closeJsonModal()"
      @click:outside="closeJsonModal()"
    />
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';
import dataTableMixin from '@/mixins/dataTableMixin';
import JsonModal from '@/components/JsonModal.vue';

export default {
  name: 'LoanProCustomerStatements',
  components: {
    JsonModal,
  },
  mixins: [
    dataTableMixin,
  ],
  props: {
    customer: {
      type: Object,
      required: true,
    },
    customerLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Period Start', value: 'period_start' },
        { text: 'Period End', value: 'period_end' },
        { text: 'Change', value: 'change' },
        { text: 'Adjust', value: 'adjust' },
        { text: '', value: 'actions' },
      ],
      json: {},
      jsonTitle: '',
      jsonModal: false,
    };
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async updateTable() {
      this.loading = true;

      const response = await this.$axios
        .get(`api/v1/loanpro-customer/${this.$route.params.customer_id}/statements`, {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
          },
        });

      this.totalItems = response.data.total;
      this.items = response.data.data;
      this.loading = false;
    },
    formatDate(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_MED);
    },
    formatAmount(number) {
      const amount = parseFloat(number).toFixed(2);

      if (amount === '0.00') {
        return '-';
      }

      if (amount < 0) {
        return `-$${Math.abs(amount)}`;
      }

      return `$${amount}`;
    },
    closeJsonModal() {
      this.jsonModal = false;
      this.$nextTick(() => {
        this.json = {};
      });
    },
    showJsonModal(title, json) {
      this.json = json;
      this.jsonTitle = title;
      this.jsonModal = true;
    },
    downloadPDF(statement) {
      this.$axios
        .get(`api/v1/reports/statements/${statement.id}`, { responseType: 'blob' })
        .then((res) => {
          const date = DateTime.fromISO(statement.period_end).toUTC().toFormat('yyyy-MM-dd');
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `statement-${statement.loan_id}-${statement.id}-${date}.pdf`;
          link.click();
          link.remove();

          this.setNotification({
            message: 'Statement pdf downloaded',
            color: 'green',
          });
        })
        .catch(async (error) => {
          const responseObj = await error.response.data.text();

          const jsonResponse = JSON.parse(responseObj);
          this.setNotification({
            message: `PDF could not be retrieved. If error persist please contact Dev Department. Details: ${jsonResponse.error}`,
            color: 'red',
          });
        });
    },
  },
};
</script>
