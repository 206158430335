<template>
  <v-main class="grey lighten-3">
    <v-container
      fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <div class="h-screen flex flex-col items-center text-center">
          <div class="flex items-center mb-4">
            <h1 class="text-3xl px-2">
              Admin Portal is Under Maintenance
            </h1>
          </div>

          <p>Please check back in a little bit.</p>
          <p class="text-caption font-weight-thin text-center">
            {{ version }}
          </p>
        </div>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Maintenance',
  computed: {
    ...mapGetters('app', ['version']),
  },
};
</script>

<style></style>
