<template>
  <div
    class="text-center"
    style="min-width: 100%"
  >
    <v-dialog
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          large
          block
          v-on="on"
        >
          Payments Made
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Payments Made
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="payments"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{backgroundRed: defineBackground(item)}"
                >
                  <td>{{ item.info }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ getLastValue(item, 'extra') }}</td>
                  <td>{{ getPaymentStatus(item) }}</td>
                  <td>{{ formatDate(item.apply_date) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { DateTime } from 'luxon';

export default {
  name: 'PaymentsMade',
  props: {
    loanId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      paymentSchedules: [],
      headers: [
        { text: 'Info', value: 'info' },
        { text: 'Amount', value: 'amount' },
        { text: 'Extra', value: 'extra' },
        { text: 'Status', value: 'status' },
        { text: 'Apply Date', value: 'created' },
      ],
    };
  },
  computed: {
    ...mapState('loanProLoan', ['payments']),
  },
  methods: {
    defineBackground(item) {
      return (item.status !== 'payment.status.success');
    },
    getPaymentStatus(item) {
      switch (item.status) {
        case ('payment.status.success'):
          return 'Success';
        case ('payment.status.none'):
          return 'Pending';
        case ('payment.status.voided'):
          return 'Reverse';
        default:
          return 'Failed';
      }
    },
    getLastValue(item, value) {
      return item[value]?.split(/[\s.]+/)?.pop() || '';
    },
    formatDate(date) {
      return DateTime.fromSQL(date).toLocaleString(DateTime.DATE_SHORT);
    },
  },
};
</script>

<style>
  .backgroundRed {
    background-color: rgba(230, 10, 10, 0.7);
    color: white;
  }
</style>
