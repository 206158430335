<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      class="fraud-claim"
      width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasPermission('view ticket::fraud-claim')"
          v-bind="attrs"
          color="secondary"
          x-large
          block
          class="fraud-claim-dialog-btn"
          v-on="on"
        >
          Fraud Doc not Signed
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          text="Fraud Doc not Signed"
          permission="view ticket::fraud-claim"
          :x-small="false"
          :x-large="true"
          :block="true"
          button-class="fraud-claim-dialog-btn"
        />
      </template>

      <v-card :loading="loadingModel">
        <v-card-title class="text-h5 grey lighten-2">
          Fraud Doc not Signed
        </v-card-title>

        <v-card-text class="pa-5">
          <p>
            They Just in Helix and Zendesk send Denial email: Response for Clarity
            Only Denials only then place a screen shot of the Helix Data
            page on your internal note
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="hasPermission('create ticket::fraud-claim')"
            color="primary"
            @click="processForm"
          >
            Send Denial Email
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="Send Denial Email"
            permission="create ticket::fraud-claim"
            :x-small="false"
          />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'FraudDocUnSigned',
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticketId: {
      type: Number,
      required: true,
      default: null,
    },
    customer: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      loadingModel: false,
    };
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async processForm() {
      try {
        this.loadingModel = true;
        const res = await this.$axios
          .post('api/v1/support/ticket/fraud-claim/doc-not-signed', {
            ticketId: this.ticketId,
            customer: this.customer,
          });
        this.loadingModel = false;
        if (res.status === 200 && res.data) {
          this.dialog = true;
          this.setNotification({
            message: res.data.message,
            color: 'green',
          });
        }
      } catch (error) {
        let errMsg = 'There was an error processing your request';
        if (error.response && error.response.status === 404 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
  },
};
</script>
