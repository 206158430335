<template>
  <div class="personal-info">
    <h4>{{ sectionTitle }}</h4>

    <ProspectInfo
      :name="'First Name:'"
      :value="prospect.firstName"
    />

    <ProspectInfo
      :name="'Middle Initial:'"
      :value="prospect.middleName || 'N/A'"
    />

    <ProspectInfo
      :name="'Last Name:'"
      :value="prospect.lastName"
    />

    <ProspectInfo
      :value="prospect.householdNumber"
      :name="'Phone Number:'"
    />

    <ProspectInfo
      :name="'System Expire Date:'"
      :value="prospect.systemExpiredDate"
    />
  </div>
</template>
<script>
import ProspectInfo from './ProspectInfo.vue';

export default {
  name: 'PersonalInfo',
  components: {
    ProspectInfo,
  },

  props: {
    prospect: {
      required: true,
      type: Object,
    },

    sectionTitle: {
      required: false,
      default: '',
      type: String,
    },
  },

};
</script>

<style scoped>

</style>
