<template>
  <v-container fluid>
    <v-data-table
      dense
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      class="elevation-1"
      disable-sort
      hide-default-footer
      @update:options="updateTable()"
    >
      <template v-slot:top>
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title>Zendesk Tickets</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
      </template>

      <template v-slot:item.created="{ item }">
        {{ createDate(item.created_at) }}
      </template>

      <template v-slot:item.description="{ item }">
        <p
          class="text-truncate mb-0"
          style="max-width: 200px"
        >
          {{ item.description }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          x-small
          color="primary"
          class="mr-2"
          @click="openCommentsModal(item)"
        >
          <v-icon
            left
            dark
          >
            mdi-comment-multiple
          </v-icon>
          Comments
        </v-btn>
      </template>
    </v-data-table>

    <LoanProCustomerZendeskTicketCommentsModal
      v-model="dialogComments"
      :ticket="selectedTicket"
      :comments="selectedTicketComments"
      :lp-customer-id="$route.params.customer_id"
      @close-modal="closeCommentsModal()"
    />
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';

import LoanProCustomerZendeskTicketCommentsModal from '@/views/LoanProCustomers/Zendesk/LoanProCustomerZendeskTicketCommentsModal.vue';

export default {
  name: 'LoanProCustomerZendesk',

  components: {
    LoanProCustomerZendeskTicketCommentsModal,
  },

  mixins: [dataTableMixin],

  data() {
    return {
      dialogComments: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Date/Time', value: 'created', width: 180 },
        { text: 'Status', value: 'status' },
        { text: 'Subject', value: 'subject' },
        { text: 'Description', value: 'description' },
        { text: '', value: 'actions' },
      ],
      selectedTicket: {
        id: null,
        description: '',
      },
      selectedTicketComments: [],
    };
  },

  methods: {
    async updateTable() {
      this.loading = true;

      const response = await this.$axios.get(
        `api/v1/loanpro-customer/${this.$route.params.customer_id}/zendesk/tickets`,
      );

      this.totalItems = response.data.total;
      this.items = response.data.results;
      this.loading = false;
    },
    createDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    openCommentsModal(ticket) {
      this.dialogComments = true;
      this.selectedTicket = ticket;

      this.$axios.get(
        `api/v1/loanpro-customer/${this.$route.params.customer_id}/zendesk/tickets/${ticket.id}/comments`,
      ).then((res) => {
        const { data } = res;

        this.selectedTicketComments = data.comments;
      });
    },

    closeCommentsModal() {
      this.dialogComments = false;
      this.selectedTicketComments = [];

      this.selectedTicket = {
        id: null,
        description: '',
      };
    },
  },
};
</script>
