<template>
  <v-dialog
    v-model="show"
    width="800"
  >
    <v-card>
      <v-card-text
        class="pt-5"
      >
        <v-alert
          v-if="!showDisclosure"
          prominent
          dense
          dark
          text
          border="left"
          color="primary"
          icon="mdi-account-voice"
        >
          <blockquote class="blockquote">
            "Would you like to set this {{ accountType }} as your Primary Payment Method? This means
            that all future payments for your loan will be charged using this payment method."
          </blockquote>
        </v-alert>
        <!--        <p-->
        <!--          v-if="!showDisclosure"-->
        <!--        >-->
        <!--          Are you changing the {{ accountType }} for future payments?-->
        <!--        </p>-->

        <div
          v-if="showDisclosure"
        >
          <v-alert
            v-if="!disclosuresSent"
            prominent
            dense
            dark
            text
            border="left"
            color="primary"
            icon="mdi-account-voice"
          >
            <blockquote class="blockquote">
              "In order to set this {{ accountType }} as your Primary Payment Method,
              we will need you to acknowledge and agree to a Electronic Payment Disclosure.
              We are sending the Disclosure to your email now." (click below to send)
            </blockquote>
          </v-alert>
          <DisclosureModal
            v-model="disclosureDialog"
          />
        </div>
        <div
          v-if="disclosuresSent"
          class="row mt-3 mb-3"
        >
          <div class="col-12">
            <v-alert
              v-if="disclosuresSent"
              prominent
              dense
              dark
              text
              border="left"
              color="primary"
              icon="mdi-account-voice"
            >
              <blockquote class="blockquote">
                Do you acknowledge and agree to the Electronic Payment Disclosure?
              </blockquote>
            </v-alert>
            <div
              class="text-center mb-2"
            >
              If YES
            </div>
            <v-btn
              class="primary"
              width="100%"
              @click="$emit('use-for-future-payments')"
            >
              add this {{ accountType }} as the Primary Method
            </v-btn>
            <div
              class="text-center mb-2 mt-2"
            >
              If NO
            </div>
            <v-btn
              class="mb-2"
              width="100%"
              @click="$emit('no-future-payments')"
            >
              add this {{ accountType }}, but do not set as Primary Method
            </v-btn>
            <v-btn
              width="100%"
              @click="onClose"
            >
              do not add this {{ accountType }}
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <v-card-actions
        v-if="!showDisclosure"
        class="pb-10"
      >
        <div class="row">
          <div class="cols-4 offset-8">
            <v-btn
              v-if="!loading"
              color="primary"
              dark
              :disabled="disclosureDialog"
              @click.stop="handleYes()"
            >
              yes
            </v-btn>
            <v-btn
              text
              class="ml-2"
              @click.stop="$emit('no-future-payments')"
            >
              no
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions, mapState } from 'vuex';

import DisclosureModal from '../../LoanSettlement/DisclosureModal.vue';

export default {
  components: { DisclosureModal },
  props: {
    value: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    accountType: {
      type: String,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disclosureDialog: false,
      showDisclosure: false,
      autopay: null,
      payment: null,
    };
  },
  computed: {
    ...mapState('settlement', ['disclosuresSent']),
    ...mapState('autopays', ['recurring']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    show(newVal) {
      if (newVal === true) {
        this.disclosuresNotSent();
        this.showDisclosure = false;
      }
    },
  },
  methods: {
    ...mapActions('settlement', ['disclosuresNotSent']),
    ...mapActions('autopays', ['getRecurringAutopays', 'updateAutopayPayment']),

    handleYes() {
      // this.show = false;
      this.showDisclosure = true;
      // this.$emit('use-for-future-payments');
      // this.disclosureDialog = true;
    },

    onClose() {
      this.showDisclosure = false;
      this.show = false;
    },

    getAutopay() {
      return this.$axios
        .post('api/v1/support/ticket/autopay/find', {
          ticketId: this.$route.params.id,
          loanId: this.ticket.active_loan.id,
        })
        .then((res) => {
          this.autopay = res.data.autopay;
          this.payment = res.data.payment;
        });
    },

    revokeAutopay() {
      this.confirmModal = false;
      this.loadingModel = true;
      return this.$axios
        .post('api/v1/support/ticket/autopay/revoke', {
          ticketId: this.$route.params.id,
          loanId: this.ticket.active_loan.id,
          autopayId: this.autopay.id,
          paymentId: this.payment ? this.payment.id : undefined,
        })
        .then(() => {
          // this.loadingModel = false;
          // this.revokeModal = true;
          this.setNotification({
            message: 'The autopay was revoked successfully.',
            color: 'green',
          });
        });
    },
  },
};
</script>
