<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    @click:outside="close()"
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert
                border="top"
                color="info lighten-2"
                dark
                icon="mdi-send"
                prominent
              >
                Add email addresses that should receive
                the test email for adverse action code {{ code }}.
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div
                v-for="(email, index) in $v.emailAddresses.$each.$iter"
                :key="`aa_email_${index}`"
              >
                <v-text-field
                  v-model="emailAddresses[index].email"
                  required
                  label="Email Address"
                  :error-messages="emailAddressErrors(email)"
                  @input="email.$touch()"
                  @blur="email.$touch()"
                >
                  <v-icon
                    v-if="emailAddresses.length > 1"
                    slot="append"
                    color="red"
                    @click="removeEmail(index)"
                  >
                    mdi-delete
                  </v-icon>
                </v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                icon
                color="green"
                @click="emailAddresses.push({email: null})"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          dark
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue btn-bg-deep-blue"
          dark
          :disabled="formProcessing"
          @click="send()"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          code: '',
        };
      },
    },
  },
  data() {
    return {
      id: null,
      code: '',
      formProcessing: false,
      emailAddresses: [],
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return `Send ${this.code} Test Email`;
    },
    ...mapState('currentUser', ['user']),
  },

  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.code = this.editedItem.code;
    },
    show(value) {
      if (value) {
        this.addActualEmail();
      }
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    addActualEmail() {
      let exists = false;
      this.emailAddresses.forEach((address) => {
        if (address.email === this.user.email) {
          exists = true;
        }
      });

      if (!exists) {
        this.emailAddresses.push({ email: this.user.email });
      }
    },
    send() {
      this.$v.emailAddresses.$touch();

      if (!this.$v.emailAddresses.$invalid) {
        this.formProcessing = true;

        const emails = this.emailAddresses.map((elem) => elem.email);

        this.$axios.post(`/api/v1/adverse-action/${this.id}/send-test-email`, { emails })
          .then(() => {
            this.$emit('email-sent');

            this.setNotification({
              message: 'Email has been sent.',
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    emailAddressErrors(v) {
      const errors = [];

      if (!v.email.$dirty) {
        return errors;
      }
      if (!v.email.required) {
        errors.push('Email Address cannot be blank.');
      }
      if (!v.email.email) {
        errors.push('Please use a valid email address.');
      }
      return errors;
    },
    close() {
      this.$emit('close-modal');

      this.emailAddresses = [{ email: this.user.email }];
      this.$v.emailAddresses.$reset();
    },
    removeEmail(index) {
      this.emailAddresses.splice(index, 1);
    },
  },
  validations: {
    emailAddresses: {
      required,
      $each: {
        email: {
          required,
          email,
          minLength: minLength(3),
        },
      },
    },
  },
};
</script>

<style></style>
