<template>
  <v-dialog
    v-model="show"
    max-width="600px"
    @click:outside="close()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          Configuration for<span class="font-weight-bold ml-1">{{ title }}</span>
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-for="(input, index) in inputs"
              :key="index"
              cols="12"
              md="12"
            >
              <v-text-field
                v-if="input.type !== 'json'"
                v-model="form.model[input.name]"
                :type="input.type"
                required
                :label="input.name"
                :disabled="isLendingStrategyLocked"
              />
              <JsonTextField
                v-else
                v-model="form.model[input.name]"
                :disabled="isLendingStrategyLocked"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="isLendingStrategyLocked"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Vue from 'vue';
import JsonTextField from '../ScoringElement/JsonTextField.vue';

export default {
  name: 'ScoringModelSequenceElementModal',
  components: {
    JsonTextField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    elementData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        model: [],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    inputs() {
      const inputs = this.elementData.route_custom_inputs
        ? JSON.parse(this.elementData.route_custom_inputs)
        : [];

      return inputs.map((input) => ({ name: input.split(':')[0], type: input.split(':')[1] }));
    },
    title() {
      return `${this.elementData.class}->${this.elementData.method}` || '';
    },
    isLendingStrategyLocked() {
      return this.elementData.isLendingStrategyLocked;
    },
  },

  watch: {
    elementData() {
      if (this.elementData.scoringModelId) {
        this.fetch();
      }
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    close() {
      this.$emit('close-modal');
    },
    fetch() {
      const endpoint = `/api/v1/scoring-model/${this.elementData.scoringModelId}/sequence/get-route-custom-vars/${this.elementData.id}`;

      return this.$axios.get(endpoint)
        .then((response) => {
          const customVars = Object.entries(response.data.custom_vars);

          const model = [];
          customVars.forEach((item) => {
            // set the name of the custom var as the key of the array, i.e. [tier_score: 12]
            model[item[0]] = item[1].value;
          });
          // and use that array as the v-models for the inputs
          Vue.set(this.form, 'model', model);
        });
    },
    save() {
      const endpoint = `/api/v1/scoring-model/${this.elementData.scoringModelId}/sequence/update-route-custom-vars/${this.elementData.id}`;

      return this.$axios.put(endpoint, { ...this.form.model })
        .then((response) => {
          if (response.status === 200) {
            this.$emit('setCustomVars', response.data.custom_vars);
            this.setNotification({
              message: response.data.message,
              color: 'green',
            });
          } else {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          }
        })
        .catch(() => {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        })
        .then(() => { this.close(); });
    },
  },
};
</script>

<style scoped>

</style>
