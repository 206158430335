import Vue from 'vue';

const initialState = {
  settings: {},
};

const getters = {
  getSettings(state) {
    return state.settings;
  },
  productName(state) {
    return (state.settings && state.settings.brand) ? state.settings.brand.product_name : '';
  },
};

const actions = {
  async getSettings({ commit }, params = null) {
    try {
      const settings = await Vue.prototype.$axios.get('/api/v1/admin/settings', { params });

      const vars = {};

      settings.data.groups.forEach((group) => {
        vars[group.value] = {};
      });

      settings.data.settings.forEach((setting) => {
        vars[setting.group][setting.key] = setting.value;
      });

      commit('SET_SETTINGS', vars);
    } catch (err) {
      commit('SET_SETTINGS', {});
    }
  },

  updateSettings({ commit }, data) {
    commit('UPDATE_SETTING', data);
  },
};

const mutations = {
  SET_SETTINGS(state, data) {
    state.settings = data;
  },

  UPDATE_SETTING(state, data) {
    state.settings[data.group][data.key] = data.value;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
