<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Monitors</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <MonitorFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <v-btn
            v-if="hasPermission('create system-settings::monitors')"
            color="primary"
            dark
            class="mb-2"
            @click="dialogForm = true"
          >
            New Monitor
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Monitor"
            permission="create system-settings::monitors"
            :x-small="false"
            button-class="mb-2"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('edit system-settings::monitors')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil"
        :text-button="true"
        permission="edit system-settings::monitors"
        :x-small="false"
        button-class="mr-10"
      />

      <MonitorToggle
        :item="item"
        @monitor-toggled="updateTable"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import MonitorFormModal from '@/views/Settings/Monitor/MonitorFormModal.vue';
import MonitorToggle from '@/views/Settings/Monitor/MonitorToggle.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    MonitorFormModal,
    MonitorToggle,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Heartbeat', value: 'heartbeat_name' },
      { text: 'Interval', value: 'interval', sortable: false },
      { text: 'Actions', value: 'actions' },
    ],

    dialogForm: false,
    editedItem: {
      id: null,
      command: '',
    },
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/monitor', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
