<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title> Two Factor Authentication </v-card-title>
      <v-card-text>
        <v-alert
          v-if="formError"
          color="red"
          type="error"
        >
          {{ formError }}
        </v-alert>
        Please enter your secure code:
        <v-text-field
          v-if="!useRecoveryCode"
          v-model="code"
          label="6 Digit Code"
          @keydown.enter="twoFactorAuth()"
        />
        <v-text-field
          v-if="useRecoveryCode"
          v-model="recovery_code"
          label="Recovery Code"
          @keydown.enter="twoFactorAuth()"
        />
        <v-switch
          v-model="useRecoveryCode"
          label="Use Alphanumeric Recovery Code"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="formProcessing"
          :icon="formProcessing"
          @click="twoFactorAuth()"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  props: { dialog: Boolean },
  data() {
    return {
      formProcessing: false,
      formError: '',
      code: '',
      recovery_code: '',
      useRecoveryCode: false,
    };
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('currentUser', ['logUserOut']),
    ...mapMutations('currentUser', ['SET_USER', 'SET_IS_LOGGED_IN']),
    async twoFactorAuth() {
      this.setFormState();
      try {
        /**
         * If the user enters a bad numeric code and good recovery_code
         * laravel will respond with a failure. Only send one or the other.
         */
        const params = this.useRecoveryCode ? { recovery_code: this.recovery_code }
          : { code: this.code };

        const auth = await this.$axios.post('/two-factor-challenge', params);

        this.SET_USER(auth.data.user);
        this.SET_IS_LOGGED_IN(true);
        this.$router.push({ name: 'dashboard-home' });
      } catch (err) {
        this.formProcessing = false;
        this.recovery_code = '';
        this.code = '';
        this.logUserOut();
        this.$emit('failedMfa', true);
        this.setNotification({
          message: 'The provided two factor authentication code was invalid.',
          color: 'red',
        });
      }
    },
    setFormState(processing = true, message = '') {
      this.formProcessing = processing;
      this.formError = message;
    },
  },
};
</script>
