<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    @click:outside="close()"
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">Update {{ editedItem.first_name }}'s Password</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="password"
                  required
                  type="password"
                  label="New Password"
                  :error-messages="passwordErrors"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="passwordConfirmed"
                  required
                  type="password"
                  label="Confirm Password"
                  :error-messages="passwordConfirmedErrors"
                  @input="$v.passwordConfirmed.$touch()"
                  @blur="$v.passwordConfirmed.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-checkbox
                  v-model="require_reset"
                  label="Require reset at next login"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import {
  required, minLength, sameAs,
} from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          groups: [],
        };
      },
    },
  },
  data() {
    return {
      id: null,
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      groups: '',
      password: '',
      passwordConfirmed: '',
      formProcessing: false,
      require_reset: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.required) {
        errors.push('Password is required.');
      }
      if (!this.$v.password.minLength) {
        errors.push(`Password must be more than ${this.$v.password.$params.minLength.min} letters.`);
      }

      return errors;
    },
    passwordConfirmedErrors() {
      const errors = [];

      if (!this.$v.passwordConfirmed.$dirty) {
        return errors;
      }
      if (!this.$v.passwordConfirmed.sameAs) {
        errors.push('Password confirmation must match.');
      }

      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.firstName = this.editedItem.first_name;
      this.lastName = this.editedItem.last_name;
      this.username = this.editedItem.username;
      this.email = this.editedItem.email;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';

        const data = {
          password: this.password,
          password_confirmation: this.passwordConfirmed,
        };

        request = this.$axios.post(`/api/v1/user/${this.editedItem.id}/reset-password`, data);

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.first_name}'s password has been reset.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;
            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.password = '';
      this.passwordConfirmed = '';
      this.$emit('close-modal');
    },
  },
  validations() {
    const validationObj = {
      password: {
        required,
        minLength: minLength(5),
      },
      passwordConfirmed: {
        sameAs: sameAs('password'),
      },
    };

    return validationObj;
  },
};
</script>

<style></style>
