const helixDomain = 'https://helixfi.com';
const loopDomain = 'https://loopfund.com';
const vaultDomain = 'https://myvaultloan.com';
const demoDomain = 'https://hyphendemo.com';

const initialState = {
  helix: {
    company: {
      name: 'Helix',
      nameLong: 'Helix Financial',
      phone: '800.619.5309',
      email: 'info@helixfi.com',
      address1: '4149 Pennsylvania Ave, Suite 102',
      address2: 'Kansas City, MO 64111',
      links: {
        mainWebsite: {
          label: 'helixfi.com',
          href: helixDomain,
        },
        loanCost: {
          label: 'helixfi.com/cost',
          href: `${helixDomain}/cost`,
        },
        smsTerms: {
          label: 'SMS Terms & Conditions',
          href: `${helixDomain}/sms-terms-conditions`,
        },
        privacyPolicy: {
          label: 'Privacy Policy',
          href: `${helixDomain}/privacy-policy`,
        },
        mla: {
          label: '',
          href: `${helixDomain}/mla`,
        },
        nonLendingStates: {
          label: '',
          href: `${helixDomain}/non-lending-states`,
        },
        termsOfUse: {
          label: 'Terms Of Use',
          href: `${helixDomain}/terms-of-use`,
        },
        esignConsent: {
          label: 'E-Sign Consent',
          href: `${helixDomain}/e-sign-consent`,
        },
      },
      smsTerms: 'I authorize Helix Financial to send recurring text messages regarding loan status and offers at the phone number provided. Message frequency may vary and may be made using an automatic telephone dialing system. Message and data rates may apply. You may opt-out anytime, and consent is not required to receive funding.',
      disclosures: [
        'This is an expensive form of borrowing and it is not intended to be a long term financial solution. Please note: Helix by Kendall Bank loans are designed to assist you in meeting your short-term borrowing needs and are not intended to be a long term financial solution. Examples of emergency reasons why these loans might be used include unexpected emergencies, car repair bills, medical care, or essential travel expenses. Alternative forms of credit, such as a credit card cash advance, personal loan, home equity line of credit, existing savings or borrowing from a friend or relative, may be less expensive and more suitable for your financial needs.',
        'Late fees and non-sufficient funds/returned item fees may apply as described in your Loan Agreement. Our fees are disclosed in your loan agreement. Loan amounts range from $200 to $4,000 with a repayment term up to 24 months. Annual Percentage Rates range from 36% to 499%. If you do not make a payment on time, we will attempt to contact you via one or more authorized methods. We adhere to the principles of the federal Fair Debt Collection Practices Act (FDCPA). Because we may report your payment history to one or more credit bureaus, late or non-payment of your loan may negatively impact your credit rating. If you fail to repay your loan in accordance with its terms, we may place your loan with or sell your loan to a third-party collection agency or other company that acquires and/or collects delinquent consumer debt.',
        'As part of our information collection process, we may detect additional bank accounts under the ownership of the consumer. We will consider these additional accounts to be part of the application process. Kendall Bank reviews your information in real-time to determine whether your information meets its lending criteria. You acknowledge that by completing and submitting the website application that you are applying for a loan. We verify applicant information through national databases, and we may pull your credit in order to determine your eligibility and ability to repay.',
        'Availability of loans are subject to change at any time at the sole discretion of Kendall Bank.',
        'Helix by Kendall Bank is a brand of Kendall Bank. All loans are issued and serviced by Kendall Bank, Missouri Branch, Member FDIC.',
      ],
      electronicPaymentDisclosure: [
        {
          title: 'Electronic Payment Disclosures and Agreement',
          contents: [
            'In this Electronic Payment Disclosures and Agreement, the words "you", "your" and "I" mean the borrower, and the words "we", "us," and "our" mean Kendall Bank, a Missouri-chartered bank.  This Electronic Payment Disclosures and Agreement governs electronically authorized ACH debits and debit card charges.',
            'If you believe your information has been lost or stolen, you should contact your account-holding financial institution.  If you would like to also inform us of an unauthorized transfer relation to any transactions with us, please contact us in writing at info@helixfi.com and/or 9019 S 7 Highway, Lee’s Summit, MO 64064.',
            'We will disclose information to third parties about your account or the transfers you make: (1) where it is necessary for completing the transfers, (2) in order to verify the existence or condition of your account for a third party such as a credit bureau or a merchant, (3) in order to comply with government agencies or court orders, or (4) if you have given us written permission. See also the Kendall Bank’s Privacy Policy.',
          ],
          contentsLoC: [
            'In this Electronic Payment Disclosures and Agreement, the words "you", "your" and "I" mean the borrower, and the words "we", "us," and "our" mean Kendall Bank, Member FDIC. This Electronic Payment Disclosures and Agreement governs electronically authorized ACH debits and debit card charges.',
            'If you believe your information has been lost or stolen, you should contact your account-holding financial institution. If you would like to also inform us of an unauthorized transfer relation to any transactions with us, please contact us in writing at info@helixfi.com and/or 11225 College Blvd, Suite 150, Overland Park, KS 66210.',
            'We will disclose information to third parties about your account or the transfers you make: (1) where it is necessary for completing the transfers, (2) in order to verify the existence or condition of your account for a third party such as a credit bureau or a merchant, (3) in order to comply with government agencies or court orders, or (4) if you have given us written permission. See also the Kendall Bank Privacy Policy.',
          ],
        },
        {
          title: 'One-Time Payments',
          contents: [
            'By designating a bank account or debit card for a one-time payment, you authorize us to initiate an ACH debit entry to your bank account or initiate a charge on your debit card, as applicable, on the date and in the amount requested.  You agree there will be a sufficient balance to pay the requested payment and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payment.  If you authorized an ACH debit entry to your account, you also authorize us to re-initiate the ACH up to two additional times for the same amount if the ACH is dishonored.  If any payment cannot be obtained by the ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.',
          ],
          contentsLoC: [
            'By designating a bank account or debit card for a one-time payment, you authorize us to initiate an ACH debit entry to your bank account or initiate a charge on your debit card, as applicable, on the date and in the amount requested.  You agree there will be a sufficient balance to pay the requested payment and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payment.  If you authorized an ACH debit entry to your account, you also authorize us to re-initiate the ACH up to two additional times for the same amount if the ACH is dishonored.  If any payment cannot be obtained by the ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.',
          ],
        },
        {
          title: 'Preauthorized Recurring Payments',
          contents: [
            '1. Authorization for Recurring Payments.  By designating a bank account or debit card for automatic recurring payments, you agree to automatic recurring payments and acknowledge and agree to the following: (a) You authorize us to initiate ACH debit entries to your bank account or initiate charges on your debit card, as applicable, on dates and in the amounts you schedule.  You agree there will be a sufficient balance to pay all requested payments and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payments.  If you authorized ACH debit entries to your account, you also authorize us to re-initiate any ACH up to two additional times for the same amount if the ACH is dishonored.  If any payment cannot be obtained by ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.  (b) You have provided us with all necessary information for the bank account or debit card that you wish to use and that the bank account or debit card is legitimate, open, and active.  You agree to notify us promptly if this information changes.  (c) The origination of any ACH debit entry or debit card charge must comply with U.S. law.  (d) The use of automatic recurring payments is optional and not a requirement of obtaining or continuing your loan.  You agree that your authorization of automatic recurring payments is completely voluntary and for your convenience.',
            '2. Right to Stop Payment and Procedure for Doing So.  If you have authorized us in advance to make automatic recurring payments, you can stop these payments by (1) calling us at 1-800-619-5309, or (2) writing us at info@helixfi.com and/or 9019 S 7 Highway, Lee’s Summit, MO 64064, in time for us to receive your request 3 business days or more before the payment is scheduled to be made.  If you call, we may also require you to put your request in writing and deliver your request to us within 14 days after your call.',
            '3. Notice of Varying Amounts.  You have the right to receive notice of all transfers varying in amount. You acknowledge that we elected to offer you a specified range of amounts for the automatic recurring payments (in lieu of providing the notice of transfers in varying amount). The amount of any preauthorized payment will range from (i) the schedule payment amount provided in your Loan Agreement (which may be less than a scheduled payment if partial prepayments have been made), to (ii) an amount equal to the scheduled payment plus as applicable, any other amounts I may owe under your Loan Agreement (e.g. late charge) up to an additional $100.',
            '4. Liability for Failure to Stop Payment of Preauthorized Transfer.  If you order us to stop one of these payments 3 business days or more before the payment is scheduled, and we do not do so, we will be liable for your losses or damages.',
            'IMPORTANT NOTE: THIS INFORMATION IS ONLY APPLICABLE TO TRANSACTIONS BETWEEN YOU AND Kendall Bank.  FOR FURTHER INFORMATION, YOU SHOULD CONTACT YOUR ACCOUNT-HOLDING FINANCIAL INSTITUTIONS. YOU MAY HAVE ADDITIONAL RIGHTS AND OBLIGATIONS.',
          ],
          contentsLoC: [
            '1. Authorization for Recurring Payments. By designating a bank account or debit card for automatic recurring payments, you agree to automatic recurring payments and acknowledge and agree to the following: (a) You authorize us to initiate ACH debit entries to your bank account or initiate charges on your debit card, as applicable, on dates and in the amounts you schedule. You agree there will be a sufficient balance to pay all requested payments and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payments. If you authorized ACH debit entries to your account, you also authorize us to re-initiate any ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement. (b) You have provided us with all necessary information for the bank account or debit card that you wish to use and that the bank account or debit card is legitimate, open, and active. You agree to notify us promptly if this information changes. (c) The origination of any ACH debit entry or debit card charge must comply with U.S. law. (d) The use of automatic recurring payments is optional and not a requirement of obtaining or continuing your loan. You agree that your authorization of automatic recurring payments is completely voluntary and for your convenience.',
            '2. Right to Stop Payment and Procedure for Doing So. If you have authorized us in advance to make automatic recurring payments, you can stop these payments by (1) calling us at 1-888-227-1935, or (2) writing us at info@helixfi.com 11225 College Blvd, Suite 150, Overland Park, KS 66210, in time for us to receive your request 3 business days or more before the payment is scheduled to be made. If you call, we may also require you to put your request in writing and deliver your request to us within 14 days after your call.',
            '3. Notice of Varying Amounts. You have the right to receive notice of all transfers varying in amount. You acknowledge that we elected to offer you a specified range of amounts for the automatic recurring payments (in lieu of providing the notice of transfers in varying amount). The amount of any preauthorized payment will range from (i) the schedule payment amount provided in your Loan Agreement (which may be less than a scheduled payment if partial prepayments have been made), to (ii) an amount equal to the scheduled payment plus as applicable, any other amounts I may owe under your Loan Agreement (e.g. late charge) up to an additional $100.',
            '4. Liability for Failure to Stop Payment of Preauthorized Transfer. If you order us to stop one of these payments 3 business days or more before the payment is scheduled, and we do not do so, we will be liable for your losses or damages.',
            'IMPORTANT NOTE: THIS INFORMATION IS ONLY APPLICABLE TO TRANSACTIONS BETWEEN YOU AND KENDALL BANK. FOR FURTHER INFORMATION, YOU SHOULD CONTACT YOUR ACCOUNT-HOLDING FINANCIAL INSTITUTIONS. YOU MAY HAVE ADDITIONAL RIGHTS AND OBLIGATIONS.',

          ],
        },
      ],
      collectionAgency: {
        name: 'National Credit Adjusters, LLC',
        address1: '327 W 4th St.',
        address2: 'Hutchinson, KS 67501',
        phone: '1-800-542-1048',
        email: '',
      },
      nonLendingStates: ['CT', 'DC', 'IA', 'KS', 'MD', 'MO', 'NY', 'PA', 'RI', 'VA', 'VT', 'WI', 'WV'],
      paymentSlackChannel: 'helixpayments',
      settlementSlackChannel: 'helixsettlements',
    },
    assets: {
      logo: {
        'footer-desktop': 'Helix_350x66_reversed.png',
        'footer-mobile': 'Helix_350x66_reversed.png',
        'header-desktop': 'Helix_350x66.png',
        'header-mobile': 'Helix_350x66_reversed.png',
        'header-desktop-reverse': 'Helix_350x66_reversed.png',
        'header-mobile-reverse': 'Helix_350x66_reversed.png',
        'fullscreen-loader': 'Helix_350x66_reversed.png',
      },
      login: {
        'iphone-help-img-1': 'iPhone-Help-01.png',
      },
    },
  },
  loop: {
    company: {
      name: 'Loop',
      nameLong: 'Loop Fund',
      phone: '833.684.0905',
      email: 'info@loopfund.com',
      address1: 'PO Box 838',
      address2: 'Keshena, WI 54135',
      links: {
        mainWebsite: {
          label: 'loopfund.com',
          href: loopDomain,
        },
        loanCost: {
          label: 'loopfund.com/cost',
          href: `${loopDomain}/cost`,
        },
        smsTerms: {
          label: 'SMS Terms & Conditions',
          href: `${loopDomain}/sms-terms-conditions`,
        },
        privacyPolicy: {
          label: 'Privacy Policy',
          href: `${loopDomain}/mobile-privacy-policy`,
        },
        mla: {
          label: '',
          href: `${loopDomain}/mla`,
        },
        nonLendingStates: {
          label: '',
          href: `${loopDomain}/non-lending-states`,
        },
        termsOfUse: {
          label: 'Terms Of Use',
          href: `${loopDomain}/terms-of-use`,
        },
        esignConsent: {
          label: 'E-Sign Consent',
          href: `${loopDomain}/e-sign-consent`,
        },
      },
      smsTerms: 'I authorize Loop Fund to send recurring text messages regarding loan status and offers at the phone number provided. Message frequency may vary and may be made using an automatic telephone dialing system. Message and data rates may apply. You may opt-out anytime, and consent is not required to receive funding.',
      disclosures: [
        'Fox Lending, LLC dba Loop Fund is a commercial enterprise and instrumentality of the Menominee Indian Tribe of Wisconsin, a federally recognized sovereign Indian nation (the “Tribe”), which abides by the principles of federal consumer finance laws, as incorporated by the Tribe, and operates within the interior boundaries of the Tribes’ reservation in Keshena, WI. Fox Lending, LLC is chartered under and operates pursuant to Tribal Law. Loans from Loop Fund are governed by the laws of the Tribe, not the law of your resident state. Please note that nothing in this communication should be construed as a waiver of sovereign immunity, which is expressly reserved.',
        'Loop Fund does not lend to residents of AR, CT, DC, FL, GA, MA, ME, MD, NH, NY, PA, VA, VT, WV, and WI.',
        'This “prescreened” offer of credit is based on information indicating that you meet certain criteria and is subject to credit verification. In addition, to accept this offer, you must have reached 18 years old. This offer may be accepted only by the person identified in this offer and may not be transferred to any other party.',
        'This is an expensive form of borrowing. Loop Fund loans are designed to assist you in meeting your emergency borrowing needs and are not intended to be a long-term financial solution. Fox Lending, LLC encourages quick repayment to avoid finance fees. Alternative forms of credit may be less expensive and more suitable for your financial needs.',
        `You acknowledge that by completing and submitting the website application that you are applying for a loan. We verify applicant information through national databases and we may pull your credit in order to determine your eligibility and ability to repay.
      *Loan applications fully verified and approved by 11AM Central Time (CT) will be funded as soon as the next business day. Loan applications fully verified and approved after 11AM CT on Friday will be funded as soon as the following Monday, so long as the Monday is not a bank holiday. Fox Lending, LLC is not responsible for any additional charges applied by your financial institution.`,
        'View our website for applicable rates and frequently asked questions. Complete disclosure of APR, fees and payment terms are provided within the Loan Agreement.',
        'Menominee Indian Tribe of Wisconsin Tribal Hotline: 833.341.5479',
        'Loop Fund Customer Service Team: 833.684.0905',
        '© 2020 Fox Lending, LLC dba Loop Fund. All Rights Reserved.',
      ],
      electronicPaymentDisclosure: [
        {
          title: 'Electronic Payment Disclosures and Agreement',
          contents: [
            'In this Electronic Payment Disclosures and Agreement, the words "you", "your" and "I" mean the borrower, and the words "we", "us," and "our" mean Fox Lending LLC d/b/a Loop Fund, an economic development arm of, instrumentality of, and a limited liability company wholly-owned and controlled by, the Menominee Indian Tribe of Wisconsin (“Tribe”), a federally recognized Indian tribe, and any authorized representative, agent, independent contractor, affiliate or assignee we use in the provision of your loan. This Electronic Payment Disclosures and Agreement governs electronically authorized ACH debits and debit card charges.',
            `If you believe your information has been lost or stolen, you should contact your account-holding financial institution. If you would like to also inform us of an unauthorized transfer relation to any transactions with us, please contact us in writing at info@loopfund.com and/or P.O.Box 838, Keshena, WI 54135.
          We will disclose information to third parties about your account or the transfers you make: (1) where it is necessary for completing the transfers, (2) in order to verify the existence or condition of your account for a third party such as a credit bureau or a merchant, (3) in order to comply with government agencies or court orders, or (4) if you have given us written permission. See also the Fox Lending LLC DBA Loop Fund Privacy Policy.`,
          ],
          contentsLoC: [
            'In this Electronic Payment Disclosures and Agreement, the words "you", "your" and "I" mean the borrower, and the words "we", "us," and "our" mean Fox Lending LLC d/b/a Loop Fund, an economic development arm of, instrumentality of, and a limited liability company wholly-owned and controlled by, the Menominee Indian Tribe of Wisconsin (“Tribe”), a federally recognized Indian tribe, and any authorized representative, agent, independent contractor, affiliate or assignee we use in the provision of your loan. This Electronic Payment Disclosures and Agreement governs electronically authorized ACH debits and debit card charges.',
            `If you believe your information has been lost or stolen, you should contact your account-holding financial institution. If you would like to also inform us of an unauthorized transfer relation to any transactions with us, please contact us in writing at info@loopfund.com and/or P.O.Box 838, Keshena, WI 54135.
          We will disclose information to third parties about your account or the transfers you make: (1) where it is necessary for completing the transfers, (2) in order to verify the existence or condition of your account for a third party such as a credit bureau or a merchant, (3) in order to comply with government agencies or court orders, or (4) if you have given us written permission. See also the Fox Lending LLC DBA Loop Fund Privacy Policy.`,
          ],
        },
        {
          title: 'One-Time Payments',
          contents: [
            'By designating a bank account or debit card for a one-time payment, you authorize us to initiate an ACH debit entry to your bank account or initiate a charge on your debit card, as applicable, on the date and in the amount requested. You agree there will be a sufficient balance to pay the requested payment and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payment. If you authorized an ACH debit entry to your account, you also authorize us to re-initiate the ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by the ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.',
          ],
          contentsLoC: [
            'By designating a bank account or debit card for a one-time payment, you authorize us to initiate an ACH debit entry to your bank account or initiate a charge on your debit card, as applicable, on the date and in the amount requested. You agree there will be a sufficient balance to pay the requested payment and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payment. If you authorized an ACH debit entry to your account, you also authorize us to re-initiate the ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by the ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.',
          ],
        },
        {
          title: 'Preauthorized Recurring Payments',
          contents: [
            '1. Authorization for Recurring Payments. By designating a bank account or debit card for automatic recurring payments, you agree to automatic recurring payments and acknowledge and agree to the following: (a) You authorize us to initiate ACH debit entries to your bank account or initiate charges on your debit card, as applicable, on dates and in the amounts you schedule. You agree there will be a sufficient balance to pay all requested payments and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payments. If you authorized ACH debit entries to your account, you also authorize us to re-initiate any ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement. (b) You have provided us with all necessary information for the bank account or debit card that you wish to use and that the bank account or debit card is legitimate, open, and active. You agree to notify us promptly if this information changes. (c) The origination of any ACH debit entry or debit card charge must comply with U.S. law. (d) The use of automatic recurring payments is optional and not a requirement of obtaining or continuing your loan. You agree that your authorization of automatic recurring payments is completely voluntary and for your convenience.',
            '2. Right to Stop Payment and Procedure for Doing So. If you have authorized us in advance to make automatic recurring payments, you can stop these payments by (1) calling us at 1-833-684-0905, or (2) writing us at info@loopfund.com and/or P.O.Box 838, Keshena, WI 54135, in time for us to receive your request 3 business days or more before the payment is scheduled to be made. If you call, we may also require you to put your request in writing and deliver your request to us within 14 days after your call.',
            '3. Notice of Varying Amounts. You have the right to receive notice of all transfers varying in amount. You acknowledge that we elected to offer you a specified range of amounts for the automatic recurring payments (in lieu of providing the notice of transfers in varying amount). The amount of any preauthorized payment will range from (i) the schedule payment amount provided in your Loan Agreement (which may be less than a scheduled payment if partial prepayments have been made), to (ii) an amount equal to the scheduled payment plus as applicable, any other amounts I may owe under your Loan Agreement (e.g. late charge) up to an additional $100.',
            '4. Liability for Failure to Stop Payment of Preauthorized Transfer. If you order us to stop one of these payments 3 business days or more before the payment is scheduled, and we do not do so, we will be liable for your losses or damages.',
            'IMPORTANT NOTE: THIS INFORMATION IS ONLY APPLICABLE TO TRANSACTIONS BETWEEN YOU AND FOX LENDING LLC DBA LOOP FUND. FOR FURTHER INFORMATION, YOU SHOULD CONTACT YOUR ACCOUNT-HOLDING FINANCIAL INSTITUTIONS. YOU MAY HAVE ADDITIONAL RIGHTS AND OBLIGATIONS.',
          ],
          contentsLoC: [
            '1. Authorization for Recurring Payments. By designating a bank account or debit card for automatic recurring payments, you agree to automatic recurring payments and acknowledge and agree to the following: (a) You authorize us to initiate ACH debit entries to your bank account or initiate charges on your debit card, as applicable, on dates and in the amounts you schedule. You agree there will be a sufficient balance to pay all requested payments and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payments. If you authorized ACH debit entries to your account, you also authorize us to re-initiate any ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement. (b) You have provided us with all necessary information for the bank account or debit card that you wish to use and that the bank account or debit card is legitimate, open, and active. You agree to notify us promptly if this information changes. (c) The origination of any ACH debit entry or debit card charge must comply with U.S. law. (d) The use of automatic recurring payments is optional and not a requirement of obtaining or continuing your loan. You agree that your authorization of automatic recurring payments is completely voluntary and for your convenience.',
            '2. Right to Stop Payment and Procedure for Doing So. If you have authorized us in advance to make automatic recurring payments, you can stop these payments by (1) calling us at 1-833-684-0905, or (2) writing us at info@loopfund.com and/or P.O.Box 838, Keshena, WI 54135, in time for us to receive your request 3 business days or more before the payment is scheduled to be made. If you call, we may also require you to put your request in writing and deliver your request to us within 14 days after your call.',
            '3. Notice of Varying Amounts. You have the right to receive notice of all transfers varying in amount. You acknowledge that we elected to offer you a specified range of amounts for the automatic recurring payments (in lieu of providing the notice of transfers in varying amount). The amount of any preauthorized payment will range from (i) the schedule payment amount provided in your Loan Agreement (which may be less than a scheduled payment if partial prepayments have been made), to (ii) an amount equal to the scheduled payment plus as applicable, any other amounts I may owe under your Loan Agreement (e.g. late charge) up to an additional $100.',
            '4. Liability for Failure to Stop Payment of Preauthorized Transfer. If you order us to stop one of these payments 3 business days or more before the payment is scheduled, and we do not do so, we will be liable for your losses or damages.',
            'IMPORTANT NOTE: THIS INFORMATION IS ONLY APPLICABLE TO TRANSACTIONS BETWEEN YOU AND FOX LENDING LLC DBA LOOP FUND. FOR FURTHER INFORMATION, YOU SHOULD CONTACT YOUR ACCOUNT-HOLDING FINANCIAL INSTITUTIONS. YOU MAY HAVE ADDITIONAL RIGHTS AND OBLIGATIONS.',
          ],
        },
      ],
      collectionAgency: {
        name: 'Northwood Asset Management Group',
        address1: '235 College Parkway Suite 200',
        address2: 'Williamsville, NY 14221',
        phone: '1-855-677-4497',
        email: 'info@namg.biz',
      },
      nonLendingStates: ['AR', 'CT', 'DC', 'FL', 'GA', 'MA', 'ME', 'MD', 'NH', 'NY', 'PA', 'VA', 'VT', 'WV', 'WI'],
      paymentSlackChannel: 'looppayments',
      settlementSlackChannel: 'loopsettlements',
    },
    assets: {
      logo: {
        'footer-desktop': 'logo-blue@2x.png',
        'footer-mobile': 'logo-blue.png',
        'header-desktop': 'logo-blue@2x.png',
        'header-mobile': 'logo-white.png',
        'header-desktop-reverse': 'logo-white@2x.png',
        'header-mobile-reverse': 'logo-white.png',
        'fullscreen-loader': 'logo-white@2x.png',
      },
      login: {
        'iphone-help-img-1': 'iPhone-Help-01.png',
      },
    },
  },
  vault: {
    company: {
      name: 'Vault',
      nameLong: 'Vault by Kendall Bank',
      phone: '888-227-1935',
      email: 'info@myvaultloan.com',
      address1: '11225 College Blvd, Suite 150',
      address2: 'Overland Park, KS 66210',
      links: {
        mainWebsite: {
          label: 'myvaultloan.com',
          href: vaultDomain,
        },
        loanCost: {
          label: 'myvaultloan.com/cost',
          href: `${vaultDomain}/cost`,
        },
        smsTerms: {
          label: 'SMS Terms of Service',
          href: `${vaultDomain}/sms-terms-service`,
        },
        privacyPolicy: {
          label: 'Privacy Policy',
          href: `${vaultDomain}/privacy-policy`,
        },
        mla: {
          label: '',
          href: `${vaultDomain}/mla`,
        },
        nonLendingStates: {
          label: '',
          href: `${vaultDomain}/non-lending-states`,
        },
        termsOfUse: {
          label: 'Terms Of Use',
          href: `${vaultDomain}/terms-of-use`,
        },
        esignConsent: {
          label: 'E-Sign Consent',
          href: `${vaultDomain}/e-sign-consent`,
        },
      },
      smsTerms: 'I authorize Vault by Kendall Bank to send recurring text messages regarding loan status and offers at the phone number provided. Message frequency may vary and may be made using an automatic telephone dialing system. Message and data rates may apply. You may opt-out anytime, and consent is not required to receive funding.',
      disclosures: [
        'This is an expensive form of borrowing and it is not intended to be a long term financial solution. Please note: Vault by Kendall Bank loans are designed to assist you in meeting your short-term borrowing needs and are not intended to be a long term financial solution. Examples of emergency reasons why these loans might be used include unexpected emergencies, car repair bills, medical care, or essential travel expenses. Alternative forms of credit, such as a credit card cash advance, personal loan, home equity line of credit, existing savings or borrowing from a friend or relative, may be less expensive and more suitable for your financial needs.',
        'Late fees and non-sufficient funds/returned item fees may apply as described in your Loan Agreement. Our fees are disclosed in your loan agreement. Loan amounts range from $200 to $4,000. Annual Percentage Rates range from 36% to 499%. If you do not make a payment on time, we will attempt to contact you via one or more authorized methods. We adhere to the principles of the federal Fair Debt Collection Practices Act (FDCPA). Because we may report your payment history to one or more credit bureaus, late or non-payment of your loan may negatively impact your credit rating. If you fail to repay your loan in accordance with its terms, we may place your loan with or sell your loan to a third-party collection agency or other company that acquires and/or collects delinquent consumer debt.',
        'As part of our information collection process, we may detect additional bank accounts under the ownership of the consumer. We will consider these additional accounts to be part of the application process. Kendall Bank reviews your information in real-time to determine whether your information meets its lending criteria. You acknowledge that by completing and submitting the website application that you are applying for a loan. We verify applicant information through national databases, and we may pull your credit in order to determine your eligibility and ability to repay.',
        'Availability of loans are subject to change at any time at the sole discretion of Kendall Bank.',
        'Vault by Kendall Bank is a brand of Kendall Bank. All loans are issued and serviced by Kendall Bank, Kansas Branch, Member FDIC.',
      ],
      electronicPaymentDisclosure: [
        {
          title: 'Electronic Payment Disclosures and Agreement',
          contents: [
            'In this Electronic Payment Disclosures and Agreement, the words "you", "your" and "I" mean the borrower, and the words "we", "us," and "our" mean Kendall Bank, Member FDIC. This Electronic Payment Disclosures and Agreement governs electronically authorized ACH debits and debit card charges.',
            'If you believe your information has been lost or stolen, you should contact your account-holding financial institution. If you would like to also inform us of an unauthorized transfer relation to any transactions with us, please contact us in writing at info@myvaultloan.com and/or 11225 College Blvd, Suite 150, Overland Park, KS 66210. We will disclose information to third parties about your account or the transfers you make: (1) where it is necessary for completing the transfers, (2) in order to verify the existence or condition of your account for a third party such as a credit bureau or a merchant, (3) in order to comply with government agencies or court orders, or (4) if you have given us written permission. See also the Kendall Bank Privacy Policy.',
          ],
          contentsLoC: [
            'In this Electronic Payment Disclosures and Agreement, the words "you", "your" and "I" mean the borrower, and the words "we", "us," and "our" mean Kendall Bank, Member FDIC. This Electronic Payment Disclosures and Agreement governs electronically authorized ACH debits and debit card charges.',
            'If you believe your information has been lost or stolen, you should contact your account-holding financial institution. If you would like to also inform us of an unauthorized transfer relation to any transactions with us, please contact us in writing at info@myvaultloan.com and/or 11225 College Blvd, Suite 150, Overland Park, KS 66210. We will disclose information to third parties about your account or the transfers you make: (1) where it is necessary for completing the transfers, (2) in order to verify the existence or condition of your account for a third party such as a credit bureau or a merchant, (3) in order to comply with government agencies or court orders, or (4) if you have given us written permission. See also the Kendall Bank Privacy Policy.',
          ],
        },
        {
          title: 'One-Time Payments',
          contents: [
            'By designating a bank account or debit card for a one-time payment, you authorize us to initiate an ACH debit entry to your bank account or initiate a charge on your debit card, as applicable, on the date and in the amount requested. You agree there will be a sufficient balance to pay the requested payment and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payment. If you authorized an ACH debit entry to your account, you also authorize us to re-initiate the ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by the ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.',
          ],
          contentsLoC: [
            'By designating a bank account or debit card for a one-time payment, you authorize us to initiate an ACH debit entry to your bank account or initiate a charge on your debit card, as applicable, on the date and in the amount requested. You agree there will be a sufficient balance to pay the requested payment and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payment. If you authorized an ACH debit entry to your account, you also authorize us to re-initiate the ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by the ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.',
          ],
        },
        {
          title: 'Preauthorized Recurring Payments',
          contents: [
            '1. Authorization for Recurring Payments. By designating a bank account or debit card for automatic recurring payments, you agree to automatic recurring payments and acknowledge and agree to the following: (a) You authorize us to initiate ACH debit entries to your bank account or initiate charges on your debit card, as applicable, on dates and in the amounts you schedule. You agree there will be a sufficient balance to pay all requested payments and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payments. If you authorized ACH debit entries to your account, you also authorize us to re-initiate any ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement. (b) You have provided us with all necessary information for the bank account or debit card that you wish to use and that the bank account or debit card is legitimate, open, and active. You agree to notify us promptly if this information changes. (c) The origination of any ACH debit entry or debit card charge must comply with U.S. law. (d) The use of automatic recurring payments is optional and not a requirement of obtaining or continuing your loan. You agree that your authorization of automatic recurring payments is completely voluntary and for your convenience.',
            '2. Right to Stop Payment and Procedure for Doing So. If you have authorized us in advance to make automatic recurring payments, you can stop these payments by (1) calling us at 1-888-227-1935, or (2) writing us at info@myvaultloan.com and/or 11225 College Blvd, Suite 150, Overland Park, KS 66210, in time for us to receive your request 3 business days or more before the payment is scheduled to be made. If you call, we may also require you to put your request in writing and deliver your request to us within 14 days after your call.',
            '3. Notice of Varying Amounts. You have the right to receive notice of all transfers varying in amount. You acknowledge that we elected to offer you a specified range of amounts for the automatic recurring payments (in lieu of providing the notice of transfers in varying amount). The amount of any preauthorized payment will range from (i) the schedule payment amount provided in your Loan Agreement (which may be less than a scheduled payment if partial prepayments have been made), to (ii) an amount equal to the scheduled payment plus as applicable, any other amounts I may owe under your Loan Agreement (e.g. late charge) up to an additional $100.',
            '4. Liability for Failure to Stop Payment of Preauthorized Transfer. If you order us to stop one of these payments 3 business days or more before the payment is scheduled, and we do not do so, we will be liable for your losses or damages.',
            'IMPORTANT NOTE: THIS INFORMATION IS ONLY APPLICABLE TO TRANSACTIONS BETWEEN YOU AND KENDALL BANK. FOR FURTHER INFORMATION, YOU SHOULD CONTACT YOUR ACCOUNT-HOLDING FINANCIAL INSTITUTIONS. YOU MAY HAVE ADDITIONAL RIGHTS AND OBLIGATIONS.',
          ],
          contentsLoC: [
            '1. Authorization for Recurring Payments. By designating a bank account or debit card for automatic recurring payments, you agree to automatic recurring payments and acknowledge and agree to the following: (a) You authorize us to initiate ACH debit entries to your bank account or initiate charges on your debit card, as applicable, on dates and in the amounts you schedule. You agree there will be a sufficient balance to pay all requested payments and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payments. If you authorized ACH debit entries to your account, you also authorize us to re-initiate any ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement. (b) You have provided us with all necessary information for the bank account or debit card that you wish to use and that the bank account or debit card is legitimate, open, and active. You agree to notify us promptly if this information changes. (c) The origination of any ACH debit entry or debit card charge must comply with U.S. law. (d) The use of automatic recurring payments is optional and not a requirement of obtaining or continuing your loan. You agree that your authorization of automatic recurring payments is completely voluntary and for your convenience.',
            '2. Right to Stop Payment and Procedure for Doing So. If you have authorized us in advance to make automatic recurring payments, you can stop these payments by (1) calling us at 1-888-227-1935, or (2) writing us at info@myvaultloan.com and/or 11225 College Blvd, Suite 150, Overland Park, KS 66210, in time for us to receive your request 3 business days or more before the payment is scheduled to be made. If you call, we may also require you to put your request in writing and deliver your request to us within 14 days after your call.',
            '3. Notice of Varying Amounts. You have the right to receive notice of all transfers varying in amount. You acknowledge that we elected to offer you a specified range of amounts for the automatic recurring payments (in lieu of providing the notice of transfers in varying amount). The amount of any preauthorized payment will range from (i) the schedule payment amount provided in your Loan Agreement (which may be less than a scheduled payment if partial prepayments have been made), to (ii) an amount equal to the scheduled payment plus as applicable, any other amounts I may owe under your Loan Agreement (e.g. late charge) up to an additional $100.',
            '4. Liability for Failure to Stop Payment of Preauthorized Transfer. If you order us to stop one of these payments 3 business days or more before the payment is scheduled, and we do not do so, we will be liable for your losses or damages.',
            'IMPORTANT NOTE: THIS INFORMATION IS ONLY APPLICABLE TO TRANSACTIONS BETWEEN YOU AND KENDALL BANK. FOR FURTHER INFORMATION, YOU SHOULD CONTACT YOUR ACCOUNT-HOLDING FINANCIAL INSTITUTIONS. YOU MAY HAVE ADDITIONAL RIGHTS AND OBLIGATIONS.',
          ],
        },
      ],
      collectionAgency: {
        name: 'National Credit Adjusters, LLC',
        address1: '327 W. 4th',
        address2: 'Hutchinson, KS 67501',
        phone: '1-800-542-1048',
        email: 'info@ncaks.com',
      },
      nonLendingStates: ['CT', 'DC', 'IA', 'KS', 'MD', 'MO', 'NY', 'PA', 'VA', 'VT', 'WI'],
      paymentSlackChannel: 'vaultpayments',
      settlementSlackChannel: 'vaultsettlements',
    },
    assets: {
      logo: {
        'footer-desktop': 'Vault_Logo_Reverse.png',
        'footer-mobile': 'Vault_Logo_Reverse.svg',
        'header-desktop': 'Vault_Logo_Full.svg',
        'header-mobile': 'Vault_Logo_Reverse.svg',
        'header-desktop-reverse': 'Vault_Logo_Reverse.png',
        'header-mobile-reverse': 'Vault_Logo_Reverse.svg',
        'fullscreen-loader': 'Vault_Logo_Reverse.svg',
      },
      login: {
        'iphone-help-img-1': 'iPhone-Help-01.png',
      },
    },
  },
  demo: {
    company: {
      name: 'Hyphen',
      nameLong: 'Hyphen Fund',
      phone: '800.555.1234',
      email: 'info@hyphendemo.com',
      address1: 'PO Box 123',
      address2: 'Mission Woods, KS 12345',
      links: {
        mainWebsite: {
          label: 'hyphendemo.com',
          href: demoDomain,
        },
        loanCost: {
          label: 'hyphendemo.com/cost',
          href: `${demoDomain}/cost`,
        },
        smsTerms: {
          label: 'SMS Terms & Conditions',
          href: `${demoDomain}/sms-terms-conditions`,
        },
        privacyPolicy: {
          label: 'Privacy Policy',
          href: `${demoDomain}/mobile-privacy-policy`,
        },
        mla: {
          label: '',
          href: `${demoDomain}/mla`,
        },
        nonLendingStates: {
          label: '',
          href: `${demoDomain}/non-lending-states`,
        },
        termsOfUse: {
          label: 'Terms Of Use',
          href: `${demoDomain}/terms-of-use`,
        },
        esignConsent: {
          label: 'E-Sign Consent',
          href: `${demoDomain}/e-sign-consent`,
        },
      },
      smsTerms: 'I authorize Hyphen Fund to send recurring text messages regarding loan status and offers at the phone number provided. Message frequency may vary and may be made using an automatic telephone dialing system. Message and data rates may apply. You may opt-out anytime, and consent is not required to receive funding.',
      disclosures: [
        'Demo Bank, LLC dba Hyphen Fund is a commercial enterprise, which abides by the principles of federal consumer finance laws. Please note that nothing in this communication should be construed as a waiver of sovereign immunity, which is expressly reserved.',
        'Hyphen Fund does not lend to residents of AR, CT, DC, FL, GA, MA, ME, MD, NH, NY, PA, VA, VT, WV, and WI.',
        'This “prescreened” offer of credit is based on information indicating that you meet certain criteria and is subject to credit verification. In addition, to accept this offer, you must have reached 18 years old. This offer may be accepted only by the person identified in this offer and may not be transferred to any other party.',
        'This is an expensive form of borrowing. Hyphen Fund loans are designed to assist you in meeting your emergency borrowing needs and are not intended to be a long-term financial solution. Demo Bank, LLC encourages quick repayment to avoid finance fees. Alternative forms of credit may be less expensive and more suitable for your financial needs.',
        `You acknowledge that by completing and submitting the website application that you are applying for a loan. We verify applicant information through national databases and we may pull your credit in order to determine your eligibility and ability to repay.
        *Loan applications fully verified and approved by 11AM Central Time (CT) will be funded as soon as the next business day. Loan applications fully verified and approved after 11AM CT on Friday will be funded as soon as the following Monday, so long as the Monday is not a bank holiday. Demo Bank, LLC is not responsible for any additional charges applied by your financial institution.`,
        'View our website for applicable rates and frequently asked questions. Complete disclosure of APR, fees and payment terms are provided within the Loan Agreement.',
        'Hyphen Fund Customer Service Team: 800.555.1234',
        '© 2020 Demo Bank, LLC dba Hyphen Fund. All Rights Reserved.',
      ],
      electronicPaymentDisclosure: [
        {
          title: 'Electronic Payment Disclosures and Agreement',
          contents: [
            'In this Electronic Payment Disclosures and Agreement, the words "you", "your" and "I" mean the borrower, and the words "we", "us," and "our" mean Demo Bank LLC d/b/a Hyphen Fund, an economic development arm of, instrumentality of, and a limited liability company and any authorized representative, agent, independent contractor, affiliate or assignee we use in the provision of your loan. This Electronic Payment Disclosures and Agreement governs electronically authorized ACH debits and debit card charges.',
            `If you believe your information has been lost or stolen, you should contact your account-holding financial institution. If you would like to also inform us of an unauthorized transfer relation to any transactions with us, please contact us in writing at info@hyphendemo.com and/or P.O.Box 838, Keshena, WI 54135.
          We will disclose information to third parties about your account or the transfers you make: (1) where it is necessary for completing the transfers, (2) in order to verify the existence or condition of your account for a third party such as a credit bureau or a merchant, (3) in order to comply with government agencies or court orders, or (4) if you have given us written permission. See also the Demo Bank LLC DBA Hyphen Fund Privacy Policy.`,
          ],
          contentsLoC: [
            'In this Electronic Payment Disclosures and Agreement, the words "you", "your" and "I" mean the borrower, and the words "we", "us," and "our" mean Demo Bank LLC d/b/a Hyphen Fund, an economic development arm of, instrumentality of, and a limited liability company and any authorized representative, agent, independent contractor, affiliate or assignee we use in the provision of your loan. This Electronic Payment Disclosures and Agreement governs electronically authorized ACH debits and debit card charges.',
            `If you believe your information has been lost or stolen, you should contact your account-holding financial institution. If you would like to also inform us of an unauthorized transfer relation to any transactions with us, please contact us in writing at info@hyphendemo.com and/or P.O.Box 838, Keshena, WI 54135.
          We will disclose information to third parties about your account or the transfers you make: (1) where it is necessary for completing the transfers, (2) in order to verify the existence or condition of your account for a third party such as a credit bureau or a merchant, (3) in order to comply with government agencies or court orders, or (4) if you have given us written permission. See also the Demo Bank LLC DBA Hyphen Fund Privacy Policy.`,
          ],
        },
        {
          title: 'One-Time Payments',
          contents: [
            'By designating a bank account or debit card for a one-time payment, you authorize us to initiate an ACH debit entry to your bank account or initiate a charge on your debit card, as applicable, on the date and in the amount requested. You agree there will be a sufficient balance to pay the requested payment and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payment. If you authorized an ACH debit entry to your account, you also authorize us to re-initiate the ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by the ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.',
          ],
          contentsLoC: [
            'By designating a bank account or debit card for a one-time payment, you authorize us to initiate an ACH debit entry to your bank account or initiate a charge on your debit card, as applicable, on the date and in the amount requested. You agree there will be a sufficient balance to pay the requested payment and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payment. If you authorized an ACH debit entry to your account, you also authorize us to re-initiate the ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by the ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement.',
          ],
        },
        {
          title: 'Preauthorized Recurring Payments',
          contents: [
            '1. Authorization for Recurring Payments. By designating a bank account or debit card for automatic recurring payments, you agree to automatic recurring payments and acknowledge and agree to the following: (a) You authorize us to initiate ACH debit entries to your bank account or initiate charges on your debit card, as applicable, on dates and in the amounts you schedule. You agree there will be a sufficient balance to pay all requested payments and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payments. If you authorized ACH debit entries to your account, you also authorize us to re-initiate any ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement. (b) You have provided us with all necessary information for the bank account or debit card that you wish to use and that the bank account or debit card is legitimate, open, and active. You agree to notify us promptly if this information changes. (c) The origination of any ACH debit entry or debit card charge must comply with U.S. law. (d) The use of automatic recurring payments is optional and not a requirement of obtaining or continuing your loan. You agree that your authorization of automatic recurring payments is completely voluntary and for your convenience.',
            '2. Right to Stop Payment and Procedure for Doing So. If you have authorized us in advance to make automatic recurring payments, you can stop these payments by (1) calling us at 1-833-684-0905, or (2) writing us at info@loopfund.com and/or P.O.Box 838, Keshena, WI 54135, in time for us to receive your request 3 business days or more before the payment is scheduled to be made. If you call, we may also require you to put your request in writing and deliver your request to us within 14 days after your call.',
            '3. Notice of Varying Amounts. You have the right to receive notice of all transfers varying in amount. You acknowledge that we elected to offer you a specified range of amounts for the automatic recurring payments (in lieu of providing the notice of transfers in varying amount). The amount of any preauthorized payment will range from (i) the schedule payment amount provided in your Loan Agreement (which may be less than a scheduled payment if partial prepayments have been made), to (ii) an amount equal to the scheduled payment plus as applicable, any other amounts I may owe under your Loan Agreement (e.g. late charge) up to an additional $100.',
            '4. Liability for Failure to Stop Payment of Preauthorized Transfer. If you order us to stop one of these payments 3 business days or more before the payment is scheduled, and we do not do so, we will be liable for your losses or damages.',
            'IMPORTANT NOTE: THIS INFORMATION IS ONLY APPLICABLE TO TRANSACTIONS BETWEEN YOU AND DEMO BANK LLC DBA HYPHEN FUND. FOR FURTHER INFORMATION, YOU SHOULD CONTACT YOUR ACCOUNT-HOLDING FINANCIAL INSTITUTIONS. YOU MAY HAVE ADDITIONAL RIGHTS AND OBLIGATIONS.',
          ],
          contentsLoC: [
            '1. Authorization for Recurring Payments. By designating a bank account or debit card for automatic recurring payments, you agree to automatic recurring payments and acknowledge and agree to the following: (a) You authorize us to initiate ACH debit entries to your bank account or initiate charges on your debit card, as applicable, on dates and in the amounts you schedule. You agree there will be a sufficient balance to pay all requested payments and agree that we are not liable for any overdraft or insufficient fund charges caused by your failure to maintain funds sufficient to cover the scheduled payments. If you authorized ACH debit entries to your account, you also authorize us to re-initiate any ACH up to two additional times for the same amount if the ACH is dishonored. If any payment cannot be obtained by ACH debit entry or debit card charge, you remain responsible for such payment and any resulting fees under your Loan Agreement. (b) You have provided us with all necessary information for the bank account or debit card that you wish to use and that the bank account or debit card is legitimate, open, and active. You agree to notify us promptly if this information changes. (c) The origination of any ACH debit entry or debit card charge must comply with U.S. law. (d) The use of automatic recurring payments is optional and not a requirement of obtaining or continuing your loan. You agree that your authorization of automatic recurring payments is completely voluntary and for your convenience.',
            '2. Right to Stop Payment and Procedure for Doing So. If you have authorized us in advance to make automatic recurring payments, you can stop these payments by (1) calling us at 1-800-555-1234, or (2) writing us at info@hyphendemo.com and/or P.O.Box 838, Keshena, WI 54135, in time for us to receive your request 3 business days or more before the payment is scheduled to be made. If you call, we may also require you to put your request in writing and deliver your request to us within 14 days after your call.',
            '3. Notice of Varying Amounts. You have the right to receive notice of all transfers varying in amount. You acknowledge that we elected to offer you a specified range of amounts for the automatic recurring payments (in lieu of providing the notice of transfers in varying amount). The amount of any preauthorized payment will range from (i) the schedule payment amount provided in your Loan Agreement (which may be less than a scheduled payment if partial prepayments have been made), to (ii) an amount equal to the scheduled payment plus as applicable, any other amounts I may owe under your Loan Agreement (e.g. late charge) up to an additional $100.',
            '4. Liability for Failure to Stop Payment of Preauthorized Transfer. If you order us to stop one of these payments 3 business days or more before the payment is scheduled, and we do not do so, we will be liable for your losses or damages.',
            'IMPORTANT NOTE: THIS INFORMATION IS ONLY APPLICABLE TO TRANSACTIONS BETWEEN YOU AND HYPHEN LLC DBA HYPHEN FUND. FOR FURTHER INFORMATION, YOU SHOULD CONTACT YOUR ACCOUNT-HOLDING FINANCIAL INSTITUTIONS. YOU MAY HAVE ADDITIONAL RIGHTS AND OBLIGATIONS.',
          ],
        },
      ],
      collectionAgency: {
        name: 'Northwood Asset Management Group',
        address1: '235 College Parkway Suite 200',
        address2: 'Williamsville, NY 14221',
        phone: '1-855-677-4497',
        email: 'info@namg.biz',
      },
      nonLendingStates: ['AR', 'CT', 'DC', 'FL', 'GA', 'MA', 'ME', 'MD', 'NH', 'NY', 'PA', 'VA', 'VT', 'WV', 'WI'],
      paymentSlackChannel: 'demopayments',
      settlementSlackChannel: 'demosettlements',
    },
    assets: {
      logo: {
        'footer-desktop': 'demo-blue@2x.png',
        'footer-mobile': 'demo-blue.png',
        'header-desktop': 'demo-blue@2x.png',
        'header-mobile': 'demo-white.png',
        'header-desktop-reverse': 'demo-white@2x.png',
        'header-mobile-reverse': 'demo-white.png',
        'fullscreen-loader': 'demo-white@2x.png',
      },
      login: {
        'iphone-help-img-1': 'iPhone-Help-01.png',
      },
    },
  },
  raven: {
    company: {
    },
  },
};

export default {
  namespaced: true,
  state: initialState,
};
