<template>
  <v-dialog
    v-model="disclosureDialog"
    width="1000px"
    scrollable
  >
    <template #activator="{ on: disclosureDialog }">
      <v-btn
        color="primary"
        block
        :loading="isDisclosureLoading"
        :disabled="forcedDisabled || isViewDisclosureDisabled"
        :class="disclosuresSent ? 'mb-4 success' : 'mb-4 error'"
        v-on="{...disclosureDialog}"
      >
        {{ viewDisclosureButtonText }}
      </v-btn>
    </template>

    <v-card :loading="loadingModel">
      <v-card-text class="py-5">
        <div class="row">
          <div class="col-12">
            <v-btn
              :loading="isDisclosureLoading"
              :disabled="isSendDisclosureDisabled"
              :class="disclosuresSent ? 'ml-4 mb-4 success' : 'ml-4 mb-4 error'"
              @click="onSendDisclosure()"
            >
              {{ sendDisclosureButtonText }}
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <template v-if="!isLineOfCredit">
              <h5>{{ settings.installment.electronic_payment_disclosure_title }}</h5>
              <p
                v-for="(content, index)
                  in settings.installment.electronic_payment_disclosure_contents"
                :key="`d${index}`"
              >
                {{ content }}
              </p>
              <h5>{{ settings.installment.one_time_payments_title }}</h5>
              <p
                v-for="(content, index)
                  in settings.installment.one_time_payments_contents"
                :key="`p${index}`"
              >
                {{ content }}
              </p>
              <h5>{{ settings.installment.preauthorized_recurring_payments_title }}</h5>
              <p
                v-for="(content, index)
                  in settings.installment.preauthorized_recurring_payments_contents"
                :key="`r${index}`"
              >
                {{ content }}
              </p>
            </template>
            <template v-else>
              <h5>{{ settings.lineOfCredit.electronic_payment_disclosure_title }}</h5>
              <p
                v-for="(content, index)
                  in settings.lineOfCredit.electronic_payment_disclosure_contents"
                :key="`d${index}`"
              >
                {{ content }}
              </p>
              <h5>{{ settings.lineOfCredit.one_time_payments_title }}</h5>
              <p
                v-for="(content, index)
                  in settings.lineOfCredit.one_time_payments_contents"
                :key="`p${index}`"
              >
                {{ content }}
              </p>
              <h5>{{ settings.lineOfCredit.preauthorized_recurring_payments_title }}</h5>
              <p
                v-for="(content, index)
                  in settings.lineOfCredit.preauthorized_recurring_payments_contents"
                :key="`r${index}`"
              >
                {{ content }}
              </p>
            </template>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapActions } from 'vuex';

export default {
  name: 'DisclosureModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    forcedDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      disclosureDialog: false,
      loadingModel: false,
      valid: true,
      isDisclosureLoading: false,
      isViewDisclosureDisabled: false,
      isSendDisclosureDisabled: false,
      viewDisclosureButtonText: 'view electronic payment disclosures',
      sendDisclosureButtonText: 'send disclosure via zendesk',
    };
  },
  computed: {
    ...mapState('app', ['instanceId']),
    ...mapState('settlement', ['disclosuresSent']),
    ...mapState('ticket', ['ticket']),
    ...mapState('settings', ['settings']),

    zendeskEmailDisclosures() {
      if (this.isLineOfCredit) {
        return this.buildForLineOfCredit();
      }
      return this.buildForInstallment();
    },

    isLineOfCredit() {
      const type = this.ticket?.active_loan?.loan_setup?.loan_type || '';
      return type === 'loan.type.creditLimit';
    },
  },
  watch: {
    disclosuresSent(newVal) {
      if (newVal === true) {
        this.disclosureDialog = false;
      }
    },
  },
  methods: {
    ...mapActions('settlement', ['zendeskSend']),
    async onSendDisclosure() {
      this.isDisclosureLoading = true;
      this.isViewDisclosureDisabled = true;
      this.isSendDisclosureDisabled = true;

      await this.zendeskSend({
        ticketId: this.$route.params.id,
        message: this.zendeskEmailDisclosures,
        action: 'SET_DISCLOSURES_SENT',
      });

      const disclosureButtonText = 'disclosures sent';
      this.viewDisclosureButtonText = disclosureButtonText;
      this.sendDisclosureButtonText = disclosureButtonText;

      this.isDisclosureLoading = false;
      this.isViewDisclosureDisabled = false;
    },
    buildForInstallment() {
      let disclosureContent = '';

      disclosureContent += `${this.settings.installment.electronic_payment_disclosure_title}\n\n`;

      this.settings.installment.electronic_payment_disclosure_contents.forEach((content) => {
        disclosureContent += `${content}\n\n`;
      });

      disclosureContent += `${this.settings.installment.one_time_payments_title}\n\n`;

      this.settings.installment.one_time_payments_contents.forEach((content) => {
        disclosureContent += `${content}\n\n`;
      });

      disclosureContent += `${this.settings.installment.preauthorized_recurring_payments_title}\n\n`;

      this.settings.installment.preauthorized_recurring_payments_contents.forEach((content) => {
        disclosureContent += `${content}\n\n`;
      });

      return disclosureContent;
    },

    buildForLineOfCredit() {
      let disclosureContent = '';

      disclosureContent += `${this.settings.lineOfCredit.electronic_payment_disclosure_title}\n\n`;

      this.settings.lineOfCredit.electronic_payment_disclosure_contents.forEach((content) => {
        disclosureContent += `${content}\n\n`;
      });

      disclosureContent += `${this.settings.lineOfCredit.one_time_payments_title}\n\n`;

      this.settings.lineOfCredit.one_time_payments_contents.forEach((content) => {
        disclosureContent += `${content}\n\n`;
      });

      disclosureContent += `${this.settings.lineOfCredit.preauthorized_recurring_payments_title}\n\n`;

      this.settings.lineOfCredit.preauthorized_recurring_payments_contents.forEach((content) => {
        disclosureContent += `${content}\n\n`;
      });

      return disclosureContent;
    },
  },
};
</script>

<style>
.v-btn--disabled .v-btn__content {
  color: green;
}
</style>
