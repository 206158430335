<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col
        md="3"
        style="max-width: 300px"
      >
        <v-card
          rounded="lg"
          tile
        >
          <v-card-title class="text-uppercase">
            System Settings
          </v-card-title>
          <v-card-text class="px-0 py-0">
            <v-tabs vertical>
              <template v-for="(item, index) in getMenus">
                <v-tab
                  v-if="hasPermission(item.permission)"
                  :key="index"
                  :to="{ name: item.route }"
                >
                  {{ item.label }}
                </v-tab>
                <v-tab
                  v-else
                  :key="index"
                >
                  <disabled-for-lack-of-role-btn
                    :text="item.label"
                    :text-button="true"
                    :permission="item.permission"
                    :x-small="false"
                  />
                </v-tab>
              </template>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card
          tile
          rounded="lg"
        >
          <router-view />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    DisabledForLackOfRoleBtn,
  },

  computed: {
    ...mapGetters({
      getMenus: 'systemSettingsMenu/getMenus',
      getFirstItemRouteName: 'systemSettingsMenu/getFirstItemRouteName',
    }),
  },
};
</script>

<style></style>
