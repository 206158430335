<template>
  <v-menu bottom right offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
    </template>

    <v-list class="pa-0" style="min-width: 250px">
      <v-list-item>
        <v-list-item-title class="text-body-2">
          <strong class="text-body-1 font-weight-bold">Ops Ticket Settings</strong><br />
        </v-list-item-title>
      </v-list-item>

      <v-list-item>
        <OpsTicketStatusDialog />
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="text-body-2">
          <OpsTicketClassificationDialog />
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import OpsTicketStatusDialog from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketStatusDialog.vue';
import OpsTicketClassificationDialog from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketClassificationDialog.vue';

export default {
  name: 'OpsTicketMenu',
  components: { OpsTicketClassificationDialog, OpsTicketStatusDialog },

  data: () => ({
    statusModal: false,
  }),

  mounted() {
    this.getStatus();
  },

  methods: {
    getStatus() {},
  },
};
</script>

<style scoped></style>
