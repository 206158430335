import Vue from 'vue';
import { DateTime } from 'luxon';

const initialState = {
  activeLoan: {},
  autopays: [],
  payments: [],
  paymentSchedules: [],
  isRescind: false,
  nextScheduledPayment: {},
  todayScheduledPayment: {},
  fraudItemToReview: {},
};

const getters = {
  loanType(state) {
    return state.activeLoan?.loan_setup.loan_type || null;
  },
  isPastDue(state) {
    if (state.loanType === 'loan.type.creditLimit') {
      if (!state.activeLoan.latest_statement) {
        return false;
      }
      return state.activeLoan.latest_statement.past_due_amount > 0;
    }
    return state.activeLoan.current_status.amount_due > 0;
  },
  paymentTypes(state) {
    let result = [
      { id: 18, title: 'Settlement', other: 'Settlement' },
      { id: 2, title: 'Principal', other: 'Regular' },
      { id: 8, title: 'IPF', other: 'Regular' },
      { id: 13, title: 'Payoff', other: 'PayOff' },
    ];

    const settlementStatuses = [
      'Portal 3 - Pay In Full',
      'Portal 2 - Full Settlement',
      'Portal 1 - Partial Settlement',
      'Partial Settlement Completed',
      'Portal 3 - Final Payment Pending',
      'Debt Consolidation',
      'Non-Accrual',
      'Settled in Full',
      'Final Payment Pending',
      'Payment Plan',
    ];

    if (settlementStatuses.includes(state.activeLoan.current_status.loan_sub_status_text)) {
      result = result.filter((rs) => ['settlement'].includes(rs.title.toLowerCase()));
    } else if (getters.isPastDue) {
      result = result.filter((rs) => ['ipf'].includes(rs.title.toLowerCase()));
    } else if (!getters.isPastDue) {
      result = result.filter((rs) => ['principal', 'payoff'].includes(rs.title.toLowerCase()));
    }

    return result;
  },
  recurringAutopays(state) {
    return state.autopays.filter((autopay) => {
      //  check for recuring payments
      const recuringPayment = ['autopay.type.recurring', 'autopay.type.recurring_match'].includes(autopay.type);
      // check for pending p
      const pendingPayment = autopay.status === 'autopay.status.pending';
      return recuringPayment && pendingPayment;
    });
  },
};

const actions = {
  async getAutopays({ commit, state, dispatch }) {
    try {
      const res = await Vue.prototype.$axios
        .post('api/v1/support/ticket/loan/autopays', {
          loanId: state.activeLoan.id,
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
        });
      commit('SET_AUTOPAYS', res.data);
    } catch (error) {
      let errMsg = 'There was an error when sending an API request to collect Autopay Data. Please try again. If there error persists, please notify your supervisor.';
      if (
        error.response
        && (error.response.status === 404 || error.response.status === 403)
        && error.response.data
      ) {
        errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
  async getPaymentSchedule({ commit, state, dispatch }) {
    try {
      const res = await Vue.prototype.$axios
        .post('api/v1/support/ticket/loan/payment-schedule', {
          loanId: state.activeLoan.id,
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
        });
      commit('SET_PAYMENT_SCHEDULES', res.data);
    } catch (error) {
      let errMsg = 'There was an error when sending an API request to get Payment Schedule Data. Please try again. If there error persists, please notify your supervisor.';
      if (error.response && error.response.status === 404 && error.response.data) {
        errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
  async getPayments({ commit, state, dispatch }) {
    try {
      const res = await Vue.prototype.$axios
        .post('api/v1/support/ticket/loan/payments', {
          loanId: state.activeLoan.id,
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
        });
      commit('SET_PAYMENTS', res.data);
      commit('SET_TODAY_SCHEDULED_PAYMENT');
    } catch (error) {
      let errMsg = 'There was an error when sending an API request to collect Payment Data. Please try again. If there error persists, please notify your supervisor.';
      if (error.response && error.response.status === 404 && error.response.data) {
        errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
  async setActiveLoan({ commit }, data) {
    commit('SET_ACTIVE_LOAN', data);
  },
  async rescindLoanBeforeFunding({ commit, state, dispatch }) {
    try {
      const res = await Vue.prototype.$axios
        .post('api/v1/support/ticket/loan/rescind-before-funding', {
          loanId: state.activeLoan.id,
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
          customer: this.state.loanProCustomer.customer,
        });
      commit('SET_IS_RESCIND', res.data.isRescind);
      dispatch('notification/setNotification', { message: res.data.message, color: 'success' }, { root: true });
    } catch (error) {
      let errMsg = 'There was an error when sending an API request to rescind loan. Please try again. If there error persists, please notify your supervisor.';
      if (error.response && error.response.status === 404 && error.response.data) {
        errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
  async rescindLoanAfterFunding({ commit, state, dispatch }, data) {
    try {
      const res = await Vue.prototype.$axios
        .post(`api/v1/support/ticket/loan/rescind-after-funding/payment/${state.activeLoan.id}/${data.paymentAccount}`, {
          loanId: state.activeLoan.id,
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
          customer: this.state.loanProCustomer.customer,
          paymentDate: data.paymentDate,
          paymentTypeId: data.paymentTypeId,
          paymentAmount: data.paymentAmount,
          immediatePayment: data.immediatePayment ? data.immediatePayment : false,
        });
      commit('SET_IS_RESCIND', res.data.isRescind);
      dispatch('notification/setNotification', { message: res.data.message, color: 'success' }, { root: true });

      return true;
    } catch (error) {
      let errMsg = 'There was an error when sending an API request to rescind loan. Please try again. If there error persists, please notify your supervisor.';
      if (error.response && error.response.status === 404 && error.response.data) {
        errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
      }
      if (error.response && error.response.data.errors) {
        // eslint-disable-next-line prefer-destructuring
        errMsg = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });

      return false;
    }
  },
  async getNextScheduledPayment({ commit, state, dispatch }) {
    try {
      const res = await Vue.prototype.$axios
        .get(`api/v1/support/ticket/loan/${state.activeLoan.id}/autopays/next-payment-summary`);
      commit('SET_NEXT_SCHEDULED_PAYMENT', res.data);
    } catch (error) {
      let errMsg = 'There was an error when sending an API request to collect next payment summary. Please try again. If there error persists, please notify your supervisor.';
      if (
        error.response
        && (error.response.status === 404 || error.response.status === 403)
        && error.response.data
      ) {
        errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
  async getFraudItemsToReview({ commit, state, dispatch }) {
    try {
      const res = await Vue.prototype.$axios
        .post('api/v1/support/ticket/fraud-claim/items-review', {
          loanId: state.activeLoan.id,
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
          customer: this.state.ticket.ticket.lpCustomer,
        });
      commit('SET_FRAUD_ITEM_TO_REVIEW', res.data.data);
    } catch (error) {
      let errMsg = 'Something went wrong. Items to review failed';
      if (error.response && error.response.status === 404 && error.response.data) {
        errMsg = error.response.data.message;
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
};

const mutations = {
  SET_AUTOPAYS(state, data) {
    state.autopays = data;
  },
  SET_PAYMENTS(state, data) {
    state.payments = data;
  },
  SET_PAYMENT_SCHEDULES(state, data) {
    state.paymentSchedules = data;
  },
  SET_ACTIVE_LOAN(state, data) {
    state.activeLoan = data;
    // 55 - loan rescinded;
    if ([55].includes(data.current_status.loan_sub_status_id)) {
      state.isRescind = true;
    }
  },
  SET_IS_RESCIND(state, data) {
    state.isRescind = data;
  },
  SET_NEXT_SCHEDULED_PAYMENT(state, data) {
    state.nextScheduledPayment = data;
  },
  SET_TODAY_SCHEDULED_PAYMENT(state) {
    // get a payment greater or equal today
    state.todayScheduledPayment = state.payments.find((ps) => DateTime.local().startOf('day') <= (DateTime.fromSQL(ps.apply_date).startOf('day')));
  },
  SET_FRAUD_ITEM_TO_REVIEW(state, data) {
    const items = data;
    const { payments } = state;
    items.payments_attempted = (payments.length > 0) ? 'yes' : 'no';
    items.payments_successful = (payments).some((payment) => payment.status === 'payment.status.success') === true ? 'yes' : 'no';
    items.payments_returned = (payments).some((payment) => payment.reverse_date !== null) === true ? 'yes' : 'no';
    const reversedPayment = (payments).find((payment) => (payment.reverse_date !== null));
    if (reversedPayment && Object.keys(reversedPayment).length > 0) {
      items.payments_returned_code = reversedPayment.nacha_return_code;
    } else {
      items.payments_returned_code = null;
    }
    state.fraudItemToReview = items;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
