<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <v-card-title> Adverse Action Failures Report </v-card-title>
      </div>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="fromDate"
                required
                label="From Date"
                type="date"
                :error-messages="fromDateErrors"
                @input="fromDateOnInputOrBlur"
                @blur="fromDateOnInputOrBlur"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="toDate"
                required
                label="To Date"
                type="date"
                :error-messages="toDateErrors"
                @input="toDateOnInputOrBlur"
                @blur="toDateOnInputOrBlur"
              />
            </v-col>
          </v-row>

          <v-btn
            class="mt-5 mb-5 btn-bg-deep-blue"
            :disabled="formProcessing"
            :loading="reportGenerating"
            @click="generateReport()"
          >
            Generate Report
          </v-btn>
        </v-form>
      </v-container>
    </v-card>

    <template v-if="reportLoaded">
      <v-btn
        v-if="reportLoaded"
        color="green"
        class="ml-3 mt-8"
        @click="downloadReport()"
      >
        Download This Report
      </v-btn>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-1 mt-2"
      >
        <template v-slot:top>
          <v-card-title>
            <v-toolbar flat>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-toolbar>
          </v-card-title>
        </template>
      </v-data-table>
    </template>
    <template v-if="reportLoaded">
      <v-simple-table class="mt-2">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                Total Leads
              </th>
              <th class="text-center">
                Accepted Leads
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                {{ reportTotals.total_leads }}
              </td>
              <td class="text-center">
                {{ reportTotals.accepted_leads }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import { DateTime } from 'luxon';

const lessThanToDate = (value, vm) => (value <= vm.toDate);
const greaterThanFromDate = (value, vm) => (value >= vm.fromDate);

export default {
  name: 'AdverseActionFailuresReportIndex',

  data() {
    return {
      fromDate: DateTime.local().toISODate(),
      toDate: DateTime.local().toISODate(),
      search: '',
      reportData: [],
      reportTotals: {},
      reportLoaded: false,
      formProcessing: false,
      reportGenerating: false,
      headers: [
        { text: 'Waterfall Filter ID', value: 'waterfall_filter_id' },
        { text: 'Filter Slug', value: 'filter_slug' },
        { text: 'Category', value: 'category' },
        { text: 'Count', value: 'count' },
      ],
      items: [],
    };
  },
  computed: {
    fromDateErrors() {
      const errors = [];

      if (!this.$v.fromDate.$dirty) {
        return errors;
      }
      if (!this.$v.fromDate.required) {
        errors.push('From Date is required.');
      }

      return errors;
    },
    toDateErrors() {
      const errors = [];

      if (!this.$v.toDate.$dirty) {
        return errors;
      }
      if (!this.$v.toDate.required) {
        errors.push('To Date is required.');
      }

      return errors;
    },
  },
  created() {
    const routeParams = this.$route.params;
    let generateReport = false;

    if (routeParams.fromDate) {
      this.fromDate = routeParams.fromDate;
      generateReport = true;
    }

    if (routeParams.toDate) {
      this.toDate = routeParams.toDate;
      generateReport = true;
    }

    if (generateReport) {
      this.generateReport();
    }
  },
  methods: {
    generateReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .get('api/v1/reports/adverse-action-failures', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          })
          .then((res) => {
            vm.items = Object.values(res.data.failures);
            vm.reportTotals = { ...res.data.totals };
            vm.reportLoaded = true;
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },

    downloadReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = true;
        this.reportGenerating = false;

        this.$axios
          .get('api/v1/reports/adverse-action-failures/download', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          })
          .then((res) => {
            const blob = new Blob([res.data], { type: 'text/csv' });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = 'adverseActionFailuresExport.csv';
            link.click();

            link.remove();
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },

    resetDateValidators() {
      this.$v.fromDate.$reset();
      this.$v.toDate.$reset();
    },

    touchDateValidators() {
      this.$v.fromDate.$touch();
      this.$v.toDate.$touch();
    },

    fromDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.fromDateErrors.push('From Date cannot be after To Date.');
      }
    },

    toDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.toDateErrors.push('To Date cannot be before From Date.');
      }
    },
  },
  validations: {
    fromDate: { required, lessThanToDate },
    toDate: { required, greaterThanFromDate },
  },
};
</script>

<style></style>
