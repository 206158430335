<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      v-if="ok"
      cols="12"
    >
      <v-stepper
        v-model="currentStep"
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="currentStep > 1"
            step="1"
          >
            Confirm Eligibility
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="currentStep > 2"
            step="2"
          >
            Calculate &amp; Review Payment
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="complete"
            step="3"
          >
            Sign Loan Documents
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div
              class="pb-2"
            >
              <v-row>
                <v-col
                  cols="6"
                  class="mb-0"
                />
              </v-row>
              <v-row class="mt-5">
                <v-col
                  cols="12"
                  class="mb-0"
                >
                  <p>
                    <b>Note For Phone Agent:</b>
                  </p>
                  <p>
                    Read the following:
                  </p>
                  <v-alert
                    prominent
                    dense
                    dark
                    text
                    border="left"
                    color="primary"
                    icon="mdi-account-voice"
                  >
                    <blockquote class="blockquote">
                      "When changing payment frequency, you may see a change in the
                      total payment amount. If you would like to proceed, please
                      confirm your next two pay dates."
                    </blockquote>
                  </v-alert>
                  <p>
                    The customer must call in to complete a loan modification due to the
                    time sensitive nature of sending the modified documents and receiving
                    signed documents to complete the modification.
                  </p>
                </v-col>
              </v-row>
              <v-btn
                class="mr-2"
                color="primary"
                @click="skipChangeScheduleEmail = true"
              >
                Continue
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div
              class="pt-2 pb-2"
            >
              <v-row>
                <v-col
                  cols="12"
                  class="mb-0"
                >
                  <v-select
                    v-model="paymentFrequency"
                    :items="availablePaymentFrequencies"
                    item-value="id"
                    item-text="title"
                    label="Payment Frequency"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="mb-0"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="firstPaymentDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs}">
                      <v-text-field
                        v-model="firstPaymentDateFormatted"
                        label="First Payment Date"
                        append-icon="mdi-calendar"
                        placeholder="MM/DD/YYYY"
                        :error-messages="firstPaymentDateError"
                        outlined
                        required
                        readonly
                        v-bind="attrs"
                        @change="validateFirstPaymentDate"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="firstPaymentDate"
                      :active-picker.sync="activePicker"
                      no-title
                      :min="stringMinPaymentDate"
                      :max="stringMaxPaymentDate"
                      :allowed-dates="allowedDates"
                      @input="menu = false"
                      @change="pickDate"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-btn
                class="mr-2"
                color="primary"
                :loading="loadingModel"
                :disabled="loadingModel || !paymentFrequency || !firstPaymentDate"
                @click="calculatePayments()"
              >
                Calculate New Payment
              </v-btn>
            </div>
            <div
              v-if="status.after"
              class="pb-2"
            >
              To send new loan documents to the borrow with the following
              new payment schedule click continue.
              <v-row>
                <v-col>
                  <v-list dense>
                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Payment Frequency</v-list-item-title>
                        <v-list-item-subtitle
                          v-if="currentPaymentFrequency !=''"
                        >
                          {{ currentPaymentFrequency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Approximate Payment Amount</v-list-item-title>
                        <v-list-item-subtitle
                          v-if="currentPaymentFrequency !=''"
                        >
                          ${{ status.after.tilPaymentSchedule[0].payment }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-else
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="mb-0"
                >
                  <v-select
                    v-model="paymentProfile"
                    :items="customer.paymentProfiles"
                    item-value="id"
                    item-text="title"
                    label="Payment Method"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="mb-0"
                >
                  <p>
                    <b>Note For Phone Agent:</b>
                  </p>
                  <p>
                    Read the following:
                  </p>
                  <v-alert
                    prominent
                    dense
                    dark
                    text
                    border="left"
                    color="primary"
                    icon="mdi-account-voice"
                  >
                    <blockquote class="blockquote">
                      Thank you for confirming your new pay dates. Your new approximate payment is
                      ${{ status.after.tilPaymentSchedule[0].payment }}.
                      If you are ready to complete this change,
                      I can send you the Modification Agreement.
                      This link will only be accessible for 10 minutes so it’s
                      important you’re able to review it now.
                    </blockquote>
                  </v-alert>
                </v-col>
              </v-row>
              <v-btn
                class="mr-2"
                color="primary"
                :disabled="!formValid"
                @click="confirmAcceptModal = true"
              >
                Continue
              </v-btn>

              <v-btn
                @click="status.before = {}"
              >
                Cancel
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div
              v-if="status.docusignRequest && complete"
              class="mb-12"
            >
              <p>The loan documents were signed successfully.</p>
              <p>The ticket was marked as solved.</p>
            </div>
            <div
              v-if="status.docusignRequest && !complete"
              class="mb-12"
            >
              This loan modification has loan documents awaiting a signature.
              <span v-if="pendingPayment & status.docusignRequest">There is a
                pending payment the requires a waiting period before the loan
                documents can be signed.</span>
              <v-list dense>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content
                    v-if="status.docusignRequest.sent_at"
                  >
                    <v-list-item-title>Documents Were Emailed</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatDate(status.docusignRequest.send_at) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content
                    v-else-if="!status.docusignRequest.sent_at"
                  >
                    <v-list-item-title>Documents Will Be Emailed</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatDate(status.docusignRequest.send_at) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Documents Must Be Signed By</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatDate(status.docusignRequest.expire_at) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div
                v-if="pendingPayment
                  && status.docusignRequest
                  && !status.docusignRequest.sent_at"
              >
                <p>
                  <b>Note For Phone Agent:</b>
                </p>
                <p>
                  Read the following:
                </p>
                <v-alert
                  prominent
                  dense
                  dark
                  text
                  border="left"
                  color="primary"
                  icon="mdi-account-voice"
                >
                  <blockquote class="blockquote">
                    "You will need to sign new loan documents for the update to be
                    effective. Since you have a pending payment, a 3-day waiting period
                    is required so the payment applies to your account. Revised loan
                    documents will be sent 3 days after the payment was made. Please
                    watch for these loan documents, as the documents will expire within
                    10 minutes. We will provide a courtesy phone call once the documents
                    are sent."
                  </blockquote>
                </v-alert>
              </div>
              <div
                v-if="pendingPayment
                  && status.docusignRequest
                  && status.docusignRequest.sent_at"
              >
                <p>
                  <b>Note For Phone Agent:</b>
                </p>
                <p>
                  Read the following:
                </p>
                <v-alert
                  prominent
                  dense
                  dark
                  text
                  border="left"
                  color="primary"
                  icon="mdi-account-voice"
                >
                  <blockquote class="blockquote">
                    "This call is a courtesy reminder from Helix Financial. Your
                    updated loan documents will be emailed to you shortly, if you
                    have not already received them. Please review and sign within
                    10 minutes for your loan modification to be effective. If the
                    loan documents are not signed within 10 minutes, the previous
                    loan agreement payment and due dates will remain effective.
                    You will be responsible for making the remaining loan payments
                    manually through the customer portal."
                  </blockquote>
                </v-alert>
              </div>
              <div
                v-if="!pendingPayment && status.docusignRequest"
              >
                <p>
                  <b>Note For Phone Agent:</b>
                </p>
                <p>
                  Read the following:
                </p>
                <v-alert
                  prominent
                  dense
                  dark
                  text
                  border="left"
                  color="primary"
                  icon="mdi-account-voice"
                >
                  <blockquote class="blockquote">
                    "Your
                    updated loan documents will be emailed to you shortly, if you
                    have not already received them. Please review and sign within
                    10 minutes for your loan modification to be effective. If the
                    loan documents are not signed within 10 minutes, the previous
                    loan agreement payment and due dates will remain effective.
                    You will be responsible for making the remaining loan payments
                    manually through the customer portal."
                  </blockquote>
                </v-alert>
              </div>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-dialog
        v-if="status && status.after"
        v-model="confirmAcceptModal"
        max-width="500px"
        @click:outside="confirmAcceptModal = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Are you sure?</span>
          </v-card-title>

          <v-card-text>
            Clicking confirm will email the customer prompting them to
            sign new loan documents with the following payments.
            <v-row
              v-for="(schedule, key) in status.after.tilPaymentSchedule"
              :key="key"
            >
              <v-col>
                <v-list dense>
                  <v-list-item
                    two-line
                    class="pl-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Payment Frequency</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="currentPaymentFrequency != ''"
                      >
                        {{ currentPaymentFrequency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    two-line
                    class="pl-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>First Payment Date</v-list-item-title>
                      <v-list-item-subtitle>{{ schedule.startDate }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    two-line
                    class="pl-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Approximate Payment Amount</v-list-item-title>
                      <v-list-item-subtitle>${{ schedule.payment }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              v-if="hasPermission('create ticket::modify-loan')"
              color="primary"
              :loading="loadingModel"
              :disabled="loadingModel"
              @click="sendLoanDocuments()"
            >
              Confirm
            </v-btn>
            <disabled-for-lack-of-role-btn
              v-else
              text="Confirm"
              permission="create ticket::modify-loan"
              :x-small="false"
            />

            <v-btn
              @click="confirmAcceptModal = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="status && status.after"
        v-model="hasProcessingModal"
        max-width="500px"
        @click:outside="hasProcessingModal = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">There's a pending payment</span>
          </v-card-title>

          <v-card-text>
            Read the following:
            <blockquote>
              In order to change your payment due schedule, we need to complete a loan
              modification but are unable to do this while you have a payment that is pending
              or in our payment application waiting period. Your payment will clear on
              {{ this.status.cureDate }}. At that time we will
              follow up with you or you can contact us to complete this process.
            </blockquote>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              v-if="hasPermission('create ticket::modify-loan')"
              color="primary"
              :loading="loadingModel"
              :disabled="loadingModel"
              @click="mailAndWait()"
            >
              Email Information
            </v-btn>
            <disabled-for-lack-of-role-btn
              v-else
              text="Confirm"
              permission="create ticket::modify-loan"
              :x-small="false"
            />

            <v-btn
              @click="hasProcessingModal = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col
      v-if="collections"
      cols="12"
    >
      <v-card>
        <v-card-title>
          Loan Modification
        </v-card-title>
        <v-card-text>
          <p>
            Unfortunately, this customer does not qualify for a loan modification at this time.
          </p>
          <v-row>
            <v-col
              cols="6"
            >
              <reusable-communication-by-email-template
                :customer="ticket.lpCustomer"
                :ticket-id="ticket.zendesk.id * 1"
                title="Send Instructions For Settlement Dashboard"
                action="portal-login-instructions-settlement"
                tag="portal_login_instructions_settlement"
                :extra="{}"
                @success="handleSuccess('macro')"
              />
            </v-col>
          </v-row>
          <p class="mt-5">
            <b>Note For Phone Agent:</b>
          </p>
          <p>
            Read the following:
          </p>
          <v-alert
            prominent
            dense
            dark
            text
            border="left"
            color="primary"
            icon="mdi-account-voice"
          >
            <blockquote class="blockquote">
              "Based on the status of your account, you now have
              the option to create a new payment however we will
              need to cancel your autopay and your account will
              go back into collections until you create a new payment
              plan. I can help you complete that today; your payment
              will need to be made within the next 30 days."
            </blockquote>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-if="settlement"
      cols="12"
    >
      <v-card>
        <v-card-title>
          Loan Modification
        </v-card-title>
        <v-card-text>
          <p>
            Unfortunately, this customer does not qualify for a loan modification at this time.
          </p>
          <v-row>
            <v-col
              cols="6"
            >
              <reset-settlement
                :ticket="ticket"
                @success="handleSuccess('reset')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
            >
              <reusable-communication-by-email-template
                :customer="ticket.lpCustomer"
                :ticket-id="ticket.zendesk.id * 1"
                title="Send Instructions For Settlement Dashboard"
                action="portal-login-instructions-settlement"
                tag="portal_login_instructions_settlement"
                :extra="{}"
                @success="handleSuccess('macro')"
              />
            </v-col>
          </v-row>
          <p class="mt-5">
            <b>Note For Phone Agent:</b>
          </p>
          <p>
            Read the following:
          </p>
          <v-alert
            prominent
            dense
            dark
            text
            border="left"
            color="primary"
            icon="mdi-account-voice"
          >
            <blockquote class="blockquote">
              "Based on the status of your account, you now have
              the option to create a new payment however we will
              need to cancel your autopay and your account will
              go back into collections until you create a new payment
              plan. I can help you complete that today; your payment
              will need to be made within the next 30 days."
            </blockquote>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-else-if="hasProcessingModal"
      cols="12"
    >
      <v-card>
        <v-card-title>
          Loan Modification
        </v-card-title>
        <v-card-text>
          <v-progress-circular
            v-if="loadingModel || !ticket.active_loan"
            indeterminate
            color="primary"
          />
          <p v-else>
            Unfortunately, this customer does not qualify for a loan modification at this time.
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-else-if="!ok && !collections && !settlement"
      cols="12"
    >
      <v-card>
        <v-card-title>
          Loan Modification
        </v-card-title>
        <v-card-text>
          <v-progress-circular
            v-if="loadingModel || !ticket.active_loan"
            indeterminate
            color="primary"
          />
          <p v-else>
            Unfortunately, this customer does not qualify for a loan modification at this time.
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'vuex';
import ResetSettlement from './SettlementReset/ResetSettlement.vue';
import ReusableCommunicationByEmailTemplate from './Communications/ReusableCommunicationByEmailTemplate.vue';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketModifyLoan',
  components: {
    ResetSettlement,
    ReusableCommunicationByEmailTemplate,
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasProcessingModal: false,
      confirmModifyModal: false,
      confirmAcceptModal: false,
      confirmRejectModal: false,
      paymentChangeEmail: false,
      skipChangeScheduleEmail: false,
      loadingModel: false,
      autopay: null,
      paymentFrequency: null,
      firstPaymentDate: null,
      finalPaymentDate: null,
      firstPaymentDateFormatted: null,
      paymentProfile: null,
      showDialog: false,
      status: {},
      currentPaymentFrequency: false,
      paymentFrequencies: [
        {
          id: 'loan.frequency.biWeekly',
          title: 'Bi-Weekly',
        },
        {
          id: 'loan.frequency.monthly',
          title: 'Monthly',
        },
        {
          id: 'loan.frequency.semiMonthly',
          title: 'Semi-Monthly',
        },
        {
          id: 'loan.frequency.weekly',
          title: 'Weekly',
        },
      ],
      minPaymentDate: false,
      maxPaymentDate: false,
      firstPaymentDateError: [],
      activePicker: null,
      menu: false,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['activeLoan']),
    ...mapState('loanProCustomer', ['customer']),
    extra() {
      return {
        paymentDate: DateTime.fromFormat(this.activeLoan.current_status.next_payment_date, 'yyyy-MM-dd').toFormat('MM/dd/yyyy'),
      };
    },
    ok() {
      return this.status && [9, 147, 148].includes(this.status.loan_sub_status_id);
    },
    collections() {
      return this.status && [149, 150, 151, 152]
        .includes(this.status.loan_sub_status_id);
    },
    settlement() {
      return this.status && [172, 173, 174]
        .includes(this.status.loan_sub_status_id);
    },
    currentStep() {
      let step = 1;
      if (this.skipChangeScheduleEmail) {
        step = 2;
      }
      if (this.status.docusignRequest) {
        step = 3;
      }
      return step;
    },
    complete() {
      return this.status.docusignRequest && !!this.status.docusignRequest.signed_at;
    },
    pendingPayment() {
      return this.status.docusignRequest
        && this.formatDate(this.status.docusignRequest.send_at)
        !== this.formatDate(this.status.docusignRequest.created_at);
    },
    availablePaymentFrequencies() {
      return this.isLineOfCredit ? this.paymentFrequencies.filter((frequency) => frequency.title !== 'Weekly') : this.paymentFrequencies;
    },
    isLineOfCredit() {
      const type = this.ticket?.active_loan?.loan_setup?.loan_type || '';
      return type === 'loan.type.creditLimit';
    },
    formValid() {
      return (
        !this.loadingModel && this.paymentFrequency
        && this.firstPaymentDate && this.paymentProfile
      );
    },
    stringMinPaymentDate() {
      if (!this.minPaymentDate) {
        return '';
      }
      const parseDate = Date.parse(this.minPaymentDate);
      const dateObject = new Date(parseDate);

      return dateObject.toISOString();
    },
    stringMaxPaymentDate() {
      if (!this.maxPaymentDate) {
        return '';
      }
      const parseDate = Date.parse(this.maxPaymentDate);
      const dateObject = new Date(parseDate);

      return dateObject.toISOString();
    },
  },
  watch: {
    customer: {
      handler(newValue) {
        if (newValue.paymentProfiles) {
          this.paymentProfile = newValue.paymentProfiles.find((p) => p.is_primary).id;
        }
      },
      deep: true,
    },
    activeLoan: {
      handler() {
        this.getModificationStatus();
      },
    },
    firstPaymentDate() {
      this.firstPaymentDateFormatted = this.formatFirstPaymentDate(this.firstPaymentDate);
    },
  },
  created() {
    this.maxPaymentDate = this.addDays(new Date(), 31);
    this.minPaymentDate = this.addBusinessDays(new Date(), 2);
    if (this.activeLoan && this.activeLoan.id) {
      this.getModificationStatus();
    }
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    pickDate(date) {
      this.$refs.menu.save(date);
    },
    allowedDates: (date) => {
      const selectedDay = (new Date(Date.parse(date))).getDay();

      return selectedDay !== 5 && selectedDay !== 6;
    },
    addDays(d, n) {
      const parsedDay = new Date(d.getTime());
      parsedDay.setDate(parsedDay.getDate() + n);
      return parsedDay;
    },
    addBusinessDays(d, n) {
      const parsedDay = new Date(d.getTime());
      const day = parsedDay.getDay();
      parsedDay.setDate(parsedDay.getDate() + n + (day === 6 ? 2 : +!day)
        + (Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2));
      return parsedDay;
    },
    validateFirstPaymentDate() {
      const selectedDate = new Date(this.firstPaymentDate);
      if (selectedDate.getTime() > this.maxPaymentDate.getTime()) {
        this.firstPaymentDate = null;
        this.firstPaymentDateError = [`Please select a date before ${this.maxPaymentDate.getMonth() + 1}-${this.maxPaymentDate.getDate()}-${this.maxPaymentDate.getFullYear()}`];
        return false;
      }
      if (selectedDate.getTime() <= this.minPaymentDate.getTime()) {
        this.firstPaymentDate = null;
        this.firstPaymentDateError = [`Please select a date after ${this.minPaymentDate.getMonth() + 1}-${this.minPaymentDate.getDate()}-${this.minPaymentDate.getFullYear()}`];
        return false;
      }
      this.firstPaymentDateError = [];
      return true;
    },
    getModificationStatus() {
      this.loadingModel = true;
      return this.$axios
        .post('api/v1/support/ticket/modification/status', {
          ticketId: this.$route.params.id,
          loanId: this.activeLoan.id,
        })
        .then((res) => {
          this.status = res.data;
          // this.hasProcessingModal = res.data.hasProcessing;
        })
        .catch(() => {
        }).finally(() => {
          this.loadingModel = false;
        });
    },
    calculatePayments() {
      this.loadingModel = true;
      this.$axios
        .post('api/v1/support/ticket/modification/calculate', {
          ticketId: this.$route.params.id,
          loanId: this.activeLoan.id,
          paymentFrequency: this.paymentFrequency,
          firstPaymentDate: this.firstPaymentDate,
        })
        .then((res) => {
          this.loadingModel = false;
          // this.confirmModifyModal = false;
          this.status = res.data;
          this.finalPaymentDate = res?.data?.after?.finalPaymentDate || '';
          this.currentPaymentFrequency = (this.paymentFrequencies?.find((p) => p.id === res?.data?.after?.paymentFrequency || '') || { title: '' }).title;
        })
        .catch((error) => {
          this.loadingModel = false;
          this.confirmModifyModal = false;
          if (error.response.data.errors.loanId) {
            this.setNotification({
              message: error.response.data.errors.loanId[0],
              color: 'red',
            });
          } else if (error.response.data.errors.firstPaymentDate) {
            this.setNotification({
              message: error.response.data.errors.firstPaymentDate[0],
              color: 'red',
            });
          } else {
            this.setNotification({
              message: error.response.data.message,
              color: 'red',
            });
          }
        });
    },
    sendLoanDocuments() {
      this.loadingModel = true;
      this.$axios
        .post('api/v1/support/ticket/modification/notify', {
          ticketId: this.$route.params.id,
          loanId: this.activeLoan.id,
          paymentFrequency: this.paymentFrequency,
          firstPaymentDate: this.firstPaymentDate,
          paymentAccountId: this.paymentProfile,
          finalPaymentDate: this.finalPaymentDate,
        })
        .then((res) => {
          this.status = res.data;
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.loadingModel = false;
          this.confirmAcceptModal = false;
        });
    },
    anotherTask() {
      this.$router.push({
        name: 'ticket-show',
        params: {
          id: this.$route.params.id,
        },
      });
    },
    resolveTicket() {
      this.loadingModel = true;
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.loadingModel = false;
          this.$router.push({
            name: 'tickets',
          });
        })
        .catch(() => {
          this.loadingModel = false;
        });
    },
    revokeAutopay() {
      this.$router.push({
        name: 'ticket-revoke-autopay',
        params: {
          id: this.$route.params.id,
        },
      });
    },
    formatDate(date) {
      if (DateTime.fromISO(date).isValid) {
        return DateTime.fromISO(date).toFormat('MM-dd-yyyy');
      }
      return date;
    },
    formatFirstPaymentDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    mailAndWait() {
      this.loadingModel = true;
      this.$axios
        .post('api/v1/support/ticket/modification/followup',
          {
            ticketId: this.$route.params.id,
            loanId: this.activeLoan.id,
            cureDate: this.status.cureDate,
          })
        .then(() => {
          this.loadingModel = false;
          this.hasProcessingModal = false;
          this.status.after = false;
        }).catch(() => {
          this.loadingModel = false;
        });
    },
  },
};
</script>
