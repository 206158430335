<template>
  <v-card
    flat
    width="99%"
  >
    <v-card-title>Lending Strategy: {{ lendingStrategy }}</v-card-title>

    <v-timeline dense>
      <v-timeline-item
        v-for="(item, index) in waterfallMethods"
        :key="index"
        :color="item.result === 'pass' ? 'green' : 'red'"
      >
        <v-card outlined>
          <v-card-title>{{ `${item.class}->${item.method} ` }}</v-card-title>
          <v-card-text>
            {{ item.message }} <br> Execution time: {{ item.execution_time }}
          </v-card-text>

          <v-card-actions @click="toggleExpand(index)">
            <v-btn
              color="primary"
              text
            >
              View Raw Response
            </v-btn>

            <v-spacer />

            <v-btn
              icon
            >
              <v-icon>
                {{
                  isExpanded(index) ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}
              </v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="isExpanded(index)">
              <v-divider />

              <v-card-text>
                <JsonFormatterWrapper :data="item.raw_response" />
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import JsonFormatterWrapper from '../JsonFormatterWrapper.vue';

export default {
  components: {
    JsonFormatterWrapper,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      expandedIndex: null,
      logs: {},
    };
  },

  computed: {
    waterfallMethods() {
      // Waterfall log format are:
      // {
      //   "result": "pass",
      //   "message": "All Pre-Filters Passed",
      //   "class": "prefilters",
      //   "method": "return_app_prefilters",
      //   "execution_time": "0.0072"
      // }
      return Object.values(this.data).filter((v) => v.class && v.method);
    },

    lendingStrategy() {
      // "0": {
      //   "lending_strategy": "Tau.v2"
      // }
      return Object.values(this.data).filter((v) => v.lending_strategy)[0]?.lending_strategy;
    },
  },

  // created() {
  //   const logData = this.results?.log_data ?? '{}';
  //   this.logs = JSON.parse(JSON.parse(logData));
  // },

  methods: {
    isExpanded(index) {
      return this.expandedIndex === index;
    },

    toggleExpand(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
  },
};
</script>
