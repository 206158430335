<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Make a Reduced Payment
        </v-card-title>
        <v-card-text
          v-if="!requestToMissPayment"
        >
          <div>
            <v-btn
              v-if="hasPermission('create ticket::make-reduced-payment')"
              color="primary"
              x-large
              block
              class="fraud-claim-dialog-btn"
              :loading="loading"
              :disabled="loading"
              @click="sendRequestToMissPayment()"
            >
              Send Request for Reduced Payment Options
            </v-btn>
            <disabled-for-lack-of-role-btn
              v-else
              text="Send Request for Reduced Payment Options"
              permission="create ticket::make-reduced-payment"
              :x-small="false"
              :x-large="true"
              :block="true"
              button-class="fraud-claim-dialog-btn"
            />
          </div>
          <div>
            <v-btn
              v-if="hasPermission('create ticket::make-reduced-payment')"
              color="secondary"
              x-large
              block
              class="fraud-claim-dialog-btn"
              @click="requestToMissPayment = true"
            >
              Select Reduced Payment Options
            </v-btn>
            <disabled-for-lack-of-role-btn
              v-else
              text="Send Request for Reduced Payment Options"
              permission="create ticket::make-reduced-payment"
              :x-small="false"
              :x-large="true"
              :block="true"
              button-class="fraud-claim-dialog-btn"
            />
          </div>
        </v-card-text>
        <v-card-text
          v-else
        >
          <div>
            <v-alert
              prominent
              dense
              dark
              text
              border="left"
              color="primary"
              icon="mdi-account-voice"
            >
              <blockquote class="blockquote">
                <p>
                  You are eligible to make a 20% reduced payment of at least
                  ${{ twentyPercentPayment }}.
                  After making this payment, you will still owe: ${{ remainingPayment }}.
                  Please log into your customer portal to see your next due dates and amounts.
                </p>
              </blockquote>
            </v-alert>
            <ticket-make-payment
              v-if="paymentProfiles"
              :ticket="ticket"
              :payment-profiles="paymentProfiles"
              :force-payment-amount="twentyPercentPayment * 1"
              :force-payment-type="forcePaymentType"
              @success="handleSuccessfulPayment($event)"
              @error="sendingMissedPaymentEmailDialog = false"
              @loader="sendingMissedPaymentEmailDialog = true"
              @differentAmount="differentAmount = $event"
              @immediatePayment="handleImmediatePayment($event)"
            />
            <v-dialog
              v-model="sendingMissedPaymentEmailDialog"
              persistent
            >
              <v-card>
                <v-card-title>
                  Processing Request
                </v-card-title>
                <v-card-text>
                  <v-alert
                    type="success"
                  >
                    We are Scheduling the Reduced Payment and then we are sending the
                    "MISS PAYMENT: 20% Payment Confirmation" email. Stand by,
                    this window will close once task is complete.
                  </v-alert>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-btn
                    color="primary"
                    loading
                    disabled
                  >
                    Sending
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card-text>
      </v-card>
      <!--ticket action -  resolve ticket and do another action-->
      <v-dialog
        v-model="displayTicketActions"
        width="400px"
        persistent
      >
        <v-card>
          <v-card-title>
            Alert
          </v-card-title>
          <v-card-text>
            Would you like to resolve the ticket or carry out another action?
          </v-card-text>
          <v-divider />
          <ticket-actions />
        </v-card>
      </v-dialog>
      <!--End ticket action -  resolve ticket and do another action-->
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTime } from 'luxon';
import TicketMakePayment from './TicketMakePayment.vue';
import TicketActions from './TicketActions.vue';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketMakeReducedPayment',
  components: {
    TicketMakePayment,
    TicketActions,
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    paymentSchedules: {
      type: Array,
      required: true,
    },
    paymentProfiles: {
      type: Array,
      required: true,
    },
    autopays: {
      type: Array,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ticketSuccessfulActionId: 0,
      ticketSuccessfulActionDialog: false,
      requestToMissPayment: false,
      forcePaymentType: 8,
      sendingMissedPaymentEmailDialog: false,
      loading: false,
      differentAmount: 0,
      displayTicketActions: false,
      // default date for TicketMakePayment is today, so we can set this to true initially.
      immediatePayment: true,
    };
  },
  computed: {
    isLineOfCredit() {
      const type = this.ticket?.active_loan?.loan_setup?.loan_type || '';
      return type === 'loan.type.creditLimit';
    },
    payoffBalance() {
      return this.ticket?.active_loan?.current_status?.payoff || 0;
    },
    pastDueBalance() {
      return this.ticket?.active_loan?.current_status?.amount_past_due_30 || 0;
    },
    currentPaymentAmount() {
      return this.ticket?.active_loan?.current_status?.next_payment_amount || 0;
    },
    currentPaymentDate() {
      return this.ticket?.active_loan?.current_status?.next_payment_date || '';
    },
    currentPaymentScheduleIndex() {
      if (this.currentPaymentDate) {
        const currentPaymentDateObject = DateTime.fromISO(this.currentPaymentDate).toLocal();

        return (this.paymentSchedules || []).findIndex((x) => {
          const lastPayment = DateTime.fromISO(x.date.split(' ')[0]).toLocal();
          const difference = currentPaymentDateObject
            .diff(lastPayment, ['days'])
            .toObject();
          return difference.days === 0;
        });
      }
      return -1;
    },
    twentyPercentPayment() {
      return Number((this.currentPaymentAmount || 0) * 0.20).toFixed(2);
    },
    remainingBalance() {
      return Number(this.payoffBalance
        - (this.differentAmount > 0 ? this.differentAmount : this.twentyPercentPayment)).toFixed(2);
    },
    remainingPayment() {
      return Number(this.currentPaymentAmount
        - (this.differentAmount > 0 ? this.differentAmount : this.twentyPercentPayment)).toFixed(2);
    },
    nextPaymentAmountAfterReducedPayment() {
      return this.currentPaymentScheduleIndex === -1
        ? 'Cannot find the current payment that is scheduled.'
        : this.paymentSchedules[this.currentPaymentScheduleIndex + 1]?.charge_amount
        || 0;
    },
    nextPaymentDateAfterReducedPayment() {
      return this.currentPaymentScheduleIndex === -1
        ? 'Cannot find the current payment that is scheduled.'
        : this.paymentSchedules[this.currentPaymentScheduleIndex + 1]?.date
        || '(There is not another Payment Scheduled)';
    },
    activeAutopays() {
      return this.autopays.filter((i) => i.status !== 'autopay.status.cancelled' && (i.active === true || i.active === 1 || i.active === '1'));
    },
    hasActiveAutoPay() {
      if ((this.activeAutopays || []).length > 0) {
        return true;
      }
      return false;
    },
    loanAutopay() {
      const recurringAutopays = this.activeAutopays.filter((i) => i.type === 'autopay.type.recurring');
      if (recurringAutopays.length === 1) {
        return recurringAutopays;
      }

      const type = (this.isLineOfCredit) ? 'Minimum Payment' : 'Scheduled Installment';
      return recurringAutopays.filter((i) => i.name === type);
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProLoan', ['getAutopays']),

    /**
     * Setting up immediatePayment to true.
     */
    handleImmediatePayment(value) {
      this.immediatePayment = value;
    },

    /**
     * Formatting Date.
     * @param date
     * @returns {string}
     */
    formatDate(date) {
      return DateTime.fromSQL(date).toLocaleString(DateTime.DATE_SHORT);
    },

    /**
     * Handle success Event.
     * @param $event
     */
    handleSuccess($event) {
      this.ticketSuccessfulActionId = $event;
      this.ticketSuccessfulActionDialog = true;
    },

    /**
     * Send Push Out Autopay except for Semi Monthly
     * @param autopayId
     * @returns {Promise<void>}
     */
    async sendPushOutAutopay(autopayId) {
      try {
        await this.$axios
          .post(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment/push-out/${autopayId}`, {
            ticketId: this.$route.params.id,
          });
      } catch (error) {
        this.handleErrorMessage(error, 'There was an error when trying to push out your next payment');
      }
    },

    /**
     * Send Push Out Autopay for Semi Monthly.
     * @param autopaysIds
     * @returns {Promise<void>}
     */
    async sendPushOutAutopaySemiMonthly(autopaysIds) {
      try {
        await this.$axios
          .post(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment/push-out-semimonthly`, {
            ticketId: this.$route.params.id,
            autopaysIds,
          });
      } catch (error) {
        this.handleErrorMessage(error, 'There was an error when trying to push out your next payment');
      }
    },

    /**
     * Handle common behavior for error messages.
     * @param error
     * @param defaultMessage
     */
    handleErrorMessage(error, defaultMessage) {
      let errMsg = defaultMessage;
      if (error && error.response && error.response.status === 404 && error.response.data) {
        errMsg = error.response.data.message;
      }
      this.loading = false;
      this.sendingMissedPaymentEmailDialog = false;
      this.setNotification({
        message: errMsg,
        color: 'red',
      });
    },

    /**
     * Process after process a payment successfully.
     * @param $event
     * @returns {Promise<void>}
     */
    async handleSuccessfulPayment($event) {
      // At this point, a new Reduced Payment should have been scheduled by TicketMakePayment
      this.loading = true;

      // Cancel the most current autopay because it is being replaced with the reduced payment
      // This used to be cancel autopay, instead we want
      // to push out the date rather than cancel
      const paymentFrequency = this.ticket?.active_loan?.loan_setup?.payment_frequency || '';
      if (paymentFrequency === 'loan.frequency.semiMonthly') {
        const autopaysIds = this.loanAutopay.map((autopay) => autopay.id);
        await this.sendPushOutAutopaySemiMonthly(autopaysIds);
      } else {
        const autopayId = this.loanAutopay[0]?.id || 0;
        if (autopayId * 1 > 0) {
          await this.sendPushOutAutopay(autopayId);
        }
      }

      // after we delete the autopay, we need to refresh the autopays and then find the new
      // AutopayID from $event
      await this.getAutopays();
      // await this.getPaymentSchedule();
      const reducedPaymentAutopay = this.autopays.find((i) => i.id === $event.paymentId * 1);
      if (
        this.immediatePayment
        || reducedPaymentAutopay
      ) {
        try {
          // Send reduced payment emails (Zendesk comments) and add Zendesk tags
          const res = await this.$axios
            .post('api/v1/support/ticket/reduced-payment/payment-scheduled', {
              ticketId: this.ticket.zendesk.id,
              firstName: this.ticket?.customer?.firstname || this.customer.first_name,
              remainingPayment: this.remainingPayment,
              nextPaymentAmount: this.getNextPaymentAmount(),
              nextPaymentDate: this.getNextPaymentDate(),
              applyDate: this.getApplyDate(reducedPaymentAutopay),
              processDate: this.processDatetime(reducedPaymentAutopay),
              paymentAmount: this.getPaymentAmount(reducedPaymentAutopay),
            });
          if (res.status === 200) {
            // let msg = 'You have successfully sent the "INTERNAL: PAYMENT(S)
            // SCHEDULE/ CHANGE/ CANCEL"comment and the "MISS PAYMENT:
            // 20% Payment Confirmation" email.';
            const msg = 'Reduced Payment was successfully scheduled.';
            this.setNotification({
              message: msg,
              color: 'green',
            });
            this.loading = false;
            this.dialog = false;
            this.sendingMissedPaymentEmailDialog = false;
            this.displayTicketActions = true;
          }
        } catch (error) {
          this.handleErrorMessage(error, 'There was an error processing your request');
        }
      } else {
        this.handleErrorMessage(null, 'We were unable to locate your reduced payment. Emails were not sent');
      }
    },
    /**
     * Get Next Payment Date using autopay.
     * @returns {*|string}
     */
    getNextPaymentDate() {
      const recurring = this.autopays.find((autopay) => autopay.type === 'autopay.type.recurring');

      return recurring?.apply_date || '';
    },
    /**
     * Get Next Payment Amount using autopay.
     * @returns {PaymentCurrencyAmount|*|number}
     */
    getNextPaymentAmount() {
      const recurring = this.autopays.find((autopay) => autopay.type === 'autopay.type.recurring');

      return recurring?.amount || 0;
    },
    /**
     * Get next Apply Date from Reduced Autopay.
     * @param reducedPaymentAutopay
     * @returns {string|*}
     */
    getApplyDate(reducedPaymentAutopay) {
      if (this.immediatePayment) {
        return DateTime.local().toISODate();
      }

      return reducedPaymentAutopay.apply_date;
    },
    /**
     * Get Next Process Autopay.
     * @param reducedPaymentAutopay
     * @returns {string|*}
     */
    processDatetime(reducedPaymentAutopay) {
      if (this.immediatePayment) {
        return DateTime.local().toISODate();
      }

      return reducedPaymentAutopay.process_datetime;
    },
    /**
     * Getting payment Amount from Reduced Payment autopay.
     * @param reducedPaymentAutopay
     * @returns {number|string|PaymentCurrencyAmount|*}
     */
    getPaymentAmount(reducedPaymentAutopay) {
      if (this.immediatePayment) {
        return (this.differentAmount > 0) ? this.differentAmount : this.twentyPercentPayment;
      }

      return reducedPaymentAutopay.amount;
    },
    /**
     * Sending Request to miss payment.
     * @returns {Promise<void>}
     */
    async sendRequestToMissPayment() {
      this.loading = true;
      try {
        const res = await this.$axios
          .post('api/v1/support/ticket/reduced-payment/request-to-miss-payment', {
            ticketId: this.ticket.zendesk.id,
            firstName: this.ticket?.customer?.firstname || this.customer?.first_name,
            twentyPercentPayment: this.twentyPercentPayment,
            nextPaymentDate: this.currentPaymentDate,
            remainingPayment: this.remainingPayment,
            pastDueBalance: this.pastDueBalance,
          });
        if (res.status === 200 && res.data) {
          let msg = 'Email has been sent.';
          if (res.data.message) {
            msg = res.data.message;
          }
          this.setNotification({
            message: msg,
            color: 'green',
          });
          this.loading = false;
          this.dialog = false;
          this.displayTicketActions = true;
        }
      } catch (error) {
        this.handleErrorMessage(error, 'There was an error processing your request');
      }
    },
  },
};
</script>
