<template>
  <v-card
    class="ma-5"
    flat
  >
    <v-simple-table fixed-header>
      <thead>
        <tr>
          <th class="text-left">
            Session Time
          </th>
          <th class="text-left">
            Fullstory Url
          </th>
        </tr>
      </thead>
      <tbody v-if="isEmpty">
        <tr class="text-center title">
          <td colspan="2">
            No Data Available
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="(session, i) in sessions"
          :key="i"
        >
          <td>{{ session.CreatedTime }}</td>
          <td>
            <a
              :href="session.FsUrl"
              target="__blank"
            >{{ session.FsUrl }}</a>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: 'FullStorySessions',

  props: {
    sessions: {
      type: [Array, Object],
      default: () => [],
    },
  },

  computed: {
    isEmpty() {
      return this.sessions.length === 0;
    },
  },
};
</script>
