import './polyfills';
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import adminRoutes from './adminRoutes';
import unauthRoutes from './unauthRoutes';
import { addRequiresAuthMeta } from './routerHelpers';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    addRequiresAuthMeta(adminRoutes),
    unauthRoutes,
    // catch all routes
    {
      path: '*',
      redirect: 'dashboard',
    },
  ],
});

const hasAccess = (route) => {
  // everyone has access to dashboard
  if (route.name === 'dashboard-home') {
    return true;
  }

  // everyone has access to their own user profile
  if (route.name === 'user-profile') {
    return true;
  }

  // if no permission was provided, return false
  if (!route.meta.permission) {
    return false;
  }
  // this checks to see if any portion of a permission matches
  // if the user has `view ticket::make-payment` and we pass `view ticket` this will return true
  // for more strict permission provide a more complete permission name
  return store.state.currentUser.user.permission.filter(
    (perm) => perm.name.includes(route.meta.permission),
  ).length > 0;
};

router.beforeEach(async (to, from, next) => {
  // Update the title
  document.title = `Admin Portal | ${to.meta.title || ''}`;

  // if not logged in redirect to login
  if (to.meta.requiresAuth && !store.state.currentUser.isLoggedIn) {
    next({ name: 'login' });
    return;
  }

  if (store.state.app.env === 'production' && ['waterfall-test', 'support-test-suite'].includes(to.name)) {
    store.dispatch('currentUser/logUserOut');
    next({ name: '/login' });
  }

  // if user is logged in, but we don't have their info, get user info
  if (store.state.currentUser.isLoggedIn && store.state.currentUser.user !== undefined) {
    await store.dispatch('currentUser/getUser');
    next();
  }

  if (store.state.currentUser.isLoggedIn && store.state.currentUser.user.mfa && to.name === 'login') {
    next({ name: 'dashboard-home' });
  }

  if (store.state.currentUser.isLoggedIn && !store.state.currentUser.user.mfa && to.name === 'login') {
    next({ name: 'user-profile' });
  }

  if (store.state.currentUser.isLoggedIn && to.path === '/') {
    next({ name: 'dashboard-home' });
  }

  // if route requires auth but user does not have permssion, then redirect back to dashboard.
  if (to.meta.requiresAuth && !hasAccess(to)) {
    next({ name: 'dashboard-home' });
  }

  next();
});

export default router;
