import { DateTime } from 'luxon-business-days';

const local = DateTime.local();
const hiredate = local.plusBusiness({ days: -90 });

export default {
  ipaddress: '1.21.4.40',
  affid: '11111111',
  campaign: '',
  api_key: '',
  test: '',
  testtype: '',
  requestamount: 1000,
  ssn: 767011143,
  dob: '1999-05-13',
  firstname: 'Henry',
  lastname: 'Ssassafras',
  address: '6948 Shepherd',
  address2: '',
  city: 'Russelville',
  zip: '72801',
  state: 'AR',
  homephone: '(305)111-1111',
  email: `test+${Date.now()}@user.com`,
  dlstate: 'AR',
  dlnumber: '11111111111',
  ismilitary: 'false',
  iscitizen: 'true',
  otheroffers: 'false',
  rentorown: 'O',
  addressmonths: 0,
  addressyears: 5,
  incometype: 'E',
  paytype: 'D',
  hiredate: hiredate.toFormat('yyyy-MM-dd'),
  empmonths: '8',
  empyears: '6',
  empname: 'Test Company',
  empphone: '(222)222-2222',
  empphoneext: '',
  payfrequency: 'W',
  netmonthly: '3500',
  grossmonthly: '3500',
  bankname: 'Test Bank',
  accounttype: 'C',
  routingnumber: '256074974',
  accountnumber: '222222222',
  bankmonths: '0',
  bankyears: '4',
  lastpaydate: '',
  nextpaydate: '',
  secondpaydate: '',
  customer_guid: null,

  clarityResponse: {
    prescreen: {
      result: 'pass',
      message: 'Testing prescreen',
    },
    prescreen_log: {
      result: 'pass',
      message: 'Testing prescreen_log',
    },
    logFraud: {
      result: 'pass',
      message: 'Testing logFraud',
    },
    fraud: {
      result: 'pass',
      message: 'Testing fraud',
    },
    logFraudInsight: {
      result: 'pass',
      message: 'Testing logFraudInsight',
    },
    fraudInsight: {
      result: 'pass',
      message: 'Testing fraudInsight',
    },
    logBureauLite: {
      result: 'pass',
      message: 'Testing logBureauLite',
    },
    bureauLite: {
      result: 'pass',
      message: 'Testing bureauLite',
    },
    risk: {
      result: 'pass',
      message: 'Testing risk',
    },
    logRisk: {
      result: 'pass',
      message: 'Testing logRisk',
    },
    bank: {
      result: 'pass',
      message: 'Testing bank',
    },
    logBank: {
      result: 'pass',
      message: 'Testing logBank',
    },
  },

  validifiResponse: {
    Response: {
      Requests: {
        Request: {
          Packages: {
            Package: {
              Scorings: {
                Scoring: {
                  Score: '115',
                },
              },
            },
          },
        },
      },
    },
  },
  whitepagesResponse: {
    identity_check_score: '115',
    ip_address_checks: {
      geolocation: {
        country_code: 'US',
      },
    },
    identity_network_score: '115',
  },
};
