<template>
  <v-card
    elevation="2"
    outlined
  >
    <v-card-text>
      <h2 class="d-flex justify-space-between">
        <span>Scoring Element</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ scoringElement.cost }}
            </span>
          </template>
          <span>Acceptable Response</span>
        </v-tooltip>
      </h2>
      <p class="text--primary mt-2">
        <span class="font-weight-bold">
          Description:
        </span>
        {{ scoringElement.description }}
      </p>
      <v-row class="mt-6">
        <v-col
          cols="12"
          md="6"
        >
          Class: {{ scoringElement.class }}
        </v-col>
        <v-col
          class="text-right"
          cols="12"
          md="6"
        >
          Type: {{ scoringElement.type }}
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          Method: {{ scoringElement.method }}
        </v-col>
        <v-col
          class="text-right"
          cols="12"
          md="6"
        >
          Provider: {{ scoringElement.provider }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ScoringElementCard',
  props: {
    scoringElement: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {

    };
  },

};
</script>

<style scoped>

</style>
