<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    scrollable
    @click:outside="dialog = !dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="hasPermission('delete loan-pro-customer::payment-profiles')"
        class="mr-2 btn-bg-deep-blue"
        v-bind="attrs"
        v-on="on"
      >
        Payment Profiles
      </v-btn>
      <DisabledForLackOfRoleBtn
        v-else
        :x-small="false"
        button-class="mr-2"
        text="Payment Profiles"
        permission="delete loan-pro-customer::payment-profiles"
      />
    </template>
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">
          {{ `${editedItem.first_name} ${editedItem.last_name}` }}'s Payment Profiles
        </span>
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Active</th>
              <th>Primary</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="loading"
            >
              <tr>
                <td
                  class="py-2 text-center"
                  colspan="5"
                >
                  <p>Loading Profiles...</p>
                  <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  />
                </td>
              </tr>
            </template>
            <template
              v-else
            >
              <template
                v-if="paymentProfiles.length > 0"
              >
                <tr
                  v-for="(profile, index) in paymentProfiles"
                  :key="`profile_${index}`"
                >
                  <td>{{ profile.id }}</td>
                  <td>{{ profile.title }}</td>
                  <td>{{ profile.active }}</td>
                  <td>{{ profile.is_primary }}</td>
                  <td
                    class="py-2"
                    style="width: 30%"
                  >
                    <LoanProCustomersPaymentProfilesConfirmDeleteButtons
                      :key="`confirm_delete_modal_${profile.id}`"
                      :profile-id="profile.id"
                      :form-processing="formProcessing"
                      @delete-profile="confirmDelete($event, index)"
                    />
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td
                  class="py-2 text-center"
                  colspan="5"
                >
                  No Profiles Found.
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="dialog = !dialog"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

import LoanProCustomersPaymentProfilesConfirmDeleteButtons from '@/views/LoanProCustomers/CustomerProfile/LoanProCustomersPaymentProfilesConfirmDeleteButtons.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'LoanProCustomersPaymentProfilesModal',

  components: {
    DisabledForLackOfRoleBtn,
    LoanProCustomersPaymentProfilesConfirmDeleteButtons,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      id: null,
      paymentProfiles: [],
      formProcessing: false,
      loading: false,
      dialogConfirmDelete: false,
      confirmDeleteButtons: false,
      dialog: false,
    };
  },

  watch: {
    editedItem() {
      this.id = this.editedItem.id;
    },
  },
  mounted() {
    this.getProfiles();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async getProfiles() {
      this.loading = true;

      const profiles = await this.$axios
        .get(`api/v1/loanpro-customer/${this.$route.params.customer_id}/payment-profiles`);

      this.paymentProfiles = [...profiles.data.data];
      this.loading = false;
    },

    async confirmDelete(profileId, index) {
      this.formProcessing = true;

      try {
        await this.$axios
          .delete(`/api/v1/loanpro-customer/${this.id}/payment-profiles/${profileId}`);

        this.setNotification({
          message: 'LoanPro Customer Payment Profile has been deleted.',
          color: 'green',
        });

        this.deleteProfile(index);
      } catch (error) {
        this.setNotification({
          message: 'There was an error processing your request.',
          color: 'red',
        });
      }

      this.formProcessing = false;
      this.dialog = false;
    },
    deleteProfile(index) {
      this.paymentProfiles.splice(index, 1);
    },
  },
};
</script>
