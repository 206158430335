<template>
  <v-dialog
    :value="true"
    max-width="900px"
    scrollable
    @click:outside="$emit('close')"
  >
    <v-card :loading="formLoading">
      <v-card-title>
        <span>Model {{ modelName }} Tier {{ tierData.tier_score }} Debug</span>

        <v-spacer />

        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text style="max-height: 75vh;">
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="frequency"
              :items="frequencies"
              label="Frequency"
              :error-messages="frequencyErrors"
              @input="$v.frequency.$touch()"
              @blur="$v.frequency.$touch()"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="income"
              label="Monthly Income"
              type="number"
              :error-messages="incomeErrors"
              @input="$v.income.$touch()"
              @blur="$v.income.$touch()"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="loanAmount"
              label="Loan Amount"
              type="number"
              :error-messages="loanAmountErrors"
              @input="$v.loanAmount.$touch()"
              @blur="$v.loanAmount.$touch()"
            />
          </v-col>
          <v-col
            class="d-flex justify-space-between align-center"
            cols="12"
            sm="6"
          >
            <v-btn
              color="blue darken-1"
              text
              :loading="formLoading"
              @click="getData()"
            >
              Generate Report
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showDebug">
          <v-col
            v-for="(debugData, index) in debugInfo"
            :key="`debuginfo_${index}`"
            cols="12"
          >
            <h3>{{ index + 1 }} - {{ debugData.title }}: {{ debugData.amount }}</h3>
            <small v-if="debugData.equation">
              <strong>Equation:</strong>
              {{ debugData.equation }}
            </small>
            <pre
              v-if="debugData.vars"
              class="result-box"
            >{{ debugData.vars }}</pre>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  props: {
    modelName: {
      type: String,
      required: true,
    },
    tierData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      formLoading: false,
      income: null,
      frequency: null,
      loanAmount: null,
      frequencies: [
        {
          value: 'w',
          text: 'Weekly',
        },
        {
          value: 'b',
          text: 'Biweekly',
        },
        {
          value: 'm',
          text: 'Monthly',
        },
        {
          value: 's',
          text: 'Semimonthly',
        },
      ],
      showDebug: false,
      debugInfo: {},
    };
  },

  computed: {
    frequencyErrors() {
      const errors = [];

      if (!this.$v.frequency.$dirty) {
        return errors;
      }
      if (!this.$v.frequency.required) {
        errors.push('Frequency is required.');
      }
      return errors;
    },
    incomeErrors() {
      const errors = [];

      if (!this.$v.income.$dirty) {
        return errors;
      }
      if (!this.$v.income.required) {
        errors.push('Monthly income is required.');
      }
      return errors;
    },

    loanAmountErrors() {
      if (!this.$v.loanAmount.$dirty) {
        return [];
      }

      if (!this.$v.loanAmount.required) {
        return ['Loan amount is required.'];
      }

      if (
        this.$v.loanAmount.$error
        && (this.$v.loanAmount.minValue || this.$v.loanAmount.maxValue)
      ) {
        return [`Loan amount must be between ${this.tierData.min_loan_amount} and ${this.tierData.max_loan_amount}`];
      }

      return [];
    },
  },
  methods: {
    getData() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formLoading = true;
        this.showDebug = false;

        const data = {
          tier_score: this.tierData.tier_score,
          repayment_frequency: this.frequency,
          income: this.income,
          debug_version: 2,
          requested_loan_amount: this.loanAmount,
        };
        const modelId = this.tierData.model_id;

        this.$axios
          .post(`/api/v1/product-assignment-model/${modelId}/show-debug-results`, data)
          .then((res) => {
            this.showDebug = true;
            const debugData = res.data.debug_info;

            this.debugInfo = [
              {
                title: 'Loan Amount',
                equation: 'loan amount = (payment/(equivalent_rate/annual_period)) * (1-(1+equivalent_rate/annual_period)^- number_payments)',
                amount: this.formatAmount(debugData.loan_amount.value),
                vars: this.formatVars(debugData.loan_amount.vars),
              },
              {
                title: 'Max Loan Amount',
                equation: 'max_loan_amount = loan amount rounded down to the nearest $100 if max_loan_amount > max_loan_amount(limit) then max_loan_amount = max_loan_amount(limit)',
                amount: this.formatAmount(debugData.max_loan_amount.value),
                vars: this.formatVars(debugData.max_loan_amount.vars),
              },
              {
                title: 'Payment Amount by repayment freq and score',
                equation: 'amount = PTI * income',
                amount: this.formatAmount(debugData.payment.value),
                vars: this.formatVars(debugData.payment.vars),
              },
              {
                title: 'Max Payment allowed',
                equation: 'if calculated payment amount (step 3) is > max payment amount allowed by repayment frequency and score, max payment amount = max payment amount allowed otherwise max payment amount = PTI * income (step 3)',
                amount: this.formatAmount(debugData.max_payment.value),
                vars: this.formatVars(debugData.max_payment.vars),
              },
              {
                title: 'Equivalent Rate',
                equation: 'equivalent_rate = annual_period * ((1+rate/365) ^ (365/annual_period)) - 1)',
                amount: this.formatAmount(debugData.equivalent_rate.value, 4),
                vars: this.formatVars(debugData.equivalent_rate.vars),
              },
              {
                title: 'Annual Period',
                amount: debugData.annual_period,
              },
              {
                title: 'Number Payments',
                amount: debugData.number_of_payments,
              },
            ];
          })
          .catch((/* error */) => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formLoading = false;
          });
      }
    },
    formatAmount(amount, decimals = 2) {
      return parseFloat(amount).toFixed(decimals);
    },
    formatVars(vars) {
      return JSON.stringify(vars, null, 2);
    },
    ...mapActions('notification', ['setNotification']),
  },

  validations() {
    return {
      income: { required },
      frequency: { required },
      loanAmount: {
        required,
        minValue: minValue(this.tierData.min_loan_amount),
        maxValue: maxValue(this.tierData.max_loan_amount),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.result-box {
  margin: .5rem;
  background-color: #eee;
  padding: .5rem;
  border-radius: .25rem;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
}
</style>
