<template>
  <div />
</template>

<script>
export default {
  name: 'NACHAReturnsIndex',
  components: {
  },
  mixins: [
  ],
  data: () => ({
  }),

  computed: {
  },

  methods: {
  },
};
</script>
