import AdminLayout from '@/layouts/Admin/AdminLayout.vue';

// Pages
import Leads from '@/views/Leads/LeadIndex.vue';
import LeadStreams from '@/views/Leads/Stream/LeadStreamIndex.vue';
import LendingTreeLeads from '@/views/Leads/Lendingtree/LendingtreeLeadIndex.vue';
import LeadAmortizations from '@/views/Leads/Amortization/LeadAmortizationIndex.vue';
import LeadServicing from '@/views/Leads/Servicing/LeadServicingIndex.vue';

import DashboardHome from '@/views/Dashboard/Home.vue';
import LeadQueueIndex from '@/views/LeadQueue/LeadQueueIndex.vue';
import LeadQueueApiCallsIndex from '@/views/LeadQueue/LeadQueueApiCallsIndex.vue';
import TicketsIndex from '@/views/Tickets/TicketsIndex.vue';
import TicketShow from '@/views/Tickets/TicketShow.vue';
import TicketShowActions from '@/views/Tickets/TicketShowActions.vue';
import TicketAddPayment from '@/views/Tickets/TicketAddPayment.vue';
import TicketMakePayment from '@/views/Tickets/TicketMakePayment.vue';
import TicketFraudClaim from '@/views/Tickets/FraudClaim/Index.vue';
import TicketCommunicationMacros from '@/views/Tickets/Communications/Index.vue';
import TicketCancelPayment from '@/views/Tickets/TicketCancelPayment.vue';
import TicketModifyLoan from '@/views/Tickets/TicketModifyLoan.vue';
import TicketSettlement from '@/views/Tickets/TicketSettlement.vue';
import TicketReschedulePayment from '@/views/Tickets/TicketReschedulePayment.vue';
import TicketRevokeAutopay from '@/views/Tickets/TicketRevokeAutopay.vue';
import SettingsIndex from '@/views/Settings/SettingsIndex.vue';
import AppSettingsIndex from '@/views/Settings/AppSettings/AppSettingsIndex.vue';
import BrandSettingsIndex from '@/views/Settings/BrandSettings/BrandSettingsIndex.vue';
import DebtCompanyIndex from '@/views/Settings/DebtCompany/DebtCompanyIndex.vue';
import BadAbaIndex from '@/views/LendingStrategies/BadAba/BadAbaIndex.vue';
import BadEmployerIndex from '@/views/LendingStrategies/BadEmployer/BadEmployerIndex.vue';
import BlockedStateIndex from '@/views/LendingStrategies/BlockedState/BlockedStateIndex.vue';
import BlockedSsnIndex from '@/views/LendingStrategies/BlockedSsn/BlockedSsnIndex.vue';
import CronjobIndex from '@/views/Settings/Cronjob/CronjobIndex.vue';
import MonitorIndex from '@/views/Settings/Monitor/MonitorIndex.vue';
import LeadProviderIndex from '@/views/Leads/LeadProvider/LeadProviderIndex.vue';
import CampaignIndex from '@/views/Leads/Campaign/CampaignIndex.vue';
import WaterfallFilterIndex from '@/views/Settings/WaterfallFilter/WaterfallFilterIndex.vue';
import AdverseActionIndex from '@/views/Settings/AdverseAction/AdverseActionIndex.vue';
import SlackChannelIndex from '@/views/Settings/SlackChannel/SlackChannelIndex.vue';
import TrackedJobsIndex from '@/views/Settings/TrackedJobs/TrackedJobsIndex.vue';
import LendingStrategyLayout from '@/views/LendingStrategies/LendingStrategyLayout.vue';
import LendingStrategiesIndex from '@/views/LendingStrategies/LendingStrategiesIndex.vue';
import ScoringElementIndex from '@/views/LendingStrategies/ScoringElement/ScoringElementIndex.vue';
import ScoringModelIndex from '@/views/LendingStrategies/ScoringModel/ScoringModelIndex.vue';

import ProductAssignmentIndex from '@/views/LendingStrategies/ProductAssignment/ProductAssignmentIndex.vue';
import ReportsIndex from '@/views/Reports/ReportsIndex.vue';
import ScoringElementSummaryIndex from '@/views/Reports/ScoringElementSummaryIndex.vue';
import UserAdminIndex from '@/views/UserAdmin/UserAdminIndex.vue';
import UserIndex from '@/views/UserAdmin/User/UserIndex.vue';
import UserGroupIndex from '@/views/UserAdmin/UserGroup/UserGroupIndex.vue';
import UserIpIndex from '@/views/UserAdmin/UserIp/UserIpIndex.vue';
import UserProfileIndex from '@/views/UserProfile/UserProfileIndex.vue';

import WaterfallTestIndex from '@/views/Waterfall/WaterfallTestIndex.vue';
import SupportWaterfallTestIndex from '@/views/Tickets/TestSuite/WaterfallTestIndex.vue';

import PerformanceIndex from '@/views/Performance/PerformanceIndex.vue';
import LeadJourneyMethods from '@/views/Performance/LeadJourneyMethods.vue';
import LeadJourneyApiCalls from '@/views/Performance/LeadJourneyApiCalls.vue';

import LeadDashboardIndex from '@/views/Reports/LeadDashboardIndex.vue';
import LoanProIndex from '@/views/LoanProCustomers/LoanProIndex.vue';
import LoanProCustomerTable from '@/views/LoanProCustomers/LoanProCustomerTable.vue';
import LoanProCustomerEmails from '@/views/LoanProCustomers/CustomerProfile/LoanProCustomerEmails.vue';
import LoanProCustomerStatements from '@/views/LoanProCustomers/CustomerProfile/LoanProCustomerStatements.vue';
import LoanProCustomerActions from '@/views/LoanProCustomers/CustomerProfile/LoanProCustomerActions.vue';
import LoanProCustomerKBOT from '@/views/LoanProCustomers/CustomerProfile/LoanProCustomerKBOT.vue';
import LoanProCustomerFullStory from '@/views/LoanProCustomers/FullStory/LoanProCustomerFullStory.vue';
import LoanProCustomerProfileProfileIndex from '@/views/LoanProCustomers/CustomerProfile/ProfileIndex.vue';
import LoanProCustomerProfile from '@/views/LoanProCustomers/CustomerProfile/LoanProCustomerProfile.vue';
import AffiliateReportIndex from '@/views/Reports/AffiliateReportIndex.vue';
import AdverseActionFailuresReport from '@/views/Reports/AdverseActionFailuresReport.vue';
import LoanProActivityReport from '@/views/Reports/LoanProActivityReport.vue';
import StatementsReport from '@/views/Reports/StatementsReport.vue';
import StatementsAuditReport from '@/views/Reports/StatementsAuditReport.vue';
import StatementLogsReport from '@/views/Reports/StatementLogsReport.vue';
import ComplianceReport from '@/views/Reports/ComplianceReport.vue';

import OfacMonitoringIndex from '@/views/OfacMonitoring/OfacMonitoringIndex.vue';
import OfacReportsIndex from '@/views/OfacMonitoring/OfacReports/OfacReportsIndex.vue';
import OfacMatchesIndex from '@/views/OfacMonitoring/OfacMatches/OfacMatchesIndex.vue';
import OfacEmailNotificationSettingsIndex from '@/views/OfacMonitoring/OfacEmailNotificationSettings/OfacEmailNotificationSettingsIndex.vue';

import ComplianceIndex from '@/views/Compliance/ComplianceIndex.vue';
import ComplianceLeadIndex from '@/views/Compliance/Lead/ComplianceLeadIndex.vue';

import LoanProCustomerZendesk from '@/views/LoanProCustomers/Zendesk/LoanProCustomerZendesk.vue';

import FincenIndex from '@/views/Fincen/FincenIndex.vue';
import FincenPersonsIndex from '@/views/Fincen/FincenPersons/FincenPersonsIndex.vue';
import FincenFilesIndex from '@/views/Fincen/FincenFiles/FincenFilesIndex.vue';
import FincenBatchesIndex from '@/views/Fincen/FincenBatches/FincenBatchesIndex.vue';

import TicketBankUpdateFunding from '@/views/Tickets/TicketBankUpdateFunding.vue';
import TicketSettlementReset from '@/views/Tickets/TicketSettlementReset.vue';
import TicketRescindLoan from '@/views/Tickets/RescindLoan/Index.vue';
import TicketChangePrimaryEmail from '@/views/Tickets/ChangePrimaryEmail/TicketChangePrimaryEmail.vue';
import TicketAutopay from '@/views/Tickets/Autopay/index.vue';
import UserTokensIndex from '@/views/UserAdmin/UserToken/UserTokensIndex.vue';
import Helpdesk from '@/views/Helpdesk/Helpdesk.vue';
import OpsTickets from '@/views/Helpdesk/OpsTickets.vue';
import HelpdeskOpsTickets from '@/views/Helpdesk/HelpdeskOpsTickets.vue';
import TicketPaymentWizard from '../views/Tickets/PaymentWizard/Index.vue';
import TicketMakeReducedPayment from '../views/Tickets/TicketMakeReducedPayment.vue';
import TicketStatementReport from '../views/Tickets/TicketStatementReport.vue';

import DevMenu from '../views/Dev/DevMenu.vue';
import DeveloperTools from '../views/Dev/DeveloperTools.vue';

import UserTokenIndex from '../views/UserAdmin/UserToken/UserTokenIndex.vue';

import ThirdPartyServiceHealthIndex from '../views/Settings/ThirdPartyServiceHealth/ThirdPartyServiceHealthIndex.vue';

import FileManagementLayout from '../views/FileManagement/FileManagementLayout.vue';
import NACHAReturnsIndex from '../views/FileManagement/NACHAReturns/NACHAReturnsIndex.vue';
import NACHAConversionsIndex from '../views/FileManagement/NACHAConversions/NACHAConversionsIndex.vue';

import PrescreenCampaignIndex from '../views/Leads/Prescreen/Campaign/PrescreenCampaignIndex.vue';
import PrescreenFileIndex from '../views/Leads/Prescreen/FileManagement/PrescreenFileIndex.vue';
import PrescreenFileDetails from '../views/Leads/Prescreen/FileManagement/PrescreenFileDetails.vue';
import OfferLookup from '../views/OfferLookup/OfferLookup.vue';
import PrescreenProspectIndex from '../views/PrescreenProspect/PrescreenProspectIndex.vue';

export default {
  path: '/',
  component: AdminLayout,
  name: 'admin',
  children: [
    {
      path: 'dashboard',
      name: 'dashboard-home',
      component: DashboardHome,
      meta: {
        title: 'Dashboard',
      },
    },
    {
      path: 'lead-queue/:id',
      name: 'lead-queue-show',
      component: LeadQueueApiCallsIndex,
      meta: {
        title: 'Lead Queue API Calls',
        permission: 'view queue',
      },
    },
    {
      path: 'tickets',
      name: 'tickets',
      component: TicketsIndex,
      meta: {
        title: 'Tickets',
        permission: 'view ticket',
      },
    },
    {
      path: 'ticket/:id',
      // this parent route has a default route, it will render by using the name ticket-show below
      // name: 'ticket-show',
      component: TicketShow,
      meta: {
        title: 'Ticket Information',
        permission: 'view ticket',
      },
      children: [
        {
          path: '',
          name: 'ticket-show',
          component: TicketShowActions,
          meta: {
            title: 'Ticket Actions',
            permission: 'view ticket',
          },
        },
        {
          path: 'add-payment',
          name: 'ticket-add-payment',
          component: TicketAddPayment,
          meta: {
            title: 'Ticket Add Payment',
            permission: 'view ticket::add-payment',
          },
        },
        {
          path: 'settlement',
          name: 'ticket-settlement',
          component: TicketSettlement,
          meta: {
            title: 'Ticket Settlement',
            permission: 'view ticket::settlement',
          },
        },
        {
          path: 'make-payment',
          name: 'ticket-make-payment',
          component: TicketMakePayment,
          meta: {
            title: 'Ticket Make Payment',
            permission: 'view ticket::make-payment',
          },
        },
        {
          path: 'make-reduced-payment',
          name: 'ticket-make-reduced-payment',
          component: TicketMakeReducedPayment,
          meta: {
            title: 'Ticket Make Reduced Payment',
            permission: 'view ticket::make-reduced-payment',
          },
        },
        {
          path: 'fraud-claim',
          name: 'ticket-fraud-claim',
          component: TicketFraudClaim,
          meta:
          {
            title: 'Ticket Fraud Claim',
            permission: 'view ticket::fraud-claim',
          },
        },
        {
          path: 'communication-macros',
          name: 'ticket-communication-macros',
          component: TicketCommunicationMacros,
          meta:
            {
              title: 'Ticket Communications Macros',
              permission: 'view ticket::communication-macros',
            },
        },
        {
          path: 'cancel-payment',
          name: 'ticket-cancel-payment',
          component: TicketCancelPayment,
          meta: {
            title: 'Ticket Cancel Payment',
            permission: 'view ticket::cancel-payment',
          },
        },
        {
          path: 'reschedule-payment',
          name: 'ticket-reschedule-payment',
          component: TicketReschedulePayment,
          meta: {
            title: 'Ticket Reschedule Payment',
            permission: 'view ticket::reschedule-payment',
          },
        },
        {
          path: 'revoke-autopay',
          name: 'ticket-revoke-autopay',
          component: TicketRevokeAutopay,
          meta: {
            title: 'Ticket Revoke Autopay',
            permission: 'view ticket::revoke-autopay',
          },
        },
        {
          path: 'settlement-reset',
          name: 'ticket-settlement-reset',
          component: TicketSettlementReset,
          meta: {
            title: 'Ticket Settlement Reset',
            permission: 'view ticket::settlement',
          },
        },
        {
          path: 'bank-updated-funding',
          name: 'ticket-bank-update-funding',
          component: TicketBankUpdateFunding,
          meta: {
            title: 'Ticket Bank Update Funding',
            permission: 'view ticket::bank-update-funding',
          },
        },
        {
          path: 'rescind-loan',
          name: 'ticket-rescind-loan',
          component: TicketRescindLoan,
          meta: {
            title: 'Ticket Rescind Loan',
            permission: 'view ticket::rescind-loan',
          },
        },
        {
          path: 'payment-wizard',
          name: 'ticket-payment-wizard',
          component: TicketPaymentWizard,
          meta: {
            title: 'Ticket Payment Wizard',
            permission: 'view ticket::payment-wizard',
          },
        },
        {
          path: 'modify-loan',
          name: 'ticket-modify-loan',
          component: TicketModifyLoan,
          meta: {
            title: 'Ticket Modify Loan',
            permission: 'view ticket::modify-loan',
          },
        },
        {
          path: 'change-primary-email',
          name: 'ticket-change-primary-email',
          component: TicketChangePrimaryEmail,
          meta: {
            title: 'Ticket Change Primary Email',
            permission: 'view ticket::change-primary-email',
          },
        },
        {
          path: 'statement-report',
          name: 'ticket-statement-report',
          component: TicketStatementReport,
          meta: {
            title: 'Ticket Statement Report',
            permission: 'view report::statements-report',
          },
        },
        {
          path: 'autopay',
          name: 'ticket-autopay',
          component: TicketAutopay,
          meta: {
            title: 'Ticket Autopay',
            permission: 'view ticket::cancel-payment',
          },
        },
      ],
    },
    {
      path: 'users-index',
      name: 'users-index',
      component: UserAdminIndex,
      meta: {
        title: 'User Admin',
      },
      children: [
        {
          path: 'users',
          name: 'users',
          component: UserIndex,
          meta: {
            title: 'Users',
            permission: 'view user-admin::user',
          },
        },
        {
          path: 'groups',
          name: 'groups',
          component: UserGroupIndex,
          meta: {
            title: 'Groups',
            permission: 'view user-admin::role',
          },
        },
        {
          path: 'ips',
          name: 'ips',
          component: UserIpIndex,
          meta: {
            title: 'Allowed Ips',
            permission: 'view user-admin::allowed-ips',
          },
        },
        {
          path: 'api-tokens',
          name: 'api-tokens',
          component: UserTokenIndex,
          meta: {
            title: 'API Tokens',
            permission: 'view app-token',
          },
        },
        {
          path: 'tokens/:id',
          name: 'user-tokens',
          component: UserTokensIndex,
          meta: {
            title: 'Tokens',
            permission: 'view user-token',
          },
        },
      ],
    },
    {
      path: 'user-profile',
      name: 'user-profile',
      component: UserProfileIndex,
      meta: {
        title: 'User Profile',
      },
    },
    {
      path: 'settings',
      name: 'settings',
      component: SettingsIndex,
      meta: {
        title: 'Settings',
        permission: 'view system-settings',
      },
      children: [
        {
          path: 'app-settings',
          name: 'app-settings',
          component: AppSettingsIndex,
          meta: {
            title: 'App Settings',
            permission: 'view system-settings::app-settings',
          },
        },
        {
          path: 'brand-settings',
          name: 'brand-settings',
          component: BrandSettingsIndex,
          meta: {
            title: 'Brand Settings',
            permission: 'view system-settings::brand-settings',
          },
        },
        {
          path: 'debt-company-settings',
          name: 'debt-company-settings',
          component: DebtCompanyIndex,
          meta: {
            title: 'Debt Company Settings',
            permission: 'view system-settings::brand-settings',
          },
        },
        {
          path: 'cronjobs',
          name: 'cronjobs',
          component: CronjobIndex,
          meta: {
            title: 'Cron Jobs',
            permission: 'view system-settings::cron-jobs',
          },
        },
        {
          path: 'monitors',
          name: 'monitors',
          component: MonitorIndex,
          meta: {
            title: 'Monitors',
            permission: 'view system-settings::monitors',
          },
        },
        {
          path: 'waterfall-filter',
          name: 'waterfall-filter',
          component: WaterfallFilterIndex,
          meta: {
            title: 'Waterfall Filters',
            permission: 'view system-settings::waterfall-filters',
          },
        },
        {
          path: 'adverse-action',
          name: 'adverse-action',
          component: AdverseActionIndex,
          meta: {
            title: 'Adverse Action',
            permission: 'view system-settings::adverse-action',
          },
        },
        {
          path: 'slack-channels',
          name: 'slack-channels',
          component: SlackChannelIndex,
          meta: {
            title: 'Slack Channels',
            permission: 'view system-settings::slack-channels',
          },
        },
        {
          path: 'tracked-jobs',
          name: 'tracked-jobs',
          component: TrackedJobsIndex,
          meta: {
            title: 'Tracked Jobs',
            permission: 'view system-settings::tracked-jobs',
          },
        },
        {
          path: 'third-party-service-health',
          name: 'third-party-service-health',
          component: ThirdPartyServiceHealthIndex,
          meta: {
            title: 'Third Party Service Health',
            permission: 'view system-settings::third-party-health',
          },
        },
      ],
    },
    {
      path: 'lending-strategies',
      name: 'lending-strategies',
      component: LendingStrategyLayout,
      meta: {
        title: 'Lending Strategies',
      },
      children: [
        {
          path: 'lending-strategy',
          name: 'lending-strategy',
          component: LendingStrategiesIndex,
          meta: {
            title: 'Lending Strategies',
            permission: 'view lending-strategies::list',
          },
        },
        {
          path: 'scoring-element',
          name: 'scoring-element',
          component: ScoringElementIndex,
          meta: {
            title: 'Scoring Elements',
            permission: 'view lending-strategies::scoring-elements',
          },
        },
        {
          path: 'scoring-model',
          name: 'scoring-model',
          component: ScoringModelIndex,
          meta: {
            title: 'Scoring Models',
            permission: 'view lending-strategies::scoring-models',
          },
        },
        {
          path: 'product-assignment/:modelId?',
          name: 'product-assignment',
          component: ProductAssignmentIndex,
          meta: {
            title: 'Product Assignment',
            permission: 'view lending-strategies::product-assignment',
          },
        },
        {
          path: 'bad-aba',
          name: 'bad-aba',
          component: BadAbaIndex,
          meta: {
            title: 'Bad ABA',
            permission: 'view lending-strategies::bad-aba',
          },
        },
        {
          path: 'bad-employer',
          name: 'bad-employer',
          component: BadEmployerIndex,
          meta: {
            title: 'Bad Employer',
            permission: 'view lending-strategies::bad-employer',
          },
        },
        {
          path: 'blocked-state',
          name: 'blocked-state',
          component: BlockedStateIndex,
          meta: {
            title: 'Blocked State',
            permission: 'view lending-strategies::blocked-state',
          },
        },
        {
          path: 'blocked-ssn',
          name: 'blocked-ssn',
          component: BlockedSsnIndex,
          meta: {
            title: 'Blocked SSNs',
            permission: 'view lending-strategies::blocked-ssns',
          },
        },
      ],
    },
    {
      path: 'reports',
      name: 'reports',
      component: ReportsIndex,
      meta: {
        title: 'Reports',
      },
      children: [
        {
          path: 'scoring-element-summary',
          name: 'scoring-element-summary',
          component: ScoringElementSummaryIndex,
          meta: {
            title: 'Scoring Element Summary Report',
            permission: 'view report::scoring-element-summary',
          },
        },
        {
          path: 'lead-dashboard',
          name: 'lead-dashboard',
          component: LeadDashboardIndex,
          meta: {
            title: 'Lead Dashboard Report',
            permission: 'view report::lead-dashboard-report',
          },
        },
        {
          path: 'affiliate-report',
          name: 'affiliate-report',
          component: AffiliateReportIndex,
          meta: {
            title: 'Affiliate Report',
            permission: 'view report::affiliate-report',
          },
        },
        {
          path: 'adverse-action-failures-report',
          name: 'adverse-action-failures-report',
          component: AdverseActionFailuresReport,
          meta: {
            title: 'Adverse Action Failures Report',
            permission: 'view report::adverse-action-failures-report',
          },
        },
        {
          path: 'loanpro-activity-report',
          name: 'loanpro-activity-report',
          component: LoanProActivityReport,
          meta: {
            title: 'LoanPro Activity Report Report',
            permission: 'view report::loan-pro-activity-report',
          },
        },
        {
          path: 'tickets-streams-report',
          name: 'tickets-streams-report',
          component: () => import(/* webpackChunkName: "leads" */ '@/views/Reports/TicketsStreamsReport.vue'),
          meta: {
            title: 'Tickets Streams Report',
            permission: 'view report::tickets-streams-report',
          },
        },
        {
          path: 'statements-report',
          name: 'statements-report',
          component: StatementsReport,
          meta: {
            title: 'Statements Report',
            permission: 'view report::statements-report',
          },
        },
        {
          path: 'statements-audit-report',
          name: 'statements-audit-report',
          component: StatementsAuditReport,
          meta: {
            title: 'Statements Audit Report',
            permission: 'view report::statements-report',
          },
        },
        {
          path: 'statement-logs-report',
          name: 'statement-logs-report',
          component: StatementLogsReport,
          meta: {
            title: 'Statement Logs Report',
            permission: 'view report::statement-logs-report',
          },
        },
        {
          path: 'compliance-report',
          name: 'compliance-report',
          component: ComplianceReport,
          meta: {
            title: 'Compliance Report',
            permission: 'view report::compliance',
          },
        },
      ],
    },
    {
      path: 'waterfall/test',
      name: 'waterfall-test',
      component: WaterfallTestIndex,
      meta: {
        title: 'Waterfall Test',
        permission: 'view waterfall-test',
      },
    },
    {
      path: 'support/test',
      name: 'support-test-suite',
      component: SupportWaterfallTestIndex,
      meta: {
        title: 'Tbot Waterfall Test',
        permission: 'view support-test-suite',
      },
    },
    {
      path: 'support/prospect',
      name: 'prescreen-prospect',
      component: PrescreenProspectIndex,
      meta: {
        title: 'Prescreen Prospects',
        permission: 'view support-test-suite',
      },
    },
    {
      path: 'leads',
      name: 'leads',
      component: Leads,
      meta: {
        title: 'Leads',
        permission: 'view lead',
      },
      children: [
        {
          path: 'streams',
          name: 'lead-streams',
          component: LeadStreams,
          meta: {
            title: 'Streams',
            permission: 'view lead::stream',
          },
        },
        {
          path: 'lendingtree',
          name: 'lendingtree-leads',
          component: LendingTreeLeads,
          meta: {
            title: 'Lendingtree',
            permission: 'view lead::lending-tree',
          },
        },
        {
          path: 'servicing',
          name: 'lead-servicing',
          component: LeadServicing,
          meta: {
            title: 'Servicing',
            permission: 'view lead::servicing',
          },
        },
        {
          path: 'lead-providers',
          name: 'lead-providers',
          component: LeadProviderIndex,
          meta: {
            title: 'Lead Providers',
            permission: 'view lead::lead-providers',
          },
        },
        {
          path: 'campaigns',
          name: 'campaigns',
          component: CampaignIndex,
          meta: {
            title: 'Campaigns',
            permission: 'view lead::campaigns',
          },
        },
        {
          path: 'prescreen-campaigns',
          name: 'prescreen-campaigns',
          component: PrescreenCampaignIndex,
          meta: {
            title: 'Prescreen Campaigns',
            permission: 'view prescreen::campaign',
          },
        },
        {
          path: 'prescreen-files',
          name: 'prescreen-files',
          component: PrescreenFileIndex,
          meta: {
            title: 'Prescreen Files',
            permission: 'view prescreen::file-list',
          },
        },
        {
          path: 'prescreen-file/:id',
          name: 'prescreen-file',
          component: PrescreenFileDetails,
          meta: {
            title: 'Prescreen File Details',
            permission: 'view prescreen::file',
          },
        },
      ],
    },
    {
      path: 'loanpro-customer',
      name: 'loanpro-customer',
      component: LoanProIndex,
      meta: {
        title: 'LoanPro Customers',
        permission: 'view loan-pro-customers',
      },
      children: [
        {
          path: 'list',
          name: 'loanpro-customer-list',
          component: LoanProCustomerTable,
          meta: {
            title: 'LoanPro Customer List',
            permission: 'view loan-pro-customers::list',
          },
        },
        {
          path: ':customer_id',
          name: 'loanpro-customer-profile-index',
          component: LoanProCustomerProfileProfileIndex,
          meta: {
            title: 'LoanPro Customer Profile',
            permission: 'view loan-pro-customers::profile',
          },
          children: [
            {
              path: 'view',
              name: 'loanpro-customer-profile',
              component: LoanProCustomerProfile,
              meta: {
                title: 'LoanPro Customer Profile',
                permission: 'view loan-pro-customers::profile',
              },
            },
            {
              path: 'emails',
              name: 'loanpro-customer-emails',
              component: LoanProCustomerEmails,
              meta: {
                title: 'LoanPro Customer Emails',
                permission: 'view loan-pro-customers::emails',
              },
            },
            {
              path: 'statements',
              name: 'loanpro-customer-statements',
              component: LoanProCustomerStatements,
              meta: {
                title: 'LoanPro Customer Statements',
                permission: 'view loan-pro-customers::statements',
              },
            },
            {
              path: 'zendesk',
              name: 'loanpro-customer-zendesk',
              component: LoanProCustomerZendesk,
              meta: {
                title: 'LoanPro Customer Zendesk',
                permission: 'view loan-pro-customers::zendesk',
              },
            },
            {
              path: 'actions',
              name: 'loanpro-customer-actions',
              component: LoanProCustomerActions,
              meta: {
                title: 'LoanPro Customer Action Log',
                permission: 'view loan-pro-customers::action-log',
              },
            },
            {
              path: 'kbot',
              name: 'loanpro-customer-kbot',
              component: LoanProCustomerKBOT,
              meta: {
                title: 'KBOT',
                permission: 'view loan-pro-customers::zendesk',
              },
            },
            {
              path: 'fullstory',
              name: 'loanpro-customer-fullstory',
              component: LoanProCustomerFullStory,
              meta: {
                title: 'FullStory',
                permission: 'view loan-pro-customers::fullstory',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'compliance',
      name: 'compliance',
      component: ComplianceIndex,
      meta: {
        title: 'Compliance',
        permission: 'view compliance',
      },
      children: [
        {
          path: 'compliance-lead',
          name: 'compliance-lead',
          component: ComplianceLeadIndex,
          meta: {
            title: 'Lead Compliance',
            permission: 'view compliance::lead',
          },
        },
        {
          path: 'ofac-monitoring',
          name: 'ofac-monitoring',
          component: OfacMonitoringIndex,
          meta: {
            title: 'OFAC Monitoring',
            permission: 'view ofac',
          },
          children: [
            {
              path: 'matches',
              name: 'ofac-matches',
              component: OfacMatchesIndex,
              meta: {
                title: 'OFAC Review Matches',
                permission: 'view ofac::matches-list',
              },
            },
            {
              path: 'reports',
              name: 'ofac-reports',
              component: OfacReportsIndex,
              meta: {
                title: 'OFAC Report List',
                permission: 'view ofac::report-list',
              },
            },
            {
              path: 'email-notifications-settings',
              name: 'email-notifications-settings',
              component: OfacEmailNotificationSettingsIndex,
              meta: {
                title: 'OFAC Email Notifications',
                permission: 'view ofac::email-notification-settings',
              },
            },
          ],
        },
        {
          path: 'fincen',
          name: 'fincen',
          component: FincenIndex,
          meta: {
            title: 'FinCen',
            permission: 'view fincen',
          },
          children: [
            {
              path: 'files',
              name: 'fincen-files',
              component: FincenFilesIndex,
              meta: {
                title: 'FinCen File List',
                permission: 'view fincen::files',
              },
            },
            {
              path: 'batches',
              name: 'fincen-batches',
              component: FincenBatchesIndex,
              meta: {
                title: 'FinCen Batch List',
                permission: 'view fincen::batches',
              },
            },
            {
              path: 'persons',
              name: 'fincen-persons',
              component: FincenPersonsIndex,
              meta: {
                title: 'FinCen Persons List',
                permission: 'view fincen::persons',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'file-management',
      name: 'file-management',
      component: FileManagementLayout,
      meta: {
        title: 'File Management',
      },
      children: [
        {
          path: 'nacha-returns',
          name: 'nacha-returns',
          component: NACHAReturnsIndex,
          meta: {
            title: 'NACHA Returns',
            permission: 'view file-management::nacha-return',
          },
        },
        {
          path: 'nacha-conversions',
          name: 'nacha-conversions',
          component: NACHAConversionsIndex,
          meta: {
            title: 'NACHA Conversions',
            permission: 'view file-management::nacha-conversion',
          },
        },
      ],
    },
    {
      path: 'lead-queue-index',
      name: 'lead-queue-index',
      component: LeadQueueIndex,
      meta: {
        title: 'Lead Queue',
        permission: 'view queue',
      },
    },
    {
      path: 'developer-tools',
      name: 'developer-tools',
      component: DeveloperTools,
      meta: {
        title: 'Developer Tools',
        permission: 'view dev-menu',
      },
      children: [
        {
          path: 'lead-queue',
          name: 'lead-queue',
          component: LeadQueueIndex,
          meta: {
            title: 'Lead Queue',
            permission: 'view queue',
          },
        },
        {
          path: 'performance',
          name: 'performance',
          component: PerformanceIndex,
          meta: {
            title: 'Performance',
            permission: 'view performance',
          },
          children: [
            {
              path: 'lead-journey-methods',
              name: 'lead-journey-methods',
              component: LeadJourneyMethods,
              meta: {
                title: 'Lead Journey Methods',
                permission: 'view performance::lead-journey-methods',
              },
            },
            {
              path: 'lead-journey-api-calls',
              name: 'lead-journey-api-calls',
              component: LeadJourneyApiCalls,
              meta: {
                title: 'Lead Journey Api Calls',
                permission: 'view performance::lead-journey-api-calls',
              },
            },
          ],
        },
        {
          path: 'amortization',
          name: 'lead-amortizations',
          component: LeadAmortizations,
          meta: {
            title: 'Amortization',
            permission: 'view lead::amortization',
          },
        },
      ],
    },
    {
      path: 'dev',
      name: 'dev-menu',
      component: DevMenu,
      meta: {
        title: 'Dev Menu',
        permission: 'view dev-menu',
      },
    },
    {
      path: 'offer-lookup',
      name: 'offer-lookup',
      component: OfferLookup,
      meta: {
        title: 'Offer Lookup',
        permission: 'view dev-menu',
      },
    },
    {
      path: 'helpdesk-ops-tickets',
      name: 'helpdesk-ops-tickets',
      component: HelpdeskOpsTickets,
      meta: {
        title: 'Helpdesk and Ops Tickets',
        permission: 'view jira-tickets',
      },
      children: [
        {
          path: 'helpdesk',
          name: 'helpdesk',
          component: Helpdesk,
          meta: {
            title: 'Helpdesk',
            permission: 'view jira-tickets',
          },
        },
        {
          path: 'ops-ticket',
          name: 'ops-ticket',
          component: OpsTickets,
          meta: {
            title: 'Ops Tickets',
            permission: 'view ops_tickets::dashboard',
          },
        },
      ],
    },
  ],
};
