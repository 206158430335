<template>
  <div>
    <v-data-table
      dense
      :calculate-widths="true"
      :headers="headers"
      :loading="dataLoading"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props.sync="footerProps"
      @update:options="updateTable()"
    >
      <template v-slot:top>
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title>Debt Company Settings</v-toolbar-title>

            <v-spacer />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="searchTable()"
            />

            <v-spacer />
            <v-btn
              v-if="hasPermission('create system-settings::debt-company')"
              class="btn-bg-teal"
              @click="openFormModal()"
            >
              Add debt Company
            </v-btn>
            <v-spacer />
          </v-toolbar>
        </v-card-title>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="hasPermission('edit system-settings::debt-company')"
          :id="item"
          class="mr-1 orange--text"
          @click="openFormModal(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <v-icon
          v-if="hasPermission('delete system-settings::debt-company')"
          :id="item"
          class="mr-1 red--text"
          @click="openDeleteModal(item)"
        >
          mdi-delete-outline
        </v-icon>
      </template>
    </v-data-table>
    <FormModal
      v-model="dialogForm"
      :edited-item="editedItem"
      :dialog-form="dialogForm"
      :statuses="statuses"
      @close-modal="closeModal('dialogForm')"
      @item-saved="itemSaved()"
      @click:outside="clearState()"
    />
    <DeleteModal
      v-model="deleteModal"
      :delete-item="deleteItem"
      :delete-form="deleteModal"
      @close-modal="closeModal('deleteModal')"
      @item-saved="itemSaved()"
      @click:outside="clearState()"
    />
  </div>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import FormModal from './FormModal.vue';
import DeleteModal from './DeleteModal.vue';

export default {
  components: {
    FormModal,
    DeleteModal,
  },
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    dataLoading: true,
    headers: [
      { text: 'Name', value: 'name', width: '10%' },
      { text: 'Address', value: 'addressCompleted', width: '30%' },
      { text: 'Loan Status Associated', value: 'statusText', width: '20%' },
      { text: 'Website', value: 'website', width: '20%' },
      { text: 'Phone', value: 'phone', width: '10%' },
      { text: 'Email', value: 'email', width: '10%' },
      {
        text: 'Action',
        value: 'actions',
        sortable: false,
        width: '10%',
      },
    ],
    group: '',
    editedItem: {},
    deleteItem: {},
    deleteModal: false,
    dialogDelete: false,
    dialogForm: false,
    snackbar: true,
    statuses: [],
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    openFormModal(item = null) {
      this.dialogForm = true;
      this.editedItem = item;
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.dataLoading = true;

      return this.$axios
        .get('api/v1/debt-company', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
            group: this.group,
          },
        })
        .then((res) => {
          this.items = Object.values(res.data.companyList.data);
          this.totalItems = res.data.companyList.total;
          this.statuses = res.data.statuses;
          this.dataLoading = false;
        });
    },
    openDeleteModal(item) {
      this.deleteItem = item;
      this.deleteModal = true;
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
        this.deleteItem = {};
      });
      this.updateTable();
    },
  },
};
</script>
<style scoped>
  .edit-icon {
    color: #9c6829;
  }
  .edit-icon-hover {
    color: #8c2512;
  }
</style>
