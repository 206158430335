<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container
          fluid
        >
          <v-form>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="code"
                  required
                  label="Code"
                  :error-messages="codeErrors"
                  @input="$v.code.$touch()"
                  @blur="$v.code.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="description"
                  required
                  label="Description"
                  :error-messages="descriptionErrors"
                  @input="$v.description.$touch()"
                  @blur="$v.description.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="emailVerbiage"
                  label="Email Verbiage"
                  required
                  auto-grow
                  :error-messages="emailVerbiageErrors"
                  @input="$v.emailVerbiage.$touch()"
                  @blur="$v.emailVerbiage.$touch()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue btn-bg-deep-blue"
          dark
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          code: '',
          description: '',
          email_verbiage: '',
        };
      },
    },
  },
  data() {
    return {
      id: null,
      code: '',
      description: '',
      emailVerbiage: '',
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return this.isNewItem ? 'Add New Adverse Action' : 'Edit Adverse Action';
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    codeErrors() {
      const errors = [];

      if (!this.$v.code.$dirty) {
        return errors;
      }
      if (!this.$v.code.required) {
        errors.push('Code is required.');
      }
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.description.$dirty) {
        return errors;
      }
      if (!this.$v.description.required) {
        errors.push('Description is required.');
      }
      return errors;
    },
    emailVerbiageErrors() {
      const errors = [];

      if (!this.$v.emailVerbiage.$dirty) {
        return errors;
      }
      if (!this.$v.emailVerbiage.required) {
        errors.push('Email verbiage is required.');
      }
      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.code = this.editedItem.code;
      this.description = this.editedItem.description;
      this.emailVerbiage = this.editedItem.email_verbiage;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;

        let request = '';

        let data = {
          code: this.code,
          description: this.description,
          emailVerbiage: this.emailVerbiage,
        };

        if (this.isNewItem) {
          data = {
            ...data,
          };

          request = this.$axios.post('/api/v1/adverse-action', data);
        } else {
          request = this.$axios.put(`/api/v1/adverse-action/${this.editedItem.id}`, data);
        }

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.description} has been saved.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');
    },
  },
  validations: {
    code: { required },
    description: { required },
    emailVerbiage: { required },
  },
};
</script>

<style></style>
