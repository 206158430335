<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <v-card-title> LoanPro Activity Report </v-card-title>
      </div>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="fromDate"
                required
                label="From Date"
                type="date"
                :error-messages="fromDateErrors"
                @input="fromDateOnInputOrBlur"
                @blur="fromDateOnInputOrBlur"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="toDate"
                required
                label="To Date"
                type="date"
                :error-messages="toDateErrors"
                @input="toDateOnInputOrBlur"
                @blur="toDateOnInputOrBlur"
              />
            </v-col>
          </v-row>

          <v-btn
            class="mt-5 mb-5 btn-bg-deep-blue"
            :loading="reportGenerating"
            @click="generateReport()"
          >
            Generate Report
          </v-btn>
        </v-form>
      </v-container>
    </v-card>

    <template v-if="reportLoaded">
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
      >
        <template v-slot:item.created_at="{ item }">
          {{ createDate(item.created_at) }}
        </template>

        <template v-slot:item.causer="{ item }">
          <span v-if="item.causer">
            {{ item.causer.first_name }} {{ item.causer.last_name }}
          </span>
        </template>

        <template v-slot:item.subject_type="{ item }">
          {{ item.subject_type.split('\\').pop().split('/').pop() }}
        </template>

        <template v-slot:item.before="{ item }">
          <span v-if="Array.isArray(item.properties.old)">
            -
          </span>
          <v-btn
            v-else
            tile
            color="primary"
            small
            text
            @click="showJsonModal('JSON', item.properties.old)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-eye
            </v-icon>
            SHOW
          </v-btn>
        </template>

        <template v-slot:item.after="{ item }">
          <span v-if="Array.isArray(item.properties.attributes)">
            -
          </span>
          <v-btn
            v-else
            tile
            color="primary"
            small
            text
            @click="showJsonModal('JSON', item.properties.attributes)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-eye
            </v-icon>
            SHOW
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <JsonModal
      v-model="jsonModal"
      :title="jsonTitle"
      :json="json"
      @close-modal="closeJsonModal()"
      @click:outside="closeJsonModal()"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';
import JsonModal from '@/components/JsonModal.vue';

const lessThanToDate = (value, vm) => (value <= vm.toDate);
const greaterThanFromDate = (value, vm) => (value >= vm.fromDate);

export default {
  name: 'LoanProActivityReportIndex',
  components: {
    JsonModal,
  },
  data() {
    return {
      formProcessing: false,
      fromDate: DateTime.local().toISODate(),
      toDate: DateTime.local().toISODate(),
      reportLoaded: false,
      reportGenerating: false,
      json: {},
      jsonTitle: '',
      jsonModal: false,
      headers: [
        { text: 'Date/Time', value: 'created_at' },
        { text: 'Log', value: 'log_name' },
        { text: 'Admin', value: 'causer' },
        { text: 'Action', value: 'description' },
        { text: 'Model', value: 'subject_type' },
        { text: 'Model ID', value: 'subject_id' },
        { text: 'Before', value: 'before' },
        { text: 'After', value: 'after' },
      ],
      items: [],
    };
  },
  computed: {
    fromDateErrors() {
      const errors = [];

      if (!this.$v.fromDate.$dirty) {
        return errors;
      }
      if (!this.$v.fromDate.required) {
        errors.push('From Date is required.');
      }

      return errors;
    },
    toDateErrors() {
      const errors = [];

      if (!this.$v.toDate.$dirty) {
        return errors;
      }
      if (!this.$v.toDate.required) {
        errors.push('To Date is required.');
      }

      return errors;
    },
  },
  created() {
    const routeParams = this.$route.params;
    let generateReport = false;

    if (routeParams.fromDate) {
      this.fromDate = routeParams.fromDate;
      generateReport = true;
    }

    if (routeParams.toDate) {
      this.toDate = routeParams.toDate;
      generateReport = true;
    }

    if (generateReport) {
      this.generateReport();
    }
  },
  methods: {
    generateReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .get('api/v1/reports/loanpro-activity/', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          })
          .then((res) => {
            vm.items = Object.values(res.data);
            vm.reportLoaded = true;
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },
    resetDateValidators() {
      this.$v.fromDate.$reset();
      this.$v.toDate.$reset();
    },
    touchDateValidators() {
      this.$v.fromDate.$touch();
      this.$v.toDate.$touch();
    },
    fromDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.fromDateErrors.push('From Date cannot be after To Date.');
      }
    },
    toDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.toDateErrors.push('To Date cannot be before From Date.');
      }
    },
    createDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    closeJsonModal() {
      this.jsonModal = false;
      this.$nextTick(() => {
        this.json = {};
      });
    },
    showJsonModal(title, json) {
      this.json = json;
      this.jsonTitle = title;
      this.jsonModal = true;
    },
  },
  validations: {
    fromDate: { required, lessThanToDate },
    toDate: { required, greaterThanFromDate },
  },
};
</script>

<style></style>
