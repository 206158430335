<template>
  <div>
    <ScoringElementCostList
      v-if="activeView === 'costList'"
      :scoring-element="scoringElement"
      :new-item="newItem"
      @changeView="handleChangeView"
    />
    <ScoringElementFormCost
      v-if="activeView === 'costForm'"
      :scoring-element="scoringElement"
      @changeView="handleChangeView"
      @newItem="handleNewItem"
    />
  </div>
</template>

<script>
import ScoringElementCostList from './ScoringElementCostList.vue';
import ScoringElementFormCost from './ScoringElementFormCost.vue';

export default {
  name: 'ScoringElementCostEffectiveDates',
  components: {
    ScoringElementFormCost,
    ScoringElementCostList,
  },

  props: {
    scoringElement: {
      type: Object,
      required: true,
    },

    activeView: {
      type: String,
      default: 'costList',
    },

  },

  data: () => ({
    newItem: {},
  }),

  methods: {
    handleNewItem(data) {
      this.newItem = data;
    },

    handleChangeView(view) {
      this.$emit('changeView', view);
    },
  },
};
</script>

<style>
  /* Setting padding to 0 prevents hiding last week of the month on mobile devices */
.v-date-picker-table.v-date-picker-table--date.theme--light  {
  height: 100% !important;
}
.v-btn--disabled .v-btn__content {
  color: #D6D3CD;
}
</style>
