<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="1050"
    @click:outside="closeDialog()"
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline"> Ops Ticket - {{ ticket.opsTicketDisplayId }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="mt-3">
            <v-col
              cols="8"
              sm="8"
              md="8"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                >
                  <h1>{{ ticket.title }}</h1>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <p>
                    {{ ticket.description }}
                  </p>
                </v-col>
              </v-row>

              <v-divider />
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <OpsTicketDialogFS
                    :ticket="ticket"
                  />
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <OpsTicketDialogComments
                    :ticket="ticket"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical />
            <v-col
              cols="4"
              sm="4"
              md="4"
            >
              <OpsTicketDialogDetails :ticket="ticket" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="closeDialog()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OpsTicketDialogDetails from '@/views/Helpdesk/components/OpsTicketDashboard/OpsTicketDialogDetails.vue';
import OpsTicketDialogComments from '@/views/Helpdesk/components/OpsTicketDashboard/OpsTicketDialogComments.vue';
import OpsTicketDialogFS from '@/views/Helpdesk/components/OpsTicketDashboard/OpsTicketDialogFS.vue';

export default {
  name: 'OpsTicketDialog',
  components: { OpsTicketDialogFS, OpsTicketDialogComments, OpsTicketDialogDetails },

  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },

    ticket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      formProcessing: false,
      currentComment: '',
    };
  },

  watch: {
    open: {
      deep: true,
      handler(value) {
        this.dialog = value;
      },
    },
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
