<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="hasPermission('edit loan-pro-customer::password')"
        class="mr-2 btn-bg-regal-gray"
        v-bind="attrs"
        v-on="on"
      >
        Password
      </v-btn>
      <DisabledForLackOfRoleBtn
        v-else
        :x-small="false"
        button-class="mr-2"
        text="Password"
        permission="edit loan-pro-customer::password"
      />
    </template>
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">
          Update {{ `${editedItem.first_name} ${editedItem.last_name}` }}'s Password
        </span>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-form>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="password"
                  label="Password"
                  :type="passwordInputType"
                >
                  <template #append-outer>
                    <v-btn
                      icon
                      @click="togglePasswordInputType"
                    >
                      <v-icon>{{ passwordIcon }}</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <div class="ml-2">
                  <ul>
                    <li
                      :class="lengthTextColorClass"
                    >
                      Must be at least 10 characters in length.
                    </li>
                    <li
                      :class="oneDigitTextColorClass"
                    >
                      Must contain at least one numeric digit.
                    </li>
                    <li
                      :class="oneLowerCaseTextColorClass"
                    >
                      Must contain at least one lowercase letter.
                    </li>
                    <li
                      :class="oneUpperCaseTextColorClass"
                    >
                      Must contain at least one uppercase letter.
                    </li>
                    <li
                      :class="passwordConfirmedTextColorClass"
                    >
                      Password and Confirm Password must match.
                    </li>
                  </ul>
                  <v-text-field
                    v-model="passwordConfirmation"
                    label="Confirm Password"
                    :type="passwordInputType"
                    :disabled="!confirmPasswordEnabled"
                  >
                    <template #append-outer>
                      <v-btn
                        icon
                        @click="togglePasswordInputType"
                      >
                        <v-icon>{{ passwordIcon }}</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing || !passwordConfirmed"
          @click="save()"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'LoanProCustomerUpdatePasswordModal',
  components: { DisabledForLackOfRoleBtn },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      id: null,
      password: '',
      passwordConfirmation: '',
      formProcessing: false,
      passwordInputType: 'password',
      dialog: false,
    };
  },
  computed: {
    lengthValid() {
      return this.password.length >= 10;
    },
    lengthTextColorClass() {
      return this.lengthValid ? 'green--text' : 'red--text';
    },
    digitValid() {
      return /\d+/.test(this.password);
    },
    oneDigitTextColorClass() {
      return this.digitValid ? 'green--text' : 'red--text';
    },
    lowerCaseValid() {
      return /[a-z]+/.test(this.password);
    },

    oneLowerCaseTextColorClass() {
      return this.lowerCaseValid ? 'green--text' : 'red--text';
    },
    upperCaseValid() {
      return /[A-Z]+/.test(this.password);
    },
    oneUpperCaseTextColorClass() {
      return this.upperCaseValid ? 'green--text' : 'red--text';
    },
    passwordConfirmed() {
      return this.lengthValid && this.password === this.passwordConfirmation;
    },
    passwordConfirmedTextColorClass() {
      return this.passwordConfirmed ? 'green--text' : 'red--text';
    },
    confirmPasswordEnabled() {
      return this.lengthValid && this.digitValid && this.lowerCaseValid && this.upperCaseValid;
    },
    passwordIcon() {
      return this.passwordInputType === 'password' ? 'mdi-eye' : 'mdi-eye-off';
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async save() {
      this.formProcessing = true;

      const data = {
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      };

      try {
        await this.$axios.post(`/api/v1/loanpro-customer/${this.editedItem.id}/update-password`, data);

        this.setNotification({
          message: 'LoanPro Customer password has been saved.',
          color: 'green',
        });
      } catch (error) {
        this.setNotification({
          message: 'There was an error processing your request.',
          color: 'red',
        });
      }

      this.formProcessing = false;
      this.close();
    },
    close() {
      const vm = this;

      setTimeout(() => {
        vm.password = '';
        vm.passwordConfirmation = '';
      }, 250);

      this.dialog = false;
    },
    togglePasswordInputType() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style></style>
