<template>
  <div>
    <v-card
      width="100%"
      elevation="3"
      :loading="searching"
      outlined
    >
      <v-card-title
        width="100%"
      >
        <h3>Offer Lookup</h3>

        <v-spacer />

        <v-text-field
          v-model="searchValue"
          class="search mt-5"
          outlined
          label="Search for offer code"
          prepend-inner-icon="mdi-magnify"
          @blur="handleSearchOfferCode()"
          @keyup.enter="handleSearchOfferCode()"
          @focus="removeAllErrors()"
        />
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="prospectNotFound"
          border="top"
          color="red lighten-2"
          dark
        >
          Prospect not found with this offer code.
        </v-alert>
        <div
          v-if="showResultSection"
          class="content-container"
        >
          <div class="prospect-section">
            <v-row>
              <v-col
                cols="6"
                class="mt-5"
              >
                <h3>Prospect Info - ({{ searchValue }})</h3>
                <PersonalInfo
                  class="ml-5 mt-5"
                  :section-title="'Personal Info'"
                  :prospect="prospect"
                />

                <ProspectAddress
                  class="ml-5 mt-5"
                  :section-title="'Prospect Address'"
                  :prospect="prospect"
                />
              </v-col>

              <v-col
                cols="6"
                class="mt-5"
              >
                <div
                  class="lead-info-container"
                >
                  <h3>
                    Lead Info
                    <span v-if="prospect && prospect.lead"> -
                      <span :class="classSelected">({{ prospect.lead.lead_guid }})</span>
                    </span>
                  </h3>
                  <v-alert
                    v-if="!leadAssociated"
                    border="top"
                    color="red lighten-2"
                    dark
                  >
                    This prospect doesn't have any lead associated.
                  </v-alert>
                  <LeadInfo
                    v-else
                    class="ml-5 mt-5"
                    :lead="prospect.lead"
                    :section-title="'Lead Section'"
                  />

                  <CampaignInfo
                    class="ml-5 mt-5"
                    :campaign="prospect.campaign"
                    :section-title="'Prospect Campaign'"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="mt-5"
              >
                <IsValidOffer
                  :prospect="prospect"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PersonalInfo from './PersonalInfo.vue';
import ProspectAddress from './ProspectAddress.vue';
import LeadInfo from './LeadInfo.vue';
import CampaignInfo from './CampaignInfo.vue';
import IsValidOffer from './IsValidOffer.vue';

export default {
  name: 'OfferLookup',

  components: {
    PersonalInfo,
    ProspectAddress,
    LeadInfo,
    CampaignInfo,
    IsValidOffer,
  },

  data() {
    return {
      searchValue: '',
      showOfferCodeError: false,
      prospect: {},
      searching: false,
      prospectNotFound: false,
    };
  },

  computed: {
    showResultSection() {
      if (this.searching) {
        return false;
      }

      return !!Object.keys(this.prospect).length;
    },

    leadAssociated() {
      return this.prospect && this.prospect.lead;
    },

    classSelected() {
      if (this.prospect && this.prospect.lead) {
        switch (this.prospect.lead.finalResult) {
          case ('accepted'):
            return 'ft-success';
          case ('declined'):
            return 'ft-danger';
          default:
            return '';
        }
      }

      return '';
    },
  },

  methods: {
    removeAllErrors() {
      this.prospectNotFound = false;
      this.showOfferCodeError = false;
    },

    getOfferCodeProspectData() {
      this.searching = true;
      this.prospect = {};

      this.$axios
        .post('api/v1/prescreen/getProspect', {
          search: this.searchValue,
        })
        .then((res) => {
          if (res.status === 200) {
            this.prospect = res.data.data;
          } else {
            this.prospectNotFound = true;
          }
        })
        .catch(() => {
          this.prospectNotFound = true;
        })
        .finally(() => {
          this.searching = false;
        });
    },

    handleSearchOfferCode() {
      if (!this.searchValue) {
        this.showOfferCodeError = true;
      } else {
        this.getOfferCodeProspectData();
      }
    },
  },
};
</script>

<style scoped>
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ft-warning {
  color: #7C5907;
}

.ft-success {
  color: #1E7C55;
}

.ft-danger {
  color: #7C172A;
}
</style>
