<template>
  <v-main
    v-if="isLoggedIn && user.id"
    class="grey lighten-3"
  >
    <AdminToolBar v-if="user.permission.length" />
    <AdminSidebar v-if="user.permission.length" />

    <v-flex :style="`margin-left: ${contentAreaStyle.marginLeft};`">
      <v-container
        class="py-10 px-10"
        fluid
      >
        <version-banner />
        <router-view />
      </v-container>
    </v-flex>
    <notification />
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Notification from '../../components/notification.vue';
import VersionBanner from '../../components/VersionBanner.vue';
import AdminSidebar from './AdminSidebar.vue';
import AdminToolBar from './AdminToolbar.vue';

export default {
  components: {
    AdminSidebar,
    AdminToolBar,
    Notification,
    VersionBanner,
  },
  computed: {
    ...mapState('currentUser', ['user', 'isLoggedIn']),
    ...mapState('sidebar', ['contentAreaStyle']),
  },
  async created() {
    this.setContentAreaStyle();
    if (!this.user?.id) {
      this.getUser();
    }
  },
  methods: {
    ...mapActions('currentUser', ['getUser']),
    ...mapActions('sidebar', ['setContentAreaStyle']),
  },
};
</script>

<style scoped>
.v-application .grey.lighten-3 {
  background: #f1f7f0 !important;
  background-blend-mode: normal;
  padding: 0 !important;
}
</style>

<style>
.ag-theme-alpine {
  max-height: 65vh;
  height: 65vh !important;
}
.ag-theme-alpine .ag-root-wrapper.ag-layout-normal {
  border: none;
  background: transparent;
  box-shadow: 3px 3px 10px #cccccc;
}
thead.v-data-table-header, .ag-header {
  background: #e9eaec !important;
}
thead.v-data-table-header th, .ag-header .ag-header-cell {
  padding: 1rem !important;
  border-right: 1px solid #999999;
  color: #4d4d4d !important;
  font-weight: bold;
  font-size: 0.9rem !important;
}
tbody td, .ag-cell-value {
  padding: 0.5rem 0.5rem !important;
  color: #4d4d4d;
}
tbody tr:nth-child(odd), .ag-row-odd {
  background-color: #ffffff;
}
tbody tr:nth-child(even), .ag-row-even {
  background-color: #f7f7f8 !important;
}

.v-data-table {
  background-color: transparent !important;
}
.v-data-table .theme--light.v-toolbar.v-sheet {
  background-color: transparent;
}
.v-data-table .v-toolbar__title {
  font-size: 1.3rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #2b3243;
  font-weight: bold;
  text-transform: uppercase;
}

.v-data-table .v-toolbar .v-input {
  border: 1px solid #cccccc;
  padding: 0.3rem 1rem;
  background: #fff;
}

.v-data-table .v-toolbar .v-input .v-input__slot {
  background: #fff !important;
  padding: 0.3rem 1rem;
}

.v-data-table .v-toolbar .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}

.btn-bg-regal-gray {
  background: #7d959d !important;
  color: #ffffff !important;
}
.btn-bg-deep-blue {
  background: #205e86 !important;
  color: #ffffff !important;
}
.btn-bg-gunmetal {
  background: #2b3243 !important;
  color: #ffffff !important;
}
.btn-bg-teal {
  background: #45a9a5 !important;
  color: #ffffff !important;
}
.btn-bg-alert-red {
  background: #95291f !important;
  color: #ffffff !important;
}

.v-icon {
  -webkit-text-stroke: 1px inherit;
}

.headline {
  font-size: 2.0rem !important;
  color: #2b3243 !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

.v-tabs {
  box-shadow: 1px 1px 2px 0px #999999;
}

.v-tabs a.v-tab {
  color: #205e86 !important;
  text-decoration: underline;
  padding: 1rem 2rem !important;
  font-size: 0.9rem;
  font-weight: 500;
}

.v-tabs a.v-tab--active.v-tab{
  font-weight: bold;
  text-decoration: none;
}
.v-tabs .v-tabs-slider {
  background-color: #b61e4e !important;
  height: 100%;
  width: 100%;
}

.v-tabs.v-tabs--vertical a.v-tab {
  color: #205e86 !important;
  text-decoration: none !important;
  padding: 1rem 0.8rem !important;
  font-size: 0.8rem;
  font-weight: 500;
  justify-content: left;
}
.v-tabs.v-tabs--vertical a.v-tab--active.v-tab {
  background: #999999 !important;
}

.v-dialog .v-card__title {
  background: #f1f7f0 !important;
}

.v-input__slot {
  background: #e9eaec !important;
  padding: 0.5rem 1rem;
}
</style>
