import Vue from 'vue';

const initialState = {
  customer: {},
  paymentProfiles: [],
};

const actions = {
  async getPaymentProfiles({ commit, state, dispatch }) {
    try {
      const res = await Vue.prototype.$axios
        .post(`api/v1/loanpro-customer/${state.customer.id}/payment-profiles`, {
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
        });

      commit('SET_PAYMENT_PROFILES', [...res.data.data]);
    } catch (error) {
      let errMsg = 'There was an error when sending an API request to collect Payment Profile data. Please try again. If there error persists, please notify your supervisor.';
      if (error.response && error.response.status === 404 && error.response.data) {
        errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
  setCustomer({ commit }, data) {
    commit('SET_CUSTOMER', data);
  },
  setCustomerPhone({ commit }, data) {
    commit('SET_CUSTOMER_PHONE', data);
  },
  setCustomerEmail({ commit }, data) {
    commit('SET_CUSTOMER_EMAIL', data);
  },
};

const mutations = {
  SET_PAYMENT_PROFILES(state, profiles) {
    state.customer.paymentProfiles = profiles;
    state.paymentProfiles = profiles;
  },
  SET_CUSTOMER(state, data) {
    state.customer = data;
  },

  SET_CUSTOMER_PHONE(state, data) {
    state.customer.homephone = data;
  },

  SET_CUSTOMER_EMAIL(state, data) {
    state.customer.email = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
