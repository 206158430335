<template>
  <v-card class="mt-4">
    <v-card-title>
      <v-icon
        class="mr-4"
        @click="redirectPage"
      >
        mdi-arrow-left
      </v-icon>
      File Summary
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Import Status
              </th>
              <th
                v-if="formattedFile.sub_status"
                class="text-left"
              >
                Sub Status
              </th>
              <th
                v-if="formattedFile.export_file_path"
                class="text-left"
              >
                Exported File
              </th>
              <th class="text-left">
                Created
              </th>
              <th class="text-left">
                Updated
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ formattedFile.name }}</td>
              <td>{{ formattedFile.type }}</td>
              <td>{{ formattedFile.status }}</td>
              <td v-if="formattedFile.sub_status">
                {{ formattedFile.sub_status }}
              </td>
              <td v-if="formattedFile.export_file_path">
                <v-btn
                  :loading="loading"
                  color="green"
                  small
                  @click="downloadExportFile"
                >
                  <v-icon>mdi-download</v-icon>
                  Download File
                </v-btn>
              </td>
              <td>{{ formattedFile.created_at }}</td>
              <td>{{ formattedFile.updated_at }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatDateTime } from '@/services/HelperService';

export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    formattedFile() {
      return (this.file) ? {
        ...this.file,
        created_at: formatDateTime(this.file.created_at),
        updated_at: formatDateTime(this.file.updated_at),
        type: (this.file.type).toUpperCase(),
        status: (this.file.status).toUpperCase(),
        sub_status: this.file.sub_status ? (this.file.sub_status).toUpperCase() : null,
      } : [];
    },
  },
  methods: {
    redirectPage() {
      this.$router.push({ name: 'prescreen-files' });
    },
    downloadExportFile() {
      this.loading = true;
      this.$axios
        .get(`/api/v1/prescreen/files/${this.file.id}/intermediate/export-download`, {
          responseType: 'blob',
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.file.name);
          document.body.appendChild(link);
          link.click();

          this.loading = false;
        });
    },
  },
};
</script>
