<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col>
        <h1>Waterfall Test</h1>
        <p>Fill out the form sections below to generate a new test lead.</p>
      </v-col>
      <v-col
        v-if="!dataLoading && !showError"
        class="d-flex align-end justify-end"
      >
        <v-btn
          class="blue darken-1 mb-4"
          dark
          :loading="formLoading"
          @click="submitLead"
        >
          Submit Lead
        </v-btn>
      </v-col>
    </v-row>

    <template
      v-if="dataLoading"
    >
      <p>Loading data</p>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </template>

    <v-row
      v-else
    >
      <v-col
        v-if="!showError"
      >
        <v-stepper
          v-model="step"
          non-linear
        >
          <v-stepper-header>
            <v-stepper-step
              editable
              step="1"
            >
              Lead Data
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              editable
              step="2"
            >
              Clarity Data
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              editable
              step="3"
            >
              Validifi Data
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              editable
              step="4"
            >
              White Pages Data
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              step="1"
            >
              <WaterfallTestLeadDataForm
                :lead-data="leadData"
                :campaigns="campaigns"
                :lead-providers="leadProviders"
                :us-states="usStates"
                :customers="customers"
                :is-return-campaign="isReturnCampaign"
              />
            </v-stepper-content>

            <v-stepper-content
              step="2"
            >
              <WaterfallTestClarityDataForm
                :clarity-data="leadData.clarityResponse"
              />
            </v-stepper-content>

            <v-stepper-content
              step="3"
            >
              <WaterfallTestValidifiDataForm
                :lead-data="leadData"
                :campaigns="campaigns"
                :validifi-data="leadData.validifiResponse"
              />
            </v-stepper-content>

            <v-stepper-content
              step="4"
            >
              <WaterfallTestWhitePagesFormData
                :white-pages-data="leadData.whitepagesResponse"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>

      <v-col
        v-if="showError"
        cols="12"
      >
        <v-alert
          type="error"
          dark
          border="left"
        >
          Failed to retrieve data needed to create lead.
          <ul>
            <li
              v-for="(msg, index) in errorMessages"
              :key="index"
            >
              {{ msg }}
            </li>
          </ul>
        </v-alert>
      </v-col>

      <v-dialog
        v-model="showModal"
      >
        <v-card
          flat
          class="pa-2"
        >
          <v-card-title>Result</v-card-title>
          <v-card-text>
            <v-alert
              v-show="formError"
              type="error"
              dark
              border="left"
            >
              {{ formError }}
            </v-alert>

            <vue-json-pretty
              :data="apiResponse"
              :show-line="false"
              :show-double-quotes="false"
              virtual
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import { mapActions, mapState } from 'vuex';
import WaterfallTestLeadDataForm from '@/views/Waterfall/WaterfallTestLeadDataForm.vue';
import WaterfallTestClarityDataForm from '@/views/Waterfall/WaterfallTestClarityDataForm.vue';
import WaterfallTestValidifiDataForm from '@/views/Waterfall/WaterfallTestValidifiDataForm.vue';
import WaterfallTestWhitePagesFormData from '@/views/Waterfall/WaterfallTestWhitePagesFormData.vue';

import stateList from '@/data/states.json';

import qaData from './qaData';

export default {
  components: {
    WaterfallTestLeadDataForm,
    WaterfallTestClarityDataForm,
    WaterfallTestValidifiDataForm,
    WaterfallTestWhitePagesFormData,
    VueJsonPretty,
  },
  data() {
    return {
      dataLoading: true,
      showError: false,
      errorMessages: [],
      customers: [],
      campaigns: [],
      leadProviders: [],
      usStates: [],
      lastpaydate: '',
      nextpaydate: '',
      secondpaydate: '',
      leadData: {},
      step: 1,
      formLoading: false,
      showModal: false,
      apiResponse: {},
      formError: '',
    };
  },
  computed: {
    ...mapState('settings', ['settings']),
    isReturnCampaign() {
      const campaign = this.campaigns.find((camp) => camp.value === this.leadData.campaign);
      return campaign && campaign.text.includes('Return');
    },
  },
  async created() {
    const vm = this;

    this.leadData = { ...qaData };

    this.usStates = (stateList || []).map((state) => state.id);

    try {
      const res = await this.$axios.get('/api/v1/waterfall/test');
      const { data } = res;

      vm.customers = (data?.customers || []).map((customer) => ({
        value: customer.guid,
        text: `${customer.firstname} ${customer.lastname}`,
        email: customer.email,
        phone: customer.homephone,
      }));

      // if (vm.customers.length > 0) {
      //   vm.leadData.customer_guid = vm.customers[0].value;
      // }

      vm.campaigns = (data?.campaigns || []).map((campaign) => ({
        value: campaign.id,
        text: `${campaign.id} (${campaign.campaign_name} - ${campaign.product_type})`,
      }));

      if (vm.campaigns.length === 0) {
        vm.errorMessages.push('No Campaigns found.');
      } else {
        vm.leadData.campaign = vm.campaigns[0].value;
      }

      vm.leadProviders = (data?.lead_providers || []).map((provider) => ({
        value: provider.api_key,
        text: `${provider.api_key} (${provider.category})`,
      }));

      if (vm.leadProviders.length === 0) {
        vm.errorMessages.push('No Lead Providers found.');
      } else {
        vm.leadData.api_key = vm.leadProviders[0].value;
      }

      if (vm.errorMessages.length > 0) {
        vm.showError = true;
      }

      vm.dataLoading = false;

      vm.leadData.lastpaydate = data.last_pay_date;
      vm.leadData.nextpaydate = data.next_pay_date;
      vm.leadData.secondpaydate = data.second_pay_date;

      vm.leadData.address = data.address;
      vm.leadData.homephone = data.homephone;
      vm.leadData.ssn = data.ssn;
      vm.leadData.accountnumber = data.accountnumber;
    } catch (error) {
      vm.errorMessages.push(error.message);
    }
  },
  methods: {
    submitLead() {
      const vm = this;

      this.formLoading = true;
      this.formError = '';

      // if (this.isReturnCampaign && this.leadData.customer_guid) {
      //   this.leadData.return_customer = 1;
      //
      //   // make sure email and phone match to get past fraud check
      //   const activeCustomer = this.customers.find(
      //     (customer) => customer.value === this.leadData.customer_guid,
      //   );
      //   this.leadData.email = activeCustomer.email;
      //   this.leadData.homephone = activeCustomer.phone;
      // }
      let apiUrl = 'api/';

      if (vm.isReturnCampaign && vm.settings.customerPortal.external_offer_enabled) {
        apiUrl = this.settings.customerPortal.return_app_url;
        vm.leadData.api_key = this.settings.customerPortal.return_app_provider_api_key;
        this.leadData.campaign = this.settings.customerPortal.return_app_campaign_id;
      }

      this.$axios
        .post(apiUrl, this.leadData)
        .then((res) => {
          vm.apiResponse = res.data;
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            vm.apiResponse = error.response.data;
            vm.formError = 'Please fix the following errors';
          } else {
            this.setNotification({
              message: 'There was an error processing the lead, please try again',
              color: 'red',
            });
          }
        })
        .then(() => {
          vm.showModal = true;
          vm.formLoading = false;
        });
    },
    ...mapActions('notification', ['setNotification']),
  },
};
</script>

<style></style>
