<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(menu, index) in menus">
        <v-tab
          :key="index"
          :to="{ name: menu.route }"
        >
          {{ menu.label }}
        </v-tab>
      </template>
    </v-tabs>
    <v-card class="mt-10 transparent">
      <router-view />
    </v-card>
  </v-container>
</template>

<script>
// import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'FincenIndex',

  components: {
    // DisabledForLackOfRoleBtn,
  },

  data() {
    return {
      menus: [
        {
          label: 'Batches',
          route: 'fincen-batches',
          permission: 'view fincen::batches',
        },
        {
          label: 'Files',
          route: 'fincen-files',
          permission: 'view fincen::files',
        },
        {
          label: 'Persons',
          route: 'fincen-persons',
          permission: 'view fincen::persons',
        },
      ],
    };
  },

  computed: {},

};
</script>
