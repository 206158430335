<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    calculate-widths
    class="elevation-2 mt-5"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-btn
          depressed
          color="primary"
          @click="toggleStatus()"
        >
          Add New Status
        </v-btn>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        x-small
        class="mr-2 btn-bg-deep-blue"
        @click="editStatus(item)"
      >
        <v-icon
          left
          dark
        >
          mdi-account-outline
        </v-icon>
        Edit
      </v-btn>
      <v-btn
        :loading="deletingStatus"
        x-small
        class="mr-2"
        color="error"
        @click="deleteStatus(item)"
      >
        <v-icon
          left
          dark
        >
          mdi-trash-can-outline
        </v-icon>
        Delete
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import { mapActions } from 'vuex';

export default {
  name: 'OpsTicketStatusList',

  mixins: [dataTableMixin],

  data: () => ({
    headers: [
      { text: 'ID', value: 'id', width: '5%' },
      { text: 'Order', value: 'order', width: '5%' },
      { text: 'Name', value: 'name', width: '15%' },
      { text: 'Description', value: 'description', width: '50%' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '30%',
      },
    ],

    activeStatus: null,
    deletingStatus: false,
  }),

  methods: {
    ...mapActions('notification', ['setNotification']),
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/ops-tickets/status', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
            paginate: true,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data?.data || [];

          this.totalItems = res.data?.meta.total || 0;
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    deleteStatus(item) {
      this.deletingStatus = true;

      return this.$axios.delete(`api/v1/ops-tickets/status/${item.id}`).then(() => {
        this.deletingStatus = false;

        this.updateTable();
      })
        .catch((error) => {
          this.deletingStatus = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    editStatus(item) {
      this.$emit('editing-status', item);
    },

    toggleStatus() {
      this.$emit('toggle-status');
    },
  },
};
</script>
