<template>
  <v-dialog
    v-model="show"
    width="1200px"
    max-width="1200px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">Scoring Element Effective Dates</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="5"
              >
                <ScoringElementCard
                  :scoring-element="scoringElement"
                />
                <div
                  v-if="activeView === 'costForm'"
                  class="mt-5"
                  style="margin-top:40px !important"
                >
                  <h2>Effective Cost Dates for this Scoring Element</h2>
                  <ScoringElementCostList
                    :scoring-element="scoringElement"
                    :type="'reference'"
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="7"
              >
                <h2>
                  Cost effective dates
                </h2>
                <ScoringElementCostEffectiveDates
                  :scoring-element="scoringElement"
                  :active-view="activeView"
                  @changeView="handleChangeView"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import ScoringElementCard from './ScoringElementCard.vue';
import ScoringElementCostEffectiveDates from './ScoringElementCostEffectiveDates.vue';
import ScoringElementCostList from './ScoringElementCostList.vue';

export default {
  components: {
    ScoringElementCard,
    ScoringElementCostEffectiveDates,
    ScoringElementCostList,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    scoringElement: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      activeView: 'costList',
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    handleChangeView(view) {
      this.activeView = view;
    },

    close() {
      this.activeView = 'costList';
      this.$emit('close-modal');
    },
  },
};
</script>

<style></style>
