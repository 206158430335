<template>
  <disabled-for-lack-of-role-btn
    v-if="!hasPermission('create system-settings::app-settings')"
    text="Hide a TBot Action"
    permission="create system-settings::app-settings"
    :x-small="false"
    button-class="mb-2"
  />
  <v-dialog
    v-else
    v-model="dialog"
    width="400px"
  >
    <template #activator="{ on: dialog }">
      <v-btn
        dark
        class="mb-2 btn-bg-deep-blue"
        v-on="{ ...dialog }"
      >
        Hide a TBot Action
      </v-btn>
    </template>

    <v-card
      :loading="loading"
    >
      <v-card-title>
        <span class="headline">Hide a TBot Action</span>
      </v-card-title>

      <v-card-text>
        <p> Select the Tbot action below that you wish to hide on this platform.</p>
        <v-container>
          <v-select
            v-model="tbotAction"
            :items="actions"
            item-text="text"
            item-value="name"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="loading"
          @click="dialog = false"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="loading"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'HideTbotActionFormModal',
  components: {
    DisabledForLackOfRoleBtn,
  },
  data() {
    return {
      dialog: false,
      loading: true,
      tbotAction: '',
    };
  },
  computed: {
    ...mapState('tbotActions', ['actions']),
  },
  async created() {
    await this.getTbotActions(0);
    this.loading = false;
  },
  methods: {
    ...mapActions('tbotActions', ['getTbotActions']),
    ...mapActions('notification', ['setNotification']),
    async save() {
      if (this.tbotAction !== '') {
        this.loading = true;

        const data = {
          site: 'admin',
          name: this.tbotAction,
          value: 'false',
        };

        try {
          const request = await this.$axios.post('/api/v1/system-setting', data);

          this.$emit('item-saved');

          this.setNotification({
            message: `${request.data.name} has been saved.`,
            color: 'green',
          });
          this.loading = false;
          this.dialog = false;
        } catch (error) {
          let errorMessage = 'There was an error processing your request.';
          if (error.message === 'Request failed with status code 422') {
            errorMessage = 'This TBot action already has been set, please edit it to false to hide.';
          }
          this.setNotification({
            message: errorMessage,
            color: 'red',
          });
          this.loading = false;
        }
      } else {
        this.setNotification({
          message: 'Please select which TBot Action you want to hide.',
          color: 'red',
        });
        this.loading = false;
      }
    },
  },
};
</script>
