<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    @click:outside="close()"
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">Update Lending Strategy Status?</span>
      </v-card-title>

      <v-card-text>
        <p>Are you sure you want to <strong>{{ newStatus }}</strong> {{ editedItem.name }}?</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="$emit('close-modal')"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LendingStrategiesChangeStatusModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          name: '',
          description: '',
          active: 0,
          scoring_mo_id: null,
          conditions: [],
          productAssignmentModelId: null,
        };
      },
    },
  },
  data() {
    return {
      id: null,
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    newStatus() {
      return this.editedItem.active ? 'deactivate' : 'activate';
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$axios
        .post(`/api/v1/lending-strategy/${this.editedItem.id}/change-status`, {
          active: this.editedItem.active ? 0 : 1,
        })
        .then(() => {
          this.$emit('item-saved');

          this.setNotification({
            message: `Lending strategy status has been update to ${this.newStatus}.`,
            color: 'green',
          });

          this.close();
        });
    },
    close() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style></style>
