<template>
  <v-dialog
    :value="true"
    max-width="1024px"
    scrollable
    @click:outside="close()"
  >
    <v-card
      id="product-assignment-view"
      :loading="loading"
    >
      <v-card-title>
        <span>Product Assignment Model: {{ formData.name }}</span>

        <v-spacer />

        <v-btn
          icon
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <div
        v-if="loading"
        class="d-flex flex-column align-center py-4 px-4"
      >
        <h3 class="mb-6 mt-2">
          Loading Model...
        </h3>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
      </div>
      <template v-else>
        <v-card-text style="height: 80vh;">
          <v-container justify="space-around">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="formData.name"
                  required
                  label="Name"
                  hide-details="auto"
                  :error-messages="nameErrors"
                  @blur="$v.formData.name.$touch()"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="formData.endpoint"
                  required
                  label="Endpoint"
                  hide-details="auto"
                  :error-messages="endpointErrors"
                  @blur="$v.formData.endpoint.$touch()"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model.trim="formData.description"
                  label="Description"
                  auto-grow
                  rows="3"
                  hide-details="auto"
                  :error-messages="descriptionErrors"
                  @blur="$v.formData.description.$touch()"
                />
              </v-col>
            </v-row>

            <v-row class="d-block px-3 mt-12">
              <v-data-table
                :headers="headers"
                :items="modelTiers"
                :items-per-page="5"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    class="px-0"
                  >
                    <v-toolbar-title>Model Tiers</v-toolbar-title>

                    <v-spacer />

                    <v-btn
                      class="btn-bg-deep-blue mr-10"
                      small
                      :disabled="!isUpdating"
                      @click="openTierFormModal()"
                    >
                      New Tier
                    </v-btn>
                  </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    small
                    color="primary"
                    class="mr-5"
                    @click="openTierFormModal(item)"
                  >
                    Update Tier
                  </v-btn>

                  <v-btn
                    small
                    color="secondary"
                    :disabled="!isUpdating"
                    @click="openTierDebugModal(item)"
                  >
                    Debug
                  </v-btn>
                </template>
              </v-data-table>
            </v-row>
          </v-container>

          <ProductAssignmentModelTier
            v-if="tierViewModalOpen"
            :tier-data="activeTier"
            :model-id="modelId"
            @close="tierViewModalOpen = false"
            @tier-saved="onTierSaved"
          />
          <ProductAssignmentModelDebugView
            v-if="isUpdating && debugViewModalOpen"
            :model-name="formData.name"
            :tier-data="activeTier"
            @close="debugViewModalOpen = false; activeTier = null"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancel
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$invalid"
            @click="save()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions } from 'vuex';
import ProductAssignmentModelTier from './ProductAssignmentModelTier.vue';
import ProductAssignmentModelDebugView from './ProductAssignmentModelDebugView.vue';

export default {
  name: 'ProductAssignmentModel',

  components: {
    ProductAssignmentModelTier,
    ProductAssignmentModelDebugView,
  },

  props: {
    modelData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: true,
      savingForm: false,

      formData: {
        id: null,
        name: '',
        endpoint: '',
        description: '',
      },

      activeTier: null,
      tierViewModalOpen: false,
      modelTiers: [],

      headers: [
        { text: 'Tier', value: 'tier_score' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 315,
        },
      ],

      debugViewModalOpen: false,
    };
  },

  computed: {
    nameErrors() {
      const errors = [];

      if (!this.$v.formData.name.$dirty) {
        return errors;
      }
      if (!this.$v.formData.name.required) {
        errors.push('Name is required.');
      }

      return errors;
    },
    endpointErrors() {
      const errors = [];

      if (!this.$v.formData.endpoint.$dirty) {
        return errors;
      }
      if (!this.$v.formData.endpoint.required) {
        errors.push('Endpoint is required.');
      }
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.formData.description.$dirty) {
        return errors;
      }
      if (!this.$v.formData.description.required) {
        errors.push('Description is required.');
      }

      return errors;
    },

    isUpdating() {
      return Number(this.formData.id) > 0;
    },

    modelId() {
      return Number(this.formData.id);
    },
  },

  validations: {
    formData: {
      name: {
        required,
      },
      description: {
        required,
      },
      endpoint: { required },
    },
  },

  created() {
    this.loading = false;
    this.formData = cloneDeep({ ...this.modelData });
    this.getModelTiers();
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    close() {
      this.$router.push({ name: 'product-assignment' });
    },

    async save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          this.savingForm = true;

          const response = !this.isUpdating
            ? await this.$axios.post('api/v1/product-assignment-model', this.formData)
            : await this.$axios.put(`api/v1/product-assignment-model/${this.modelData.id}`, this.formData);

          this.setNotification({
            color: 'green',
            message: `Product Assignment ${this.formData.name} has been saved.`,
          });

          this.formData = cloneDeep({ ...response.data });
          this.$emit('model-saved', cloneDeep({ ...response.data }));
        } catch (err) {
          let message = 'There was an error saving the Product Assignment.';

          if (err.response && err.response.data.errors.name) {
            [message] = err.response.data.errors.name;
          }
          if (err.response && err.response.data.errors.endpoint) {
            [message] = err.response.data.errors.endpoint;
          }
          if (err.response && err.response.data.errors.isLocked) {
            [message] = err.response.data.errors.isLocked;
          }

          this.setNotification({
            color: 'red',
            message,
          });
        } finally {
          this.savingForm = false;
        }
      }
    },

    openTierFormModal(tier = {}) {
      this.activeTier = { ...tier };
      this.tierViewModalOpen = true;
    },

    openTierDebugModal(tier) {
      this.debugViewModalOpen = true;
      this.activeTier = { ...tier };
    },

    toggleTierStatus(/* item */) {
      // console.log(item);
    },

    onTierSaved(tier) {
      const index = this.modelTiers.findIndex((t) => t.id === tier.id);

      if (index > -1) {
        this.modelTiers.splice(index, 1, tier);
      } else {
        this.modelTiers.push(tier);
      }
    },

    getModelTiers() {
      this.loading = true;

      this.$axios.get(`api/v1/product-assignment-model/${this.modelData.id}/tiers`)
        .then((response) => {
          this.modelTiers = response.data;
        })
        .catch((/* err */) => {
          /* console.log(err) */
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
#product-assignment-view .v-toolbar__title,
#product-assignment-view .v-btn__content {
  text-transform: none !important;
}

#product-assignment-view .v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
}
</style>
