<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      class="fraud-claim"
      width="1200px"
      scrollable
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasPermission('view ticket::fraud-claim')"
          v-bind="attrs"
          color="secondary"
          x-large
          block
          class="fraud-claim-dialog-btn"
          v-on="on"
        >
          {{ title }}
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          :text="title"
          permission="view ticket::fraud-claim"
          :x-small="false"
          :x-large="true"
          :block="true"
          button-class="fraud-claim-dialog-btn"
        />
      </template>

      <v-card :loading="loadingModel">
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }} - QUESTIONNAIRE
        </v-card-title>

        <v-card-text class="py-5">
          <v-row>
            <v-col cols="10">
              <p>
                <b>PLEASE READ!!!!!!!</b>
              </p>
              <p>
                Using the information from Loan Pro, please ask the caller if the following matches
                their information. <b>NEVER ask a victim of identity theft to give us their
                  personal information</b>. If you were a victim of identity theft, would you give
                your
                personal information to a stranger? No, most definitely would not.
              </p>
              <p>
                Mark (Y, N, or wouldn't respond)
              </p>
              <v-row
                v-for="(question, index) in questions"
                :key="`question-${index}`"
                class="justify-center"
              >
                <v-col class="justify-center">
                  <p>{{ question.question }}</p>
                </v-col>
                <v-col>
                  <v-radio-group
                    v-model="questions[index]['callerResponse']"
                    row
                  >
                    <v-radio
                      v-for="(option, index) in questions[index]['responseOptions']"
                      :key="index"
                      :label="option"
                      :value="getValueFromOption(option)"
                      @change="clickQuestion(question.question, option)"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <p>
                NOTE:  Please inform the caller that they should visit
                <a href="www.identitytheft.gov">www.identitytheft.gov</a>
                and file an Identity Theft Report.  The easy, step by step guide, will help
                them navigate the stress of dealing with identity theft.
              </p>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="callNote"
                    outlined
                    name="input-7-4"
                    label="Call Notes"
                    placeholder="Additional information gathered during call (Optional)"
                    rows="3"
                    no-resize
                  />
                </v-col>
              </v-row>
              <v-row
                v-for="(question, index) in csAdminQuestions"
                :key="`csadminquestion-${index}`"
                class="justify-center"
              >
                <v-col class="justify-center">
                  <p>{{ question.question }}</p>
                </v-col>
                <v-col>
                  <v-radio-group
                    v-model="csAdminQuestions[index]['response']"
                    row
                  >
                    <v-radio
                      label="Yes"
                      value="Y"
                    />
                    <v-radio
                      label="No"
                      value="N"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />
          <v-btn
            v-if="hasPermission('create ticket::fraud-claim-validate')"
            color="primary"
            text
            @click="confirmDialog = true"
          >
            Valid
          </v-btn>
          <v-btn
            v-if="hasPermission('create ticket::fraud-claim-validate')"
            color="primary"
            text
            @click="completeFraud(false)"
          >
            InValid
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="hasPermission('create ticket::fraud-claim')"
            color="primary"
            @click="processForm"
          >
            Submit Questionnaire
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="Submit Questionnaire"
            permission="create ticket::fraud-claim"
            :x-small="false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="customMessageDialog"
      max-width="500px"
      @click:outside="customMessageDialog = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Read to Customer</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="mt-3"
            >
              <p v-html="fraudItemToReview.read_to_customer" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="customMessageDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Are You Sure?
        </v-card-title>

        <v-card-text>
          <v-col>
            <v-row
              class="justify-center"
            >
              <v-col>
                <p>Loan Funded?</p>
                <v-radio-group
                  v-model="hasFundInAccount"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row
              class="justify-center"
            >
              <v-col>
                <p>Consent to return funds??</p>
                <v-radio-group
                  v-model="consentACHDebit"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="completeFraud(true)"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'FraudAfterFunding',
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    options: {
      type: Object,
      required: true,
      default: null,
    },
    ticketId: {
      type: Number,
      required: true,
      default: null,
    },
    customer: {
      type: Object,
      required: true,
      default: null,
    },
    loanId: {
      type: Number,
      required: true,
      default: null,
    },
    leadGuid: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      csAdminQuestions: [
        {
          question: 'Did you inform the caller that they need to visit www.identitytheft.gov and file an Identity Theft Report since they are a victim of identity theft?  The easy, step by step guide, will help them navigate through this stressful time. NOTE:  We no longer request a police report, however, they are welcome to file one if they wish.  Completing the information on www.identitytheft.gov is just as useful, if not more useful, as filing a police report.',
          response: null,
        },
        {
          question: 'Do they intend to file an Identity Theft Report?',
          response: null,
        },
      ],
      dialog: false,
      callNote: '',
      loadingModel: false,
      customMessageDialog: false,
      hasFundInAccount: true,
      consentACHDebit: true,
      confirmDialog: false,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['fraudItemToReview']),

    questions() {
      return [
        {
          question: `Are these the last four of SSN? ${this.customer.ssn}`,
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: `Is this your Date of Birth? ${this.customer.dob}`,
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: `Is this your email? ${this.customer.email}`,
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: `Is this your Address? ${this.customer.address}, ${this.customer.address2}, ${this.customer.city}, ${this.customer.state}`,
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: `Are these the Last four of Bank Account Number? ${this.fraudItemToReview.lp_funding?.payment_account?.checking_account?.account_number || ''}`,
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: `Are these your Bank Routing Number? ${this.fraudItemToReview.lp_funding?.payment_account?.checking_account?.routing_number || ''}`,
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: 'Have you recently applied for any loans online?',
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: 'Did you receive the deposit in this bank account?',
          callerResponse: null,
          responseOptions: ['Yes', 'No'],
        },
        {
          question: 'If you received the deposit in this bank account, do you consent to return funds?',
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: 'If the bank account doesn\'t match, was this an old account?',
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: 'Did you ever have an account at that bank?',
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: 'Are you aware of anyone that has access to all of their personal information?',
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: 'Did you receive the email alerting them that the funding was being sent?',
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
        {
          question: 'Have they logged into the client portal?',
          callerResponse: null,
          responseOptions: ['Yes', 'No', 'Wouldn\'t respond'],
        },
      ];
    },
    itemsToReviewQuestions() {
      return [
        {
          question: 'How many times was ID verification attempted? This is not the total of times they\'ve applied.  This is the total only for the loan they were approved for.  Look at the approved loan and obtain the number under ID Verification. NOTE: If it is more than 2, please Slack your Team Lead.',
          value: this.fraudItemToReview.num_verification_attempt,
        },
        {
          question: 'Have they logged into the client portal?',
          value: this.fraudItemToReview.logged_into_client_portal,
        },
        {
          question: 'Have any payments been attempted?',
          value: this.fraudItemToReview.payments_attempted,
        },
        {
          question: 'Have any payments been successful?',
          value: this.fraudItemToReview.payments_successful,
        },
        {
          question: 'Have any payments been returned?',
          value: this.fraudItemToReview.payments_returned,
        },
        {
          question: 'If any payments have been returned, what was the return code?',
          value: this.fraudItemToReview.payments_returned_code,
        },
      ];
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async processForm() {
      this.loadingModel = true;
      // validate questions and responses
      const invalidCallerRes = this.questions.find((question) => question.callerResponse === null);
      if (invalidCallerRes && Object.keys(invalidCallerRes).length > 0) {
        this.loadingModel = false;
        this.setNotification({
          message: `Question "${invalidCallerRes.question}" was not answered`,
          color: 'red',
        });
        return;
      }
      // validate csAdminQuestions and responses
      const invalidCsAdRes = this.csAdminQuestions.find((question) => question.response === null);
      if (invalidCsAdRes && Object.keys(invalidCsAdRes).length > 0) {
        this.loadingModel = false;
        this.setNotification({
          message: `Question "${invalidCsAdRes.question}" was not answered`,
          color: 'red',
        });
        return;
      }
      try {
        const res = await this.$axios
          .post('api/v1/support/ticket/fraud-claim/after-funding', {
            ticketId: this.ticketId,
            loanId: this.loanId,
            customer: this.customer,
            questionnaire: this.questions,
            itemsToReview: this.itemsToReviewQuestions,
            csAdminQuestionnaire: this.csAdminQuestions,
            callNotes: this.callNote,
            ncaChargeOffFraud: this.options.ncaCharge,
            leadGuid: this.leadGuid,
          });
        this.loadingModel = false;
        if (res.status === 200 && res.data) {
          this.dialog = false;
          if (this.options.ncaCharge === true) {
            this.setNotification({
              message: 'Fraud ticket has been assigned to compliance. Ticket status is now Pending',
              color: 'green',
            });
            setTimeout(() => {
              this.$router.push({ name: 'tickets' });
            }, 2000);
          } else {
            this.setNotification({
              message: res.data.message,
              color: 'green',
            });
          }
        }
      } catch (error) {
        let errMsg = 'There was an error processing your request';
        if (error.response && error.response.status === 404 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
    getValueFromOption(option) {
      if (['Yes', 'No'].includes(option)) {
        return option.split('')[0];
      }
      return option;
    },
    clickQuestion(question, option) {
      if (question === 'Did you receive the deposit in this bank account?' && option === 'Yes') {
        this.dialog = false;
        this.customMessageDialog = true;
      }
    },
    async completeFraud(status) {
      this.loadingModel = true;
      try {
        const res = await this.$axios
          .post(`api/v1/support/ticket/fraud-claim/${this.loanId}/complete`, {
            ticketId: this.ticketId,
            loanId: this.loanId,
            validFraud: status,
            hasFundInAccount: this.hasFundInAccount,
            consentACHDebit: this.consentACHDebit,
            customer: this.customer,
          });
        this.loadingModel = false;
        if (res.status === 200 && res.data) {
          this.dialog = false;
          this.confirmDialog = false;
          this.setNotification({
            message: res.data.message,
            color: 'green',
          });
        }
      } catch (error) {
        let errMsg = 'There was an error processing your request';
        if (error.response && error.response.status === 404 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
  },
};
</script>
