<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>LendingTree Customers</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <v-row
            align="center"
            justify="space-around"
          >
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="costReportsOpen = true"
            >
              Daily Cost Reports
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="productModelOpen = true"
            >
              Product Model
            </v-btn>
          </v-row>

          <LendingtreeCostReportsModal
            v-model="costReportsOpen"
            @close-modal="closeModal('costReportsOpen')"
            @click:outside="closeModal('costReportsOpen')"
          />

          <LendingtreeProductModelModal
            v-model="productModelOpen"
            @close-modal="closeModal('productModelOpen')"
            @click:outside="closeModal('productModelOpen')"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created) }}
    </template>
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';
import LendingtreeCostReportsModal from '@/views/Leads/Lendingtree/LendingtreeCostReportsModal.vue';
import LendingtreeProductModelModal from '@/views/Leads/Lendingtree/LendingtreeProductModelModal.vue';

export default {
  name: 'LendingtreeLeadIndex',

  components: { LendingtreeProductModelModal, LendingtreeCostReportsModal },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Lead ID', value: 'lead_id' },
      { text: 'Waterfall', value: 'lead.final_result', sortable: false },
      { text: 'Tracking ID', value: 'lt_tracking_id' },
      { text: 'Filter ID', value: 'lt_filter_id' },
      { text: 'First name', value: 'first_name' },
      { text: 'Last name', value: 'last_name' },
      { text: 'Email', value: 'email' },
      { text: 'Phone', value: 'phone' },
      { text: 'State', value: 'state' },
      { text: 'Status', value: 'status' },
      { text: 'Redirected', value: 'redirected' },
      { text: 'Pre-offer', value: 'offer_sent' },
      { text: 'Date', value: 'created' },
    ],
    costReportsOpen: false,
    productModelOpen: false,
    editedItem: {
      id: null,
    },
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/lendingtree/customers', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toFormat('yyyy-MM-dd HH:mm:ss');
    },
  },
};
</script>
