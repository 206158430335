<template>
  <v-dialog
    v-model="show"
    max-width="900px"
    scrollable
    @click:outside="close()"
  >
    <v-card :loading="loading">
      <v-card-title>
        Edit Sequence for <span class="font-weight-bold ml-1">{{ editedItem.name }}</span>
      </v-card-title>
      <v-card-subtitle>
        {{ editedItem.description }}
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <v-row>
            <v-alert
              v-if="isLendingStrategyLocked"
              type="warning"
              title="Lending Strategy is Locked"
              text
              icon="mdi-lock"
            >
              Scoring model has been attached to a lead. Therefore sequence cannot be updated.
            </v-alert>
          </v-row>
          <v-row
            justify="space-around"
          >
            <v-card
              class="mx-auto"
              width="400"
              tile
            >
              <v-toolbar
                color="green"
                dark
              >
                <v-toolbar-title>Active</v-toolbar-title>
              </v-toolbar>
              <v-list dense>
                <v-list-item-group>
                  <draggable
                    class="list-group"
                    :list="active"
                    group="scoring-elements"
                    :move="onMoveActive"
                    :disabled="isLendingStrategyLocked"
                    @change="updateSequence()"
                  >
                    <v-list-item
                      v-for="(item, index) in active"
                      :key="index"
                    >
                      <v-list-item-content>
                        <div class="d-flex align-center justify-space-between">
                          <span>
                            <v-chip
                              label
                              color="blue"
                              text-color="white"
                              class="mr-2"
                            >
                              {{ index + 1 }}
                            </v-chip>

                            <span class="font-weight-bold">
                              {{ item.class }}->{{ item.method }}
                            </span>
                          </span>

                          <v-list-item-icon v-if="hasCustomInputs(item)">
                            <v-icon
                              color="blue darken-2"
                              @click="openDialogElement(item)"
                            >
                              mdi-cog
                            </v-icon>
                          </v-list-item-icon>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-list-item-group>
              </v-list>
            </v-card>

            <v-card
              class="mx-auto"
              width="400"
              tile
            >
              <v-toolbar
                color="cyan"
                dark
              >
                <v-toolbar-title>Available</v-toolbar-title>
              </v-toolbar>
              <v-list dense>
                <v-list-item-group>
                  <draggable
                    class="list-group"
                    :list="available"
                    group="scoring-elements"
                    :move="onMoveAvailable"
                    :disabled="isLendingStrategyLocked"
                  >
                    <v-list-item
                      v-for="(item, index) in available"
                      :key="index"
                    >
                      <v-list-item-content>
                        <div class="d-flex align-center justify-space-between">
                          <span class="font-weight-bold">
                            {{ item.class }}->{{ item.method }}
                          </span>
                          <v-list-item-icon v-if="hasCustomInputs(item)">
                            <v-icon
                              color="blue darken-2"
                              @click="openDialogElement(item)"
                            >
                              mdi-cog
                            </v-icon>
                          </v-list-item-icon>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <ScoringModelSequenceElementModal
      v-model="dialogElement"
      :element-data="dialogElementData"
      @setCustomVars="onSetCustomVars"
      @close-modal="closeDialogElement()"
      @click:outside="closeDialogElement()"
    />
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import ScoringModelSequenceElementModal from '@/views/LendingStrategies/ScoringModel/ScoringModelSequenceElementModal.vue';

export default {
  components: { ScoringModelSequenceElementModal, draggable },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          custom_vars: null,
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      available: [],
      active: [],
      isLendingStrategyLocked: false,
      dialogElement: false,
      dialogElementData: {},
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    editedItem() {
      if (this.editedItem.id) {
        // set dialog modal components to default value
        this.active = [];
        this.available = [];
        this.isLendingStrategyLocked = false;
        // fetch sequence from scoring model
        this.fetchSequences();
      }
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    close() {
      this.$emit('close-modal');
    },
    closeDialogElement() {
      this.dialogElementData = {};
      this.dialogElement = false;
    },
    openDialogElement(item) {
      this.dialogElementData = {
        scoringModelId: this.editedItem.id,
        isLendingStrategyLocked: this.isLendingStrategyLocked,
        ...item,
      };

      this.dialogElement = true;
    },
    fetchSequences() {
      this.loading = true;

      return this.$axios
        .get(`api/v1/scoring-model/${this.editedItem.id}/sequence`)
        .then((res) => {
          if (res.status === 200) {
            this.active = res.data.activeSequences;
            this.available = res.data.availableSequences;
            this.isLendingStrategyLocked = res.data.isLockedLendingStrategy;
          }
        })
        .then(() => { this.loading = false; });
    },
    updateSequence() {
      const activeIds = this.active.map((item) => item.id);
      const payload = { item: activeIds };

      return this.$axios
        .post(`api/v1/scoring-model/${this.editedItem.id}/sequence/update-order`, payload);
    },
    hasCustomInputs(item) {
      return item.route_custom_inputs
          && JSON.parse(item.route_custom_inputs)
          && JSON.parse(item.route_custom_inputs).length;
    },
    hasCustomVarForInput(scoringElementId) {
      const customVars = this.editedItem.custom_vars && JSON.parse(this.editedItem.custom_vars)
        ? JSON.parse(this.editedItem.custom_vars)
        : {};

      return customVars[`element_${scoringElementId}`];
    },
    onMoveAvailable(event) {
      // check if the scoring element requires custom inputs
      if (this.hasCustomInputs(event.draggedContext.element)) {
        // then check if the scoring model has a var saved for that element
        if (this.hasCustomVarForInput(event.draggedContext.element.id)) {
          return true;
        }
        // if it doesn't, we want to tell them to add the config first
        this.setNotification({
          message: 'Please enter config values before moving!',
          color: 'red',
        });
        return false;
      }
      return true;
    },
    onMoveActive() {
      this.updateSequence();
    },
    onSetCustomVars(event) {
      // this event gets passed to ScoringModelIndex to update custom_vars in local state
      this.$emit('setCustomVars', event);
    },
  },
};
</script>

<style scoped></style>
