<template>
  <v-row justify="center">
    <v-dialog
      :value="true"
      max-width="820px"
      scrollable
      @click:outside="close()"
    >
      <v-card
        :loading="loading"
        class="tier-settings"
      >
        <v-card-title>
          <span>Tier: {{ formData.tier_score }}</span>

          <v-spacer />

          <v-btn
            icon
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text style="max-height: 65vh;">
          <v-expansion-panels flat>
            <!-- Overview -->
            <v-expansion-panel>
              <v-expansion-panel-header class="text-lg-h5">
                Overview
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row justify="space-around">
                  <v-col cols="12">
                    <v-text-field
                      v-model.lazy.number="formData.tier_score"
                      required
                      type="number"
                      label="Tier Score"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.lazy.number="formData.max_term"
                      required
                      label="Max Term"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.lazy.number="formData.min_loan_amount"
                      label="Min Loan Amount"
                      required
                      type="number"
                      @change="handleLoanLimitChanges()"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.lazy.number="formData.max_loan_amount"
                      label="Max Loan Amount"
                      required
                      type="number"
                      @change="handleLoanLimitChanges()"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="formData.use_requested_as_max_loan"
                      label="Use Request Amount As Max Loan Amount"
                      inset
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- ./Overview -->

            <!-- Payment To Income Ratio -->
            <v-expansion-panel>
              <v-expansion-panel-header class="text-lg-h5">
                Payment To Income Ratio
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table
                  dense
                  style="width: 400px;"
                >
                  <tbody>
                    <tr
                      v-for="(_, key) in formData.payment_income_ratios"
                      :key="key"
                    >
                      <th>{{ frequencyKeyLabel(key) }}</th>
                      <td>
                        <v-text-field
                          v-model.number="formData.payment_income_ratios[key]"
                          dense
                          hide-details="auto"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- ./Payment To Income Ratio -->

            <!-- Number Of Payments -->
            <v-expansion-panel>
              <v-expansion-panel-header class="text-lg-h5">
                Number Of Payments
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table
                  dense
                  style="width: 400px;"
                >
                  <tbody>
                    <tr
                      v-for="(_, key) in formData.number_of_payments"
                      :key="key"
                    >
                      <th>{{ frequencyKeyLabel(key) }}</th>
                      <td>
                        <v-text-field
                          v-model.number="formData.number_of_payments[key]"
                          dense
                          hide-details="auto"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- ./Number Of Payments -->

            <!--  Loan Rates and Max Allowed Payments  -->
            <v-expansion-panel>
              <v-expansion-panel-header class="text-lg-h5">
                Rates and Max Allowed Payments
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table
                  fixed-header
                  height="400px"
                >
                  <thead>
                    <tr>
                      <th
                        rowspan="2"
                        style="width: 120px;"
                      >
                        Loan Amount
                      </th>
                      <th rowspan="2">
                        Rate
                      </th>
                      <th
                        colspan="4"
                        class="text-center"
                      >
                        Max Allowed Payments
                      </th>
                    </tr>
                    <tr>
                      <th>Weekly</th>
                      <th>Bi-Weekly</th>
                      <th>Semi-Monthly</th>
                      <th>Monthly</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(loanAmount) in loanAmountRanges"
                      :key="loanAmount"
                    >
                      <th>{{ loanAmount }}</th>
                      <td>
                        <v-text-field
                          :value="getRateValue(loanAmount)"
                          dense
                          hide-details="auto"
                          @change="formData.loan_rates[loanAmount].rate = $event"
                        />
                      </td>
                      <td>
                        <v-text-field
                          dense
                          hide-details="auto"
                          :value="getMaxAllowedPaymentValue(loanAmount, 'weekly')"
                          @change="setMaxAllowedPaymentValue(loanAmount, 'weekly', $event)"
                        />
                      </td>
                      <td>
                        <v-text-field
                          dense
                          hide-details="auto"
                          :value="getMaxAllowedPaymentValue(loanAmount, 'biweekly')"
                          @change="setMaxAllowedPaymentValue(loanAmount, 'biweekly', $event)"
                        />
                      </td>
                      <td>
                        <v-text-field
                          dense
                          hide-details="auto"
                          :value="getMaxAllowedPaymentValue(loanAmount, 'semimonthly')"
                          @change="setMaxAllowedPaymentValue(loanAmount, 'semimonthly', $event)"
                        />
                      </td>
                      <td>
                        <v-text-field
                          dense
                          hide-details="auto"
                          :value="getMaxAllowedPaymentValue(loanAmount, 'monthly')"
                          @change="setMaxAllowedPaymentValue(loanAmount, 'monthly', $event)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-row class="mt-10 flex-column justify-center">
                  <v-file-input
                    v-model="tierUploadFile"
                    accept=".xls,.xlsx"
                    placeholder="Upload valid tier format xls or xlsx file"
                  />
                  <v-btn
                    color="primary"
                    @click="extractTierData"
                  >
                    Extract Tier Data
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- ./Loan Rates and Max Allowed Payments  -->
          </v-expansion-panels>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancel
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            :disabled="savingForm"
            @click="save()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable max-len */

import range from 'lodash/range';
import { mapActions } from 'vuex';
import readXlsxFile from 'read-excel-file';

export default {
  props: {
    modelId: {
      type: [String, Number],
      default: null,
    },
    tierData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: true,
      savingForm: false,

      formData: {
        id: null,
        tier_score: null,
        max_term: null,
        min_loan_amount: null,
        max_loan_amount: null,
        use_requested_as_max_loan: false,
        payment_income_ratios: {
          weekly: null,
          biweekly: null,
          semimonthly: null,
          monthly: null,
        },
        number_of_payments: {
          weekly: null,
          biweekly: null,
          semimonthly: null,
          monthly: null,
        },
        max_allowed_payments: {},
        loan_rates: {},
      },

      tierUploadFile: null,
    };
  },

  computed: {
    isUpdating() {
      return this.formData.id !== null;
    },

    loanAmountRanges() {
      const { min_loan_amount, max_loan_amount } = this.formData;

      if (min_loan_amount > 0 && max_loan_amount > 0) {
        return range(min_loan_amount, max_loan_amount + 1, 100);
      }

      return [];
    },
  },

  created() {
    this.loading = false;
    this.formData = { ...this.formData, ...this.tierData };
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    getRateValue(loanAmount) {
      return this.formData.loan_rates[loanAmount]?.rate || null;
    },

    getMaxAllowedPaymentValue(loanAmount, frequency) {
      return this.formData.max_allowed_payments[loanAmount]?.[frequency] || null;
    },

    setMaxAllowedPaymentValue(loanAmount, frequency, value) {
      this.formData.max_allowed_payments[loanAmount][frequency] = value;
    },

    frequencyKeyLabel(key) {
      return {
        weekly: 'Weekly',
        biweekly: 'Bi-Weekly',
        semimonthly: 'Semi-Monthly',
        monthly: 'Monthly',
      }[key];
    },
    close() {
      this.$emit('close');
    },

    async save() {
      // console.log('formData', this.formData);

      try {
        this.savingForm = true;

        const baseUrl = `api/v1/product-assignment-model/${this.modelId}/tiers`;
        const response = !this.isUpdating
          ? await this.$axios.post(baseUrl, this.formData)
          : await this.$axios.put(`${baseUrl}/${this.formData.id}`, this.formData);

        this.setNotification({
          color: 'green',
          message: `Tier ${this.isUpdating ? 'updated' : 'created'} successfully`,
        });

        this.$emit('tier-saved', response.data);
      } catch (error) {
        this.setNotification({
          color: 'red',
          message: 'An error occurred while saving the tier',
        });
      } finally {
        this.savingForm = false;
      }
    },

    handleLoanLimitChanges() {
      const { max_allowed_payments, loan_rates } = this.formData;

      this.formData.max_allowed_payments = {};
      this.formData.loan_rates = {};

      this.loanAmountRanges.forEach((loanAmount) => {
        this.formData.max_allowed_payments[loanAmount] = {
          weekly: null,
          biweekly: null,
          semimonthly: null,
          monthly: null,
          ...max_allowed_payments[loanAmount],
          loan_amount: loanAmount,
        };

        this.formData.loan_rates[loanAmount] = {
          rate: null,
          ...loan_rates[loanAmount],
          loan_amount: loanAmount,
        };
      });
    },

    extractTierData() {
      // console.log('extractTierData', this.tierUploadFile);

      if (!this.tierUploadFile) {
        this.setNotification({
          color: 'red',
          message: 'Please upload a valid tier format xls or xlsx file',
        });
        return;
      }

      const schema = {
        tier: {
          prop: 'tier',
          type: (value) => Math.round(value),
          required: true,
        },
        rate: {
          prop: 'rate',
          type: (value) => Math.round(value),
          required: true,
        },
        weekly: {
          prop: 'weekly',
          type: (value) => Math.round(value),
          required: true,
        },
        biweekly: {
          prop: 'biweekly',
          type: (value) => Math.round(value),
          required: true,
        },
        semimonthly: {
          prop: 'semimonthly',
          type: (value) => Math.round(value),
          required: true,
        },
        monthly: {
          prop: 'monthly',
          type: (value) => Math.round(value),
          required: true,
        },
      };
      const sheet = `t${this.formData.tier_score}`;

      readXlsxFile(this.tierUploadFile, { schema, sheet }).then(({ rows, errors }) => {
        // console.log('rows', rows);
        // console.log('errors', errors);

        if (errors.length === 0) {
          const loan_rates = {};
          const max_allowed_payments = {};

          this.loanAmountRanges.forEach((loanAmount) => {
            const tierData = rows.find((row) => Number(row.tier) === loanAmount);

            if (tierData) {
              max_allowed_payments[loanAmount] = {
                weekly: tierData.weekly,
                biweekly: tierData.biweekly,
                semimonthly: tierData.semimonthly,
                monthly: tierData.monthly,
                loan_amount: loanAmount,
              };

              loan_rates[loanAmount] = {
                rate: tierData.rate,
                loan_amount: loanAmount,
              };
            }
          });

          this.formData.max_allowed_payments = {
            ...this.formData.max_allowed_payments,
            ...max_allowed_payments,
          };

          this.formData.loan_rates = {
            ...this.formData.loan_rates,
            ...loan_rates,
          };
        } else {
          this.setNotification({
            color: 'red',
            message: 'An error occurred while extracting tier data',
          });
        }
      });
    },
  },
};
</script>

<style>
.tier-settings table {
  width: auto;
}
</style>
