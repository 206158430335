<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    :height="tableHeight"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Lead Amortization</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <LeadAmortizationExtendedSummaryModal
            v-model="summaryModal"
            :item="summaryItem"
            @close-modal="closeSummary()"
            @click:outside="closeSummary()"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.schedule_match="{ item }">
      {{ item.schedule_match == 1 ? 'Yes' : 'No' }}
    </template>

    <template v-slot:item.setup_match="{ item }">
      {{ item.setup_match == 1 ? 'Yes' : 'No' }}
    </template>

    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created) }}
    </template>

    <template v-slot:item.last_updated="{ item }">
      {{ formatDateTime(item.last_updated) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        color="primary"
        small
        block
        text
        @click="showSummary(item)"
      >
        Extended Summary
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';

import LeadAmortizationExtendedSummaryModal from '@/views/Leads/Amortization/LeadAmortizationExtendedSummaryModal.vue';

export default {
  name: 'LeadAmortizationIndex',
  components: {
    LeadAmortizationExtendedSummaryModal,
  },
  mixins: [
    dataTableMixin,
  ],
  props: {
    height: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Lead ID', value: 'lead_id', sortable: false },
      { text: 'Schedule Match', value: 'schedule_match', sortable: false },
      { text: 'Setup Match', value: 'setup_match', sortable: false },
      { text: 'Created', value: 'created', sortable: false },
      { text: 'Updated', value: 'last_updated', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    summaryModal: false,
    summaryItem: {},
  }),
  computed: {
    tableHeight() {
      const newTableHeight = this.height - 350;
      return `${newTableHeight}px`;
    },
  },
  methods: {
    closeSummary() {
      this.summaryModal = false;
      this.$nextTick(() => {
        this.summaryItem = {};
      });
    },
    showSummary(item) {
      this.summaryItem = { ...item };
      this.summaryModal = true;
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/lead/amortization', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    },
  },
};
</script>
