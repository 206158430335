<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="campaignName"
                  required
                  label="Campaign Name"
                  :error-messages="campaignNameErrors"
                  @input="$v.campaignName.$touch()"
                  @blur="$v.campaignName.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="cost"
                  type="number"
                  step="0.01"
                  required
                  label="Cost"
                  :error-messages="costErrors"
                  @input="$v.cost.$touch()"
                  @blur="$v.cost.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="biddableCost"
                  :items="biddableCostOptions"
                  required
                  label="Biddable Cost"
                  :error-messages="biddableCostErrors"
                  @input="$v.biddableCost.$touch()"
                  @blur="$v.biddableCost.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="leadProviderId"
                  :items="leadProviders"
                  required
                  label="Lead Provider"
                  :error-messages="leadProviderIdErrors"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="productType"
                  :items="productTypeOptions"
                  required
                  label="Product type"
                  :error-messages="productTypeErrors"
                  @input="$v.productType.$touch()"
                  @blur="$v.productType.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="onfidoEnabled"
                  :items="enabledOptions"
                  required
                  label="Onfido Enabled"
                  :error-messages="onfidoEnabledErrors"
                  @input="$v.onfidoEnabled.$touch()"
                  @blur="$v.onfidoEnabled.$touch()"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="proveEnabled"
                  :items="enabledOptions"
                  required
                  label="Prove Enabled"
                  :error-messages="proveEnabledErrors"
                  @input="$v.proveEnabled.$touch()"
                  @blur="$v.proveEnabled.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="docusignIdCheck"
                  :items="docusignIdCheckOptions"
                  required
                  label="Docusign ID Check"
                  :error-messages="docusignIdCheckErrors"
                  @input="$v.docusignIdCheck.$touch()"
                  @blur="$v.docusignIdCheck.$touch()"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="skipLandingPage"
                  :items="skipLandingPageOptions"
                  required
                  label="Skip Landing Page"
                  :error-messages="skipLandingPageErrors"
                  @input="$v.skipLandingPage.$touch()"
                  @blur="$v.skipLandingPage.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="enabled"
                  :items="enabledOptions"
                  required
                  label="Enabled"
                  :error-messages="enabledErrors"
                  @input="$v.enabled.$touch()"
                  @blur="$v.enabled.$touch()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          cost: '',
          lead_provider_id: '',
          biddable_cost: 0,
          campaign_name: '',
          product_type: '',
          docusign_id_check: '',
          skip_landing_page: '',
          enabled: '',
          onfido_enabled: '',
          prove_enabled: '',
        };
      },
    },
    leadProviders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      biddableCostOptions: [
        { value: 0, text: 'No' },
        { value: 1, text: 'Yes' },
      ],
      productTypeOptions: [
        { value: 'lineOfCredit', text: 'Line Of Credit' },
        { value: 'installment', text: 'Installment' },
      ],
      docusignIdCheckOptions: [
        { value: true, text: 'true' },
        { value: false, text: 'false' },
      ],
      skipLandingPageOptions: [
        { value: true, text: 'true' },
        { value: false, text: 'false' },
      ],
      enabledOptions: [
        { value: true, text: 'true' },
        { value: false, text: 'false' },
      ],
      leadProviderId: '',
      id: null,
      campaignName: '',
      cost: 0.00,
      biddableCost: 0,
      productType: null,
      formProcessing: false,
      docusignIdCheck: null,
      skipLandingPage: null,
      enabled: null,
      onfidoEnabled: null,
      proveEnabled: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return this.isNewItem ? 'Add New Campaign' : 'Edit Campaign';
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    costErrors() {
      const errors = [];

      if (!this.$v.cost.$dirty) {
        return errors;
      }
      if (!this.$v.cost.required) {
        errors.push('Cost is required.');
      }
      return errors;
    },
    biddableCostErrors() {
      const errors = [];

      if (!this.$v.biddableCost.$dirty) {
        return errors;
      }
      if (!this.$v.biddableCost.required) {
        errors.push('Biddable cost is required.');
      }
      return errors;
    },
    productTypeErrors() {
      const errors = [];

      if (!this.$v.productType.$dirty) {
        return errors;
      }
      if (!this.$v.productType.required) {
        errors.push('Product type is required.');
      }
      return errors;
    },
    docusignIdCheckErrors() {
      const errors = [];

      if (!this.$v.docusignIdCheck.$dirty) {
        return errors;
      }
      if (!this.$v.docusignIdCheck.required) {
        errors.push('identity verification enabled is required.');
      }
      return errors;
    },
    skipLandingPageErrors() {
      const errors = [];

      if (!this.$v.docusignIdCheck.$dirty) {
        return errors;
      }
      if (!this.$v.docusignIdCheck.required) {
        errors.push('identity verification enabled is required.');
      }
      return errors;
    },

    onfidoEnabledErrors() {
      const errors = [];

      if (!this.$v.onfidoEnabled.$dirty) {
        return errors;
      }
      if (!this.$v.onfidoEnabled.required) {
        errors.push('Onfido enabled is required.');
      }
      return errors;
    },

    proveEnabledErrors() {
      const errors = [];

      if (!this.$v.proveEnabled.$dirty) {
        return errors;
      }
      if (!this.$v.proveEnabled.required) {
        errors.push('Prove enabled is required.');
      }
      return errors;
    },

    enabledErrors() {
      const errors = [];

      if (!this.$v.enabled.$dirty) {
        return errors;
      }
      if (!this.$v.enabled.required) {
        errors.push('Enabled is required.');
      }
      return errors;
    },
    leadProviderIdErrors() {
      const errors = [];

      if (!this.$v.leadProviderId.$dirty) {
        return errors;
      }
      if (!this.$v.leadProviderId.required) {
        errors.push('Lead provider is required.');
      }
      return errors;
    },
    campaignNameErrors() {
      const errors = [];

      if (!this.$v.campaignName.$dirty) {
        return errors;
      }
      if (!this.$v.campaignName.required) {
        errors.push('Campaign name is required.');
      }
      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.cost = this.editedItem.cost;
      this.biddableCost = this.editedItem.biddable_cost;
      this.leadProviderId = this.editedItem.lead_provider_id;
      this.campaignName = this.editedItem.campaign_name;
      this.productType = this.editedItem.product_type;
      this.docusignIdCheck = this.editedItem.docusign_id_check;
      this.skipLandingPage = this.editedItem.skip_landing_page;
      this.enabled = this.editedItem.enabled;
      this.onfidoEnabled = this.editedItem.onfido_enabled;
      this.proveEnabled = this.editedItem.prove_enabled;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;

        let request = '';

        let data = {
          cost: this.cost,
          biddableCost: this.biddableCost,
          leadProviderId: this.leadProviderId,
          campaignName: this.campaignName,
          productType: this.productType,
          docusignIdCheck: this.docusignIdCheck,
          skipLandingPage: this.skipLandingPage,
          enabled: this.enabled,
          onfidoEnabled: this.onfidoEnabled,
          proveEnabled: this.proveEnabled,
        };

        if (this.isNewItem) {
          data = {
            ...data,
          };

          request = this.$axios.post('/api/v1/campaign', data);
        } else {
          request = this.$axios.put(`/api/v1/campaign/${this.editedItem.id}`, data);
        }

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.campaign_name} has been saved.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');
    },
  },
  validations: {
    campaignName: { required },
    cost: { required },
    biddableCost: { required },
    leadProviderId: { required },
    productType: { required },
    docusignIdCheck: { required },
    skipLandingPage: { required },
    enabled: { required },
    onfidoEnabled: { required },
    proveEnabled: { required },
  },
};
</script>

<style></style>
