<template>
  <tr>
    <td>
      <v-select
        v-model="condition.lead_column"
        :items="leadColumns"
        @change="updateConditionObject(condition.lead_column)"
      />
    </td>

    <td>
      <v-select
        v-model="condition.operator"
        :items="availableOperators"
      />
    </td>

    <td>
      <component
        :is="inputComponent"
        v-model="condition.value"
        :items="availableOptions"
        v-bind="inputAttributes"
        :error-messages="rowErrorMessage"
        required
      />
    </td>
    <td class="text-right">
      <v-btn
        color="error"
        @click="$emit('remove-row')"
      >
        <v-icon> mdi-minus </v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { VTextField, VSelect } from 'vuetify/lib';

export default {
  name: 'LendingStrategyConditionRow',
  components: {
    VTextField,
    VSelect,
  },
  props: {
    leadColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    operators: {
      type: Array,
      default() {
        return [];
      },
    },
    inputDefs: {
      type: Object,
      default() {
        return {};
      },
    },
    condition: {
      type: Object,
      default() {
        return {};
      },
    },
    rowErrorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      availableOperators: [],
      availableOptions: [],
      inputComponent: 'v-text-field',
      inputAttributes: {
        type: 'text',
      },
    };
  },
  created() {
    this.updateConditionObject(this.condition.lead_column);
  },
  methods: {
    updateConditionObject(leadColumn) {
      const inputDef = this.inputDefs[leadColumn];

      this.availableOperators = [...this.operators];

      let { value } = this.condition;

      if (inputDef) {
        switch (inputDef.type) {
          case 'select':
            this.inputComponent = 'v-select';

            this.availableOptions = Object.entries(inputDef.options).map((option) => ({
              value: option[0],
              text: option[1],
            }));

            value = this.condition.value || this.availableOptions[0].value;
            break;
          default:
            this.inputComponent = 'v-text-field';
            this.inputAttributes = { ...inputDef };

            break;
        }

        if (inputDef.operators) {
          this.availableOperators = this.operators.filter((operator) => (
            inputDef.operators.includes(operator.value) ? { ...operator } : false
          ));
        }
      } else {
        this.inputComponent = 'v-text-field';
        this.inputAttributes = { type: 'text' };
        this.availableOptions = [];
      }

      this.$emit('set-condition-value', value);
    },
  },
};
</script>

<style></style>
