<template>
  <v-container fluid>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ formatPhone(item.phone_number) }}
              </v-card-title>

              <v-divider />

              <v-list dense>
                <v-list-item
                  v-for="header in headers"
                  :key="header.value"
                >
                  <v-list-item-content class="font-weight-bold">
                    {{ header.text }}:
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item[header.value] }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    headers: [
      { text: 'Phone Number', value: 'phone_number' },
      { text: 'Line Type', value: 'line_type' },
      { text: 'Carrier', value: 'carrier' },
      { text: 'Trust Score', value: 'trust_score' },
      { text: 'Trust Reason Codes', value: 'trust_reason_codes' },
      { text: 'Identity Verified', value: 'identity_verified' },
      { text: 'CIP Confidence', value: 'cip_confidence' },
      { text: 'First Name Score', value: 'first_name_score' },
      { text: 'Last Name Score', value: 'last_name_score' },
      { text: 'Name Score', value: 'name_score' },
      { text: 'Street Number Score', value: 'street_number_score' },
      { text: 'Street Verified', value: 'street_verified' },
      { text: 'City Verified', value: 'city_verified' },
      { text: 'State Verified', value: 'state_verified' },
      { text: 'Zipcode Verified', value: 'zipcode_verified' },
      { text: 'Address Score', value: 'address_score' },
      { text: 'Email Verified', value: 'email_verified' },
      { text: 'SSN Verified', value: 'ssn_verified' },
      { text: 'DOB Verified', value: 'dob_verified' },
      { text: 'DL Number Verified', value: 'dl_number_verified' },
      { text: 'DL State Verified', value: 'dl_state_verified' },
      { text: 'Identity Reason Codes', value: 'identity_reason_codes' },
      { text: 'Link Clicked', value: 'link_clicked' },
      { text: 'Phone Match', value: 'phone_match' },
      { text: 'IP Address Match', value: 'ip_address_match' },
      { text: 'VFP Verified', value: 'vfp_verified' },
      { text: 'Possession Check Failures', value: 'possession_check_failures' },
      { text: 'Last Possession Check Time', value: 'last_possession_check_time' },
      { text: 'SMS Attempts', value: 'sms_attemps' },
      { text: 'SMS Failures', value: 'sms_failures' },
      { text: 'SMS Clicks', value: 'sms_clicks' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Updated At', value: 'updated_at' },
    ],
    itemsPerPage: 3,
  }),

  methods: {
    formatPhone(phone) {
      return `(${phone.slice(1, 4)}) ${phone.slice(4, 7)}-${phone.slice(7, 11)}`;
    },
  },
};
</script>
