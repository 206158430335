<template>
  <div>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="mt-m ml-5 mt-9 float-right">
          <ticket-add-debit-card-dialog
            :ticket="ticket"
            @success="$emit('reload-payment-profiles')"
          />
          <ticket-add-checking-dialog
            :ticket="ticket"
            @success="$emit('reload-payment-profiles')"
          />
        </div>
        <div>
          <p class="mb-xxxs">
            What payment account would you like to use for this payment?
          </p>
          <v-select
            v-model="settlementOffer.paymentAccountId"
            :items="paymentsItems"
            item-value="id"
            item-text="title"
            label="Payment Accounts"
            outlined
            required
            :error-messages="errorMessages"
          />
        </div>
      </v-col>
    </v-row>
    <div class="mt-xs">
      <p class="mb-xxxs">
        <b
          v-if="isSameDayAchEnabled"
        >
          Debit cards can be used for same day payments. Bank accounts can only be used to
          apply payments the same day, if it is a single payment settlement. Otherwise Bank
          accounts can only be used to apply payments either on the next business day
          (if currently before 3pm Central Time) or on the second business day
          (if currently after 3pm Central Time).
        </b>
        <b
          v-else
        >
          Debit cards can be used for same day payments. Bank accounts can only be used to
          apply payments either on the next business day (if currently before 3pm Central Time)
          or on the second business day (if currently after 3pm Central Time).
        </b>
      </p>
      <p class="mb-xxxs">
        <b>
          When you select a payment method and payment date, the system will validate your
          input and alert you if there is an issue.
        </b>
      </p>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import TicketAddDebitCardDialog from '../Tickets/AddPaymentDialogs/TicketAddDebitCardDialog.vue';
import TicketAddCheckingDialog from '../Tickets/AddPaymentDialogs/TicketAddCheckingDialog.vue';

export default {
  name: 'PaymentMethodSelect',

  components: {
    TicketAddCheckingDialog,
    TicketAddDebitCardDialog,
  },

  props: {
    ticket: {
      type: Object,
      required: true,
    },
    settlementOffer: {
      type: Object,
      required: true,
    },
    paymentAccounts: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    paymentsItems() {
      const manualItem = {
        active: 1,
        id: -1,
        is_primary: 0,
        is_secondary: 0,
        title: 'Manual Payments',
        type: 'manual-payments',
        visible: 1,
      };
      return [
        ...this.paymentAccounts,
        manualItem,
      ];
    },
    ...mapState('settings', ['settings']),
    isSameDayAchEnabled() {
      return this.settings?.brand?.enable_same_day_ach || false;
    },
  },

  watch: {
    'settlementOffer.paymentAccountId': {
      deep: true,
      handler(value) {
        this.setIsManualPayment(value === -1);
      },
    },
  },

  methods: {
    ...mapActions('settlement', ['setIsManualPayment']),
  },
};
</script>
