import { DateTime } from 'luxon-business-days';
// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

const now = DateTime.local();
const nextMonth = now.plusBusiness({ days: 30 });

const generateCampaignCode = () => `${faker.random.numeric(4)}`;
const campaignCode = generateCampaignCode();
const addressState = faker.address.stateAbbr();

const generateStreetAddress2 = () => {
  const beRandom = faker.random.numeric(1);
  if (beRandom % 2 === 0) {
    return '';
  }
  return `Apt. ${faker.random.numeric(2)}`;
};

const initialState = {
  campaign: {
    name: faker.company.name(),
    printExpireDate: nextMonth.toFormat('yyyy-MM-dd'),
    activeDate: now.toFormat('yyyy-MM-dd'),
    mailedDate: now.toFormat('yyyy-MM-dd'),
    externalCampaignId: '',
    leadProviderId: '',
    campaignCode,
    maxLoanAmount: faker.random.numeric(4),
  },

  prospect: {
    firstName: faker.name.firstName(),
    middleInitial: faker.name.middleName().slice(1, 2),
    lastName: faker.name.lastName(),
    systemExpireDate: nextMonth.toFormat('yyyy-MM-dd'),
    ssn7: faker.random.numeric(7),
    suffix: faker.name.fullName().slice(1, 2),
    streetAddress: faker.address.streetAddress(),
    streetAddress2: generateStreetAddress2(),
    city: faker.address.city(),
    state: addressState,
    zip: faker.address.zipCode('#####'),
    offerCode: `${faker.random.numeric(4)}-${faker.random.numeric(4)}-${campaignCode}`,
    clarityKey: faker.random.numeric(17),
    programName: faker.random.numeric(10),
    householdNumber: faker.phone.number('##########'),
    clearCreditScore: faker.random.numeric(3),
    vantageV4Score: faker.random.numeric(3),
    riskScore: faker.random.numeric(3),
    prescreenFileId: faker.random.numeric(),
    numTimesCodeSubmitted: 0,
    isWalkupComplete: 0,
  },
};

const getters = {
  campaign: (state) => state.campaign,
  prospect: (state) => state.prospect,
};

const actions = {
  refreshData({ commit }) {
    commit('REFRESH_DATA');
  },
  setCampaignData({ commit }, data) {
    commit('SET_CAMPAIGN_DATA', data);
  },
  updateCampaignData({ commit }, data) {
    commit('UPDATE_CAMPAIGN_DATA', data);
  },

  setProspectData({ commit }, data) {
    commit('SET_PROSPECT_DATA', data);
  },
  updateProspectData({ commit }, data) {
    commit('UPDATE_PROSPECT_DATA', data);
  },
};

const mutations = {
  REFRESH_DATA(state) {
    const generateStreetAddressTwo = () => {
      const beRandom = faker.random.numeric(1);
      if (beRandom % 2 === 0) {
        return '';
      }
      return `Apt. ${faker.random.numeric(2)}`;
    };

    state.campaign = {
      name: faker.company.name(),
      printExpireDate: nextMonth.toFormat('yyyy-MM-dd'),
      activeDate: DateTime.local().toFormat('yyyy-MM-dd'),
      mailedDate: DateTime.local().toFormat('yyyy-MM-dd'),
      externalCampaignId: '',
      leadProviderId: '',
      campaignCode: `${faker.random.numeric(4)}`,
      maxLoanAmount: faker.random.numeric(4),
    };

    state.prospect = {
      firstName: faker.name.firstName(),
      middleInitial: faker.name.middleName().slice(1, 2),
      lastName: faker.name.lastName(),
      ssn7: faker.random.numeric(7),
      suffix: faker.name.fullName().slice(1, 2),
      streetAddress: faker.address.streetAddress(),
      streetAddress2: generateStreetAddressTwo(),
      city: faker.address.city(),
      state: faker.address.stateAbbr(),
      zip: faker.address.zipCode('#####'),
      offerCode: `${faker.random.numeric(4)}-${faker.random.numeric(4)}-${state.campaign.campaignCode}`,
      clarityKey: faker.random.numeric(17),
      programName: faker.random.numeric(10),
      householdNumber: faker.phone.number('##########'),
      clearCreditScore: faker.random.numeric(3),
      vantageV4Score: faker.random.numeric(3),
      riskScore: faker.random.numeric(3),
      prescreenFileId: faker.random.numeric(),
      numTimesCodeSubmitted: 0,
      systemExpireDate: nextMonth.toFormat('yyyy-MM-dd'),
      isWalkupComplete: 0,
    };
  },
  SET_CAMPAIGN_DATA(state, data) {
    state.campaign = data;
  },
  UPDATE_CAMPAIGN_DATA(state, data) {
    Object.keys(data).forEach((key) => {
      state.campaign[key] = data[key];
    });
  },

  SET_PROSPECT_DATA(state, data) {
    state.prospect = data;
  },
  UPDATE_PROSPECT_DATA(state, data) {
    Object.keys(data).forEach((key) => {
      state.prospect[key] = data[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
