<template>
  <div class="mb-xs">
    <p
      class="mb-xxxs mb-7"
      style="text-transform: uppercase;"
    >
      {{ fullName }} - {{ displayId }}
    </p>
    <h1 class="header-title">
      {{ title }}
    </h1>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';

export default {
  name: 'PaymentHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    fullName: {
      type: String,
      required: true,
    },
    displayId: {
      type: String,
      required: true,
    },
  },
  computed: {
    // ...mapGetters('customer', ['fullName']),
    // ...mapGetters('loan', ['displayId']),
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 48px;
  font-weight: normal;
}
</style>
