<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      class="fraud-claim"
      width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          color="secondary"
          x-large
          block
          class="fraud-claim-dialog-btn"
          :loading="loadingRecurring"
          v-on="on"
        >
          Open Settlement Options and Send Portal Login
        </v-btn>
      </template>

      <v-card :loading="loadingModel">
        <v-card-title class="text-h5 grey lighten-2">
          Open Settlement Options and Send Portal Login
        </v-card-title>

        <v-card-text
          class="pa-5"
        >
          <vue-editor
            id="macroEditor"
            v-model="macro"
          />
          <br>
          <v-textarea
            v-model="notes"
            label="Notes (Optional)"
            outlined
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="loadingModel"
            @click="processForm"
          >
            Send Email and Process Incoming Settlement Request
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
#macroEditor {
  height: 400px;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import { VueEditor } from 'vue2-editor';

export default {
  name: 'PortalLoginAndSettlementDashboard',
  components: {
    VueEditor,
  },
  props: {
    ticketId: {
      type: Number,
      required: true,
      default: null,
    },
    customer: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      loadingModel: false,
      macro: null,
      notes: '',
      loadingRecurring: false,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['activeLoan']),
  },
  async created() {
    this.macro = await this.getMacroTemplate();
    this.macro = this.macro.replace(/<br><br>/g, '<br>');
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    async getMacroTemplate() {
      let data;
      try {
        const res = await this.$axios
          .post('api/v1/support/communication/template', {
            customer: this.customer,
            action: 'portal-login-instructions-settlement',
            ticketId: this.ticketId,
            extra: {
              leadGuid: this.activeLoan?.lead_guid || '',
            },
          });
        data = res.data.template;
      } catch (error) {
        let errMsg = 'There was an error processing your request';
        if (error.response && error.response.status === 404 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
      return data;
    },
    async processForm() {
      try {
        this.loadingModel = true;

        // send the 'go to your portal to create a settlement' email
        const res = await this.$axios
          .post('api/v1/support/communication/send-message', {
            ticketId: this.ticketId,
            macro: this.macro.replace(/<p><br><\/p>/g, ''), // remove excessive empty lines from the html string
            notes: this.notes,
            loanId: this.activeLoan.id,
            tag: 'portal_login_instructions_settlement',
            formatHTML: this.containsHTML(this.macro),
          });

        // this line is being commented out so that we no longer:
        // 1. update loan status to account frozen
        // 2. suspend interest as of today
        // 3. cancel/revoke recurring autopay(s)
        // eslint-disable-next-line no-unused-vars
        // const actionsRes = await this.$axios
        //   .post(`api/v1/support/ticket/loan/${this.activeLoan.id}/
        //   settlement/incoming-email-actions`, {
        //     ticketId: this.ticketId,
        //   });

        this.loadingModel = false;
        if (res.status === 200 && res.data) {
          this.dialog = false;
          this.setNotification({
            message: res.data.message,
            color: 'green',
          });
          this.$emit('success', 'The Open Settlement Options and Send Portal Login communication macro was sent successfully.');
        }
      } catch (error) {
        let errMsg = error.response?.data?.message || 'There was an error processing your request';
        if (error.response && error.response.status === 422 && error.response.data.error !== '') {
          errMsg = error.response.data.error;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
    containsHTML(str) {
      return /<[a-z][\s\S]*>/i.test(str);
    },
  },
};
</script>
