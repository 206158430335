<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="6"
        >
          <v-select
            v-model="classificationSelected"
            :items="classifications"
            item-text="name"
            item-value="id"
            label="Select Classification"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-model="subClassificationName"
            label="Sub Classification Name"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-textarea
            v-model="subClassificationDescription"
            name="input-7-1"
            label="Sub Classification Description"
            value=""
            :hint="subClassificationDescriptionHint"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="d-flex flex-row justify-end"
          cols="12"
          sm="12"
          md="12"
        >
          <v-btn
            v-if="editingSubClassification"
            :loading="loading"
            color="warning"
            depressed
            :disabled="!ableToAdd"
            @click="editSubClassification()"
          >
            Edit
          </v-btn>

          <v-btn
            v-if="!editingSubClassification"
            :loading="loading"
            color="primary"
            depressed
            :disabled="!ableToAdd"
            @click="addNewSubClassification()"
          >
            Create
          </v-btn>

          <v-btn
            class="ml-2"
            depressed
            @click="showSubClassificationList()"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    activeSubClassification: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },

  data: () => ({
    editingSubClassification: false,
    subClassificationName: '',
    subClassificationDescription: '',
    addingSubClassification: false,
    loading: false,
    loadingClassifications: false,
    classifications: [],
    classificationSelected: null,
  }),

  computed: {
    ableToAdd() {
      return !!(this.subClassificationName.length && this.subClassificationDescription.length);
    },

    subClassificationDescriptionHint() {
      return `${256 - this.subClassificationDescription.length} characters remaining`;
    },
  },

  watch: {
    activeSubClassification(value) {
      if (value) {
        this.initValues();
      }
    },
  },

  mounted() {
    this.getClassifications();

    if (this.activeSubClassification) {
      this.initValues(this.activeSubClassification);
    }
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    getClassifications() {
      this.loadingClassifications = true;

      return this.$axios.get('api/v1/ops-tickets/classification/list').then((res) => {
        this.loadingClassifications = false;

        this.classifications = res.data ?? [];
      })
        .catch((error) => {
          this.loadingClassifications = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    clearForm() {
      this.editingSubClassification = false;
      this.subClassificationName = '';
      this.subClassificationDescription = '';
      this.addingSubClassification = false;
      this.loading = false;
    },

    initValues(subClassificationName) {
      this.editingSubClassification = true;
      this.subClassificationName = subClassificationName.name;
      this.subClassificationDescription = subClassificationName.description;
      this.classificationSelected = subClassificationName.classification_id;
    },

    showSubClassificationList() {
      this.clearForm();
      this.$emit('sub-classification-list');
    },

    editSubClassification() {
      this.loading = true;

      return this.$axios
        .patch(`api/v1/ops-tickets/sub-classification/${this.activeSubClassification.id}`, {
          classification_id: this.classificationSelected,
          name: this.subClassificationName,
          description: this.subClassificationDescription,
        })
        .then(() => {
          this.loading = false;
          this.showSubClassificationList();
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    addNewSubClassification() {
      this.loading = true;

      return this.$axios
        .post('api/v1/ops-tickets/sub-classification', {
          classification_id: this.classificationSelected,
          name: this.subClassificationName,
          description: this.subClassificationDescription,
        })
        .then(() => {
          this.loading = false;
          this.showSubClassificationList();
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },
  },
};
</script>
