<template>
  <div>
    <h1>Dashboard</h1>
    <action-required v-if="settings.mfa_required || user.require_password_reset" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ActionRequired from './ActionRequired.vue';

export default {
  components: {
    ActionRequired,
  },
  computed: {
    ...mapState('app', ['settings']),
    ...mapState('currentUser', ['user']),
    ...mapGetters('auth', ['isAdminGroup']),
  },
};
</script>
