<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    @click:outside="close()"
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">{{ modalTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <div
                  class="mb-5 title-span"
                >
                  Company Details
                </div>
                <v-text-field
                  v-model="companySelected.name"
                  label="Name"
                  type="text"
                  outlined
                  :rules="validation.nameRules"
                />

                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="companySelected.email"
                      label="Email"
                      type="text"
                      outlined
                      :rules="validation.emailRules"
                    />
                    <v-text-field
                      v-model="companySelected.website"
                      label="Website"
                      type="text"
                      outlined
                      :rules="validation.websiteRules"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="companySelected.phone"
                      label="Phone"
                      type="text"
                      outlined
                      :rules="validation.phoneRules"
                    />
                    <v-select
                      v-model="companySelected.status_loan_associated"
                      :items="statuses"
                      label="Status Associated"
                      item-value="id"
                      item-text="title"
                      outlined
                      :rules="validation.statusRules"
                    />
                  </v-col>
                </v-row>

                <div
                  class="mb-5 title-span"
                >
                  Address Details
                </div>
                <v-text-field
                  v-model="companySelected.address"
                  label="Address"
                  type="text"
                  outlined
                  :rules="validation.addressRules"
                />
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="companySelected.address2"
                      label="Address 2"
                      type="text"
                      outlined
                      :rules="validation.addressRules"
                    />
                    <v-select
                      v-model="companySelected.state"
                      :items="usStates"
                      label="States"
                      outlined
                    />
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="companySelected.city"
                      label="City"
                      type="text"
                      outlined
                      :rules="validation.cityRules"
                    />
                    <v-text-field
                      v-model="companySelected.zip"
                      label="Zip Code"
                      type="text"
                      outlined
                      :rules="validation.zipRules"
                    />
                  </v-col>
                </v-row>
                <v-spacer />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="processRequest()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions } from 'vuex';
import stateList from '@/data/states.json';

export default {
  props: {
    dialogForm: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {};
      },
    },
    statuses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      companySelected: {},
      formType: 'create',
      formProcessing: false,
      showDetails: false,
      statusSelected: 0,
      usStates: [],
      validation: {
        nameRules: [
          (value) => !!value || 'Name is required',
          (value) => (!!value && value.length <= 60) || 'Name must be less than 60 characters',
          (value) => (!!value && value.length >= 3) || 'Name must be more than 3 character',
        ],
        emailRules: [
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (value) ? pattern.test(value) || 'Invalid e-mail.' : true;
          },
        ],
        phoneRules: [
          (value) => {
            if (value && value.length <= 10) {
              return 'Value must have at less 10 characters';
            }

            if (value && value.length >= 25) {
              return 'Value must have less than 25 characters';
            }

            return true;
          },
        ],
        websiteRules: [
          (value) => {
            if (value) {
              return this.isURL(value) || 'URL is not valid';
            }

            return true;
          },
        ],
        statusRules: [
          (value) => !!value || 'Status is required',
        ],
        addressRules: [
          (value) => {
            if (value && value.length >= 100) {
              return 'Value must be less than 100 characters';
            }

            if (value && value.length <= 3) {
              return 'Value must be more than 3 characters';
            }

            return true;
          },
        ],
        cityRules: [
          (value) => {
            if (value && value.length >= 50) {
              return 'City must be less than 50 characters';
            }

            if (value && value.length <= 3) {
              return 'City must be more than 3 characters';
            }

            return true;
          },
        ],
        zipRules: [
          (value) => {
            const pattern = /^(\d{5}$)|(^\d{5}-\d{4}$)/;
            return (value) ? pattern.test(value) || 'Invalid zip code' : true;
          },
        ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.dialogForm;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    modalTitle() {
      return this.formType === 'create' ? 'Add New Debt Company'
        : `Edit ${this.companySelected.name}`;
    },
  },
  watch: {
    editedItem(value) {
      this.companySelected = {
        ...value,
      };
    },
    dialogForm() {
      this.formType = Object.keys(this.companySelected).length ? 'edit' : 'create';
    },
  },
  created() {
    this.usStates = stateList.map((state) => state.id);

    this.usStates.unshift({
      value: null,
      text: 'All States',
    });
  },
  mounted() {
    this.initFormType();
  },
  methods: {
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    },
    async handleRequest(data) {
      if (this.formType === 'create') {
        return this.$axios.post('/api/v1/debt-company', data);
      }

      return this.$axios.patch(`/api/v1/debt-company/${this.companySelected.id}`, data);
    },
    async processRequest() {
      if (this.$refs.form.validate()) {
        this.formProcessing = true;

        const data = {
          ...this.companySelected,
        };

        try {
          await this.handleRequest(data);

          this.$emit('item-saved');

          this.setNotification({
            message: (this.formType === 'add')
              ? `New Company added ${this.companySelected.name}.`
              : `Edited ${this.companySelected.name}. company successfully`,
            color: 'green',
          });
        } catch (err) {
          this.setNotification({
            message: err,
            color: 'red',
          });
        }

        this.formProcessing = false;
        this.close();
      }
    },
    close() {
      this.$emit('close-modal');
      this.$refs.form.reset();
    },
    initFormType() {
      if (this.formType === 'edit') {
        this.companySelected = {
          ...this.editedItem,
        };
      }
    },
    ...mapActions('notification', ['setNotification']),
  },
};
</script>
<style scoped>
  .title-span {
    border-bottom: 1px solid #5b5b60;
  }
</style>
