<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-data-table
            dense
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            :footer-props.sync="footerProps"
            @update:options="updateTable()"
          >
            <template v-slot:top>
              <v-card-title>
                <v-toolbar flat>
                  <v-toolbar-title>Communication History</v-toolbar-title>
                  <v-spacer />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="btn-bg-deep-blue"
                        :href="dripUrl"
                        target="_blank"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-water
                        </v-icon>
                        Drip History
                      </v-btn>
                    </template>
                    <span>{{ tooltipText }}</span>
                  </v-tooltip>
                </v-toolbar>
              </v-card-title>
            </template>

            <template v-slot:item.created="{ item }">
              {{ createDate(item.created) }}
            </template>

            <template v-slot:expanded-item="{ item }">
              <td colspan="2" />
              <td colspan="1">
                <div class="pt-4">
                  <p>Email: {{ JSON.parse(item.note_data).email }} </p>
                  <p v-if="JSON.parse(item.note_data).subject">
                    Subject: {{ JSON.parse(item.note_data).subject }}
                  </p>
                  <p v-if="JSON.parse(item.note_data).body">
                    {{ JSON.parse(item.note_data).body }}
                  </p>
                </div>
              </td>
              <td colspan="1" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  name: 'LoanProCustomerProfile',
  mixins: [
    dataTableMixin,
  ],
  props: {
    customer: {
      type: Object,
      required: true,
    },
    customerLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      headers: [
        { text: 'Date/Time', value: 'created', width: 180 },
        { text: 'Note/Email', value: 'note_title' },
        { text: 'Author', value: 'create_user_name', width: 150 },
      ],
      dripUrl: null,
    };
  },
  computed: {
    tooltipText() {
      return this.dripUrl
        ? 'Click to open this customers drip emails in a new window.'
        : 'This customer was not found in drip.';
    },
  },
  watch: {
    customerLoading() {
      if (!this.customerLoading && !this.dripUrl) {
        this.fetchDripUrl();
      }
    },
  },
  created() {
    if (this.customer.email && !this.dripUrl) {
      this.fetchDripUrl();
    }
  },
  methods: {
    async updateTable() {
      this.loading = true;

      const response = await this.$axios
        .get(`api/v1/loanpro-customer/${this.$route.params.customer_id}/emails`, {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        });

      this.totalItems = response.data.total;
      this.items = response.data.data;
      this.loading = false;
    },
    async fetchDripUrl() {
      const response = await this.$axios.get(`api/v1/drip/get-url?email=${encodeURIComponent(this.customer.email)}`);
      if (response.status === 200 && response.data.url) {
        this.dripUrl = response.data.url;
      }
    },
    createDate(date) {
      return DateTime.fromSQL(date).toLocaleString(DateTime.DATETIME_MED);
    },
  },
};
</script>
