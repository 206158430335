<template>
  <div>
    <v-alert
      v-if="isValidOffer"
      border="top"
      color="green lighten-2"
      dark
    >
      This offer still valid.
    </v-alert>
    <v-alert
      v-else
      border="top"
      color="red ligthen-2"
      dark
    >
      This offer is not valid. {{ invalidReason }}
    </v-alert>
  </div>
</template>
<script>
export default {
  name: 'IsValidOffer',

  props: {
    prospect: {
      required: true,
      type: Object,
    },
  },

  computed: {
    isValidOffer() {
      if (this.prospect.isWalkupComplete) {
        return false;
      }

      return !(this.prospect && this.prospect.campaign && this.prospect.isExpired);
    },

    invalidReason() {
      if (!this.isValidOffer) {
        if (this.prospect.isWalkupComplete) {
          return 'This offer was already completed';
        }

        if (this.prospect && this.prospect.campaign && this.prospect.isExpired) {
          return 'This offer already expired.';
        }
      }

      return '';
    },
  },

};
</script>

<style scoped>

</style>
