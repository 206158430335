<template>
  <v-dialog
    max-width="500"
    :value="show"
    persistent
    @input="close"
  >
    <v-card>
      <v-card-title class="headline">
        Lead Search
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="mb-0"
          >
            <v-autocomplete
              v-model="selectedInputs"
              :items="inputs"
              item-value="name"
              item-text="label"
              label="Pick your search fields"
              multiple
              outlined
              chips
              deletable-chips
              small-chips
            />
          </v-col>

          <v-col
            v-for="(input, i) in activeFormInputs"
            :key="i"
            cols="12"
          >
            <v-select
              v-if="input.items"
              v-model.trim="search[input.name]"
              :label="input.label"
              :items="input.items"
              filled
              dense
              hide-details
            />
            <v-text-field
              v-else
              v-model.trim="search[input.name]"
              :label="input.label"
              dense
              filled
              clearable
              required
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mt-12" />
      <v-card-actions>
        <v-btn
          color="orange darken-4"
          text
          @click="close"
        >
          Cancel
        </v-btn>

        <v-spacer />

        <v-btn
          color="primary"
          text
          :disabled="disableSubmit"
          @click="$emit('search', searchData)"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LeadSearchForm',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    inputs: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedInputs: this.selected,
      search: {},
    };
  },

  computed: {
    activeFormInputs() {
      return this.inputs.filter((input) => this.selectedInputs.includes(input.name));
    },

    searchData() {
      const searchData = {};

      // loop and take only field which has a value
      Object.keys(this.search).forEach((key) => {
        if (this.search[key]) {
          searchData[key] = this.search[key];
        }
      });

      return Object.keys(searchData).length > 0 ? searchData : null;
    },

    disableSubmit() {
      return this.searchData === null || this.selectedInputs.length === 0;
    },
  },

  methods: {
    close() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>
