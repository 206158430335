<template>
  <div
    v-if="!user.mfa || user.require_password_reset"
  >
    <h3>{{ user.first_name }}, please fix the following items:</h3>
    <ul>
      <li v-if="!user.mfa && settings.mfa_required">
        Two Factor Authentication is currently NOT enabled.
      </li>
      <li v-if="user.require_password_reset">
        Reset your login password.
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('app', ['settings']),
    ...mapState('currentUser', ['user']),
  },
};
</script>
