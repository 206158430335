<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Communication Macros
        </v-card-title>
        <v-card-text
          justify="center"
          class="my-5 py-5"
        >
          <v-row
            class="my-5"
            justify="center"
          >
            <v-col
              cols="12"
            >
              <v-switch
                v-model="selectMacro"
                class="mb-2"
                :label="selectMacro
                  ? 'Select from a List of All Communication Macros'
                  : 'Search by Communication Macro Title'"
              />
              <v-text-field
                v-if="!selectMacro"
                v-model="searchMacro"
                class="search"
                outlined
                clearable
                label="Search for a Communication Macro"
                prepend-inner-icon="mdi-magnify"
              />
              <v-select
                v-if="selectMacro"
                v-model="searchMacro"
                outlined
                clearable
                label="Select a Communication Macro"
                prepend-inner-icon="mdi-magnify"
                :items="macros"
                item-text="title"
                item-value="title"
              />
            </v-col>
            <v-col
              v-for="(macro, index) in filteredMacros"
              :key="'macro-'+index"
              :cols="macro.cols || 12"
            >
              <template
                v-if="macro.published
                  && ((loanType === 'installment' && macro.installment)
                    || (loanType === 'creditLimit' && macro.creditline))"
              >
                <reusable-communication-by-email-template
                  v-if="macro.component === 'reusable' && macro.published"
                  :customer="ticket.lpCustomer"
                  :ticket-id="ticket.zendesk.id * 1"
                  :loan-id="ticket.active_loan.id"
                  :title="macro.title"
                  :action="macro.action"
                  :tag="macro.tag"
                  :extra="extra || {}"
                  @success="handleSuccess($event)"
                />
                <component
                  :is="macro.component"
                  v-else-if="macro.published"
                  :ticket-id="ticket.zendesk.id * 1"
                  :customer="ticket.lpCustomer"
                  @success="handleSuccess($event)"
                />
              </template>
            </v-col>
            <v-col
              v-if="!showAllMacros && filteredMacros.length >= 5"
              cols="12"
            >
              <v-btn
                x-large
                block
                color="primary"
                @click="showAllMacros = true"
              >
                Show All Macros
                <v-icon
                  class="ml-4"
                >
                  mdi-arrow-expand-all
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <ticket-successful-action
      :ticket-successful-action-dialog="ticketSuccessfulActionDialog"
      :ticket-id="$route.params.id"
      :title="ticketSuccessfulActionTitle"
      @closed="ticketSuccessfulActionDialog = false"
    />
  </v-row>
</template>

<style>
.fraud-claim-dialog-btn {
  padding: 3rem 3rem !important;
}
</style>

<script>
import { orderBy } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { DateTime } from 'luxon';
import ReusableCommunicationByEmailTemplate from './ReusableCommunicationByEmailTemplate.vue';
import TicketSuccessfulAction from '../TicketSuccessfulAction.vue';
import PortalLoginAndSettlementDashboard from './PortalLoginAndSettlementDashboard.vue';

export default {
  name: 'CommunicationMacros',
  components: {
    ReusableCommunicationByEmailTemplate,
    TicketSuccessfulAction,
    PortalLoginAndSettlementDashboard,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    showMacro: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      itemsToReview: {},
      ticketSuccessfulActionDialog: false,
      ticketSuccessfulActionTitle: '',
      showAllMacros: false,
      searchMacro: '',
      selectMacro: true,
      recurringAutopayStatus: false,
      macrosList: [],
    };
  },
  computed: {
    ...mapState('loanProLoan', ['activeLoan', 'nextScheduledPayment', 'paymentSchedules']),
    nextNextPayment() {
      // eslint-disable-next-line max-len
      const nextPayment = this.formatDateNoTime(this.activeLoan?.current_status?.next_payment_date || null);
      if (nextPayment) {
        // eslint-disable-next-line max-len
        const index = this.paymentSchedules.findIndex((payment) => this.formatDateNoTime(payment.date) === nextPayment);
        if (index > -1) {
          return this.paymentSchedules[index + 1]?.date || null;
        }
      }
      return null;
    },
    loanType() {
      const type = this.ticket?.active_loan?.loan_setup?.loan_type || '';
      if (type !== '') {
        return type.split('.')[2];
      }
      return type;
    },
    extra() {
      return {
        applyDate: this.formatDateNoTime(this.activeLoan?.current_status?.next_payment_date),
        nextPaymentAmount: this.activeLoan?.current_status?.next_payment_amount || 0,
        // eslint-disable-next-line max-len
        nextPaymentDate: this.nextNextPayment ? this.formatDateNoTime(this.nextNextPayment) : null,
        dailyChange: this.activeLoan?.current_status?.perdiem || null,
        state: this.ticket?.lpCustomer?.state || null,
        payOffBalance: this.activeLoan?.current_status?.payoff || null,
        debtCompany: this.activeLoan?.debt_company || null,
      };
    },
    filteredMacros() {
      if (this.searchMacro) {
        // eslint-disable-next-line max-len
        const result = this.macros.filter((i) => i.title.toLowerCase().includes(this.searchMacro.toLowerCase())) || [];
        if (result.length > 5 && !this.showAllMacros) {
          return result.slice(0, 5);
        }
        return result;
      }

      if (this.showAllMacros) {
        return this.macros;
      }

      return this.macros.slice(0, 5);
    },
    isLoanPaidOff() {
      return (this.activeLoan?.current_status?.loan_status_id === 3
        && (this.activeLoan?.current_status?.loan_sub_status_id === 175
        || this.activeLoan?.current_status?.loan_sub_status_id === 17));
    },
    validMacroList() {
      let macros = this.macrosList;
      if ((this.activeLoan?.autopays || []).some((autopay) => (autopay.type === 'autopay.type.recurring' && autopay.status === 'autopay.status.pending'))) {
        macros = macros.filter((macro) => (macro.action !== 'miss-payment' && macro.action !== 'cancel-payment-no-recurring-autopay'));
      }
      if (!this.isLoanPaidOff) {
        macros = macros.filter((macro) => macro.action !== 'how-to-reapply');
      }

      return macros;
    },
    macros() {
      if (!this.activeLoan?.current_status?.perdiem && !this.ticket?.lpCustomer?.state) {
        return [];
      }

      return orderBy(this.validMacroList, ['title'], ['asc']);
    },
  },
  watch: {
    async activeLoan() {
      await this.getLoanRecurringAutopayStatus();
    },
    async ticket() {
      this.macrosList = await this.getPusblishedMacrosList();
      await this.getActiveLead();
    },
  },
  async created() {
    this.macrosList = await this.getPusblishedMacrosList();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    handleSuccess($event) {
      this.ticketSuccessfulActionTitle = $event;
      this.ticketSuccessfulActionDialog = true;
    },
    formatDateNoTime(date) {
      return DateTime.fromSQL(date).toFormat('MM/dd/yyyy');
    },
    async getActiveLead() {
      const res = await this.$axios
        .post('api/v1/support/communication/active-loan', {
          email: this.ticket.lpCustomer.email,
          ticketId: this.ticket.zendesk.id,
        });
      return res.data;
    },
    async getLoanRecurringAutopayStatus() {
      try {
        const res = await this.$axios
          .post('api/v1/support/communication/recurring-autopay', {
            loanId: this.activeLoan.id,
          });
        this.recurringAutopayStatus = res.data?.recurringAutopay;
      } catch (error) {
        let errMsg = 'There was an error processing your request';
        if (error.response && error.response.status === 404 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingRecurring = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
    async getPusblishedMacrosList() {
      const res = await this.$axios.get('api/v1/support/communication/macros/list');
      return res.data;
    },
  },
};
</script>
