<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-model="statusName"
            label="Status Name"
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="6"
        >
          <v-select
            v-model="orderSelected"
            :items="orderList"
            item-text="order"
            item-value="order"
            label="Select Order"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-textarea
            v-model="statusDescription"
            name="input-7-1"
            label="Status Description"
            value=""
            :hint="statusDescriptionHint"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="d-flex flex-row justify-end"
          cols="12"
          sm="12"
          md="12"
        >
          <v-btn
            v-if="editingStatus"
            :loading="loading"
            color="warning"
            depressed
            :disabled="!ableToAdd"
            @click="editStatus()"
          >
            Edit
          </v-btn>

          <v-btn
            v-if="!editingStatus"
            :loading="loading"
            color="primary"
            depressed
            :disabled="!ableToAdd"
            @click="addNewStatus()"
          >
            Create
          </v-btn>

          <v-btn
            class="ml-2"
            depressed
            @click="showStatusList()"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    activeStatus: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },

  data: () => ({
    editingStatus: false,
    statusName: '',
    statusDescription: '',
    orderSelected: 0,
    addingStatus: false,
    loading: false,
    orderList: [],
  }),

  computed: {
    ableToAdd() {
      return !!(this.statusName.length && this.statusDescription.length);
    },

    statusDescriptionHint() {
      return `${256 - this.statusDescription.length} characters remaining`;
    },
  },

  watch: {
    activeStatus(value) {
      if (value) {
        this.initValues();
      }
    },
  },

  mounted() {
    if (this.activeStatus) {
      this.initValues(this.activeStatus);
    }
    this.getOrderList();
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    getOrderList() {
      this.$axios
        .get('api/v1/ops-tickets/status/order-list', {
          params: {
            isEdit: this.editingStatus ? 1 : 0,
          },
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.orderList = response.data;
          }
        })
        .catch((error) => {
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    clearForm() {
      this.editingStatus = false;
      this.statusName = '';
      this.statusDescription = '';
      this.addingStatus = false;
      this.loading = false;
    },

    initValues(status) {
      this.editingStatus = true;
      this.statusName = status.name;
      this.statusDescription = status.description;
      this.orderSelected = status.order;
    },

    showStatusList() {
      this.$emit('status-list');
    },

    editStatus() {
      this.loading = true;

      return this.$axios
        .patch(`api/v1/ops-tickets/status/${this.activeStatus.id}`, {
          name: this.statusName,
          order: this.orderSelected,
          description: this.statusDescription,
        })
        .then(() => {
          this.loading = false;
          this.showStatusList();
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    addNewStatus() {
      this.loading = true;

      return this.$axios
        .post('api/v1/ops-tickets/status', {
          name: this.statusName,
          description: this.statusDescription,
          order: this.orderSelected,
        })
        .then(() => {
          this.loading = false;
          this.showStatusList();
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },
  },
};
</script>

<style scoped></style>
