<template>
  <v-dialog
    v-model="show"
    scrollable
    @click:outside="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>
        Ticket {{ ticket.id }} Comments
      </v-card-title>
      <v-card-text>
        <p class="text-h6 mb-1">
          Ticket Description:
        </p>
        <p>{{ ticket.description }}</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Comment ID</th>
              <th>Date/Time</th>
              <th>Body</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(comment, index) in comments"
              :key="`comment_${index}`"
            >
              <td>{{ comment.id }}</td>
              <td>{{ createDate(comment.created_at) }}</td>
              <td><pre>{{ comment.body }}</pre></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="loading"
          @click="close()"
        >
          Close
        </v-btn>

        <v-btn
          color="primary"
          :disabled="loading"
          @click="goToActions()"
        >
          Choose Ticket
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'LoanProCustomerZendeskTicketCommentsModal',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
      default() {
        return {
          id: null,
          description: '',
        };
      },
    },
    comments: {
      type: Array,
      default() {
        return [
          {
            id: null,
          },
        ];
      },
    },
    lpCustomerId: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    comments() {
      this.loading = this.comments.length === 0;
    },
  },

  methods: {

    createDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },

    goToActions() {
      this.$router.push({ name: 'loanpro-customer-kbot', params: { customer_id: this.lpCustomerId, zendesk_ticket: this.ticket } });
    },

    close() {
      this.$emit('close-modal');
    },
  },
};

</script>

<style>

</style>
