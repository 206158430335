<template>
  <div class="p-s">
    <v-row>
      <v-col
        cols="12"
      >
        <v-btn
          class="float-right mt-n10"
          @click="$emit('back-offer-decision')"
        >
          back
        </v-btn>
      </v-col>
    </v-row>
    <h2 class="mb-4">
      Confirm Your Settlement
    </h2>
    <p>
      <b>Settlement Type:</b> {{ settlementOffer.offerType }}
    </p>
    <p>
      <b>Settlement Description:</b> {{ settlementOffer.description }}
    </p>
    <p>
      To finalize your offer, please select your payment method and click
      the "Confirm" button below.
    </p>

    <OfferSummary
      :offer="$props.settlementOffer"
      show-title
      class="settlement-option p-s pa-5 mb-5"
    />

    <div
      class="mb-4"
    >
      <p v-if="!isManualPayment">
        YOU MUST E-MAIL BOTH THE NEW PAYMENT SCHEDULE AND THE ELECTRONIC PAYMENT
        DISCLOSURES TO THE CUSTOMER BEFORE CHECKING THE ACH CONSENT BOX BELOW.
      </p>
      <div class="row">
        <div class="col-6">
          <PaymentScheduleModal
            :settlement-offer="settlementOffer"
          />
        </div>
        <div class="col-6">
          <DisclosureModal
            :forced-disabled="isManualPayment"
          />
        </div>
      </div>
    </div>

    <form
      novalidate
      class="p-xs"
      @submit.prevent="onConfirmSettlement"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <!-- Payment Payment Method -->
            <div class="mt-m mb-n8">
              <v-select
                v-model="settlementOffer.paymentAccountId"
                :items="paymentMethods"
                item-value="id"
                item-text="title"
                label="Payment Method"
                outlined
                required
                readonly
              />
            </div>
            <!-- ./ Payment Payment Method -->
          </v-col>
        </v-row>
      </v-card-text>

      <!-- achConsent -->
      <v-container
        v-if="!isManualPayment"
        fluid
      >
        <v-checkbox
          v-model="formData.achConsent"
          class="mt-n7"
          :disabled="consentCheckDisabled"
        >
          <template v-slot:label>
            <div
              class="mt-13"
            >
              <p>
                Agents: Please read the following to the customer and gain verbal authorization
                before proceeding.
              </p>
              <p>
                "Please acknowledge you have received, read, and consent to the ACH authorization."
              </p>
            </div>
          </template>
        </v-checkbox>
      </v-container>
      <!-- ./ achConsent -->

      <v-card-actions
        class="justify-end"
        width="100%"
      >
        <v-btn
          v-if="!isLoading"
          button-full
          size="large"
          color="settlement"
          :disabled="disabledConfirm"
          @click="onConfirmSettlement"
        >
          CONFIRM SETTLEMENT
        </v-btn>
        <Loader v-else />
      </v-card-actions>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import LpLoan from '@/services/LpLoanService';
import Loader from '@/components/ui/Loader.vue';
import OfferSummary from './OfferSummary.vue';
import DisclosureModal from './DisclosureModal.vue';
import PaymentScheduleModal from './PaymentScheduleModal.vue';

export default {
  name: 'ConfirmSettlement',

  components: {
    PaymentScheduleModal,
    Loader,
    OfferSummary,
    DisclosureModal,
  },

  props: {
    ticket: {
      type: Object,
      required: true,
    },
    settlementOffer: {
      type: Object,
      required: true,
    },
    loanSettingsId: {
      type: Number,
      required: true,
    },
    todayPayoffBalance: {
      type: Number,
      required: true,
    },
    customerId: {
      type: Number,
      required: true,
    },
    loanId: {
      type: Number,
      required: true,
    },
    paymentAccounts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        paymentAccountId: null,
        achConsent: null,
      },
      submitted: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapState('app', ['instanceId']),
    ...mapState('settlement',
      [
        'numberOfPayments',
        'numberOfFullPayments',
        'fullPaymentAmount',
        'lastPaymentAmount',
        'paymentSchedule',
        'paymentSchedulesSent',
        'disclosuresSent',
        'isManualPayment'
      ]),

    consentCheckDisabled() {
      return !this.paymentSchedulesSent || !this.disclosuresSent;
    },

    isAchPayment() {
      return LpLoan.isBankAccount(this.paymentAccounts, this.formData.paymentAccountId);
    },

    paymentAccount() {
      return this.settlementOffer.paymentAccountId;
    },

    disabledConfirm() {
      if (this.isManualPayment) {
        return !this.paymentSchedulesSent;
      }

      return (!this.paymentSchedulesSent || !this.disclosuresSent || !this.formData.achConsent);
    },

    paymentMethods() {
      const manualItem = {
        active: 1,
        id: -1,
        is_primary: 0,
        is_secondary: 0,
        title: 'Manual Payments',
        type: 'manual-payments',
        visible: 1,
      };

      return [
        ...this.paymentAccounts,
        manualItem,
      ];
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('ticket', ['getTicket']),

    handleSuccess($event) {
      this.ticketSuccessfulActionId = $event;
      this.ticketSuccessfulActionDialog = true;
    },
    onConfirmSettlement() {
      if (this.isManualPayment) {
        this.confirmManualSettlement();
      } else {
        this.confirmAutopaySettlement();
      }
    },

    /**
     * Confirm manual settlement offer.
     * @returns {Promise<void>}
     */
    async confirmManualSettlement() {
      this.formData.paymentAccountId = -1;
      this.submitted = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        const offerData = {
          ...this.settlementOffer,
          ...this.formData,
          isAchPayment: false,
        };

        const result = await LpLoan.createManualSettlement({
          ...LpLoan.settlementConfig(
            this.loanId,
            this.loanSettingsId,
            this.todayPayoffBalance,
          ),
          ...offerData,
          loanId: this.loanId,
          paymentAccountId: this.formData.paymentAccountId,
          loanSettingsId: this.loanSettingsId,
          ticketId: this.$route.params.id,
          scheduleData: JSON.stringify(this.paymentSchedule),
        });

        if (result.status === 200) {
          await this.getTicket(this.$route.params.id);
          this.$emit('offer-confirmed', offerData);
        } else {
          const errMsg = (result.response.data.exception && result.response.data.message)
            ? result.response.data.message
            : 'Something went wrong, unable to create settlement autopay. Please contact your admin.';

          await this.setNotification({
            message: errMsg,
            color: 'red',
          });

          this.isLoading = false;
        }
      }
    },

    /**
     * Confirm autopay settlement offer.
     * @returns {Promise<void>}
     */
    async confirmAutopaySettlement() {
      this.formData.paymentAccountId = this.settlementOffer.paymentAccountId;
      this.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;

        const offerData = {
          ...this.settlementOffer,
          ...this.formData,
          isAchPayment: this.isAchPayment,
        };

        const result = await LpLoan.createSettlementAutopay(
          {
            ...LpLoan.settlementConfig(
              this.loanId,
              this.loanSettingsId,
              this.todayPayoffBalance,
            ),
            ...offerData,
            loanId: this.loanId,
            paymentAccountId: this.formData.paymentAccountId,
            loanSettingsId: this.loanSettingsId,
            ticketId: this.$route.params.id,
            scheduleData: JSON.stringify(this.paymentSchedule),
          },
        );

        if (result.status === 200) {
          await this.getTicket(this.$route.params.id);
          this.$emit('offer-confirmed', offerData);
        } else {
          const errMsg = (result.response.data.exception && result.response.data.message)
            ? result.response.data.message
            : 'Something went wrong, unable to create settlement autopay. Please contact your admin.';

          await this.setNotification({
            message: errMsg,
            color: 'red',
          });

          this.isLoading = false;
        }
      }
    },
  },

  validations: {
    formData: {
      paymentAccountId: {
        required,
      },
      achConsent: {
        checked: (v, vm) => {
          if (vm.paymentAccountId === -1) {
            return true;
          }

          if (vm.paymentAccountId !== -1 && vm.paymentAccountId !== null) {
            return v === true;
          }

          return false;
        },
      },
    },
  },
};
</script>

<style></style>
