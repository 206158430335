<template>
  <div>
    <h4>{{ sectionTitle }}</h4>
    <ProspectInfo
      :name="'Campaign Name:'"
      :value="campaign.name"
    />

    <ProspectInfo
      :name="'Print Expire Date:'"
      :value="campaign.printExpiredDate"
    />

    <ProspectInfo
      :name="'Active Date:'"
      :value="campaign.activeDate"
    />

    <ProspectInfo
      :name="'Mailed Date:'"
      :value="campaign.mailedDate"
    />
  </div>
</template>

<script>
import ProspectInfo from './ProspectInfo.vue';

export default {
  name: 'CampaignInfo',

  components: {
    ProspectInfo,
  },

  props: {
    sectionTitle: {
      required: false,
      default: '',
      type: String,
    },

    campaign: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>

</style>
