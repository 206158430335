<template>
  <div>
    <v-tabs>
      <template v-for="(item, index) in tabs">
        <v-tab
          v-if="hasPermission(item.permission)"
          :key="index"
          :to="{name: item.route}"
          :disabled="item.name == 'KBOT' && !$route.params.zendesk_ticket"
        >
          {{ item.name }}
        </v-tab>
      </template>
    </v-tabs>
    <div class="d-flex justify-lg-space-between align-center">
      <v-card-title class="headline pb-0">
        {{ `${customer.first_name || ''} ${customer.last_name || ''}` }}
      </v-card-title>
      <v-btn
        class="mt-3 btn-bg-deep-blue"
        :to="{name: 'loanpro-customer-list'}"
      >
        Return to customers
      </v-btn>
    </div>
    <router-view
      :customer="customer"
      :customer-loading="loading"
    />
  </div>
</template>

<script>

export default {
  data: () => ({
    tabs: [
      { name: 'Profile', route: 'loanpro-customer-profile', permission: 'view loan-pro-customers::profile' },
      { name: 'Emails', route: 'loanpro-customer-emails', permission: 'view loan-pro-customers::emails' },
      { name: 'Statements', route: 'loanpro-customer-statements', permission: 'view loan-pro-customers::statements' },
      { name: 'Zendesk', route: 'loanpro-customer-zendesk', permission: 'view loan-pro-customers::zendesk' },
      { name: 'Action Log', route: 'loanpro-customer-actions', permission: 'view loan-pro-customers::profile' },
      { name: 'KBOT', route: 'loanpro-customer-kbot', permission: 'view loan-pro-customers::zendesk' },
      { name: 'FullStory', route: 'loanpro-customer-fullstory', permission: 'view loan-pro-customers::zendesk' },
    ],
    customer: {
      id: null,
      status: null,
      email: null,
    },
    loading: false,
  }),
  created() {
    this.getCustomer();
  },
  methods: {
    async getCustomer() {
      this.loading = true;
      const customer = await this.$axios.get(`api/v1/loanpro-customer/${this.$route.params.customer_id}`);
      this.customer = customer.data;
      this.loading = false;
    },
  },
};
</script>
