<template>
  <v-dialog
    v-model="show"
    max-width="1200px"
    scrollable
    persistent
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="name"
                  required
                  label="Name"
                  :error-messages="nameErrors"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="description"
                  required
                  label="Description"
                  :error-messages="descriptionErrors"
                  @input="$v.description.$touch()"
                  @blur="$v.description.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="scoringModelId"
                  :items="scoringModels"
                  label="Scoring Model"
                  required
                  :error-messages="scoringModelIdErrors"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="productAssignmentModelId"
                  :items="productAssignmentModels"
                  label="Product Assignment Models"
                  :error-messages="productAssignmentModelIdErrors"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Lead Column</th>
                      <th>Comparison Operators</th>
                      <th>Values</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <LendingStrategyConditionRow
                      v-for="(condition, index) in conditions"
                      :key="`condition_${index}`"
                      :lead-columns="leadColumns"
                      :operators="operators"
                      :condition="condition"
                      :input-defs="inputDefs"
                      :row-error-message="getRowErrorMessages(index)"
                      @remove-row="removeRow(index)"
                      @set-condition-value="setConditionValue($event, index)"
                    />
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colspan="4"
                        class="text-right"
                      >
                        <v-btn
                          color="primary"
                          @click="addRow()"
                        >
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tfoot>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';

import LendingStrategyConditionRow from '@/views/LendingStrategies/LendingStrategyConditionRow.vue';

export default {
  name: 'LendingStrategyFormModal',
  components: {
    LendingStrategyConditionRow,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          name: '',
          description: '',
          scoring_mo_id: null,
          conditions: [],
          productAssignmentModelId: null,
        };
      },
    },
    scoringModels: {
      type: Array,
      default() {
        return [];
      },
    },
    productAssignmentModels: {
      type: Array,
      default() {
        return [];
      },
    },
    leadColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    operators: {
      type: Array,
      default() {
        return [];
      },
    },
    inputDefs: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      id: null,
      name: '',
      description: '',
      scoringModelId: null,
      conditions: [{
        lead_column: '',
        operator: '==',
        value: '',
      }],
      productAssignmentModelId: null,
      formProcessing: false,
      rowErrorMessages: {},
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      const newOrUpdate = this.isNewItem ? 'New' : 'Update';
      return `${newOrUpdate} Lending Strategy`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Name is required.');
      }
      if (!this.$v.name.maxLength) {
        errors.push(`Name must not exceed ${this.$v.name.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.description.$dirty) {
        return errors;
      }
      if (!this.$v.description.required) {
        errors.push('Description is required.');
      }
      if (!this.$v.description.maxLength) {
        errors.push(`Description must not exceed ${this.$v.description.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    scoringModelIdErrors() {
      const errors = [];

      if (!this.$v.scoringModelId.$dirty) {
        return errors;
      }
      if (!this.$v.scoringModelId.required) {
        errors.push('Scoring Model is required.');
      }
      return errors;
    },
    productAssignmentModelIdErrors() {
      const errors = [];

      if (!this.$v.productAssignmentModelId.$dirty) {
        return errors;
      }
      if (!this.$v.productAssignmentModelId.required) {
        errors.push('Product Assignment Model is required.');
      }
      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.name = this.editedItem.name;
      this.description = this.editedItem.description;
      this.scoringModelId = this.editedItem.scoring_mo_id;

      const conditions = this.editedItem.conditions ? JSON.parse(this.editedItem.conditions) : [];

      conditions.forEach((element, index) => {
        this.rowErrorMessages[index] = { value: null };
      });

      this.conditions = conditions;

      this.productAssignmentModelId = this.editedItem.prod_asgmt_id;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';

        const data = {
          name: this.name,
          description: this.description,
          scoring_mo_id: this.scoringModelId,
          prod_asgmt_id: this.productAssignmentModelId,
          conditions: this.conditions,
        };

        if (this.isNewItem) {
          request = this.$axios.post('/api/v1/lending-strategy', data);
        } else {
          request = this.$axios.put(`/api/v1/lending-strategy/${this.editedItem.id}`, data);
        }

        request
          .then(() => {
            this.$emit('item-saved');

            this.setNotification({
              message: 'Lending strategy has been saved.',
              color: 'green',
            });

            this.close();
          })
          .catch((error) => {
            let errorMessage = 'There was an error processing your request.';

            if (error.response && error.response.status === 422) {
              const errorData = error.response.data.errors;

              if (errorData) {
                errorMessage = 'Please fix the errors';

                const errorKeys = Object.keys(errorData);

                errorKeys.forEach((key) => {
                  const keyParts = key.split('.');

                  if (key === 'isLocked') {
                    // eslint-disable-next-line prefer-destructuring
                    errorMessage = errorData[key][0];
                  }

                  if (keyParts[0] === 'conditions') {
                    if (this.rowErrorMessages[keyParts[1]]) {
                      this.rowErrorMessages[keyParts[1]][keyParts[2]] = errorData[key].pop();
                    } else {
                      this.rowErrorMessages[keyParts[1]] = {
                        [keyParts[2]]: errorData[key].pop(),
                      };
                    }
                  }
                });
              }
            } else {
              this.close();
            }

            this.setNotification({
              message: errorMessage,
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');
    },
    addRow() {
      this.conditions.push({
        lead_column: 'accountholder',
        operator: '==',
        value: '',
      });
    },
    removeRow(index) {
      this.conditions.splice(index, 1);
    },
    setConditionValue(event, index) {
      this.conditions[index].value = event;
    },
    getRowErrorMessages(index) {
      if (this.rowErrorMessages[index]) {
        return this.rowErrorMessages[index].value;
      }

      return '';
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(80),
    },
    description: {
      required,
      maxLength: maxLength(100),
    },
    scoringModelId: {
      required,
      maxLength: maxLength(100),
    },
    productAssignmentModelId: {
      required,
      maxLength: maxLength(100),
    },
  },
};
</script>

<style></style>
