<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <v-card-title> Statement Logs Report </v-card-title>
      </div>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="fromDate"
                required
                label="From Date"
                type="date"
                :error-messages="fromDateErrors"
                @input="fromDateOnInputOrBlur"
                @blur="fromDateOnInputOrBlur"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="toDate"
                required
                label="To Date"
                type="date"
                :error-messages="toDateErrors"
                @input="toDateOnInputOrBlur"
                @blur="toDateOnInputOrBlur"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="hasAdjust"
                :items="adjustOptions"
                label="Has Adjust"
              />
            </v-col>
          </v-row>
          <v-btn
            class="mt-5 mb-5 btn-bg-deep-blue"
            :loading="reportGenerating"
            @click="generateReport()"
          >
            Generate Report
          </v-btn>
        </v-form>
      </v-container>
    </v-card>

    <template v-if="reportLoaded">
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
      >
        <template v-slot:item.statement.period_start="{ item }">
          {{ formatDate(item.statement.period_start) }}
        </template>

        <template v-slot:item.statement.period_end="{ item }">
          {{ formatDate(item.statement.period_end) }}
        </template>

        <template v-slot:item.statement.change="{ item }">
          {{ formatAmount(item.statement.change) }}
        </template>

        <template v-slot:item.statement.adjust="{ item }">
          {{ formatAmount(item.statement.adjust) }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ formatDateTime(item.created_at) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            tile
            color="primary"
            small
            text
            @click="showJsonModal('JSON', item.statement)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-eye
            </v-icon>
            SHOW
          </v-btn>

          <v-btn
            v-if="item.statement.adjust === 0.00"
            tile
            color="primary"
            small
            text
            @click="downloadPDF(item.statement)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-download
            </v-icon>
            PDF
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <JsonModal
      v-model="jsonModal"
      :title="jsonTitle"
      :json="json"
      @close-modal="closeJsonModal()"
      @click:outside="closeJsonModal()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';
import JsonModal from '@/components/JsonModal.vue';

const lessThanToDate = (value, vm) => (value <= vm.toDate);
const greaterThanFromDate = (value, vm) => (value >= vm.fromDate);

export default {
  name: 'StatementLogsReport',
  components: {
    JsonModal,
  },
  data() {
    return {
      formProcessing: false,
      fromDate: DateTime.local().toISODate(),
      toDate: DateTime.local().toISODate(),
      reportLoaded: false,
      reportGenerating: false,
      json: {},
      jsonTitle: '',
      jsonModal: false,
      headers: [
        { text: 'Loan ID', value: 'statement.loan_id' },
        { text: 'Period Start', value: 'statement.period_start' },
        { text: 'Period End', value: 'statement.period_end' },
        { text: 'Change', value: 'statement.change' },
        { text: 'Adjust', value: 'statement.adjust' },
        { text: 'Requested At', value: 'created_at' },
        { text: '', value: 'actions' },
      ],
      items: [],
      hasAdjust: null,
      adjustOptions: [
        {
          value: null,
          text: 'All',
        },
        {
          value: true,
          text: 'Yes',
        },
        {
          value: false,
          text: 'No',
        },
      ],
    };
  },
  computed: {
    fromDateErrors() {
      const errors = [];

      if (!this.$v.fromDate.$dirty) {
        return errors;
      }
      if (!this.$v.fromDate.required) {
        errors.push('From Date is required.');
      }

      return errors;
    },
    toDateErrors() {
      const errors = [];

      if (!this.$v.toDate.$dirty) {
        return errors;
      }
      if (!this.$v.toDate.required) {
        errors.push('To Date is required.');
      }

      return errors;
    },
  },
  created() {
    const routeParams = this.$route.params;
    let generateReport = false;

    if (routeParams.fromDate) {
      this.fromDate = routeParams.fromDate;
      generateReport = true;
    }

    if (routeParams.toDate) {
      this.toDate = routeParams.toDate;
      generateReport = true;
    }

    if (generateReport) {
      this.generateReport();
    }
  },
  methods: {
    generateReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .get('api/v1/reports/statement-logs/', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
              hasAdjust: this.hasAdjust,
            },
          })
          .then((res) => {
            vm.items = Object.values(res.data);
            vm.reportLoaded = true;
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },
    resetDateValidators() {
      this.$v.fromDate.$reset();
      this.$v.toDate.$reset();
    },
    touchDateValidators() {
      this.$v.fromDate.$touch();
      this.$v.toDate.$touch();
    },
    fromDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.fromDateErrors.push('From Date cannot be after To Date.');
      }
    },
    toDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.toDateErrors.push('To Date cannot be before From Date.');
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_MED);
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATETIME_MED);
    },
    formatAmount(number) {
      const amount = parseFloat(number).toFixed(2);

      if (amount === '0.00') {
        return '-';
      }

      if (amount < 0) {
        return `-$${Math.abs(amount)}`;
      }

      return `$${amount}`;
    },
    closeJsonModal() {
      this.jsonModal = false;
      this.$nextTick(() => {
        this.json = {};
      });
    },
    showJsonModal(title, json) {
      this.json = json;
      this.jsonTitle = title;
      this.jsonModal = true;
    },
    downloadPDF(statement) {
      this.$axios
        .get(`api/v1/reports/statement-logs/${statement.id}`, { responseType: 'blob' })
        .then((res) => {
          const date = DateTime.fromISO(statement.period_end).toUTC().toFormat('yyyy-MM-dd');
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `statement-${statement.loan_id}-${statement.id}-${date}.pdf`;
          link.click();
          link.remove();
        })
        .catch(() => {
          this.setNotification({
            message: 'There was an error downloading that statement.',
            color: 'red',
          });
        });
    },
    ...mapActions('notification', ['setNotification']),
  },
  validations: {
    fromDate: { required, lessThanToDate },
    toDate: { required, greaterThanFromDate },
  },
};
</script>

<style></style>
