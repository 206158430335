<template>
  <v-dialog
    v-if="leadId > 0"
    value="true"
    max-width="1200"
    scrollable
    @input="$emit('update:leadId', null)"
  >
    <v-card height="100%">
      <v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title>Details for lead: {{ leadId }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="$emit('update:leadId', null)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="isLoading">
        <div class="text-center ma-15">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>

      <template v-else>
        <v-tabs v-model="tabs">
          <v-tab>FullStory sessions</v-tab>
          <v-tab>Loanpro Tracking</v-tab>
          <v-tab>Password Protocol</v-tab>
        </v-tabs>

        <v-divider />

        <v-card-text>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <FullStorySessions :sessions="leadDetails.fullStorySessions" />
            </v-tab-item>
            <!-- ./ FullStory sessions -->

            <v-tab-item>
              <v-card flat>
                <v-card-title>Loanpro Tracking</v-card-title>
                <JsonFormatterWrapper
                  class="pa-5 pt-0"
                  :data="leadDetails.loanproTracking"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <PasswordProtocol :password-protocol="leadDetails.passwordProtocol" />
            </v-tab-item>
            <!-- ./ Password Protocol -->
          </v-tabs-items>
        </v-card-text>

        <v-divider />
        <v-divider />
        <v-card-actions>
          <v-card-text class="pl-5 pb-0">
            <h2 class="pb-4">
              Servicing Details
            </h2>
            <JsonFormatterWrapper :data="servicingDetails" />
          </v-card-text>
        </v-card-actions>
      </template>

      <v-card-actions />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

import JsonFormatterWrapper from '../JsonFormatterWrapper.vue';
import FullStorySessions from './FullStorySessions.vue';
import PasswordProtocol from './PasswordProtocol.vue';

export default {
  name: 'LeadDetails',

  components: {
    FullStorySessions,
    JsonFormatterWrapper,
    PasswordProtocol,
  },

  props: {
    leadId: {
      type: Number,
    },
  },

  data() {
    return {
      tabs: null,
      show: true,
      leadDetails: {
        fullStorySessions: [],
        loanproTracking: {},
      },
      isLoading: true,
    };
  },

  computed: {
    ...mapState('app', ['settings']),

    servicingDetails() {
      const details = JSON.parse(this.leadDetails.servicingDetails ?? '{}');

      if (!this.settings.servicing_show_2fa) {
        delete details.two_factor_auth_code;
      }
      return details;
    },

    passwordProtocol() {
      return this.leadDetails.passwordProtocol;
    },
  },

  async created() {
    const { status, data } = await this.$axios.get(
      `api/v1/lead/${this.leadId}/service`,
    );

    if (status === 200) {
      this.leadDetails = Object.assign(this.leadDetails, data);
    }

    this.isLoading = false;
  },
};
</script>

<style scoped>
.v-dialog__content {
  align-items: start;
  margin-top: 15px;
}
</style>
