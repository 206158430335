import { debounce } from 'lodash';

export default {
  data() {
    return {
      editedIndex: -1,
      loading: false,
      items: [],
      options: {
        itemsPerPage: 15,
      },
      footerProps: {
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 25, 50, 100],
      },
      search: '',
      totalItems: 0,
    };
  },
  methods: {
    // Debounce will not work with an arrow function
    // eslint-disable-next-line func-names
    searchTable: debounce(function () {
      this.updateTable();

      this.options.page = 1;
    }, 500),
  },
};
