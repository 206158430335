<template>
  <v-dialog
    v-model="dialog"
    width="400px"
    @click:outside="dialog = !dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        small
        v-on="on"
      >
        mdi-help-circle-outline
      </v-icon>
    </template>
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">
          Generate Pay Off
        </span>
      </v-card-title>

      <v-card-text class="my-5">
        <v-row>
          <v-col>
            <v-text-field
              v-model="selectedDate"
              label="Select Date"
              type="date"
              @input="getPayOff"
            />
          </v-col>
        </v-row>
        <v-simple-table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Daily Change</th>
              <th>Payoff</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, key) in payoffData"
              :key="key"
            >
              <td>{{ data.date }}</td>
              <td>{{ data.change }}</td>
              <td>{{ formatCurrency(data.payoff) }}</td>
            </tr>
            <tr v-if="payoffData.length == 0">
              <td>No Data Available</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="dialog = !dialog"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PayoffModal',
  props: {
    loanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      formProcessing: false,
      loading: false,
      dialog: false,
      payoffData: [],
      selectedDate: new Date().toISOString().slice(0, 10),
    };
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
    },
  },
  created() {
    this.getPayOff(this.selectedDate);
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async getPayOff(val) {
      const newDate = (new Date(val)).getTime();
      if (newDate > 0) {
        this.payoffData = [];
        this.loading = true;
        const res = await this.$axios
          .get(`api/v1/support/ticket/loan/${this.loanId}/payoff-amount`, {
            params: {
              date: val,
            },
          });
        this.loading = false;
        if (res.data) {
          this.payoffData = res.data.d;
        }
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(value);
    },
  },
};
</script>
