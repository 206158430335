import Vue from 'vue';

const initialState = {};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async updateAutopayPayment({ commit }, { loanId, selectedAccountId }) {
    try {
      const response = await Vue.prototype.$axios
        .post(`api/v1/admin/loan/${loanId}/autopays/${selectedAccountId}/update`);
      return response;
    } catch (err) {
      return err.response;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async makePayment({ commit }, data) {
    try {
      const response = await Vue.prototype.$axios
        .post(`api/v1/loanpro-customer/${this.state.loanProCustomer.customer.id}/payment/${this.state.loanProLoan.activeLoan.id}/${data.paymentProfile}/make`, {
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
          paymentDate: data.paymentDate,
          paymentAmount: data.paymentAmount,
          paymentTypeId: data.paymentTypeId,
        });
      return response;
    } catch (err) {
      return err.response;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async reversePayment({ commit }, paymentId) {
    try {
      const response = await Vue.prototype.$axios
        .post(`api/v1/loanpro-customer/${this.state.loanProCustomer.customer.id}/payment/${paymentId}/reverse`, {
          ticketId: this.state.ticket.ticket.zendesk.id * 1,
          loanId: this.state.loanProLoan.activeLoan.id,
        });
      return response;
    } catch (err) {
      return err.response;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async createLoanRecurringAutopay({ commit }, { loanId, paymentAccountId }) {
    try {
      const response = await Vue.prototype.$axios
        .post(`api/v1/admin/loan/${loanId}/autopays/${paymentAccountId}/schedule`);
      return response;
    } catch (err) {
      return err.response;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async updateSelectedAutopayPayment({ commit }, { loanId, selectedAccount, autopays }) {
    const nAuto = (autopays || []).map((autopay) => ({
      ...autopay,
      applyDate: autopay.apply_date,
      processDateTime: autopay.process_datetime,
      PrimaryPaymentMethod: {
        id: autopay.primary_payment_account.id,
        title: autopay.primary_payment_account.title,
        type: autopay.primary_payment_account.type,
      },
      PaymentType: {
        id: autopay.payment_type_id,
      },
    }));

    const options = {
      nAuto,
    };

    try {
      const response = await Vue.prototype.$axios
        .post(`api/v1/admin/loan/${loanId}/autopays/${selectedAccount.id}/update`, options);
      return response;
    } catch (err) {
      return err.response;
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
