<template>
  <v-btn
    color="primary"
    class="mt-2"
    small
    block
    text
  >
    <v-icon class="mr-1">
      mdi-account-details-outline
    </v-icon> Details
  </v-btn>
</template>

<script>
export default {
  // this.params.value
};
</script>
