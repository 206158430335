<template>
  <div class="grey lighten-3">
    <v-card>
      <v-card-title>
        Lead Compliance
      </v-card-title>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="6"
              md="4"
            >
              <v-select
                v-model="searchType"
                :items="searchTypes"
                label="Search Type"
                :error-messages="searchTypeErrors"
                @input="$v.searchType.$touch()"
                @blur="$v.searchType.$touch()"
              />
            </v-col>

            <v-col
              cols="6"
              md="4"
            >
              <v-text-field
                v-model="searchValue"
                required
                label="Search Value"
                :error-messages="searchValueErrors"
                @input="$v.searchValue.$touch()"
                @blur="$v.searchValue.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              md="3"
            >
              <v-btn
                v-if="hasPermission('view compliance::lead')"
                class="btn-bg-deep-blue"
                :disabled="formProcessing"
                :loading="formProcessing"
                @click="submitForm()"
              >
                Generate Report
              </v-btn>
              <disabled-for-lack-of-role-btn
                v-else
                text="Generate Report"
                permission="view compliance::lead"
                :x-small="false"
              />
            </v-col>

            <v-col
              cols="6"
              md="3"
            >
              <p
                v-if="notFound"
                class="red--text"
              >
                No Leads Found
              </p>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>

    <v-card
      v-for="(lead, leadIndex) in leads"
      :key="leadIndex"
      class="mt-6"
    >
      <v-toolbar
        color="blue darken-2"
        dark
      >
        <v-toolbar-title>
          Lead {{ lead.id }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-title v-if="hasLoan(lead)">
          Loan {{ loanProTracking(lead).loan_id }} - {{ loanDisplayId(lead) }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-title>
          {{ formatDate(lead.create_date) }}
        </v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row dense>
          <v-col
            cols="12"
            sm="6"
          >
            <v-card min-height="320">
              <v-card-title>
                Application
              </v-card-title>
              <v-card-subtitle class="d-flex justify-lg-space-between">
                <span>Full Name</span>
                <span>{{ lead.firstname }} {{ lead.middleinitial }} {{ lead.lastname }}</span>
              </v-card-subtitle>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, lpIndex) in leadProvider(lead)"
                    :key="lpIndex"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ item.heading }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-for="(property, propIndex) in item.properties"
                        :key="propIndex"
                        class="d-flex justify-lg-space-between"
                      >
                        <span>{{ property.title }}</span>
                        <span>{{ property.value }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-card min-height="320">
              <v-card-title>
                Document
              </v-card-title>
              <v-card-subtitle
                v-if="hasLoan(lead)"
                class="d-flex justify-lg-space-between"
              >
                <a
                  :href="`https://loanpro.simnang.com/client/app/index.php#/t_/5201208/loan/menu/profile?loanid=${loanProTracking(lead).loan_id}&view=servicing.documents`"
                  target="_blank"
                >LoanPro Docs</a>
                <span>
                  Loan Id: {{ loanProTracking(lead).loan_id }} - {{ loanDisplayId(lead) }}
                </span>
              </v-card-subtitle>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(doc, docIndex) in document(lead)"
                    :key="docIndex"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ doc.title }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="d-flex justify-lg-space-between">
                        <span>Completed</span>
                        <span :class="[doc.completed === 'Yes' ? 'green--text' : 'red--text']">
                          {{ doc.completed }}
                        </span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="doc.timestamp"
                        class="d-flex justify-lg-space-between"
                      >
                        <span>Timestamp</span>
                        <span>{{ doc.timestamp }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-card min-height="320">
              <v-card-title>
                Consent
              </v-card-title>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(consent, cIndex) in consents(lead)"
                    :key="cIndex"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ consent.title }}</span>
                      </v-list-item-title>

                      <v-list-item-subtitle class="d-flex justify-lg-space-between">
                        <span>Received</span>
                        <span :class="[consent.received === 'Yes' ? 'green--text' : 'red--text']">
                          {{ consent.received }}
                        </span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="consent.timestamp"
                        class="d-flex justify-lg-space-between"
                      >
                        <span>Timestamp</span>
                        <span>{{ consent.timestamp }}</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="consent.ipaddress"
                        class="d-flex justify-lg-space-between"
                      >
                        <span>IP Address</span>
                        <span>{{ consent.ipaddress }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>Credit Score Risk Disclosure</span>
                      </v-list-item-title>

                      <v-list-item-subtitle class="d-flex justify-lg-space-between">
                        <span>Received</span>
                        <span :class="[lead.click_through ? 'green--text' : 'red--text']">
                          {{ lead.click_through ? 'Yes' : 'No' }}
                        </span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="d-flex justify-lg-space-between">
                        <span>Score</span>
                        <span>{{ lead.one_off_score.clear_credit_risk }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-card min-height="320">
              <v-card-title>
                Waterfall
              </v-card-title>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(method, wIndex) in waterfall(lead).trackingByCall"
                    :key="wIndex"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title class="d-flex justify-lg-space-between">
                        <span>{{ method.class }}->{{ method.method }}</span>
                        <span :class="[method.result ? 'green--text' : 'red--text']">
                          {{ method.result ? 'Yes' : 'No' }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="!method.result">
                        <span>{{ waterfall(lead).failedMessage }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-card min-height="320">
              <v-card-title>
                Scores
              </v-card-title>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(score, sIndex) in scores(lead)"
                    :key="sIndex"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title class="d-flex justify-lg-space-between">
                        <span>{{ score.title }}</span>
                        <span>{{ score.value }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';
import { get } from 'lodash';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'ComplianceLeadIndex',

  components: {
    DisabledForLackOfRoleBtn,
  },

  data() {
    return {
      searchTypes: [
        {
          value: 'id',
          text: 'Lead Id',
        },
        {
          value: 'ssn',
          text: 'SSN',
        },
      ],
      searchType: 'id',
      searchValue: null,
      formProcessing: false,
      leads: [],
      notFound: false,
    };
  },

  computed: {
    searchValueErrors() {
      const errors = [];

      if (!this.$v.searchValue.$dirty) {
        return errors;
      }
      if (!this.$v.searchValue.required) {
        errors.push('Search value is required.');
      }
      return errors;
    },

    searchTypeErrors() {
      const errors = [];

      if (!this.$v.searchType.$dirty) {
        return errors;
      }
      if (!this.$v.searchType.required) {
        errors.push('Search type is required.');
      }
      return errors;
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    formatDate(date) {
      if (DateTime.fromISO(date).isValid) {
        return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
      }

      if (DateTime.fromSQL(date).isValid) {
        return DateTime.fromSQL(date).toLocaleString(DateTime.DATETIME_SHORT);
      }

      return '';
    },

    loanProTracking(lead) {
      return lead.lead_loan_pro_tracking;
    },

    hasLoan(lead) {
      return !!get(this.loanProTracking(lead), 'loan_id', false);
    },

    docusignComplete(lead) {
      return !!get(this.loanProTracking(lead), 'completed_docusign', false);
    },

    loanDisplayId(lead) {
      return get(lead.loan_entity, 'display_id', '');
    },

    waterfall(lead) {
      const trackingByCall = lead.lead_tracking_by_call;
      // get the last item in the tracking by call array
      const lastCall = trackingByCall[trackingByCall.length - 1];

      let failedMessage = '';
      if (!lastCall.result) {
        // make log data an array
        const logObject = JSON.parse(lead.lead_tracking.log_data);
        const logArray = Object.keys(logObject).map((log) => logObject[log]);
        // go backwards through the array for the first failed result
        const failedLog = logArray.slice().reverse().find((log) => log.result === 'fail');
        // and get the message from it.
        failedMessage = failedLog.message || '';
      }

      return {
        trackingByCall,
        failedMessage,
      };
    },

    scores(lead) {
      return [
        {
          title: `${process.env.VUE_APP_PL_NAME} Score`,
          value: lead.one_off_score.dataiku_wf_score,
        },
        {
          title: 'Whitepages Confidence Score',
          value: lead.one_off_score.white_pages_confidence,
        },
        {
          title: 'Clarity Clear Credit Risk Score',
          value: lead.one_off_score.clear_credit_risk,
        },
        {
          title: 'Clarity Fraud Insight Score',
          value: lead.one_off_score.clarity_fraud_insight_score,
        },
        {
          title: 'Clarity Clear Bank Behavior Score',
          value: lead.one_off_score.clarity_bank_score,
        },
        {
          title: 'Clarity Vantage4 Score',
          value: lead.one_off_score.vantage4_score,
        },
      ];
    },

    consents(lead) {
      const clickThrough = !!lead.click_through;
      const timestamp = lead.create_date;
      const { ipaddress } = lead;
      const tcpaTimestamp = lead.tcpa ? lead.tcpa.record_date : timestamp;
      const tcpaIpaddress = lead.tcpa ? lead.tcpa.ipaddress : ipaddress;
      const docusignComplete = this.docusignComplete(lead);

      return [
        {
          title: 'Electronic Communications',
          received: clickThrough ? 'Yes' : 'No',
          timestamp: clickThrough
            ? this.formatDate(timestamp)
            : null,
          ipaddress: clickThrough ? ipaddress : null,
        },
        {
          title: 'Privacy Policy',
          received: clickThrough ? 'Yes' : 'No',
          timestamp: clickThrough
            ? this.formatDate(timestamp)
            : null,
          ipaddress: clickThrough ? ipaddress : null,
        },
        {
          title: 'TCPA',
          received: docusignComplete ? 'Yes' : 'No',
          timestamp: docusignComplete
            ? this.formatDate(tcpaTimestamp)
            : null,
          ipaddress: docusignComplete ? tcpaIpaddress : null,
        },
        {
          title: 'SMS',
          received: docusignComplete ? 'Yes' : 'No',
          timestamp: docusignComplete
            ? this.formatDate(tcpaTimestamp)
            : null,
          ipaddress: docusignComplete ? tcpaIpaddress : null,
        },
      ];
    },

    document(lead) {
      const docusignComplete = this.docusignComplete(lead);
      const loanProTracking = this.loanProTracking(lead);
      const achAuth = docusignComplete && !!loanProTracking.ach_accepted;
      const activeMilitary = docusignComplete && !!loanProTracking.is_mla;

      return [
        {
          title: 'Loan Documents / Truth in Lending',
          completed: docusignComplete ? 'Yes' : 'No',
          timestamp: docusignComplete
            ? this.formatDate(lead.docusign_result.last_update)
            : null,
        },
        {
          title: 'ACH Authorization',
          completed: achAuth ? 'Yes' : 'No',
          timestamp: achAuth ? this.formatDate(loanProTracking.updated) : null,
        },
        {
          title: 'Active Military',
          completed: activeMilitary ? 'Yes' : 'No',
          timestamp: activeMilitary
            ? this.formatDate(loanProTracking.updated)
            : null,
        },
      ];
    },

    leadProvider(lead) {
      return [
        {
          heading: 'Lead Provider',
          properties: [
            {
              title: 'Company',
              value: lead.lead_provider.company_name,
            },
            {
              title: 'Source',
              value: `${lead.lead_provider.first_name} ${lead.lead_provider.last_name}`,
            },
          ],
        },
        {
          heading: 'Status',
          properties: [
            {
              title: 'Disposition',
              value: lead.final_disposition,
            },
            {
              title: 'Acceptance',
              value: lead.final_result,
            },
          ],
        },
      ];
    },

    submitForm() {
      this.notFound = false;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        this.$axios
          .post('api/v1/compliance/lead', {
            column: this.searchType,
            searchTerm: this.searchValue,
          })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.length) {
                this.leads = res.data;
              } else {
                this.notFound = true;
              }
            } else {
              this.errorNotification();
            }
          })
          .catch(() => {
            this.errorNotification();
          })
          .then(() => {
            this.formProcessing = false;
          });
      }
    },

    errorNotification() {
      this.setNotification({
        message: 'There was an error processing your request.',
        color: 'red',
      });
    },
  },

  validations: {
    searchValue: { required },
    searchType: { required },
  },
};
</script>

<style>
</style>
