<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Admin User List</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <UserDeleteModal
            v-model="dialogDelete"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogDelete')"
            @item-deleted="itemDeleted()"
            @click:outside="clearState()"
          />

          <UserFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <user-password-modal
            v-model="dialogPassword"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogPassword')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <User2faModal
            v-model="dialog2fa"
            :edited-item="editedItem"
            @close-modal="closeModal('dialog2fa')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <v-btn
            v-if="hasPermission('create user-admin::user')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New User
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New User"
            permission="create user-admin::user"
            :x-small="false"
            button-class="mb-2"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:[`item.roles`]="{ item }">
      {{ roleNames(item.roles) }}
    </template>

    <template v-slot:[`item.create_date`]="{ item }">
      {{ dateFromSQL(item.create_date) }}
    </template>

    <template v-slot:[`item.deleted_at`]="{ item }">
      {{ dateFromISO(item.deleted_at) }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        v-if="hasPermission('edit user-admin::user')"
        :id="item"
        class="mr-10"
        :disabled="item.deleted_at"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="edit user-admin::user"
        :x-small="false"
        button-class="mr-10"
      />

      <v-icon
        v-if="hasPermission('edit user-admin::user')"
        :id="item"
        class="mr-10"
        :disabled="item.deleted_at"
        @click="updatePassword(item)"
      >
        mdi-lock-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-lock-outline"
        :text-button="true"
        permission="edit user-admin::user"
        :x-small="false"
        button-class="mr-10"
      />

      <v-icon
        v-if="hasPermission('edit user-admin::user')"
        :id="item"
        class="mr-10"
        :disabled="item.deleted_at"
        :color="item.mfa ? 'green' : 'red'"
        @click="reset2fa(item)"
      >
        mdi-two-factor-authentication
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-two-factor-authentication"
        :text-button="true"
        permission="edit user-admin::user"
        :x-small="false"
        button-class="mr-10"
      />

      <v-icon
        v-if="hasPermission('delete user-admin::user')"

        :disabled="item.deleted_at"
        @click="deleteItem(item)"
      >
        mdi-delete-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-delete-outline"
        :text-button="true"
        permission="delete user-admin::user"
        :x-small="false"
        button-class="mr-10"
      />
      <v-icon
        v-if="hasPermission('view user-token')"
        :disabled="item.tokens"
        @click="viewToken(item)"
      >
        mdi-api
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-api"
        :text-button="true"
        permission="view user-token"
        :x-small="false"
        button-class="mr-10"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';
import UserFormModal from '@/views/UserAdmin/User/UserFormModal.vue';
import UserDeleteModal from './UserDeleteModal.vue';
import UserPasswordModal from './UserPasswordModal.vue';
import User2faModal from './User2faModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    UserFormModal,
    UserDeleteModal,
    UserPasswordModal,
    User2faModal,
    DisabledForLackOfRoleBtn,
  },
  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'First Name', value: 'first_name' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Username', value: 'username' },
      { text: 'Email', value: 'email' },
      { text: 'Roles', value: 'roles' },
      { text: 'Activated', value: 'create_date' },
      { text: 'Deactivated', value: 'deleted_at' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],

    dialogDelete: false,
    dialogForm: false,
    dialogPassword: false,
    dialog2fa: false,
    editedItem: {
      id: null,
    },
  }),

  computed: {
  },

  created() {
    this.getRoles({
      perPage: this.options.itemsPerPage,
      page: this.options.page,
      sortBy: this.options.sortBy,
      sortDesc: this.options.sortDesc,
      search: this.search,
    });
    this.getAllRoles({});
  },

  methods: {
    ...mapActions('userPermission', ['getRoles', 'getAllRoles']),
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    updatePassword(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogPassword = true;
    },
    reset2fa(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialog2fa = true;
    },
    itemDeleted() {
      this.items = [];
      this.updateTable();
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/user', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    roleNames(roles) {
      // find the group objects that match the id of this users allowed groups
      const items = [];
      if (roles?.length > 0) {
        roles.forEach((element) => {
          items.push(element.name);
        });

        return items.toString();
      }

      return '';
    },
    dateFromSQL(date) {
      return date === null ? '' : DateTime.fromSQL(date).toLocaleString(DateTime.DATETIME_MED);
    },
    dateFromISO(date) {
      return date === null ? '' : DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    viewToken(item) {
      this.$router.push({
        name: 'user-tokens',
        params: {
          id: item.id,
          user: item.username,
        },
        query: {
          user: item.username,
        },
      });
    },
  },
};
</script>
