<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="1050"
    class="remove-class"
    @click:outside="dialog = !dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        Ops Ticket
      </v-btn>
    </template>
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline"> Ops Ticket </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <OpsTicketAlertMessage v-if="!loadingClassifications && !classifications.length">
            Classification have not been setup yet!
          </OpsTicketAlertMessage>

          <OpsTicketAlertMessage
            v-else-if="ticketCreated"
            color="blue-grey"
          >
            Your ticket has been submitted successfully. Your ticket number is {{ ticketDisplayId }}
          </OpsTicketAlertMessage>

          <v-row
            v-else
            class="mt-3"
          >
            <v-col
              cols="8"
              sm="8"
              md="8"
            >
              <v-row>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="classificationSelected"
                    :items="[{ id: 0, name: 'Select' }, ...classifications]"
                    item-text="name"
                    item-value="id"
                    label="Classification*"
                    :rules="validations.classificationRule"
                  />
                </v-col>

                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="subClassificationSelected"
                    :items="[{ id: 0, name: 'Select' }, ...subClassifications]"
                    item-text="name"
                    item-value="id"
                    label="Sub Classification*"
                    :rules="validations.subClassificationRule"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="ticketTitle"
                    dense
                    label="What is the issue*"
                    :rules="validations.ticketTitleRule"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    v-model="ticketDescription"
                    name="input-7-1"
                    label="What have you tried to do to solve this issue?*"
                    :rules="validations.ticketDescriptionRule"
                  />
                </v-col>
              </v-row>

              <OpsTicketFSInput v-model="fullStoryLinksArray" />
            </v-col>

            <v-divider vertical />

            <v-col
              cols="4"
              sm="4"
              md="4"
            >
              <v-text-field
                dense
                label="Possible Ops Ticket Number"
                cols="12"
                sm="12"
                md="12"
                disabled
                :value="possibleTicketDisplayId"
              />

              <v-text-field
                v-model="ticketStatus"
                dense
                label="Ops Ticket Status"
                cols="12"
                sm="12"
                md="12"
                disabled
              />

              <v-text-field
                dense
                label="Zendesk Ticket Number"
                cols="12"
                sm="12"
                md="12"
                disabled
                :value="ticketNumber"
              />

              <v-text-field
                dense
                label="Lp Display Id"
                cols="12"
                sm="12"
                md="12"
                disabled
                :value="activeLoan.display_id"
              />

              <v-row>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    dense
                    label="Lp Status"
                    cols="12"
                    sm="12"
                    md="12"
                    disabled
                    :value="lpLoanStatus"
                  />
                </v-col>

                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    dense
                    label="Lp Sub Status"
                    cols="12"
                    sm="12"
                    md="12"
                    disabled
                    :value="lpLoanSubStatus"
                  />
                </v-col>
              </v-row>

              <v-text-field
                dense
                label="Agent Name"
                cols="12"
                sm="12"
                md="12"
                disabled
                :value="userAgent"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          v-if="!ticketCreated"
          :loading="formProcessing"
          color="primary"
          :disabled="formProcessing || !ableToCreate"
          @click="createOpsTicket()"
        >
          Create
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="closeDialog()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import OpsTicketFSInput from '@/views/Tickets/TicketSummary/OpsTicketModal/OpsTicketFSInput.vue';
import { datadogRum } from '@datadog/browser-rum';
import OpsTicketAlertMessage from '@/views/Tickets/TicketSummary/OpsTicketModal/OpsTicketAlertMessage.vue';

export default {
  name: 'OpsTicketModal',
  components: { OpsTicketAlertMessage, OpsTicketFSInput },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lpCustomer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      ticketTitle: '',
      ticketDescription: '',
      fullStoryLinksArray: [],
      formProcessing: false,
      loadingClassifications: false,
      classifications: [],
      classificationSelected: null,
      loadingSubClassification: false,
      subClassifications: [],
      subClassificationSelected: null,
      ticketStatus: 'New',
      lastTicketId: '',
      validations: {
        classificationRule: [(value) => !!value || 'Please select a valid classification'],
        subClassificationRule: [(value) => !!value || 'Please select a valid sub classification'],
        ticketTitleRule: [
          (value) => !!value || 'This field is required',
          (value) => (!!value && value.length >= 3) || 'Title must be more than 3 character',
        ],
        ticketDescriptionRule: [
          (value) => !!value || 'This field is required',
          (value) => (!!value && value.length >= 3) || 'Description must be more than 3 character',
        ],
      },
      datadogRumSessionId: '0.0.0',
      ticketCreated: false,
      ticketDisplayId: '',
    };
  },

  computed: {
    ...mapState('loanProLoan', ['activeLoan']),
    ...mapState('ticket', ['ticket']),
    ...mapState('currentUser', ['user']),

    lpLoanStatus() {
      return this.activeLoan?.current_status?.loan_status_text ?? '';
    },

    lpLoanSubStatus() {
      return this.activeLoan?.current_status?.loan_sub_status_text ?? '';
    },

    ticketNumber() {
      return this.ticket?.zendesk?.id ?? '';
    },

    userAgent() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },

    possibleTicketDisplayId() {
      let displayCode = '';

      if (this.classificationSelected) {
        this.classifications.forEach((classification) => {
          if (classification.id === this.classificationSelected) {
            displayCode = classification.name[0].toUpperCase();
          }
        });
      }

      if (this.subClassificationSelected) {
        this.subClassifications.forEach((sub) => {
          if (sub.id === this.subClassificationSelected) {
            displayCode += sub.name[0].toUpperCase();
          }
        });
      }

      return displayCode ? `${displayCode}-${this.lastTicketId * 1 + 1}` : this.lastTicketId * 1 + 1;
    },

    ableToCreate() {
      if (!this.classificationSelected) {
        return false;
      }

      if (!this.subClassificationSelected) {
        return false;
      }

      if (!this.ticketTitle) {
        return false;
      }

      if (!this.ticketDescription) {
        return false;
      }

      return true;
    },
  },

  watch: {
    classificationSelected(newValue) {
      this.loadSubClassifications(newValue);
    },

    dialog(newValue) {
      if (newValue) {
        this.getPossibleTicketDisplayId();
      }
    },
  },

  created() {
    if (datadogRum.getInternalContext() !== undefined) {
      this.datadogRumSessionId = datadogRum.getInternalContext().session_id || '0.0.0';
    }
  },

  mounted() {
    this.loadClassifications();
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    isURL(str) {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    },

    loadClassifications() {
      this.loadingClassifications = true;

      return this.$axios
        .get('api/v1/ops-tickets/classification/list', {
          params: {
            forceSub: true,
          },
        })
        .then((res) => {
          this.loadingClassifications = false;

          this.classifications = res.data ?? [];

          this.classificationSelected = 0;
        })
        .catch((error) => {
          this.loadingClassifications = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    loadSubClassifications(newValue) {
      this.loadingSubClassification = true;

      if (newValue === 0) {
        this.subClassifications = [];

        return null;
      }
      return this.$axios
        .get('api/v1/ops-tickets/sub-classification', {
          params: {
            classification: newValue,
            paginate: false,
          },
        })
        .then((res) => {
          this.subClassifications = res.data?.data ?? [];
        })
        .catch((error) => {
          this.loadingSubClassification = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    getPossibleTicketDisplayId() {
      return this.$axios.get('api/v1/ops-tickets/possible-ticket-display-id').then((res) => {
        this.lastTicketId = res.data || 0;
      }).catch((error) => {
        let errMsg = 'There was an error processing your request';
        if (
          error.response
          && (error.response.status === 404 || error.response.status === 422)
          && error.response.data
        ) {
          errMsg = error.response.data.message;
        }
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      });
    },

    createOpsTicket() {
      this.formProcessing = true;

      return this.$axios.post('api/v1/ops-tickets', this.getOptions()).then((res) => {
        this.formProcessing = false;

        if (res.status >= 200 && res.status < 300) {
          this.ticketCreated = true;
          this.ticketDisplayId = res.data?.data.opsTicketDisplayId ?? '';
        }
      }).catch((error) => {
        this.formProcessing = false;
        let errMsg = 'There was an error processing your request';
        if (
          error.response
          && (error.response.status === 404 || error.response.status === 422)
          && error.response.data
        ) {
          errMsg = error.response.data.message;
        }
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      });
    },

    getOptions() {
      return {
        classification_id: this.classificationSelected,
        sub_classification_id: this.subClassificationSelected,
        title: this.ticketTitle,
        description: this.ticketDescription,
        fs_links: JSON.stringify(this.fullStoryLinksArray),
        zd_ticket_number: this.ticketNumber,
        lp_display_id: this.activeLoan?.display_id || '',
        hui_session_id: this.datadogRumSessionId,
        lp_status: this.lpLoanStatus,
        lp_sub_status: this.lpLoanSubStatus,
        agent_id: this.user.id,
      };
    },

    closeDialog() {
      this.cleanForm();
      this.dialog = !this.dialog;
    },

    cleanForm() {
      this.ticketTitle = '';
      this.ticketDescription = '';
      this.fullStoryLinksArray = [];
      this.formProcessing = false;
      this.loadingClassifications = false;
      this.classificationSelected = null;
      this.loadingSubClassification = false;
      this.subClassificationSelected = null;
      this.ticketStatus = 'New';
      this.lastTicketId = '';
      this.ticketCreated = false;
    },
  },
};
</script>
