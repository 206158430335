<template>
  <div>
    <v-card-actions>
      <v-spacer />
      <v-btn
        button-full
        size="large"
        @click="anotherTask()"
      >
        Another Task
      </v-btn>
      <v-btn
        button-full
        size="large"
        @click="resolveTicket()"
      >
        Resolve Ticket
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'TicketActions',

  methods: {

    ...mapActions('ticket', ['actionButtonsMenuToSideBar']),

    anotherTask() {
      this.paymentModal = false;
      this.actionButtonsMenuToSideBar({
        routerViewCol: 12,
        sidebar: 0,
        sidebarDisplay: 'd-none',
      });
      this.$router.push({
        name: 'ticket-show',
        params: {
          id: this.$route.params.id,
        },
      });
    },

    resolveTicket() {
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.$router.push({
            name: 'tickets',
          });
        });
    },
  },
};
</script>
