import { DateTime } from 'luxon';

// convert date to human readable format
// eslint-disable-next-line import/prefer-default-export
export function formatDate(date) {
  if (DateTime.fromSQL(date).isValid) {
    return DateTime.fromSQL(date).toFormat('MM/dd/yyyy');
  }
  return date;
}

export function formatDateTime(date) {
  if (DateTime.fromSQL(date).isValid) {
    return DateTime.fromSQL(date).toFormat('MM/dd/yyyy HH:mm:ss');
  }
  return date;
}

export function formatCurrency(value) {
  const parsedInput = parseFloat(value);

  if (parsedInput >= 0) {
    return (new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

    })).format(parsedInput).replace(/^(\D+)/, '$1 ');
  }

  return null;
}
