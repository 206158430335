<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Ticket Settlement Reset
        </v-card-title>
        <v-card-text>
          <v-row
            cols="12"
          >
            <v-col
              cols="3"
            >
              <v-card
                style="height: 100px"
                :color="loanStatus === 'Portal 1' ? 'success' : ''"
              >
                <v-card-text
                  class="text-center justify-center"
                >
                  Portal 1 - Partial Settlement
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
            >
              <v-card
                style="height: 100px"
                :color="loanStatus === 'Portal 2' ? 'success' : ''"
              >
                <v-card-text
                  class="text-center justify-center"
                >
                  Portal 2 - Full Settlement
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
            >
              <v-card
                style="height: 100px"
                :color="loanStatus === 'Portal 3' ? 'success' : ''"
              >
                <v-card-text
                  class="text-center justify-center"
                >
                  Portal 3 - Pay In Full
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
            >
              <v-card
                style="height: 100px"
                :color="loanStatus === 'Past Due Support' ? 'success' : ''"
              >
                <v-card-text
                  class="text-center justify-center"
                >
                  Past Due Support
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-alert
            v-if="loanStatus !== 'Portal 1'
              && loanStatus !== 'Portal 2'
              && loanStatus !== 'Portal 3'
              && loanStatus !== 'Past Due Support'"
            type="error"
            class="mt-4 text-center"
          >
            This loan does not contain one of the above statuses,
            there for you cannot preform a Settlement Reset. This loan's
            status is: {{ loanStatus }}
          </v-alert>
          <v-card
            v-else
            class="mt-4"
          >
            <v-card-text>
              <v-alert
                type="warning"
              >
                <p>
                  <b>Note For CSR:</b>
                </p>
                <p>
                  Please STRONGLY encourage the Customer to create a new Settlement
                  after resetting their Settlement. If they do not want to create a new
                  Settlement right now, please send the email below by clicking
                  on "Reset Settlement and Send Email". The Email provides instructions
                  on how to create new Settlement via the Client Portal.
                </p>
              </v-alert>
              <reset-settlement
                :ticket="ticket"
                @success="handleSuccess('reset')"
              />
              <reset-settlement
                :ticket="ticket"
                :send-email="true"
                @success="handleSuccess('reset-and-email')"
              />
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <ticket-successful-action
      :ticket-successful-action-dialog="ticketSuccessfulActionDialog"
      :ticket-id="$route.params.id"
      :title="title"
      @closed="ticketSuccessfulActionDialog = false"
    />
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import ResetSettlement from './SettlementReset/ResetSettlement.vue';
import TicketSuccessfulAction from './TicketSuccessfulAction.vue';

export default {
  name: 'TicketSettlementReset',
  components: {
    ResetSettlement,
    TicketSuccessfulAction,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ticketSuccessfulActionDialog: false,
      title: '',
    };
  },
  computed: {
    loanStatus() {
      const status = this.ticket?.active_loan?.current_status?.loan_status_id || 0;
      const subStatus = this.ticket?.active_loan?.current_status?.loan_sub_status_id || 0;
      if ([17, 19].includes(status)) {
        if (subStatus === 172) {
          return 'Portal 1';
        }
        if (subStatus === 173) {
          return 'Portal 2';
        }
        if (subStatus === 174) {
          return 'Portal 3';
        }
        if (subStatus === 217) {
          return 'Past Due Support';
        }
      }
      const statusText = this.ticket?.active_loan?.current_status?.loan_status_text || '';
      const subStatusText = this.ticket?.active_loan?.current_status?.loan_sub_status_text || '';
      return `${statusText} ${subStatusText}`;
    },
  },
  methods: {
    ...mapActions('ticket', ['getTicket']),
    async handleSuccess(type) {
      if (type === 'reset') {
        this.title = 'This Settlement was successfully reset.';
      }
      if (type === 'macro') {
        this.title = '"NEW PORTAL Login Instructions + SETTLEMENT Dashboard" Communication Macro was successfully sent.';
      }
      if (type === 'reset-and-email') {
        this.title = 'This Settlement was successfully reset and the "NEW PORTAL Login Instructions + SETTLEMENT Dashboard" Communication Macro was successfully sent.';
      }
      this.ticketSuccessfulActionDialog = true;
      await this.getTicket(this.$route.params.id);
    },
  },
};
</script>
