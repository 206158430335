<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="name"
                  required
                  label="Campaign Name"
                  :error-messages="validation($v.name, 'Name')"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="campaignCode"
                  required
                  label="Campaign Code"
                  maxlength="4"
                  :error-messages="validation($v.campaignCode, 'Campaign Code ')"
                  @input="$v.campaignCode.$touch()"
                  @blur="$v.campaignCode.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="maxLoanAmount"
                  type="number"
                  step="0.01"
                  required
                  label="Max Loan Amount"
                  :error-messages="validation($v.maxLoanAmount, 'Max Loan Amount')"
                  @input="$v.maxLoanAmount.$touch()"
                  @blur="$v.maxLoanAmount.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="leadProviderId"
                  :items="leadProviders"
                  required
                  label="Lead Provider"
                  :error-messages="leadProviderIdErrors"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="externalCampaignId"
                  :items="campaigns[leadProviderId]"
                  required
                  label="Campaign"
                  :error-messages="validation($v.externalCampaignId,'Campaign')"
                  :disabled="leadProviderId.length === 0"
                  @input="$v.externalCampaignId.$touch()"
                  @blur="$v.externalCampaignId.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="printExpireDate"
                  required
                  label="Print Expire Date"
                  type="date"
                  :error-messages="validation($v.printExpireDate, 'Print Expire Date')"
                  @input="$v.printExpireDate.$touch()"
                  @blur="$v.printExpireDate.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="systemExpireDate"
                  required
                  label="System Expire Date"
                  type="date"
                  :error-messages="validation($v.systemExpireDate, 'System Expire Date')"
                  @input="$v.systemExpireDate.$touch()"
                  @blur="$v.systemExpireDate.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="activeDate"
                  required
                  label="Active Date"
                  type="date"
                  :error-messages="validation($v.activeDate, 'Active Date')"
                  @input="$v.activeDate.$touch()"
                  @blur="$v.activeDate.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="mailDate"
                  required
                  label="Mail Date"
                  type="date"
                  :error-messages="validation($v.mailDate, 'Mail Date')"
                  @input="$v.mailDate.$touch()"
                  @blur="$v.mailDate.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="cost"
                  type="number"
                  step="0.01"
                  label="Campaign Cost"
                  :error-messages="validation($v.cost, 'Campaign Cost')"
                  @input="$v.cost.$touch()"
                  @blur="$v.cost.$touch()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required,
  minLength,
  minValue,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          name: '',
          print_expire_date: '',
          system_expire_date: '',
          active_date: '',
          mailed_date: '',
          campaign_code: '',
          max_loan_amount: 0,
          lead_provider_id: '',
          external_campaign_id: '',
          cost: 0,
        };
      },
    },
    leadProviders: {
      type: Array,
      default: () => [],
    },
    campaigns: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: null,
      name: '',
      maxLoanAmount: 0.00,
      printExpireDate: null,
      systemExpireDate: null,
      activeDate: null,
      mailDate: null,
      campaignCode: null,
      externalCampaignId: null,
      leadProviderId: '',
      formProcessing: false,
      cost: 0.00,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return this.isNewItem ? 'Add New Prescreen Campaign' : 'Edit Prescreen Campaign';
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    leadProviderIdErrors() {
      const errors = [];

      if (!this.$v.leadProviderId.$dirty) {
        return errors;
      }
      if (!this.$v.leadProviderId.required) {
        errors.push('Lead provider is required.');
      }
      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.name = this.editedItem.name;
      this.maxLoanAmount = this.editedItem.max_loan_amount;
      this.printExpireDate = this.editedItem.print_expire_date;
      this.systemExpireDate = this.editedItem.system_expire_date;
      this.activeDate = this.editedItem.active_date;
      this.mailDate = this.editedItem.mailed_date;
      this.campaignCode = this.editedItem.campaign_code;
      this.externalCampaignId = this.editedItem.external_campaign_id;
      this.leadProviderId = this.editedItem.lead_provider_id;
      this.cost = this.editedItem.cost;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;

        let request = '';

        let data = {
          name: this.name,
          max_loan_amount: this.maxLoanAmount,
          print_expire_date: this.printExpireDate,
          system_expire_date: this.systemExpireDate,
          active_date: this.activeDate,
          mailed_date: this.mailDate,
          campaign_code: this.campaignCode,
          external_campaign_id: this.externalCampaignId,
          lead_provider_id: this.leadProviderId,
          cost: this.cost,
        };

        if (this.isNewItem) {
          data = {
            ...data,
          };

          request = this.$axios.post('/api/v1/prescreen-campaign', data);
        } else {
          request = this.$axios.put(`/api/v1/prescreen-campaign/${this.editedItem.id}`, data);
        }

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.campaign_name} has been saved.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');
    },
    validation(field, name) {
      const errors = [];

      if (!field.$dirty) {
        return errors;
      }
      if (field.minLength && field.$anyError) {
        errors.push(`${name} must have ${field.$params.minLength.min} characters`);
      }
      if (field.minValue && field.$error) {
        errors.push(`${name} must be at least ${field.$params.minValue.min}`);
      }
      if (field.$error) {
        errors.push(`${name} is Required`);
      }
      if (field.$anyError) {
        errors.push(`${name} is Not Valid`);
      }
      return errors;
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    maxLoanAmount: {
      required,
      minValue: minValue(1),
    },
    printExpireDate: { required },
    leadProviderId: { required },
    systemExpireDate: { required },
    activeDate: { required },
    mailDate: { required },
    campaignCode: {
      required,
      minLength: minLength(4),
    },
    externalCampaignId: { required },
    cost: {
      required,
      minValue: minValue(0),
    },
  },
};
</script>

<style></style>
