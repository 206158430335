<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          large
          block
          v-on="on"
        >
          Advancements
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Advancements
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="advances"
            :items-per-page="10"
            class="elevation-1"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AdvancesModal',
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Date', value: 'date' },
        { text: 'Amount', value: 'amount' },
      ],
    };
  },
  computed: {
    ...mapState('advances', ['advances']),
  },
};
</script>
