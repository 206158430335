<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="name"
                  required
                  label="Name"
                  :error-messages="nameErrors"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                Has the following {{
                  currentPermissions ? currentPermissions.length : 0
                }} permissions:
                <ul
                  style="list-style-type:none;"
                  class="mb-5"
                >
                  <li v-if="name === 'Super Admin'">
                    Full Access
                  </li>
                  <li
                    v-for="(item, index) in currentPermissions"
                    v-else
                    :key="index"
                  >
                    <v-icon
                      :id="item"
                      class="red--text mr-5"
                      @click="removePermission(item)"
                    >
                      mdi-delete
                    </v-icon>
                    {{ formatPermission(item.name) }}
                  </li>
                </ul>

                {{ availablePermissions ? availablePermissions.length : 0 }} available permissions:
                <ul style="list-style-type:none;">
                  <li v-if="name === 'Super Admin'">
                    None
                  </li>
                  <li
                    v-for="(item, index) in availablePermissions"
                    v-else
                    :key="index"
                  >
                    <v-icon
                      :id="item"
                      class="green--text mr-5"
                      @click="addPermission(item)"
                    >
                      mdi-plus-circle
                    </v-icon>
                    {{ formatPermission(item.name) }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          name: '',
          guard_name: '',
          permissions: [],
        };
      },
    },
  },
  data() {
    return {
      id: null,
      name: '',
      guard_name: '',
      permissions: [],
      formProcessing: false,
      availablePermissions: [],
    };
  },
  computed: {
    ...mapState('userPermission', { allPermissions: 'permissions' }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      const newOrUpdate = this.isNewItem ? 'New' : 'Update';
      return `${newOrUpdate} User Group`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Name is required.');
      }
      if (!this.$v.name.maxLength) {
        errors.push(`Name must not exceed ${this.$v.name.$params.maxLength.max} letters.`);
      }

      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.description.$dirty) {
        return errors;
      }
      if (!this.$v.description.maxLength) {
        errors.push(`Name must not exceed ${this.$v.description.$params.maxLength.max} letters.`);
      }

      return errors;
    },
    currentPermissions() {
      return this.permissions.map((item) => {
        const element = { ...item };
        element.other = `${element.name.split(' ')[1]} ${element.name.split(' ')[0]}`;
        return element;
      }).sort((a, b) => a.other.localeCompare(b.other));
    },
  },
  watch: {
    editedItem: {
      handler() {
        this.id = this.editedItem.id;
        this.name = this.editedItem.name;
        this.permissions = this.editedItem.permissions ? this.editedItem.permissions : [];
        this.getAvailablePermissions();
      },
      deep: true,
    },
    permissions: {
      handler() {
        this.getAvailablePermissions();
      },
    },
    allPermissions: {
      handler() {
        this.getAvailablePermissions();
      },
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    getAvailablePermissions() {
      // return only the permissions this role does NOT have
      const items = this.allPermissions?.filter((item) => {
        const index = this.permissions?.findIndex((x) => x.id === item.id);
        return !(index >= 0);
      });

      this.availablePermissions = items.map((item) => {
        const element = { ...item };
        element.other = `${element.name.split(' ')[1]} ${element.name.split(' ')[0]}`;
        return element;
      }).sort((a, b) => a.other.localeCompare(b.other));
    },
    formatPermission(name) {
      return `${name.split(' ')[1]} ${name.split(' ')[0]}`;
    },
    addPermission(item) {
      this.permissions.push(item);
    },
    removePermission(item) {
      const index = this.permissions.findIndex((x) => x.id === item.id);
      this.permissions.splice(index, 1);
    },
    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';

        const data = {
          name: this.name,
          permissions: this.permissions,
        };

        if (this.isNewItem) {
          request = this.$axios.post('/api/v1/permission/roles', data);
        } else {
          request = this.$axios.put(`/api/v1/permission/roles/${this.editedItem.id}`, data);
        }

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.name} has been saved.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.name = '';
      this.description = '';
      this.permissions = [];
      this.$emit('close-modal');
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(50),
    },
    description: {
      maxLength: maxLength(150),
    },
  },
};
</script>

<style></style>
