<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-if="hasPermission('edit system-settings::monitors')"
        small
        :color="chipColor"
        text-color="white"
        v-bind="attrs"
        @click="toggle()"
        v-on="on"
      >
        {{ chipText }}
      </v-chip>
      <disabled-for-lack-of-role-btn
        v-else
        :text="chipText"
        permission="edit system-settings::monitors"
        :button="false"
        :chip="true"
        chip-color="error"
        v-on="on"
      />
    </template>
    <span>Click to {{ chipText.toLowerCase() }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'MonitorToggle',

  components: {
    DisabledForLackOfRoleBtn,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    active() {
      return !!this.item.active;
    },
    chipColor() {
      return this.active ? 'green' : 'red';
    },
    chipText() {
      return this.active ? 'Disable' : 'Enable';
    },
    messageText() {
      return this.active ? 'disabled' : 'enabled';
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    toggle() {
      const active = this.active ? 0 : 1;

      this.$axios.put(`api/v1/monitor/${this.item.heartbeat_name}`, {
        heartbeat_name: this.item.heartbeat_name,
        interval: this.item.interval,
        active,
      })
        .then(() => {
          this.setNotification({
            message: `The monitor was ${this.messageText}.`,
            color: 'green',
          });

          this.$emit('monitor-toggled');
        })
        .catch(() => {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        });
    },
  },
};
</script>

<style scoped>

</style>
