<template>
  <div id="api-calls">
    <v-card>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>API Calls For Lead: {{ leadId }}</v-toolbar-title>
          <v-spacer />

          <v-btn
            class="mr-2 mb-2"
            color="blue darken-1"
            text
            @click="$router.go(-1)"
          >
            Back to Lead Queue
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          :footer-props.sync="footerProps"
          @update:options="updateTable()"
        >
          <template v-slot:item.method="{ item }">
            {{ item.method.replace('App\\Services\\', '') }}
          </template>
          <template v-slot:item.endpoint="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="text-truncate"
                  style="max-width: 120px;"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.endpoint }}
                </div>
              </template>
              <span>{{ item.endpoint }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              tile
              color="primary"
              small
              text
              @click="showJsonModal('JSON Payload', item.json_payload)"
            >
              <v-icon
                dark
                left
                class="mr-1"
              >
                mdi-eye
              </v-icon>
              PAYLOAD
            </v-btn>
            <v-btn
              tile
              color="teal"
              small
              text
              @click="showJsonModal('JSON Response', item.json_response)"
            >
              <v-icon
                dark
                left
                class="mr-1"
              >
                mdi-eye
              </v-icon>
              RESPONSE
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <JsonModal
        v-model="apiCallsJsonModal"
        :title="apiCallsJsonTitle"
        :json="apiCallsJson"
        @close-modal="closeJsonModal()"
        @click:outside="closeJsonModal()"
      />
    </v-card>
  </div>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import JsonModal from '@/components/JsonModal.vue';

export default {
  name: 'LeadQueueApiCallsIndex',
  components: {
    JsonModal,
  },
  mixins: [
    dataTableMixin,
  ],
  data() {
    return {
      headers: [
        { text: 'Performed Action', value: 'method' },
        { text: 'Method', value: 'http_method' },
        { text: 'Code', value: 'http_status_code' },
        { text: 'Endpoint', value: 'endpoint', width: 120 },
        { text: 'Duration', value: 'execution_time' },
        { text: 'Time', value: 'timestamp' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      apiCallsJsonModal: false,
      apiCallsJsonTitle: '',
      apiCallsJson: {},
    };
  },
  computed: {
    leadId() {
      return this.$route.params.id;
    },
  },
  methods: {
    closeJsonModal() {
      this.apiCallsJsonModal = false;
      this.$nextTick(() => {
        this.apiCallsJson = {};
      });
    },
    showJsonModal(title, json) {
      try {
        this.apiCallsJson = JSON.parse(json);
        this.apiCallsJsonTitle = title;
        this.apiCallsJsonModal = true;
      } catch (e) {
        this.apiCallsJson = {};
      }
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get(`api/v1/lead-queue/${this.leadId}/api-calls`, {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
  },
};
</script>

<style></style>
