import Vue from 'vue';

const initialState = {
  advances: [],
};

const actions = {
  async getAdvances({ commit, dispatch }, params) {
    try {
      const res = await Vue.prototype.$axios
        .post('api/v1/support/ticket/line-of-credit/advancements', {
          ticketId: params.ticketId,
          loanId: params.loanId,
        });

      commit('SET_ADVANCES', res.data);
    } catch (error) {
      let errMsg = 'There was an error when sending an API request to collect Advancement Data. Please try again. If there error persists, please notify your supervisor.';
      if (
        error.response
        && (error.response.status === 404 || error.response.status === 403)
        && error.response.data
      ) {
        errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
      }
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
};

const mutations = {
  SET_ADVANCES(state, data) {
    state.advances = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
