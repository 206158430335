<template>
  <v-container fluid>
    <v-card-title>
      Validifi Data
    </v-card-title>

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="routingNumber"
          label="Routing Number"
          disabled
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="accountNumber"
          label="Account Number"
          disabled
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <WaterfallTestValidifiOptionsDialog
          :lead-data="leadData"
          :campaigns="campaigns"
          @saveOptions="updateNumbers"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WaterfallTestValidifiOptionsDialog from './WaterfallTestValidifiOptionsDialog.vue';

export default {

  components: {
    WaterfallTestValidifiOptionsDialog,
  },

  props: {
    leadData: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      routingNumber: '',
      accountNumber: '',
      forceValidifi: true,
      validifiModal: false,
    };
  },
  methods: {
    showValidifiModal() {
      this.validifiModal = true;
    },
    updateNumbers(options) {
      this.routingNumber = options.routingNumber;
      this.accountNumber = options.accountNumber;

      this.leadData.routingnumber = options.routingNumber;
      this.leadData.accountnumber = options.accountNumber;
    },
  },
};
</script>

<style></style>
