<template>
  <v-dialog
    v-model="pmtDialog"
    width="1000px"
    scrollable
  >
    <template #activator="{ on: pmtDialog }">
      <v-btn
        color="primary"
        block
        :loading="isPmtLoading"
        :disabled="isViewPmtDisabled"
        :class="paymentSchedulesSent ? 'mb-4 success' : 'mb-4 error'"
        v-on="{...pmtDialog}"
      >
        {{ viewPaymentScheduleButtonText }}
      </v-btn>
    </template>

    <v-card :loading="loadingModel">
      <v-card-text
        class="py-5"
      >
        <div
          v-if="loadingModel"
          class="text-center"
        >
          Please wait while the payment schedule is loading...
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <p v-if="numberOfPayments != 1">
                Your new payment schedule will consist of {{ numberOfPayments }} payments in total.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p v-if="numberOfPayments == 1">
                You will make one payment of {{ fullPaymentAmount|currency }} on
                {{ firstPaymentDate|formatdate('MMMM d, y') }}.
              </p>
              <p v-else-if="numberOfFullPayments != numberOfPayments">
                The first {{ numberOfFullPayments }} of these payments will be equal
                {{ paymentFrequency }} payments of {{ fullPaymentAmount|currency }} each, beginning
                on {{ firstPaymentDate|formatdate('MMMM d, y') }}. Your final payment of
                {{ lastPaymentAmount|currency }} will be due on
                {{ lastPaymentDate|formatdate('MMMM d, y') }}.
              </p>
              <p v-else>
                You will make {{ numberOfFullPayments }} equal {{ paymentFrequency }} payments of
                {{ fullPaymentAmount|currency }} each, beginning on
                {{ firstPaymentDate|formatdate('MMMM d, y') }}. Your last payment will be due on
                {{ lastPaymentDate|formatdate('MMMM d, y') }}.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p v-if="isEOM && paymentFrequency == 'monthly'">
                After your first payment, all subsequent payments will be due on the last
                day of the month.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <v-btn
                :loading="isPmtLoading"
                :disabled="isSendPmtDisabled"
                :class="paymentSchedulesSent ? 'ml-4 mb-4 success' : 'ml-4 mb-4 error'"
                @click="onSendPaymentSchedule()"
              >
                {{ sendPaymentScheduleButtonText }}
              </v-btn>
            </div>
          </div>
          <div class="row">
            <v-simple-table
              fixed-header
              :height="tableHeight"
              style="width:100%; margin-top: 20px;"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Payment Date
                    </th>
                    <th class="text-left">
                      Payment Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in paymentSchedule"
                    :key="item.date"
                  >
                    <td>{{ item.date }}</td>
                    <td>{{ item.amount|currency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapActions } from 'vuex';

export default {
  name: 'PaymentScheduleModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    settlementOffer: {
      type: Object,
      required: true,
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pmtDialog: false,
      loadingModel: false,
      valid: true,
      isPmtLoading: false,
      isViewPmtDisabled: false,
      isSendPmtDisabled: false,
      viewPaymentScheduleButtonText: '',
      sendPaymentScheduleButtonText: '',
    };
  },
  created() {
    // default button text values
    this.resetDefaults();
  },
  computed: {
    ...mapState('app', ['instanceId']),
    ...mapState('settlement', [
      'paymentSchedule',
      'numberOfPayments',
      'numberOfFullPayments',
      'fullPaymentAmount',
      'lastPaymentAmount',
      'firstPaymentDate',
      'lastPaymentDate',
      'paymentFrequency',
      'isEOM',
      'paymentSchedulesSent',
    ]),

    tableHeightInt() {
      const maxRows = 7;
      return (
        this.paymentSchedule.length < maxRows ? (this.paymentSchedule.length + 1) : maxRows
      ) * 50;
    },

    tableHeight() {
      return `${this.tableHeightInt}px`;
    },

    modalHeight() {
      return `${this.tableHeightInt + 250}px`;
    },

    zendeskPaymentSchedule() {
      let paymentScheduleText = 'Here is your proposed settlement payment schedule:\n\n';
      this.paymentSchedule.forEach(
        (item) => {
          paymentScheduleText += `date: ${item.date}, amount due: $${parseFloat(item.amount).toFixed(2)}\n\n`;
        },
      );

      return paymentScheduleText;
    },
  },
  methods: {
    ...mapActions('settlement', ['paymentSchedulesNotSent', 'getPaymentSchedule', 'zendeskSend', 'getManualPaymentSchedule']),

    resetDefaults() {
      this.isSendPmtDisabled = false;
      this.isViewPmtDisabled = false;

      this.viewPaymentScheduleButtonText = 'view settlement payment schedule';
      this.sendPaymentScheduleButtonText = 'send payment schedule via zendesk';
    },

    async onSendPaymentSchedule() {
      this.isPmtLoading = true;
      this.isViewPmtDisabled = true;
      this.isSendPmtDisabled = true;

      await this.zendeskSend({
        ticketId: this.$route.params.id,
        message: this.zendeskPaymentSchedule,
        action: 'SET_PAYMENT_SCHEDULES_SENT',
      });

      const paymentScheduleButtonText = 'payment schedules sent';
      this.viewPaymentScheduleButtonText = paymentScheduleButtonText;
      this.sendPaymentScheduleButtonText = paymentScheduleButtonText;

      this.isPmtLoading = false;
      this.isViewPmtDisabled = false;
    },
  },
  watch: {
    paymentSchedulesSent(newVal) {
      if (newVal === true) {
        this.pmtDialog = false;
      }
    },
    refreshKey() {
      // if the refresh key changes, allow re-sending schedules and set everything back to defaults
      this.paymentSchedulesNotSent();
      this.resetDefaults();
    },
    async pmtDialog(visible) {
      if (visible) {
        // reload the payment schedule when modal opens
        this.loadingModel = true;
        const offerData = {
          ...this.settlementOffer,
          loanId: this.loanId,
          isAchPayment: this.isAchPayment,
          loanSettingsId: this.loanSettingsId,
          payoffBalance: this.todayPayoffBalance,
        };

        await this.getPaymentSchedule({ offer: offerData });
        this.loadingModel = false;
      }
    },
  },
};
</script>

<style>
.v-btn--disabled .v-btn__content {
  color: green;
}
</style>
