import Vue from 'vue';
import lodashFind from 'lodash/find';
import {
  PaymentAccountTypes,
} from './LpConstants';

export default {
  async getSettlementData(customerId, loanId, date) {
    const response = await Vue.prototype.$axios
      .get(`api/v1/support/ticket/loan/${loanId}/settlement/data?date=${date}`)
      .catch(this.errorHandler);

    if (response.status === 200) {
      return response.data;
    }

    return false;
  },

  /**
   * Create Settlement for autopay.
   * @param options
   * @returns {Promise<*>}
   */
  async createSettlementAutopay(options) {
    // REVOKE AUTOPAY BEFORE CREATING SETTLEMENT AUTOPAY
    const findAutopay = await Vue.prototype.$axios
      .post('api/v1/support/ticket/autopay/find', options);

    if (findAutopay?.data?.autopay || null) {
      // eslint-disable-next-line no-unused-vars
      const revokeAutoPay = await Vue.prototype.$axios
        .post('api/v1/support/ticket/autopay/revoke', {
          ticketId: options.ticketId,
          loanId: options.loanId,
          autopayId: findAutopay.data.autopay.id,
          paymentId: findAutopay.data.payment ? findAutopay.data.payment.id : undefined,
        });
    }
    // MAKE LOANPRO CALL
    const response = await Vue.prototype.$axios
      .post(`api/v1/support/ticket/loan/${options.loanId}/autopays/${options.paymentAccountId}/settlement`, options)
      .catch(this.errorHandler);

    return response;
  },

  /**
   * Create settlement for manual payments.
   * @param options
   * @returns {Promise<*>}
   */
  async createManualSettlement(options) {
    // Revoke previous autopay before create the new settlement.
    const findAutopay = await Vue.prototype.$axios
      .post('api/v1/support/ticket/autopay/find', options);

    if (findAutopay?.data?.autopay || null) {
      await Vue.prototype.$axios
        .post('api/v1/support/ticket/autopay/revoke', {
          ticketId: options.ticketId,
          loanId: options.loanId,
          autopayId: findAutopay.data.autopay.id,
          paymentAccountId: findAutopay.data.payment ? findAutopay.data.payment.id : undefined,
        })
    }

    return await Vue.prototype.$axios
      .post(`api/v1/support/ticket/loan/${options.loanId}/manual/settlement`, options)
      .catch(this.errorHandler);
  },

  isBankAccount(paymentAccounts, paymentAccountId) {
    return Boolean(lodashFind(paymentAccounts, {
      id: paymentAccountId,
      type: PaymentAccountTypes.CHECKING,
    }));
  },

  isInCollections(loanStatusId, loanSubStatusId) {
    // check if loan is in collection portal
    // LoanStatus: Collections = 17
    // Regular settlement portal loanSubStatus:
    //   'Account Frozen' = 150,
    //   'Pending Placement' = 149,
    //   'Settlement Offer 1' = 152,
    //   'Settlement Offer 2' = 151,
    //   'Consecutive Missed Payments' = 148,
    return loanStatusId === 17 && [148, 149, 150, 151, 152].includes(loanSubStatusId);
  },

  isCovidCollection(loanStatusId, loanSubStatusId) {
    return loanStatusId === 17 && loanSubStatusId === 178;
  },

  settlementConfig(loanId, loanSettingsId, todayPayoffBalance) {
    return {
      loanId,
      loanSettingsId,
      payoffBalance: todayPayoffBalance,
    };
  },

  errorHandler(error) {
    const rsp = {
      status: error.response.status,
    };

    if (error.response) {
      rsp.response = error.response;
    }
    if (error.request) {
      rsp.request = error.request;
    }
    rsp.message = error.message;

    return rsp;
  },
};
