<template>
  <v-card>
    <v-container>
      <apexchart
        v-if="loaded"
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </v-container>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'BarGraph',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    totalCalls() {
      return this.data.reduce((prev, current) => prev + Number(current.total), 0);
    },
    graphTitle() {
      return `Total ${this.title} Calls: ${this.totalCalls}`;
    },
    series() {
      const seriesData = this.seriesData();
      return [
        {
          name: '',
          data: seriesData,
        },
      ];
    },
    chartOptions() {
      return {
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val}%`;
          },
          offsetY: -18,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        xaxis: {
          categories: this.getXaxisCategories(),
          labels: {
            show: this.data.length > 0,
          },
        },
        fill: {
          opacity: 1,
        },
        yaxis: {
          categories: this.getYaxisCategories(),
          labels: {
            show: false,
            formatter(val) {
              return `${val}%`;
            },
          },
        },
        tooltip: {
          show: false,
        },
        title: {
          text: this.graphTitle,
          align: 'center',
          style: {
            color: '#444',
          },
        },
      };
    },
  },
  mounted() {
    this.loaded = true;
  },
  methods: {
    seriesData() {
      return this.data.map((v) => ((100 * v.total) / this.totalCalls).toFixed(2));
    },
    getXaxisCategories() {
      return this.data.map((v) => v.responseCode);
    },
    getYaxisCategories() {
      return this.data.map((v) => v.total);
    },
  },
};
</script>

<style></style>
