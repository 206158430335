<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Scoring Models</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <ScoringModelFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <ScoringModelSequenceModal
            v-model="dialogSequence"
            :edited-item="editedItem"
            @setCustomVars="onSetCustomVars"
            @close-modal="closeModal('dialogSequence')"
            @click:outside="clearState()"
          />

          <v-btn
            v-if="hasPermission('create lending-strategies::scoring-models')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Scoring Model
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Scoring Model"
            permission="create lending-strategies::scoring-models"
            :x-small="false"
            button-class="mb-2"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('create lending-strategies::scoring-models')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="create lending-strategies::scoring-models"
        :x-small="false"
        button-class="mr-10"
      />
      <v-btn
        v-if="hasPermission('edit lending-strategies::scoring-models')"
        small
        class="btn-bg-deep-blue"
        text-color="white"
        @click="editSequence(item)"
      >
        Edit Sequence
      </v-btn>
      <disabled-for-lack-of-role-btn
        v-else
        text="Edit Sequence"
        permission="edit lending-strategies::scoring-models"
        :button="true"
        :chip="false"
        chip-color="error"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import ScoringModelFormModal from '@/views/LendingStrategies/ScoringModel/ScoringModelFormModal.vue';
import ScoringModelSequenceModal from '@/views/LendingStrategies/ScoringModel/ScoringModelSequenceModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    ScoringModelFormModal,
    ScoringModelSequenceModal,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Version', value: 'version' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: 213,
      },
    ],

    dialogForm: false,
    dialogSequence: false,
    editedItem: {
      id: null,
    },
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    editSequence(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogSequence = true;
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/scoring-model', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    onSetCustomVars(event) {
      // this is being passed all the way from ScoringModelSequenceElementModal
      // when custom_vars are updated
      this.editedItem.custom_vars = event;
    },
  },
};
</script>
