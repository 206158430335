<template>
  <v-form
    ref="singlePaymentForm"
    novalidate
    class="settlement-option"
    @submit.prevent="onSubmit"
  >
    <!-- Total Settlement Amount -->
    <div>
      <p class="mb-xxxs">
        How much are you willing to pay in total to settle your loan debt?
      </p>
      <!-- // TODO: PREVENT THIS FROM SURPASSING PAYOFF AMOUNT -->
      <v-text-field
        v-model.number="stlmt.settlementAmount"
        prepend-inner-icon="mdi-currency-usd"
        label="Total Settlement Amount"
        outlined
        required
      />
    </div>
    <!-- ./ Total Settlement Amount -->

    <PaymentMethodSelect
      :ticket="ticket"
      :settlement-offer="stlmt"
      :payment-accounts="paymentAccounts"
      :error-messages="paymentAccountError"
      @reload-payment-profiles="$emit('reload-payment-profiles')"
    />

    <!-- First Payment Date -->
    <div class="mt-xs">
      <p class="mb-xxxs">
        When can you make your payment?
      </p>
      <v-text-field
        v-model="stlmt.firstPaymentDate"
        label="Payment Date"
        type="date"
        :error-messages="paymentDateError"
        outlined
        required
      />
      <v-progress-linear
        v-if="isCheckingPaymentDate"
        class="mt-n7 mb-6"
        indeterminate
      />
    </div>
    <!-- ./ First Payment Date -->

    <v-card-actions
      class="justify-end"
      width="100%"
    >
      <v-btn
        button-full
        size="large"
        color="settlement"
        :disabled="confirmDisabled"
        @click="onSubmit"
      >
        CONFIRM SINGLE PAYMENT OFFER
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import PaymentMethodSelect from './PaymentMethodSelect.vue';

export default {
  name: 'SettlmentOfferSinglePayment',

  components: {
    PaymentMethodSelect,
  },

  props: {
    paymentAccounts: {
      type: Array,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    refreshKey: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      stlmt: {
        settlementAmount: 0,
        paymentAccountId: null,
        firstPaymentDate: null,
      },
      submitted: false,
      paymentDateError: null,
      paymentAccountError: '',
      isCheckingPaymentDate: false,
    };
  },

  computed: {
    paymentDateErrors() {
      return [];
    },

    isManualPayment() {
      return (this.stlmt.paymentAccountId !== null && this.stlmt.paymentAccountId === -1);
    },

    confirmDisabled() {
      return !this.stlmt.settlementAmount
        || !this.stlmt.paymentAccountId
        || !this.stlmt.firstPaymentDate;
    },
  },

  watch: {
    'stlmt.paymentAccountId': {
      async handler(newVal) {
        if (newVal !== null && this.stlmt.firstPaymentDate !== null) {
          this.paymentAccountError = '';
          this.paymentDateError = '';
          this.checkNextPaymentDate(this.stlmt.firstPaymentDate);
        }
      },
    },
    'stlmt.firstPaymentDate': {
      async handler(paymentDate, prevValue) {
        // make sure we change only when the value changes
        if (paymentDate !== prevValue) {
          // if the payment schedule has changed, we may need to send it again
          this.pmtRefresh += 1;
        }

        if (paymentDate !== null && this.stlmt.paymentAccountId !== null) {
          this.checkNextPaymentDate(this.stlmt.firstPaymentDate);
        }
      },
    },
    refreshKey: {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        // if the key value has changed, we need to reset the form
        this.resetFormValues();
      },
    },
  },

  created() {
    this.resetFormValues();
  },

  deactivated() {
    this.$v.$reset(); // reset form validation
    this.submitted = false; // make sure error don't show on initial load
  },

  methods: {
    ...mapActions('settlement', ['checkPaymentDate', 'checkManualPaymentDate']),
    async onSubmit() {
      this.submitted = true;

      const checkPaymentFunction = (this.isManualPayment) ? 'checkManualPaymentDate' : 'checkPaymentDate';

      if (!this.$v.$invalid) {
        await this[checkPaymentFunction]({
          paymentAccountId: this.stlmt.paymentAccountId,
          paymentDate: this.stlmt.firstPaymentDate,
          singlePayment: true,
        })
          .then(async (resp) => {
            // passed check
            if (resp.data === true) {
              const stlmt = {
                ...this.stlmt,
                paymentFrequency: '',
                paymentAmount: this.stlmt.settlementAmount,
              };
              this.$emit('form-submit', stlmt);
            } else {
              this.paymentDateError = resp.data;
            }
          })
          .catch(() => { this.paymentDateError = 'payment date validation error'; });
      }
    },

    /**
     * Check Next payment date for manual and autopay.
     * @param paymentDate
     */
    checkNextPaymentDate(paymentDate) {
      if (this.isManualPayment) {
        this.checkNextManualPaymentDate(paymentDate);
      } else {
        this.checkNextAutopayPaymentDate(paymentDate);
      }
    },

    /**
     * Check Manual payment date
     * @param paymentDate
     */
    async checkNextManualPaymentDate(paymentDate) {
      this.isCheckingPaymentDate = true;
      await this.checkManualPaymentDate({ paymentDate })
        .then((response) => {
          if (response.data === true) {
            this.paymentDateError = null;
            this.$refs.singlePaymentForm.resetValidation();
          } else {
            this.paymentDateError = response.data;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.paymentDateError = 'payment date validation error';
          this.isLoading = false;
        });
      this.isCheckingPaymentDate = false;
    },

    /**
     * Check Autopay payment date.
     * @param paymentDate
     */
    async checkNextAutopayPaymentDate(paymentDate) {
      this.isCheckingPaymentDate = true;
      await this.checkPaymentDate({
        paymentAccountId: this.stlmt.paymentAccountId,
        paymentDate,
        singlePayment: true,
      })
        .then((resp) => {
          // passed check
          if (resp.data === true) {
            this.paymentDateError = null;
            this.$refs.singlePaymentForm.resetValidation();
          } else {
            this.paymentDateError = resp.data;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.paymentDateError = 'payment date validation error';
          this.isLoading = false;
        });
      this.isCheckingPaymentDate = false;
    },

    resetFormValues() {
      this.stlmt.settlementAmount = 0;
      this.stlmt.firstPaymentDate = null;
      this.stlmt.paymentAccountId = null;
    },
  },

  validations: {
    stlmt: {
      settlementAmount: {
        required,
        positiveNonZero: (v) => v > 0,
      },
      firstPaymentDate: {
        required,
      },
    },
  },
};
</script>

<style>
</style>
