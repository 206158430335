import _orderBy from 'lodash/orderBy';
import _first from 'lodash/first';

const initialState = () => ({
  menus: [
    {
      label: 'App Settings',
      icon: 'mdi-account-group',
      route: 'app-settings',
      permission: 'view system-settings::app-settings',
    },
    {
      label: 'Brand Settings',
      icon: 'mdi-account-group',
      route: 'brand-settings',
      permission: 'view system-settings::brand-settings',
    },

    {
      label: 'Debt Company Settings',
      icon: 'mdi-account-group',
      route: 'debt-company-settings',
      permission: 'view system-settings::brand-settings',
    },
    {
      label: 'Cronjobs',
      icon: 'mdi-clock-outline',
      route: 'cronjobs',
      permission: 'view system-settings::cron-jobs',
    },
    {
      label: 'Monitors',
      icon: 'mdi-clock-outline',
      route: 'monitors',
      permission: 'view system-settings::monitors',
    },
    {
      label: 'Adverse Actions',
      icon: 'mdi-account-group',
      route: 'adverse-action',
      permission: 'view system-settings::adverse-action',
    },
    {
      label: 'Waterfall Filters',
      icon: 'mdi-account-group',
      route: 'waterfall-filter',
      permission: 'view system-settings::waterfall-filters',
    },
    {
      label: 'Slack Channels',
      icon: 'mdi-account-group',
      route: 'slack-channels',
      permission: 'view system-settings::slack-channels',
    },
    {
      label: 'Tracked Jobs',
      icon: 'mdi-account-group',
      route: 'tracked-jobs',
      permission: 'view system-settings::tracked-jobs',
    },
    {
      label: 'Third Party Services',
      icon: 'mdi-account-group',
      route: 'third-party-service-health',
      permission: 'view system-settings::third-party-health',
    },
  ],
});

const getters = {
  getMenus(state) {
    return _orderBy(state.menus, 'label');
  },
  getFirstItemRouteName(state) {
    return (_first(_orderBy(state.menus, 'label'))).route;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
};
