const initialState = {
  notification: '',
};

const actions = {
  setNotification({ commit }, notification) {
    commit('SET_NOTIFICATION', notification);
  },
};

const mutations = {
  SET_NOTIFICATION(state, notification) {
    state.notification = notification;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
