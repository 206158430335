<template>
  <div class="lead-info">
    <h4>{{ sectionTitle }}</h4>
    <ProspectInfo
      :name="'Final Result:'"
      :value="lead.finalResult || 'N/A'"
      :custom-class="classSelected"
    />

    <ProspectInfo
      :value="lead.finalDisposition || 'N/a'"
      :name="'Final Disposition:'"
    />
    <ProspectInfo
      :value="lead.guid || 'N/a'"
      :name="'Guid:'"
    />
    <ProspectInfo
      :value="lead.lead_guid || 'N/a'"
      :name="'Lead Guid:'"
    />
  </div>
</template>

<script>
import ProspectInfo from './ProspectInfo.vue';

export default {
  name: 'LeadInfo',

  components: {
    ProspectInfo,
  },

  props: {
    lead: {
      required: true,
      type: Object,
    },

    sectionTitle: {
      required: false,
      default: '',
      type: String,
    },
  },

  computed: {
    classSelected() {
      switch (this.lead.finalResult) {
        case ('accepted'):
          return 'ft-success';
        case ('declined'):
          return 'ft-danger';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>

</style>
