<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          large
          block
          v-on="on"
        >
          Original Payment Schedule
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Original Payment Schedule
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="formattedPaymentSchedules"
            :items-per-page="10"
            class="elevation-1"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { formatDate } from '@/services/HelperService';

export default {
  name: 'PaymentSchedule',
  props: {
    loanId: {
      type: Number,
      required: false,
      default: null,
    },
    loanType: {
      type: String,
      required: false,
      default: 'installment',
    },
  },
  data() {
    let tableHeaders = [
      { text: 'Title', value: 'title' },
      { text: 'Principal Balance', value: 'principal_balance' },
      { text: 'charge amount', value: 'charge_amount' },
      { text: 'adb', value: 'adb' },
      { text: 'adb days', value: 'adb_days' },
      { text: 'Period', value: 'period' },
      { text: 'Period Start', value: 'period_start' },
      { text: 'Period End', value: 'period_end' },
      { text: 'Date', value: 'date' },
      { text: 'Last updated', value: 'lastUpdated' },
    ];

    if (this.loanType !== 'loan.type.installment') {
      tableHeaders = [
        { text: 'Title', value: 'display_id' },
        { text: 'Balacne', value: 'new_balance' },
        { text: 'Payment Amount', value: 'payment_amount' },
        { text: 'Fee Amount', value: 'fee_amount' },
        { text: 'adb', value: 'adb' },
        { text: 'Days in Cycle', value: 'days_in_cycle' },
        { text: 'Period Start', value: 'period_start' },
        { text: 'Period End', value: 'period_end' },
        { text: 'Date', value: 'created_at' },
        { text: 'Updated', value: 'updated_at' },

      ];
    }

    return {
      dialog: false,
      headers: tableHeaders,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['paymentSchedules']),

    formattedPaymentSchedules() {
      return (this.paymentSchedules || []).map((pSchedule) => ({
        ...pSchedule,
        period_start: formatDate(pSchedule.period_start),
        period_end: formatDate(pSchedule.period_end),
        created_at: formatDate(pSchedule.created_at),
        updated_at: formatDate(pSchedule.updated_at),
        date: formatDate(pSchedule.date),
        lastUpdated: formatDate(pSchedule.lastUpdated),
      }));
    },
  },
};
</script>
