<template>
  <v-container fluid>
    <v-row
      class="ticket-show"
    >
      <v-col
        cols="12"
      >
        <TicketSummary
          v-if="Object.keys(ticket).length > 0"
          :model-data="ticket"
          :autopays="autopays"
          :advances="mappedAdvances"
        />
      </v-col>
    </v-row>
    <v-row
      class="ticket-show"
    >
      <v-col
        v-if="allowAccess"
        :cols="allowAccess ? actionButtonsMenu.sidebar : 12"
        :class="actionButtonsMenu.sidebarDisplay"
      >
        <TicketShowActions
          :ticket="ticket"
        />
      </v-col>
      <v-col
        v-if="allowAccess"
        :cols="actionButtonsMenu.routerViewCol"
      >
        <router-view
          v-if="customer"
          :ticket="ticket"
          :autopays="autopays"
          :payments="payments"
          :payment-schedules="paymentSchedules"
          :customer="customer"
          :payment-profiles="paymentProfiles"
        />
      </v-col>
      <v-col
        v-else-if="!loading"
        cols="12"
      >
        <v-card>
          <v-card-text>
            <v-alert
              type="warning"
            >
              This Ticket belongs to someone else, you do not have access to it!
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <v-card>
          <v-card-text
            class="text-center"
          >
            <v-progress-circular
              color="primary"
              indeterminate
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import TicketSummary from './TicketSummary/Index.vue';
import TicketShowActions from './TicketShowActions.vue';

export default {
  name: 'TicketShow',
  components: {
    TicketSummary,
    TicketShowActions,
  },
  data() {
    return {
      key: 0,
      paymentProfileHolder: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters('ticket', ['actionButtonsMenu']),
    ...mapState('ticket', ['ticket', 'allowAccess']),
    ...mapState('loanProCustomer', ['customer', 'paymentProfiles']),
    ...mapState('loanProLoan', ['autopays', 'payments', 'paymentSchedules']),
    ...mapState('advances', ['advances']),
    mappedAdvances() {
      return this.advances || [];
    },
    // customerPaymentProfiles: {
    //   get() {
    //     return this.paymentProfiles || this.customer?.paymentProfiles
    //       || this.ticket?.lpCustomer?.paymentProfiles || this.paymentProfileHolder;
    //   },
    //   set(newVal) {
    //     this.paymentProfileHolder = newVal;
    //   },
    // },
  },
  watch: {
    async ticket(val) {
      if (Object.keys((val.active_loan || {})).length > 0) {
        await this.setActiveLoan(this.ticket.active_loan);
        await this.setCustomer(this.ticket.lpCustomer);
        await this.getPaymentProfiles();
        await this.getAutopays();
        await this.getPaymentSchedule();
        await this.getPayments();
        if ((this.ticket?.active_loan?.loan_setup?.loan_type || '') === 'loan.type.creditLimit') {
          // eslint-disable-next-line max-len
          await this.getAdvances({ ticketId: this.$route.params.id, loanId: this.ticket.active_loan.id });
        }
        // Force update since computed cannot watch customer.paymentProfiles or ticket.lpCustomer
        // since in Vue.js sub-properties have to exist when the computed property is defined.
        // Vue cannot detect property addition or deletion, only changes to existing properties.
        // this.customerPaymentProfiles = this.ticket?.lpCustomer?.paymentProfiles || [];
      }
    },
  },
  async created() {
    await this.verifyTicketOwnerStatus(this.$route.params.id);
    await this.getTicket(this.$route.params.id);
    this.loading = false;
  },
  methods: {
    ...mapActions('ticket', ['getTicket', 'verifyTicketOwnerStatus']),
    ...mapActions('loanProCustomer', ['setCustomer', 'getPaymentProfiles']),
    ...mapActions('loanProLoan', ['getAutopays', 'getPaymentSchedule', 'getPayments', 'setActiveLoan']),
    ...mapActions('notification', ['setNotification']),
    ...mapActions('advances', ['getAdvances']),
  },
};
</script>
