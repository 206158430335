<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="1000px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasPermission('view prescreen::prospect')"
          v-bind="attrs"
          dark
          class="py-6 btn-bg-deep-blue"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
          Assign Prospects
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          text=""
          icon="mdi-information-outline"
          :text-button="true"
          permission="view prescreen::prospect"
          :x-small="false"
          button-class="mr-10"
        />
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Intermediate File Assignment
        </v-card-title>

        <v-card-text class="pa-5">
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>Assignment Criteria</v-card-title>
                <v-card-text>
                  <v-row
                    v-for="(criteria, index) in assignmentCriterias"
                    :key="`criteria-${index}`"
                    class="mt-0"
                  >
                    <v-col class="py-0">
                      <v-select
                        v-model="assignmentCriterias[index]['prescreen_campaign_id']"
                        :items="campaigns"
                        item-value="id"
                        item-text="name"
                        label="campaign"
                      />
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="assignmentCriterias[index]['percentage']"
                        label="percentage"
                        type="number"
                      />
                    </v-col>
                    <v-col class="py-0">
                      <v-select
                        v-model="assignmentCriterias[index]['states']"
                        :items="states"
                        item-text="state_abbr"
                        item-value="state_abbr"
                        multiple
                        label="states"
                      />
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="assignmentCriterias[index]['min_credit_score']"
                        label="Min Score"
                        type="number"
                      />
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="assignmentCriterias[index]['max_credit_score']"
                        label="Max Score"
                        type="number"
                      />
                    </v-col>
                    <v-col class="col-1 pb-0 pt-7">
                      <v-icon
                        v-if="index != 0"
                        color="red"
                        @click="deleteCriteria(index)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    @click="insertNewCriteria"
                  >
                    Add criteria
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    class="btn-bg-deep-blue"
                    :loading="assignBtnLoading"
                    @click="assignProspects"
                  >
                    Assign Prospects
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel :key="1">
                  <v-expansion-panel-header>
                    Total Assigned Vs Unassigned
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Total Prospects
                            </th>
                            <th class="text-left">
                              Total Assigned
                            </th>
                            <th class="text-left">
                              Total Unassigned
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ stats.totalProspects }}</td>
                            <td>{{ stats.totalAssigned }}</td>
                            <td>{{ stats.totalUnassigned }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :key="2">
                  <v-expansion-panel-header>
                    Total By States
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              State
                            </th>
                            <th class="text-left">
                              Total Assigned
                            </th>
                            <th class="text-left">
                              Total Unassigned
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="state in stats.groupByState"
                            :key="`intermediate-file-stat-${state.state_cd}`"
                          >
                            <td>{{ state.state_cd }}</td>
                            <td>{{ state.num_assigned }}</td>
                            <td>{{ state.num_unassigned }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :key="3">
                  <v-expansion-panel-header>
                    Total By campaign
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              ID
                            </th>
                            <th class="text-left">
                              Campaign Name
                            </th>
                            <th class="text-left">
                              Campaign Code
                            </th>
                            <th class="text-left">
                              Max Loan Amount
                            </th>
                            <th class="text-left">
                              Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="campaign in stats.groupByCampaign"
                            :key="`groupbycampaign-${campaign.id}`"
                          >
                            <td>{{ campaign.id }}</td>
                            <td>{{ campaign.name }}</td>
                            <td>{{ campaign.campaign_code }}</td>
                            <td>{{ campaign.max_loan_amount }}</td>
                            <td>{{ campaign.count }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :key="4">
                  <v-expansion-panel-header>
                    Total By Credit Scores
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Campaign ID
                            </th>
                            <th class="text-left">
                              Campaign Name
                            </th>
                            <th class="text-left">
                              Min Credit Score
                            </th>
                            <th class="text-left">
                              Max Credit Score
                            </th>
                            <th class="text-left">
                              Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="campaign in stats.groupByCreditScoreRange"
                            :key="`groupbycreditscore-${campaign.id}`"
                          >
                            <td>{{ campaign.id }}</td>
                            <td>{{ campaign.name }}</td>
                            <td>{{ campaign.min_credit_score }}</td>
                            <td>{{ campaign.max_credit_score }}</td>
                            <td>{{ campaign.count }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :key="5">
                  <v-expansion-panel-header>
                    Total By Print Expire Date
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Print Expire Date
                            </th>
                            <th class="text-left">
                              Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="campaign in stats.groupByPrintExpireDate"
                            :key="`groupbyprintexpiredate-${campaign.print_expire_date}`"
                          >
                            <td>{{ campaign.print_expire_date }}</td>
                            <td>{{ campaign.count }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :key="6">
                  <v-expansion-panel-header>
                    Total By System Expire Date
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              System Expire Date
                            </th>
                            <th class="text-left">
                              Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="campaign in stats.groupBySystemExpireDate"
                            :key="`groupbysystemexpiredate-${campaign.system_expire_date}`"
                          >
                            <td>{{ campaign.system_expire_date }}</td>
                            <td>{{ campaign.count }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    fileId: {
      type: Number,
      required: true,
    },
    states: {
      type: Array,
    },
    campaigns: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      stats: [],
      assignmentCriterias: [],
      assignBtnLoading: false,
    };
  },
  created() {
    this.insertNewCriteria();
    this.getStats();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    insertNewCriteria() {
      this.assignmentCriterias.push({
        prescreen_campaign_id: null,
        percentage: null,
        states: [],
        min_credit_score: null,
        max_credit_score: null,
      });
    },
    deleteCriteria(index) {
      this.$delete(this.assignmentCriterias, index);
    },
    getStats() {
      this.$axios
        .get(`/api/v1/prescreen/files/${this.fileId}/intermediate/stats`)
        .then((res) => {
          this.stats = res.data;
        });
    },
    assignProspects() {
      this.assignBtnLoading = true;
      this.$axios
        .post(`/api/v1/prescreen/files/${this.fileId}/intermediate/assign-campaign`, {
          assignments: this.assignmentCriterias,
        })
        .then((res) => {
          this.assignBtnLoading = false;
          this.setNotification({
            message: res.data,
            color: 'success',
          });
          this.getStats();
        })
        .catch((error) => {
          this.assignBtnLoading = false;

          let msg = error.response.data.message;

          if (error.response.status === 422) {
            if (error.response.data.errors) {
              Object.values(error.response.data.errors).forEach((val) => {
                msg += `\n ${val}`;
              });
            }
          }

          this.setNotification({
            message: msg,
            color: 'red',
          });
        });
    },
  },
};
</script>
