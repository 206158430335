<template>
  <div class="address-section">
    <h4>{{ sectionTitle }}</h4>
    <ProspectInfo
      :name="'Address:'"
      :value="prospect.streetAddress"
    />

    <ProspectInfo
      v-if="prospect.streetAddress2 && prospect.streetAddress2.length"
      :name="'Address 2:'"
      :value="prospect.streetAddress2"
    />

    <ProspectInfo
      :name="'City:'"
      :value="prospect.city"
    />

    <ProspectInfo
      :name="'State:'"
      :value="prospect.state"
    />

    <ProspectInfo
      :name="'Zip:'"
      :value="prospect.zip"
    />
  </div>
</template>

<script>
import ProspectInfo from './ProspectInfo.vue';

export default {
  name: 'ProspectAddress',
  components: {
    ProspectInfo,
  },

  props: {
    prospect: {
      required: true,
      type: Object,
    },

    sectionTitle: {
      required: false,
      default: '',
      type: String,
    },
  },
};
</script>

<style scoped>

</style>
