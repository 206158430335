<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    @click:outside="close()"
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">Confirm Delete</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <v-alert
              color="red darken-4"
              dark
            >
              Are you sure you want to delete this company {{ deleteItem.name }}?
            </v-alert>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="red darken-1"
          text
          :disabled="formProcessing"
          @click="deleteCompany()"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  props: {
    deleteForm: {
      type: Boolean,
      default: false,
    },
    deleteItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      companySelected: {},
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.deleteForm;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    deleteItem(value) {
      this.companySelected = {
        ...value,
      };
    },
  },
  methods: {
    async deleteCompany() {
      if (this.$refs.form.validate()) {
        this.formProcessing = true;

        const data = {
          ...this.companySelected,
        };

        try {
          await this.$axios.delete(`/api/v1/debt-company/${this.companySelected.id}`, data);

          this.$emit('item-saved');

          this.setNotification({
            message: `Company ${this.companySelected.name} successfully deleted  .`,
            color: 'green',
          });
        } catch (err) {
          this.setNotification({
            message: err,
            color: 'red',
          });
        }

        this.formProcessing = false;
        this.close();
      }
    },
    close() {
      this.$emit('close-modal');
    },
    ...mapActions('notification', ['setNotification']),
  },
};
</script>
<style scoped>
  .title-span {
    border-bottom: 1px solid #5b5b60;
  }
</style>
