<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    @click:outside="close()"
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ capitalizeFirstLetter(newStatus) }} OFAC Email?</span>
      </v-card-title>

      <v-card-text>
        <p>Are you sure you want to <strong>{{ newStatus }}</strong> {{ editedItem.email }}?</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="$emit('close-modal')"
        >
          Cancel
        </v-btn>

        <v-btn
          :color="(newStatus === 'activate') ? 'green' : 'red'"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          {{ newStatus }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OfacMonitoringEmailNotificationSettingsStatusModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      id: null,
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    newStatus() {
      return this.editedItem.active ? 'deactivate' : 'activate';
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$axios
        .put(`/api/v1/ofac-email/${this.editedItem.id}`, {
          active: this.editedItem.active ? 0 : 1,
          email: this.editedItem.email,
        })
        .then(() => {
          this.$emit('item-saved');

          this.setNotification({
            message: `OFAC Email status has been updated to ${this.newStatus}.`,
            color: 'green',
          });

          this.close();
        });
    },
    close() {
      this.$emit('close-modal');
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style></style>
