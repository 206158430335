<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>User Roles</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />
          <v-spacer />
          <UserGroupDeleteModal
            v-model="dialogDelete"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogDelete')"
            @item-deleted="itemDeleted()"
            @click:outside="clearState()"
          />
          <UserGroupFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />
          <v-btn
            v-if="hasPermission('create user-admin::role')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Roles
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Roles"
            permission="create user-admin::role"
            :x-small="false"
            button-class="mb-2"
          />
        </v-toolbar>
      </v-card-title>
    </template>
    <template v-slot:item.routes="{ item }">
      {{ groupRoutePermission(item.name) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('edit user-admin::role')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="edit user-admin::role"
        :x-small="false"
        button-class="mr-10"
      />
      <!--      <v-icon
        class="red&#45;&#45;text"
        hide
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>-->
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import dataTableMixin from '@/mixins/dataTableMixin';
import UserGroupDeleteModal from '@/views/UserAdmin/UserGroup/UserGroupDeleteModal.vue';
import UserGroupFormModal from '@/views/UserAdmin/UserGroup/UserGroupFormModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    UserGroupFormModal,
    UserGroupDeleteModal,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Group ID', value: 'id' },
      { text: 'Role', value: 'name' },
      { text: 'Guard', value: 'guard_name', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],

    dialogDelete: false,
    dialogForm: false,
    editedItem: {
      id: null,
    },
  }),
  computed: {
    ...mapState('userPermission', ['roles', 'permissions']),
    ...mapGetters('userPermission', ['formattedPermissions']),
  },
  created() {
    this.getPermissions();
  },
  methods: {
    ...mapActions('userPermission', ['getRoles', 'getPermissions']),
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemDeleted() {
      this.items = [];
      this.updateTable();
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    async updateTable() {
      this.loading = true;

      if (this.options.sortBy.length === 0) {
        this.options.sortBy.push('name');
        this.options.sortDesc = [false];
      }

      await this.getRoles({
        perPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        search: this.search,
      });
      this.items = this.roles.data;
      this.totalItems = this.roles.total;

      this.loading = false;
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
