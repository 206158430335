<template>
  <v-container>
    <v-row
      v-for="(element, index) in payloadVars"
      :key="index"
    >
      <v-col
        :cols="columnSize(index)"
        :md="columnSize(index)"
      >
        <v-text-field
          v-model="element.name"
          required
          :label="'Name'"
          :disabled="index < currentIndex"
        />
      </v-col>
      <v-col
        :cols="columnSize(index)"
        :md="columnSize(index)"
      >
        <v-text-field
          v-model="element.value"
          required
          :label="'Value'"
          :disabled="index < currentIndex"
        />
      </v-col>
      <v-col
        v-if="columnSize(index) === 4"
      >
        <v-btn
          dark
          class="mt-6 btn-bg-alert-red"
          text
          @click="removeValue(index)"
        >
          Remove
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="d-flex justify-end"
        cols="12"
        md="12"
      >
        <v-btn
          dark
          class="btn-bg-deep-blue"
          text
          @click="addValues()"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="Object.keys(dataTransformed).length">
      <h2>
        Payload Preview
      </h2>
      <v-col
        cols="12"
        md="12"
      >
        <VueJsonPretty
          :data="dataTransformed"
          :show-line="false"
          :show-double-quotes="false"
          virtual
          :height="100"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'JsonTextField',
  components: {
    VueJsonPretty,
  },

  data() {
    return {
      payloadVars: [],
      currentIndex: 0,
    };
  },

  computed: {
    dataTransformed() {
      const transformedData = {};

      this.payloadVars.forEach((payload) => {
        if (payload.name.length) {
          transformedData[payload.name] = payload.value;
        }
      });

      return transformedData;
    },
  },

  watch: {
    dataTransformed: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
    '$attrs.value': {
      deep: true,
      handler(value) {
        if (Object.keys(value).length) {
          Object.entries(value).forEach((currentPair) => {
            this.payloadVars.unshift({ name: currentPair[0], value: currentPair[1] });
            this.currentIndex += 1;
          });
        }
      },
    },
  },

  mounted() {
    this.payloadVars.push({ name: '', value: '' });
  },

  methods: {
    addValues() {
      this.payloadVars.push({ name: '', value: '' });
      this.currentIndex += 1;
    },

    removeValue(index) {
      this.payloadVars.splice(index, 1);
      this.currentIndex -= 1;
    },

    columnSize(index) {
      return (index < this.currentIndex) ? 4 : 6;
    },
  },
};
</script>

<style scoped>

</style>
