<template>
  <v-dialog
    v-model="enableRecurringAutopayModal"
    max-width="500px"
    @click:outside="enableRecurringAutopayModal = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="hasPermission('view ticket::cancel-payment')"
        class="mr-2 btn-bg-regal-gray"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
        Enable Recurring Autopay
      </v-btn>
      <disabled-for-lack-of-role-btn
        v-else
        icon="mdi-pencil-outline"
        text="Edit"
        permission="view ticket::cancel-payment"
        :x-small="true"
        button-class="mr-2"
      />
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Enable Recurring Autopay</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
            >
              Autopay Information
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
            >
              Review & Electronic Disclosure
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      class="mb-0"
                    >
                      <p>
                        To enable automatic payment, select a payment method
                        and agree to electronic disclosure
                      </p>
                      <v-select
                        v-model="paymentProfile"
                        :items="paymentProfiles"
                        item-value="id"
                        item-text="title"
                        label="Payment Method"
                        outlined
                        required
                        :class="{ 'is-invalid': $v.paymentProfile.$error }"
                        :error-messages="paymentProfileErrors"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :loading="loading"
                    @click="validateAutopay"
                  >
                    Continue
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <p v-if="paymentProfile">
                        Payment Method: {{ paymentProfile ?
                          paymentProfiles.find(p => p.id === paymentProfile).title : ''
                        }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <disclosure-modal />
                    </v-col>
                    <v-col cols="12">
                      <p>
                        YOU MUST E-MAIL THE ELECTRONIC PAYMENT DISCLOSURES TO
                        THE CUSTOMER BEFORE CHECKING THE ACH CONSENT BOX BELOW.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="electronicDisclosureConsent"
                        class="mt-n7"
                        :disabled="disclosuresSent === false"
                      >
                        <template v-slot:label>
                          <div
                            class="mt-13"
                          >
                            <p>
                              Agents: Please read the following to the customer
                              and gain verbal authorization before proceeding.
                            </p>
                            <p>
                              "Please acknowledge you have received,
                              read, and consent to the ACH authorization."
                            </p>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    @click="e1 = 1"
                  >
                    Back
                  </v-btn>

                  <v-spacer />

                  <v-btn
                    :disabled="electronicDisclosureConsent === false"
                    color="primary"
                    :loading="loading"
                    @click="enableRecurringPayment"
                  >
                    Enable Recurring Payment
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';
import DisclosureModal from '../../LoanSettlement/DisclosureModal.vue';

export default {
  name: 'EnableRecurringAutopay',
  components: {
    DisabledForLackOfRoleBtn,
    DisclosureModal,
  },
  data() {
    return {
      enableRecurringAutopayModal: false,
      loading: false,
      paymentProfile: null,
      e1: 1,
      electronicDisclosureConsent: false,
    };
  },
  validations() {
    return {
      paymentProfile: { required },
    };
  },
  computed: {
    ...mapState('loanProCustomer', ['paymentProfiles']),
    ...mapState('settlement', ['disclosuresSent']),
    ...mapState('loanProLoan', ['activeLoan', 'autopays']),
    ...mapGetters('loanProLoan', ['paymentTypes', 'recurringAutopays']),

    paymentProfileErrors() {
      const errors = [];

      if (!this.$v.paymentProfile.$dirty) {
        return errors;
      }
      if (!this.$v.paymentProfile.required) {
        errors.push('Payment method is required.');
      }
      return errors;
    },
  },
  methods: {
    ...mapActions('autopays', ['updateAutopayPayment', 'createLoanRecurringAutopay', 'updateSelectedAutopayPayment']),
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProLoan', ['getAutopays']),

    validateAutopay() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.e1 = 2;
      }
    },
    async enableRecurringPayment() {
      this.loading = true;
      let response;
      // if there are existing recurring autopays, update their payment method
      if (this.recurringAutopays.length > 0) {
        const paymentProfile = this.paymentProfiles.find((pp) => pp.id === this.paymentProfile);
        response = await this.updateSelectedAutopayPayment({
          loanId: this.activeLoan.id,
          selectedAccount: paymentProfile,
          autopays: this.recurringAutoPays,
        });
      } else {
        response = await this.createLoanRecurringAutopay({
          loanId: this.activeLoan.id,
          paymentAccountId: this.paymentProfile,
        });
      }

      this.loading = false;

      // get autopays
      this.getAutopays();

      if (response && response.data === true) {
        this.setNotification({
          color: 'green',
          message: 'Recurring Autopay has been enabled for selected payment method',
        });
      } else {
        this.setNotification({
          color: 'red',
          message: 'Recurring Autopay cannot be enabled for selected payment method',
        });
      }

      this.enableRecurringAutopayModal = false;
    },
  },
};
</script>
