<template>
  <div
    class="costForm"
  >
    <v-row class="text-right">
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          elevation="2"
          dark
          color="primary"
          @click="cancelAdd()"
        >
          Back to Cost List
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="cost"
          required
          label="Cost"
          placeholder="0.00"
          prepend-icon="mdi-currency-usd"
          :error-messages="costErrors"
          @input="$v.cost.$touch()"
          @blur="$v.cost.$touch()"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      />
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="startDateFormatted"
          label="Pick the start date"
          hint="MM/DD/YYYY format"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          :error-messages="errors.startDate"
          @blur="startDate = parseDate(startDateFormatted)"
        />
        <v-date-picker
          v-model="startDate"
          :max="endDate"
          :type="'date'"
          light
          no-title
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="endDateFormatted"
          elevation="2"
          label="Pick the end date"
          hint="MM/DD/YYYY format"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          :error-messages="errors.endDate"
          @blur="endDate = parseDate(endDateFormatted)"
        />
        <v-date-picker
          v-model="endDate"
          :min="startDate"
          :type="'date'"
          light
          no-title
        />
      </v-col>
    </v-row>
    <v-row
      class="text-right"
    >
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          elevation="2"
          dark
          color="primary"
          :disabled="!ableToAdd"
          @click="addEffectiveCostDate"
        >
          Add Effective Date
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { decimal, required } from 'vuelidate/lib/validators';

export default {
  name: 'ScoringElementFormCost',

  props: {
    scoringElement: {
      type: Object,
      required: true,
    },
  },

  data: (vm) => ({
    cost: 0.00,
    startDate: '',
    endDate: '',
    startDateFormatted: vm.formatDate(
      (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substr(0, 10),
    ),
    endDateFormatted: vm.formatDate(
      (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substr(0, 10),
    ),
    adding: false,
    errors: {},
  }),

  computed: {
    haveError() {
      return Object.keys(this.errors).length;
    },

    costErrors() {
      const errors = [];

      if (!this.$v.cost.$dirty) {
        return errors;
      }
      if (!this.$v.cost.required) {
        errors.push('Cost is required.');
      }
      if (!this.$v.cost.decimal) {
        errors.push('Cost must be a number.');
      }
      return errors;
    },

    ableToAdd() {
      return !!(this.cost !== 0.00 && this.startDate && this.endDate);
    },
  },

  watch: {
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate() {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  methods: {
    cancelAdd() {
      this.clearForm();
      this.backToList();
    },

    backToList() {
      this.$emit('changeView', 'costList');
    },

    addEffectiveCostDate() {
      this.adding = false;
      this.$axios.post('/api/v1/scoring-element-cost', {
        scoringElementId: this.scoringElement.id,
        cost: this.cost,
        startDate: this.startDate,
        endDate: this.endDate,
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            const { data } = response;
            this.$emit('newItem', data.data);
            this.backToList();
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          }
        });
    },

    clearForm() {
      this.loading = false;
      this.activeView = 'costList';
      this.cost = 0.00;
      this.startDate = '';
      this.endDate = '';
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
  },

  validations: {
    startDate: { required },
    endDate: { required },
    cost: { required, decimal },
  },
};
</script>

<style scoped>

</style>
