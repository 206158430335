<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title
            v-if="$route.query.id"
          >
            OFAC Upload #{{ $route.query.id }} Matches
          </v-toolbar-title>
          <v-toolbar-title v-else>
            OFAC Review All Matches
          </v-toolbar-title>
          <v-spacer />
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="btn-bg-deep-blue"
                dark
                small
                tile
                v-bind="attrs"
                v-on="on"
              >
                Filter
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'ofac-matches', query: { id: $route.query.id } }"
                exact
              >
                <v-list-item-title>All</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'ofac-matches', query: { id: $route.query.id, status: 'open' } }"
                exact
              >
                <v-list-item-title>Outstanding</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'ofac-matches', query: { id: $route.query.id, status: 'confirmed' } }"
                exact
              >
                <v-list-item-title>Confirmed</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'ofac-matches', query: { id: $route.query.id, status: 'false' } }"
                exact
              >
                <v-list-item-title>False</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-card-title>

      <OfacMatchesModal
        v-model="matchesModal"
        :item="matchesItem"
        @item-saved="itemSaved()"
        @close-modal="closeMatch()"
        @click:outside="closeMatch()"
      />
    </template>

    <template v-slot:item.created_on="{ item }">
      {{ formatDateTime(item.created_on) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        v-if="hasPermission('edit ofac::match')"
        color="primary"
        dark
        small
        tile
        @click="showMatch(item)"
      >
        Review
      </v-btn>
      <disabled-for-lack-of-role-btn
        v-else
        text="Review"
        permission="edit ofac::match"
      />
    </template>
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';

import OfacMatchesModal from '@/views/OfacMonitoring/OfacMatches/OfacMatchesModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'OfacMatchesIndex',
  components: {
    OfacMatchesModal,
    DisabledForLackOfRoleBtn,
  },
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Upload ID', value: 'upload_id' },
      { text: 'Name', value: 'full_name', sortable: false },
      { text: 'Address', value: 'ofac_data.inquiry.street_address_1', sortable: false },
      { text: 'City', value: 'ofac_data.inquiry.city', sortable: false },
      { text: 'State', value: 'ofac_data.inquiry.state', sortable: false },
      { text: 'ZIP', value: 'ofac_data.inquiry.zip', sortable: false },
      { text: 'DOB', value: 'ofac_data.inquiry.date_of_birth', sortable: false },
      { text: 'Timestamp', value: 'created_on' },
      { text: 'Status', value: 'status.status_name', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    matchesItem: {},
    matchesModal: false,
  }),
  watch: {
    '$route.query': {
      handler: 'updateTable',
    },
  },
  methods: {
    closeMatch() {
      this.matchesModal = false;
      this.$nextTick(() => {
        this.matchesItem = {};
      });
    },
    showMatch(item) {
      this.matchesItem = { ...item };
      this.matchesModal = true;
    },
    itemSaved() {
      this.updateTable();
    },
    updateTable() {
      this.loading = true;
      return this.$axios
        .get('api/v1/ofac-match/', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            uploadId: this.$route.query.id,
            status: this.$route.query.status,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data
            .map((item) => {
              const transformed = { ...item };
              let dob = DateTime.fromISO(transformed.date_of_birth).toFormat('MM-dd-yyyy');
              dob = dob.replace(/.(?=.{4})/g, '*');
              const ssn = transformed.social_security_number.replace(/.(?=.{4})/g, '*');
              const dlnum = transformed.drivers_license_number.replace(/.(?=.{4})/g, '*');
              const banknum = transformed.bank_account_number.replace(/.(?=.{4})/g, '*');
              transformed.ofac_data = JSON.parse(transformed.ofac_data);
              transformed.ofac_data.inquiry.date_of_birth = dob;
              transformed.ofac_data.inquiry.social_security_number = ssn;
              transformed.ofac_data.inquiry.drivers_license_number = dlnum;
              transformed.ofac_data.inquiry.bank_account_number = banknum;
              transformed.full_name = `${transformed.ofac_data.inquiry.first_name} ${transformed.ofac_data.inquiry.middle_initial} ${transformed.ofac_data.inquiry.last_name}`;
              transformed.full_name.replace('"', '').replace('  ', ' ');
              return transformed;
            });
          this.totalItems = res.data.total;
        });
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    },
  },
};
</script>
