<template>
  <v-dialog
    v-model="show"
    @click:outside="close()"
  >
    <v-card>
      <v-toolbar
        flat
        dark
        color="primary"
      >
        <v-toolbar-title>
          <span class="headline">{{ title }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-actions>
        <v-card-text>
          <JsonFormatterWrapper :data="json" />
        </v-card-text>
      </v-card-actions>
      <v-card-actions>
        <v-spacer />

        <v-btn
          class="mr-2 mb-2"
          color="blue darken-1"
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import JsonFormatterWrapper from '@/views/Leads/JsonFormatterWrapper.vue';

export default {
  name: 'JsonModal',
  components: {
    JsonFormatterWrapper,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return 'JSON';
      },
    },
    json: {
      default() {
        return {};
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style></style>
