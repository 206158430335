<template>
  <div id="product-assignment">
    <v-data-table
      dense
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props.sync="footerProps"
      @update:options="updateTable()"
    >
      <template v-slot:top>
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title>Product Assignment Models</v-toolbar-title>

            <v-spacer />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="searchTable()"
            />

            <v-spacer />
            <v-btn
              v-if="hasPermission('create lending-strategies::product-assignment')"
              dark
              class="mb-2 btn-bg-deep-blue"
              @click="openProductAssignmentView()"
            >
              <v-icon>mdi-plus</v-icon>
              New Model
            </v-btn>
            <disabled-for-lack-of-role-btn
              v-else
              text="New Product Assignment Model"
              permission="create lending-strategies::product-assignment"
              :x-small="false"
              button-class="mb-2"
            />
          </v-toolbar>
        </v-card-title>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="hasPermission('edit lending-strategies::product-assignment')"
          justify="space-around"
          small
          class="btn-bg-deep-blue"
          text-color="white"
          :to="{ 'name': 'product-assignment', 'params': { 'modelId': item.id }}"
        >
          Edit Model
        </v-btn>

        <disabled-for-lack-of-role-btn
          v-else
          text="Edit Model"
          permission="edit lending-strategies::product-assignment"
          :button="true"
          :chip="false"
          chip-color="error"
        />
      </template>
    </v-data-table>
    <ProductAssignmentModel
      v-if="productAssignmentViewOpen"
      :model-data="activeProductAssignment"
      @model-saved="onProductModelSaved"
    />
  </div>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';
import ProductAssignmentModel from './ProductAssignmentModel.vue';

export default {
  components: {
    DisabledForLackOfRoleBtn,
    ProductAssignmentModel,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Endpoint', value: 'endpoint' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: 160,
      },
    ],

    activeProductAssignment: null,
    productAssignmentViewOpen: false,
  }),

  watch: {
    '$route.params.modelId': {
      handler() {
        this.productAssignmentViewOpen = false;
        this.openActiveModel();
      },
      immediate: true,
    },
  },

  methods: {
    openProductAssignmentView(model = {}) {
      this.activeProductAssignment = model;
      this.productAssignmentViewOpen = true;
    },

    onProductModelSaved(modelData) {
      const index = this.items.findIndex((item) => item.id === modelData.id);

      if (index !== -1) {
        this.items.splice(index, 1, modelData);
      } else {
        this.items.push(modelData);
        this.$router.push({ name: 'product-assignment', params: { modelId: modelData.id } });
      }
    },

    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/product-assignment-model', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;

          this.openActiveModel();
        });
    },

    openActiveModel() {
      const { modelId } = this.$route.params;
      const activeModel = this.items.find((item) => Number(item.id) === Number(modelId));

      if (activeModel) {
        this.openProductAssignmentView(activeModel);
      }
    },
  },
};
</script>
