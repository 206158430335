<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    @click:outside="close()"
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">Edit {{ name }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row
              v-if="isLocal"
            >
              <v-col
                cols="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-alert
                      v-cloak
                      border="top"
                      type="warning"
                      dark
                    >
                      <p>
                        If this change should persist for proper local functionality,
                        please update the Brand seeders for all brands.
                      </p>

                      <span>BrandSeeder: "database/seeders/brands"</span>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <div v-if="type === 'array'">
                  <template
                    v-for="(line,index) in value"
                  >
                    <v-switch
                      v-if="typeof line === 'boolean'"
                      :key="`array-switch-${index}`"
                      v-model="valueArray[index]"
                      :label="value[index] ? index + ' True' : index + ' False'"
                      color="primary"
                      hide-details
                    />

                    <v-textarea
                      v-else
                      :key="`array-textarea-${index}`"
                      v-model="valueArray[index]"
                      required
                      auto-grow
                      :label="'Value ' + index"
                    />
                  </template>
                </div>
                <div
                  v-if="type === 'string' && editedItem.value.length > 100"
                >
                  <v-textarea
                    v-model="value"
                    required
                    auto-grow
                    label="Value"
                  />
                </div>
                <div v-if="type === 'string' && editedItem.value.length <= 100">
                  <v-text-field
                    v-model="value"
                    type="text"
                    required
                    auto-grow
                    label="Value"
                  />
                </div>
                <div
                  v-if="type === 'integer'"
                >
                  <v-text-field
                    v-model="value"
                    type="number"
                    required
                    auto-grow
                    label="Value"
                  />
                </div>
                <div
                  v-if="type === 'boolean'"
                >
                  <v-switch
                    v-model="value"
                    :label="value ? 'True' : 'False'"
                    color="primary"
                    hide-details
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    dialogForm: {
      type: Boolean,
      default: false,
    },
    isLocal: {
      type: Boolean,
      required: false,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          key: '',
          name: '',
          value: '',
          group: '',
          type: '',
        };
      },
    },
  },
  data() {
    return {
      valid: false,
      key: '',
      value: '',
      name: '',
      type: '',
      group: '',
      formProcessing: false,
      valueArray: {},
      arrayLabel: 'Value',
    };
  },
  computed: {
    show: {
      get() {
        return this.dialogForm;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    finalValue() {
      return this.type === 'array' ? this.valueArray : this.value;
    },
  },
  watch: {
    editedItem() {
      this.key = this.editedItem.key;
      this.type = this.editedItem.type;
      this.name = this.editedItem.name;
      this.value = this.editedItem.value;
      this.group = this.editedItem.group;

      if (this.type === 'array' && this.value) {
        Object.keys(this.value).forEach((key) => {
          this.valueArray[key] = this.value[key];
        });
      }
    },
  },
  methods: {
    ...mapActions('settings', ['updateSettings']),
    async save() {
      if (this.$refs.form.validate()) {
        this.formProcessing = true;

        const data = {
          key: this.key,
          value: this.finalValue,
          group: this.group,
        };

        try {
          await this.$axios.put('/api/v1/admin/brand-settings', data);

          this.updateSettings(data);

          this.$emit('item-saved');
          this.setNotification({
            message: `${this.name} has been updated.`,
            color: 'green',
          });
        } catch {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        }

        this.formProcessing = false;
        this.close();
      }
    },
    close() {
      this.$emit('close-modal');
      this.$refs.form.reset();
    },
    ...mapActions('notification', ['setNotification']),
  },
};
</script>
