<template>
  <v-dialog
    v-model="show"
    max-width="650px"
    @click:outside="$emit('click:outside')"
  >
    <v-card
      :loading="dialogDeleteProcessing"
    >
      <v-card-title class="headline">
        Confirm Delete
      </v-card-title>
      <v-card-text>
        Are you sure you want to delete {{ editedItem.bank_name }}?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :disabled="dialogDeleteProcessing"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="red"
          text
          :disabled="dialogDeleteProcessing"
          @click="deleteItemConfirm"
        >
          OK
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          bank_name: '',
        };
      },
    },
  },
  data() {
    return {
      dialogDeleteProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close-modal');
    },
    deleteItemConfirm() {
      this.dialogDeleteProcessing = true;
      this.$axios
        .delete(`api/v1/system-setting/${this.editedItem.id}`)
        .then(() => {
          this.$emit('item-deleted');

          this.setNotification({
            message: 'Application value has been deleted.',
            color: 'green',
          });
        })
        .catch((error) => {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });

          console.error(error);
        })
        .then(() => {
          this.dialogDeleteProcessing = false;

          this.close();
        });
    },
    ...mapActions('notification', ['setNotification']),
  },
};
</script>

<style>

</style>
