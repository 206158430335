<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <v-card-title> Compliance Report </v-card-title>
      </div>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="fromDate"
                required
                label="From Date"
                type="date"
                :error-messages="fromDateErrors"
                @input="fromDateOnInputOrBlur"
                @blur="fromDateOnInputOrBlur"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="toDate"
                required
                label="To Date"
                type="date"
                :error-messages="toDateErrors"
                @input="toDateOnInputOrBlur"
                @blur="toDateOnInputOrBlur"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="reportType"
                :items="reportTypes"
                label="Report Type"
              />
            </v-col>
          </v-row>
          <v-btn
            class="mt-5 mb-5 btn-bg-deep-blue"
            :loading="reportGenerating"
            @click="generateReport()"
          >
            Generate Report
          </v-btn>
        </v-form>
      </v-container>
    </v-card>

    <template v-if="reportLoaded">
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
      >
        <template v-slot:item.start_date="{ item }">
          {{ formatDate(item.start_date) }}
        </template>

        <template v-slot:item.end_date="{ item }">
          {{ formatDate(item.end_date) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            tile
            color="primary"
            small
            text
            @click="showJsonModal('JSON', item)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-eye
            </v-icon>
            SHOW
          </v-btn>

          <v-btn
            v-if="item.status === 'completed'"
            tile
            color="primary"
            small
            text
            @click="downloadReport(item)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-download
            </v-icon>
            CSV
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <JsonModal
      v-model="jsonModal"
      :title="jsonTitle"
      :json="json"
      @close-modal="closeJsonModal()"
      @click:outside="closeJsonModal()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';
import JsonModal from '@/components/JsonModal.vue';

const lessThanToDate = (value, vm) => (value <= vm.toDate);
const greaterThanFromDate = (value, vm) => (value >= vm.fromDate);

export default {
  name: 'ComplianceReport',
  components: {
    JsonModal,
  },
  data() {
    return {
      formProcessing: false,
      fromDate: DateTime.local().toISODate(),
      toDate: DateTime.local().toISODate(),
      reportLoaded: false,
      reportGenerating: false,
      json: {},
      jsonTitle: '',
      jsonModal: false,
      reportTypes: [],
      reportType: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Start', value: 'start_date' },
        { text: 'End', value: 'end_date' },
        { text: 'Report Type', value: 'report_type' },
        { text: 'Records', value: 'records' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'actions' },
      ],
      items: [],
      hasAdjust: null,
      adjustOptions: [
        {
          value: null,
          text: 'All',
        },
        {
          value: true,
          text: 'Yes',
        },
        {
          value: false,
          text: 'No',
        },
      ],
    };
  },
  computed: {
    fromDateErrors() {
      const errors = [];

      if (!this.$v.fromDate.$dirty) {
        return errors;
      }
      if (!this.$v.fromDate.required) {
        errors.push('From Date is required.');
      }

      return errors;
    },
    toDateErrors() {
      const errors = [];

      if (!this.$v.toDate.$dirty) {
        return errors;
      }
      if (!this.$v.toDate.required) {
        errors.push('To Date is required.');
      }

      return errors;
    },
  },
  created() {
    const routeParams = this.$route.params;
    let displayReport = true;

    if (routeParams.fromDate) {
      this.fromDate = routeParams.fromDate;
      displayReport = true;
    }

    if (routeParams.toDate) {
      this.toDate = routeParams.toDate;
      displayReport = true;
    }

    if (displayReport) {
      this.displayReport();
    }
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    displayReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .get('api/v1/reports/compliance-reports/', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          })
          .then((res) => {
            vm.items = Object.values(res.data.reports);
            vm.reportTypes = res.data.reportTypes;
            vm.reportLoaded = true;
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },
    generateReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .post('api/v1/reports/compliance-reports/', {
            fromDate: this.fromDate,
            toDate: this.toDate,
            reportType: this.reportType,
          })
          .then((res) => {
            this.setNotification({
              message: res.data.message,
              color: 'success',
            });

            vm.displayReport();
          })
          .catch((error) => {
            vm.reportGenerating = false;

            let msg = '';
            if (error.response.status === 422) {
              msg = error.response.data.message;
              if (error.response.data.errors) {
                Object.values(error.response.data.errors).forEach((val) => {
                  msg += `\n ${val}`;
                });
              }
            }

            if (error.response.status === 500) {
              msg = error.response.data.message;
            }

            this.setNotification({
              message: msg,
              color: 'red',
            });
          });
      }
    },
    resetDateValidators() {
      this.$v.fromDate.$reset();
      this.$v.toDate.$reset();
    },
    touchDateValidators() {
      this.$v.fromDate.$touch();
      this.$v.toDate.$touch();
    },
    fromDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.fromDateErrors.push('From Date cannot be after To Date.');
      }
    },
    toDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.toDateErrors.push('To Date cannot be before From Date.');
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_MED);
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATETIME_MED);
    },
    formatAmount(number) {
      const amount = parseFloat(number).toFixed(2);

      if (amount === '0.00') {
        return '-';
      }

      if (amount < 0) {
        return `-$${Math.abs(amount)}`;
      }

      return `$${amount}`;
    },
    closeJsonModal() {
      this.jsonModal = false;
      this.$nextTick(() => {
        this.json = {};
      });
    },
    showJsonModal(title, json) {
      this.json = json;
      this.jsonTitle = title;
      this.jsonModal = true;
    },
    downloadReport(report) {
      this.$axios
        .get(`api/v1/reports/compliance-reports/${report.id}/download`, {
          responseType: 'blob',
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${report.file_name}${report.file_extension}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.setNotification({
            message: 'There was an error downloading report.',
            color: 'red',
          });
        });
    },
  },
  validations: {
    fromDate: { required, lessThanToDate },
    toDate: { required, greaterThanFromDate },
  },
};
</script>
