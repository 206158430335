<template>
  <v-dialog
    v-model="ticketSuccessfulActionDialog"
    :max-width="maxWidth"
    @click:outside="$emit('closed')"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <p>ID: {{ ticketId }}</p>
        <p>Would you like to perform another task or mark this ticket as solved?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="anotherTask()"
        >
          Another Task
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          @click="resolveTicket()"
        >
          Resolve Ticket
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'TicketSuccessfulAction',
  components: {
  },
  props: {
    ticketSuccessfulActionDialog: {
      type: Boolean,
      default: false,
    },
    ticketId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
  },
  data() {
    return {
    };
  },
  methods: {
    anotherTask() {
      this.paymentModal = false;
      this.$router.push({
        name: 'ticket-show',
        params: {
          id: this.$route.params.id,
        },
      });
    },

    resolveTicket() {
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.$router.push({
            name: 'tickets',
          });
        });
    },
  },
};
</script>
