<template>
  <v-card-text v-if="passwordProtocol === 'old'">
    <p>
      The password for this account will consist of these parts, in order:
    </p>
    <p>
      <ul>
        <li>the user's full last name</li>
        <li>the last four digits of the user's SSN</li>
      </ul>
    </p>
    <p>
      For example:<br>
      &nbsp;&nbsp;&nbsp;&nbsp;If this lead belongs to John Smith, SSN 123-45-6789<br>
      &nbsp;&nbsp;&nbsp;&nbsp;The password would be: <b>Smith6789</b>
    </p>
  </v-card-text>
  <v-card-text v-else-if="passwordProtocol === 'new'">
    <p>
      The password for this account will consist of these parts, in order:
    </p>
    <p>
      <ul>
        <li>the first two letters of the user's last name</li>
        <li>the last four digits of the user's SSN</li>
        <li>the user's four digit birth year</li>
      </ul>
    </p>
    <p>
      For example:<br>
      &nbsp;&nbsp;&nbsp;&nbsp;If this lead belongs to John Smith, SSN 123-45-6789, born 1/1/1950<br>
      &nbsp;&nbsp;&nbsp;&nbsp;The password would be: <b>Sm67891950</b>
    </p>
  </v-card-text>
  <v-card-text v-else>
    <p>
      This user has not signed any documents. Password Protocol could not be determined.
    </p>
  </v-card-text>
</template>

<script>
export default {
  name: 'PasswordProtocol',

  props: {
    sessions: {
      type: [Array, Object],
      default: () => [],
    },
  },

  computed: {
    isEmpty() {
      return this.sessions.length === 0;
    },

    passwordProtocol() {
      return this.$attrs.passwordProtocol;
    },
  },
};
</script>
