<template>
  <div class="p-s">
    <v-row>
      <v-col
        cols="12"
      >
        <v-btn
          class="float-right mt-n10"
          @click="$emit('back-make-offer')"
        >
          back
        </v-btn>
      </v-col>
    </v-row>
    <div
      v-if="offerAccepted"
      class="mb-3"
    >
      <h2 class="font-weight-bold green--text mb-2">
        We have accepted your offer to settle your debt for {{ offer.settlementAmount | currency }}.
      </h2>
      <div>
        However, we would like you to consider the additional options that will not only stop the
        collections process, but also provide significant long-term benefits.
      </div>
    </div>
    <div
      v-else
      class="mb-3 mt-n5"
    >
      <h2 class="font-weight-bold red--text mb-2">
        We are unable to settle your debt for {{ offer.settlementAmount | currency }}.
      </h2>
      <div>
        However, we can offer you the following options. Please review carefully before making a
        decision to ensure you select the option that works best for you.
      </div>
    </div>

    <div
      v-for="(item, index) in settlementOptions"
      :key="index"
      class="settlement-option p-s mb-s mb-3"
    >
      <h3 class="title">
        Option {{ index + 1 }}: {{ item.title }}
      </h3>
      <p>{{ item.description }}</p>

      <OfferSummary
        :offer="item.offerInfo"
        border="top"
        class="mb-s"
      />

      <div>
        <v-btn
          button-full
          size="large"
          color="settlement"
          @click="
            $emit('settlement-decision', {
              ...item.offerInfo, ...{ description: item.description }
            })"
        >
          SELECT OPTION {{ index + 1 }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import OfferSummary from './OfferSummary.vue';
import offerTypes from './offerTypes';

export default {
  name: 'SettlementOfferDecision',

  components: {
    OfferSummary,
  },

  props: {
    offer: {
      type: Object,
      required: true,
    },
    loanData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    offerAccepted() {
      return this.offer.settlementAmount >= this.loanData.principalBalance;
    },

    // Partial Settlement = Greater of Customer Offer or Outstanding Principal Balance
    // total amount of money it would take to pay off the loan in full
    partialSettlement() {
      const principalBalance = Number(this.loanData.principalBalance);
      const custOfferAmount = this.offer.settlementAmount;
      const settlementAmount = custOfferAmount > principalBalance
        ? custOfferAmount : principalBalance;
      let { paymentAmount } = this.offer;
      let numberOfPayments = Math.ceil(settlementAmount / paymentAmount);
      const offerType = offerTypes.PARTIAL_SETTLEMENT;

      if (this.offer.isSinglePayment) {
        numberOfPayments = 1;
        paymentAmount = settlementAmount;
      }

      const offerInfo = {
        ...this.offer,
        settlementAmount,
        numberOfPayments,
        paymentAmount,
        offerType,
      };

      return {
        offerInfo,
        title: 'Partial Settlement',
        description: `
          A partial settlement will close out your account and stop the collections process. However,
          you will still experience a negative effect on your credit. We may report information about
          your account to credit bureaus. Late payments, missed payments, or other defaults on your
          account may be reflected in your credit report.
        `,
      };
    },

    // Full Settlement = principal balance + outstanding interest x 50%
    fullSettlement() {
      const fullBalance = Number(this.loanData.principalBalance)
        + Number(this.loanData.dueInterest) * 0.5;

      const custOfferAmount = this.offer.settlementAmount;
      const settlementAmount = custOfferAmount > fullBalance
        ? custOfferAmount : Math.ceil(fullBalance);
      let { paymentAmount } = this.offer;
      let numberOfPayments = Math.ceil(settlementAmount / paymentAmount);
      const offerType = offerTypes.FULL_SETTLEMENT;

      if (this.offer.isSinglePayment) {
        numberOfPayments = 1;
        paymentAmount = settlementAmount;
      }

      const offerInfo = {
        ...this.offer,
        settlementAmount,
        numberOfPayments,
        paymentAmount,
        offerType,
      };

      return {
        offerInfo,
        title: 'Full Settlement',
        description: `
          We may report information about your account to credit bureaus.
          Late payments, missed payments, or other defaults on your account
          may be reflected in your credit report. A full settlement will close out
          your account and stop the collections process. In addition, you will NOT
          experience a negative effect on your credit as a result of this settlement.
          A full settlement is far more beneficial to you than a partial settlement.
        `,
      };
    },

    // Pay In Full = Payoff Balance
    payInFull() {
      const settlementAmount = Number(this.loanData.payoff);
      let { paymentAmount } = this.offer;
      let numberOfPayments = Math.ceil(settlementAmount / paymentAmount);
      const offerType = offerTypes.PAY_IN_FULL;

      if (this.offer.isSinglePayment) {
        numberOfPayments = 1;
        paymentAmount = settlementAmount;
      }

      const offerInfo = {
        ...this.offer,
        settlementAmount,
        numberOfPayments,
        paymentAmount,
        offerType,
      };

      return {
        offerInfo,
        title: 'Pay in Full',
        description: `
          Paying your loan balance in full is by far the best long-term decision.
          By paying in full, the collections process will stop and your account will
          be reported to credit bureaus as paid in full. Without accepting a pay in full settlement,
          we may report information about your account to credit bureaus. Late payments, missed payments,
          or other defaults on your account may be reflected in your credit report.
        `,
      };
    },
    settlementOptions() {
      const options = [];

      const fullSettlementAmount = this.fullSettlement.offerInfo.settlementAmount;
      const partialSettlementAmount = this.partialSettlement.offerInfo.settlementAmount;
      const payInFullAmount = this.payInFull.offerInfo.settlementAmount;

      // fullSettlementAmount || payInFullAmount === partialSettlementAmount
      if (fullSettlementAmount === partialSettlementAmount) {
        options.push(this.fullSettlement);
      } else if (payInFullAmount === partialSettlementAmount) {
        options.push(this.payInFull);
      } else {
        if (fullSettlementAmount > partialSettlementAmount) {
          options.push(this.partialSettlement);
        }

        if (payInFullAmount > fullSettlementAmount) {
          options.push(this.fullSettlement);
        }
        options.push(this.payInFull);
      }
      return options;
    },
  },

};
</script>

<style></style>
