<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          :footer-props.sync="footerProps"
          @update:options="updateTable()"
        >
          <template v-slot:top>
            <v-card-title>
              <v-toolbar flat>
                <v-toolbar-title>Action Log</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ createDate(item.created_at) }}
          </template>

          <template v-slot:item.causer="{ item }">
            <span v-if="item.causer">
              {{ item.causer.first_name }} {{ item.causer.last_name }}
            </span>
          </template>

          <template v-slot:item.subject_type="{ item }">
            {{ item.subject_type.split('\\').pop().split('/').pop() }}
          </template>

          <template v-slot:item.before="{ item }">
            <span v-if="Array.isArray(item.properties.old)">
              -
            </span>
            <v-btn
              v-else
              tile
              color="primary"
              small
              text
              @click="showJsonModal('JSON', item.properties.old)"
            >
              <v-icon
                dark
                left
                class="mr-1"
              >
                mdi-eye
              </v-icon>
              SHOW
            </v-btn>
          </template>

          <template v-slot:item.after="{ item }">
            <span v-if="Array.isArray(item.properties.attributes)">
              -
            </span>
            <v-btn
              v-else
              tile
              color="primary"
              small
              text
              @click="showJsonModal('JSON', item.properties.attributes)"
            >
              <v-icon
                dark
                left
                class="mr-1"
              >
                mdi-eye
              </v-icon>
              SHOW
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <JsonModal
      v-model="jsonModal"
      :title="jsonTitle"
      :json="json"
      @close-modal="closeJsonModal()"
      @click:outside="closeJsonModal()"
    />
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';
import JsonModal from '@/components/JsonModal.vue';

export default {
  name: 'LoanProCustomerProfile',
  components: {
    JsonModal,
  },
  mixins: [
    dataTableMixin,
  ],
  data() {
    return {
      json: {},
      jsonTitle: '',
      jsonModal: false,
      headers: [
        { text: 'Date/Time', value: 'created_at' },
        { text: 'Log', value: 'log_name' },
        { text: 'Admin', value: 'causer' },
        { text: 'Action', value: 'description' },
        { text: 'Model', value: 'subject_type' },
        { text: 'Model ID', value: 'subject_id' },
        { text: 'Before', value: 'before' },
        { text: 'After', value: 'after' },
      ],
    };
  },
  methods: {
    async updateTable() {
      this.loading = true;

      const response = await this.$axios
        .get(`api/v1/loanpro-customer/${this.$route.params.customer_id}/actions`, {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        });

      this.totalItems = response.data.total;
      this.items = response.data.data;
      this.loading = false;
    },
    createDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    closeJsonModal() {
      this.jsonModal = false;
      this.$nextTick(() => {
        this.json = {};
      });
    },
    showJsonModal(title, json) {
      this.json = json;
      this.jsonTitle = title;
      this.jsonModal = true;
    },
  },
};
</script>
