<template>
  <div>
    <v-card>
      <v-card-title>
        Scoring Element Summary Report
      </v-card-title>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="fromDate"
                required
                label="From Date"
                type="date"
                :error-messages="fromDateErrors"
                @input="$v.fromDate.$touch()"
                @blur="$v.fromDate.$touch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="toDate"
                required
                label="To Date"
                type="date"
                :error-messages="toDateErrors"
                @input="$v.toDate.$touch()"
                @blur="$v.toDate.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="leadProviderId"
                :items="leadProviders"
                label="Lead Provider"
                @input="updateAvailableCampaigns()"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="campaignId"
                :items="campaignItems"
                label="Campaign"
              />
            </v-col>
          </v-row>

          <v-btn
            class="btn-bg-deep-blue"
            :disabled="formProcessing"
            :loading="reportGenerating"
            @click="generateReport()"
          >
            Generate Report
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
    <template
      v-if="reportLoaded"
    >
      <v-data-table
        dense
        :headers="headers"
        :items="reportData"
        :options.sync="options"
        :footer-props.sync="footerProps"
        :search="search"
        class="elevation-1 mt-2"
      >
        <!-- Totals Row -->
        <template slot="body.append">
          <tr>
            <td />
            <td />
            <td>{{ leadTotals.total_leads }}</td>
            <td>{{ leadTotals.rejected_leads }}</td>
            <td>{{ leadTotals.fail_percent }}</td>
            <td>{{ leadTotals.accepted_leads }}</td>
            <td>{{ leadTotals.pass_percent }}</td>
            <td>{{ reportDetails.data_cost_per_present }}</td>
            <td class="text-right">
              {{ reportDetails.total_data_cost }}
            </td>
          </tr>
          <tr>
            <td colspan="7">
              &nbsp;
            </td>
            <td>Data Cost/Lead</td>
            <td class="text-right">
              {{ reportDetails.data_cost_per_lead }}
            </td>
          </tr>
          <tr>
            <td colspan="7">
              &nbsp;
            </td>
            <td>Average Lead Cost</td>
            <td class="text-right">
              {{ reportDetails.avg_cost_per_lead }}
            </td>
          </tr>
          <tr>
            <td colspan="7">
              &nbsp;
            </td>
            <td>Average Cost / Lead</td>
            <td class="text-right">
              {{ reportDetails.avg_cost_per_lead_with_data }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import {
  required,
} from 'vuelidate/lib/validators';
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  mixins: [
    dataTableMixin,
  ],
  data() {
    return {
      fromDate: DateTime.local().toFormat('yyyy-MM-dd'),
      toDate: DateTime.local().toFormat('yyyy-MM-dd'),
      leadProviderId: null,
      leadProviders: [],
      campaignId: null,
      campaignList: {},
      campaignItems: [],
      reportData: [],
      reportLoaded: false,
      formProcessing: false,
      reportGenerating: false,
      headers: [
        { text: 'Provider', value: 'provider' },
        { text: 'Method', value: 'method' },
        { text: 'Presented', value: 'total' },
        { text: 'Failed', value: 'fail_count' },
        { text: 'Fail %', value: 'fail_percent' },
        { text: 'Passed', value: 'pass_count' },
        { text: 'Passed %', value: 'success_percent' },
        { text: 'Cost', value: 'cost' },
        { text: 'Spend', value: 'total_cost', align: 'right' },
      ],
    };
  },
  computed: {
    fromDateErrors() {
      const errors = [];

      if (!this.$v.fromDate.$dirty) {
        return errors;
      }
      if (!this.$v.fromDate.required) {
        errors.push('From Date is required.');
      }
      return errors;
    },
    toDateErrors() {
      const errors = [];

      if (!this.$v.toDate.$dirty) {
        return errors;
      }
      if (!this.$v.toDate.required) {
        errors.push('To Date is required.');
      }
      return errors;
    },
  },
  created() {
    const vm = this;

    this.$axios
      .get('/api/v1/lead-provider', {
        params: {
          perPage: 100,
          withCampaigns: 1,
        },
      })
      .then((res) => {
        res.data.data.map((elem) => {
          vm.leadProviders.push({
            text: elem.company_name,
            value: elem.id,
          });

          vm.campaignList[elem.id] = { campaigns: [] };

          elem.campaigns.map((campaign) => vm.campaignList[elem.id].campaigns.push({
            text: campaign.campaign_name,
            value: campaign.id,
          }));

          return true;
        });
      });
  },
  methods: {
    updateAvailableCampaigns() {
      const { leadProviderId } = this;

      this.campaignItems = this.campaignList[leadProviderId].campaigns;
    },
    generateReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .get('api/v1/reports/scoring-elements-summary', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
              leadProviderId: this.leadProviderId,
              campaignId: this.campaignId,
            },
          })
          .then((res) => {
            const { reportData, reportDetails, leadTotals } = res.data;

            vm.reportLoaded = true;

            vm.reportData = reportData;
            vm.reportDetails = reportDetails;
            vm.leadTotals = leadTotals;
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },
  },
  validations: {
    fromDate: { required },
    toDate: { required },
  },
};
</script>

<style></style>
