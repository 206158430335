<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Change Primary Email
        </v-card-title>
        <v-card-text>
          <div>
            <v-alert
              type="warning"
            >
              <p>
                This action will change the primary email address. If you are ready to set
                up a new primary email, click on “New Primary Email.” If you want to send
                a questionnaire to the borrower to ask them what they want their new
                primary email to be, click on “Send New Primary Email Questionnaire.”
              </p>
            </v-alert>
          </div>
          <div>
            <new-primary-email-dialog
              :ticket="ticket"
              :customer="customer"
              class="mb-1"
              @success="handleSuccess($event)"
            />
            <reusable-communication-by-email-template
              v-if="ticketId > 0 && ticket.lpCustomer"
              :customer="ticket.lpCustomer"
              :ticket-id="ticketId"
              :loan-id="loanId"
              :title="'Send New Primary Email Questionnaire'"
              :action="'new-primary-email-questionnaire'"
              :tag="'new_primary_questionnaire'"
              :extra="{}"
              @success="handleSuccess($event)"
            />
          </div>
        </v-card-text>
      </v-card>
      <ticket-successful-action
        :ticket-successful-action-dialog="ticketSuccessfulActionDialog"
        :ticket-id="$route.params.id"
        :title="ticketSuccessfulActionTitle"
        :max-width="'700px'"
        @closed="ticketSuccessfulActionDialog = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import NewPrimaryEmailDialog from './NewPrimaryEmailDialog.vue';
import ReusableCommunicationByEmailTemplate from '../Communications/ReusableCommunicationByEmailTemplate.vue';
import TicketSuccessfulAction from '../TicketSuccessfulAction.vue';

export default {
  name: 'TicketChangePrimaryEmail',
  components: {
    NewPrimaryEmailDialog,
    ReusableCommunicationByEmailTemplate,
    TicketSuccessfulAction,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ticketSuccessfulActionDialog: false,
      ticketSuccessfulActionTitle: '',
    };
  },
  computed: {
    ticketId() {
      return this.ticket?.zendesk?.id * 1 || 0;
    },
    loanId() {
      return this.ticket?.active_loan?.id * 1 || 0;
    },
  },
  methods: {
    handleSuccess(message) {
      this.ticketSuccessfulActionTitle = message;
      this.ticketSuccessfulActionDialog = true;
    },
  },
};
</script>
