<template>
  <v-container fluid>
    <h3>
      User Profile
    </h3>
    <profile-information />
    <two-factor-auth />
    <reset-password />
    <user-profile-token-index />
  </v-container>
</template>

<script>
import ProfileInformation from './ProfileInformation.vue';
import ResetPassword from './ResetPassword.vue';
import TwoFactorAuth from './TwoFactorAuth.vue';
import UserProfileTokenIndex from './UserProfileTokenIndex.vue';

export default {
  components: {
    ProfileInformation,
    TwoFactorAuth,
    ResetPassword,
    UserProfileTokenIndex,
  },
};
</script>
