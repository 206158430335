<template>
  <v-card
    class="ticket-show-actions"
    elevation="2"
    loading="false"
    outlined
    tile
  >
    <v-card-title class="pb-0">
      <v-text-field
        v-model="searchAction"
        class="search"
        outlined
        label="Search for an action to carry out"
        prepend-inner-icon="mdi-magnify"
      />
    </v-card-title>
    <v-card-text class="py-0">
      <v-list flat>
        <v-subheader>MOST USED</v-subheader>
        <v-list-item-group
          color="primary"
        >
          <v-list-item
            v-for="(item, i) in paginatedData"
            :key="i"
            @click="redirectPage(item.route)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-pagination
        v-model="pagination.page"
        :length="actions.length"
        :total-visible="pagination.visible"
        prev-icon="mdi-skip-previous"
        next-icon="mdi-skip-next"
        @input="next"
      />
      <v-btn @click="viewAll">
        View All
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import { orderBy } from 'lodash';

export default {
  name: 'TicketShowActions',
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchAction: '',
      pagination: {
        page: 1,
        perPage: 5,
        visible: 0,
      },
    };
  },
  computed: {
    ...mapState('app', ['settings']),
    ...mapState('tbotActions', ['actions']),
    ...mapGetters('ticket', ['actionButtonsMenu']),
    isSettlmentAndPortalStatus() {
      const status = this.ticket?.active_loan?.current_status?.loan_status_id || 0;
      const subStatus = this.ticket?.active_loan?.current_status?.loan_sub_status_id || 0;
      return status === 19 // Settlement
        && (
          subStatus === 172 // Portal 1 - Partial Settlement
          || subStatus === 173 // Portal 2 - Full Settlement
          || subStatus === 174 // Portal 3 - Pay In Full
          || subStatus === 179 // Portal 3 - Final Payment Pending
        );
    },
    isLineOfCredit() {
      const type = this.ticket?.active_loan?.loan_setup?.loan_type || '';
      return type === 'loan.type.creditLimit';
    },
    filteredActions() {
      const removeActions = [];
      // Hide Loan Modification for LoC
      // Hide Reschedule Payment for LoC
      if (this.isLineOfCredit) {
        removeActions.push('Modify Loan');
        // removeActions.push('Reschedule Payment');
      }
      // Hide Reschedule Payment for loans in 'Settlement' status AND
      // sub status in ['Portal 1','Portal 2','Portal 3']
      if (this.isSettlmentAndPortalStatus) {
        removeActions.push('Reschedule Payment');
      }

      // permanently hide ChangePrimaryEmail Tbot
      // removeActions.push("Change Primary Email");

      return this.actions.filter((x) => !removeActions.includes(x.text));
    },
    paginatedData() {
      const start = this.pagination.page * this.pagination.perPage - this.pagination.perPage;
      const end = start + this.pagination.perPage;
      let result = this.filteredActions;
      if (this.searchAction) {
        result = this.filteredActions.filter((item) => this.searchAction.toLowerCase().split(' ').every((v) => item.text.toLowerCase().includes(v)));
      }
      const publishedResults = result
        .filter((item) => (item.published
          && (this.settings[item.name] === undefined || this.settings[item.name] === true)))
      // || process.env.NODE_ENV !== 'production')
        .slice(start, end);
      return orderBy(publishedResults, ['text'], ['asc']);
    },
  },
  mounted() {
    if (this.$route.name !== 'ticket-show' && this.actionButtonsMenu.sidebar === 0) {
      this.actionButtonsMenuToSideBar({
        routerViewCol: 9,
        sidebar: 3,
        sidebarDisplay: '',
      });
    }
  },
  async created() {
    await this.getTbotActions(this.$route.params.id);
    await this.getSystemSettings();
  },
  methods: {
    ...mapActions('tbotActions', ['getTbotActions']),
    ...mapActions('ticket', ['actionButtonsMenuToSideBar']),
    ...mapActions('app', ['getSystemSettings']),
    next(e) {
      if (this.pagination.perPage === this.actions.length) {
        this.pagination.page = 1;
        this.pagination.perPage = 5;
      }
      this.pagination.page = e;
    },
    viewAll() {
      this.pagination.perPage = this.actions.length;
    },
    redirectPage(route) {
      if (this.$route.name === 'ticket-show' && this.actionButtonsMenu.sidebar === 0) {
        this.actionButtonsMenuToSideBar({
          routerViewCol: 9,
          sidebar: 3,
          sidebarDisplay: '',
        });
      }
      this.$router.push(route);
    },
  },

};
</script>
