const initialState = {
  queueCount: 0,
  collapsed: false,
  contentAreaStyle: {
    marginLeft: '',
  },
};

const actions = {
  setQueueCount({ commit }, queueCount) {
    commit('SET_QUEUE_COUNT', queueCount);
  },
  setCollasped({ commit }) {
    commit('SET_COLLAPSED');
  },
  setCollapsed({ commit }) {
    commit('SET_COLLAPSED');
  },
  setContentAreaStyle({ commit }) {
    commit('SET_CONTENTAREASTYLE');
  },
};

const mutations = {
  SET_QUEUE_COUNT(state, queueCount) {
    state.queueCount = queueCount;
  },
  SET_COLLAPSED(state) {
    state.collapsed = !state.collapsed;
  },
  SET_CONTENTAREASTYLE(state) {
    state.contentAreaStyle.marginLeft = (state.collapsed) ? '80px' : '295px';
  },
};

const getters = {
  collapsed(state) {
    return state.collapsed;
  },
  queueCount(state) {
    return state.queueCount;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
