<template>
  <div>
    <file-summary
      v-if="file.id"
      :file="file"
    />
    <FinalFileProspects
      v-if="file.type === 'final'"
      :file-id="file.id"
    />
    <IntermediateFileProspects
      v-if="file.type === 'intermediate'"
      :file-id="file.id"
    />
  </div>
</template>

<script>
import FileSummary from './FileSummary.vue';
import FinalFileProspects from './FinalFileProspects.vue';
import IntermediateFileProspects from './IntermediateFileProspects.vue';

export default {
  components: {
    IntermediateFileProspects,
    FinalFileProspects,
    FileSummary,
  },
  data: () => ({
    file: [],
  }),
  created() {
    this.$axios
      .get(`/api/v1/prescreen/files/${this.$route.params.id}`)
      .then((res) => {
        this.file = res.data;
      });
  },
};
</script>
