import { DateTime } from 'luxon-business-days';

const local = DateTime.local();
const hiredate = local.plusBusiness({ days: -90 });
const dateTime = Date.now();

const initialState = {
  leadData: {
    ipaddress: '1.21.4.40',
    affid: '11111111',
    campaign: '',
    api_key: '',
    test: '',
    testtype: '',
    requestamount: 1000,
    initialAmount: 1000,
    ssn: dateTime.toString().slice(1, 10),
    dob: '1999-05-13',
    firstname: 'Henry',
    lastname: 'Ssassafras',
    address: `${dateTime.toString().slice(-5)} Shepherd`,
    address2: '',
    city: 'Russelville',
    zip: '72801',
    state: 'AR',
    homephone: `(${dateTime.toString().slice(1, 4)})${dateTime.toString().slice(4, 7)}-${dateTime.toString().slice(7, 11)}`,
    email: `test+${dateTime}@user.com`,
    dlstate: 'AR',
    dlnumber: dateTime.toString(),
    ismilitary: 'false',
    iscitizen: 'true',
    otheroffers: 'false',
    rentorown: 'O',
    addressmonths: 0,
    addressyears: 5,
    incometype: 'E',
    paytype: 'D',
    hiredate: hiredate.toFormat('yyyy-MM-dd'),
    empmonths: '8',
    empyears: '6',
    empname: 'Test Company',
    empphone: '(222)222-2222',
    empphoneext: '',
    payfrequency: 'W',
    netmonthly: '3500',
    grossmonthly: '3500',
    bankname: 'Test Bank',
    accounttype: 'C',
    routingnumber: '256074974',
    accountnumber: dateTime.toString(),
    bankmonths: '0',
    bankyears: '4',
    lastpaydate: '',
    nextpaydate: '',
    secondpaydate: '',
    customer_guid: null,
    return_customer: 0,
    contract_date: local.toFormat('yyyy-MM-dd'),
    loan_status_id: 6,
    loan_sub_status_id: 31,
    loanRate: 499,
    fundLoan: 'true',
    generateZendeskTicket: 'true',
  },
};

const getters = {
  leadData: (state) => state.leadData,
};

const actions = {
  setLeadData({ commit }, data) {
    commit('SET_LEAD_DATA', data);
  },
  updateLeadData({ commit }, data) {
    commit('UPDATE_LEAD_DATA', data);
  },
};

const mutations = {
  SET_LEAD_DATA(state, data) {
    state.leadData = data;
  },
  UPDATE_LEAD_DATA(state, data) {
    Object.keys(data).forEach((key) => {
      state.leadData[key] = data[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
