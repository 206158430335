import Vue from 'vue';

const initialState = {
  numberOfPayments: 0,
  numberOfFullPayments: 0,
  fullPaymentAmount: 0,
  lastPaymentAmount: 0,
  paymentFrequency: '',
  firstPaymentDate: '',
  lastPaymentDate: '',
  isEOM: false,
  paymentSchedule: [],
  disclosuresSent: false,
  paymentSchedulesSent: false,
  isSettlementAvailable: false,
  isManualPayment: false,
};

const actions = {
  setIsManualPayment({ commit }, manualPayment) {
    commit('SET_MANUAL_PAYMENT', manualPayment);
  },

  determineAvailability({ commit }, { loanStatusId, loanSubStatusId }) {
    // allowed loan status and sub-status for settlement
    const allowedLoanStatus = {
      2: [
        9, 147,
      ],
      17: [
        148, 149, 150, 151, 152, 217
      ],
      19: [
        159,
      ],
      20: [
        177,
      ],
    };

    commit('SET_IS_SETTLEMENT_AVAILABLE',
      loanStatusId in allowedLoanStatus
        && allowedLoanStatus[loanStatusId].includes(loanSubStatusId));
  },
  async getPaymentSchedule({ commit }, { offer }) {
    const frequency = offer.isSinglePayment ? 'single'
      : offer.paymentFrequency.replace('-', '').toLowerCase();

    const res = await Vue.prototype.$axios
      .post('api/v1/admin/payment-schedule', {
        firstPaymentDate: offer.firstPaymentDate,
        totalOfPayments: offer.settlementAmount,
        paymentAmount: offer.paymentAmount,
        paymentFrequency: frequency,
      });

    // console.log(res.data);
    commit('SET_NUMBER_OF_PAYMENTS', res.data.numberOfPayments);
    commit('SET_NUMBER_OF_FULL_PAYMENTS', res.data.numberOfFullPayments);
    commit('SET_FULL_PAYMENT_AMOUNT', res.data.fullPaymentAmount);
    commit('SET_LAST_PAYMENT_AMOUNT', res.data.lastPaymentAmount);
    commit('SET_PAYMENT_FREQUENCY', offer.paymentFrequency.toLowerCase());
    commit('SET_PAYMENT_SCHEDULE', [...res.data.schedule]);
    commit('SET_FIRST_PAYMENT_DATE', res.data.schedule[0].date);
    commit('SET_LAST_PAYMENT_DATE', res.data.schedule.at(-1).date);
    commit('SET_IS_EOM', res.data.isEOM);
  },

  async zendeskSend({ commit }, { ticketId, message, action }) {
    // MAKE LOANPRO CALL
    const response = await Vue.prototype.$axios
      .post('api/v1/support/ticket/settlement/zendesk-send', {
        ticketId,
        message,
      })
      .catch(this.errorHandler);

    commit(action, (response.status === 200));
  },
  paymentSchedulesNotSent({ commit }) {
    commit('SET_PAYMENT_SCHEDULES_SENT', false);
  },
  disclosuresNotSent({ commit }) {
    commit('SET_DISCLOSURES_SENT', false);
  },
  // eslint-disable-next-line no-unused-vars
  async checkPaymentDate({ commit }, { paymentAccountId, paymentDate, singlePayment = false }) {
    return Vue.prototype.$axios.get(
      `api/v1/support/ticket/loan/autopays/account/${paymentAccountId}/check-payment-date?date=${paymentDate}&singlePayment=${singlePayment}`,
    );
  },

  async checkManualPaymentDate({ commit }, { paymentDate }) {
    return Vue.prototype.$axios.get(
      `api/v1/support/ticket/loan/manual-payment/check-payment-date?date=${paymentDate}`
    );
  }
};

const mutations = {
  SET_MANUAL_PAYMENT(state, data) {
    state.isManualPayment = data;
  },
  SET_NUMBER_OF_PAYMENTS(state, data) {
    state.numberOfPayments = data;
  },
  SET_NUMBER_OF_FULL_PAYMENTS(state, data) {
    state.numberOfFullPayments = data;
  },
  SET_FULL_PAYMENT_AMOUNT(state, data) {
    state.fullPaymentAmount = data;
  },
  SET_LAST_PAYMENT_AMOUNT(state, data) {
    state.lastPaymentAmount = data;
  },
  SET_PAYMENT_FREQUENCY(state, data) {
    state.paymentFrequency = data;
  },
  SET_PAYMENT_SCHEDULE(state, data) {
    state.paymentSchedule = data;
  },
  SET_FIRST_PAYMENT_DATE(state, data) {
    state.firstPaymentDate = data;
  },
  SET_LAST_PAYMENT_DATE(state, data) {
    state.lastPaymentDate = data;
  },
  SET_IS_EOM(state, data) {
    state.isEOM = data;
  },
  SET_DISCLOSURES_SENT(state, data) {
    state.disclosuresSent = data;
  },
  SET_PAYMENT_SCHEDULES_SENT(state, data) {
    state.paymentSchedulesSent = data;
  },
  SET_IS_SETTLEMENT_AVAILABLE(state, data) {
    state.isSettlementAvailable = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
