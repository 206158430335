import Vue from 'vue';

const initialState = {
  roles: [],
  all_roles: [],
  permissions: [],
};

const getters = {
  formattedPermissions(state) {
    // trim everything except the root permission name
    const formatPermissions = state.permissions.map((item) => `${item.name.split(' ')[1]} ${item.name.split(' ')[0]}`);

    return formatPermissions.sort();
  },
};

const actions = {
  async getAllRoles({ commit }, params) {
    try {
      const roles = await Vue.prototype.$axios.get('/api/v1/permission/roles-all', { params });
      commit('SET_ALL_ROLES', roles.data);
    } catch (err) {
      commit('SET_ALL_ROLES', {});
    }
  },
  async getRoles({ commit }, params) {
    try {
      const request = await Vue.prototype.$axios.get('/api/v1/permission/roles', { params });
      commit('SET_ROLES', request.data);
    } catch (err) {
      commit('SET_ROLES', {});
    }
  },
  async addRole({ dispatch }, role) {
    try {
      await Vue.prototype.$axios.post('/api/v1/permission/roles', { role });
      dispatch('notification/setNotification', {
        message: 'Role was successfully added.',
        color: 'green',
      }, { root: true });
    } catch (error) {
      dispatch('notification/setNotification', {
        message: 'There was an error processing your request.',
        color: 'red',
      }, { root: true });
    }
  },
  async editRole({ dispatch }, role) {
    try {
      await Vue.prototype.$axios.put('/api/v1/permission/roles', { role });
      dispatch('notification/setNotification', {
        message: 'Role was successfully edited.',
        color: 'green',
      }, { root: true });
    } catch (error) {
      dispatch('notification/setNotification', {
        message: 'There was an error processing your request.',
        color: 'red',
      }, { root: true });
    }
  },
  async deleteRole({ dispatch }, roleId) {
    try {
      await Vue.prototype.$axios.delete(`/api/v1/permission/role/${roleId}`);
      dispatch('getRoles');
      dispatch('notification/setNotification', {
        message: 'User Group has been deleted.',
        color: 'green',
      }, { root: true });
    } catch (error) {
      dispatch('notification/setNotification', {
        message: 'There was an error processing your request.',
        color: 'red',
      }, { root: true });
    }
  },
  async getPermissions({ commit }, params) {
    try {
      const request = await Vue.prototype.$axios.get('/api/v1/permission/permissions', { params });
      commit('SET_PERMISSIONS', request.data);
    } catch (err) {
      commit('SET_PERMISSIONS', {});
    }
  },
};

const mutations = {
  SET_ROLES(state, data) {
    state.roles = data;
  },
  SET_ALL_ROLES(state, data) {
    state.all_roles = data;
  },

  SET_PERMISSIONS(state, data) {
    state.permissions = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
