<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    @click:outside="close()"
  >
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">LendingTree Daily Cost Reports</span>
      </v-card-title>

      <v-container>
        <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          :footer-props.sync="footerProps"
          @update:options="updateTable()"
        />
      </v-container>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

export default {

  mixins: [
    dataTableMixin,
  ],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Total Offers', value: 'total_offers' },
        { text: 'Offers Cost', value: 'offers_cost' },
        { text: 'Total Converted', value: 'total_converted' },
        { text: 'Application Cost', value: 'application_cost' },
      ],
      loading: false,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/lendingtree/cost-reports', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy.length ? this.options.sortBy : ['date'],
            sortDesc: this.options.sortDesc.length ? this.options.sortDesc : [true],
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    close() {
      this.options.page = 1;
      this.$emit('close-modal');
    },
  },
};
</script>
