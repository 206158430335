<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-if="hasPermission('edit system-settings::cron-jobs')"
        small
        :color="chipColor"
        text-color="white"
        v-bind="attrs"
        @click="toggle()"
        v-on="on"
      >
        {{ chipText }}
      </v-chip>
      <disabled-for-lack-of-role-btn
        v-else
        :text="chipText"
        permission="edit system-settings::cron-jobs"
        :button="false"
        :chip="true"
        chip-color="error"
        v-on="on"
      />
    </template>
    <span>Click to {{ chipText.toLowerCase() }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'CronjobToggle',

  components: {
    DisabledForLackOfRoleBtn,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    active() {
      return !!this.item.active;
    },
    chipColor() {
      return this.active ? 'green' : 'red';
    },
    chipText() {
      return this.active ? 'Disable' : 'Enable';
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    toggle() {
      const active = this.active ? 0 : 1;

      this.$axios.post(`api/v1/cronjob/${this.item.id}/change-status`, { active })
        .then((response) => {
          this.setNotification({
            message: response.data.message,
            color: 'green',
          });

          this.$emit('cronjob-toggled');
        })
        .catch(() => {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        });
    },
  },
};
</script>

<style scoped>

</style>
