<template>
  <v-main class="grey lighten-3">
    <v-container
      fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <router-view />
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('app', ['version']),
  },
};
</script>

<style scoped>
.v-application .grey.lighten-3 {
  /*background: linear-gradient(to bottom, #f1f7f0 20%, #7d959d 90%);*/
  background: linear-gradient(to bottom, #f1f7f0 20%, #7d959d 80%);
  background-blend-mode: normal;
}
</style>
