<template>
  <v-dialog
    v-model="updatePaymentModal"
    max-width="500px"
    @click:outside="updatePaymentModal = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="hasPermission('view ticket::cancel-payment')"
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        icon="mdi-pencil-outline"
        text="Edit"
        permission="view ticket::cancel-payment"
        :x-small="true"
        button-class="mr-2"
      />
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Update Payment Profile</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="mb-0"
          >
            <v-select
              v-model="selectedPaymentProfile"
              :items="paymentProfiles"
              item-value="id"
              item-text="title"
              label="Select Payment Method"
              outlined
              required
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          :loading="loading"
          text
          @click="updatePaymentProfile()"
        >
          Update Payment
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'UpdateAutoPayPaymentProfile',
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    loanId: { required: true, type: Number },
    autopay: { required: true, type: Object },
  },
  data() {
    return {
      updatePaymentModal: false,
      selectedPaymentProfile: this.autopay.primary_payment_method_id,
      loading: false,
    };
  },
  computed: {
    ...mapState('loanProCustomer', ['paymentProfiles']),
  },
  methods: {
    ...mapActions('autopays', ['updateSelectedAutopayPayment']),
    ...mapActions('notification', ['setNotification']),

    async updatePaymentProfile() {
      this.loading = true;

      const result = await this.updateSelectedAutopayPayment({
        loanId: this.loanId,
        selectedAccount: this.paymentProfiles.find((pp) => pp.id === this.selectedPaymentProfile),
        autopays: [this.autopay],
      });
      this.loading = false;
      if (result && result.data === true) {
        this.setNotification({
          color: 'green',
          message: 'Payment method updated!!!',
        });
        this.updatePaymentModal = false;
      } else {
        this.setNotification({
          color: 'red',
          message: 'Payment could not be updated',
        });
      }
    },
  },
};
</script>
