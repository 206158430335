<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Details</h3>
      </v-col>
      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Status: <span>{{ ticket.statusName }}</span>
      </v-col>

      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Classification: <span>{{ ticket.classificationName }}</span>
      </v-col>

      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Sub Classification: <span>{{ ticket.subClassificationName }}</span>
      </v-col>

      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Agent: <span>{{ ticket.agentName }}</span>
      </v-col>

      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Created: <span>{{ ticket.createdAt }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3>Zendesk</h3>
      </v-col>

      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Zendesk: <span>{{ ticket.zdTicketNumber }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3>Loan Pro</h3>
      </v-col>

      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Display Id: <span>{{ ticket.lpDisplayId }}</span>
      </v-col>

      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Display Id: <span>{{ ticket.lpStatus }}</span>
      </v-col>

      <v-col
        class="ticket-details pt-1 pb-1"
        cols="12"
        sm="12"
        md="12"
      >
        Display Id: <span>{{ ticket.lpSubStatus }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OpsTicketDialogDetails',

  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
