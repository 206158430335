<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="provider"
                  required
                  label="Provider"
                  :error-messages="providerErrors"
                  @input="$v.provider.$touch()"
                  @blur="$v.provider.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="type"
                  required
                  label="Type"
                  :error-messages="typeErrors"
                  @input="$v.type.$touch()"
                  @blur="$v.type.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="scClass"
                  required
                  label="Class"
                  :error-messages="scClassErrors"
                  @input="$v.scClass.$touch()"
                  @blur="$v.scClass.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="method"
                  required
                  label="Method"
                  :error-messages="methodErrors"
                  @input="$v.method.$touch()"
                  @blur="$v.method.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="description"
                  required
                  label="Description"
                  :error-messages="descriptionErrors"
                  @input="$v.description.$touch()"
                  @blur="$v.description.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="cost"
                  required
                  label="Cost"
                  :error-messages="costErrors"
                  @input="$v.cost.$touch()"
                  @blur="$v.cost.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="response"
                  required
                  label="Response"
                  :error-messages="responseErrors"
                  @input="$v.response.$touch()"
                  @blur="$v.response.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="routeCustomInputs"
                  :items="customInputs"
                  multiple
                  label="Route Custom Inputs"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="authMethod"
                  label="Auth Method"
                  :error-messages="authMethodErrors"
                  @input="$v.authMethod.$touch()"
                  @blur="$v.authMethod.$touch()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { required, decimal, maxLength } from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          provider: '',
          type: '',
          scClass: '',
          method: '',
          description: '',
          cost: '',
          acceptable_response: '',
          authMethod: '',
          route_custom_inputs: null,
        };
      },
    },
  },
  data() {
    return {
      id: null,
      provider: '',
      type: '',
      scClass: '',
      method: '',
      description: '',
      cost: '',
      response: '',
      authMethod: '',
      routeCustomInputs: [],
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      const newOrUpdate = this.isNewItem ? 'New' : 'Update';
      return `${newOrUpdate} Scoring Element`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    providerErrors() {
      const errors = [];

      if (!this.$v.provider.$dirty) {
        return errors;
      }
      if (!this.$v.provider.required) {
        errors.push('Provider is required.');
      }
      if (!this.$v.provider.maxLength) {
        errors.push(`Provider must not exceed ${this.$v.provider.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    typeErrors() {
      const errors = [];

      if (!this.$v.type.$dirty) {
        return errors;
      }
      if (!this.$v.type.required) {
        errors.push('Type is required.');
      }
      if (!this.$v.type.maxLength) {
        errors.push(`Type must not exceed ${this.$v.type.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    scClassErrors() {
      const errors = [];

      if (!this.$v.scClass.$dirty) {
        return errors;
      }
      if (!this.$v.scClass.required) {
        errors.push('Class is required.');
      }
      if (!this.$v.scClass.maxLength) {
        errors.push(`Class must not exceed ${this.$v.scClass.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    methodErrors() {
      const errors = [];

      if (!this.$v.method.$dirty) {
        return errors;
      }
      if (!this.$v.method.required) {
        errors.push('Method is required.');
      }
      if (!this.$v.method.maxLength) {
        errors.push(`Method must not exceed ${this.$v.method.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    authMethodErrors() {
      const errors = [];

      if (!this.$v.authMethod.$dirty) {
        return errors;
      }
      if (!this.$v.authMethod.maxLength) {
        errors.push(`Auth method must not exceed ${this.$v.authMethod.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.description.$dirty) {
        return errors;
      }
      if (!this.$v.description.required) {
        errors.push('Description is required.');
      }
      if (!this.$v.description.maxLength) {
        errors.push(`Description must not exceed ${this.$v.description.$params.maxLength.max} letters.`);
      }
      return errors;
    },
    costErrors() {
      const errors = [];

      if (!this.$v.cost.$dirty) {
        return errors;
      }
      if (!this.$v.cost.required) {
        errors.push('Cost is required.');
      }
      if (!this.$v.cost.decimal) {
        errors.push('Cost must be a number.');
      }
      return errors;
    },
    responseErrors() {
      const errors = [];

      if (!this.$v.response.$dirty) {
        return errors;
      }
      if (!this.$v.response.required) {
        errors.push('Response is required.');
      }
      if (!this.$v.response.decimal) {
        errors.push('Response must be a number.');
      }
      return errors;
    },
    customInputs() {
      return [
        { value: 'score:number', text: 'Score' },
        { value: 'tier_score:number', text: 'Tier' },
        { value: 'endpoint:text', text: 'Endpoint' },
        { value: 'payload:json', text: 'Payload' },
      ];
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.provider = this.editedItem.provider;
      this.type = this.editedItem.type;
      this.scClass = this.editedItem.class;
      this.method = this.editedItem.method;
      this.description = this.editedItem.description;
      this.cost = this.editedItem.cost;
      this.response = this.editedItem.acceptable_response;
      this.authMethod = this.editedItem.authMethod;
      this.routeCustomInputs = this.setCustomInputs(this.editedItem.route_custom_inputs);
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';
        const data = {
          provider: this.provider,
          type: this.type,
          class: this.scClass,
          method: this.method,
          description: this.description,
          cost: this.cost,
          acceptableResponse: this.response,
          authMethod: this.authMethod,
          routeCustomInputs: this.routeCustomInputs,
        };

        if (this.isNewItem) {
          request = this.$axios.post('/api/v1/scoring-element', data);
        } else {
          request = this.$axios.put(`/api/v1/scoring-element/${this.editedItem.id}`, data);
        }

        request
          .then(() => {
            this.$emit('item-saved');

            this.setNotification({
              message: 'Scoring element has been saved.',
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');
    },
    setCustomInputs(blob) {
      if (!blob || !JSON.parse(blob) || !JSON.parse(blob).length) {
        return null;
      }
      return JSON.parse(blob);
    },
  },
  validations: {
    provider: {
      required,
      maxLength: maxLength(50),
    },
    type: {
      required,
      maxLength: maxLength(50),
    },
    scClass: {
      required,
      maxLength: maxLength(50),
    },
    method: {
      required,
      maxLength: maxLength(50),
    },
    authMethod: {
      maxLength: maxLength(50),
    },
    description: {
      required,
      maxLength: maxLength(100),
    },
    cost: { required, decimal },
    response: { required, decimal },
  },
};
</script>

<style></style>
