<template>
  <vue-json-pretty
    :data="data"
    :deep="3"
    :show-line="false"
    :show-double-quotes="false"
  />
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'JsonFormatterWrapper',

  components: {
    VueJsonPretty,
  },

  props: {
    data: {
      type: [Array, Object, String],
      required: true,
      default: '',
    },
  },
};
</script>
