<template>
  <v-card>
    <v-card-text>
      <v-alert
        v-if="!sessions.length"
        color="orange lighten"
        class="mt-5"
        dark
      >
        This customer does not have any FullStories associated to his email!
      </v-alert>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Session ID</th>
            <th>Created Time</th>
            <th>FS Url</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(session, index) in sessions"
            :key="`session_${index}`"
          >
            <td>{{ session.userId || 'N/F' }}</td>
            <td>{{ session.sessionId || 'N/F' }}</td>
            <td>{{ createDate(session.createdTime) || 'N/F' }}</td>
            <td>
              <a
                :href="session.fsUrl"
                target="__blank"
              >{{ session.fsUrl || 'N/F' }}</a>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>

import { DateTime } from 'luxon';

export default {
  name: 'LoanProCustomerFSEmail',

  props: {
    sessions: {
      type: Array,
      default() {
        return {};
      },
    },
  },

  data() {
    return {

    };
  },

  methods: {
    createDate(date) {
      return DateTime.fromSeconds(Number(date), { zone: 'America/Chicago' }).toLocaleString(DateTime.DATETIME_MED);
    },
  },
};
</script>

<style scoped>

</style>
