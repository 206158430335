<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    class="elevation-1"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Slack Channels</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />
          <v-spacer />
          <v-btn
            v-if="items.length && hasPermission('edit system-settings::slack-channels')"
            dark
            class="mb-2 btn-bg-deep-blue"
            :loading="sendingSlackMessage"
            @click="sendMessage()"
          >
            Send Message to All
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="Send Message to All"
            permission="edit system-settings::slack-channels"
            :x-small="false"
            button-class="mb-2"
          />
        </v-toolbar>
      </v-card-title>
    </template>
    <template
      v-slot:item.actions="{ item }"
      align-center
    >
      <v-btn
        v-if="hasPermission('edit system-settings::slack-channels')"
        small
        dark
        class="my-2 blue--text"
        outlined
        :loading="sendingSlackMessage"
        @click="sendMessage(item)"
      >
        <v-icon
          :id="item"
          class="mr-1"
        >
          mdi-send
        </v-icon>
        Send
      </v-btn>
      <disabled-for-lack-of-role-btn
        v-else
        text="Send"
        permission="edit system-settings::slack-channels"
        :x-small="false"
        button-class="mb-2"
        icon="mdi-send"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex';
import dataTableMixin from '@/mixins/dataTableMixin';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Name', value: 'text' },
      { text: 'Channel Key', value: 'value' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'start',
        width: 183,
      },
    ],
    sendingSlackMessage: false,
  }),
  methods: {
    ...mapActions('notification', ['setNotification']),
    handleNotification(color, message) {
      this.setNotification({ message, color });
    },
    updateTable() {
      this.$axios
        .get('/api/v1/slack-channels', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          const { channels } = res.data;
          this.items = channels.map((item) => ({
            value: item.key,
            text: item.name,
          }));
        })
        .catch((err) => {
          this.handleNotification('red', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendMessage(item) {
      const itemSelected = item || null;
      const options = (itemSelected) ? { channel: itemSelected.text } : {};

      this.sendingSlackMessage = true;
      this.$axios
        .post('/api/v1/slack-channels/send-message', options)
        .then(() => {
          this.handleNotification(
            'green',
            `Test notification sent in ${itemSelected ? itemSelected.value : 'all'} channel`,
          );
        })
        .catch((err) => {
          this.handleNotification('red', err);
        })
        .finally(() => {
          this.sendingSlackMessage = false;
        });
    },
  },
};
</script>
