<template>
  <v-row>
    <v-col>
      <v-card
        flat
        class="comment-card"
      >
        <v-card-title>
          <v-list-item class="grow comment-title">
            <v-list-item-avatar class="comment-avatar">
              {{ comment.userInitials }}
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ comment.userName }}</v-list-item-title>
            </v-list-item-content>

            <v-row
              align="center"
              justify="end"
            >
              <span class="comment-date mr-2">{{ comment.createdAt }}</span>
            </v-row>
          </v-list-item>
        </v-card-title>

        <v-card-text>
          <p>
            {{ comment.comment }}
          </p>
        </v-card-text>
      </v-card>
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'OpsTicketDialogCommentCard',

  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.v-dialog .v-card__title {
  background: white !important;
}

.comment-date {
  font-size: 0.75rem;
  font-weight: 300;
}

.comment-avatar {
  color: #d9e6e8d2;
  border: solid 1px #d9e6e8d2;
  background-color: grey;
}

.comment-title {
  padding: 0;
}

.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-right: 0.5rem;
}
</style>
