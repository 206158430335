<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="dataLoading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Application Settings List</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <DeleteModal
            v-model="dialogDelete"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogDelete')"
            @item-deleted="deleteItemConfirm()"
            @click:outside="clearState()"
          />

          <FormModal
            v-model="dialogForm"
            :action="formAction"
            :edited-item="editedItem"
            :dialog-form="dialogForm"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <v-btn
            v-if="hasPermission('create system-settings::app-settings')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="openFormModal()"
          >
            <v-icon>mdi-plus</v-icon>
            New Application Setting
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Application Setting"
            permission="create system-settings::app-settings"
            :x-small="false"
            button-class="mb-2"
          />

          <v-spacer />

          <HideTbotActionFormModal
            @item-saved="itemSaved()"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('edit system-settings::app-settings')"
        :id="item"
        class="mr-10"
        @click="openFormModal('update', item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="edit system-settings::app-settings"
        :x-small="false"
        button-class="mr-10"
      />

      <v-icon
        v-if="hasPermission('delete system-settings::app-settings')"
        class="red--text"
        @click="deleteItem(item)"
      >
        mdi-delete-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-delete-outline"
        :text-button="true"
        permission="delete system-settings::app-settings"
        :x-small="false"
        button-class="mr-10"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import DeleteModal from './DeleteModal.vue';
import FormModal from './FormModal.vue';
import HideTbotActionFormModal from './HideTbotActionFormModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    DeleteModal,
    FormModal,
    HideTbotActionFormModal,
    DisabledForLackOfRoleBtn,
  },
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    dataLoading: true,
    headers: [
      { text: 'Site', value: 'site' },
      { text: 'Name', value: 'name' },
      { text: 'Value', value: 'value' },
      { text: 'Action', value: 'actions', sortable: false },
    ],

    editedItem: {},
    dialogDelete: false,
    dialogForm: false,
    dialogTbotAction: false,
    formAction: 'add',
    snackbar: true,
  }),
  methods: {
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItemConfirm() {
      this.items = [];
      this.updateTable();
    },
    openFormModal(action = 'add', item = null) {
      this.dialogForm = true;

      this.formAction = action;

      if (action === 'update') {
        this.editedItem = item;
      }
    },
    openTbotActionModal() {
      console.log('should be opening');
      this.dialogTbotAction = true;
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.dataLoading = true;

      return this.$axios
        .get('api/v1/system-setting', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.dataLoading = false;
            this.items = res.data.data;
            this.totalItems = res.data.total;
          }, 1000);
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
