<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <p> These values are publicly viewable, do not store secret values like passwords here.</p>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col>
                <v-select
                  v-model="site"
                  :items="siteOptions"
                  item-text="name"
                  item-value="value"
                  label="Site"
                  persistent-hint
                  :rules="siteRules"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="name"
                  required
                  label="Name"
                  :rules="nameRules"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="value"
                  required
                  label="Value"
                  :rules="valueRules"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    dialogForm: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          site: '',
          name: '',
          value: '',
        };
      },
    },
    action: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      valid: false,
      id: null,
      site: '',
      name: '',
      value: '',
      formProcessing: false,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 45 || 'Name must be less than 45 characters',
        (v) => v.length >= 2 || 'Name must be more than 3 character',
      ],
      valueRules: [
        (v) => !!v || 'Value is required',
        (v) => v.length <= 255 || 'Name must be less than 255 characters',
        (v) => v.length >= 1 || 'Name must be more than 1 character',
      ],
      siteRules: [
        (v) => !!v || 'Site is required',
      ],
      siteOptions: [
        { name: 'Admin Portal', value: 'admin' },
        { name: 'Customer Portal', value: 'secure' },
        { name: 'Walk-up App', value: 'app' },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.dialogForm;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return this.action === 'add' ? 'Add New Application Setting' : 'Edit Application Setting';
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.site = this.editedItem.site;
      this.name = this.editedItem.name;
      this.value = this.editedItem.value;
    },
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        this.formProcessing = true;

        let request = '';

        const data = {
          site: this.site,
          name: this.name,
          value: this.value,
        };

        try {
          if (this.action === 'add') {
            request = await this.$axios.post('/api/v1/system-setting', data);
          } else {
            request = await this.$axios.put(`/api/v1/system-setting/${this.editedItem.id}`, data);
          }

          this.$emit('item-saved');

          this.setNotification({
            message: `${request.data.name} has been saved.`,
            color: 'green',
          });
        } catch {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        }

        this.formProcessing = false;
        this.close();
      }
    },
    close() {
      this.$emit('close-modal');
      this.$refs.form.reset();
    },
    ...mapActions('notification', ['setNotification']),
  },
};
</script>
