<template>
  <div>
    <h1>Classification</h1>

    <v-row>
      <v-col
        v-if="addClassification"
        cols="12"
        sm="12"
      >
        <OpsTicketClassificationAddEdit
          :active-classification="activeClassification"
          @classification-list="showClassificationList"
        />
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="12"
      >
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          calculate-widths
          class="elevation-2 mt-5"
          :footer-props.sync="footerProps"
          @update:options="updateTable()"
        >
          <template v-slot:top>
            <v-card-title>
              <v-btn
                depressed
                color="primary"
                @click="toggleClassification()"
              >
                Add New Classification
              </v-btn>
            </v-card-title>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              x-small
              class="mr-2 btn-bg-deep-blue"
              @click="editClassification(item)"
            >
              <v-icon
                left
                dark
              >
                mdi-account-outline
              </v-icon>
              Edit
            </v-btn>
            <v-btn
              :loading="deletingClassification"
              x-small
              class="mr-2"
              color="error"
              @click="deleteClassification(item)"
            >
              <v-icon
                left
                dark
              >
                mdi-trash-can-outline
              </v-icon>
              Delete
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import OpsTicketClassificationAddEdit from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketClassificationAddEdit.vue';
import { mapActions } from 'vuex';

export default {
  name: 'OpsTicketClassificationDatatable',
  components: { OpsTicketClassificationAddEdit },

  mixins: [dataTableMixin],

  data: () => ({
    headers: [
      { text: 'ID', value: 'id', width: '5%' },
      { text: 'Name', value: 'name', width: '15%' },
      { text: 'Description', value: 'description', width: '50%' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '30%',
      },
    ],
    deletingClassification: false,
    addClassification: false,
    activeClassification: null,
    loading: false,
  }),

  methods: {
    ...mapActions('notification', ['setNotification']),
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/ops-tickets/classification', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
            paginate: true,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data?.data || [];

          this.totalItems = res.data?.meta.total || 0;
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    deleteClassification(item) {
      this.deletingClassification = true;

      return this.$axios.delete(`api/v1/ops-tickets/classification/${item.id}`).then(() => {
        this.deletingClassification = false;

        this.updateTable();
      })
        .catch((error) => {
          this.deletingClassification = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    toggleClassification() {
      this.addClassification = true;
    },

    editClassification(item) {
      this.addClassification = true;
      this.activeClassification = item;
    },

    showClassificationList() {
      this.addClassification = false;
    },
  },
};
</script>

<style scoped></style>
