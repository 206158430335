<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>OFAC Reports</v-toolbar-title>
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.report_file="{ item }">
      <div
        :title="item.report_file"
      >
        {{ item.report_file }}
      </div>
    </template>

    <template v-slot:item.return_file="{ item }">
      <div
        style="max-width: 120px;"
        :title="item.return_file"
      >
        {{ item.return_file }}
      </div>
    </template>

    <template v-slot:item.created_at="{ item }">
      {{ formatDateTime(item.created_at) }}
    </template>

    <template v-slot:item.updated_at="{ item }">
      {{ formatDateTime(item.updated_at) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-menu
        v-if="item.return_file"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            small
            v-bind="attrs"
            v-on="on"
          >
            Review
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{ name: 'ofac-matches', query: { id: item.id } }">
            <v-list-item-title>All</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'ofac-matches', query: { id: item.id, status: 'open' } }">
            <v-list-item-title>Outstanding</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'ofac-matches', query: { id: item.id, status: 'confirmed' } }">
            <v-list-item-title>Confirmed</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'ofac-matches', query: { id: item.id, status: 'false' } }">
            <v-list-item-title>False</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon';
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  name: 'OfacUploadsIndex',
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Status', value: 'status' },
      { text: 'Report File', value: 'report_file' },
      { text: 'Report Time', value: 'report_time' },
      { text: 'Upload File', value: 'upload_file' },
      { text: 'Upload Time', value: 'upload_time' },
      { text: 'Return File', value: 'return_file' },
      { text: 'Return Time', value: 'return_time' },
      { text: 'Created On', value: 'created_at' },
      { text: 'Last Update', value: 'updated_at' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    returnFileToolTip: false,
  }),
  methods: {
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/ofac-report', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },

    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    },
  },
};
</script>

<style scoped>
.v-list-item {
  cursor: pointer;
}
</style>
