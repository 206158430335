<template>
  <v-navigation-drawer
    app
    clipped
    permanent
    dark
    :mini-variant="collapsed"
  >
    <v-list v-if="showSideBar">
      <template v-for="(menu, index) in menus">
        <v-list-item
          v-if="sidebarCanAccess(menu.permission)"
          :key="index"
          class="px-2"
          :to="{ name: menu.route }"
        >
          <v-list-item-icon>
            <template v-if="menu.badge && menu.count">
              <v-badge
                color="green"
                :content="menu.count"
                offset-x="10"
                offset-y="10"
              >
                <v-tooltip
                  :disabled="!collapsed"
                  right
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>
                        {{ menu.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ menu.label }}</span>
                </v-tooltip>
              </v-badge>
            </template>
            <template v-else>
              <v-tooltip
                :disabled="!collapsed"
                right
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>
                      {{ menu.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ menu.label }}</span>
              </v-tooltip>
            </template>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.label }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <v-list class="px-0">
        <v-list-item class="px-auto py-0">
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption">
              {{ version }}
            </v-list-item-subtitle>
            <v-list-item-subtitle @click="copyToClipboard">
              <div class="d-flex align-center justify-space-between">
                <div class="datadog-rum-session">
                  {{ datadogRumSessionId }}
                </div>
                <v-icon>mdi-content-copy</v-icon>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { datadogRum } from '@datadog/browser-rum';

export default {
  data() {
    return {
      queueInterval: null,
      datadogRumSessionId: null,
    };
  },
  computed: {
    ...mapState('currentUser', ['user']),
    ...mapState('app', ['name', 'env', 'settings']),
    ...mapState('sidebar', ['queueCount', 'collapsed']),
    ...mapGetters('app', ['version']),
    ...mapState('settings', ['settings']),
    menus() {
      const menu = [
        {
          label: 'Queue',
          icon: 'mdi-tray-full',
          route: 'lead-queue-index',
          badge: true,
          count: this.queueCount,
          permission: 'queue',
        },
        {
          label: 'Tickets',
          icon: 'mdi-text-box-multiple-outline',
          route: 'tickets',
          permission: 'ticket',
        },
        {
          label: 'Leads',
          icon: 'mdi-account-group-outline',
          route: 'leads',
          permission: 'lead',
        },
        {
          label: 'Customers',
          icon: 'mdi-account-multiple-check-outline',
          route: 'loanpro-customer-list',
          permission: 'loan-pro-customers',
        },
        {
          label: 'Lending Strategies',
          icon: 'mdi-head-cog-outline',
          route: 'lending-strategy',
          permission: 'lending-strategies',
        },
        {
          label: 'Reports',
          icon: 'mdi-clipboard-text-outline',
          route: this.reportDefaultRoute,
          permission: ['report', 'compliance'],
        },
        {
          label: 'Compliance',
          icon: 'mdi-format-list-bulleted',
          route: 'compliance-lead',
          permission: 'compliance',
        },
        {
          label: 'File Management',
          icon: 'mdi-file-document-multiple',
          route: 'nacha-returns',
          permission: 'file-management',
        },
        {
          label: 'Developer Tools',
          icon: 'mdi-developer-board',
          route: 'lead-queue',
          permission: 'compliance',
        },
      ];
      if (!this.prodEnv) {
        menu.push({
          label: 'Dev Menu',
          icon: 'mdi-developer-board',
          route: 'dev-menu',
          permission: 'dev-menu',
        });
      }

      if (this.settings.brand.prospect_active) {
        menu.splice(4, 0, {
          label: 'Offer Lookup',
          icon: 'mdi-clipboard-text-search-outline',
          route: 'offer-lookup',
          permission: 'ticket',
        });
      }

      if (this.showOpsTicketMenu) {
        menu.push({
          label: this.helpdeskOpsTicketName,
          icon: 'mdi-help-box-multiple-outline',
          route: this.opsTicketDefaultRoute,
          permission: ['ticket', 'ops_tickets'],
        });
      }

      return menu;
    },

    reportDefaultRoute() {
      if (this.hasPermission('view report::scoring-element-summary')) {
        return 'scoring-element-summary';
      }

      if (this.hasPermission('view report::compliance-report')) {
        return 'compliance-report';
      }

      return 'scoring-element-summary';
    },

    opsTicketDefaultRoute() {
      if (this.hasPermission('view ops_tickets::dashboard')) {
        return 'ops-ticket';
      }

      if (this.hasPermission('view ticket::any-ticket')) {
        return 'helpdesk';
      }

      return 'ops-ticket';
    },

    helpdeskOpsTicketName() {
      let menuName = '';

      if (this.hasPermission('view ticket::any-ticket')) {
        menuName += 'Helpdesk';
      }

      if (this.hasPermission('view ops_tickets::dashboard')) {
        if (menuName !== '') {
          menuName += '/Ops-Tickets';
        } else {
          menuName += 'Ops-Tickets';
        }
      }

      return menuName;
    },

    showOpsTicketMenu() {
      if (!this.opsTicketsActive) {
        return false;
      }

      return (
        this.hasPermission('view ticket::any-ticket')
        || this.hasPermission('view ops_tickets::dashboard')
      );
    },

    showSideBar() {
      if (!this.settings?.mfa_requried && !this.user.require_password_reset) {
        return true;
      }
      if (!this.user.mfa || this.user.require_password_reset) {
        return false;
      }
      return true;
    },
    userName() {
      return this.user.id ? `${this.user.first_name} ${this.user.last_name}` : '';
    },
    prodEnv() {
      if (this.env === 'production') {
        return true;
      }
      return false;
    },
    queueMenu() {
      return this.menus.find((menu) => menu.label === 'Queue');
    },
    opsTicketsActive() {
      return this.settings?.brand?.ops_ticket_active ?? false;
    },
  },
  async created() {
    if (this.queueMenu) {
      this.startPollQueueCount();
    }

    if (datadogRum.getInternalContext() !== undefined) {
      this.datadogRumSessionId = datadogRum.getInternalContext().session_id;
    }
  },
  beforeDestroy() {
    this.stopPollQueueCount();
  },
  methods: {
    copyToClipboard() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.datadogRumSessionId);
      } else {
        const el = document.createElement('textarea');
        el.value = this.datadogRumSessionId;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }

      this.setNotification({
        message: 'Session ID copied to clipboard',
        color: 'green',
      });
    },
    sidebarCanAccess(sidebarLink) {
      // trim everything except the root permission name
      const trim = this.user.permission.map((item) => item.name.split(' ').pop('').split('::')[0]);

      // remove duplicates left after trimming
      const removeDupes = [...new Set(trim)];

      if (sidebarLink instanceof Array) {
        let hasPermissions = false;

        sidebarLink.forEach((link) => {
          if (removeDupes.includes(link)) {
            hasPermissions = true;
          }
        });

        return hasPermissions;
      }

      // is requested sidebar link included in the list of permissions
      return removeDupes.includes(sidebarLink);
    },
    startPollQueueCount() {
      this.queueInterval = setInterval(() => this.pollQueueCount(), 30000);
      this.pollQueueCount();
    },
    pollQueueCount() {
      this.$axios.get('api/v1/lead-queue/count').then((res) => {
        this.setQueueCount(res.data);
      });
    },
    stopPollQueueCount() {
      clearInterval(this.queueInterval);
    },
    logOut() {
      this.logUserOut().then(() => this.$router.push({ name: 'login' }));
    },
    ...mapActions('currentUser', ['logUserOut']),
    ...mapActions('sidebar', ['setQueueCount']),
    ...mapActions('notification', ['setNotification']),
  },
};
</script>

<style scope>
.theme--dark.v-navigation-drawer {
  background-color: #4d4d4d !important;
  transform: none !important;
}
a.v-list-item.v-list-item--link.theme--dark {
  padding: 0.2rem 2rem;
  color: #f1f7f0 !important;
}
.v-list-item__icon {
  margin-right: 3rem !important;
}
.v-list-item__icon .v-icon.v-icon {
  color: #f1f7f0;
  font-size: 2.5rem;
  -webkit-text-stroke: 2px #4d4d4d;
}
.v-list .v-list-item--active {
  background: #999999;
  font-weight: 700 !important;
}
.v-list .v-list-item--active .v-list-item__icon .v-icon.v-icon {
  color: #f1f7f0;
  font-size: 2.5rem;
  -webkit-text-stroke: 1px #999999;
}

.v-list-item__title {
  font-size: 1.1rem !important;
  font-weight: 500;
}

.datadog-rum-session-id {
  font-size: 0.9em;
  margin: 15px 0;
}

.datadog-rum-session {
  font-size: 0.75em;
  overflow: hidden;
}
</style>
