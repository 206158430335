const initialState = {
  actions: [],
};

const actions = {
  getTbotActions({ commit }, ticketId) {
    const data = [
      {
        name: 'tbot_action_autopay',
        text: 'Autopay',
        icon: 'mdi-view-dashboard-outline',
        route: { name: 'ticket-autopay', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_statement_report',
        text: 'Statement Report',
        icon: 'mdi-chart-timeline',
        route: { name: 'ticket-statement-report', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_add_payment_method',
        text: 'Add Payment Method',
        icon: 'mdi-credit-card-settings',
        route: { name: 'ticket-add-payment', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_make_a_payment',
        text: 'Make a Payment',
        icon: 'mdi-credit-card-outline',
        route: { name: 'ticket-make-payment', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_fraud_claim',
        text: 'Fraud Claim',
        icon: 'mdi-ufo',
        route: { name: 'ticket-fraud-claim', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_cancel_payment',
        text: 'Cancel Upcoming Payment',
        icon: 'mdi-close',
        route: { name: 'ticket-cancel-payment', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_reschedule_payment',
        text: 'Reschedule Payment',
        icon: 'mdi-calendar-arrow-right',
        route: { name: 'ticket-reschedule-payment', params: { ticketId } },
        published: true, // set value to true to unHide Reschedule payment button
      },
      {
        name: 'tbot_action_make_a_reduced_payment',
        text: 'Make a Reduced Payment',
        icon: 'mdi-wallet-giftcard',
        route: { name: 'ticket-make-reduced-payment', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_settlement',
        text: 'Settlement',
        icon: 'mdi-currency-usd-off',
        route: { name: 'ticket-settlement', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_communications',
        text: 'Communications',
        icon: 'mdi-email-send',
        route: { name: 'ticket-communication-macros', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_revoke_autopay',
        text: 'Revoke Autopay',
        icon: 'mdi-cancel',
        route: { name: 'ticket-revoke-autopay', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_rescind_loan',
        text: 'Rescind Loan',
        icon: 'mdi-archive-cancel',
        route: { name: 'ticket-rescind-loan', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_payment_wizard',
        text: 'Payment Wizard',
        icon: 'mdi-view-dashboard-outline',
        route: { name: 'ticket-payment-wizard', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_modify_loan',
        text: 'Modify Loan',
        icon: 'mdi-update',
        route: { name: 'ticket-modify-loan', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_settlement_reset',
        text: 'Settlement Reset',
        icon: 'mdi-lock-reset',
        route: { name: 'ticket-settlement-reset', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_bank_update_funding',
        text: 'Bank Update Funding',
        icon: 'mdi-backup-restore',
        route: { name: 'ticket-bank-update-funding', params: { ticketId } },
        published: true,
      },
      {
        name: 'tbot_action_change_primary_email',
        text: 'Change Primary Email',
        icon: 'mdi-key-change',
        route: { name: 'ticket-change-primary-email', params: { ticketId } },
        published: true,
      },
    ];
    commit('SET_ACTIONS', data);
  },
};

const mutations = {
  SET_ACTIONS(state, data) {
    state.actions = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
