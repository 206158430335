<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="12"
      class="remove-class"
    >
      <v-list v-if="fullStoryLinkArray.length">
        <v-list-item-group>
          <v-list-item
            v-for="(item, linkIndex) in fullStoryLinkArray"
            :key="linkIndex"
            dense
          >
            <v-list-item-content>
              <v-list-item-title>
                <span>{{ item }}</span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon @click="removeLink(linkIndex)">
              <v-icon
                color="'grey'"
                small
              >
                mdi-trash-can-outline
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-text-field
        v-model="fullStoryLink"
        dense
        label="Relevant FS links"
        :rules="validations.validLink"
        hint="http://example.com or https://example.com"
      />
    </v-col>
    <v-btn
      color="blue-grey"
      class="ma-2 white--text"
      @click="addLinkOptions"
    >
      Add fs link
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: 'OpsTicketFSInput',

  data() {
    return {
      fullStoryLink: '',
      fullStoryLinkArray: [],
      validations: {
        validLink: [
          (value) => {
            if (value) {
              return this.isURL(value) || 'Link is not valid';
            }

            return true;
          },
        ],
      },
    };
  },

  methods: {
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    },

    addLinkOptions() {
      let isValid = true;

      this.validations.validLink.forEach((rule) => {
        if (rule(this.fullStoryLink) !== true) {
          isValid = false;
        }
      });

      if (isValid) {
        this.fullStoryLinkArray.push(this.fullStoryLink);
        this.$emit('input', this.fullStoryLinkArray);
      }
    },

    removeLink(linkIndex) {
      this.fullStoryLinkArray.splice(linkIndex, 1);
    },
  },
};
</script>

<style scoped></style>
