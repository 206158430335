<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(menu, index) in menus">
        <v-tab
          v-if="hasPermission(menu.permission)"
          :key="index"
          :to="{ name: menu.route }"
        >
          {{ menu.label }}
        </v-tab>
        <v-tab
          v-else
          :key="index"
        >
          <disabled-for-lack-of-role-btn
            :text="menu.label"
            :text-button="true"
            :permission="menu.permission"
            :x-small="false"
          />
        </v-tab>
      </template>
    </v-tabs>
    <v-card class="mt-10 transparent">
      <router-view />
    </v-card>
  </v-container>
</template>

<script>
// import TicketChangePrimaryEmail from "../Tickets/ChangePrimaryEmail/TicketChangePrimaryEmail";
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    // TicketChangePrimaryEmail,
    DisabledForLackOfRoleBtn,
  },
  data() {
    return {
      menus: [
        {
          label: 'Users',
          icon: 'mdi-account-group',
          route: 'users',
          permission: 'view user-admin::user',
        },
        {
          label: 'Groups',
          icon: 'mdi-account-group',
          route: 'groups',
          permission: 'view user-admin::role',
        },
        {
          label: 'Allowed IPs',
          icon: 'mdi-account-group',
          route: 'ips',
          permission: 'view user-admin::allowed-ips',
        },
        {
          label: 'APi Tokens',
          icon: 'mdi-account-group',
          route: 'api-tokens',
          permission: 'view user-admin::allowed-ips',
        },
      ],
    };
  },
};
</script>

<style></style>
