<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    @click:outside="close()"
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">Change Brand</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-alert
                  v-cloak
                  border="top"
                  type="info"
                  dark
                >
                  By selecting a brand the settings for this brand will be loaded!.
                </v-alert>
              </v-col>
              <v-col>
                <v-select
                  v-model="brandSelected"
                  :items="brands"
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="changeSelectedBrand()"
        >
          Select
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    dialogForm: {
      type: Boolean,
      default: false,
    },
    brands: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      formProcessing: false,
      brandSelected: {},
    };
  },
  computed: {
    show: {
      get() {
        return this.dialogForm;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    dialogForm: {
      deep: true,
      handler(value) {
        if (value) {
          this.brandSelected = this.initBrandSelected();
        }
      },
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    changeSelectedBrand() {
      this.formProcessing = true;

      this.$axios.post('api/v1/admin/brand-settings/change-brand', {
        brand: this.brandSelected,
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            if (res.data.error) {
              this.setNotification(res.data.message);
            } else {
              this.$emit('reload-settings');
              this.close();
            }
          }
        })
        .catch((err) => {
          this.setNotification(err?.response?.data?.message ?? 'Oops something went wrong!');
        })
        .finally(() => {
          this.formProcessing = false;
        });
    },

    close() {
      this.$emit('close-modal');
      this.$refs.form.reset();
    },

    initBrandSelected() {
      let selectedBrand = 0;
      this.brands.forEach((brand) => {
        if (brand.in_use === true) {
          selectedBrand = brand.id;
        }
      });

      return selectedBrand;
    },
  },
};
</script>
