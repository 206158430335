<template>
  <v-card
    flat
    tile
    min-height="1250px"
    height="1250px"
  >
    <v-toolbar
      color="darkblue"
      dark
    >
      <OpsTicketMenu v-if="hasPermission('edit ops_tickets::dashboard')" />

      <v-toolbar-title>Tickets</v-toolbar-title>

      <v-spacer />

      <v-spacer />

      <OpsTicketDialogReport v-if="hasPermission('edit ops_tickets::dashboard')" />
    </v-toolbar>

    <v-container
      v-for="type in types"
      :key="type"
      class="grey lighten-4"
      fluid
      min
    >
      <v-row>
        <v-col
          cols="4"
          sm="4"
          md="4"
        >
          <v-text-field
            v-model="searchValue"
            label="Search by Agent Name, Ops Display Id"
            prepend-inner-icon="mdi-magnify"
          />
        </v-col>
      </v-row>

      <v-row v-if="statusCols.length">
        <OpsTicketStatusCol
          v-for="col in statusCols"
          :key="col.id"
          :col="col"
          :ops-tickets="opsTicketsFiltered"
          @update-ticket="updateTicket"
          @open-ticket-dialog="handleTicketDialog"
        />
      </v-row>
    </v-container>
    <OpsTicketDialog
      :ticket="dialogTicket"
      :open="showTicketDialog"
      @close="handleCloseTicketDialog()"
    />
  </v-card>
</template>

<script>
import OpsTicketMenu from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketMenu.vue';
import OpsTicketStatusCol from '@/views/Helpdesk/components/OpsTicketDashboard/OpsTicketStatusCol.vue';
import OpsTicketDialog from '@/views/Helpdesk/components/OpsTicketDashboard/OpsTicketDialog.vue';
import OpsTicketDialogReport from '@/views/Helpdesk/components/OpsTicketDashboard/OpsTicketDialogReport.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    OpsTicketDialogReport,
    OpsTicketDialog,
    OpsTicketStatusCol,
    OpsTicketMenu,
  },
  data: () => ({
    loadingTickets: false,
    types: ['General Dashboard'],
    statusCols: [],
    opsTickets: [],
    dialogTicket: {},
    showTicketDialog: false,
    searchValue: '',
  }),

  computed: {
    opsTicketsFiltered() {
      return this.opsTickets.filter(
        (ticket) => ticket.agentName.toLowerCase().includes(this.searchValue.toLowerCase())
          || ticket.opsTicketDisplayId.toLowerCase().includes(this.searchValue.toLowerCase()),
      );
    },
  },

  mounted() {
    this.getTicketsByStatus();
    this.getOpsTickets();
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    getTicketsByStatus() {
      this.loadingTickets = true;

      this.$axios
        .get('api/v1/ops-tickets/status', {
          params: {
            paginate: false,
          },
        })
        .then((res) => {
          this.statusCols = res.data?.data || [];
        })
        .catch((error) => {
          this.loadingTickets = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    getOpsTickets() {
      this.loadingTickets = true;

      this.$axios
        .get('api/v1/ops-tickets', {
          params: {
            paginate: false,
          },
        })
        .then((res) => {
          this.opsTickets = res.data?.data || [];
        })
        .catch((error) => {
          this.loadingTickets = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    updateTicket(data) {
      this.opsTickets = this.opsTickets.map((ops) => {
        if (ops.id === data.ticket.id) {
          const newOps = ops;
          newOps.status = data.col;
          this.$axios.patch(`api/v1/ops-tickets/${ops.id}`, {
            status_id: ops.status.id,
          });

          return newOps;
        }
        return ops;
      });
    },

    handleTicketDialog(ticket) {
      this.dialogTicket = ticket;
      this.showTicketDialog = true;
    },

    handleCloseTicketDialog() {
      this.dialogTicket = {};
      this.showTicketDialog = false;
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 4px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-bottom: 4px;
}
</style>
