<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="mt-8 primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Validifi Options
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Validifi Options</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-alert
              v-if="error"
              border="top"
              color="red lighten-2"
              dark
              sm="12"
            >
              {{ errorMessage }}
            </v-alert>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="methodSelected"
                  :items="validifiMethods"
                  item-text="label"
                  item-value="method"
                  label="Validifi Methods"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="resultSelected"
                  :items="results"
                  item-text="label"
                  item-value="result"
                  label="Validifi Result"
                  required
                  :disabled="notAbleToSelectResult"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="routingNumber"
                  label="Routing Number"
                  required
                  :disabled="notAbleToSelectResult"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="accountNumber"
                  label="Account Number"
                  required
                  :disabled="notAbleToSelectResult"
                />
              </v-col>
              <v-col cols="12">
                <h3>
                  Validifi Request
                </h3>
                <JsonViewer
                  :value="createRequestData"
                  :expand-depth="2"
                  :expanded="isSelectedMethod"
                  copyable
                  boxed
                  sort
                />
              </v-col>
              <v-col cols="12">
                <h3>
                  Validifi Response
                </h3>
                <small>
                  This is an example of how the validifi response can be,
                  where Result 00 is ok and 99 is error
                </small>
                <JsonViewer
                  :value="createResponseData"
                  :expand-depth="2"
                  :expanded="isSelectedMethod"
                  copyable
                  boxed
                  sort
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!ableToSave"
            @click="saveOptions()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import JsonViewer from 'vue-json-viewer';
import { mapState } from 'vuex';

export default {

  name: 'WaterfallTestValidifiOptionsDialog',
  components: {
    JsonViewer,
  },

  props: {
    leadData: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      errorMessage: 'Oops Something happens, please try again',

      error: false,

      dialog: false,

      routingNumber: '',

      accountNumber: '',

      methodSelected: 'select',

      validifiMethods: [
        { method: 'select', label: 'Select One Method' },
        { method: 'logValidifi', label: 'Log Validifi' },
        { method: 'getValidifi', label: 'Get Validifi PI Risk' },
        { method: 'getValidifiFiRisk', label: 'Get Validifi FI Risk' },
      ],

      resultSelected: 'pass',

      requestType: {
        logValidifi: {
          fail: [
            {
              accountNumber: '122456654456',
              routingNumber: '9999999909',
            },
          ],
          pass: [
            {
              accountNumber: '222222222',
              routingNumber: '256074974',
            },
          ],
        },
        getValidifi: {
          fail: [
            {
              accountNumber: '1111111111',
              routingNumber: '7777888999',
            },
          ],
          pass: [
            {
              accountNumber: '9999999901',
              routingNumber: '267084199',
            },
          ],
        },
        getValidifiFiRisk: {
          fail: [
            {
              accountNumber: '9999999904',
              routingNumber: '999999992',
            },
          ],
          pass: [
            {
              accountNumber: '9999999901',
              routingNumber: '267084199',
            },
          ],
        },
      },
    };
  },

  computed: {
    ...mapState('settings', ['settings']),
    results() {
      if (this.methodSelected === 'logValidifi') {
        return [
          { result: 'pass', label: 'Pass' },
        ];
      }

      return [
        { result: 'pass', label: 'Pass' },
        { result: 'fail', label: 'Fail' },
      ];
    },
    ableToSave() {
      return (this.accountNumber !== '' && this.routingNumber !== '');
    },
    notAbleToSelectResult() {
      if (!this.isSelectedMethod) {
        this.cleanNumbers();
        return true;
      }

      if (this.methodSelected !== 'getValidifiFiRisk') {
        return false;
      }

      return !this.isValidifi3Enable;
    },
    isSelectedMethod() {
      return this.methodSelected !== 'select';
    },
    createRequestData() {
      return {
        Mode: this.getRequestMode,
        PackageServiceList: this.getPackageServiceList,
        UniqueID: 'This ID will be generated in the backend',
        AccountingCode: '',
        FirstName: this.leadData.firstname,
        LastName: this.leadData.lastname,
        SSN: this.leadData.ssn,
        BirthDate: this.leadData.dob,
        CardNumber: '',
        BankAccount: this.accountNumber,
        BankRouting: this.routingNumber,
        Amount: 1000,
        Street: this.leadData.address,
        City: this.leadData.city,
        State: this.leadData.dlstate,
        Zip: this.leadData.zip,
      };
    },
    createResponseData() {
      return {
        Response: {
          Result: '00',
          Message: 'OK',
          Requests: {
            Request: {
              RequestID: '37762770',
              UniqueID: 'e7d9f44386b',
              Status: '15',
              Packages: {
                Package: {
                  Name: 'PI Risk Score',
                  PackageID: '12',
                  Scorings: {
                    Scoring: {
                      Date: '10/17/2022',
                      Version: '2.0',
                      Name: 'PI Risk Score v2.0',
                      ScoringID: '4',
                      PackageID: '12',
                      Score: '15',
                    },
                  },
                },
              },
            },
          },
        },
      };
    },

    getRequestMode() {
      if (this.methodSelected === 'getValidifiFiRisk') {
        if (!this.isValidifi3Enable) {
          this.showError('getValidifiFiRisk need enable_validifi_3 in true');
          return false;
        }

        return 'S';
      }

      return (this.isValidifi3Enable) ? 'S' : 'P';
    },
    getPackageServiceList() {
      if (this.methodSelected === 'getValidifiFiRisk') {
        if (!this.isValidifi3Enable) {
          this.showError('getValidifiFiRisk need enable_validifi_3 in true');
          return false;
        }

        return '78';
      }

      return (this.isValidifi3Enable) ? '77' : '12';
    },
    isInstallment() {
      return this.campaigns.some((campaign) => campaign.value === this.leadData.campaign && campaign.text.includes('installment'));
    },
    isLineOfCredit() {
      return this.campaigns.some((campaign) => campaign.value === this.leadData.campaign && campaign.text.includes('lineOfCredit'));
    },
    isValidifi3Enable() {
      if (this.isLineOfCredit) {
        return this.settings.lineOfCredit.enable_validifi_3;
      }

      return this.settings.installment.enable_validifi_3;
    },
  },

  watch: {
    methodSelected(value) {
      if (value && value !== 'select') {
        this.updateRequest();
        if (value !== 'getValidifiFiRisk') {
          this.error = false;
        }

        if (value === 'getValidifiFiRisk' && !this.isValidifi3Enable) {
          this.error = true;
          this.cleanNumbers();
        }
      }
    },
    resultSelected(value) {
      if (value) {
        this.updateRequest();
      }
    },
  },

  methods: {
    cleanNumbers() {
      this.accountNumber = '';
      this.routingNumber = '';
    },
    showError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    updateRequest() {
      const requestNeeded = this.requestType[this.methodSelected][this.resultSelected][0];
      this.routingNumber = requestNeeded.routingNumber;
      this.accountNumber = requestNeeded.accountNumber;
    },
    saveOptions() {
      const options = {
        accountNumber: this.accountNumber,
        routingNumber: this.routingNumber,
      };

      this.$emit('saveOptions', options);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>

</style>
