<template>
  <card-layout
    :style="[ !user.require_password_reset ? {'border': '0px'} : {'border': '2px solid red'}]"
  >
    <template v-slot:title>
      Reset Password
      <span class="red--text">{{ !user.require_password_reset ? '' : 'REQUIRED' }}</span>
    </template>
    <template v-slot:description>
      Reset your account password.
    </template>

    <template v-slot:form>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row>
          <v-col>
            <p
              v-if="user.require_password_reset"
              class="subtitle-1 red--text"
            >
              A password reset is REQUIRED immediately.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              label="Password"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="passwordConfirmed"
              :rules="[(password === passwordConfirmed) || 'Passwords must match']"
              label="Confirm Password"
              required
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:button>
      <v-btn
        absolute
        right
        :disabled="valid == 0"
        @click="savePassword()"
      >
        Save
      </v-btn>
    </template>
  </card-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CardLayout from './CardLayout.vue';

export default {
  components: {
    CardLayout,
  },
  data() {
    return {
      password: '',
      passwordConfirmed: '',
      valid: true,
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => v?.length >= 8 || 'Password must be at least 8 characters',
      ],
    };
  },
  computed: {
    ...mapState('currentUser', ['user']),
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('currentUser', ['getUser']),
    async savePassword() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const data = {
        password: this.password,
        password_confirmation: this.passwordConfirmed,
      };

      try {
        const request = await this.$axios.post(`/api/v1/user/${this.user.id}/reset-password`, data);
        if (request.status === 200) {
          this.getUser();
          this.setNotification({
            message: `${request.data.first_name}'s password has been reset.`,
            color: 'green',
          });
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        }
      } catch (err) {
        this.setNotification({
          message: 'There was an error processing your request.',
          color: 'red',
        });
      }
    },
  },
};
</script>
