<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col
        md="3"
        style="max-width: 300px"
      >
        <v-card>
          <v-card-title class="text-uppercase">
            Reports
          </v-card-title>
          <v-card-text class="px-0 py-0">
            <v-tabs vertical>
              <template v-for="(item, index) in menus">
                <v-tab
                  :key="index"
                  :to="{ name: item.route }"
                >
                  {{ item.label }}
                </v-tab>
              </template>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col md="9">
        <v-card
          tile
          rounded="lg"
        >
          <router-view />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('currentUser', ['user']),
    menus() {
      const menus = [
        {
          label: 'Scoring Element Summary',
          icon: 'mdi-account-group',
          route: 'scoring-element-summary',
          permission: 'scoring-element-summary',
        },
        {
          label: 'Lead Dashboard Report',
          icon: 'mdi-account-group',
          route: 'lead-dashboard',
          permission: 'lead-dashboard-report',
        },
        {
          label: 'Affiliate Report',
          icon: 'mdi-account-group',
          route: 'affiliate-report',
          permission: 'affiliate-report',
        },
        {
          label: 'Adverse Action Failures Report',
          icon: 'mdi-account-group',
          route: 'adverse-action-failures-report',
          permission: 'adverse-action-failures-report',
        },
        {
          label: 'LoanPro Activity Report',
          icon: 'mdi-account-group',
          route: 'loanpro-activity-report',
          permission: 'loanpro-activity-report',
        },
        {
          label: 'Tickets Streams Report',
          icon: 'mdi-ticket-group',
          route: 'tickets-streams-report',
          permission: 'tickets-streams-report',
        },
        {
          label: 'Statements Report',
          icon: 'mdi-account-group',
          route: 'statements-report',
          permission: 'statements-report',
        },
        {
          label: 'Statements Audit Report',
          icon: 'mdi-account-group',
          route: 'statements-audit-report',
          permission: 'statements-audit-report',
        },
        {
          label: 'Statement Logs Report',
          icon: 'mdi-account-group',
          route: 'statement-logs-report',
          permission: 'statement-logs-report',
        },
        {
          label: 'Compliance Report',
          icon: 'mdi-account-group',
          route: 'compliance-report',
          permission: 'compliance-report',
        },
      ];

      const permissionTrim = this.user.permission.map((item) => item.name.split(' ').pop(''));

      const removeDupes = [...new Set(permissionTrim)];

      const reportPermissions = removeDupes.filter((permission) => permission.split('::')[0] === 'report').map((item) => item.split('::')[1]);

      return menus.filter((menu) => reportPermissions.includes(menu.permission));
    },
  },
};
</script>

<style></style>
