<template>
  <v-dialog
    v-model="show"
    @click:outside="close()"
  >
    <v-card v-if="ofacData">
      <v-card-title class="pb-4">
        <span class="headline mr-2">OFAC Match Data</span>
        <v-chip
          :color="statusColor"
          text-color="white"
        >
          {{ statusName }}
        </v-chip>
      </v-card-title>
      <v-card-subtitle>
        <div class="subtitle-1">
          Upload #{{ item.upload_id }}
        </div>
        <div class="subtitle-1">
          Match Recorded: {{ formatDateTime(item.created_on) }}
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-container fluid>
          <v-row dense>
            <v-col
              cols="6"
              sm="6"
              class="d-flex flex-column"
            >
              <v-card
                class="flex d-flex flex-column"
              >
                <v-card-title>
                  Match Data
                </v-card-title>
                <v-list>
                  <v-list-item-group>
                    <template
                      v-for="(ofacValue, ofacProperty) in removeEmpty(ofacData)"
                    >
                      <template
                        v-if="ofacValue !== null
                          && ofacValue !== undefined
                          && ofacValue !== Object(ofacValue)"
                      >
                        <v-list-item
                          :key="ofacProperty"
                          dense
                        >
                          <v-list-item-content
                            class="pt-0 pb-0"
                          >
                            <v-list-item-title
                              class="d-flex justify-lg-space-between"
                            >
                              <span>
                                <strong class="text-capitalize">
                                  {{ formatText(ofacProperty) }}
                                </strong>
                              </span>
                              <span>{{ ofacValue }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
            <template
              v-for="(ofacItem, index) in removeEmpty(ofacData)"
            >
              <v-col
                v-if="ofacItem !== null
                  && ofacItem !== undefined
                  && ofacItem === Object(ofacItem)
                  && Object.entries(ofacItem).length > 0"
                :key="index"
                cols="6"
                sm="6"
                class="d-flex flex-column"
              >
                <v-card
                  class="flex d-flex flex-column"
                >
                  <v-card-title class="text-capitalize">
                    {{ formatText(index) }}
                  </v-card-title>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(ofacValue, ofacProperty) in ofacItem"
                        :key="ofacProperty+ofacValue"
                        dense
                      >
                        <v-list-item-content
                          class="pt-0 pb-0"
                        >
                          <v-list-item-title
                            class="d-flex justify-lg-space-between"
                          >
                            <span>
                              <strong class="text-capitalize">
                                {{ formatText(ofacProperty) }}
                              </strong>
                            </span>
                            <span>{{ ofacValue }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          v-if="hasPermission('edit ofac::match') && statusName === 'open'"
          color="green"
          dark
          text
          @click="confirmMatch()"
        >
          Confirm Match
        </v-btn>

        <v-btn
          v-if="hasPermission('edit ofac::match') && statusName === 'open'"
          color="red"
          dark
          text
          @click="markFalse()"
        >
          Mark As False
        </v-btn>

        <v-btn
          color="primary"
          dark
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';

export default {
  name: 'OfacMatchesModal',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
        };
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    ofacData() {
      return this.item.ofac_data;
    },
    statusName() {
      return this.item.status ? this.item.status.status_name : '';
    },
    statusColor() {
      const colors = {
        open: 'blue',
        confirmed: 'green',
        false: 'red',
      };
      return colors[this.statusName];
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    confirmMatch() {
      this.$axios
        .put(`api/v1/ofac-match/${this.item.id}/confirm`)
        .then(() => {
          this.$emit('item-saved');

          this.setNotification({
            message: 'Match has been marked as confirmed.',
            color: 'green',
          });

          this.close();
        });
    },
    markFalse() {
      this.$axios
        .put(`api/v1/ofac-match/${this.item.id}/reject`)
        .then(() => {
          this.$emit('item-saved');

          this.setNotification({
            message: 'Match has been marked as false.',
            color: 'green',
          });

          this.close();
        });
    },
    close() {
      this.$emit('close-modal');
    },
    removeEmpty(item) {
      if (item === null || item === undefined) {
        return item;
      }
      return Object.fromEntries(
        Object.entries(item)
          .filter(([k, v]) => k && v !== null && v !== undefined)
          .map(([k, v]) => [k, v === Object(v) ? this.removeEmpty(v) : v]),
      );
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    },
    formatText(text) {
      return text
        .replaceAll('_', ' ')
        .replaceAll('ofac', 'OFAC')
        .replaceAll('ssn', 'SSN');
    },
  },
};
</script>

<style>
.v-list-item--dense {
  min-height: 20px;
}
</style>
