<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="button"
        :x-small="xSmall"
        :x-large="xLarge"
        :block="block"
        :text="textButton"
        :color="color"
        :class="buttonClass"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          v-if="icon !== 'none'"
          left
          dark
        >
          {{ icon }}
        </v-icon>
        {{ text }}
      </v-btn>
      <v-chip
        v-else-if="chip"
        small
        :color="chipColor"
        text-color="white"
        v-bind="attrs"
        v-on="on"
      >
        {{ text }}
      </v-chip>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Access Denied
      </v-card-title>
      <v-card-text
        v-if="customMessage === ''"
      >
        Your user role does not have access to the permission
        needed for this function.
        <!--        <br><br>-->
        <!--        <span class="font-weight-bold">{{ permission }}</span>-->
      </v-card-text>
      <v-card-text
        v-else
      >
        {{ customMessage }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="text"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    // Button text
    text: {
      type: String,
      required: true,
    },
    // Permission required to see actual button
    permission: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'none',
    },
    color: {
      type: String,
      default: 'error',
    },
    button: {
      type: Boolean,
      default: true,
    },
    chip: {
      type: Boolean,
      default: false,
    },
    chipColor: {
      type: String,
      default: 'success',
    },
    xSmall: {
      type: Boolean,
      default: true,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: '',
    },
    textButton: {
      type: Boolean,
      default: false,
    },
    customMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
