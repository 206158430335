<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col
        md="9"
      >
        <v-card>
          <v-card-title>
            Dev Menu
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-title>Generate a Loan with a Customer Journey</v-list-item-title>
                <v-list-item-action-text>
                  <v-btn
                    color="primary"
                    text
                    :to="{ name: 'waterfall-test' }"
                  >
                    Generate
                  </v-btn>
                </v-list-item-action-text>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-title>Generate a Loan (No Customer Journey)</v-list-item-title>
                <v-list-item-action-text>
                  <v-btn
                    color="primary"
                    text
                    :to="{ name: 'support-test-suite' }"
                  >
                    Generate
                  </v-btn>
                </v-list-item-action-text>
              </v-list-item>
              <v-divider v-if="settings.brand.prospect_active" />
              <v-list-item v-if="settings.brand.prospect_active">
                <v-list-item-title>
                  Generate a Prospect (You will to complete the whole prospect process)
                </v-list-item-title>
                <v-list-item-action-text>
                  <v-btn
                    color="primary"
                    text
                    :to="{ name: 'prescreen-prospect' }"
                  >
                    Generate
                  </v-btn>
                </v-list-item-action-text>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DevMenu',
  computed: {
    ...mapState('settings', ['settings']),
  },
};
</script>
