<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Manage Autopay
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="formatAutopays"
            :items-per-page="10"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-card-title>
                <v-toolbar flat>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="search"
                    single-line
                    hide-details
                  />

                  <v-spacer />

                  <create-recurring-autopay />

                  <v-spacer />

                  <v-btn
                    :to="{ name: 'ticket-add-payment', params: { id: $route.params.id } }"
                    class="btn-bg-deep-blue"
                  >
                    <v-icon>mdi-plus</v-icon>
                    New payment method
                  </v-btn>

                  <!--  -->
                </v-toolbar>
              </v-card-title>
            </template>
            <template v-slot:item.amount="{ item }">
              ${{ item.amount }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="hasPermission('view ticket::cancel-payment')"
                color="red"
                class="mr-2"
                @click="confirmCancel(item)"
              >
                mdi-delete
              </v-icon>
              <disabled-for-lack-of-role-btn
                v-else
                icon="mdi-close"
                text="Cancel"
                permission="view ticket::cancel-payment"
                :x-small="true"
                button-class="mr-2"
              />
              <update-auto-pay-payment-profile
                :loan-id="ticket.active_loan.id"
                :autopay="item"
              />
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingModel"
            :to="{ name: 'ticket-show', params: { id: $route.params.id } }"
          >
            Cancel
          </v-btn>
        </v-card-actions>
        <v-dialog
          v-if="selectedAutopay"
          v-model="notEligibleModal"
          max-width="800px"
          @click:outside="notEligibleModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">This Payment is not Eligible to Cancel</span>
            </v-card-title>
            <v-card-text>
              <v-alert
                type="error"
              >
                <p>
                  This Payment cannot be canceled since it was already completed. Sometimes a
                  payment
                  can still be canceled if a Nacha file has not been created for it yet. The Nacha
                  file gets create at 3:00 pm Central on the apply date:
                  {{ selectedAutopay.apply_date }}.
                </p>
              </v-alert>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-if="selectedAutopay"
          v-model="confirmModal"
          max-width="800px"
          @click:outside="confirmModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Are you sure?</span>
            </v-card-title>

            <v-card-text>
              <v-alert
                v-if="selectedAutopay.status === 'autopay.status.completed'"
                type="warning"
              >
                <p>
                  <b>Note for CSR to Read before Confirming:</b>
                </p>
                <p>
                  This Autopay has already been completed. However, the payment has not been
                  processed yet, so it is still eligible to be canceled. Canceling this payment
                  will actually reverse the payment (since it has not been processed yet) and
                  change the autopay status from "Completed" to "Canceled". If there are
                  recurring payments for this autopay, they have already been scheduled and will
                  not be unaffected by this action.
                </p>
              </v-alert>
              <v-alert
                v-else-if="selectedAutopay.type === 'autopay.type.recurring'"
                type="warning"
              >
                <p>
                  <b>Note for CSR to Read before Confirming:</b>
                </p>
                <p>
                  This Payment is a Recurring Autopay. Canceling this payment will only
                  cancel this one Payment scheduled {{ selectedAutopay.apply_date }}
                  and the rest will still be processed according to
                  the schedule (weekly, bi-weekly, monthly, etc).
                  Additionally, this Autopay will still appear in this table, but it
                  will have a different date once it is "Canceled".
                  If you want to cancel the
                  Autopay and all future recurring payments, you are in the wrong spot.
                  Instead, please navigate to the "Revoke Autopay" action.
                </p>
              </v-alert>
              Clicking confirm will cancel the following payment:
              <v-list dense>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>ID</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedAutopay.id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedAutopay.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Date</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedAutopay.apply_date }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Amount</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-list-item-subtitle>${{ selectedAutopay.amount }}</v-list-item-subtitle>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-progress-circular
                v-if="loadingModel"
                indeterminate
                color="primary"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                v-if="hasPermission('create ticket::cancel-payment')"
                color="blue darken-1"
                text
                @click="cancelPayment(selectedAutopay)"
              >
                Confirm
              </v-btn>
              <disabled-for-lack-of-role-btn
                v-else
                text="Confirm"
                permission="create ticket::cancel-payment"
                :x-small="false"
                :text-button="true"
              />

              <v-btn
                color="blue darken-1"
                text
                @click="confirmModal = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="paymentModal"
          max-width="500px"
          @click:outside="paymentModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Payment Canceled</span>
            </v-card-title>

            <v-card-text>
              <p>Would you like to perform another task or mark this ticket as solved?</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                color="blue darken-1"
                text
                @click="anotherTask()"
              >
                Another Task
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="resolveTicket()"
              >
                Resolve Ticket
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDate } from '@/services/HelperService';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';
import UpdateAutoPayPaymentProfile from './UpdateAutopayPaymentProfile.vue';
import CreateRecurringAutopay from './CreateRecurringAutopay.vue';

export default {
  name: 'TicketAutopay',
  components: {
    UpdateAutoPayPaymentProfile,
    DisabledForLackOfRoleBtn,
    CreateRecurringAutopay,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    autopays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmModal: false,
      notEligibleModal: false,
      paymentModal: false,
      selectedAutopay: null,
      loadingModel: false,
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Autopay Status', value: 'status', sortable: false },
        { text: 'Processor Status', value: 'processor_status', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Frequency', value: 'recurring_frequency', sortable: false },
        { text: 'Recurring Periods', value: 'recurring_periods', sortable: false },
        { text: 'Apply Date', value: 'apply_date', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: '',
    };
  },
  computed: {
    formatAutopays() {
      return (this.autopays || []).map((autopay) => ({
        ...autopay,
        status: autopay.status.split('.')[2].toUpperCase(),
        type: autopay.type.split('.')[2].toUpperCase(),
        recurring_frequency: autopay.recurring_frequency.split('.')[2].toUpperCase(),
        apply_date: formatDate(autopay.apply_date),
        process_datetime: formatDate(autopay.process_datetime),
        payment_method_type: autopay.primary_payment_account.type.split('.')[2],
        payment_method_title: autopay.primary_payment_account.title,
        processor_status: autopay?.payment_entity?.payment_info?.processor_status || '---',
      })).sort((a, b) => (a.apply_date < b.apply_date ? 1 : -1));
    },
  },
  methods: {
    ...mapActions('loanProLoan', ['getAutopays']),
    ...mapActions('notification', ['setNotification']),
    // eslint-disable-next-line consistent-return
    async cancelPayment(autopay) {
      this.loadingModel = true;
      // There are 3 possible scenarios for cancel payment:
      // First is that the payment has been completed, but has not been nachafied
      // In this situtation we will reverse the payment and then cancel the autopay
      // so that it does not appear as a cancel-able payment anymore
      // note: If it has been nachafied, it should never reach this cancel function
      if (autopay?.payment_entity?.payment_info?.processor_status === 'COMPLETED') {
        try {
          const res = await this.$axios
            .post('api/v1/support/ticket/loan/autopays/reverse-payment', {
              ticketId: this.$route.params.id,
              autopayId: autopay.id * 1,
              loanId: this.ticket?.active_loan?.id || 0,
            });
          if (res.data.success) {
            await this.sendCancelPayment(autopay);
            this.setNotification({
              message: 'We were successfully able to Cancel the next payment from this recurring autopay',
              color: 'green',
            });
          }
        } catch (error) {
          this.setNotification({
            message: 'We could not locate a payment to cancel for this Autopay',
            color: 'red',
          });
        }
        // Second scenario is that the payment type is recurring. In this case we only want to
        // cancel the next payment and leave the autopay in tact
      } else if (this.selectedAutopay.type === 'autopay.type.recurring') {
        await this.$axios
          .post(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment/cancel-first/${autopay.id}`, {
            ticketId: this.$route.params.id,
          })
          .then(async (res) => {
            if (res.data.success) {
              await this.sendCancelPaymentEmail(autopay);
              await this.getAutopays();
              this.setNotification({
                message: 'We were successfully able to Cancel the next payment from this recurring autopay',
                color: 'green',
              });
              this.loadingModel = false;
              this.confirmModal = false;
              this.paymentModal = true;
            } else {
              this.setNotification({
                message: res.data.message,
                color: 'red',
              });
            }
          })
          .catch(() => {
            this.setNotification({
              message: 'We were unable to Cancel the next payment from this recurring autopay',
              color: 'red',
            });
            this.loadingModel = false;
            this.confirmModal = false;
          });
        // Third scenario is that the autopay is not recurring so we can cancel it outright.
      } else {
        await this.sendCancelPayment(autopay);
      }
    },
    async sendCancelPayment(autopay) {
      await this.$axios
        .post(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment/${autopay.id}/cancel`, {
          ticketId: this.$route.params.id,
          firstName: this.ticket?.lpCustomer?.first_name || '',
          date: autopay.apply_date,
          amount: autopay.amount,
        })
        .then(async () => {
          await this.sendCancelPaymentEmail(autopay);
          await this.getAutopays();
          this.loadingModel = false;
          this.confirmModal = false;
          this.paymentModal = true;
        });
    },
    async sendCancelPaymentEmail(autopay) {
      await this.$axios
        .post('api/v1/support/ticket/loan/autopays/send-cancel-payment-email', {
          ticketId: this.$route.params.id,
          firstName: this.ticket?.lpCustomer?.first_name || '',
          date: autopay.apply_date,
          amount: autopay.amount,
        });
    },

    anotherTask() {
      this.paymentModal = false;
      this.$router.push({
        name: 'ticket-show',
        params: {
          id: this.$route.params.id,
        },
      });
    },

    resolveTicket() {
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.loadingModel = false;

          this.$router.push({
            name: 'tickets',
          });
        });
    },

    async isAutopayEligibleToCancel(autopay) {
      try {
        const res = await this.$axios
          .post('api/v1/support/ticket/loan/autopays/eligible-to-cancel', {
            ticketId: this.ticket.zendesk.id,
            // applyDate: autopay.apply_date,
            processDate: autopay.process_datetime,
          });
        if (res.data.isEligible) {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async confirmCancel(autopay) {
      let isEligible = true;
      if (autopay.status === 'autopay.status.completed') {
        isEligible = await this.isAutopayEligibleToCancel(autopay);
      }
      this.selectedAutopay = autopay;
      if (isEligible) {
        this.confirmModal = true;
      } else {
        this.notEligibleModal = true;
      }
    },
  },
};
</script>
