<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Cancel Upcoming Payment
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="pendingAutopays"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:item.amount="{ item }">
              ${{ item.amount }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="hasPermission('view ticket::cancel-payment') && recurringPaymentHasNotBeenPushedAlready(item)"
                x-small
                color="red"
                class="mr-2"
                @click="confirmCancel(item)"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-close
                </v-icon>
                Cancel
              </v-btn>
              <v-dialog
                v-else-if="hasPermission('view ticket::cancel-payment')"
                v-model="cannotPushAutopayDialog"
                max-width="800px"
                @click:outside="cannotPushAutopayDialog = false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    color="blue"
                    class="mr-2"
                    @click="cannotPushAutopayDialog = true"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-close
                    </v-icon>
                    Cannot Cancel
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Payment Canceled</span>
                  </v-card-title>

                  <v-card-text>
                    <v-alert
                      prominent
                      dense
                      dark
                      text
                      border="left"
                      color="primary"
                      icon="mdi-account-voice"
                    >
                      <blockquote class="blockquote">
                        This Payment is part of a Recurring Autopay. Its upcoming period was already canceled.
                        Please have the borrower call back after their next payment date of,
                        {{ ticket.active_loan.current_status.next_payment_date }}, as this autopay will be
                        eligible to cancel its upcoming payment again.
                      </blockquote>
                    </v-alert>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />

                    <v-btn
                      color="blue darken-1"
                      text
                      @click="cannotPushAutopayDialog = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>


              <disabled-for-lack-of-role-btn
                v-else
                icon="mdi-close"
                text="Cancel"
                permission="view ticket::cancel-payment"
                :x-small="true"
                button-class="mr-2"
              />
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingModel"
            :to="{ name: 'ticket-show', params: { id: $route.params.id } }"
          >
            Cancel
          </v-btn>
        </v-card-actions>

        <v-dialog
          v-if="selectedAutopay"
          v-model="confirmModal"
          max-width="800px"
          @click:outside="confirmModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Are you sure?</span>
            </v-card-title>

            <v-card-text>
              <v-alert
                v-if="selectedAutopay.type === 'autopay.type.recurring' && selectedAutopay.recurring_periods > 1"
                prominent
                dense
                dark
                text
                border="left"
                color="primary"
                icon="mdi-account-voice"
              >
                <blockquote class="blockquote">
                  This Payment is part of a Recurring Autopay. Canceling this payment will cancel
                  the payment scheduled for {{ selectedAutopay.apply_date }}. It will then schedule
                  your next Payment to be pulled via Autopay according to your payment schedule
                  (weekly, bi-weekly, monthly, etc).
                </blockquote>
                If you want to cancel the
                Autopay and all future recurring payments, you are using the wrong Tbot.
                Instead, please navigate to "Revoke Autopay".
              </v-alert>

              <v-alert
                v-else
                prominent
                dense
                dark
                text
                border="left"
                color="primary"
                icon="mdi-account-voice"
              >
                <blockquote class="blockquote">
                  This Payment has no recurring periods remaining after this one or is part of a
                  Single Autopay. Therefor if you cancel this Payment, no other Payments will be
                  scheduled as a result.
                </blockquote>
              </v-alert>
              <strong>Click confirm to proceed</strong>
              <v-list dense>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>ID</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedAutopay.id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedAutopay.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Date</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedAutopay.apply_date }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Amount</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-list-item-subtitle>${{ selectedAutopay.amount }}</v-list-item-subtitle>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-progress-circular
                v-if="loadingModel"
                indeterminate
                color="primary"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                v-if="hasPermission('create ticket::cancel-payment')"
                color="blue darken-1"
                text
                :loading="loading"
                @click="cancelUpcomingPayment()"
              >
                Confirm
              </v-btn>
              <disabled-for-lack-of-role-btn
                v-else
                text="Confirm"
                permission="create ticket::cancel-payment"
                :x-small="false"
                :text-button="true"
              />

              <v-btn
                color="blue darken-1"
                text
                @click="confirmModal = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="paymentModal"
          max-width="500px"
          @click:outside="paymentModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Payment Canceled</span>
            </v-card-title>

            <v-card-text>
              <p>Would you like to perform another task or mark this ticket as solved?</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                color="blue darken-1"
                text
                @click="anotherTask()"
              >
                Another Task
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="resolveTicket()"
              >
                Resolve Ticket
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketCancelUpcomingPayment',
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    autopays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmModal: false,
      paymentModal: false,
      selectedAutopay: null,
      loadingModel: false,
      loading: false,
      cannotPushAutopayDialog: false,
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Date', value: 'apply_date', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    pendingAutopays() {
      // We only want to display pending autopays as this Tbot will only allow us to:
        // Revoking a single autopay
        // Revoking a recurring autopay with only 1 recurring period
        // Pushing a recurring autopay to its next period, effective canceling it’s upcoming period.
      return (this.autopays || []).filter((autopay) => (autopay?.status || '') === 'autopay.status.pending');
    },
  },
  methods: {
    ...mapActions('loanProLoan', ['getAutopays']),
    ...mapActions('notification', ['setNotification']),
    async cancelUpcomingPayment() {
      this.loading = true;
      // This API request will do the following depending on the autopay:
        // Revoking a single autopay
        // Revoking a recurring autopay with only 1 recurring period
        // Pushing a recurring autopay to its next period, effective canceling it’s upcoming period.
      try {
        const response = await this.$axios
          .post(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment/${this.selectedAutopay.id}/cancel-upcoming-payment`, {
            ticketId: this.$route.params.id,
          });

        if (response.data.success) {
          await this.getAutopays();
          this.setNotification({
            message: response?.data?.message || 'We successfully processed the request',
            color: 'green',
          });
          this.loading = false;
          this.confirmModal = false;
          this.paymentModal = true;
        } else {
          this.setNotification({
            message: response?.data?.message || 'We were unable complete the request',
            color: 'red',
          });
          this.loading = false;
        }
      } catch (error) {
        const errorsObj = Object.values(error?.response?.data?.errors || {first: null})[0]

        this.setNotification({
          message: errorsObj || (error?.response?.data?.message || (error?.message || 'Error processing request')),
          color: 'red',
        });
        this.loading = false;
      }
    },

    anotherTask() {
      this.paymentModal = false;
      this.$router.push({
        name: 'ticket-show',
        params: {
          id: this.$route.params.id,
        },
      });
    },

    resolveTicket() {
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.loadingModel = false;

          this.$router.push({
            name: 'tickets',
          });
        });
    },

    async confirmCancel(autopay) {
      this.selectedAutopay = autopay;
      this.confirmModal = true;
    },

    recurringPaymentHasNotBeenPushedAlready(autopay) {
      // autopay will be revoked since it is not recurring
      if ((autopay?.type || '') !== 'autopay.type.recurring') {
        return true;
      }

      // autopay will be revoked in this scenario, not pushed, so return true
      if ((autopay?.recurring_periods || 0) <= 1) {
        return true;
      }

      return !((autopay?.apply_date || '') > this.ticket.active_loan.current_status.next_payment_date);
    }
  },
};
</script>
