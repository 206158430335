<template>
  <div class="content-holder">
    <div class=" p-s">
      <h2 class="mb-xs mb-2">
        Your current loan balance is {{ todayPayoffBalance | currency }}.
      </h2>

      <p v-if="isCovidCollection">
        To help you during this difficult time, we are willing to offer
        you a new payment plan for paying back your loan.
      </p>
      <p v-else>
        To help you avoid the serious consequences of defaulting on your loan, we are offering
        you two simple options for resolving your debt.
      </p>

      <FloatingIconBlock
        :class="['mt-s mb-3 settlement-option',
                 { selected: isSelected(views.PAY_FULL_BALANCE) }]"
        @click.native="selectedRoute = views.PAY_FULL_BALANCE"
      >
        <div
          slot="text"
          class="pa-5"
        >
          <div v-if="isCovidCollection">
            <h2 class="header mb-2">
              Create New Payment Plan
            </h2>
            <p>
              Tell us how much you can afford and how often you can make payments.
              Select the plan that works best for your current financial situation.
            </p>
          </div>
          <div v-else>
            <h2 class="header mb-2">
              Pay Your Full Balance
            </h2>
            <p>
              Pay off your loan and never worry about it again.
              <i>Avoid long-term negative effects to your credit.</i>
            </p>
          </div>
        </div>
      </FloatingIconBlock>

      <FloatingIconBlock
        :class="['mt-s mb-3 settlement-option',
                 { selected: isSelected(views.SETTLEMENT_OFFER) }]"
        @click.native="selectedRoute = views.SETTLEMENT_OFFER"
      >
        <div
          slot="text"
          class="pa-5"
        >
          <div v-if="isCovidCollection">
            <h2 class="header mb-2">
              Make Settlement Offer
            </h2>
            <p>
              Don't think you'll be able to pay off your loan? You may be able to settle for less
              than you owe.
            </p>
          </div>
          <div v-else>
            <h2 class="header mb-2">
              Make Settlement Offer
            </h2>
            <p>
              Refuse to pay your balance in full? You may be able to settle your debt for less
              than you owe.
            </p>
          </div>
        </div>
      </FloatingIconBlock>
      <v-card-actions
        class="justify-end"
      >
        <v-btn
          button-full
          size="large"
          color="settlement"
          @click="getStarted()"
        >
          GET STARTED
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>

import FloatingIconBlock from '@/components/ui/FloatingIconBlock.vue';

export default {
  name: 'DebtSettlement',

  components: {
    FloatingIconBlock,
  },

  props: {
    todayPayoffBalance: {
      type: Number,
      required: true,
    },
    isCovidCollection: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      selectedRoute: '',
      views: {
        PAY_FULL_BALANCE: 'PAY IN FULL',
        SETTLEMENT_OFFER: 'SETTLEMENT OFFER',
      },
    };
  },

  computed: {
  },

  methods: {
    isSelected(value) {
      return this.selectedRoute === value;
    },

    getStarted() {
      if (this.selectedRoute === this.views.PAY_FULL_BALANCE) {
        this.$emit('pay-full-balance');
      }

      if (this.selectedRoute === this.views.SETTLEMENT_OFFER) {
        this.$emit('settlement-offer');
      }
    },

  },
};
</script>

<style lang="scss">
.settlement-option {
  padding: 25px;
  border-radius: 5px;
  border: #000000 1px solid;
  cursor: pointer;
  width: 100%;

  &.selected {
    border: #119911 2px solid;

    .icon,
    .header {
      color: #119911;
    }
  }
}

.title {
  color: #ff0000;
  text-transform: uppercase;
  font-size: 1.1em;
  line-height: 1.2;
  font-weight: 500;
}
</style>
