<template>
  <v-dialog
    v-model="dialog"
    width="400px"
    @click:outside="dialog = !dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        small
        v-on="on"
      >
        mdi-help-circle-outline
      </v-icon>
    </template>
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">
          Total Paid Breakout
        </span>
      </v-card-title>

      <v-card-text class="my-5">
        <v-simple-table>
          <thead>
            <tr>
              <th>Interest</th>
              <th>Principal</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, key) in paidBreakout"
              :key="key"
            >
              <td>{{ formatCurrency(data.paymentInterest) }}</td>
              <td>{{ formatCurrency(data.paymentPrincipal) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="dialog = !dialog"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PaidBreakoutModal',
  props: {
    loanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      formProcessing: false,
      loading: false,
      dialog: false,
      paidBreakout: [],
    };
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
    },
  },
  async created() {
    this.paidBreakout = await this.getPaidBreakout();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async getPaidBreakout() {
      const res = await this.$axios
        .get(`api/v1/support/ticket/loan/${this.loanId}/paid-breakdown`);
      return res.data;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(value);
    },
  },
};
</script>
