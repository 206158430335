<template>
  <v-col
    class="p-2"
    cols="12"
    sm="6"
    md="3"
  >
    <v-card
      class="pl-2 pr-2"
      min-height="1115px"
    >
      <v-card-title>
        <span
          class="text-h6 d-inline-block"
          v-text="col.name"
        />
      </v-card-title>
      <v-divider />

      <v-card-text
        class="drop-zone"
        @drop="onDrop($event, 1)"
        @dragover.prevent
        @dragenter.prevent
      >
        <template v-if="ticketByStatus.length">
          <OpsTicketCard
            v-for="ticket in ticketByStatus"
            :key="ticket.id"
            :ticket="ticket"
            @open-ticket-dialog="openTicket"
          />
        </template>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import OpsTicketCard from '@/views/Helpdesk/components/OpsTicketDashboard/OpsTicketCard.vue';

export default {
  name: 'OpsTicketStatusCol',
  components: { OpsTicketCard },

  props: {
    col: {
      type: Object,
      required: true,
    },
    opsTickets: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loadingTickets: false,
    };
  },

  computed: {
    ticketByStatus() {
      return this.opsTickets.filter((opsTicket) => opsTicket.status.id === this.col.id);
    },
  },

  watch: {
    col(newValue) {
      if (newValue) {
        this.getOpsTickets();
      }
    },
  },

  methods: {
    onDrop(evt) {
      const ticket = JSON.parse(evt.dataTransfer.getData('ticket'));

      this.$emit('update-ticket', { ticket, col: this.col });
    },

    openTicket(ticket) {
      this.$emit('open-ticket-dialog', ticket);
    },
  },
};
</script>

<style scoped>
.drop-zone {
  background-color: #2a3244;
  height: 1045px;
  overflow-y: scroll;
}
</style>
