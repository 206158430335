<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-title
          class="text-body-2"
          v-bind="attrs"
          v-on="on"
        >
          Status
        </v-list-item-title>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Status Settings
        </v-card-title>

        <v-card-text v-if="!addingStatus">
          <OpsTicketStatusList
            @editing-status="editStatus"
            @toggle-status="toggleStatus()"
          />
        </v-card-text>

        <v-card-text v-else>
          <OpsTicketStatusAddEdit
            :active-status="activeStatus"
            @status-list="showStatusList"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OpsTicketStatusAddEdit from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketStatusAddEdit.vue';
import OpsTicketStatusList from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketStatusList.vue';

export default {
  name: 'OpsTicketStatusDialog',
  components: { OpsTicketStatusList, OpsTicketStatusAddEdit },

  data: () => ({
    dialog: false,
    activeStatus: null,
    addingStatus: false,
  }),

  methods: {
    toggleStatus() {
      this.activeStatus = null;
      this.addingStatus = true;
    },

    showStatusList() {
      this.addingStatus = false;
    },

    editStatus(item) {
      this.addingStatus = true;
      this.activeStatus = item;
    },
  },
};
</script>

<style scoped></style>
