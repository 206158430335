<template>
  <div>
    <PaymentHeader
      title="Thank You"
      :display-id="displayId"
      :full-name="fullName"
      class="mb-10"
    />

    <h1 class="green--text mb-5">
      {{ isPayoff ? "Your payoff schedule" : "Your settlement offer" }} is confirmed.
    </h1>

    <div v-if="isManualPayment">
      <p>
        Since you chose to make manual payments, you will need to log into your account to make your payment.
      </p>
    </div>
    <div v-else>
      <div v-if="isAchPayment">
        <p>
          Your <span v-if="!isSinglePayment">first</span> payment of {{ paymentAmount | currency }}
          will be withdrawn from your bank account via ACH on
          {{ firstPaymentDate | formatdate }}.
        </p>
        <hr class="mb-5">
        <p>
          <b>Note:</b> We process payments at 3:00 p.m. (Central Time) on business days.
          Payment will be pulled from your account within one business day. <br>For example,
          Friday payments are process at 3:00 p.m. on Thursday.
        </p>
      </div>
      <div v-else>
        <p>
          Your <span v-if="!isSinglePayment">first</span> payment of {{ paymentAmount | currency }}
          will be withdrawn from your debit card on
          {{ firstPaymentDate | formatdate }}.
        </p>
      </div>
    </div>
    <TicketActions />
  </div>
</template>

<script>
import PaymentHeader from './PaymentHeader.vue';
import offerTypes from './offerTypes';
import TicketActions from '../Tickets/TicketActions.vue';
import {mapState} from "vuex";

export default {
  name: 'SettlementConfirmation',

  components: { TicketActions, PaymentHeader },

  props: {
    offerType: {
      type: String,
      required: true,
    },
    numberOfPayments: {
      type: Number,
      required: true,
    },
    isAchPayment: {
      type: Boolean,
      required: true,
    },
    paymentAmount: {
      type: Number,
      required: true,
    },
    firstPaymentDate: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    displayId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showPopup: true,
    };
  },

  computed: {
    ...mapState('settlement', ['isManualPayment']),

    isPayoff() {
      return this.offerType === offerTypes.PAY_IN_FULL;
    },
    isSinglePayment() {
      return this.numberOfPayments === 1;
    },
  },

};
</script>
