<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  required
                  label="First Name"
                  :error-messages="firstNameErrors"
                  @input="$v.firstName.$touch()"
                  @blur="$v.firstName.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  required
                  label="Last Name"
                  :error-messages="lastNameErrors"
                  @input="$v.lastName.$touch()"
                  @blur="$v.lastName.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="username"
                  required
                  label="Username"
                  :error-messages="usernameErrors"
                  @input="$v.username.$touch()"
                  @blur="$v.username.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  required
                  label="Email"
                  :error-messages="emailErrors"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-alert
                  class="ma-0"
                  dense
                  text
                  type="info"
                  border="left"
                >
                  <strong>Note:</strong> Admin role has access to everything.
                  If Admin is not selected, the user only has access to the area(s) selected.
                </v-alert>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="roles"
                  :items="userRoles"
                  item-text="name"
                  label="Roles"
                  multiple
                  :error-messages="rolesErrors"
                  @input="$v.roles.$touch()"
                  @blur="$v.roles.$touch()"
                />
              </v-col>
            </v-row>

            <v-row v-if="isNewItem">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="password"
                  required
                  label="Password"
                  :error-messages="passwordErrors"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="passwordConfirmed"
                  required
                  label="Confirm Password"
                  :error-messages="passwordConfirmedErrors"
                  @input="$v.passwordConfirmed.$touch()"
                  @blur="$v.passwordConfirmed.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-checkbox
                  v-model="require_reset"
                  label="Require password reset at next login"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          v-if="hasPermission('edit user-admin::user') || hasPermission('create user-admin::user')"
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          roles: [],
        };
      },
    },
  },
  data() {
    return {
      id: null,
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      roles: [],
      password: '',
      passwordConfirmed: '',
      formProcessing: false,
      require_reset: false,
    };
  },
  computed: {
    ...mapState('userPermission', { userRoles: 'all_roles' }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      const newOrUpdate = this.isNewItem ? 'New' : 'Update';
      return `${newOrUpdate} User`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    firstNameErrors() {
      const errors = [];

      if (!this.$v.firstName.$dirty) {
        return errors;
      }
      if (!this.$v.firstName.required) {
        errors.push('First Name is required.');
      }

      return errors;
    },
    lastNameErrors() {
      const errors = [];

      if (!this.$v.lastName.$dirty) {
        return errors;
      }
      if (!this.$v.lastName.required) {
        errors.push('Last Name is required.');
      }

      return errors;
    },
    usernameErrors() {
      const errors = [];

      if (!this.$v.username.$dirty) {
        return errors;
      }
      if (!this.$v.username.required) {
        errors.push('Username is required.');
      }
      if (!this.$v.username.minLength) {
        errors.push(`Username must be more than ${this.$v.username.$params.minLength.min} letters.`);
      }

      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.required) {
        errors.push('Email is required.');
      }
      if (!this.$v.email.email) {
        errors.push('Must be a valid email.');
      }

      return errors;
    },
    rolesErrors() {
      const errors = [];

      if (!this.$v.roles.$dirty) {
        return errors;
      }
      if (!this.$v.roles.required) {
        errors.push('User Role is required.');
      }

      return errors;
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.required) {
        errors.push('Password is required.');
      }
      if (!this.$v.password.minLength) {
        errors.push(`Password must be more than ${this.$v.password.$params.minLength.min} letters.`);
      }

      return errors;
    },
    passwordConfirmedErrors() {
      const errors = [];

      if (!this.$v.passwordConfirmed.$dirty) {
        return errors;
      }
      if (!this.$v.passwordConfirmed.sameAs) {
        errors.push('Password confirmation must match.');
      }

      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.firstName = this.editedItem.first_name;
      this.lastName = this.editedItem.last_name;
      this.username = this.editedItem.username;
      this.email = this.editedItem.email;
      this.roles = this.editedItem.roles;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';

        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          email: this.email,
          roles: this.roles,
          require_password_reset: this.require_reset,
        };

        if (this.isNewItem) {
          data = {
            ...data,
            password: this.password,
            password_confirmation: this.passwordConfirmed,
          };

          request = this.$axios.post('/api/v1/user', data);
        } else {
          request = this.$axios.put(`/api/v1/user/${this.editedItem.id}`, data);
        }

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.first_name} has been saved.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.password = '';
      this.passwordConfirmed = '';
      this.$emit('close-modal');
    },
    formatRoles(roles) {
      // makes sure value is an int to be recognized by the select
      return Array.isArray(roles)
        ? roles.map((item) => (Math.trunc(item)))
        : [];
    },
  },
  validations() {
    let validationObj = {
      firstName: { required },
      lastName: { required },
      username: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      roles: { required },
    };

    if (this.isNewItem) {
      validationObj = {
        ...validationObj,
        password: {
          required,
          minLength: minLength(5),
        },
        passwordConfirmed: {
          sameAs: sameAs('password'),
        },
      };
    }

    return validationObj;
  },
};
</script>

<style></style>
