<template>
  <v-flex class="login-form">
    <v-snackbar
      v-model="show"
      top
      right
      :color="notification.color"
    >
      {{ notification.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-form
      ref="form"
      @submit.prevent="submit"
    >
      <v-card
        class="rounded-0"
        style="box-shadow: 2px 2px 4px 0px #2a3244 !important;"
      >
        <v-col class="col-12 text-center text-uppercase font-weight-bold btn-bg-gunmetal py-5">
          {{ productName }}
        </v-col>
      </v-card>
      <v-card
        class="mx-auto pa-5"
        outlined
        tile
        elevation="6"
      >
        <v-card-title class="my-1 py-7">
          <v-img
            contain
            height="38px"
            position="center"
            :src="require(`@/assets/hyphen/logo.png`)"
          />
        </v-card-title>

        <v-card-text class="mt-6 pb-0">
          <v-alert
            v-if="formError"
            color="red"
            type="error"
          >
            {{ formError }}
          </v-alert>
          <v-text-field
            v-model="username"
            prepend-inner-icon="mdi-account-outline"
            label="Username"
            height="70px"
            outlined
            required
            @input="$v.username.$touch()"
            @blur="$v.username.$touch()"
          />
          <!-- ./username -->

          <v-text-field
            v-model="password"
            prepend-inner-icon="mdi-lock-outline"
            label="Password"
            height="70px"
            type="password"
            outlined
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          />
          <!-- ./password  -->
        </v-card-text>

        <v-card-actions class="mb-3 px-4">
          <v-btn
            type="submit"
            color="primary"
            large
            block
            :loading="formProcessing"
            :icon="formProcessing"
          >
            <v-icon
              light
              left
            >
              mdi-login-variant
            </v-icon> Login
          </v-btn>
        </v-card-actions>
        <p class="text-right">
          <router-link :to="{ name: 'forgot-password'}">
            Forgot your password?
          </router-link>
        </p>
        <div class="text-center">
          <span class="text-caption font-weight-thin text-center">{{ version }}</span>
        </div>
      </v-card>
    </v-form>
    <two-factor-auth
      :dialog="twoFactorAuthDialog"
      @failedMfa="resetForm"
    />
  </v-flex>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import TwoFactorAuth from './TwoFactorAuth.vue';

export default {
  components: { TwoFactorAuth },
  data() {
    return {
      show: false,
      username: '',
      password: '',
      formProcessing: false,
      formError: '',
      twoFactorAuthDialog: false,
    };
  },
  computed: {
    ...mapState('notification', ['notification']),
    ...mapGetters('app', ['assetPath', 'version', 'name']),
    ...mapGetters('settings', ['productName']),
  },
  watch: {
    notification(value) {
      this.show = value;
    },
  },
  validations: {
    username: { required },
    password: { required },
  },

  methods: {
    ...mapActions('currentUser', ['logUserIn']),
    async submit() {
      this.setFormState();
      try {
        await this.$axios.get('/sanctum/csrf-cookie');
        const res = await this.$axios.post('/login', { username: this.username, password: this.password });

        if (res.data.two_factor === true) {
          // pop-up modal for 2fa login
          this.twoFactorAuthDialog = true;
          this.setFormState(false, '');
        } else if (res.data.two_factor === false
          && res.data.user.permission
          && res.data.user.permission.length
        ) {
          this.logUserIn(res.data.user);
          this.$router.push({ name: 'dashboard-home' });
        } else {
          this.setFormState(false, 'Authentication error');
        }
      } catch (error) {
        this.setFormState(
          false,
          error.response.data.errors.username.pop() ?? error.response.data.message,
        );
      }
    },
    setFormState(processing = true, message = '') {
      this.formProcessing = processing;
      this.formError = message;
    },
    resetForm() {
      this.username = '';
      this.password = '';
      this.twoFactorAuthDialog = false;
    },
  },
};
</script>

<style>
.login-form {
  /*max-width: 480px;*/
  max-width: 35vw;
  margin-bottom: 15vh;
}
.login-form .v-card {
  box-shadow: 2px 2px 4px 0px grey !important;
}
.login-form .v-btn.primary {
  background-color: #205e86 !important;
  height: 3.5rem !important;
  font-size: 1.1rem !important;
  font-weight: 800;
}
.login-form .v-btn.primary .v-icon {
  font-size: 2.2rem !important;
  font-weight: 400 !important;
  margin-right: 1rem;
  -webkit-text-stroke: 1px #205e86;
}
.login-form .v-input__icon {
  width: 40px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.login-form .v-input__icon .v-icon.v-icon {
  color: #4d4d4d;
  font-size: 2rem;
  -webkit-text-stroke: 1px #ffffff;
}
.login-form .v-input .v-label {
  padding: 0.5rem 1rem;
  height: auto;
}
.login-form .v-label--active {
  padding: 0rem 1rem !important;
}
.login-form .v-input input {
  padding: 0rem 1rem;
  background-color: #e9eaec;
  min-height: 45px !important;
  max-height: 50px;
}
.login-form .v-input__slot {
  background: transparent !important;
}
</style>
