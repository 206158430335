<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Tickets</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />
          <v-spacer />
        </v-toolbar>
      </v-card-title>
    </template>
    <template v-slot:item.actions="{ item }">
      <disabled-for-lack-of-role-btn
        v-if="item.status !== 'open'"
        text="View"
        permission="view ticket::show"
        icon="mdi-ticket"
        :x-small="true"
        button-class="mr-2"
        :custom-message="statusNotOpenMessage"
      />
      <v-btn
        v-else-if="hasPermission('view ticket::show') && item.has_access"
        x-small
        color="primary"
        class="mr-2"
        @click="verifyTicketOwnerStatus(item)"
      >
        <v-icon
          left
          dark
        >
          mdi-ticket
        </v-icon>
        View
      </v-btn>
      <disabled-for-lack-of-role-btn
        v-else-if="hasPermission('view ticket::show') && !item.has_access"
        text="View"
        permission="view ticket::show"
        icon="mdi-ticket"
        :x-small="true"
        button-class="mr-2"
        custom-message="You do not have access to this Ticket. It is assigned to someone else."
      />
      <disabled-for-lack-of-role-btn
        v-else
        text="View"
        permission="view ticket::show"
        icon="mdi-ticket"
        :x-small="true"
        button-class="mr-2"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex';
import dataTableMixin from '@/mixins/dataTableMixin';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketsIndex',

  components: {
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data() {
    return {
      headers: [
        { text: 'Ticket ID', value: 'id', sortable: false },
        { text: 'Customer ID', value: 'customer_id', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: '',
      statusNotOpenMessage: 'The Ticket status is not set to open, therefore this Ticket cannot be accessed.',
    };
  },

  methods: {
    updateTable() {
      setTimeout(() => this.sendUpdateTable(), 750);
    },
    sendUpdateTable() {
      if (this.search === '') return null;

      this.loading = true;

      return this.$axios
        .get('api/v1/tickets', {
          params: {
            ticketId: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        })
        .catch((error) => {
          this.loading = false;
          this.items = [];
          let errMsg = 'There was an error processing your request';
          if (error.response && error.response.status === 404 && error.response.data) {
            errMsg = error.response.data.message;
          }

          if (error.response && error.response.status === 422) {
            console.log(error.response);

            errMsg = error.response.data.message;
            if (error.response.data.errors) {
              Object.values(error.response.data.errors).forEach((val) => {
                errMsg += `\n ${val}`;
              });
            }
          }

          if (error.response && error.response.status === 500) {
            errMsg = error.response.data.message;
          }

          this.loadingModel = false;
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },
    verifyTicketOwnerStatus(item) {
      this.loading = true;
      this.$axios
        .post('api/v1/tickets/verify-owner-status', {
          ticketId: item.id,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.data && res.data.data.verified === true) {
            this.resetTicket();
            this.$router.push({
              name: 'ticket-show',
              params: {
                id: item.id,
              },
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (error.response && error.response.status === 404 && error.response.data) {
            errMsg = error.response.data.message;
          }
          this.loadingModel = false;
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },
    ...mapActions('notification', ['setNotification']),
    ...mapActions('ticket', ['resetTicket']),
  },

};
</script>

<style>
</style>
