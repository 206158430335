<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="formattedProspects"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Prospects</v-toolbar-title>

          <v-spacer />

          <v-toolbar-items>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="searchTable()"
            />
            <v-select
              v-model="filter.campaign"
              :items="filterData.campaigns"
              item-text="name"
              item-value="id"
              label="Campaigns"
            />
            <v-select
              v-model="filter.state"
              :items="filterData.states"
              item-text="state_abbr"
              item-value="state_abbr"
              label="States"
            />
            <v-select
              v-model="filter.walkupComplete"
              :items="[true, false]"
              label="Walkup Complete"
            />
            <v-select
              v-model="filter.offerCodeEntered"
              :items="[true, false]"
              label="Used Code"
            />

            <v-btn
              @click="searchTable()"
            >
              Filter
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <prospect-details :prospect="item" />
    </template>
  </v-data-table>
</template>

<style scoped>
  .v-toolbar__items {
    height: -webkit-fill-available;
  }
  .v-toolbar__items {
    max-width: 85%;
  }
</style>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import ProspectDetails from './ProspectDetails.vue';

export default {
  components: {
    ProspectDetails,
  },
  mixins: [
    dataTableMixin,
  ],
  props: {
    fileId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'First Name', value: 'first_name' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Campaign', value: 'prescreen_campaign.name' },
      { text: 'State', value: 'state' },
      { text: 'City', value: 'city' },
      { text: 'Offer Code Usage', value: 'num_times_code_submitted' },
      { text: 'Walkup Completed', value: 'is_walkup_complete' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'start',
        width: 183,
      },
    ],
    filterData: [],
    filter: {
      campaign: null,
      state: null,
      walkupComplete: null,
      offerCodeEntered: null,
    },
  }),
  computed: {
    formattedProspects() {
      return (this.items || []).map((item) => ({
        ...item,
      }));
    },
  },
  created() {
    this.$axios
      .get('/api/v1/prescreen/files/filter-params')
      .then((res) => {
        this.filterData = res.data;
      });
  },
  methods: {
    updateTable() {
      this.loading = true;
      return this.$axios
        .get(`api/v1/prescreen/files/${this.fileId}/final/prospects`, {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
            filter: this.filter,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
  },
};
</script>
