<template>
  <v-dialog
    v-model="show"
    width="700"
    @close.stop="show = false"
  >
    <v-card>
      <v-card-title>
        Confirm Payment Profile Deletion
      </v-card-title>
      <v-card-text
        class="pt-5"
      >
        <p>Are you sure you want to delete the following payment profile?</p>

        <v-data-table
          :headers="headers"
          :items="paymentProfiles"
          hide-default-footer
        />
      </v-card-text>

      <v-card-actions
        class="pb-10 pt-5"
      >
        <div class="row">
          <div class="col-6 offset-6 text-right pr-6">
            <v-btn
              color="primary"
              width="25%"
              :loading="loading"
              :disabled="loading"
              @click.stop="$emit('confirm-delete-payment-profile')"
            >
              yes
            </v-btn>
            <v-btn
              text
              width="25%"
              class="ml-2"
              :disabled="loading"
              @click.stop="show = false"
            >
              no
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    paymentProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Title',
          value: 'title',
          align: 'center',
        },
        {
          text: 'Type',
          value: 'type',
          align: 'center',
        },
        {
          text: 'Primary',
          value: 'primary',
          align: 'center',
        },
        {
          text: 'Secondary',
          value: 'secondary',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    paymentProfiles() {
      return [
        this.paymentProfile,
      ];
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
