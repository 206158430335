import { DateTime } from 'luxon';

export default {
  percentage({ value }) {
    return Number(value).toLocaleString('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
    });
  },

  currency({ value }) {
    let formatted = null;

    if (value) {
      formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
        .format(value);
    }

    return formatted;
  },

  number({ value }) {
    return Number(value).toLocaleString('en-US', {
      minimumFractionDigits: 3,
    });
  },

  date({ value }) {
    // setZone override the zone with a fixed-offset zone
    // specified in the string itself if it specifies one
    const date = DateTime.fromISO(value, { setZone: true });
    return date.toFormat('yyyy-MM-dd HH:mm:ss');
  },
};
