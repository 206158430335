<template>
  <v-app-bar
    app
    elevation="2"
    absolute
    permanent
    style="position: sticky"
    clipped-right
  >
    <v-btn
      icon
      @click="collapseSidebar()"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-app-bar-title>
      <v-img
        contain
        height="30px"
        width="100%"
        position="left"
        :src="require(`@/assets/hyphen/logo_reverse.png`)"
      />
    </v-app-bar-title>

    <v-spacer />

    <v-btn
      v-cloak
      text
      class="bar-item font-weight-bold"
    >
      {{ productName }}
    </v-btn>

    <v-menu
      bottom
      right
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="toolbar-profile-btn mx-10"
          color="primary"
          rounded
          v-bind="attrs"
          v-on="on"
        >
          {{ userInitials }}
        </v-btn>
      </template>

      <v-list
        class="pa-0"
        style="min-width: 250px;"
      >
        <v-list-item>
          <v-list-item-title class="text-body-2">
            <strong class="text-body-1 font-weight-bold">{{ userName }}</strong><br>
            {{ user.email }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'user-profile' }">
          <v-list-item-title class="text-body-2">
            Edit Account
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="v-list-item-border-top">
          <v-list-item-title
            v-if="userCanAccess('user-admin') || userCanAccess('system-settings')"
            class="text-uppercase font-weight-bold text-body-2 grey--text text--darken-3"
          >
            Administration
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="userCanAccess('user-admin')"
          :to="{ name: 'users' }"
        >
          <v-list-item-title class="text-body-2">
            Users
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="userCanAccess('system-settings')"
          :to="{ name: getFirstItemRouteName }"
        >
          <v-list-item-title class="text-body-2">
            System Settings
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="v-list-item-border-top"
          @click="logOut()"
        >
          <v-list-item-title class="text-body-2">
            Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState('currentUser', ['user']),
    ...mapGetters({
      productName: 'settings/productName',
      getFirstItemRouteName: 'systemSettingsMenu/getFirstItemRouteName',
    }),
    userInitials() {
      return this.user.id ? `${this.user.first_name.charAt(0)}${this.user.last_name.charAt(0)}` : '';
    },
    userName() {
      return this.user.id ? `${this.user.first_name} ${this.user.last_name}` : '';
    },
  },
  methods: {
    userCanAccess(sidebarLink) {
      // trim everything except the root permission name
      const trim = this.user.permission.map((item) => item.name.split(' ').pop('').split('::')[0]);

      // remove duplicates left after trimming
      const removeDupes = [...new Set(trim)];

      // is requested sidebar link included in the list of permissions
      return removeDupes.includes(sidebarLink);
    },
    logOut() {
      this.logUserOut()
        .then(() => this.$router.push({ name: 'login' }));
    },
    collapseSidebar() {
      this.setCollapsed();
      this.setContentAreaStyle();
    },
    ...mapActions('currentUser', ['logUserOut']),
    ...mapActions('sidebar', ['setCollapsed', 'setContentAreaStyle']),
  },
};
</script>
<style lang="scss" scoped>
  .theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #2b3243;
  }
  .v-toolbar .v-btn.v-btn--icon {
    color: #f1f7f0 !important;
  }
  .v-btn.toolbar-profile-btn {
    min-width: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    background-color: #7d959d !important;
    font-size: 1.1rem;
    border: 2px solid #f1f7f0 !important;
  }

  .v-list-item {
    min-height: 0;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .v-list-item-border-top {
    border-top: 1px solid #ddd;
  }

  .bar-item {
    padding: 1rem 3.5rem !important;
    border-right: 1px solid #f1f7f0;
    border-radius: 1px;
    box-shadow: none;
    color: #f1f7f0;
  }

  .bar-item .v-btn, .bar-item .v-icon {
    color: #f1f7f0 !important;
    font-size: 2rem !important;
  }
</style>
