<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(menu, index) in menus">
        <v-tab
          :key="index"
          :to="{ name: menu.route }"
        >
          {{ menu.label }}
        </v-tab>
      </template>
    </v-tabs>
    <v-card class="mt-10 transparent">
      <router-view />
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          label: 'Lead',
          icon: 'mdi-account-group',
          route: 'compliance-lead',
        },
        {
          label: 'FinCen',
          icon: 'mdi-account-group',
          route: 'fincen-batches',
        },
        {
          label: 'OFAC Monitoring',
          icon: 'mdi-account-group',
          route: 'ofac-matches',
        },
      ],
    };
  },
};
</script>

<style></style>
