<template>
  <v-dialog
    v-model="showDialog"
    width="500"
    scrollable
    @click:outside="closeModal()"
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">
          {{ updatingPhoneEmailMessage }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="editValue"
              :label="phoneEmailLabel"
            />
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="closeModal()"
        >
          Close
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="unableToUpdate"
          @click="updatePhoneEmail()"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LpPhoneEmailUpdate',
  props: {
    editedItem: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    dialog: {
      type: Boolean,
      required: false,
      default: false,
    },

    type: {
      type: String,
      required: false,
      default: 'phone',
    },

    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formProcessing: false,
      showDialog: false,
      editValue: '',
      currentValue: '',
    };
  },

  computed: {
    updatingPhoneEmailMessage() {
      return (this.type === 'phone')
        ? 'Update customer phone'
        : 'Update customer email';
    },

    phoneEmailLabel() {
      return (this.type === 'phone')
        ? 'New Phone'
        : 'New Email';
    },

    unableToUpdate() {
      return this.formProcessing || this.currentValue === this.editValue;
    },
  },

  watch: {
    editedItem() {
      this.id = this.editedItem.id;
    },

    dialog(value) {
      this.showDialog = this.dialog;
      if (value) {
        this.initValue();
      }
    },
  },

  mounted() {
    this.showDialog = this.dialog;

    this.initValue();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProCustomer', ['setCustomerPhone', 'setCustomerEmail']),
    ...mapActions('ticket', ['updateCustomerTicketPhone', 'updateCustomerTicketEmail']),
    validatePhoneNumber(phoneString) {
      const regex = /^\(?[2-9][0-9]{2}\)?[-. ]?[2-9][0-9]{2}[-. ]?[0-9]{4}$/;

      return regex.test(phoneString);
    },

    validateEmail(emailString) {
      const regex = /^[a-zA-Z0-9._+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})$/;

      return regex.test(emailString);
    },

    initValue() {
      if (this.type === 'phone') {
        this.currentValue = this.editedItem.homephone;
        this.editValue = this.editedItem.homephone ?? '';
      } else {
        this.currentValue = this.editedItem.email;
        this.editValue = this.editedItem.email ?? '';
      }
    },

    closeModal() {
      this.editValue = '';
      this.showDialog = false;
      this.$emit('close', this.dialog);
    },
    updatePhoneEmail() {
      const passValidation = (this.type === 'phone')
        ? this.validatePhoneNumber(this.editValue)
        : this.validateEmail(this.editValue);

      if (!passValidation) {
        this.setNotification({
          message: `Invalid ${this.type} value`,
          color: 'red',
        });
      } else {
        this.formProcessing = true;

        this.$axios
          .put('api/v1/admin/customer/update-phone-email', {
            type: this.type,
            customer: this.editedItem,
            newValue: this.editValue,
            ticket: this.ticket?.zendesk?.id || 0,
            currentValue: this.currentValue,
          })
          .then((response) => {
            const { data } = response;
            this.formProcessing = false;
            if (this.type === 'phone') {
              this.setCustomerPhone(data.newValue);
              this.updateCustomerTicketPhone(data.newValue);
            } else if (this.type === 'email') {
              this.setCustomerEmail(data.newValue);
              this.updateCustomerTicketEmail(data.newValue);
            }

            this.currentValue = data.valueUpdated;
            this.setNotification({
              message: data.message,
              color: 'green',
            });

            this.$emit('close', this.dialog);
          })
          .catch((err) => {
            const message = err.response?.data?.message || 'Oops something went wrong!';

            this.setNotification({
              message,
              color: 'red',
            });

            this.formProcessing = false;
          });
      }
    },
  },
};
</script>
