<template>
  <div>
    <div
      v-if="isLocal"
      class="mx-auto"
    >
      <v-list-item
        three-line
      >
        <v-list-item-content>
          <div
            class="text-overline mb-4"
          >
            Brands Selected
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{ settings.brand.company_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            This is the brand selected in group "brand"
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          outlined
          rounded
          text
          @click="showSelectedBrandModal()"
        >
          Change Selected Brand
        </v-btn>
      </v-card-actions>
      <v-alert
        v-cloak
        border="left"
        type="info"
        icon="mdi mdi-alert-circle-check-outline"
        dark
      >
        <v-row align="center">
          <v-col class="grow">
            <p>
              In addition to the settings changes, to complete these changes
              we need to update some values in the
              .env file in (helix-uw, admin and customer-portal)
            </p>
            <v-row>
              <v-col
                cols="12"
                sm="3"
              >
                <h3>Helix-uw</h3>
                <span>BRAND_NAME=Atlas</span><br>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <h3>Admin</h3>
                <span>VUE_APP_PL_INSTANCE_ID="helix"</span><br>
                <span>VUE_APP_PL_NAME="HelixData"</span><br>
              </v-col>
              <v-col
                cols="12"
                sm="5"
              >
                <h3>Customer Portal</h3>
                <span>VUE_APP_INSTANCE_ID=atlas</span><br>
                <span>VUE_APP_HEAD_TITLE="Atlas"</span><br>
                <span>VUE_APP_CUSTOMER_PORTAL_INSTANCE_ID=atlas</span><br>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-alert>

      <BrandSelectedModal
        v-model="brandSelectForm"
        :brands="brands"
        :dialog-form="brandSelectForm"
        @close-modal="closeModal('brandSelectForm')"
        @reload-settings="reloadAllSettings()"
      />
    </div>

    <v-divider />

    <v-data-table
      dense
      :calculate-widths="true"
      :headers="headers"
      :loading="dataLoading"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props.sync="footerProps"
      @update:options="updateTable()"
    >
      <template v-slot:top>
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title>Brand Settings</v-toolbar-title>

            <v-spacer />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="searchTable()"
            />

            <v-spacer />

            <v-select
              v-model="group"
              :items="groupList"
              single-line
              hide-details
              placeholder="Select Group"
              @change="searchTable()"
            />

            <v-spacer />

            <FormModal
              v-model="dialogForm"
              :edited-item="editedItem"
              :dialog-form="dialogForm"
              :is-local="isLocal"
              @close-modal="closeModal('dialogForm')"
              @item-saved="itemSaved()"
              @click:outside="clearState()"
            />
          </v-toolbar>
        </v-card-title>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          :id="item"
          class="mr-10"
          @click="openFormModal(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import { mapState, mapActions } from 'vuex';
import BrandSelectedModal from '@/views/Settings/BrandSettings/BrandSelectedModal.vue';
import FormModal from './FormModal.vue';

export default {
  components: {
    BrandSelectedModal,
    FormModal,
  },
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    dataLoading: true,
    headers: [
      { text: 'Name', value: 'name', width: '20%' },
      { text: 'Group', value: 'group', width: '20%' },
      { text: 'Key', value: 'key', width: '20%' },
      { text: 'Value', value: 'value', width: '50%' },
      {
        text: 'Action',
        value: 'actions',
        sortable: false,
        width: '10%',
      },
    ],
    group: '',
    editedItem: {},
    dialogDelete: false,
    dialogForm: false,
    snackbar: true,
    needUpdate: false,
    groupList: [],
    inSync: true,
    brands: [],
    brandSelectForm: false,
    isLocal: false,
  }),

  computed: {
    ...mapState('settings', ['settings']),
  },

  methods: {
    ...mapActions('settings', ['getSettings']),
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    openFormModal(item = null) {
      this.dialogForm = true;
      this.editedItem = item;
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.dataLoading = true;

      return this.$axios
        .get('api/v1/admin/brand-settings', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
            group: this.group,
          },
        })
        .then((res) => {
          this.items = Object.values(res.data.settings.data);
          this.totalItems = res.data.settings.total;
          this.groupList = res.data.groupList;
          this.dataLoading = false;
          this.inSync = res.data.inSync || null;
          this.brands = res.data.brands || [];
          this.isLocal = res.data.isLocal;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
      this.updateTable();
    },
    syncBrand() {
      this.dataLoading = true;

      return this.$axios
        .post('api/v1/admin/sync-brand');
    },

    showSelectedBrandModal() {
      this.brandSelectForm = true;
    },

    reloadAllSettings() {
      this.getSettings();
      this.updateTable();
    },
  },
};
</script>
