<template>
  <div class="content-holder p-s">
    <v-form
      ref="singlePaymentForm"
      novalidate
      class="settlement-option"
      @submit.prevent="onSubmit"
    >
      <!-- Payment Amount -->
      <div class="mt-xs">
        <p class="mb-xxxs">
          Payment Amount
        </p>
        <v-text-field
          v-model.number="settlementOffer.paymentAmount"
          prepend-inner-icon="mdi-currency-usd"
          label="Payment Amount"
          outlined
          required
          readonly
        />
      </div>
      <!-- ./ Payment Amount -->

      <PaymentMethodSelect
        :ticket="ticket"
        :settlement-offer="settlementOffer"
        :payment-accounts="paymentAccounts"
        :error-messages="paymentAccountError"
        @reload-payment-profiles="$emit('reload-payment-profiles')"
      />

      <!-- First Payment Date -->
      <div class="mt-xs">
        <p class="mb-xxxs">
          When can you make your payment?
        </p>
        <v-text-field
          v-model="settlementOffer.firstPaymentDate"
          label="Payment Date"
          type="date"
          :error-messages="paymentDateError"
          outlined
          required
        />
        <v-progress-linear
          v-if="isCheckingPaymentDate"
          class="mt-n7 mb-6"
          indeterminate
        />
      </div>
      <!-- ./ First Payment Date -->

      <OfferSummary
        :offer="settlementOffer"
        :is-offer-valid="isOfferValid"
        show-title
        border="both"
        class="mt-m mb-3"
      />

      <div
        v-if="isOfferValid"
        class="mb-4"
      >
        <p v-if="!isManualPayment">
          YOU MUST E-MAIL BOTH THE NEW PAYMENT SCHEDULE AND THE ELECTRONIC PAYMENT
          DISCLOSURES TO THE CUSTOMER BEFORE CHECKING THE ACH CONSENT BOX BELOW.
        </p>
        <div class="row">
          <div class="col-6">
            <PaymentScheduleModal
              :settlement-offer="settlementOffer"
              :refresh-key="pmtRefresh"
            />
          </div>
          <div class="col-6">
            <DisclosureModal
              :forced-disabled="isManualPayment"
            />
          </div>
        </div>
      </div>

      <!-- achConsent -->
      <v-container
        v-if="!isManualPayment"
        fluid
      >
        <v-checkbox
          v-model="settlementOffer.achConsent"
          class="mt-n7"
          :disabled="!paymentSchedulesSent || !disclosuresSent"
        >
          <template v-slot:label>
            <div
              class="mt-13"
            >
              <p>
                Agents: Please read the following to the customer and gain verbal authorization
                before proceeding.
              </p>
              <p>
                "Please acknowledge you have received, read, and consent to the ACH authorization."
              </p>
            </div>
          </template>
        </v-checkbox>
      </v-container>
      <!-- ./ achConsent -->

      <v-card-actions
        class="justify-end"
        width="100%"
      >
        <v-btn
          v-if="!isLoading"
          button-full
          size="large"
          color="settlement"
          :disabled="disabledConfirm"
          @click="onSubmit"
        >
          CONFIRM FULL BALANCE PAYMENT SCHEDULE
        </v-btn>
        <Loader v-else />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

import LpLoan from '@/services/LpLoanService';
import Loader from '@/components/ui/Loader.vue';
import PaymentScheduleModal from './PaymentScheduleModal.vue';
import DisclosureModal from './DisclosureModal.vue';

import OfferSummary from './OfferSummary.vue';
import offerTypes from './offerTypes';

import PaymentMethodSelect from './PaymentMethodSelect.vue';

export default {
  name: 'PayFullBalanceSinglePaymentForm',

  components: {
    Loader,
    OfferSummary,
    DisclosureModal,
    PaymentScheduleModal,
    PaymentMethodSelect,
  },

  props: {
    ticket: {
      type: Object,
      required: true,
    },
    todayPayoffBalance: {
      type: Number,
      required: true,
    },
    paymentAccounts: {
      type: Array,
      required: true,
    },
    loanSettingsId: {
      type: Number,
      required: true,
    },
    customerId: {
      type: Number,
      required: true,
    },
    loanId: {
      type: Number,
      required: true,
    },
    refreshKey: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showDisclosure: false,
      showPaymentSchedule: false,
      settlementOffer: {
        settlementAmount: 0,
        paymentAmount: 0,
        paymentAccountId: null,
        paymentFrequency: null,
        firstPaymentDate: null,
        achConsent: null,
        offerType: offerTypes.PAY_IN_FULL,
        numberOfPayments: 0,
      },
      submitted: false,
      showConfirmation: false,
      isLoading: false,
      paymentDateError: null,
      paymentAccountError: '',
      paymentFrequencies: [
        { title: 'Weekly', value: 'weekly', description: 'week' },
        { title: 'Bi-Weekly', value: 'biWeekly', description: 'every other week' },
        { title: 'Monthly', value: 'monthly', description: 'month' },
      ],
      pmtRefresh: 0,
      isCheckingPaymentDate: false,
    };
  },

  computed: {
    ...mapState('app', ['instanceId']),
    ...mapState('settlement', ['paymentSchedule', 'paymentSchedulesSent', 'disclosuresSent', 'isManualPayment']),

    isAchPayment() {
      const { paymentAccountId } = this.settlementOffer;
      return LpLoan.isBankAccount(this.paymentAccounts, paymentAccountId);
    },

    paymentDateErrors() {
      return [];
    },

    isOfferValid() {
      return Boolean(
        this.settlementOffer.paymentAmount > 0 && this.settlementOffer.firstPaymentDate
        && !this.isCheckingPaymentDate && !this.paymentDateError,
      );
    },

    disabledConfirm() {
      if (!this.settlementOffer.paymentAmount) {
        return true;
      }

      if (!this.settlementOffer.paymentAccountId) {
        return true;
      }

      if (!this.settlementOffer.firstPaymentDate) {
        return true;
      }

      if (this.isManualPayment) {
        return !this.paymentSchedulesSent;
      }

      return (
        !this.paymentSchedulesSent
        || !this.disclosuresSent
        || !this.settlementOffer.achConsent
      );
    },
  },

  watch: {
    refreshKey: {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        // if the key value has changed, we need to reset the form
        this.resetFormValues();
      },
    },
    'settlementOffer.paymentAccountId': {
      async handler(newVal) {
        if (newVal !== null && this.settlementOffer.firstPaymentDate !== null) {
          this.isCheckingPaymentDate = true;
          this.checkNextPaymentDate(
            this.settlementOffer.firstPaymentDate,
            this.settlementOffer.firstPaymentDate,
          );
          this.isCheckingPaymentDate = false;
        }
      },
    },
    'settlementOffer.firstPaymentDate': {
      async handler(paymentDate, prevValue) {
        // make sure we change only when the value changes
        if (paymentDate !== prevValue) {
          // if the payment schedule has changed, we may need to send it again
          this.pmtRefresh += 1;
        }
        if (paymentDate !== null && this.settlementOffer.paymentAccountId !== null) {
          this.isCheckingPaymentDate = true;
          this.checkNextPaymentDate(paymentDate, prevValue);
          this.isCheckingPaymentDate = false;
        }
      },
    },
    todayPayoffBalance: {
      handler(currentValue) {
        // when settling a loan with pay in full settlementAmount is equal to todayPayoffBalance
        this.settlementOffer.settlementAmount = currentValue;
      },
      immediate: true,
    },
  },

  created() {
    this.resetFormValues();
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('ticket', ['getTicket']),
    ...mapActions('settlement', ['getPaymentSchedule', 'checkPaymentDate', 'checkManualPaymentDate']),

    /**
     * Handle submit settlement.
     * @returns {Promise<void>}
     */
    async onSubmit() {
      if (this.isManualPayment) {
        await this.manualSubmit();
      } else {
        await this.autopaySubmit();
      }
    },

    /**
     * Send settlement for manual submit.
     * @returns {Promise<void>}
     */
    async manualSubmit() {
      this.submitted = true;

      if (!this.$v.$invalid) {
        this.isLoading = true;

        const offerData = {
          ...this.settlementOffer,
          loanId: this.loanId,
          isAchPayment: this.isAchPayment,
          loanSettingsId: this.loanSettingsId,
          payoffBalance: this.todayPayoffBalance,
          scheduleData: JSON.stringify(this.paymentSchedule),
          isManualPayment: this.isManualPayment,
        };

        this.checkManualPaymentDate({
          paymentDate: offerData.firstPaymentDate,
        })
          .then(async (resp) => {
            // passed check
            if (resp.data === true) {
              const result = await LpLoan.createManualSettlement(
                { ...this.settlementConfig, ...offerData, ticketId: this.$route.params.id },
              );

              if (result.status === 200) {
                await this.getTicket(this.$route.params.id);
                this.$emit('offer-confirmed', offerData);
              } else {
                const errMsg = (result.response.data.exception && result.response.data.message)
                  ? result.response.data.message
                  : 'Something went wrong, unable to create the manual pay settlement. Please contact your admin.';

                await this.setNotification({
                  message: errMsg,
                  color: 'red',
                });

                this.isLoading = false;
              }
            } else {
              this.paymentDateError = resp.data;
              this.isLoading = false;
            }
          })
          .catch(() => {
            this.paymentDateError = 'payment date validation error';
            this.isLoading = false;
          });
      }
    },

    /**
     * Sent settlement for autopay payments
     * @returns {Promise<void>}
     */
    async autopaySubmit() {
      this.submitted = true;

      if (!this.$v.$invalid) {
        this.isLoading = true;

        const offerData = {
          ...this.settlementOffer,
          loanId: this.loanId,
          isAchPayment: this.isAchPayment,
          loanSettingsId: this.loanSettingsId,
          payoffBalance: this.todayPayoffBalance,
          scheduleData: JSON.stringify(this.paymentSchedule),
        };

        this.checkPaymentDate({
          paymentAccountId: offerData.paymentAccountId,
          paymentDate: offerData.firstPaymentDate,
          singlePayment: true,
        })
          .then(async (resp) => {
            // passed check
            if (resp.data === true) {
              const result = await LpLoan.createSettlementAutopay(
                { ...this.settlementConfig, ...offerData, ticketId: this.$route.params.id },
              );

              if (result.status === 200) {
                await this.getTicket(this.$route.params.id);
                this.$emit('offer-confirmed', offerData);
              } else {
                const errMsg = (result.response.data.exception && result.response.data.message)
                  ? result.response.data.message
                  : 'Something went wrong, unable to create settlement autopay. Please contact your admin.';

                this.setNotification({
                  message: errMsg,
                  color: 'red',
                });

                this.isLoading = false;
              }
            } else {
              this.paymentDateError = resp.data;
              this.isLoading = false;
            }
          })
          .catch(() => {
            this.paymentDateError = 'payment date validation error';
            this.isLoading = false;
          });
      }
    },

    onPaymentFrequencyChange(event) {
      this.settlementOffer.paymentFrequency.value = event.value;
    },

    resetFormValues() {
      this.settlementOffer.paymentAccountId = null;
      this.settlementOffer.achConsent = null;
      this.settlementOffer.offerType = offerTypes.PAY_IN_FULL;

      this.settlementOffer.settlementAmount = parseFloat(this.todayPayoffBalance);
      this.settlementOffer.paymentAmount = parseFloat(this.todayPayoffBalance);
      this.settlementOffer.paymentFrequency = 'weekly';
      this.settlementOffer.firstPaymentDate = null;
      this.settlementOffer.numberOfPayments = 1;
    },

    /**
     * Update pmtRefresh Value.
     * @param paymentDate
     * @param previousPaymentDate
     */
    updatePmt(paymentDate, previousPaymentDate) {
      // make sure we change only when the value changes
      if (paymentDate !== previousPaymentDate) {
        // if the payment schedule has changed, we may need to send it again
        this.pmtRefresh += 1;
      }
    },

    /**
     * Check next payment date for manual and autopay.
     * @param previousPaymentDate
     * @param paymentDate
     */
    checkNextPaymentDate(paymentDate, previousPaymentDate) {
      this.updatePmt(paymentDate, previousPaymentDate);

      if (this.isManualPayment) {
        this.checkNextManualPaymentDate(paymentDate);
      } else {
        this.checkNextAutopayPaymentDate(paymentDate);
      }
    },

    /**
     * Check Manual payment date
     * @param paymentDate
     */
    async checkNextManualPaymentDate(paymentDate) {
      this.isCheckingPaymentDate = true;
      await this.checkManualPaymentDate({ paymentDate })
        .then((response) => {
          if (response.data === true) {
            this.paymentDateError = null;
            this.$refs.singlePaymentForm.resetValidation();
          } else {
            this.paymentDateError = response.data;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.paymentDateError = 'payment date validation error';
          this.isLoading = false;
        });
      this.isCheckingPaymentDate = false;
    },

    /**
     * Check Autopay payment date.
     * @param paymentDate
     */
    async checkNextAutopayPaymentDate(paymentDate) {
      this.isCheckingPaymentDate = true;
      await this.checkPaymentDate({
        paymentAccountId: this.settlementOffer.paymentAccountId,
        paymentDate,
        singlePayment: true,
      })
        .then((resp) => {
          // passed check
          if (resp.data === true) {
            this.paymentDateError = null;
            this.$refs.singlePaymentForm.resetValidation();
          } else {
            this.paymentDateError = resp.data;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.paymentDateError = 'payment date validation error';
          this.isLoading = false;
        });
      this.isCheckingPaymentDate = false;
    },
  },

  validations: {
    settlementOffer: {
      paymentAmount: {
        required,
        positiveNonZero: (v) => v > 0,
      },
      paymentFrequency: {
        required,
      },
      paymentAccountId: {
        required,
      },
      firstPaymentDate: {
        required,
      },
      achConsent: {
        checked: (v, vm) => {
          if (vm.paymentAccountId === -1) {
            return true;
          }

          if (vm.paymentAccountId !== -1 && vm.paymentAccountId !== null) {
            return v === true;
          }

          return false;
        },
      },
    },
  },
};
</script>
