<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(menu, index) in menus">
        <v-tab
          :key="index"
          :to="{ name: menu.route }"
        >
          {{ menu.label }}
        </v-tab>
      </template>
    </v-tabs>
    <v-card class="mt-10 transparent">
      <router-view
        :data="chartData()"
        :loading="isLoading()"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

const METHODS_ROUTE = 'lead-journey-methods';
const API_CALLS_ROUTE = 'lead-journey-api-calls';

export default {
  data() {
    return {
      menus: [
        {
          label: 'Lead Journey Methods',
          icon: 'mdi-account-group',
          route: METHODS_ROUTE,
        },
        {
          label: 'Lead Journey Api Calls',
          icon: 'mdi-account-group',
          route: API_CALLS_ROUTE,
        },
      ],
      methods: [],
      methodsLoading: false,
      apiCalls: [],
      apiCallsLoading: false,
    };
  },

  computed: {
    isMethodsRoute() {
      return this.$route.name === METHODS_ROUTE;
    },

    isApiCallsRoute() {
      return this.$route.name === API_CALLS_ROUTE;
    },
  },

  created() {
    this.menus.forEach((menu) => {
      this.fetchChartData(menu.route);
    });
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    isLoading() {
      if (this.isMethodsRoute) {
        return this.methodsLoading;
      }

      if (this.isApiCallsRoute) {
        return this.apiCallsLoading;
      }

      return false;
    },

    chartData() {
      if (this.isMethodsRoute) {
        return this.methods;
      }

      if (this.isApiCallsRoute) {
        return this.apiCalls;
      }

      return [];
    },

    fetchChartData(route) {
      this.setLoading(route, true);

      let endpoint = '';
      let message = '';

      if (route === METHODS_ROUTE) {
        endpoint = '/api/v1/lead-journey-method-monitor';
        message = 'There was an error processing methods monitor';
      }

      if (route === API_CALLS_ROUTE) {
        endpoint = '/api/v1/lead-journey-api-monitor';
        message = 'There was an error processing api calls monitor';
      }

      if (endpoint !== '') {
        this.$axios
          .get(endpoint)
          .then((res) => {
            if (res.status === 200) {
              this.setData(route, res.data.map((item) => (this.setChart(item))));
            }
          })
          .catch(() => {
            this.setNotification({
              message,
              color: 'red',
            });
          })
          .then(() => {
            this.setLoading(route, false);
          });
      }
    },

    setChart(item) {
      return {
        options: {
          chart: {
            height: 380,
            width: '100%',
            type: 'area',
            id: item.name,
            stacked: false,
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: item.name,
            align: 'left',
          },
          xaxis: {
            labels: {
              rotate: 0,
            },
          },
        },
        series: [{
          name: 'Execution Time',
          data: item.data,
        }],
      };
    },

    setLoading(route, bool) {
      if (route === METHODS_ROUTE) {
        this.methodsLoading = bool;
      }

      if (route === API_CALLS_ROUTE) {
        this.apiCallsLoading = bool;
      }
    },

    setData(route, data) {
      if (route === METHODS_ROUTE) {
        this.methods = data;
      }

      if (route === API_CALLS_ROUTE) {
        this.apiCalls = data;
      }
    },
  },
};
</script>

<style></style>
