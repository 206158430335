<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col>
        <h1>TBot Prescreen Prospect Generator</h1>
        <p>Fill out the form sections below to generate a new Prescreen Prospect.</p>
      </v-col>
      <v-col
        v-if="!dataLoading && !showError"
        class="d-flex align-end justify-end"
      >
        <v-btn
          class="blue darken-1 mb-4"
          dark
          :loading="sendingProspect"
          @click="submitProspect"
        >
          Submit Prospect
        </v-btn>
      </v-col>
    </v-row>

    <template
      v-if="dataLoading"
    >
      <p>Loading data</p>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </template>

    <v-row
      v-else
    >
      <v-col
        v-if="!showError"
      >
        <v-card
          class="my-2"
          elevation="10"
        >
          <v-card-title>
            Campaign
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="3"
                md="3"
                sm="3"
              >
                <v-text-field
                  v-model="campaign.name"
                  label="Name"
                />
              </v-col>
              <v-col
                cols="3"
                md="3"
                sm="3"
              >
                <v-select
                  v-model="campaign.leadProviderId"
                  :items="leadProviders"
                  label="Lead Provider"
                />
              </v-col>
              <v-col
                cols="3"
                md="3"
                sm="3"
              >
                <v-text-field
                  v-model="campaign.campaignCode"
                  label="Campaign Code"
                />
              </v-col>
              <v-col
                cols="3"
                md="3"
                sm="3"
              >
                <v-text-field
                  v-model="campaign.maxLoanAmount"
                  label="Max Loan Amount"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="4"
                md="4"
                sm="4"
              >
                <v-text-field
                  v-model="campaign.printExpireDate"
                  label="Print Expire Date"
                  type="date"
                />
              </v-col>
              <v-col
                cols="4"
                md="4"
                sm="4"
              >
                <v-text-field
                  v-model="prospect.systemExpireDate"
                  label="System Expire Date"
                  type="date"
                />
              </v-col>

              <v-col
                cols="4"
                md="4"
                sm="4"
              >
                <v-text-field
                  v-model="campaign.activeDate"
                  label="Active Date"
                  type="date"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="6"
                md="6"
                sm="6"
              >
                <v-text-field
                  v-model="campaign.mailedDate"
                  label="Mailed Date"
                  type="date"
                />
              </v-col>

              <v-col
                cols="6"
                md="6"
                sm="6"
              >
                <v-select
                  v-model="campaign.externalCampaignId"
                  :items="campaigns"
                  label="External Campaign"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card
      class="my-2"
      elevation="10"
    >
      <v-card-title>
        Prospect
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="3"
            md="3"
            sm="3"
          >
            <v-text-field
              v-model="prospect.offerCode"
              label="Offer Code"
              type="text"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="3"
            md="3"
            sm="3"
          >
            <v-text-field
              v-model="prospect.firstName"
              label="First Name"
              type="text"
            />
          </v-col>

          <v-col
            cols="2"
            md="2"
            sm="2"
          >
            <v-text-field
              v-model="prospect.middleInitial"
              label="Middle Initial"
              type="text"
            />
          </v-col>
          <v-col
            cols="3"
            md="3"
            sm="3"
          >
            <v-text-field
              v-model="prospect.lastName"
              label="Last Name"
              type="text"
            />
          </v-col>
          <v-col
            cols="2"
            md="2"
            sm="2"
          >
            <v-text-field
              v-model="prospect.ssn7"
              label="SSN last 7"
              type="text"
            />
          </v-col>
          <v-col
            cols="2"
            md="2"
            sm="2"
          >
            <v-text-field
              v-model="prospect.suffix"
              label="Suffix"
              type="text"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="3"
            md="3"
            sm="3"
          >
            <v-text-field
              v-model="prospect.streetAddress"
              label="Address"
              type="text"
            />
          </v-col>
          <v-col
            cols="3"
            md="3"
            sm="3"
          >
            <v-text-field
              v-model="prospect.streetAddress2"
              label="Apt/Suite #"
              type="text"
            />
          </v-col>
          <v-col
            cols="2"
            md="2"
            sm="2"
          >
            <v-text-field
              v-model="prospect.city"
              label="City"
              type="text"
            />
          </v-col>
          <v-col
            cols="2"
            md="2"
            sm="2"
          >
            <v-text-field
              v-model="prospect.state"
              label="State"
              type="text"
            />
          </v-col>
          <v-col
            cols="2"
            md="2"
            sm="2"
          >
            <v-text-field
              v-model="prospect.zip"
              label="State"
              type="text"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-col
      v-if="showError"
      cols="12"
    >
      <v-alert
        type="error"
        dark
        border="left"
      >
        Failed to retrieve data needed to create lead.
        <ul>
          <li
            v-for="(msg, index) in errorMessages"
            :key="index"
          >
            {{ msg }}
          </li>
        </ul>
      </v-alert>
    </v-col>
    <v-dialog
      v-model="showModal"
      @click:outside="handleCloseResult()"
    >
      <v-card
        flat
        class="pa-2"
      >
        <v-card-title>Result</v-card-title>
        <v-card-text>
          <v-alert
            v-show="formError"
            type="error"
            dark
            border="left"
          >
            {{ formError }}
          </v-alert>

          <vue-json-pretty
            :data="apiResponse"
            :show-line="false"
            :show-double-quotes="false"
            virtual
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PrescreenProspectIndex',

  components: {
    VueJsonPretty,
  },

  data() {
    return {
      dataLoading: false,
      showError: false,
      sendingProspect: false,
      errorMessages: [],
      apiResponse: {},
      showModal: false,
      formError: '',
      campaigns: [],
      leadProviders: [],
      campaignSelected: {},
      leadProviderSelected: {},
    };
  },

  computed: {
    ...mapGetters('prescreenProspect', ['campaign', 'prospect']),
  },

  created() {
    this.dataLoading = true;
    this.$axios.post('/api/v1/support/make-test-prospect/loadDataNeeded')
      .then((data) => {
        this.campaigns = data.data.campaigns.map((campaign) => ({
          value: campaign.id,
          text: `${campaign.id} (${campaign.campaign_name} - ${campaign.product_type})`,
        }));

        this.leadProviders = data.data.leadProviders.map((provider) => ({
          value: provider.id,
          text: `${provider.api_key} (${provider.category})`,
        }));
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          this.apiResponse = error.response.data;
          this.formError = 'Please fix the following errors';
        } else {
          this.setNotification({
            message: 'There was an error processing the prospect, please try again',
            color: 'red',
          });
          console.error(error);
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('prescreenProspect', ['refreshData']),
    submitProspect() {
      this.sendingProspect = true;

      this.$axios.post('api/v1/support/make-test-prospect', {
        campaign: this.campaign,
        prospect: this.prospect,
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.showModal = true;
            this.apiResponse = resp.data;
          } else {
            this.setNotification({
              message: 'There was an error processing the prospect, please try again',
              color: 'red',
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.apiResponse = error.response.data;
            this.formError = 'Please fix the following errors';
            this.setNotification({
              message: 'There was an error processing the prospect, please try again',
              color: 'red',
            });
          } else {
            this.setNotification({
              message: 'There was an error processing the prospect, please try again',
              color: 'red',
            });
            console.error(error);
          }
        })
        .finally(() => {
          this.sendingProspect = false;
        });
    },

    handleCloseResult() {
      this.refreshData();
    },

  },
};
</script>

<style scoped>

</style>
