<template>
  <v-row class="ticket-show">
    <v-col cols="12">
      <v-card
        class="ticket-show-actions"
        elevation="2"
        :loading="loading"
        outlined
        tile
      >
        <v-card-title>
          Rescind Loan
        </v-card-title>
        <v-card-text
          class="ma-5 py-5"
        >
          <v-row v-if="isRescindStatus">
            <v-alert type="info">
              There's nothing to do. Loan is already rescinded.
            </v-alert>
          </v-row>
          <v-row v-else-if="isRescindStatus === false && isLoanWithinThreeBusinessDays === false">
            <v-alert
              v-if="isRescindAfterFundingACHInitiatedState"
              type="info"
            >
              Intiated ACH to return fund has a status:
              {{ isRescindAfterFundingACHInitiatedState.autopay.status }}
            </v-alert>
            <v-alert
              v-else
              type="info"
            >
              There's nothing to do. You can only rescind a loan within  3 business days.
            </v-alert>
          </v-row>
          <v-row v-else-if="isRescindBeforeFundingState">
            <v-col>
              <h3>
                This loan has not been funded. It's still within 3 business
                days, click the button below to cancel loan
              </h3>

              <v-btn
                v-if="hasPermission('create ticket::rescind-loan')"
                color="primary"
                large
                :loading="loading"
                @click="rescindBeforeFundingAction()"
              >
                Rescind Loan Before Funding
              </v-btn>
              <disabled-for-lack-of-role-btn
                v-else
                text="Rescind Loan Before Funding"
                permission="create ticket::rescind-loan"
                :x-small="false"
              />
            </v-col>
          </v-row>
          <v-row v-else-if="isRescindAfterFundingState && !isRescindAfterFundingACHInitiatedState">
            <v-col>
              <h3>
                This loan can be rescinded. It's still within 3 business
                days, click the button below to make a payment.
              </h3>

              <rescind-after-funding
                :ticket="ticket"
                :force-payment-type="2"
                :force-payment-amount="ticket.active_loan.current_status.principal_balance"
                :payment-profiles="customer.paymentProfiles"
                @success="afterRescindAction($event)"
                @error="notifyError"
              />
            </v-col>
          </v-row>
          <v-row v-else-if="isRescindStatus === false && isRescindAfterFundingACHInitiatedState">
            <v-alert type="info">
              Autopay has been scheduled to return principal balance.
              Loan will be rescinded after 3 business days
            </v-alert>
          </v-row>
          <v-row v-else>
            <v-alert type="info">
              Please wait, we are getting loan funding information...
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <ticket-successful-action
      :ticket-successful-action-dialog="ticketSuccessfulActionDialog"
      :ticket-id="$route.params.id"
      :title="'You have successfully Rescinded this Loan.'"
      @closed="ticketSuccessfulActionDialog = false"
    />
  </v-row>
</template>

<style>
.fraud-claim-dialog-btn {
  padding: 2rem 1rem !important;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import TicketSuccessfulAction from '../TicketSuccessfulAction.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';
import RescindAfterFunding from './RescindAfterFunding.vue';

export default {
  name: 'TicketRescindLoan',
  components: {
    RescindAfterFunding,
    TicketSuccessfulAction,
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    paymentProfiles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      itemsToReview: {},
      loading: false,
      ticketSuccessfulActionDialog: false,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['payments', 'isRescind']),
    ...mapState('loanProCustomer', ['customer']),

    isRescindBeforeFundingState() {
      return this.isLoanWithinThreeBusinessDays && this.ticket.active_loan.current_status.loan_status_text === 'Loan Documents'
        && this.ticket.active_loan.current_status.loan_sub_status_text === 'Loan Docs Signed';
    },
    isRescindAfterFundingState() {
      return this.isLoanWithinThreeBusinessDays
        && ['Open', 'Pending Funding'].includes(this.ticket.active_loan.current_status.loan_status_text)
        && this.itemsToReview.successful_funding
        && this.itemsToReview.customer_return_payment === null;
    },
    isRescindAfterFundingACHInitiatedState() {
      return this.itemsToReview.customer_return_payment;
    },
    isLoanWithinThreeBusinessDays() {
      return this.itemsToReview.loan_within_three_business_days;
    },
    isRescindStatus() {
      const statuses = ['Loan Docs Signed-Rescinded'];
      return this.isRescind
        || statuses.includes(this.ticket.active_loan?.current_status?.loan_sub_status_text);
    },
  },
  watch: {
    async ticket() {
      this.itemsToReview = await this.getItemsReview();
    },
  },
  async created() {
    if (Object.keys(this.ticket).length > 0) {
      this.itemsToReview = await this.getItemsReview();
    }
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProLoan', ['getPayments', 'rescindLoanBeforeFunding', 'rescindLoanAfterFunding']),

    async getItemsReview() {
      this.loading = true;
      const res = await this.$axios
        .get('api/v1/support/ticket/loan/rescind', {
          params: {
            loanId: this.ticket.active_loan.id,
            ticketId: this.$route.params.id,
          },
        });
      this.loading = false;
      return res.data;
    },
    async rescindBeforeFundingAction() {
      this.loading = true;
      await this.rescindLoanBeforeFunding();
      this.loading = false;
      await this.afterRescindAction();
    },
    async afterRescindAction() {
      this.loading = true;
      this.itemsToReview = await this.getItemsReview();
      this.loading = false;
      this.ticketSuccessfulActionDialog = true;
    },
    notifyError() {
      this.setNotification({
        message: 'We were unable to make a payment. Please try again. If error persists, please contact your supervisor.',
        color: 'red',
      });
    },
  },
};
</script>
