<template>
  <v-dialog
    v-model="show"
    @click:outside="close()"
  >
    <v-card v-if="true">
      <v-card-title class="pb-4">
        <span class="headline mr-2">FinCen Person Data</span>
        <v-chip
          :color="statusColor"
          text-color="white"
        >
          {{ reviewStatus }}
        </v-chip>
      </v-card-title>
      <v-card-subtitle>
        <div
          v-if="item.batch !== undefined"
          class="subtitle-1"
        >
          Upload #{{ item.batch.file_id }}
        </div>
        <div
          v-if="item.batch !== undefined"
          class="subtitle-1"
        >
          Match Recorded: {{ formatDateTime(item.batch.timestamp) }}
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-container fluid>
          <v-row dense>
            <v-col
              cols="6"
              sm="6"
              class="d-flex flex-column"
            >
              <v-card
                class="flex d-flex flex-column"
              >
                <v-card-title>
                  Match Data
                </v-card-title>

                <v-list>
                  <v-list-item-group>
                    <template
                      v-for="(personValue, personProperty) in item"
                    >
                      <template
                        v-if="personValue !== null
                          && personValue !== undefined
                          && personValue !== Object(personValue)"
                      >
                        <v-list-item
                          :key="personProperty"
                          dense
                        >
                          <v-list-item-content
                            class="pt-0 pb-0"
                          >
                            <v-list-item-title
                              class="d-flex justify-lg-space-between"
                            >
                              <span v-if="personProperty === 'number_type_id'">
                                <strong class="text-capitalize">
                                  Number Type
                                </strong>
                              </span>
                              <span v-else>
                                <strong class="text-capitalize">
                                  {{ formatText(personProperty) }}
                                </strong>
                              </span>
                              <span
                                v-if="personProperty === 'match_status'"
                              >
                                {{ formatMatchStatus(personValue) }}
                              </span>
                              <span
                                v-else-if="personProperty === 'number_type_id'"
                              >
                                {{ item.type.type }}
                              </span>
                              <span
                                v-else-if="personProperty === 'review_status'"
                              >
                                {{ reviewStatus }}
                              </span>
                              <span v-else>{{ personValue }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          v-if="reviewStatus === 'unreviewed'"
          color="green"
          dark
          text
          @click="confirmPerson()"
        >
          Confirm Person
        </v-btn>

        <v-btn
          v-if="reviewStatus === 'unreviewed'"
          color="red"
          dark
          text
          @click="rejectPerson()"
        >
          Reject Person
        </v-btn>

        <v-btn
          color="primary"
          dark
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';

export default {
  name: 'FincenPersonsModal',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
        };
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    reviewStatus() {
      if (this.item.review_status === -1) {
        return 'unreviewed';
      }
      if (this.item.review_status === 0) {
        return 'rejected';
      }
      if (this.item.review_status === 1) {
        return 'confirmed';
      }
      return '';
    },
    statusColor() {
      const colors = {
        unreviewed: 'blue',
        confirmed: 'green',
        rejected: 'red',
      };
      return colors[this.reviewStatus];
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    confirmPerson() {
      this.$axios
        .put(`api/v1/fincen-person/${this.item.id}/confirm`)
        .then(() => {
          this.$emit('item-saved');

          this.setNotification({
            message: 'Person has been marked as confirmed.',
            color: 'green',
          });

          this.close();
        });
    },
    rejectPerson() {
      this.$axios
        .put(`api/v1/fincen-person/${this.item.id}/reject`)
        .then(() => {
          this.$emit('item-saved');

          this.setNotification({
            message: 'Person has been rejected.',
            color: 'green',
          });

          this.close();
        });
    },
    close() {
      this.$emit('close-modal');
    },
    removeEmpty(item) {
      if (item === null || item === undefined) {
        return item;
      }
      return Object.fromEntries(
        Object.entries(item)
          .filter(([k, v]) => k && v !== null && v !== undefined)
          .map(([k, v]) => [k, v === Object(v) ? this.removeEmpty(v) : v]),
      );
    },
    formatDateTime(date) {
      return DateTime.fromSeconds(date).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    },
    formatText(text) {
      return text
        .replaceAll('_', ' ')
        .replaceAll('ssn', 'SSN');
    },
    formatMatchStatus(matchStatus) {
      return JSON.parse(matchStatus).pct;
    },
  },
};
</script>

<style>
.v-list-item--dense {
  min-height: 20px;
}
</style>
