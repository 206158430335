<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="6">
          <v-card :loading="customerLoading">
            <v-card-title>
              KBOT
            </v-card-title>

            <v-card-text>
              <LoanProCustomersLoansModal
                :edited-item="customer"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card v-if="zendesk_ticket.id">
            <v-card-title>
              Zendesk Ticket {{ zendesk_ticket.id }} Actions
            </v-card-title>

            <v-card-text />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LoanProCustomersLoansModal from './LoanProCustomersLoansModal.vue';

export default {
  components: {
    LoanProCustomersLoansModal,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    customerLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      zendesk_ticket: {},
    };
  },

  mounted() {
    this.zendesk_ticket = this.$route.params.zendesk_ticket || { id: null };
  },
};
</script>
