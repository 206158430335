<template>
  <div
    v-show="isOfferValid"
    class="summary-container"
    :class="{
      'border-top': border === 'top',
      'border-bottom': border === 'bottom',
      'border-bottom border-top': border === 'both'
    }"
  >
    <h5
      v-if="showTitle"
      class="title"
    >
      PAYMENT SUMMARY
    </h5>

    <div class="summary-row">
      <span>{{ isPayoff ? "Payoff Balance:" : "Total Settlement Amount:" }}</span>
      <span>{{ offer.settlementAmount | currency }}</span>
    </div>

    <template v-if="offer.numberOfPayments > 1">
      <div class="summary-row">
        <span>Payment Amount:</span>
        <span>{{ offer.paymentAmount | currency }} {{ paymentFrequencyDescription }}</span>
      </div>

      <div class="summary-row">
        <span>Number of Payments:</span>
        <span>{{ offer.numberOfPayments }}</span>
      </div>

      <div class="summary-row">
        <span>First Payment Due:</span>
        <span>{{ offer.firstPaymentDate | formatdate }}</span>
      </div>
    </template>
    <template v-else>
      <div class="summary-row">
        <span>Your payment would be due on {{ offer.firstPaymentDate | formatdate }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import lodashFind from 'lodash/find';

export default {
  name: 'SettlementOfferSummary',

  props: {
    offer: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    isOfferValid: {
      type: Boolean,
      default: true,
    },

    border: {
      type: String,
      default: 'none',
      validator(value) {
        return ['none', 'top', 'botton', 'both'].indexOf(value) !== -1;
      },
    },
  },

  computed: {
    isPayoff() {
      return this.offer.offerType && this.offer.offerType === 'Pay In Full';
    },

    paymentFrequencyDescription() {
      const value = lodashFind(this.paymentFrequencies, { value: this.offer.paymentFrequency });

      if (value) {
        return `/ ${value.description}`;
      }

      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.summary-container {
  width: 100%;
  padding: 12px 0;

  &.border-top {
    border-top: 1px solid #003b4c;
  }

  &.border-bottom {
    border-bottom: 1px solid #003b4c;
  }

  .summary-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    span {
      flex: auto;
      display: inline-block;
      font-size: 1em;
    }

    span:last-child:not(:only-child) {
      text-align: right;
    }

    @media (max-width: 500px) {
      span {
        font-size: 0.87em;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
