<template>
  <v-container>
    <h3>FullStory Links</h3>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-alert
          v-if="!fsLinks.length"
          dense
          outlined
          type="warning"
        >
          There is no fullstory associated to this ticket.
        </v-alert>
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="(link, i) in fsLinks"
              :key="i"
            >
              <v-list-item-content>
                <a
                  :href="link"
                  target="_blank"
                >{{ link }}</a>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OpsTicketDialogFS',

  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      fsLinks: [],
    };
  },

  watch: {
    ticket: {
      deep: true,
      handler() {
        this.initFsLinks();
      },
    },
  },

  mounted() {
    this.initFsLinks();
  },

  methods: {
    initFsLinks() {
      if (this.ticket && this.ticket.fsLinks) {
        this.fsLinks = JSON.parse(this.ticket.fsLinks);
      }
    },

    clearFsLinks() {
      this.fsLinks = [];
    },
  },
};
</script>

<style scoped></style>
