<template>
  <v-card
    v-if="modelData && modelHasEverything"
    :loading="loadingModel"
    class="ticket-summary"
    elevation="12"
    outlined
    tile
  >
    <v-card-title>
      <v-row>
        <v-col
          cols="3"
          class="border-right"
        >
          <v-chip
            class="ma-2"
            label
          >
            Status:
          </v-chip>
          <span v-if="modelData.zendesk"> {{ modelData.zendesk.status.toUpperCase() }} </span>
        </v-col>
        <v-col
          cols="9"
          class="text-left"
        >
          <!-- Dynamic Alert By Loan Sub Status !! -->
          <template v-if="modelData && Object.keys(modelData.active_loan).length > 0">
            <v-chip
              class="ma-2"
              label
            >
              Zendesk Ticket #: {{ modelData.zendesk.id || 'N/A' }}
            </v-chip>
            <v-chip
              class="ma-2"
              label
            >
              Loan Type: {{ `${getLoanType(modelData.active_loan.loan_setup)}` }}
            </v-chip>
            <v-chip
              class="ma-2"
              label
            >
              Loan Status: {{ `${modelData.active_loan.current_status.loan_status_text}` }}
            </v-chip>
            <v-chip
              class="ma-2"
              label
            >
              Sub-Status: {{ `${modelData.active_loan.current_status.loan_sub_status_text}` }}
            </v-chip>
            <v-chip
              v-if="
                modelData.active_loan.current_status.loan_sub_status_text === 'Active Offer' &&
                  settings.brand.show_tbot_banner_zero_loc
              "
              class="ma-2"
              label
              color="green"
              text-color="white"
            >
              {{ `${settings.brand.tbot_banner_zero_loc_text}` }}
            </v-chip>
          </template>
        </v-col>
        <v-divider class="mx-1" />
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="3"
          class="border-right"
        >
          <v-row cols="12">
            <v-col cols="3">
              <v-chip
                class="pa-2 ml-0 mr-1"
                label
                small
              >
                Name
              </v-chip>
            </v-col>
            <v-col cols="9">
              <span v-if="Object.keys(modelData.lpCustomer).length > 0">
                {{
                  `${modelData.lpCustomer.first_name || ''}
                ${modelData.lpCustomer.last_name || ''}`
                }}
              </span>
            </v-col>
            <v-col cols="3">
              <v-chip
                class="pa-2 ml-0 mr-1"
                label
                small
              >
                Email
              </v-chip>
            </v-col>
            <v-col cols="9">
              {{ `${modelData.lpCustomer.email || ''}` }}
              <!--              <v-tooltip-->
              <!--                right-->
              <!--                color="primary"-->
              <!--              >-->
              <!--                <template v-slot:activator="{ on, attrs }">-->
              <!--                  <span-->
              <!--                    v-if="Object.keys(modelData.lpCustomer).length > 0"-->
              <!--                    class="col-pointer"-->
              <!--                    v-bind="attrs"-->
              <!--                    v-on="on"-->
              <!--                    @click.stop="handleUpdate('email')"-->
              <!--                  >-->
              <!--                    {{ `${modelData.lpCustomer.email || ''}` }}-->
              <!--                  </span>-->
              <!--                </template>-->
              <!--                <span>Click to Edit</span>-->
              <!--              </v-tooltip>-->
            </v-col>
            <v-col cols="3">
              <v-chip
                class="pa-2 ml-0 mr-1"
                label
                small
              >
                Phone
              </v-chip>
            </v-col>
            <v-col cols="9">
              <v-tooltip
                right
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-if="Object.keys(modelData.lpCustomer).length > 0"
                    class="col-pointer"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="handleUpdate('phone')"
                  >
                    {{ `${modelData.lpCustomer.homephone || ''}` }}
                  </span>
                </template>
                <span>Click to Edit</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <v-chip
                class="pa-2 ml-0 mr-1"
                label
                small
              >
                4 SSN
              </v-chip>
            </v-col>
            <v-col cols="9">
              <span v-if="Object.keys(modelData.lpCustomer).length > 0">
                {{ `${modelData.lpCustomer.ssn || ''}` }}
              </span>
            </v-col>
            <v-col cols="3">
              <v-chip
                class="pa-2 ml-0 mr-1"
                label
                small
              >
                DOB
              </v-chip>
            </v-col>
            <v-col cols="9">
              <span v-if="Object.keys(modelData.lpCustomer).length > 0">
                {{ `${modelData.lpCustomer.dob || ''}` }}
              </span>
            </v-col>
            <v-col cols="3">
              <v-chip
                class="pa-2 ml-0 mr-1"
                label
                small
              >
                Address
              </v-chip>
            </v-col>
            <LpPhoneEmailUpdate
              v-if="modelData.lpCustomer"
              :edited-item="modelData.lpCustomer"
              :ticket="modelData"
              :dialog="showUpdatePhoneEmailUpdateModal"
              :type="updateType"
              @close="showUpdatePhoneEmailUpdateModal = false"
            />
            <v-col cols="9">
              <span v-if="Object.keys(modelData.lpCustomer).length > 0">
                {{
                  `${modelData.lpCustomer.address || ''}
                  ${modelData.lpCustomer.address2 || ''}`
                }}
                <br>
                <span>
                  {{ modelData.lpCustomer.city ? modelData.lpCustomer.city + ',' : '' }}
                  {{ customerState !== '' ? customerState + ',' : '' }}
                  {{ modelData.lpCustomer.zip ? modelData.lpCustomer.zip : '' }}
                </span>
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="9"
          class="text-left"
        >
          <v-row>
            <v-col class="left">
              <v-chip
                class="ma-2"
                color="secondary"
                label
                outlined
              >
                Amount Due:
                {{ formatCurrency(pastDueAmount) }}
              </v-chip>
              <v-chip
                class="ma-2"
                color="secondary"
                label
                outlined
              >
                # Days Past Due:
                {{
                  modelData.active_loan || null
                    ? modelData.active_loan.current_status.days_past_due
                    : '---'
                }}
              </v-chip>
              <v-chip
                v-if="modelData.active_loan.loan_setup.loan_type === 'loan.type.creditLimit'"
                class="ma-2"
                color="secondary"
                label
                outlined
              >
                Available Credit:
                {{
                  modelData.active_loan || null
                    ? formatCurrency(computeAvailableCredit(modelData.active_loan.current_status))
                    : '---'
                }}
              </v-chip>
              <v-chip
                class="ma-2"
                color="secondary"
                label
                outlined
              >
                Next Payment Date:
                {{
                  modelData.active_loan || null
                    ? nFormatDate(modelData.active_loan.current_status.next_payment_date)
                    : '---'
                }}
              </v-chip>
              <v-chip
                class="ma-2"
                color="secondary"
                label
                outlined
              >
                Next Payment Amount:
                {{
                  modelData.active_loan || null
                    ? formatCurrency(modelData.active_loan.current_status.next_payment_amount)
                    : '---'
                }}
              </v-chip>
            </v-col>
            <v-col class="text-right">
              <OpsTicketModal
                v-if="opsTicketsActive"
                :lp-customer="modelData.lpCustomer"
              />

              <TicketLogModal
                v-if="!opsTicketsActive && modelData.lpCustomer"
                :edited-item="modelData.lpCustomer"
              />
              <LoanProCustomerLoansModal
                v-if="modelData.lpCustomer"
                :edited-item="modelData.lpCustomer"
              />
              <TicketResolveModal v-if="modelData.lpCustomer" />
            </v-col>
          </v-row>
          <!-- LOAN INFORMATION -->
          <v-card
            class="py-0 my-4"
            elevation="20"
            outlined
            tile
          >
            <v-card-text class="py-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Active Loan ID:
                      </th>
                      <th class="text-left">
                        Loan Status:
                      </th>
                      <th class="text-left">
                        Sub Status:
                      </th>
                      <th class="text-left">
                        Payoff Balance:
                      </th>
                      <th class="text-left">
                        Principal Balance:
                      </th>
                      <th class="text-left">
                        Loan Amount:
                      </th>
                      <th class="text-left">
                        Total Paid:
                      </th>
                      <th class="text-left">
                        Daily Change:
                      </th>
                      <th class="text-left">
                        Signing Date:
                      </th>
                      <th class="text-left">
                        Funding Date:
                      </th>
                      <th class="text-left">
                        Frequency:
                      </th>
                      <th class="text-left" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="modelData.active_loan && modelData.active_loan.current_status">
                      <td>{{ `${modelData.active_loan.display_id}` }}</td>
                      <td>{{ `${modelData.active_loan.current_status.loan_status_text}` }}</td>
                      <td>{{ `${modelData.active_loan.current_status.loan_sub_status_text}` }}</td>
                      <td>
                        {{ formatCurrency(modelData.active_loan.current_status.payoff) }}
                        <payoff-modal :loan-id="modelData.active_loan.id" />
                      </td>
                      <td>
                        {{ formatCurrency(modelData.active_loan.current_status.principal_balance) }}
                      </td>
                      <td>{{ formatCurrency(modelData.active_loan.loan_setup.loan_amount) }}</td>
                      <td>
                        {{ formatCurrency(modelData.active_loan.payments_sum_amount) }}
                        <paid-breakout-modal :loan-id="modelData.active_loan.id" />
                      </td>
                      <td>{{ formatCurrency(modelData.active_loan.current_status.perdiem) }}</td>
                      <td>
                        {{
                          modelData.active_loan.document_signed !== null
                            ? nFormatDate(modelData.active_loan.loan_setup.contract_date)
                            : '---'
                        }}
                      </td>
                      <td>
                        {{
                          modelData.active_loan.loan_funding !== null
                            ? nFormatDate(modelData.active_loan.loan_funding.date)
                            : '---'
                        }}
                      </td>
                      <td class="text-capitalize">
                        {{ `${modelData.active_loan.loan_setup.payment_frequency.split('.')[2]}` }}
                      </td>
                      <td>
                        <loan-action-menu
                          v-if="loanId"
                          :loan-id="loanId"
                          :loan-type="loanType"
                          :ticket="modelData"
                        />
                      </td>
                    </tr>
                    <tr v-else>
                      <td
                        class="py-2 text-center"
                        colspan="5"
                      >
                        No Active Loan Found.
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <!-- END LOAN INFORMATION -->
          <!-- AUTO PAY INFORMATION -->
          <v-card
            class="py-0 mt-4"
            elevation="20"
            outlined
            tile
          >
            <v-card-text class="py-0">
              <v-data-table
                :headers="autopayHeaders"
                :items="trimmedAutopays"
                class="elevation-1"
                :disable-pagination="true"
                :hide-default-footer="true"
              />
              <v-divider class="pb-1" />
              <v-row>
                <v-col cols="12">
                  <v-dialog
                    v-model="showAllAutopays"
                    class="fraud-claim"
                    width="1000px"
                    scrollable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        color="primary"
                        block
                        class="mb-1"
                        v-on="on"
                      >
                        View All Autopays
                        <v-icon class="pl-4">
                          mdi-arrow-expand
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <div>All Autopays:</div>
                      </v-card-title>
                      <v-divider class="mt-1 mb-1" />
                      <v-card-text>
                        <div class="subtitle-1">
                          Every column is sortable
                        </div>
                        <!--    possible error with Vuetify here-->
                        <!--    I believe our CSRs use MnK so this shouldn't be an issue-->
                        <!--    https://github.com/vuetifyjs/vuetify/issues/3765-->
                        <v-data-table
                          :headers="autopayHeaders"
                          :items="sortedAutopays"
                          :items-per-page="10"
                          class="elevation-1"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="showAllAutopays = !showAllAutopays"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <!-- END AUTO PAY INFORMATION ROWS -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-divider class="mx-1" />
      </v-row>
    </v-card-text>
  </v-card>
  <v-alert
    v-else-if="!loadingModel && !modelHasEverything"
    type="warning"
  >
    <p>
      <b>LP Customer could not be found.</b>
    </p>
    <p>
      There is no LoanPro information associated with this Ticket/Customer.
      This means this Customer is still a Lead and has not been given a loan yet.
      <b> Please only use Communication Actions until Customer is given an active loan. </b>
    </p>
  </v-alert>
  <v-card v-else>
    <v-card-text class="text-center">
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </v-card-text>
  </v-card>
</template>

<style>
.ticket-summary .v-card__text {
  color: #000000 !important;
}
.ticket-summary p {
  margin-bottom: 1px !important;
}
.ticket-summary th.text-left {
  font-weight: 600;
  font-size: 0.9rem !important;
}
.ticket-summary .border-right {
  border-right: 1px solid #e0e0e0;
}
.v-data-table-header th {
  white-space: nowrap !important;
}
.col-pointer {
  cursor: pointer;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import { orderBy } from 'lodash';
import { formatDate, formatCurrency } from '@/services/HelperService';
import OpsTicketModal from '@/views/Tickets/TicketSummary/OpsTicketModal.vue';
import TicketLogModal from '@/views/Tickets/TicketSummary/TicketLogModal.vue';
import LoanProCustomerLoansModal from './LoanProCustomerLoansModal.vue';
import PayoffModal from './PayoffModal.vue';
import LoanActionMenu from './LoanActionMenu.vue';
import PaidBreakoutModal from './PaidBreakoutModal.vue';
import TicketResolveModal from './TicketResolveModal.vue';
import LpPhoneEmailUpdate from './LpPhoneEmailUpdate.vue';

export default {
  name: 'TicketSummary',
  components: {
    TicketLogModal,
    OpsTicketModal,
    LoanProCustomerLoansModal,
    PayoffModal,
    LoanActionMenu,
    PaidBreakoutModal,
    TicketResolveModal,
    LpPhoneEmailUpdate,
  },
  props: {
    modelData: {
      // aka ticket
      type: Object,
      required: true,
    },
    autopays: {
      type: Array,
      required: true,
    },
    advances: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formatCurrency,
      showAllAutopays: false,
      showUpdatePhoneEmailUpdateModal: false,
      updateType: 'phone',
      autopayHeaders: [
        {
          text: 'Autopays',
          value: 'id',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Autopay Status',
          value: 'status',
          sortable: true,
        },
        {
          text: 'Processor Status',
          value: 'processor_status',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true,
        },
        {
          text: 'Frequency',
          value: 'recurring_frequency',
          sortable: true,
        },
        {
          text: 'Payment Method',
          value: 'payment_method_title',
          sortable: true,
        },
        {
          text: 'Recurring Periods',
          value: 'recurring_periods',
          sortable: true,
        },
        {
          text: 'Amount',
          value: 'amount',
          sortable: true,
        },
        {
          text: 'Apply Date',
          value: 'apply_date',
          sortable: true,
        },
        {
          text: 'Process Date',
          value: 'process_datetime',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('settings', ['settings']),
    opsTicketsActive() {
      return this.settings?.brand?.ops_ticket_active ?? false;
    },
    customerState() {
      // This is a computed because sometimes state comes like
      // this "geo.state.CO" and sometimes like this "CO"
      const state = this.modelData?.lpCustomer?.state || '';
      const splitState = state.split('.');
      return splitState[2] !== undefined ? splitState[2] : state;
    },
    modelHasEverything() {
      if (
        (this.modelData?.lpCustomer?.id || 0) > 0
        && (this.modelData?.zendesk?.id || 0) > 0
        // && (this.modelData?.customer?.id || 0) > 0
        && (this.modelData?.active_loan?.id || 0) > 0
      ) {
        return true;
      }
      return false;
    },
    loadingModel() {
      if ((this.modelData?.zendesk?.id || 0) > 0) {
        return false;
      }
      return true;
    },
    loanId: {
      get() {
        return this.modelData?.active_loan?.id || 0;
      },
      set(val) {
        this.setLoanId(val);
      },
    },
    loanType: {
      get() {
        return this.modelData?.active_loan?.loan_setup.loan_type;
      },
      set(val) {
        this.setLoanType(val);
      },
    },
    trimmedAutopays() {
      return this.sortedAutopays.slice(0, 2);
    },
    sortedAutopays() {
      return orderBy(this.formattedAutopays, ['status'], ['desc']);
    },
    formattedAutopays() {
      return (this.autopays || []).map((autopay) => ({
        ...autopay,
        status: autopay.status.split('.')[2].toUpperCase(),
        type: autopay.type.split('.')[2].toUpperCase(),
        recurring_frequency: autopay.recurring_frequency.split('.')[2].toUpperCase(),
        apply_date: formatDate(autopay.apply_date),
        process_datetime: formatDate(autopay.process_datetime),
        payment_method_type: autopay.primary_payment_account.type.split('.')[2],
        payment_method_title: autopay.primary_payment_account.title,
        processor_status: autopay?.payment_entity?.payment_info?.processor_status || '---',
      }));
    },
    pastDueAmount() {
      if (this.loanType === 'loan.type.creditLimit') {
        return this.modelData.active_loan.latest_statement
          ? this.modelData.active_loan.latest_statement.past_due_amount
          : 0.0;
      }
      if (this.loanType === 'loan.type.installment') {
        return this.modelData.active_loan.current_status
          ? this.modelData.active_loan.current_status.amount_due
          : 0.0;
      }
      return 0.0;
    },
  },
  watch: {
    modelData(val) {
      this.loanId = val.active_loan.id;
      this.loanType = val.active_loan.loanSetup.loanType;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('ticket', ['setLoanId', 'setLoanType']),
    nFormatDate(date) {
      return formatDate(date);
    },
    computeAvailableCredit(currentStatus) {
      return currentStatus.available_credit;
    },
    getLoanType(setUp) {
      const loanType = setUp.loan_type.split('.')[2];
      return loanType === 'creditLimit' ? 'Line of Credit' : loanType.toUpperCase();
    },
    handleUpdate(type) {
      this.updateType = type;
      this.showUpdatePhoneEmailUpdateModal = true;
    },
  },
};
</script>
