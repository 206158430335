<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Make Payment and Payoff
        </v-card-title>
        <v-card-text
          class="ma-5 py-5"
        >
          <v-row>
            <v-col>
              <p class="muted text-advices">
                Manage all payment related activities with payment wizard.
                Click the button below to get stated.
              </p>
              <wizard />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style>
.fraud-claim-dialog-btn {
  padding: 2rem 1rem !important;
}
</style>

<script>
import Wizard from './Wizard.vue';

export default {
  name: 'PaymentWizard',
  components: {
    Wizard,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
