<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Adverse Actions List</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <v-btn
            v-if="hasPermission('create system-settings::adverse-action')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Adverse Action
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Adverse Action"
            permission="create system-settings::adverse-action"
            :x-small="false"
            button-class="mb-2"
          />

          <AdverseActionSendEmailModal
            v-model="dialogEmailForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogEmailForm')"
            @email-sent="updateTable()"
            @click:outside="clearState()"
          />

          <AdverseActionFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <AdverseActionDeleteModal
            v-model="dialogDelete"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogDelete')"
            @item-deleted="itemDeleted()"
            @click:outside="clearState()"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <div class="d-flex justify-space-around">
        <v-icon
          v-if="hasPermission('edit system-settings::adverse-action')"
          :id="item"
          @click="editItem(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <disabled-for-lack-of-role-btn
          v-else
          text=""
          icon="mdi-pencil-outline"
          :text-button="true"
          permission="edit system-settings::adverse-action"
          :x-small="false"
        />

        <v-icon
          v-if="hasPermission('edit system-settings::adverse-action')"
          :id="item"
          class="blue--text"
          @click="openSendEmailModal(item)"
        >
          mdi-email-outline
        </v-icon>
        <disabled-for-lack-of-role-btn
          v-else
          text=""
          icon="mdi-email-outline"
          :text-button="true"
          permission="edit system-settings::adverse-action"
          :x-small="false"
        />

        <v-icon
          v-if="hasPermission('delete system-settings::adverse-action')"
          class="red--text"
          @click="deleteItem(item)"
        >
          mdi-delete-outline
        </v-icon>
        <disabled-for-lack-of-role-btn
          v-else
          text=""
          icon="mdi-delete-outline"
          :text-button="true"
          permission="delete system-settings::adverse-action"
          :x-small="false"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import AdverseActionFormModal from '@/views/Settings/AdverseAction/AdverseActionFormModal.vue';
import AdverseActionDeleteModal from '@/views/Settings/AdverseAction/AdverseActionDeleteModal.vue';
import AdverseActionSendEmailModal from '@/views/Settings/AdverseAction/AdverseActionSendEmailModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    AdverseActionFormModal,
    AdverseActionDeleteModal,
    AdverseActionSendEmailModal,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Code', value: 'code' },
      { text: 'Description', value: 'description' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: 183,
      },
    ],

    dialogDelete: false,
    dialogForm: false,
    dialogEmailForm: false,
    editedItem: {
      id: null,
    },
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    openSendEmailModal(item) {
      this.editedItem = { ...item };
      this.dialogEmailForm = true;
    },
    itemDeleted() {
      this.items = [];
      this.updateTable();
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/adverse-action', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
