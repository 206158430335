<template>
  <v-card
    flat
    tile
    class="grey lighten-3"
  >
    <v-card-title>
      <h2 class="headline">
        Lead Servicing
      </h2>

      <v-spacer />

      <div class="d-flex align-start justify-end">
        <v-btn
          depressed
          tile
          color="primary"
          class="mr-3"
          @click.stop="showSearchForm"
        >
          <v-icon class="mr-2">
            mdi-account-search
          </v-icon>
          Lead Servicing Search
        </v-btn>

        <v-btn
          depressed
          tile
          color="success"
          @click.stop="onFinalResultFilterChange('all')"
        >
          <v-icon class="mr-2">
            mdi-filter-remove-outline
          </v-icon>
          Clear Filters
        </v-btn>
      </div>
    </v-card-title>

    <ag-grid-vue
      :style="'width: 100%; height: ' + tableHeight"
      class="ag-theme-alpine"
      :grid-options="gridConfig.gridOptions"
      :column-defs="gridConfig.columnDefs"
      :default-col-def="gridConfig.defaultColDef"
      :row-data="rowData"
      :pagination="true"
      row-selection="single"
      @grid-ready="onGridReady"
    />

    <LeadDetails
      v-if="selectedLeadId"
      :lead-id.sync="selectedLeadId"
      @close="selectedLeadId = null"
    />

    <LeadServicingSearchForm
      v-if="showLeadServicingSearchForm"
      :show.sync="showLeadServicingSearchForm"
      :inputs="searchInputs"
      :search-error-msg="searchErrorMsg"
      @search="onLeadServicingSearch"
      @change="onLeadServicingSearchCriteriaChange"
    />
  </v-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapState } from 'vuex';

import CheckColumnRenderer from '../CheckColumnRenderer.vue';
import ActionComponent from '../ActionComponent.vue';
import LeadServicingSearchForm from './LeadServicingSearchForm.vue';
import LeadDetails from './LeadDetails.vue';
import gridFormatter from '../aggrid-formatter';

export default {
  name: 'LeadServicingIndex',
  components: {
    ActionComponent,
    CheckColumnRenderer,
    LeadServicingSearchForm,
    LeadDetails,
  },

  props: {
    height: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState('app', ['settings']),
    tableHeight() {
      const newTableHeight = this.height - 245;
      return `${newTableHeight}px`;
    },
  },

  data() {
    return {
      showLeadServicingSearchForm: false,
      searchInputs: [
        { label: 'Lead Guid', name: 'leadGuid' },
        { label: 'Guid', name: 'guid' },
        { label: 'Email', name: 'email' },
        { label: 'Phone Number', name: 'phone' },
      ],
      selectedLeadId: null,
      gridConfig: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        defaultColDef: null,
      },
      rowData: null,
      searchErrorMsg: '',
    };
  },

  watch: {
    rowData() {
      if (this.rowData) {
        this.gridConfig.gridApi.sizeColumnsToFit();
        this.gridConfig.gridColumnApi.autoSizeAllColumns(false);
      }
    },
  },

  beforeMount() {
    this.gridConfig.gridOptions = {};

    this.gridConfig.columnDefs = [
      { headerName: 'Id', field: 'lead_id', pinned: 'left' },
      {
        headerName: 'Create Date',
        field: 'lead_create_date',
        pinned: 'left',
        valueFormatter: gridFormatter.date,
      },
      {
        headerName: 'Waterfall Result',
        field: 'waterfall_result',
        cellClass: this.waterfallResultClass,
      },
      { headerName: 'Name', field: 'name' },
      { headerName: 'E-Mail', field: 'email' },
      { headerName: 'SSN', field: 'ssn' },
      { headerName: 'Account Number', field: 'bank_account_num' },
      {
        headerName: 'ID Check',
        field: 'id_check_pass_fail',
        cellClass: this.idCheckClass,
      },
      {
        headerName: 'Docusign Status',
        field: 'docusign_status_display',
        cellClass: this.docSignResultClass,
      },
      { headerName: 'Redirect URL', field: 'redirect_url' },
      { headerName: 'Max Loan Amount', field: 'max_loan_amount' },
      { headerName: 'APR', field: 'apr' },
      { headerName: 'Payment Frequency', field: 'pay_frequency' },
      { headerName: 'Term', field: 'term' },
    ];

    if (this.settings.servicing_show_2fa) {
      this.gridConfig.columnDefs.push(
        { headerName: '2FA Code', field: 'two_factor_auth_code' },
      );
    }

    this.gridConfig.columnDefs.push(
      {
        headerName: 'Action',
        field: 'lead_id',
        pinned: 'right',
        cellRendererFramework: 'ActionComponent',
        onCellClicked: this.onCellClicked,
      },
    );

    this.gridConfig.defaultColDef = {
      sortable: true,
      resizable: true,
      flex: 1,
      autoHeight: true,
    };
  },

  async mounted() {
    this.gridConfig.gridApi = this.gridConfig.gridOptions.api;
    this.gridConfig.gridColumnApi = this.gridConfig.gridOptions.columnApi;
  },

  methods: {
    waterfallResultClass(params) {
      switch (params.value) {
        case 'accepted':
          return 'green--text';
        case 'lead_rejected':
          return 'amber--text';
        default:
          return 'red--text';
      }
    },

    docSignResultClass(params) {
      switch (params.value) {
        case 'Signed':
        case 'Signed-MLA':
          return 'green--text';
        default:
          return 'red--text';
      }
    },

    idCheckClass(params) {
      switch (params.value) {
        case 'pass':
          return 'green--text';
        default:
          return 'red--text';
      }
    },

    async onGridReady() {
      this.gridConfig.gridApi.sizeColumnsToFit();
      this.gridConfig.gridColumnApi.autoSizeAllColumns(false);
      this.rowData = [];
      this.gridConfig.gridApi.hideOverlay();
      this.showSearchForm();
    },

    async onFinalResultFilterChange(value) {
      this.selectedFinalResultFilter = value;
      this.rowData = [];
      this.showSearchForm();
    },

    showSearchForm() {
      this.searchErrorMsg = '';
      this.showLeadServicingSearchForm = true;
    },

    onLeadServicingSearchCriteriaChange() {
      this.searchErrorMsg = '';
    },

    onLeadServicingSearch(search) {
      this.gridConfig.gridApi.showLoadingOverlay();

      let postData = [];
      postData = search;
      postData.perPage = 300;

      return this.$axios
        .post('api/v1/lead/servicing', postData)
        .then(({ status, data: { data } }) => {
          if (status === 200 && data) {
            this.rowData = data;
          }
          this.showLeadServicingSearchForm = false;
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.searchErrorMsg = error.response.data.message;
            this.gridConfig.gridApi.hideOverlay();
          }
        });
    },

    onCellClicked({ value: selectedLeadId }) {
      this.selectedLeadId = Number(selectedLeadId);
    },
  },
};
</script>
