<template>
  <v-dialog
    v-model="dialog"
    width="1000px"
    scrollable
  >
    <template #activator="{ on: dialog }">
      <v-btn
        v-if="hasPermission('view ticket::settlement-reset')"
        color="primary"
        x-large
        block
        class="fraud-claim-dialog-btn mt-2"
        v-on="{...dialog}"
      >
        Reset Settlement {{ sendEmail ? 'and Send Email' : '' }}
      </v-btn>
      <disabled-for-lack-of-role-btn
        v-else
        :text="sendEmail ? 'Reset Settlement and Send Email' : 'Reset Settlement'"
        permission="view ticket::settlement-reset"
        :x-small="false"
        :x-large="true"
        :block="true"
        button-class="fraud-claim-dialog-btn mt-2"
      />
    </template>

    <v-card :loading="loadingModel">
      <v-card-title class="text-h5 grey lighten-2">
        Reset Settlement {{ sendEmail ? 'and Send Email' : '' }}
      </v-card-title>

      <v-card-text class="py-5">
        In order to reset this Settlement, please click on the "Reset Settlement" button below.
        This action will do the following:
        <ul>
          <li>Delete the Settlement Portal Credit</li>
          <li>Delete any and all autopays</li>
          <li>
            Reverse any payments processed with apply date equal to or greater than
            tomorrow and void corresponding pending collections transaction in Secure
            Payments
          </li>
          <li>Place loan in previous Collections Status</li>
          <li
            v-if="sendEmail"
          >
            Send the Communication Macro that tells the user to set up a new Payment Plan
            (This is Email reference in orange box)
          </li>
        </ul>
      </v-card-text>

      <v-divider />

      <v-card-actions class="py-3">
        <v-spacer />
        <v-btn
          v-if="hasPermission('create ticket::settlement-reset')"
          color="primary"
          :disabled="loadingModel"
          :loading="loadingModel"
          @click="resetSettlement()"
        >
          Reset Settlement
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          text="Reset Settlement"
          permission="create ticket::settlement-reset"
          :x-small="false"
        />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <reusable-communication-by-email-template
      ref="settlementMacro"
      :customer="ticket.lpCustomer"
      :ticket-id="ticket.zendesk.id * 1"
      title="NEW PORTAL Login Instructions + SETTLEMENT Dashboard"
      action="portal-login-instructions-settlement"
      tag="portal_login_instructions_settlement"
      :extra="{}"
      :hide="true"
    />
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import ReusableCommunicationByEmailTemplate from '../Communications/ReusableCommunicationByEmailTemplate.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'ResetSettlementDialog',
  components: {
    ReusableCommunicationByEmailTemplate,
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    sendEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      loadingModel: false,
      valid: true,
    };
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async resetSettlement() {
      this.loadingModel = true;
      try {
        const res = await this.$axios
          .post('api/v1/support/ticket/settlement-reset/reset', {
            ticketId: this.ticket.zendesk.id * 1,
            loanId: this.ticket.active_loan.id * 1,
          });
        if (res.status === 200 && res.data) {
          this.$refs.settlementMacro.macro = await this.$refs.settlementMacro.getMacroTemplate();
          await this.$refs.settlementMacro.processForm();
          let msg = 'You have successfully Reset the Settlement';
          if (res.data.message) {
            msg = res.data.message;
          }
          this.setNotification({
            message: msg,
            color: 'green',
          });
          this.loadingModel = false;
          this.dialog = false;
          this.$emit('success');
        }
      } catch (error) {
        let errMsg = 'There was an error processing your request';
        if (error.response && error.response.status === 404 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
  },
};
</script>
