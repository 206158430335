<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(item, index) in menus">
        <v-tab
          v-if="hasPermission(item.permission)"
          :key="index"
          :to="{ name: item.route }"
        >
          {{ item.label }}
        </v-tab>
      </template>
    </v-tabs>
    <router-view
      :height="height"
    />
  </v-container>
</template>

<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { mapState } from 'vuex';

Vue.component('AgGridVue', AgGridVue);

export default {
  name: 'LeadIndex',

  data() {
    return {
      height: 0,
      activeTabIndex: 0,
    };
  },
  computed: {
    ...mapState('settings', ['settings']),
    menus() {
      const menus = [
        {
          label: 'Stream',
          route: 'lead-streams',
          permission: 'view lead::stream',
        },
        {
          label: 'Campaigns',
          route: 'campaigns',
          permission: 'view lead::campaigns',
        },
        {
          label: 'Lead Providers',
          route: 'lead-providers',
          permission: 'view lead::lead-providers',
        },
      ];

      if (this.settings.brand.prospect_active) {
        menus.push({
          label: 'Prescreen Campaigns',
          route: 'prescreen-campaigns',
          permission: 'view prescreen::campaign',
        });

        menus.push({
          label: 'Prescreen Files',
          route: 'prescreen-files',
          permission: 'view prescreen::file-list',
        });
      }

      return menus;
    },
  },
  watch: {
    // needed so if you click Leads in the sidebar when on a Lead page tab
    $route() {
      if (this.$route.name === 'leads') {
        this.goToLeadTab();
      }
    },
  },
  created() {
    this.goToLeadTab();
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.height = window.innerHeight;
    },
    goToLeadTab() {
      const canSeeLeadStream = this.hasPermission('view lead::stream');
      const canSeeLeadAmortization = this.hasPermission('view lead::amortization');
      const canSeeLeadServicing = this.hasPermission('view lead::servicing');

      // redirect to Lead tab if requesting `/leads`
      if (this.$route.name === 'leads') {
        if (canSeeLeadStream) {
          this.$router.push({ name: 'lead-streams' });
        } else if (canSeeLeadAmortization) {
          this.$router.push({ name: 'lead-amortizations' });
        } else if (canSeeLeadServicing) {
          this.$router.push({ name: 'lead-servicing' });
        }
      }
    },
  },
};
</script>

<style></style>
