import UnauthLayout from '@/layouts/Unauth/UnauthLayout.vue';
import Login from '@/views/Login.vue';
import Maintenance from '@/views/Maintenance.vue';
import ForgotPassword from '@/views/ForgotPassword/ForgotPassword.vue';
import ResetPassword from '@/views/ForgotPassword/ResetPassword.vue';

export default {
  path: '',
  component: UnauthLayout,
  children: [
    {
      path: 'login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Login',
      },
    },
    {
      path: 'maintenance',
      name: 'maintenance',
      component: Maintenance,
      meta: {
        title: 'Under Maintenance',
      },
    },
    {
      path: 'forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        title: 'Forgot Password',
      },
    },
    {
      path: 'reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        title: 'Reset Password',
      },
    },
  ],
};
