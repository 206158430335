<template>
  <v-form
    ref="paymentPlanForm"
    novalidate
    class="settlement-option"
    @submit.prevent="onSubmit"
  >
    <!-- Total Settlement Amount -->
    <div>
      <p class="mb-xxxs">
        How much are you willing to pay in total to settle your loan debt?
      </p>
      <v-text-field
        v-model.number="stlmt.settlementAmount"
        prepend-inner-icon="mdi-currency-usd"
        label="Total Settlement Amount"
        outlined
        required
      />
    </div>
    <!-- ./ Total Settlement Amount -->

    <!-- Payment Frequency -->
    <div class="mt-xs">
      <p class="mb-xxxs">
        How often can you make payments?
      </p>
      <v-select
        v-model="stlmt.paymentFrequency"
        :items="paymentFrequencies"
        item-value="value"
        item-text="title"
        label="Payment Frequency"
        outlined
        required
      />
    </div>
    <!-- ./ Payment Frequency -->

    <!-- Payment Amount -->
    <div class="mt-xs">
      <p class="mb-xxxs">
        How much can you afford for each payment?
      </p>
      <v-text-field
        v-model.number="stlmt.paymentAmount"
        prepend-inner-icon="mdi-currency-usd"
        label="Payment Amount"
        outlined
        required
      />
    </div>
    <!-- ./ Payment Amount -->

    <PaymentMethodSelect
      :ticket="ticket"
      :settlement-offer="stlmt"
      :payment-accounts="paymentAccounts"
      :error-messages="paymentAccountError"
      @reload-payment-profiles="$emit('reload-payment-profiles')"
    />

    <!-- First Payment Date -->
    <div class="mt-xs">
      <p class="mb-xxxs">
        When can you make your first payment?
      </p>
      <v-text-field
        v-model="stlmt.firstPaymentDate"
        label="First Payment Date"
        type="date"
        :error-messages="paymentDateError"
        outlined
        required
      />
      <v-progress-linear
        v-if="isCheckingPaymentDate"
        class="mt-n7 mb-6"
        indeterminate
      />
    </div>
    <!-- ./ First Payment Date -->

    <v-card-actions
      class="justify-end"
      width="100%"
    >
      <v-btn
        button-full
        size="large"
        color="settlement"
        :disabled="disabledConfirm"
        @click="onSubmit"
      >
        CONFIRM PAYMENT PLAN OFFER
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import PaymentMethodSelect from './PaymentMethodSelect.vue';

export default {
  name: 'SettlmentOfferPaymentPlan',

  components: {
    PaymentMethodSelect,
  },

  props: {
    paymentAccounts: {
      type: Array,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    refreshKey: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      stlmt: {
        settlementAmount: 0,
        paymentAmount: 0,
        paymentFrequency: null,
        firstPaymentDate: null,
        paymentAccountId: null,
      },
      isCheckingPaymentDate: false,
      submitted: false,
      paymentDateError: null,
      paymentAccountError: '',
      paymentFrequencies: [
        { title: 'Weekly', value: 'weekly', description: 'week' },
        { title: 'Bi-Weekly', value: 'biWeekly', description: 'every other week' },
        { title: 'Monthly', value: 'monthly', description: 'month' },
      ],
    };
  },

  computed: {
    paymentDateErrors() {
      return [];
    },

    isManualPayment() {
      return (this.stlmt.paymentAccountId !== null && this.stlmt.paymentAccountId === -1);
    },

    disabledConfirm() {
      return !this.stlmt.settlementAmount
        || !this.stlmt.paymentAmount
        || !this.stlmt.paymentFrequency
        || !this.stlmt.paymentAccountId
        || !this.stlmt.firstPaymentDate;
    },
  },

  watch: {
    'stlmt.paymentAccountId': {
      async handler(newVal) {
        if (newVal !== null && this.stlmt.firstPaymentDate !== null) {
          this.paymentAccountError = '';
          this.paymentDateError = '';
          this.checkNextPaymentDate(this.stlmt.firstPaymentDate, this.stlmt.firstPaymentDate);
        }
      },
    },
    'stlmt.firstPaymentDate': {
      async handler(paymentDate, prevValue) {
        if (!this.stlmt.paymentAccountId) {
          this.paymentAccountError = 'Please select the payment account';
          this.paymentDateError = 'Please select payment account first and then payment date';
        } else {
          this.checkNextPaymentDate(paymentDate, prevValue);
        }
      },
    },
    refreshKey: {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        // if the key value has changed, we need to reset the form
        this.resetFormValues();
      },
    },
  },

  created() {
    this.resetFormValues();
  },

  deactivated() {
    this.$v.$reset(); // reset form validation
    this.submitted = false; // make sure error don't show on initial load
  },

  methods: {
    ...mapActions('settlement', ['checkPaymentDate', 'checkManualPaymentDate']),

    /**
     * Send settlement for autopay submit.
     * @returns {Promise<void>}
     */
    async onSubmit() {
      this.submitted = true;

      const checkPaymentFunction = (this.isManualPayment) ? 'checkManualPaymentDate' : 'checkPaymentDate';

      if (!this.$v.$invalid) {
        await this[checkPaymentFunction]({
          paymentAccountId: this.stlmt.paymentAccountId,
          paymentDate: this.stlmt.firstPaymentDate,
        })
          .then(async (resp) => {
            // passed check
            if (resp.data === true) {
              this.$emit('form-submit', { ...this.stlmt });
            } else {
              this.paymentDateError = resp.data;
            }
          })
          .catch(() => { this.paymentDateError = 'payment date validation error'; });
      }
    },

    resetFormValues() {
      this.stlmt.settlementAmount = 0;
      this.stlmt.paymentAmount = 0;
      this.stlmt.paymentFrequency = null;
      this.stlmt.firstPaymentDate = null;
      this.stlmt.paymentAccountId = null;
    },

    /**
     * Update pmtRefresh Value.
     * @param paymentDate
     * @param previousPaymentDate
     */
    updatePmt(paymentDate, previousPaymentDate) {
      // make sure we change only when the value changes
      if (paymentDate !== previousPaymentDate) {
        // if the payment schedule has changed, we may need to send it again
        this.pmtRefresh += 1;
      }
    },

    /**
     * Check Next Payment date for manual and autopay.
     * @param paymentDate
     * @param previousPaymentDate
     */
    checkNextPaymentDate(paymentDate, previousPaymentDate) {
      this.updatePmt(paymentDate, previousPaymentDate);

      if (this.isManualPayment) {
        this.checkNextManualPaymentDate(paymentDate);
      } else {
        this.checkNextAutopayPaymentDate(paymentDate);
      }
    },

    /**
     * Checking autopay next payment date.
     * @param paymentDate
     * @returns {Promise<void>}
     */
    async checkNextAutopayPaymentDate(paymentDate) {
      this.isCheckingPaymentDate = true;
      await this.checkPaymentDate({
        paymentAccountId: this.stlmt.paymentAccountId,
        paymentDate,
      })
        .then((resp) => {
          // passed check
          if (resp.data === true) {
            this.paymentDateError = null;
            this.$refs.paymentPlanForm.resetValidation();
          } else {
            this.paymentDateError = resp.data;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.paymentDateError = 'payment date validation error';
          this.isLoading = false;
        });
      this.isCheckingPaymentDate = false;
    },

    /**
     * Check next manual payment date.
     * @param paymentDate
     * @returns {Promise<void>}
     */
    async checkNextManualPaymentDate(paymentDate) {
      this.isCheckingPaymentDate = true;
      await this.checkManualPaymentDate({ paymentDate })
        .then((response) => {
          if (response.data === true) {
            this.paymentDateError = null;
            this.$refs.paymentPlanForm.resetValidation();
          } else {
            this.paymentDateError = response.data;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.paymentDateError = 'payment date validation error';
          this.isLoading = false;
        });
      this.isCheckingPaymentDate = false;
    },

  },

  validations: {
    stlmt: {
      settlementAmount: {
        required,
        positiveNonZero: (v) => v > 0,
      },
      paymentAmount: {
        required,
        positiveNonZero: (v) => v > 0,
      },
      paymentFrequency: {
        required,
      },
      firstPaymentDate: {
        required,
      },
    },
  },
};
</script>

<style>
</style>
