/* eslint-disable import/prefer-default-export, no-param-reassign */
export function addRequiresAuthMeta(route) {
  let authRoute = { ...route };

  // add requiresAuth = true to all admin routes
  if (!('meta' in authRoute)) {
    authRoute = { ...authRoute, meta: {} };
  }

  // if no permission is assigned, provide blank value
  authRoute.meta.requiresAuth = true;
  if (!('permission' in authRoute.meta)) {
    authRoute.meta.permission = '';
  }

  if ('children' in authRoute && Array.isArray(authRoute.children)) {
    authRoute.children = authRoute.children.map((childRoute) => addRequiresAuthMeta(childRoute));
  }

  return authRoute;
}
