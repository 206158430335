<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <v-card-title> Affiliate Report {{ reportTitle }} </v-card-title>
        <v-btn
          v-if="showBackButton"
          class="mr-2"
          small
          color="secondary"
          :to="{ name: 'lead-dashboard' }"
        >
          Back to Lead Dashboard Report
        </v-btn>
      </div>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="fromDate"
                required
                label="From Date"
                type="date"
                :error-messages="fromDateErrors"
                @input="$v.fromDate.$touch()"
                @blur="$v.fromDate.$touch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="toDate"
                required
                label="To Date"
                type="date"
                :error-messages="toDateErrors"
                @input="$v.toDate.$touch()"
                @blur="$v.toDate.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="returnCustomer"
                :items="returnCustomerOptions"
                label="Customer Type"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="usState"
                :items="usStates"
                label="States"
              />
            </v-col>
          </v-row>

          <v-btn
            class="btn-bg-deep-blue"
            :disabled="formProcessing"
            :loading="reportGenerating"
            @click="generateReport()"
          >
            Generate Report
          </v-btn>
        </v-form>
      </v-container>
    </v-card>

    <template v-if="reportLoaded">
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-1 mt-2"
      >
        <template v-slot:top>
          <v-card-title>
            <v-toolbar flat>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-toolbar>
          </v-card-title>
        </template>

        <template v-slot:item.pass_percent="{ item }">
          {{ formatValue(item.pass_percent) }}%
        </template>

        <template v-slot:item.redirected_rate="{ item }">
          {{ formatValue(item.redirected_rate) }}%
        </template>

        <template v-slot:item.click_through_pct="{ item }">
          {{ formatValue(item.click_through_pct) }}%
        </template>

        <template v-slot:item.conversion="{ item }">
          {{ formatValue(item.conversion) }}%
        </template>

        <template v-slot:item.data_cost_per_lead="{ item }">
          {{ formatValue(item.data_cost_per_lead) }}
        </template>

        <template v-slot:item.lead_cost_fee="{ item }">
          {{ formatValue(item.lead_cost_fee) }}
        </template>

        <template v-slot:item.total_cost_per_lead="{ item }">
          {{ formatValue(item.total_cost_per_lead) }}
        </template>

        <template v-slot:item.acq_cost_dollar="{ item }">
          {{ formatValue(item.acq_cost_dollar) }}
        </template>

        <template v-slot:item.acq_cost_pct="{ item }">
          {{ formatValue(item.acq_cost_pct) }}%
        </template>

        <template v-slot:item.acq_cost_pct="{ item }">
          {{ formatValue(item.acq_cost_pct) }}%
        </template>

        <template v-slot:item.fpd="{ item }">
          {{ formatValue(item.fpd) }}
        </template>

        <template v-slot:item.first_payment_amount="{ item }">
          {{ formatValue(item.first_payment_amount) }}
        </template>

        <template v-slot:item.first_payment_reversed_amount="{ item }">
          {{ formatValue(item.first_payment_reversed_amount) }}
        </template>

        <!-- Totals Row -->
        <template slot="body.append">
          <tr>
            <th>Totals/Avgs</th>
            <th>&mdash;</th>
            <th>&mdash;</th>
            <th>{{ formatValue(reportTotals.total_leads) }}</th>
            <th>{{ formatValue(reportTotals.accepted_leads) }}</th>
            <th>{{ formatValue(reportTotals.pass_percent) }}%</th>
            <th>{{ formatValue(reportTotals.redirect_count) }}</th>
            <th>{{ formatValue(reportTotals.redirected_rate) }}%</th>
            <th>{{ formatValue(reportTotals.click_through_count) }}</th>
            <th>{{ formatValue(reportTotals.click_through_pct) }}%</th>
            <th>{{ formatValue(reportTotals.count_funded) }}</th>
            <th>{{ formatValue(reportTotals.funded_amount) }}</th>
            <th>{{ formatValue(reportTotals.conversion) }}%</th>
            <th>{{ formatValue(reportTotals.data_cost_per_lead) }}</th>
            <th>{{ formatValue(reportTotals.lead_cost_fee) }}</th>
            <th>{{ formatValue(reportTotals.total_cost_per_lead) }}</th>
            <th>{{ formatValue(reportTotals.acq_cost_dollar) }}</th>
            <th>{{ formatValue(reportTotals.acq_cost_pct) }}%</th>
            <th>{{ formatValue(reportTotals.fpd) }}</th>
            <th>{{ formatValue(reportTotals.first_payment_amount) }}</th>
            <th>{{ formatValue(reportTotals.first_payment_count) }}</th>
            <th>{{ formatValue(reportTotals.first_payment_reversed_amount) }}</th>
            <th>{{ formatValue(reportTotals.first_payment_reversed_count) }}</th>
          </tr>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import { DateTime } from 'luxon';
import stateList from '@/data/states.json';

export default {
  name: 'AffiliateReportIndex',

  data() {
    return {
      fromDate: DateTime.local().toISODate(),
      toDate: DateTime.local().toISODate(),
      search: '',
      returnCustomer: null,
      returnCustomerOptions: [
        {
          value: null,
          text: 'All Customers',
        },
        {
          value: 0,
          text: 'New Customers',
        },
        {
          value: 1,
          text: 'Return Customers',
        },
      ],
      leadProviderId: null,
      leadProviderName: null,
      campaignId: null,
      campaignName: null,
      showBackButton: false,
      usState: null,
      usStates: [],
      reportData: [],
      reportTotals: {},
      reportLoaded: false,
      formProcessing: false,
      reportGenerating: false,
      headers: [
        { text: 'Source', value: 'company_name' },
        { text: 'Camp', value: 'campaign_name' },
        { text: 'Affid', value: 'affid' },
        { text: 'Presented', value: 'total_leads' },
        { text: 'Accept', value: 'accepted_leads' },
        { text: 'Accept Rate', value: 'pass_percent' },
        { text: 'Redir', value: 'redirect_count' },
        { text: 'Redir Rate', value: 'redirected_rate' },
        { text: 'Clk Through Cnt', value: 'click_through_count' },
        { text: 'Clk Through %', value: 'click_through_pct' },
        { text: 'Orig #', value: 'count_funded' },
        { text: 'Orig $', value: 'funded_amount' },
        { text: 'Conv', value: 'conversion' },
        { text: 'Data Cost', value: 'data_cost_per_lead' },
        { text: 'Lead Cost', value: 'lead_cost_fee' },
        { text: 'Total Cost', value: 'total_cost_per_lead' },
        { text: 'Acq Cost $', value: 'acq_cost_dollar' },
        { text: 'Acq Cost %', value: 'acq_cost_pct' },
        { text: 'FPD $%', value: 'fpd' },
        { text: '1st pmt $', value: 'first_payment_amount' },
        { text: '1st pmt cnt', value: 'first_payment_count' },
        { text: '1st pmt reverse $', value: 'first_payment_reversed_amount' },
        { text: '1st pmt reverse cnt', value: 'first_payment_reversed_count' },
      ],
      items: [],
    };
  },
  computed: {
    fromDateErrors() {
      const errors = [];

      if (!this.$v.fromDate.$dirty) {
        return errors;
      }
      if (!this.$v.fromDate.required) {
        errors.push('From Date is required.');
      }
      return errors;
    },
    toDateErrors() {
      const errors = [];

      if (!this.$v.toDate.$dirty) {
        return errors;
      }
      if (!this.$v.toDate.required) {
        errors.push('To Date is required.');
      }
      return errors;
    },
    reportTitle() {
      if (this.leadProviderName && this.campaignName) {
        return `- ${this.leadProviderName} | ${this.campaignName}`;
      }

      return null;
    },
  },
  created() {
    this.usStates = stateList.map((state) => state.id);

    this.usStates.unshift({
      value: null,
      text: 'All States',
    });

    const routeParams = this.$route.params;
    let generateReport = false;

    if (routeParams.fromDate) {
      this.fromDate = routeParams.fromDate;
      generateReport = true;
    }

    if (routeParams.toDate) {
      this.toDate = routeParams.toDate;
      generateReport = true;
    }

    if (routeParams.leadProviderId) {
      this.leadProviderId = routeParams.leadProviderId;
      this.leadProviderName = routeParams.leadProviderName;
      generateReport = true;
    }

    if (routeParams.campaignId) {
      this.campaignId = routeParams.campaignId;
      this.campaignName = routeParams.campaignName;
      generateReport = true;
    }

    if (generateReport) {
      this.showBackButton = true;
      this.generateReport();
    }
  },
  methods: {
    generateReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .get('api/v1/reports/affiliate-report', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
              returnCustomer: this.returnCustomer,
              state: this.usState,
              leadProviderId: this.leadProviderId,
              campaignId: this.campaignId,
            },
          })
          .then((res) => {
            vm.items = Object.values(res.data.reportData);
            vm.reportTotals = { ...res.data.reportTotals };
            vm.reportLoaded = true;
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },

    formatValue(value) {
      return value ? parseFloat(value).toFixed(2) : 0;
    },
  },
  validations: {
    fromDate: { required },
    toDate: { required },
  },
};
</script>

<style></style>
