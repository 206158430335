<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="slug"
                  required
                  label="Slug"
                  :error-messages="slugErrors"
                  @input="$v.slug.$touch()"
                  @blur="$v.slug.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="wfClass"
                  required
                  label="Class"
                  :error-messages="wfClassErrors"
                  @input="$v.wfClass.$touch()"
                  @blur="$v.wfClass.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="method"
                  required
                  label="Method"
                  :error-messages="methodErrors"
                  @input="$v.method.$touch()"
                  @blur="$v.method.$touch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="adverseActionId"
                  :items="adverseActions"
                  label="Adverse Action"
                  :error-messages="adverseActionIdErrors"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="sendEmail"
                  :items="['Yes', 'No']"
                  label="Email Adverse Action"
                  :error-messages="sendEmailErrors"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="leadProviderType"
                  :items="['Lead', 'Direct', 'Both']"
                  label="Lead Provider Type"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          filter_slug: '',
          class: '',
          method: '',
          adverse_action_id: '',
          send_email: '',
          lead_provider_type: '',
        };
      },
    },
    adverseActions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      slug: '',
      wfClass: '',
      method: '',
      adverseActionId: '',
      sendEmail: '',
      leadProviderType: '',
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      const newOrUpdate = this.isNewItem ? 'New' : 'Update';
      return `${newOrUpdate} Waterfall Filter`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    slugErrors() {
      const errors = [];
      if (!this.$v.slug.$dirty) {
        return errors;
      }
      if (!this.$v.slug.required) {
        errors.push('Slug is required.');
      }
      return errors;
    },
    wfClassErrors() {
      const errors = [];
      if (!this.$v.wfClass.$dirty) {
        return errors;
      }
      if (!this.$v.wfClass.required) {
        errors.push('Class is required.');
      }
      return errors;
    },
    methodErrors() {
      const errors = [];
      if (!this.$v.method.$dirty) {
        return errors;
      }
      if (!this.$v.method.required) {
        errors.push('Method is required.');
      }
      return errors;
    },
    adverseActionIdErrors() {
      const errors = [];
      if (!this.$v.adverseActionId.$dirty) {
        return errors;
      }
      if (!this.$v.adverseActionId.required) {
        errors.push('Adverse action is required.');
      }
      return errors;
    },
    sendEmailErrors() {
      const errors = [];
      if (!this.$v.sendEmail.$dirty) {
        return errors;
      }
      if (!this.$v.sendEmail.required) {
        errors.push('Send email is required.');
      }
      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.slug = this.editedItem.filter_slug;
      this.wfClass = this.editedItem.class;
      this.method = this.editedItem.method;
      this.adverseActionId = this.editedItem.adverse_action_id;
      this.leadProviderType = this.editedItem.lead_provider_type;
      this.sendEmail = this.editedItem.send_email ? 'Yes' : 'No';
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';
        const data = {
          filter_slug: this.slug,
          class: this.wfClass,
          method: this.method,
          adverse_action_id: this.adverseActionId,
          lead_provider_type: this.leadProviderType,
          send_email: this.sendEmail === 'Yes' ? 1 : 0,
        };

        if (this.isNewItem) {
          request = this.$axios.post('/api/v1/waterfall-filter', data);
        } else {
          request = this.$axios.put(`/api/v1/waterfall-filter/${this.editedItem.id}`, data);
        }

        request
          .then(() => {
            this.$emit('item-saved');

            this.setNotification({
              message: 'Waterfall filter has been saved.',
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');

      this.slug = '';
    },
  },
  validations: {
    slug: { required },
    wfClass: { required },
    method: { required },
    adverseActionId: { required },
    sendEmail: { required },
  },
};
</script>

<style></style>
