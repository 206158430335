<template>
  <div class="mt-3">
    <template
      v-if="decryptButton"
    >
      <v-btn
        depressed
        tile
        color="primary"
        :disabled="formProcessing"
        @click="showConfirmButtons"
      >
        Decrypt
      </v-btn>
    </template>

    <template
      v-if="confirmButtons"
    >
      <p class="mb-1">
        Please enter the reason for viewing this data:
      </p>
      <v-text-field
        v-model="reason"
        label="Reason"
        style="max-width: 80%"
      />
      <v-btn
        small
        color="secondary"
        class="mr-2"
        :disabled="formProcessing"
        @click="showDecryptButton"
      >
        Cancel
      </v-btn>
      <v-btn
        small
        color="primary"
        :disabled="formProcessing"
        @click="confirmDecrypt"
      >
        Confirm
      </v-btn>
    </template>

    <template
      v-if="processingMessage"
    >
      <p>Processing...</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LoanProCustomersLoansConfirmDecryptButtons',

  props: {
    formProcessing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      reason: '',
      decryptButton: true,
      confirmButtons: false,
      processingMessage: false,
    };
  },

  methods: {
    confirmDecrypt() {
      this.showProcessingMessage();
      this.$emit('decrypt', this.reason);
    },
    showConfirmButtons() {
      this.decryptButton = false;
      this.confirmButtons = true;
      this.processingMessage = false;
    },
    showDecryptButton() {
      this.decryptButton = true;
      this.confirmButtons = false;
      this.processingMessage = false;
    },
    showProcessingMessage() {
      this.decryptButton = false;
      this.confirmButtons = false;
      this.processingMessage = true;
    },
  },
};
</script>

<style>

</style>
