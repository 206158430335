<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Scoring Elements</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <ScoringElementFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <ScoringElementCostModal
            v-model="showEffectiveDialogForm"
            :scoring-element="effectiveDateItem"
            @close-modal="closeCostModal()"
          />

          <v-btn
            v-if="hasPermission('create lending-strategies::scoring-elements')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Scoring Element
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Scoring Element"
            permission="create lending-strategies::scoring-elements"
            :x-small="false"
            button-class="mb-2"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.active_cost="{ item }">
      <v-chip
        label
        link
        @click="effectiveCostDates(item)"
      >
        {{ item.active_cost }}
      </v-chip>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('create lending-strategies::scoring-elements')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="edit lending-strategies::list"
        :x-small="false"
        button-class="mr-10"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import ScoringElementCostModal from '@/views/LendingStrategies/ScoringElement/ScoringElementCostEffectiveDates/ScoringElementCostModal.vue';
import ScoringElementFormModal from '@/views/LendingStrategies/ScoringElement/ScoringElementFormModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    ScoringElementFormModal,
    DisabledForLackOfRoleBtn,
    ScoringElementCostModal,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Provider', value: 'provider' },
      { text: 'Type', value: 'type' },
      { text: 'Class', value: 'class' },
      { text: 'Method', value: 'method' },
      { text: 'Description', value: 'description' },
      { text: 'Base Cost', value: 'cost' },
      { text: 'Effective Costs By Dates', value: 'active_cost' },
      { text: 'Auth Method', value: 'authMethod' },
      { text: 'Response', value: 'acceptable_response' },
      { text: 'Route Custom Inputs', value: 'route_custom_inputs' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],

    dialogForm: false,
    showEffectiveDialogForm: false,
    effectiveDateItem: {},
    editedItem: {
      id: null,
    },
  }),

  methods: {
    closeCostModal() {
      this.closeModal('showEffectiveDialogForm');
      this.updateTable();
    },

    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/scoring-element', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    effectiveCostDates(item) {
      this.showEffectiveDialogForm = true;
      this.effectiveDateItem = item;
    },
  },
};
</script>
