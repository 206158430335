<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    class="elevation-1"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Third Party Services</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />
        </v-toolbar>
      </v-card-title>
    </template>
    <template
      v-slot:item.status="{ item }"
      align-center
    >
      <v-chip :color="item.status == true ? 'success' : 'error'">
        {{ item['status'] }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex';
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Service', value: 'name' },
      { text: 'Status', value: 'status' },
    ],
    sendingSlackMessage: false,
  }),
  methods: {
    ...mapActions('notification', ['setNotification']),
    handleNotification(color, message) {
      this.setNotification({ message, color });
    },
    updateTable() {
      this.loading = true;
      this.$axios
        .get('/api/v1/third-party-statuses', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.items = res.data;
        })
        .catch((err) => {
          this.handleNotification('red', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
