<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(menu, index) in menus">
        <v-tab
          v-if="hasPermission(menu.permission)"
          :key="index"
          :to="{ name: menu.route }"
        >
          {{ menu.label }}
        </v-tab>
      </template>
    </v-tabs>
    <v-card class="mt-10 transparent">
      <router-view />
    </v-card>
  </v-container>
</template>

<script>
// import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    // DisabledForLackOfRoleBtn,
  },
  data() {
    return {
      menus: [
        {
          label: 'Lending Strategies',
          icon: 'mdi-account-group',
          route: 'lending-strategy',
          permission: 'view lending-strategies::list',
        },
        {
          label: 'Scoring Elements',
          icon: 'mdi-account-group',
          route: 'scoring-element',
          permission: 'view lending-strategies::scoring-elements',
        },
        {
          label: 'Scoring Models',
          icon: 'mdi-account-group',
          route: 'scoring-model',
          permission: 'view lending-strategies::scoring-models',
        },
        {
          label: 'Product Assignment',
          icon: 'mdi-account-group',
          route: 'product-assignment',
          permission: 'view lending-strategies::product-assignment',
        },
        {
          label: 'Bad ABA',
          icon: 'mdi-account-group',
          route: 'bad-aba',
          permission: 'view lending-strategies::bad-aba',
        },
        {
          label: 'Bad Employers',
          icon: 'mdi-account-group',
          route: 'bad-employer',
          permission: 'view lending-strategies::bad-employers',
        },
        {
          label: 'Blocked SSNs',
          icon: 'mdi-account-group',
          route: 'blocked-ssn',
          permission: 'view lending-strategies::blocked-ssns',
        },
        {
          label: 'Blocked States',
          icon: '',
          route: 'blocked-state',
          permission: 'view lending-strategies::blocked-state',
        },
      ],
    };
  },
};
</script>

<style></style>
