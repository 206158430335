<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <v-card-title> Statements Report </v-card-title>
      </div>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="fromDate"
                required
                label="From Date"
                type="date"
                :error-messages="fromDateErrors"
                @input="fromDateOnInputOrBlur"
                @blur="fromDateOnInputOrBlur"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="toDate"
                required
                label="To Date"
                type="date"
                :error-messages="toDateErrors"
                @input="toDateOnInputOrBlur"
                @blur="toDateOnInputOrBlur"
              />
            </v-col>
          </v-row>

          <v-btn
            class="mt-5 mb-5 btn-bg-deep-blue"
            :loading="reportGenerating"
            @click="generateReport()"
          >
            Generate Report
          </v-btn>
        </v-form>
      </v-container>
    </v-card>

    <template v-if="reportLoaded">
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
      >
        <template v-slot:item.period_start="{ item }">
          {{ formatDate(item.period_start) }}
        </template>

        <template v-slot:item.period_end="{ item }">
          {{ formatDate(item.period_end) }}
        </template>

        <template v-slot:item.change="{ item }">
          {{ formatAmount(item.change) }}
        </template>

        <template v-slot:item.adjust="{ item }">
          {{ formatAmount(item.adjust) }}
        </template>

        <template v-slot:item.next_payment_adjust="{ item }">
          {{ formatAmount(item.next_payment_adjust) }}
        </template>

        <template v-slot:item.autopay_amount="{ item }">
          {{ formatAmount(item.autopay_amount) }}
        </template>

        <template v-slot:item.viewed="{ item }">
          {{ item.logs.length > 0 ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.revised="{ item }">
          {{ item.revision ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            tile
            color="primary"
            small
            text
            @click="showJsonModal('JSON', item)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-eye
            </v-icon>
            SHOW
          </v-btn>

          <v-btn
            tile
            color="success"
            small
            text
            @click="downloadPDF(item)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-download
            </v-icon>
            PDF
          </v-btn>

          <v-btn
            v-if="item.adjust && !item.revision"
            tile
            color="primary"
            small
            text
            @click="confirmAdjustment(item)"
          >
            <v-icon
              dark
              left
              class="mr-1"
            >
              mdi-marker
            </v-icon>
            ADJUST
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <JsonModal
      v-model="jsonModal"
      :title="jsonTitle"
      :json="json"
      @close-modal="closeJsonModal()"
      @click:outside="closeJsonModal()"
    />
    <v-dialog
      v-if="selectedAdjustmentItem"
      v-model="confirmAdjustmentModal"
      max-width="500px"
      @click:outside="confirmAdjustmentModal = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Are you sure?</span>
        </v-card-title>

        <v-card-text>
          <p>
            Clicking confirm will apply the
            {{ formatAmount(selectedAdjustmentItem.adjust) }}
            adjust amount as interest and set the adjust to zero.
            The new interest amount will then be
            {{ formatAmount(
              selectedAdjustmentItem.interest_amount
                + selectedAdjustmentItem.adjust
            )
            }}.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="btn-bg-deep-blue"
            text
            @click="adjustStatement()"
          >
            Confirm
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="confirmAdjustmentModal = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';
import JsonModal from '@/components/JsonModal.vue';

const lessThanToDate = (value, vm) => (value <= vm.toDate);
const greaterThanFromDate = (value, vm) => (value >= vm.fromDate);

export default {
  name: 'StatementsReport',
  components: {
    JsonModal,
  },
  data() {
    return {
      confirmAdjustmentModal: false,
      formProcessing: false,
      fromDate: DateTime.local().toISODate(),
      toDate: DateTime.local().toISODate(),
      reportLoaded: false,
      reportGenerating: false,
      json: {},
      jsonTitle: '',
      jsonModal: false,
      headers: [
        { text: 'Loan ID', value: 'loan_id' },
        { text: 'Period Start', value: 'period_start' },
        { text: 'Period End', value: 'period_end' },
        { text: 'Change', value: 'change' },
        { text: 'Adjust', value: 'adjust' },
        { text: 'Next Payment Adjust', value: 'next_payment_adjust' },
        { text: 'Autopay', value: 'autopay_amount' },
        { text: 'Viewed By Customer', value: 'viewed' },
        { text: 'Revised', value: 'revised' },
        { text: '', value: 'actions' },
      ],
      items: [],
      selectedAdjustmentItem: null,
      notes: '',
    };
  },
  computed: {
    fromDateErrors() {
      const errors = [];

      if (!this.$v.fromDate.$dirty) {
        return errors;
      }
      if (!this.$v.fromDate.required) {
        errors.push('From Date is required.');
      }

      return errors;
    },
    toDateErrors() {
      const errors = [];

      if (!this.$v.toDate.$dirty) {
        return errors;
      }
      if (!this.$v.toDate.required) {
        errors.push('To Date is required.');
      }

      return errors;
    },
  },
  created() {
    const routeParams = this.$route.params;
    let generateReport = false;

    if (routeParams.fromDate) {
      this.fromDate = routeParams.fromDate;
      generateReport = true;
    }

    if (routeParams.toDate) {
      this.toDate = routeParams.toDate;
      generateReport = true;
    }

    if (generateReport) {
      this.generateReport();
    }
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    generateReport() {
      const vm = this;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reportLoaded = false;
        this.reportGenerating = true;

        this.$axios
          .get('api/v1/reports/statements/', {
            params: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          })
          .then((res) => {
            vm.items = Object.values(res.data);
            vm.reportLoaded = true;
          })
          .then(() => {
            vm.reportGenerating = false;
          });
      }
    },
    resetDateValidators() {
      this.$v.fromDate.$reset();
      this.$v.toDate.$reset();
    },
    touchDateValidators() {
      this.$v.fromDate.$touch();
      this.$v.toDate.$touch();
    },
    fromDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.fromDateErrors.push('From Date cannot be after To Date.');
      }
    },
    toDateOnInputOrBlur() {
      this.reportLoaded = false;
      this.resetDateValidators();
      this.touchDateValidators();

      if (this.fromDate > this.toDate) {
        this.toDateErrors.push('To Date cannot be before From Date.');
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_MED);
    },
    formatAmount(number) {
      const amount = parseFloat(number).toFixed(2);

      if (amount === '0.00') {
        return '-';
      }

      if (amount < 0) {
        return `-$${Math.abs(amount)}`;
      }

      return `$${amount}`;
    },
    closeJsonModal() {
      this.jsonModal = false;
      this.$nextTick(() => {
        this.json = {};
      });
    },
    showJsonModal(title, json) {
      this.json = json;
      this.jsonTitle = title;
      this.jsonModal = true;
    },
    downloadPDF(statement) {
      this.$axios
        .get(`api/v1/reports/statements/${statement.id}`, { responseType: 'blob' })
        .then((res) => {
          const date = DateTime.fromISO(statement.period_end).toUTC().toFormat('yyyy-MM-dd');
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `statement-${statement.loan_id}-${statement.id}-${date}.pdf`;
          link.click();
          link.remove();

          this.setNotification({
            message: 'Statement pdf downloaded',
            color: 'green',
          });
        })
        .catch(async (error) => {
          const responseObj = JSON.parse(await error.response.data.text());

          this.setNotification({
            message: `PDF could not be retrieved. If error persist please contact Dev Department. Details: ${responseObj.error}`,
            color: 'red',
          });
        });
    },
    confirmAdjustment(statement) {
      this.selectedAdjustmentItem = statement;
      this.confirmAdjustmentModal = true;
    },
    adjustStatement() {
      const statement = this.selectedAdjustmentItem;
      this.$axios
        .post(`api/v1/reports/statements/${statement.id}/adjust`)
        .then(() => {
          this.confirmAdjustmentModal = false;
          this.generateReport();
        });
    },
  },
  validations: {
    fromDate: { required, lessThanToDate },
    toDate: { required, greaterThanFromDate },
  },
};
</script>

<style></style>
