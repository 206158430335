<template>
  <v-alert
    :border="border"
    :color="alertColor"
    dark
    class="remove-class"
  >
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'OpsTicketAlertMessage',
  props: {
    border: {
      type: String,
      required: false,
      default: 'top',
    },
    color: {
      type: String,
      required: false,
      default: 'red',
    },
  },

  computed: {
    alertColor() {
      return `${this.color} lighten-2`;
    },
  },
};
</script>

<style scoped></style>
