<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Add Payment Method
        </v-card-title>
        <v-card-text>
          <div>
            <v-row
              cols="12"
            >
              <v-col
                cols="6"
              >
                <v-subheader>Available Payment Methods</v-subheader>
              </v-col>
              <v-col
                cols="6"
              >
                <div
                  class="float-right"
                >
                  <ticket-add-debit-card-dialog
                    :ticket="ticket"
                    @success="handleSuccess($event)"
                  />
                  <ticket-add-checking-dialog
                    :ticket="ticket"
                    @success="handleSuccess($event)"
                  />
                  <ticket-successful-action
                    :ticket-successful-action-dialog="ticketSuccessfulActionDialog"
                    :ticket-id="$route.params.id"
                    :title="'Payment Profile Added: ' + ticketSuccessfulActionId"
                    @closed="ticketSuccessfulActionDialog = false"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <ConfirmPaymentProfileDeleteModal
            v-model="confirmDeleteDialog"
            :loading="confirmDeleteLoading"
            :payment-profile="paymentProfileToDelete"
            @confirm-delete-payment-profile="deleteProfile()"
          />
          <v-dialog
            v-model="notAllowedModal"
            width="500"
            @close.stop="notAllowedModal = false"
          >
            <v-card>
              <v-card-text
                class="pt-9 pb-7 ml-3"
              >
                <p>You cannot delete the primary payment profile.</p>
              </v-card-text>
              <v-card-actions
                class="pt-5"
              >
                <div class="row">
                  <div class="col-6 offset-6 text-right pr-6">
                    <v-btn
                      text
                      width="25%"
                      class="mt-n14"
                      @click.stop="notAllowedModal = false"
                    >
                      ok
                    </v-btn>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer />
          <v-data-table
            :headers="headers"
            :items="dataTableItems"
            :loading="loading"
            :no-results-text="noResultsText"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="item.primary !== 'Yes' && hasPermission('edit ticket::add-payment')"
                class="red--text"
                @click.stop="confirmDelete(item)"
              >
                mdi-delete
              </v-icon>
              <disabled-for-lack-of-role-btn
                v-else-if="item.primary !== 'Yes'"
                text=""
                icon="mdi-delete"
                :text-button="true"
                permission="edit ticket::add-payment"
                :x-small="false"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import TicketAddDebitCardDialog from './AddPaymentDialogs/TicketAddDebitCardDialog.vue';
import TicketAddCheckingDialog from './AddPaymentDialogs/TicketAddCheckingDialog.vue';
import TicketSuccessfulAction from './TicketSuccessfulAction.vue';
import ConfirmPaymentProfileDeleteModal from './AddPaymentDialogs/ConfirmPaymentProfileDeleteModal.vue';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketAddPayment',
  components: {
    TicketSuccessfulAction,
    TicketAddDebitCardDialog,
    TicketAddCheckingDialog,
    ConfirmPaymentProfileDeleteModal,
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    paymentProfiles: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      notAllowedModal: false,
      confirmDeleteLoading: false,
      paymentProfileToDelete: { },
      confirmDeleteDialog: false,
      loading: false,
      ticketSuccessfulActionDialog: false,
      ticketSuccessfulActionId: '',
      noResultsText: 'This Customer does not have any Payment Methods associated with their Account.',
      headers: [
        {
          text: 'Title',
          value: 'title',
          align: 'center',
        },
        {
          text: 'Type',
          value: 'type',
          align: 'center',
        },
        {
          text: 'Primary',
          value: 'primary',
          align: 'center',
        },
        {
          text: 'Secondary',
          value: 'secondary',
          align: 'center',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    dataTableItems() {
      return this.paymentProfiles
        .map((profile) => {
          const returnObject = {
            id: profile.id,
            title: profile.title,
            primary: profile.is_primary ? 'Yes' : 'No',
            secondary: profile.is_secondary ? 'Yes' : 'No',
            type: profile.checking_account
              ? `${profile.checking_account.account_type.substr(14)} account`
              : 'debit card',
          };
          return returnObject;
        });
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProCustomer', ['getPaymentProfiles']),
    handleSuccess($event) {
      // this.getPaymentProfiles();
      this.ticketSuccessfulActionId = $event;
      this.ticketSuccessfulActionDialog = true;
    },
    confirmDelete(item) {
      if (item.primary === 'Yes') {
        this.notAllowedModal = true;
        return;
      }
      this.paymentProfileToDelete = item;
      this.confirmDeleteDialog = true;
    },
    async deleteProfile() {
      this.confirmDeleteLoading = true;

      // delete using this.ticket.lpCustomer.id and paymentProfileToDelete.id
      try {
        await this.$axios
          .delete(`/api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment-profiles/${this.paymentProfileToDelete.id}`);

        this.paymentProfileToDelete = { };
        this.confirmDeleteDialog = false;
        this.confirmDeleteLoading = false;

        this.setNotification({
          message: 'LoanPro Customer Payment Profile has been deleted.',
          color: 'green',
        });

        await this.getPaymentProfiles();
      } catch (error) {
        if (error.response.data.message) {
          this.setNotification({
            message: error.response.data.message,
            color: 'red',
          });
        } else {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        }
        this.confirmDeleteLoading = false;
      }
    },
  },
};
</script>
