<template>
  <v-container fluid>
    <v-tabs>
      <template v-for="(menu, index) in menus">
        <v-tab
          v-if="hasPermission(menu.permission)"
          :key="index"
          :to="{ name: menu.route }"
        >
          <template
            v-if="menu.badge && menu.count"
          >
            <v-badge
              color="green"
              :content="menu.count"
              offset-x="-5"
              offset-y="10"
            >
              {{ menu.label }}
            </v-badge>
          </template>
          <template v-else>
            {{ menu.label }}
          </template>
        </v-tab>
      </template>
    </v-tabs>
    <v-card class="mt-10 transparent">
      <router-view />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeveloperTools',
  data() {
    return {
      menus: [
        {
          label: 'Queue',
          route: 'lead-queue',
          permission: 'view queue',
          badge: true,
          count: 0,
        },
        {
          label: 'Performance',
          route: 'lead-journey-methods',
          permission: 'view performance::lead-journey-api-calls',
          badge: false,
          count: 0,
        },
        {
          label: 'Amortization',
          route: 'lead-amortizations',
          permission: 'view lead::amortization',
          badge: false,
          count: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('sidebar', ['queueCount']),
    queueMenu() {
      return this.menus.find((menu) => menu.label === 'Queue');
    },
  },
  watch: {
    queueCount(value) {
      if (this.queueMenu) {
        this.queueMenu.count = value;
        this.$forceUpdate();
      }
    },
  },
};
</script>
