<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title
          v-if="forcePaymentAmount === null"
        >
          Make Payment And Payoff
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="showPendingPaymentNotice">
              <v-card
                class="pa-5"
                color="#FB8C00"
              >
                <div class="pending-payment-note">
                  <b>NOTE: </b> Customer has a pending payment.
                </div>
              </v-card>
            </v-col>
            <v-col>
              <div
                class="float-right"
              >
                <ticket-add-debit-card-dialog
                  :ticket="ticket"
                  @success="handleSuccess($event)"
                />
                <ticket-add-checking-dialog
                  :ticket="ticket"
                  @success="handleSuccess($event)"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              class="mb-0"
            >
              <v-select
                v-model="paymentProfile"
                :items="paymentProfiles"
                item-value="id"
                item-text="title"
                label="Payment Method"
                outlined
                required
                :class="{ 'is-invalid': $v.paymentProfile.$error }"
                :error-messages="paymentProfileErrors"
                @change="getLoanPayoff()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="mb-0"
            >
              <v-text-field
                v-model="paymentDate"
                label="Payment Date"
                type="date"
                :class="{ 'is-invalid': $v.paymentDate.$error }"
                :error-messages="paymentDateErrors"
                outlined
                required
                @input="$v.paymentDate.$touch()"
                @blur="$v.paymentDate.$touch()"
                @change="getLoanPayoff()"
              />
              <v-alert
                v-if="!isPaymentDateValid"
                prominent
                dense
                dark
                text
                border="left"
                color="error"
                icon="mdi-account-voice"
              >
                <blockquote class="blockquote">
                  The next available apply date for this
                  <span v-if="isDebitCard">Debit Card</span>
                  <span v-else>ACH account</span>
                  is {{ nextValidApplyDate }}
                </blockquote>
              </v-alert>
              <v-alert
                v-else-if="paymentProfile && isSameDayPayment"
                prominent
                dense
                dark
                text
                border="left"
                color="primary"
                icon="mdi-account-voice"
              >
                <blockquote class="blockquote">
                  This Payment will be applied immediately
                  <span
                    v-if="!isDebitCard && isSameDayAchEnabled"
                  >
                    but it will not be processed until the next business day,
                    because it is an ACH payment method.
                  </span>
                  <span
                    v-if="isDebitCard"
                  >
                    as long as it processes successfully.
                  </span>
                </blockquote>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="mb-0"
            >
              <v-select
                v-model="paymentType"
                :items="paymentTypes"
                item-value="id"
                item-text="title"
                label="Payment Type"
                outlined
                required
                :readonly="(forcePaymentType) ? true : false"
                :class="{ 'is-invalid': $v.paymentType.$error }"
                :error-messages="paymentTypeErrors"
                @change="getLoanPayoff()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="mb-0"
            >
              <v-text-field
                v-model="paymentAmount"
                prepend-inner-icon="mdi-currency-usd"
                label="Payment Amount"
                outlined
                required
                type="number"
                :disabled="paymentType === 13"
                :class="{ 'is-invalid': $v.paymentAmount.$error }"
                :error-messages="paymentAmountErrors"
                @input="$emit('differentAmount', $event)"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingModel"
            :loading="loadingModel"
            @click="validatePaymentAmount()"
          >
            Make Payment
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingModel"
            :to="{ name: 'ticket-show', params: { id: $route.params.id } }"
          >
            Cancel
          </v-btn>
        </v-card-actions>

        <v-dialog
          v-model="confirmModal"
          max-width="500px"
          @click:outside="confirmModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Are you sure?</span>
            </v-card-title>

            <v-card-text>
              Clicking confirm will schedule the following payment:
              <v-list dense>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Method</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentProfile ?
                        paymentProfiles.find(p => p.id === paymentProfile).title : ''
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Date</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentDate }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Type</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentType ?
                        paymentTypes.find(p => p.id === paymentType).title : ''
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Amount</v-list-item-title>
                    <v-list-item-subtitle>${{ paymentAmount }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                v-if="hasPermission('create ticket::make-payment')"
                color="blue darken-1"
                text
                :disabled="loadingModel"
                @click="makePayment()"
              >
                Confirm
              </v-btn>
              <disabled-for-lack-of-role-btn
                v-else
                text="Confirm"
                permission="create ticket::make-payment"
                :x-small="false"
                :text-button="true"
              />

              <v-btn
                color="blue darken-1"
                text
                @click="confirmModal = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="paymentModal"
          max-width="500px"
          @click:outside="paymentModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Payment Created</span>
            </v-card-title>

            <v-card-text>
              <v-list dense>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Autopay ID</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentData.id }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Method</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentData.payment_account }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Date</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentData.date }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Type</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ paymentData.payment_type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  class="pl-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Amount</v-list-item-title>
                    <v-list-item-subtitle>
                      ${{ paymentData.amount }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <p>Would you like to perform another task or mark this ticket as solved?</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                color="blue darken-1"
                text
                @click="anotherTask()"
              >
                Another Task
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="resolveTicket()"
              >
                Resolve Ticket
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon-business-days';
import { mapActions, mapState } from 'vuex';
import { required, between } from 'vuelidate/lib/validators';
import TicketAddDebitCardDialog from './AddPaymentDialogs/TicketAddDebitCardDialog.vue';
import TicketAddCheckingDialog from './AddPaymentDialogs/TicketAddCheckingDialog.vue';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketMakePayment',
  components: {
    TicketAddCheckingDialog,
    TicketAddDebitCardDialog,
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    paymentProfiles: {
      type: Array,
      require: true,
    },
    forcePaymentAmount: {
      type: Number,
      default: null,
    },
    forcePaymentType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      paymentModal: false,
      paymentData: {},
      confirmModal: false,
      loadingModel: false,
      today: DateTime.local().toISODate(),
      paymentAmount: 0,
      paymentProfile: null,
      paymentDate: DateTime.local().toISODate(),
      paymentType: null,
      payOffAmount: 0, // set to today payoff by default
      paymentTypes: [
        { id: 13, title: 'Payoff' },
        { id: 8, title: 'Regular' },
        { id: 2, title: 'Principal' },
      ],
    };
  },
  validations() {
    return {
      paymentAmount: { required, between: between(10, this.payOffAmount) },
      paymentType: { required },
      paymentProfile: { required },
      paymentDate: { required },
    };
  },
  computed: {
    ...mapState('settings', ['settings']),
    ...mapState('loanProLoan', {
      loanAutopays: (state) => state.autopays,
    }),
    isSameDayPayment() {
      return this.today === this.paymentDate;
    },
    isSameDayAchEnabled() {
      return this.settings?.brand?.enable_same_day_ach || false;
    },
    isDebitCard() {
      return ((this.paymentProfiles || []).find((x) => x.id === this.paymentProfile)?.type || '') === 'paymentAccount.type.credit';
    },
    nextValidApplyDate() {
      // if payment method is a debit card or same day ACH is enabled, return today
      if (this.isDebitCard || this.isSameDayAchEnabled) {
        return DateTime.local().toISODate();
      }

      const now = DateTime.now().setZone('America/Chicago').toObject();
      const hours = now.hour;
      const daysToIncrement = hours >= 15 ? 2 : 1;

      // else return the next biz day
      return DateTime.local().plusBusiness({ days: daysToIncrement }).toISODate();
    },
    isPaymentDateValid() {
      // prevent from being invalid until a date and profile are selected
      if (!this.paymentDate || !this.paymentProfile) {
        return true;
      }

      return this.paymentDate >= this.nextValidApplyDate;
    },
    paymentAmountErrors() {
      const errors = [];

      if (!this.$v.paymentAmount.$dirty) {
        return errors;
      }
      if (!this.$v.paymentAmount.required) {
        errors.push('Payment Amount is required.');
      }
      if (!this.$v.paymentAmount.between) {
        errors.push(`Payment Amount must be between ${this.$v.paymentAmount.$params.between.min} and ${this.$v.paymentAmount.$params.between.max}.`);
      }

      return errors;
    },
    paymentDateErrors() {
      const errors = [];

      if (!this.$v.paymentDate.$dirty) {
        return errors;
      }
      if (!this.$v.paymentDate.required) {
        errors.push('Payment Date is required.');
      }
      return errors;
    },
    paymentTypeErrors() {
      const errors = [];

      if (!this.$v.paymentType.$dirty) {
        return errors;
      }
      if (!this.$v.paymentType.required) {
        errors.push('Payment Type is required.');
      }
      return errors;
    },
    paymentProfileErrors() {
      const errors = [];

      if (!this.$v.paymentProfile.$dirty) {
        return errors;
      }
      if (!this.$v.paymentProfile.required) {
        errors.push('Payment method is required.');
      }
      return errors;
    },
    loanType() {
      return this.ticket?.active_loan?.loan_setup.loan_type || null;
    },
    isPastDue() {
      if (this.loanType === 'loan.type.creditLimit') {
        if (!this.ticket.active_loan.latest_statement) {
          return false;
        }
        return this.ticket.active_loan.latest_statement.past_due_amount > 0;
      }
      return this.ticket.active_loan.current_status.amount_due > 0;
    },
    paymentTypeComputed() {
      let result = [
        { id: 18, title: 'Settlement' },
        { id: 2, title: 'Principal' },
        { id: 8, title: 'IPF' },
      ];

      const settlementStatuses = [
        'Portal 3 - Pay In Full',
        'Portal 2 - Full Settlement',
        'Portal 1 - Partial Settlement',
        'Partial Settlement Completed',
        'Portal 3 - Final Payment Pending',
        'Debt Consolidation',
        'Non-Accrual',
        'Settled in Full',
        'Final Payment Pending',
        'Payment Plan',
      ];

      // eslint-disable-next-line max-len
      if (settlementStatuses.includes(this.ticket.active_loan.current_status.loan_sub_status_text)) {
        result = result.find((rs) => ['settlement'].includes(rs.title.toLowerCase())).id;
      } else if (this.isPastDue) {
        result = result.find((rs) => ['ipf'].includes(rs.title.toLowerCase())).id;
      } else if (this.paymentType === 13 || this.paymentType === 8 || this.paymentType === 2) {
        result = this.paymentType;
      }

      return result;
    },
    showPendingPaymentNotice() {
      const tz = 'America/Chicago';
      const now = DateTime.now().setZone(tz);
      const tomorrow = DateTime.now().setZone(tz).plus({ days: 1 }).startOf('day');

      const tomorowAutopay = this.loanAutopays.some((payment) => {
        if (!payment.apply_date) {
          return false;
        }
        const date = DateTime.fromISO(payment.apply_date, { zone: tz });
        return date.hasSame(tomorrow, 'day') && now.hour >= 15;
      });

      return Number(this.paymentType) === 13 && tomorowAutopay;
    },
  },
  watch: {
    ticket() {
      this.getLoanPayoff();
    },
    isSameDayPayment(value) {
      this.$emit('immediatePayment', value);
    },
  },
  created() {
    if (this.forcePaymentType) {
      this.paymentType = this.forcePaymentType;
      this.paymentAmount = this.forcePaymentAmount;
      this.getLoanPayoff();
    }
  },
  methods: {
    ...mapActions('loanProLoan', ['getAutopays']),
    ...mapActions('notification', ['setNotification']),
    getLoanPayoff() {
      if (!this.paymentDate) {
        return null;
      }
      this.loadingModel = true;

      return this.$axios
        .get(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment/payoff-amount?loanId=${this.ticket.active_loan.id}&date=${this.paymentDate}`)
        .then((res) => {
          this.loadingModel = false;
          this.payOffAmount = res.data.d[this.paymentDate].payoff;
          if (this.paymentType === 13) {
            this.paymentAmount = res.data.d[this.paymentDate].payoff;
          }
        });
    },

    forcePaymentAmountValidation() {
      if (this.paymentAmount * 1 < this.forcePaymentAmount) {
        const message = `Payment Amount can NOT be less than ${this.forcePaymentAmount}`;
        this.setNotification({
          message,
          color: 'red',
        });
        return true;
      }
      if (this.paymentProfile === null) {
        const message = 'Please select a payment method.';
        this.setNotification({
          message,
          color: 'red',
        });
        return true;
      }
      this.$emit('loader');
      return false;
    },

    generateRequestObj() {
      this.confirmModal = false;
      this.loadingModel = true;
      return {
        ticketId: this.$route.params.id,
        paymentDate: this.paymentDate,
        paymentAmount: this.paymentAmount,
        paymentTypeId: this.paymentTypeComputed,
        paymentAccount: this.paymentProfile,
      };
    },

    // eslint-disable-next-line consistent-return
    async makePayment() {
      this.loadingModel = true;
      if (this.forcePaymentAmount !== null) {
        const invalid = this.forcePaymentAmountValidation();
        if (invalid) {
          this.loadingModel = false;
          return;
        }
      }
      const requestObject = this.generateRequestObj();

      // eslint-disable-next-line consistent-return
      await this.$axios
        .post(`api/v1/loanpro-customer/${this.ticket.lpCustomer.id}/payment/${this.ticket.active_loan.id}/${this.paymentProfile}/make`, { ...requestObject })
        .then((res) => {
          if (this.forcePaymentAmount !== null) {
            this.$set(requestObject, 'paymentProfile', this.paymentProfile);
            this.$set(requestObject, 'paymentId', res.data.id);
            this.$emit('success', requestObject);
            this.loadingModel = false;
          } else {
            this.loadingModel = false;
            this.paymentData = res.data;
            this.paymentModal = true;
            this.setNotification({
              message: 'The payment was scheduled successfully.',
              color: 'green',
            });
          }
          this.getAutopays();
        })
        .catch((err) => {
          this.loadingModel = false;
          if (this.forcePaymentAmount !== null) {
            this.$emit('error');
          }
          let message = 'We were unable to schedule/make this payment. Please try again. If error continues, please contact your supervisor';
          if (err.response.data.errors) {
            // eslint-disable-next-line prefer-destructuring
            message = err.response.data.errors[Object.keys(err.response.data.errors)[0]][0];
          } else if (err.response.data.message) {
            message = err.response.data.message;
          }
          this.setNotification({
            message,
            color: 'red',
          });
        });
    },

    anotherTask() {
      this.paymentModal = false;
      this.$router.push({
        name: 'ticket-show',
        params: {
          id: this.$route.params.id,
        },
      });
    },

    resolveTicket() {
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.loadingModel = false;

          this.$router.push({
            name: 'tickets',
          });
        });
    },

    validatePaymentAmount() {
      if (this.payOffAmount === 0) {
        this.setNotification({
          message: 'We dont think payment can be made. payoff amount is $0.0',
          color: 'red',
        });
        return;
      }

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.confirmModal = true;
      }
    },
  },
};
</script>

<style scoped>
.pending-payment-note {
  color: white;
  font-size: 1.3rem;
}
</style>
