<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    @click:outside="dialog = !dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        v-bind="attrs"
        v-on="on"
      >
        Loans
      </v-btn>
    </template>
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">
          {{ `${editedItem.first_name} ${editedItem.last_name}` }}'s Loans
        </span>
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>Account</th>
              <th>Days Past Due</th>
              <th>Amount Past Due</th>
              <th>Principal Balance</th>
              <th>Loan Status</th>
              <th>Loan Sub Status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="loading"
            >
              <tr>
                <td
                  class="py-2 text-center"
                  colspan="5"
                >
                  <p>Loading Loans...</p>
                  <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  />
                </td>
              </tr>
            </template>
            <template
              v-else
            >
              <template
                v-if="loans.length > 0"
              >
                <tr
                  v-for="(loan, index) in loans"
                  :key="index"
                >
                  <td>{{ loan.title }}</td>
                  <td>{{ loan.current_status.days_past_due }}</td>
                  <td>{{ formatCurrency(loan.current_status.amount_due) }}</td>
                  <td>{{ formatCurrency(loan.current_status.principal_balance) }}</td>
                  <td>{{ loan.current_status.loan_status_text }}</td>
                  <td>{{ loan.current_status.loan_sub_status_text }}</td>
                  <td
                    class="py-2"
                    style="width: 30%"
                  >
                    <LoanProCustomersLoansConfirmBankruptcyButtons
                      v-if="loan.current_status.loan_status_text != 'Bankruptcy'"
                      :key="loan.id"
                      :loan-id="loan.id"
                      :form-processing="formProcessing"
                      @declare-bankruptcy="confirmBankruptcy($event, index)"
                    />
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td
                  class="py-2 text-center"
                  colspan="5"
                >
                  No Loans Found.
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="dialog = !dialog"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

import LoanProCustomersLoansConfirmBankruptcyButtons from '@/views/LoanProCustomers/CustomerProfile/LoanProCustomersLoansConfirmBankruptcyButtons.vue';

export default {
  name: 'LoanProCustomersLoansModal',

  components: {
    LoanProCustomersLoansConfirmBankruptcyButtons,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      id: null,
      loans: [],
      formProcessing: false,
      loading: false,
      dialogconfirmBankruptcy: false,
      confirmBankruptcyButtons: false,
      dialog: false,
    };
  },

  watch: {
    editedItem() {
      this.id = this.editedItem.id;
    },
  },
  mounted() {
    this.getLoans();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async getLoans() {
      this.loading = true;

      const loans = await this.$axios
        .get(`api/v1/loanpro-customer/${this.$route.params.customer_id}/loans`);

      this.loans = [...loans.data.data];
      this.loading = false;
    },

    async confirmBankruptcy(loanId) {
      this.formProcessing = true;

      try {
        await this.$axios
          .post(`/api/v1/loanpro-customer/${this.$route.params.customer_id}/bankruptcy/${loanId}`);

        this.setNotification({
          message: 'LoanPro customer loan has been set for bankruptcy.',
          color: 'green',
        });

        this.getLoans();
      } catch (error) {
        this.setNotification({
          message: 'There was an error processing your request.',
          color: 'red',
        });
      }

      this.formProcessing = false;
      this.dialog = false;
    },

    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(value);
    },
  },
};
</script>
