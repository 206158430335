<template>
  <div class="content-holder p-s">
    <v-row>
      <v-col
        cols="12"
      >
        <v-btn
          class="float-right mt-n10"
          @click="$emit('back-ticket-settlement')"
        >
          back
        </v-btn>
      </v-col>
    </v-row>
    <h2 class="mb-2 mt-n4">
      Make a Settlement Offer
    </h2>

    <h2 class="mb-2 red--text">
      Your current loan balance is {{ todayPayoffBalance | currency }}
    </h2>
    <p v-if="isCovidCollection">
      If you are unable to pay your loan balance in full,
      we will consider settling for less than you owe.
    </p>
    <p v-else>
      If you are unwilling to pay your loan balance in full,
      we will consider settling for less than you owe. Use the
      form below to view your options.
    </p>

    <div class="settlement-option mb-s mb-3">
      <h2 class="title">
        SELECT PAYMENT TERMS
      </h2>
      <p class="mt--xxs">
        Select how you want to resolve your debt.
      </p>

      <div class="row">
        <!--
          // TODO: FIX MOBILE CLICK CHANGING BUTTON COLOR ISSUE,
          ON MOBILE THE COLOR WHON'T CHANGE UNTIL FOCUS CHANGE
        -->
        <div class="col-sm-6 mt-xs">
          <v-btn
            class="p-xs"
            button-full
            size="large"
            width="100%"
            :color="isPaymentPlan ? 'settlement' : 'settlement-inverse'"
            @click="clickPaymentPlan"
          >
            PAYMENT PLAN
          </v-btn>
        </div>

        <div class="col-sm-6 mt-xs">
          <v-btn
            class="p-xs"
            button-full
            size="large"
            width="100%"
            :color="isSinglePayment ? 'settlement' : 'settlement-inverse'"
            @click="clickSinglePayment"
          >
            SINGLE PAYMENT
          </v-btn>
        </div>
      </div>
    </div>

    <keep-alive>
      <PaymentPlan
        v-if="isPaymentPlan"
        :payment-accounts="paymentAccounts"
        :ticket="ticket"
        :refresh-key="refreshKey"
        @form-submit="onFormSubmit"
        @reload-payment-profiles="$emit('reload-payment-profiles')"
      />
      <SinglePayment
        v-if="isSinglePayment"
        :payment-accounts="paymentAccounts"
        :ticket="ticket"
        :refresh-key="refreshKey"
        @form-submit="onFormSubmit"
        @reload-payment-profiles="$emit('reload-payment-profiles')"
      />
    </keep-alive>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import PaymentPlan from './PaymentPlan.vue';
import SinglePayment from './SinglePayment.vue';

export default {
  name: 'SettlmentMakeOffer',

  components: {
    PaymentPlan,
    SinglePayment,
  },

  props: {
    todayPayoffBalance: {
      type: Number,
      required: true,
    },
    isCovidCollection: {
      type: Boolean,
      required: false,
    },
    paymentAccounts: {
      type: Array,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedResolutionType: null,
      resolutionTypes: {
        PAYMENT_PLAN: 'PAYMENT PLAN',
        SINGLE_PAYMENT: 'SINGLE PAYMENT',
      },
      offerData: {
        settlementAmount: 0,
        paymentAmount: 0,
        paymentFrequency: null,
        firstPaymentDate: null,
      },
      submitted: false,
      refreshKey: 0,
    };
  },

  computed: {
    isPaymentPlan() {
      return this.selectedResolutionType === this.resolutionTypes.PAYMENT_PLAN;
    },

    isSinglePayment() {
      return this.selectedResolutionType === this.resolutionTypes.SINGLE_PAYMENT;
    },
  },

  methods: {
    ...mapActions('settlement', ['disclosuresNotSent', 'paymentSchedulesNotSent']),

    onFormSubmit(formData) {
      const offer = {
        ...formData,
        isSinglePayment: this.isSinglePayment,
      };
      this.$emit('settlement-offer', offer);
    },
    clickPaymentPlan() {
      this.selectedResolutionType = this.resolutionTypes.PAYMENT_PLAN;
      this.disclosuresNotSent();
      this.paymentSchedulesNotSent();
      this.refreshKey += 1;
    },
    clickSinglePayment() {
      this.selectedResolutionType = this.resolutionTypes.SINGLE_PAYMENT;
      this.disclosuresNotSent();
      this.paymentSchedulesNotSent();
      this.refreshKey += 1;
    },
  },

  // TODO: centralize payment form validation
  validations: {
    offerData: {
      settlementAmount: {
        required,
        positiveNonZero: (v) => v > 0,
      },
      paymentAmount: {
        required,
        positiveNonZero: (v) => v > 0,
      },
      paymentFrequency: {
        required,
      },
      firstPaymentDate: {
        required,
      },
    },
  },
};
</script>
