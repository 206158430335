<template>
  <v-dialog
    v-model="show"
    max-width="650px"
    @click:outside="$emit('click:outside')"
  >
    <v-card :loading="dialog2faProcessing">
      <v-card-title
        v-if="editedItem.mfa"
        class="headline"
      >
        Are you sure you want to reset {{ editedItem.first_name }}'s Two Factor Authentication?
      </v-card-title>
      <v-card-title
        v-else
        class="headline"
      >
        {{ editedItem.first_name }} does not have Two Factor Authentication enabled.
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :disabled="dialog2faProcessing"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="editedItem.mfa"
          color="red"
          text
          :disabled="dialog2faProcessing"
          @click="deleteItemConfirm"
        >
          OK
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UserDeleteModal',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          first_name: '',
        };
      },
    },

  },

  data() {
    return {
      dialog2faProcessing: false,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    close() {
      this.$emit('close-modal');
    },

    deleteItemConfirm() {
      this.dialog2faProcessing = true;

      this.$axios
        .post(`api/v1/user/${this.editedItem.id}/reset-2fa`)
        .then(() => {
          this.$emit('item-saved');

          this.setNotification({
            message: 'Two factor authentication has been reset.',
            color: 'green',
          });
        })

        .catch(() => {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        })

        .then(() => {
          this.dialog2faProcessing = false;

          this.close();
        });
    },
  },
};
</script>

<style scoped>

</style>
