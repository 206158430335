<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      class="fraud-claim"
      width="1000px"
      scrollable
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasPermission('create ticket::change-primary-email')"
          v-bind="attrs"
          color="primary"
          x-large
          block
          class="fraud-claim-dialog-btn"
          v-on="on"
        >
          New Primary Email
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          text="New Primary Email"
          permission="create ticket::change-primary-email"
          :x-small="false"
          :x-large="true"
          :block="true"
          button-class="fraud-claim-dialog-btn"
        />
      </template>

      <v-card
        v-if="starterQuestion"
        :loading="loadingModel"
      >
        <v-card-title class="text-h5 grey lighten-2">
          New Primary Email
        </v-card-title>
        <v-card-text class="py-5">
          <v-row
            class="justify-center"
          >
            <v-col
              class="justify-center"
            >
              <v-alert
                prominent
                dense
                dark
                text
                border="left"
                color="primary"
                icon="mdi-account-voice"
              >
                <blockquote class="blockquote">
                  "Are you requesting this change PRIOR to reapplying for more funding
                  OR signing NEW loan docs?"
                </blockquote>
              </v-alert>
              <span
                class="text-caption"
              >
                * If so, this request will be <b>DENIED</b> until <b>AFTER</b> the new
                loan documents are signed; this includes any manual loan docs sent
                out of LP.
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />
          <v-btn
            color="primary"
            :loading="loadingModel"
            @click="starterQuestion = false"
          >
            No
          </v-btn>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-else
        :loading="loadingModel"
      >
        <v-card-title class="text-h5 grey lighten-2">
          New Primary Email
        </v-card-title>

        <v-card-text class="py-5">
          <v-row
            class="justify-center"
          >
            <v-col
              class="justify-center"
            >
              <p>
                {{ reverse ? 'Reverse to this Email Address' : 'Current Email Address' }}
              </p>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customer.email"
                label="Current Email Address"
                outlined
                placeholder="Email"
                disabled
              />
            </v-col>
          </v-row>
          <v-row
            class="justify-center"
          >
            <v-col
              class="justify-center"
            >
              <p>
                {{ reverse ? 'Newly Update to this Email Address' : 'New Email Address' }}
              </p>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newEmail"
                label="New Email Address"
                outlined
                placeholder="Email"
                :disabled="updateSuccess !== null && !updateSuccess"
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(destination, index) in destinations"
            :key="'destination-' + index"
            class="justify-center"
          >
            <v-col
              class="justify-center"
            >
              <p>
                Change to primary email address in {{ destination.name }}?
                <br>
                <span
                  class="text-caption"
                >
                  * {{ destination.details }}
                </span>
              </p>
            </v-col>
            <v-col>
              <div
                v-if="reverse && (destination.update && destination.reversed === null)"
              >
                <span
                  class="success--text"
                >
                  This Platform was never updated, therefore it does not need to be reversed.
                </span>
              </div>
              <v-radio-group
                v-else
                v-model="destinations[index].update"
                row
                disabled
              >
                <v-radio
                  :value="true"
                  :color="(destination.update ? 'success' : 'error')"
                >
                  <template v-slot:label>
                    <strong
                      :class="(destination.errorMessage ? 'error--text':
                        (destination.update ? 'success--text' : ''))"
                    >
                      {{
                        destination.errorMessage && reverse ? 'Try to Reverse again'
                        : (destination.errorMessage && !reverse ? 'Try to Update again'
                          : (reverse ? 'Reverse' : 'Update'))
                      }}
                    </strong>
                  </template>
                </v-radio>
                <v-radio
                  :value="false"
                  color="success"
                >
                  <template v-slot:label>
                    <strong
                      :class="(!destination.update ? 'success--text' : '')"
                    >
                      {{ reverse ? 'Reversed' : 'Updated' }}
                    </strong>
                  </template>
                </v-radio>
              </v-radio-group>
              <div
                v-if="destination.errorMessage"
              >
                <span class="error--text">{{ destination.errorMessage }}</span>
              </div>
            </v-col>
          </v-row>
          <p>
            NOTE:  We will place an internal note on the ticket
            once it's been updated and will send the following
            external macro AFTER the update has been completed:
            EMAIL: Primary Email Updated
          </p>
          <v-row>
            <v-col>
              <v-textarea
                v-model="callNote"
                outlined
                name="input-7-4"
                label="Call Notes"
                placeholder="Additional information gathered during call (Optional)"
                rows="3"
                no-resize
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />
          <v-btn
            v-if="updateSuccess !== null && !updateSuccess"
            color="error"
            :loading="loadingModel && reverse"
            :disabled="loadingModel"
            @click="reverseRequest"
          >
            Reverse
          </v-btn>
          <v-btn
            v-if="!reverse"
            color="primary"
            :loading="loadingModel"
            :disabled="loadingModel"
            @click="processForm"
          >
            Change Email
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="loadingModel || reverse"
            @click="starterQuestion = true; dialog = false;"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'NewPrimaryEmailDialog',
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      callNote: '',
      loadingModel: false,
      newEmail: null,
      destinations: [],
      starterQuestion: true,
      updateSuccess: null,
      reverse: false,
    };
  },
  async created() {
    await this.getUpdateDestinations();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('ticket', ['getTicket']),
    async processForm() {
      this.reverse = false;
      return this.sendRequest();
    },
    async reverseRequest() {
      this.reverse = true;
      return this.sendRequest();
    },
    async sendRequest() {
      this.loadingModel = true;
      // validate questionnaire and caller responses
      if (this.newEmail === null) {
        this.setNotification({
          message: 'Please fill out full questionnaire',
          color: 'red',
        });
        this.loadingModel = false;
        return;
      }
      try {
        const res = await this.$axios
          .post(`api/v1/support/ticket/borrower/ ${this.customer.id * 1} /new-primary-email`, {
            ticketId: this.ticket.zendesk.id * 1,
            currentEmail: this.customer.email,
            newEmail: this.newEmail.toLowerCase(),
            callNote: this.callNote,
            destinations: this.destinations,
            reverse: this.reverse,
          });
        if (res.status === 200 && res.data) {
          if (res.data.success) {
            await this.getTicket(this.$route.params.id);
            const message = this.reverse ? 'All the platforms were successfully restored to their original state' : 'All of the platforms were successfully updated!';
            this.dialog = false;
            this.$emit('success', message);
          } else {
            this.destinations = res.data.data;
            this.updateSuccess = res.success;
            this.setNotification({
              message: 'There was an error with updating one of the platforms. Please try it again. If it continues to fail, you will have to update the email manually on that platform.',
              color: 'red',
            });
          }
        } else {
          this.setNotification({
            message: 'The request returned successful, but please check the platforms for confirmation that it worked.',
            color: 'green',
          });
        }
        this.loadingModel = false;
      } catch (error) {
        let errMsg = 'There was an error processing your request';
        if (error.response && error.response.data) {
          if (error.response.data.error !== '' && error.response.data.errors) {
            errMsg = error.response.data.errors;
          } else {
            errMsg = error.response.data.message;
          }
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
    async getUpdateDestinations() {
      try {
        const res = await this.$axios
          .get('api/v1/support/ticket/borrower/update-email-destinations');
        this.loadingModel = false;
        if (res.status === 200 && res.data) {
          this.destinations = res.data;
        }
      } catch (error) {
        let errMsg = 'There was an error trying to retrieve update destinations. Please reload this TBot Action';
        if (error.response && error.response.status === 422 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
  },
};
</script>
