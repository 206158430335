<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    persistent
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="ssn"
                  required
                  label="Social Security Number"
                  :error-messages="ssnErrors"
                  @input="$v.ssn.$touch()"
                  @blur="$v.ssn.$touch()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  validations: {
    ssn: {
      required,
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          ssn: '',
        };
      },
    },
  },
  data() {
    return {
      valid: false,
      id: null,
      ssn: '',
      formProcessing: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      const newOrUpdate = this.isNewItem ? 'New' : 'Update';
      return `${newOrUpdate} Blocked SSN`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    ssnErrors() {
      const errors = [];

      if (!this.$v.ssn.$dirty) {
        return errors;
      }

      if (!this.$v.ssn.required) {
        errors.push('SSN is required.');
      }

      return errors;
    },
  },
  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.ssn = this.editedItem.ssn;
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;
        let request = '';
        const data = { ssn: this.ssn.replace(/\D/g, '') };

        if (this.isNewItem) {
          request = this.$axios.post('/api/v1/blocked-ssn', data);
        } else {
          request = this.$axios.put(`/api/v1/blocked-ssn/${this.editedItem.id}`, data);
        }

        request
          .then((res) => {
            this.$emit('item-saved');

            this.setNotification({
              message: `${res.data.blocked_ssn} has been saved.`,
              color: 'green',
            });
          })
          .catch(() => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    close() {
      this.$v.$reset();

      this.$emit('close-modal');

      this.ssn = '';
    },
  },
};
</script>

<style></style>
