<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      class="fraud-claim"
      width="800px"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasPermission('view ticket::fraud-claim')"
          v-bind="attrs"
          color="secondary"
          x-large
          block
          class="fraud-claim-dialog-btn"
          v-on="on"
        >
          Fraud Before Funding
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          text="Fraud Before Funding"
          permission="view ticket::fraud-claim"
          :x-small="false"
          :x-large="true"
          :block="true"
          button-class="fraud-claim-dialog-btn"
        />
      </template>

      <v-card :loading="loadingModel">
        <v-card-title class="text-h5 grey lighten-2">
          Fraud Before Funding - QUESTIONNAIRE
        </v-card-title>

        <v-card-text class="py-5">
          <p>
            <b>PLEASE READ!!!!!!!</b>
          </p>
          <p>
            Using the information from Loan Pro, please ask the caller if the following matches
            their information. <b>NEVER ask a victim of identity theft to give us their
              personal information</b>. If you were a victim of identity theft, would you give your
            personal information to a stranger? No, most definitely would not.
          </p>
          <p>
            Mark (Y, N, or wouldn't respond)
          </p>
          <v-row
            v-for="(question, index) in questions"
            :key="`question-${index}`"
            class="justify-center"
          >
            <v-col class="justify-center">
              <p>{{ question.question }}</p>
            </v-col>
            <v-col>
              <v-radio-group
                v-model="questions[index]['callerResponse']"
                row
                @change="clickQuestion(question.question, $event)"
              >
                <v-radio
                  label="Yes"
                  value="Y"
                />
                <v-radio
                  label="No"
                  value="N"
                />
                <v-radio
                  label="Wouldn't Respond"
                  value="Wouldn't Respond"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <p>
            NOTE:  Please inform the caller that they should visit
            <a href="www.identitytheft.gov">www.identitytheft.gov</a>
            and file an Identity Theft Report.  The easy, step by step guide, will help
            them navigate the stress of dealing with identity theft.
          </p>
          <v-row>
            <v-col>
              <v-textarea
                v-model="callNote"
                outlined
                name="input-7-4"
                label="Call Notes"
                placeholder="Additional information gathered during call (Optional)"
                rows="3"
                no-resize
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />
          <v-btn
            v-if="hasPermission('create ticket::fraud-claim')"
            color="primary"
            @click="processForm"
          >
            Submit Questionnaire
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="Submit Questionnaire"
            permission="create ticket::fraud-claim"
            :x-small="false"
          />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="customMessageDialog"
      max-width="500px"
      @click:outside="customMessageDialog = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Read to Customer</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="mt-3"
            >
              <p
                v-html="fraudItemToReview.read_to_customer"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="customMessageDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'FraudBeforeFunding',
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticketId: {
      type: Number,
      required: true,
      default: null,
    },
    customer: {
      type: Object,
      required: true,
      default: null,
    },
    loanId: {
      type: Number,
      required: true,
      default: null,
    },
    displayId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      callNote: '',
      loadingModel: false,
      customMessageDialog: false,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['fraudItemToReview']),

    questions() {
      return [
        {
          question: `Are these the last four of SSN? ${this.customer.ssn}`,
          callerResponse: null,
        },
        {
          question: `Is this your Date of Birth? ${this.customer.dob}`,
          callerResponse: null,
        },
        {
          question: `Is this your email? ${this.customer.email}`,
          callerResponse: null,
        },
        {
          question: `Is this your Address? ${this.customer.address}, ${this.customer.address2}, ${this.customer.city}, ${this.customer.state}`,
          callerResponse: null,
        },
        {
          question: `Are these the Last four of Bank Account Number? ${this.fraudItemToReview.lp_funding?.payment_account?.checking_account?.account_number || ''}`,
          callerResponse: null,
        },
        {
          question: `Are these your Bank Routing Number? ${this.fraudItemToReview.lp_funding?.payment_account?.checking_account?.routing_number || ''}`,
          callerResponse: null,
        },
        {
          question: 'Have you recently applied for any loans online?',
          callerResponse: null,
        },
      ];
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),

    async processForm() {
      this.loadingModel = true;
      // validate questionnaire and caller responses
      const invalidCallerRes = this.questions.find((question) => question.callerResponse === null);
      if (invalidCallerRes && Object.keys(invalidCallerRes).length > 0) {
        this.loadingModel = false;
        this.setNotification({
          message: `Question "${invalidCallerRes.question}" was not answered`,
          color: 'red',
        });
        return;
      }
      try {
        const res = await this.$axios
          .post('api/v1/support/ticket/fraud-claim/before-funding', {
            ticketId: this.ticketId,
            loanId: this.loanId,
            customer: this.customer,
            questionnaire: this.questions,
            callNotes: this.callNote,
            displayId: this.displayId,
          });
        this.loadingModel = false;
        if (res.status === 200 && res.data) {
          this.dialog = false;
          this.setNotification({
            message: res.data.message,
            color: 'green',
          });
        }
      } catch (error) {
        let errMsg = 'There was an error processing your request';
        if (error.response && error.response.status === 404 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
    clickQuestion(question, option) {
      if (question.includes('Are these the Last four of Bank Account Number?') && option === 'Y') {
        this.dialog = false;
        this.customMessageDialog = true;
      }
    },
  },
};
</script>
