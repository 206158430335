<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    scrollable
    @click:outside="close()"
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">Update Lending Strategy Ordering</span>
      </v-card-title>

      <v-card-text>
        <v-list dense>
          <v-list-item-group>
            <draggable
              class="list-group"
              :list="lendingStrategies"
              :disabled="formProcessing"
              group="lending-strategies-elements"
              @change="updateOrder()"
            >
              <v-list-item
                v-for="(item, index) in lendingStrategies"
                :key="index"
              >
                <v-list-item-content>
                  <div class="d-flex align-center justify-space-between">
                    <span>
                      <v-chip
                        label
                        color="blue"
                        text-color="white"
                        class="mr-2"
                      >
                        {{ index + 1 }}
                      </v-chip>

                      <span class="font-weight-bold">
                        {{ item.name }}
                      </span>
                    </span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </draggable>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="show = !show"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

import draggable from 'vuedraggable';

export default {
  name: 'LendingStrategiesRouteOrderModal',
  components: {
    draggable,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeLendingStrategies: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      formProcessing: false,
      lendingStrategies: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    activeLendingStrategies() {
      this.lendingStrategies = this.activeLendingStrategies.map((item) => ({
        name: item.name,
        id: item.id,
        route_order: item.route_order,
      }));
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    save() {
      this.$axios
        .post(`/api/v1/lending-strategy/${this.editedItem.id}/change-status`, {
          active: this.editedItem.active ? 0 : 1,
        })
        .then(() => {
          this.$emit('item-saved');

          this.setNotification({
            message: 'Lending strategy order has been updated.',
            color: 'green',
          });

          this.close();
        });
    },
    close() {
      this.$emit('close-modal');
    },
    updateOrder() {
      this.formProcessing = true;

      const originalOrder = [...this.activeLendingStrategies];

      const updatedOrder = this.lendingStrategies.map((item, index) => ({
        id: item.id,
        route_order: index,
      }));

      this.$axios
        .post('/api/v1/lending-strategy/change-route-order', {
          route_order: updatedOrder,
        })
        .then(() => ({
          message: 'Lending strategy order has been updated.',
          color: 'green',
        }))
        .catch((error) => {
          console.error(error);

          this.lendingStrategies = [...originalOrder];

          this.close();

          return {
            message: 'There was an error processing your request.',
            color: 'red',
          };
        })
        .then((res) => {
          this.formProcessing = false;

          this.setNotification({
            message: res.message,
            color: res.color,
          });
        });
    },
  },
};
</script>

<style></style>
