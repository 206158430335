<template>
  <v-data-table
    dense
    :headers="filteredHeaders"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :server-items-length="totalPersons"
    class="elevation-1"
    :footer-props.sync="footerProps"
    :item-class="itemRowClass"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title v-if="typeof $route.query.batchId === 'undefined'">
            FinCen Persons
          </v-toolbar-title>
          <v-toolbar-title v-else>
            FinCen Persons in Batch #{{ $route.query.batchId }} |
            <div v-if="showBatchNum">
              {{ formatBatchNum(items[0].batch.batch_num) }}
            </div>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <FincenPersonsModal
        v-model="personsModal"
        :item="personsItem"
        @item-saved="itemSaved()"
        @close-modal="closePerson()"
        @click:outside="closePerson()"
      />
    </template>
    <template v-slot:item.match_status="{ item }">
      {{ formatMatchStatus(item.match_status) }}
    </template>

    <template v-slot:item.batch_data="{ item }">
      <v-btn
        v-if="typeof $route.query.batchId === 'undefined'"
        class="pt-5 pb-5"
        color="primary"
        dark
        small
        tile
        :to="{ name: 'fincen-persons', query: { batchId: item.batch_id } }"
      >
        Batch #{{ item.batch_id }}<br>{{ formatBatchNum(item.batch.batch_num) }}
      </v-btn>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-menu
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            small
            v-bind="attrs"
            v-on="on"
          >
            Actions
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="showPerson(item)"
          >
            <v-list-item-title>Review</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="item.review_status === -1"
            @click="confirm(item.id)"
          >
            <v-list-item-title>Confirm</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="item.review_status === -1"
            @click="reject(item.id)"
          >
            <v-list-item-title>Reject</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import FincenPersonsModal from '@/views/Fincen/FincenPersons/FincenPersonsModal.vue';

export default {
  name: 'FincenPersonsIndex',
  components: {
    FincenPersonsModal,
  },
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    headers: [
      { text: 'Batch', value: 'batch_data', sortable: false },
      { text: 'First Name', value: 'first_name' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'SSN / DL Number', value: 'number_data' },
      { text: 'Number Type', value: 'type.type', sortable: false },
      { text: 'DOB', value: 'dob', sortable: false },
      { text: 'Street Address', value: 'street' },
      { text: 'City', value: 'city' },
      { text: 'State', value: 'state' },
      { text: 'ZIP', value: 'zip' },
      { text: 'Phone Number', value: 'phone' },
      { text: 'Match Percentage', value: 'match_status' },
      { text: '', value: 'actions', sortable: false },
    ],
    totalPersons: 0,
    personsItem: {},
    personsModal: false,
    batchNum: null,
    sortBy: 'id',
    sortDesc: true,
  }),
  computed: {
    filteredHeaders() {
      let hdrs = [];
      if (typeof this.$route.query.batchId === 'undefined') {
        hdrs = this.headers;
      } else {
        hdrs = this.headers.filter((header) => (header.value !== 'batch_data'));
      }
      return hdrs;
    },
    showBatchNum() {
      return (this.batchNum !== null);
    },
  },
  watch: {
    '$route.query': {
      handler: 'updateTable',
    },
  },
  methods: {
    closePerson() {
      this.personsModal = false;
      this.$nextTick(() => {
        this.personsItem = {};
      });
    },
    showPerson(item) {
      this.personsItem = item;
      this.personsModal = true;
    },
    reviewStatusColor(status) {
      let color;
      switch (status) {
        case -1:
          color = 'blue';
          break;
        case 1:
          color = 'green';
          break;
        case 0:
          color = 'red';
          break;
        default:
          color = 'grey';
      }
      return color;
    },
    itemSaved() {
      this.updateTable();
    },
    confirm(id) {
      this.loading = true;
      return this.$axios
        .put(`api/v1/fincen-person/${id}/confirm`)
        .then(() => {
          this.loading = false;
          this.updateTable();
        });
    },
    reject(id) {
      this.loading = true;
      return this.$axios
        .put(`api/v1/fincen-person/${id}/reject`)
        .then(() => {
          this.loading = false;
          this.updateTable();
        });
    },
    updateTable() {
      this.loading = true;
      return this.$axios
        .get('api/v1/fincen-person', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            batchId: this.$route.query.batchId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (this.$route.query.batchId !== undefined && this.batchNum === null) {
            if (res.data.data.length === 0) {
              return;
            }

            this.batchNum = res.data.data[0].batchNum;
          }
          this.items = res.data.data
            .map((item) => {
              const transformed = { ...item };
              transformed.batch_data = `batch ${transformed.batch_id}\n${this.formatBatchNum(transformed.batch.batch_num)}`;
              return transformed;
            });
          this.totalPersons = res.data.total;
        });
    },
    formatBatchNum(batchNum) {
      const hms = batchNum.slice(-6);
      const dateFmt = batchNum.slice(0, -7);
      return `${dateFmt} ${hms.slice(0, 2)}:${hms.slice(-2)}`;
    },
    formatMatchStatus(matchStatus) {
      let parsed = null;

      try {
        parsed = JSON.parse(matchStatus);
      } catch (e) {
        return '';
      }

      return parsed.pct;
    },
    itemRowClass(item) {
      if (item.review_status === -1) {
        return 'unreviewed';
      }
      if (item.review_status === 0) {
        return 'rejected';
      }
      if (item.review_status === 1) {
        return 'confirmed';
      }
      return '';
    },
  },
};
</script>

<style>
.unreviewed {
  color: #000000;
}
.rejected {
  color: #ff0000;
}
.confirmed {
  color: #00bb00;
}
</style>
