<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-2"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Helpdesk Issues</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search By Summary"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <!--  -->
        </v-toolbar>
      </v-card-title>
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  name: 'Helpdesk',

  mixins: [dataTableMixin],

  data() {
    return {
      headers: [
        { text: 'Key', value: 'key', width: '15%' },
        { text: 'Summary', value: 'summary', width: '35%' },
        { text: 'Status', value: 'status', width: '20%' },
        { text: 'Description', value: 'description', width: '30%' },
      ],
    };
  },

  methods: {
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/helpdesk', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data?.data?.data || [];
          this.totalItems = res.data?.data?.total || 0;
        });
    },
  },
};
</script>

<style scoped></style>
