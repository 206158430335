<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="formattedProspects"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Prospects</v-toolbar-title>

          <v-spacer />

          <v-toolbar-items>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="searchTable()"
            />
            <v-select
              v-model="filter.state"
              :items="filterData.states"
              item-text="state_abbr"
              item-value="state_abbr"
              label="States"
            />
            <v-select
              v-model="filter.campaignAssigned"
              :items="[true, false]"
              label="Campaign Assigned"
            />
            <v-btn
              @click="searchTable()"
            >
              Filter
            </v-btn>
          </v-toolbar-items>

          <v-spacer />

          <intermediate-file-assignments
            :file-id="fileId"
            :states="filterData.states"
            :campaigns="filterData.campaigns"
          />
        </v-toolbar>
      </v-card-title>
    </template>
  </v-data-table>
</template>

<style scoped>
  .v-toolbar__items {
    height: -webkit-fill-available;
  }
  .v-toolbar__items {
    max-width: 60%;
  }
</style>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import IntermediateFileAssignments from './IntermediateFileAssignments.vue';

export default {
  components: {
    IntermediateFileAssignments,
  },
  mixins: [
    dataTableMixin,
  ],
  props: {
    fileId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'State', value: 'state_cd' },
      { text: 'Clarity Key', value: 'clarity_key', sortable: false },
      { text: 'Credit Score', value: 'credit_score' },
      { text: 'Campaign', value: 'prescreen_campaign.name', sortable: false },
      { text: 'Offer Code', value: 'code' },
      { text: 'Print Expire Date', value: 'print_expire_date' },
    ],
    filterData: [],
    filter: {
      state: null,
      campaignAssigned: null,
    },
  }),
  computed: {
    formattedProspects() {
      return (this.items || []).map((item) => ({
        ...item,
      }));
    },
  },
  created() {
    this.$axios
      .get('/api/v1/prescreen/files/filter-params')
      .then((res) => {
        this.filterData = res.data;
      });
  },
  methods: {
    updateTable() {
      this.loading = true;
      return this.$axios
        .get(`api/v1/prescreen/files/${this.fileId}/intermediate/prospects`, {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
            filter: this.filter,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
  },
};
</script>
