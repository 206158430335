<template>
  <v-dialog
    v-model="show"
    max-width="650px"
    @click:outside="$emit('click:outside')"
  >
    <v-card :loading="dialogDeleteProcessing">
      <v-card-title class="headline">
        Are you sure you want to remove {{ editedItem.ip }}?
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :disabled="dialogDeleteProcessing"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="red"
          text
          :disabled="dialogDeleteProcessing"
          @click="deleteItemConfirm"
        >
          OK
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UserIpDeleteModal',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          ip: '',
        };
      },
    },

  },

  data() {
    return {
      dialogDeleteProcessing: false,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    close() {
      this.$emit('close-modal');
    },

    deleteItemConfirm() {
      this.dialogDeleteProcessing = true;

      this.$axios
        .delete(`api/v1/user-ip/${this.editedItem.id}`)
        .then(() => {
          this.$emit('item-deleted');

          this.setNotification({
            message: 'User IP has been deleted.',
            color: 'green',
          });
        })

        .catch(() => {
          this.setNotification({
            message: 'There was an error processing your request.',
            color: 'red',
          });
        })

        .then(() => {
          this.dialogDeleteProcessing = false;

          this.close();
        });
    },
  },
};
</script>

<style scoped>

</style>
