<template>
  <div class="text-center">
    <template
      v-if="deleteButton"
    >
      <v-btn
        small
        color="error"
        :disabled="formProcessing"
        @click="showConfirmButtons"
      >
        Delete
      </v-btn>
    </template>

    <template
      v-if="confirmButtons"
    >
      <p class="mb-1">
        Are you sure?
      </p>
      <v-btn
        small
        color="secondary"
        class="mr-2"
        :disabled="formProcessing"
        @click="showDeleteButton"
      >
        Cancel
      </v-btn>
      <v-btn
        small
        color="primary"
        :disabled="formProcessing"
        @click="confirmDelete"
      >
        Confirm
      </v-btn>
    </template>

    <template
      v-if="processingMessage"
    >
      <p>Processing...</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LoanProCustomersPaymentProfilesConfirmDeleteButtons',

  props: {
    profileId: {
      type: Number,
      default: null,
    },
    formProcessing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      deleteButton: true,
      confirmButtons: false,
      processingMessage: false,
    };
  },

  methods: {
    confirmDelete() {
      this.showProcessingMessage();
      this.$emit('delete-profile', this.profileId);
    },

    showConfirmButtons() {
      this.deleteButton = false;
      this.confirmButtons = true;
      this.processingMessage = false;
    },
    showDeleteButton() {
      this.deleteButton = true;
      this.confirmButtons = false;
      this.processingMessage = false;
    },
    showProcessingMessage() {
      this.deleteButton = false;
      this.confirmButtons = false;
      this.processingMessage = true;
    },
  },
};
</script>

<style>

</style>
