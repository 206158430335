<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      class="fraud-claim"
      width="900px"
      scrollable
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasPermission('view ticket::payment-wizard')"
          v-bind="attrs"
          color="secondary"
          x-large
          block
          class="fraud-claim-dialog-btn"
          v-on="on"
        >
          Get Started
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          text="Get Started"
          permission="view ticket::payment-wizard"
          :x-small="false"
          :x-large="true"
          :block="true"
          button-class="fraud-claim-dialog-btn"
        />
      </template>

      <v-card :loading="loadingModel">
        <v-card-title class="text-h5 grey lighten-2">
          Make Payment and Payoff
        </v-card-title>

        <v-card-text class="pa-5">
          <!-- Screen One - Display action buttons-->
          <template
            v-if="wizardScreen.landingScreen
              && activeLoan.settings
              && filterCustomerActions"
          >
            <template>
              <v-row class="mb-3">
                <v-col>
                  <v-chip
                    class="ma-2"
                    label
                  >
                    AutoPay Enabled :
                    {{ (isAutoPayEnabled ? 'Yes' : 'No') }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    label
                  >
                    Past Due Balance :
                    {{ (isPastDue ? 'Yes' : 'No') }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    label
                  >
                    Loan Status:
                    {{ activeLoan.current_status.loan_status_text }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    label
                  >
                    Loan Sub Status:
                    {{ activeLoan.current_status.loan_sub_status_text }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row
                v-if="Object.keys(defaultActions).length > 0"
                class="my-5"
              >
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <h3>What would you like to do today ?</h3>
                </v-col>
                <v-col
                  v-for="(action, index) in defaultActions"
                  :key="index"
                >
                  <v-btn
                    v-if="hasPermission('create ticket::payment-wizard')"
                    color="secondary"
                    class="wizard-action-btn"
                    block
                    large
                    @click="displayComponent(action)"
                  >
                    {{ action.name }}
                  </v-btn>
                  <disabled-for-lack-of-role-btn
                    v-else
                    :text="action.name"
                    permission="create ticket::payment-wizard"
                    :x-small="false"
                    :x-large="true"
                    :block="true"
                    button-class="wizard-action-btn"
                  />
                </v-col>
              </v-row>
              <v-divider />
              <v-row
                v-if="Object.keys(suggestedActions).length > 0"
                class="my-5 text-center"
              >
                <v-col cols="12">
                  <h4>
                    Only suggest the below actions to a customer,
                    if they are unable to carry out the actions above
                  </h4>
                  <p>
                    If borrower is asking to miss a payment you will have to do a Loan Reset.
                  </p>
                </v-col>
                <v-col
                  v-for="(action, index) in suggestedActions"
                  :key="index"
                >
                  <v-btn
                    v-if="hasPermission('create ticket::payment-wizard')"
                    color="secondary"
                    class="wizard-action-btn"
                    block
                    large
                    @click="displayComponent(action)"
                  >
                    {{ action.name }}
                  </v-btn>
                  <disabled-for-lack-of-role-btn
                    v-else
                    :text="action.name"
                    permission="create ticket::payment-wizard"
                    :x-small="false"
                    :x-large="true"
                    :block="true"
                    button-class="wizard-action-btn"
                  />
                </v-col>
              </v-row>
            </template>
          </template>
          <template v-else-if="wizardScreen.actionScreen && activeComponent">
            <v-row>
              <v-col v-if="activeComponent.component === 'make-payment'">
                <ticket-make-payment
                  :ticket="ticket"
                  :payment-profiles="customer.paymentProfiles"
                  :force-payment-type="activeComponent.others.paymentType"
                />
              </v-col>
              <v-col v-if="activeComponent.component === 'reduce-payment'">
                <ticket-make-reduced-payment
                  :customer="customer"
                  :autopays="autopays"
                  :payment-profiles="customer.paymentProfiles"
                  :payment-schedules="paymentSchedules"
                  :ticket="ticket"
                />
              </v-col>
              <v-col v-if="activeComponent.component === 'communication-macro'">
                <p>Click the button below to send communication macro</p>
                <reusable-communication-by-email-template
                  :title="activeComponent.others.title"
                  :action="activeComponent.others.action"
                  :tag="activeComponent.others.tag"
                  :customer="customer"
                  :ticket-id="ticket.zendesk.id * 1"
                />
              </v-col>
              <v-col v-if="activeComponent.component === 'cancel-payment'">
                <ticket-cancel-payment
                  :autopays="autopays"
                  :ticket="ticket"
                />
              </v-col>
              <v-col v-if="activeComponent.component === 'reschedule-payment'">
                <ticket-reschedule-payment
                  :payment-schedules="paymentSchedules"
                  :ticket="ticket"
                />
              </v-col>
              <v-col v-if="activeComponent.component === 'settlement'">
                <ticket-settlement />
              </v-col>
              <v-col v-if="activeComponent.component === 'settlement-reset'">
                <ticket-settlement-reset :ticket="ticket" />
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            v-if="!wizardScreen.landingScreen"
            color="primary"
            text
            @click="resetWizard()"
          >
            Back
          </v-btn>
          <v-spacer />
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close Wizard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
  .wizard-action-btn {
    padding: 3rem 0rem !important;
    font-weight: 600 !important;
  }
</style>

<script>

import { mapActions, mapState } from 'vuex';
import TicketReschedulePayment from '../TicketReschedulePayment.vue';
import TicketMakePayment from '../TicketMakePayment.vue';
import ReusableCommunicationByEmailTemplate from '../Communications/ReusableCommunicationByEmailTemplate.vue';
import TicketMakeReducedPayment from '../TicketMakeReducedPayment.vue';
import TicketSettlement from '../TicketSettlement.vue';
import TicketCancelPayment from '../TicketCancelPayment.vue';
import TicketSettlementReset from '../TicketSettlementReset.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'PaymentWizard',
  components: {
    TicketReschedulePayment,
    TicketMakePayment,
    ReusableCommunicationByEmailTemplate,
    TicketMakeReducedPayment,
    TicketSettlement,
    TicketCancelPayment,
    TicketSettlementReset,
    DisabledForLackOfRoleBtn,
  },
  data() {
    return {
      dialog: false,
      loadingModel: false,
      wizardScreen: {
        landingScreen: true,
        actionScreen: false,
      },
      customerActions: [
        {
          name: 'Regular',
          component: 'make-payment',
          others: { paymentType: 8 },
          active: false,
          suggestionTab: false,
          redirect: null,
        },
        {
          name: 'Scheduled Payment',
          component: 'make-payment',
          others: { paymentType: 8 },
          active: false,
          suggestionTab: false,
          redirect: null,
        },
        {
          name: 'PayOff',
          component: 'make-payment',
          others: { paymentType: 13 },
          active: false,
          suggestionTab: false,
          redirect: null,
        },
        {
          name: 'Principal Payment',
          component: 'make-payment',
          others: { paymentType: 2 },
          active: false,
          suggestionTab: false,
          redirect: null,
        },
        {
          name: 'Past Due Payment',
          component: 'make-payment',
          others: { paymentType: 8 },
          active: false,
          suggestionTab: false,
          redirect: null,
        },
        {
          name: 'Reschedule Payment',
          component: 'reschedule-payment',
          others: { paymentType: 8 },
          active: false,
          suggestionTab: true,
          redirect: null,
        },
        {
          name: 'Reduced Payment',
          component: 'reduce-payment',
          others: null,
          active: false,
          suggestionTab: true,
          redirect: null,
        },
        {
          name: 'Settlement',
          component: 'settlement',
          others: null,
          active: false,
          suggestionTab: true,
          redirect: { name: 'ticket-settlement', params: { id: this.$route.params.id } },
        },
        {
          name: 'Cancel Payment',
          component: 'cancel-payment',
          others: null,
          active: false,
          suggestionTab: true,
          redirect: null,
        },
        {
          name: 'Settlement Reset',
          component: 'settlement-reset',
          others: null,
          active: false,
          suggestionTab: true,
          redirect: null,
        },
      ],
    };
  },
  computed: {
    ...mapState('loanProLoan', ['activeLoan', 'paymentSchedules', 'autopays']),
    ...mapState('loanProCustomer', ['customer']),
    ...mapState('ticket', ['ticket']),

    activeComponent() {
      return this.customerActions.find((actions) => actions.active === true);
    },
    isAutoPayEnabled() {
      return this.activeLoan.settings.autopay_enabled === 1;
    },
    isPastDue() {
      if (this.activeLoan.loan_setup.loan_type === 'loan.type.creditLimit') {
        if (!this.activeLoan.latest_statement) {
          return false;
        }
        return this.activeLoan.latest_statement.past_due_amount > 0;
      }
      return this.activeLoan.current_status.amount_due > 0;
    },
    defaultActions() {
      // eslint-disable-next-line max-len
      return this.customerActions.filter((custAction) => ['payoff', 'regular'].includes(custAction.name.toLowerCase()));
    },
    suggestedActions() {
      return this.filterCustomerActions.filter((custAction) => custAction.suggestionTab === true);
    },
    filterCustomerActions() {
      const collectionStatuses = [
        'Account Frozen',
        'Settlement Offer 1',
        'Settlement Offer 2',
        'Pending Placement',
      ];
      const settlementStatuses = [
        'Portal 3 - Pay In Full',
        'Portal 2 - Full Settlement',
        'Portal 1 - Partial',
        'Partial Settlement Completed',
        'Portal 3 - Final Payment Pending',
      ];

      let predefineActions = [];
      if (this.isAutoPayEnabled && this.isPastDue === false) {
        predefineActions = ['reschedule payment', 'payoff', 'settlement', 'principal payment', 'reduced payment', 'cancel payment'];
      } else if (this.isAutoPayEnabled && this.isPastDue) {
        predefineActions = ['reschedule payment', 'past due payment', 'settlement', 'reduced payment', 'cancel payment'];
      } else if (this.isAutoPayEnabled === false && this.isPastDue === false) {
        predefineActions = ['scheduled payment', 'payoff', 'settlement', 'principal payment', 'reduced payment', 'cancel payment'];
      } else if (this.isAutoPayEnabled === false && this.isPastDue) {
        predefineActions = ['past due payment', 'reschedule payment', 'settlement', 'reduced payment', 'cancel payment'];
      }

      // filter by current loan status
      if (this.activeLoan.current_status.loan_status_text === 'Collections'
        && collectionStatuses.includes(this.activeLoan.current_status.loan_sub_status_text)) {
        predefineActions = ['settlement'];
      }
      if (this.activeLoan.current_status.loan_status_text === 'Settlement'
        && settlementStatuses.includes(this.activeLoan.current_status.loan_sub_status_text)) {
        predefineActions = ['cancel payment', 'make a payment', 'settlement reset'];
      }

      // eslint-disable-next-line max-len
      return this.customerActions.filter((action) => predefineActions.includes(action.name.toLowerCase()));
    },
  },
  watch: {
    dialog() {
      this.resetWizard();
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    displayComponent(action) {
      if (action.redirect) {
        this.setNotification({
          message: `Redirecting to ${action.name}`,
          color: 'green',
        });
        setTimeout(() => { this.$router.push(action.redirect); }, 2000);
      } else {
        const objIndex = this.customerActions.findIndex(((obj) => obj === action));
        this.customerActions[objIndex].active = true;
        this.wizardScreen.landingScreen = false;
        this.wizardScreen.actionScreen = true;
      }
    },
    resetWizard() {
      this.wizardScreen.landingScreen = true;
      this.wizardScreen.actionScreen = false;
      if (this.activeComponent?.active || null) {
        const objIndex = this.customerActions.findIndex(((obj) => obj.active === true));
        this.customerActions[objIndex].active = false;
      }
    },
  },
};
</script>
