<template>
  <v-alert
    v-if="updated"
    border="left"
    color="red darken-4"
    dark
  >
    <v-row align="center">
      <v-col class="grow">
        Warning: This version of admin is out of date.
      </v-col>
      <v-col class="shrink">
        <v-btn @click="update()">
          Update
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      versionInterval: null,
      url: null,
      updated: false,
    };
  },
  created() {
    this.startPollVersion();
  },
  methods: {
    update() {
      window.location.reload();
    },
    startPollVersion() {
      this.versionInterval = setInterval(() => this.pollVersion(), 60000);
      this.pollVersion();
    },
    pollVersion() {
      this.$axios
        .get(window.location.origin)
        .then((res) => {
          if (this.updated) return;
          const parser = new DOMParser();
          const document = parser.parseFromString(res.data, 'text/html');
          const updated = this.url && this.url !== document.body.lastElementChild.src;
          if (updated) {
            this.updated = true;
            this.stopPollVersion();
            this.$forceUpdate();
          } else {
            this.url = document.body.lastElementChild.src;
          }
        });
    },
    stopPollVersion() {
      clearInterval(this.versionInterval);
    },
  },
};
</script>
