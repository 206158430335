<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="1250"
    @click:outside="dialog = !dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="blue-grey"
        class="ma-2 white--text"
        v-bind="attrs"
        v-on="on"
      >
        Report
        <v-icon
          right
          dark
        >
          mdi-cloud-download
        </v-icon>
      </v-btn>
    </template>
    <v-card :loading="searchingTickets">
      <v-card-title>
        <span class="headline"> Ops Ticket Report </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="mt-3">
            <v-col
              cols="6"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="startDate"
                required
                label="Start Date"
                type="date"
                :error-messages="startDateErrors"
                @input="$v.startDate.$touch()"
                @blur="$v.startDate.$touch()"
              />
            </v-col>
            <v-col
              cols="6"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="endDate"
                required
                label="End Date"
                type="date"
                :error-messages="endDateErrors"
                @input="$v.endDate.$touch()"
                @blur="$v.endDate.$touch()"
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="mt-3">
            <v-col
              cols="6"
              sm="6"
              md="6"
            >
              <v-select
                v-model="statusSelected"
                :items="statuses"
                item-text="name"
                item-value="id"
                label="Status"
              />
            </v-col>

            <v-col
              cols="3"
              sm="3"
              md="3"
            >
              <v-select
                v-model="classificationSelected"
                :items="classifications"
                item-text="name"
                item-value="id"
                label="Classifications"
              />
            </v-col>
            <v-col
              cols="3"
              sm="3"
              md="3"
            >
              <v-select
                v-model="subClassificationSelected"
                :items="subClassifications"
                item-text="name"
                item-value="id"
                label="Sub Classifications"
                :disabled="subClassificationsDisabled"
              />
            </v-col>
          </v-row>

          <v-row class="mb-5">
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-btn
                :loading="searchingTickets"
                color="primary"
                :disabled="searchingTickets || !ableToSearch"
                @click="searchOpsTickets()"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>

          <v-divider />

          <v-container v-if="totalItems">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-data-table
                  :headers="resultHeaders"
                  :loading="searchingTickets"
                  :items="result"
                  :options.sync="options"
                  :server-items-length="totalItems"
                  calculate-widths
                  class="elevation-2 mt-5"
                  :footer-props.sync="footerProps"
                  @update:options="searchOpsTickets()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-btn
                  :loading="exportingTickets"
                  color="primary"
                  @click="exportReport()"
                >
                  Export
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :disabled="searchingTickets"
          @click="closeDialog()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
  name: 'OpsTicketDialogReport',

  mixins: [dataTableMixin],

  data() {
    return {
      searchingTickets: false,
      dialog: false,
      startDate: '',
      endDate: '',
      errors: {},
      statuses: [],
      statusSelected: 0,
      classifications: [],
      classificationSelected: 0,
      subClassifications: [],
      subClassificationSelected: 0,
      loadingSubClassification: false,
      exportingTickets: false,

      result: [],
      resultHeaders: [
        { text: 'Classification', value: 'classificationName', sortable: false },
        { text: 'SubClassification', value: 'subClassificationName', sortable: false },
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Date/time Ticket Submitted', value: 'createdAt', sortable: false },
        { text: 'Agent Name', value: 'agentName', sortable: false },
        { text: 'Status', value: 'statusName', sortable: false },
        { text: 'Date Solved', value: 'dateSolved', sortable: false },
        { text: 'Lp Display Id', value: 'lpDisplayId', sortable: false },
        { text: 'Ops Display Id', value: 'opsTicketDisplayId', sortable: false },
      ],
      totalItems: 0,
    };
  },

  computed: {
    subClassificationsDisabled() {
      return !this.subClassifications.length || this.loadingSubClassification;
    },

    startDateErrors() {
      const errors = [];

      if (!this.$v.startDate.$dirty) {
        return errors;
      }
      if (!this.$v.startDate.required) {
        errors.push('Start Date is required.');
      }

      const startDateFormatted = new Date(this.startDate);
      const endDateFormatted = new Date(this.endDate);

      if (startDateFormatted > endDateFormatted) {
        errors.push('End Date must be greater than Start Date');
      }

      return errors;
    },

    endDateErrors() {
      const errors = [];

      if (!this.$v.endDate.$dirty) {
        return errors;
      }
      if (!this.$v.endDate.required) {
        errors.push('End Date is required.');
      }

      const startDateFormatted = new Date(this.startDate);
      const endDateFormatted = new Date(this.endDate);

      if (startDateFormatted > endDateFormatted) {
        errors.push('End Date must be greater than Start Date');
      }

      return errors;
    },

    ableToSearch() {
      if (this.startDateErrors.length || this.endDateErrors.length) {
        return false;
      }

      return this.startDate && this.endDate;
    },
  },

  watch: {
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate() {
      this.endDateFormatted = this.formatDate(this.endDate);
    },

    classificationSelected() {
      this.loadSubClassifications();
    },
  },

  mounted() {
    this.loadStatuses();

    this.loadClassifications();
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    closeDialog() {
      this.dialog = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    async loadStatuses() {
      try {
        const response = await this.$axios.get('api/v1/ops-tickets/status');

        if (response.status === 200) {
          const defaultStatus = {
            id: 0,
            name: 'All',
          };

          this.statuses = response.data?.data || [];

          this.statuses.unshift(defaultStatus);
        } else {
          this.setNotification({
            message: 'Something happens loading the statuses, please try again',
            color: 'red',
          });
        }
      } catch (err) {
        this.setNotification({
          message: 'Something happens loading the statuses, please try again',
          color: 'red',
        });
      }
    },

    async loadClassifications() {
      try {
        const response = await this.$axios.get('api/v1/ops-tickets/classification');

        if (response.status === 200) {
          const defaultClassifications = {
            id: 0,
            name: 'All',
          };

          this.classifications = response.data?.data || [];

          this.classifications.unshift(defaultClassifications);
        } else {
          this.setNotification({
            message: 'Something happens loading the classifications, please try again',
            color: 'red',
          });
        }
      } catch (err) {
        this.setNotification({
          message: 'Something happens loading the classifications, please try again',
          color: 'red',
        });
      }
    },

    async loadSubClassifications() {
      this.loadingSubClassification = true;

      try {
        const response = await this.$axios.get('api/v1/ops-tickets/sub-classification', {
          params: {
            classification: this.classificationSelected,
          },
        });

        if (response.status === 200) {
          const defaultSubClassification = {
            id: 0,
            name: 'All',
          };

          this.subClassifications = response.data?.data || [];

          this.subClassifications.unshift(defaultSubClassification);
        } else {
          this.setNotification({
            message: 'Something happens loading the sub classifications, please try again',
            color: 'red',
          });
        }
      } catch (err) {
        this.setNotification({
          message: 'Something happens loading the sub classifications, please try again',
          color: 'red',
        });
      } finally {
        this.loadingSubClassification = false;
      }
    },

    async searchOpsTickets() {
      if (this.ableToSearch) {
        this.searchingTickets = true;

        try {
          const response = await this.$axios.get('api/v1/ops-tickets/', {
            params: {
              startDate: this.startDate,
              endDate: this.endDate,
              status: this.statusSelected,
              classification: this.classificationSelected,
              subClassification: this.subClassificationSelected,
              paginate: true,
            },
          });

          if (response.status === 200) {
            this.result = response.data?.data || [];

            this.totalItems = response.data?.meta.total || 0;
          } else {
            this.setNotification({
              message: 'Something happens loading the tickets, please try again',
              color: 'red',
            });
          }
        } catch (err) {
          this.setNotification({
            message: 'Something happens loading the tickets, please try again',
            color: 'red',
          });
        } finally {
          this.searchingTickets = false;
        }
      }
    },

    exportReport() {
      this.exportingTickets = true;

      try {
        this.$axios
          .get('api/v1/ops-tickets/exportCsv', {
            params: {
              startDate: this.startDate,
              endDate: this.endDate,
              status: this.statusSelected,
              classification: this.classificationSelected,
              subClassificationSelected: this.subClassificationSelected,
            },
          })
          .then((res) => {
            const blob = new Blob([res.data], { type: 'text/csv' });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = 'tickets.csv';
            link.click();
            link.remove();
          });
      } catch (err) {
        this.setNotification({
          message: 'Something happens exporting the tickets, please try again',
          color: 'red',
        });
      } finally {
        this.exportingTickets = false;
      }
    },
  },

  validations: {
    startDate: { required, minValue() {} },
    endDate: { required },
  },
};
</script>
<style scoped></style>
