<template>
  <v-card
    class="mt-1 mb-2 ops-ticket-card grabbable"
    color="#f7f5f0"
    draggable="true"
    @dragstart="startDrag($event, ticket)"
  >
    <v-container @click="openTicketDialog()">
      <v-card-title class="ticket-title">
        {{ ticket.title }}
        <v-spacer />
        <span class="font-weight-light">
          {{ ticket.ops_ticket_display_id }}
        </span>
      </v-card-title>

      <v-card-text class="ticket-text">
        <v-row>
          <v-col class="pl-2 ticket-col text--secondary">
            <p class="">
              {{ ticket.classificationName }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <span class="font-italic text-caption">
          {{ ticket.agentName }}
        </span>
        <v-spacer />
        <div>
          <v-icon
            color="re"
            small
          >
            mdi-priority-low
          </v-icon>
        </div>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'OpsTicketCard',

  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },

  methods: {
    startDrag(evt, ticket) {
      const newEvent = evt;
      newEvent.dataTransfer.dropEffect = 'move';
      newEvent.dataTransfer.effectAllowed = 'move';
      newEvent.dataTransfer.setData('ticket', JSON.stringify(ticket));
    },

    openTicketDialog() {
      this.$emit('open-ticket-dialog', this.ticket);
    },
  },
};
</script>

<style scoped>
.ticket-title {
  padding: 10px;
}

.ticket-text {
  padding-top: 0;
  padding-left: 16px;
  padding-bottom: 5px;
}

.ticket-col {
  padding: 0;
}
</style>
