<template>
  <v-row
    class="ticket-show"
  >
    <v-col
      cols="12"
    >
      <v-card
        class="ticket-show-actions"
        elevation="2"
        loading="false"
        outlined
        tile
      >
        <v-card-title>
          Revoke Autopay
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="autopay === null">
              There is no current autopay.
              {{ autopay }}
            </v-col>
            <v-col v-if="autopay && autopay.name">
              <v-card>
                <v-card-title>Autopay</v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Autopay Name</v-list-item-title>
                        <v-list-item-subtitle>{{ autopay.name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Type</v-list-item-title>
                        <v-list-item-subtitle>Recurring</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content v-if="secondAutopay">
                        <v-list-item-title>Frequency</v-list-item-title>
                        <v-list-item-subtitle>
                          Semi-Monthly
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>Frequency</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatRecurringFrequency(autopay.recurring_frequency) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content v-if="secondAutopay">
                        <v-list-item-title>Recurring Periods</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ autopay.recurring_periods * 2 }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>Recurring Periods</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ autopay.recurring_periods }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content v-if="secondAutopay">
                        <v-list-item-title>Apply Dates</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDate(autopay.apply_date) }}<br>
                          {{ formatDate(secondAutopay.apply_date) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>Apply Date</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDate(autopay.apply_date) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content v-if="secondAutopay">
                        <v-list-item-title>Process Date/Times</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDateTime(autopay.process_datetime) }}<br>
                          {{ formatDateTime(secondAutopay.process_datetime) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>
                          Process Date/Time
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDateTime(autopay.process_datetime) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Amount</v-list-item-title>
                        <v-list-item-subtitle>${{ autopay.amount }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="payment">
              <v-card>
                <v-card-title>Payment</v-card-title>
                <v-card-text>
                  <v-list
                    v-if="payment"
                    dense
                  >
                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Payment Name</v-list-item-title>
                        <v-list-item-subtitle>{{ payment.info }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Payment Method</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ payment.payment_method_name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Apply Date</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDate(payment.apply_date) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Amount</v-list-item-title>
                        <v-list-item-subtitle>${{ payment.amount }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingModel"
            @click="confirmModal = true"
          >
            Revoke Autopay
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingModel"
            :to="{ name: 'ticket-show', params: { id: $route.params.id } }"
          >
            Cancel
          </v-btn>
        </v-card-actions>

        <v-dialog
          v-if="autopay"
          v-model="confirmModal"
          max-width="500px"
          @click:outside="confirmModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Are you sure?</span>
            </v-card-title>

            <v-card-text>
              Clicking confirm will revoke the following
              autopay<span v-if="payment"> and reverse the
                following payment</span>:
              <v-row>
                <v-col>
                  <v-list dense>
                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Autopay Name</v-list-item-title>
                        <v-list-item-subtitle>{{ autopay.name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Type</v-list-item-title>
                        <v-list-item-subtitle>Recurring</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content v-if="secondAutopay">
                        <v-list-item-title>Frequency</v-list-item-title>
                        <v-list-item-subtitle>
                          Semi-Monthly
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>Frequency</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatRecurringFrequency(autopay.recurring_frequency) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content v-if="secondAutopay">
                        <v-list-item-title>Recurring Periods</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ autopay.recurring_periods * 2 }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>Recurring Periods</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ autopay.recurring_periods }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content v-if="secondAutopay">
                        <v-list-item-title>Apply Dates</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDate(autopay.apply_date) }}<br>
                          {{ formatDate(secondAutopay.apply_date) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>Apply Date</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDate(autopay.apply_date) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content v-if="secondAutopay">
                        <v-list-item-title>Process Date/Times</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDateTime(autopay.process_datetime) }}<br>
                          {{ formatDateTime(secondAutopay.process_datetime) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>
                          Process Date/Time
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDateTime(autopay.process_datetime) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Amount</v-list-item-title>
                        <v-list-item-subtitle>${{ autopay.amount }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col>
                  <v-list
                    v-if="payment"
                    dense
                  >
                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Payment Name</v-list-item-title>
                        <v-list-item-subtitle>{{ payment.info }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Payment Method</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ payment.payment_method_name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Apply Date</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDate(payment.apply_date) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      two-line
                      class="pl-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Amount</v-list-item-title>
                        <v-list-item-subtitle>${{ payment.amount }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                v-if="autopay.name && hasPermission('create ticket::revoke-autopay')"
                color="blue darken-1"
                text
                :loading="loadingModel"
                @click="revokeAutopay()"
              >
                Confirm
              </v-btn>
              <disabled-for-lack-of-role-btn
                v-else
                text="Confirm"
                permission="create ticket::revoke-autopay"
                :x-small="false"
                :text-button="true"
              />

              <v-btn
                color="blue darken-1"
                text
                @click="confirmModal = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="revokeModal"
          max-width="500px"
          @click:outside="revokeModal = false"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Autopay Revoked</span>
            </v-card-title>

            <v-card-text>
              <p>Would you like to perform another task or mark this ticket as solved?</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                color="blue darken-1"
                text
                @click="anotherTask()"
              >
                Another Task
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="resolveTicket()"
              >
                Resolve Ticket
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'vuex';
import { AutopayFrequencies } from '@/services/LpConstants';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'TicketRevokeAutopay',
  components: {
    DisabledForLackOfRoleBtn,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      revokeModal: false,
      confirmModal: false,
      loadingModel: false,
      autopay: null,
      secondAutopay: null,
      payment: null,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['activeLoan']),
  },
  created() {
    this.getAutopay();
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    getAutopay() {
      return this.$axios
        .post('api/v1/support/ticket/autopay/find', {
          ticketId: this.$route.params.id,
          loanId: this.activeLoan.id,
        })
        .then((res) => {
          this.autopay = res.data.autopay;
          if (
            res.data.second_autopay
            && res.data.second_autopay.recurring_frequency
            === AutopayFrequencies.MONTHLY
          ) {
            this.secondAutopay = res.data.second_autopay;
          }
          this.payment = res.data.payment;
        });
    },
    revokeAutopay() {
      this.loadingModel = true;
      return this.$axios
        .post('api/v1/support/ticket/autopay/revoke', {
          ticketId: this.$route.params.id,
          loanId: this.activeLoan.id,
          autopayId: this.autopay.id,
          secondAutopayId: this.secondAutopay ? this.secondAutopay.id : undefined,
          paymentId: this.payment ? this.payment.id : undefined,
        })
        .then(() => {
          this.getAutopay();
          this.confirmModal = false;
          this.loadingModel = false;
          this.revokeModal = true;
          this.setNotification({
            message: 'The autopay was revoked successfully.',
            color: 'green',
          });
        });
    },
    anotherTask() {
      this.revokeModal = false;
      this.$router.push({
        name: 'ticket-show',
        params: {
          id: this.$route.params.id,
        },
      });
    },
    resolveTicket() {
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.loadingModel = false;

          this.$router.push({
            name: 'tickets',
          });
        });
    },
    formatDate(date) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('EEEE, M/d/yy');
    },
    formatDateTime(dateTime) {
      return DateTime.fromSQL(dateTime).toFormat('EEEE, M/d/yy h:ss a');
    },
    formatRecurringFrequency(recurringFrequency) {
      const names = {};
      names[AutopayFrequencies.BIWEEKLY] = 'Bi-Weekly';
      names[AutopayFrequencies.MONTHLY] = 'Monthly';
      names[AutopayFrequencies.WEEKLY] = 'Weekly';
      return names[recurringFrequency];
    },
  },
};
</script>
