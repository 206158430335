<template>
  <div
    class="costList"
  >
    <v-row class="text-right">
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          v-show="type !== 'reference'"
          elevation="2"
          dark
          color="primary"
          @click="showAdd('costForm')"
        >
          Add new
        </v-btn>
      </v-col>
    </v-row>

    <v-alert
      v-if="showAlert"
      class="mt-4"
      border="top"
      color="orange darken-1"
      dark
    >
      This scoring element does not have cost effective date associated!
    </v-alert>
    <v-simple-table
      v-else
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Cost
            </th>
            <th class="text-left">
              Start Date
            </th>
            <th class="text-left">
              End Date
            </th>
            <th
              v-show="type !== 'reference'"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td>{{ item.cost }}</td>
            <td>{{ item.startDate }}</td>
            <td>{{ item.endDate }}</td>
            <td
              v-show="type !== 'reference'"
            >
              <v-btn
                elevation="2"
                dark
                color="error"
                @click="deleteEffectiveDate(item)"
              >
                Delete
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'ScoringElementCostList',
  props: {
    scoringElement: {
      type: Object,
      required: true,
    },

    newItem: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    type: {
      type: String,
      required: false,
      default: 'List',
    },
  },

  data() {
    return {
      loading: false,
      items: [],
      deleting: false,
    };
  },

  computed: {
    showAlert() {
      return !this.loading && !this.items.length;
    },
  },

  watch: {
    scoringElement: {
      deep: true,
      handler(value) {
        if (value) {
          this.loadCostEffectiveDates();
        }
      },
    },

    newItem: {
      deep: true,
      handler(value) {
        if (value) {
          this.items.push(value);
        }
      },
    },
  },

  mounted() {
    this.loadCostEffectiveDates();
  },

  methods: {
    loadCostEffectiveDates() {
      this.loading = true;
      this.$axios.get('/api/v1/scoring-element-cost', {
        params: {
          scoringElementId: this.scoringElement.id,
          perPage: '5',
        },
      })
        .then((response) => {
          const { data } = response;
          this.items = data.data;
          this.loading = false;
        });
    },

    showAdd(view) {
      this.$emit('changeView', view);
    },

    deleteEffectiveDate(item) {
      this.deleting = true;
      this.$axios.delete(`/api/v1/scoring-element-cost/${item.id}`)
        .then(() => {
          this.deleting = false;
          this.items = this.items.filter((date) => date.id !== item.id);
        });
    },
  },
};
</script>

<style scoped>

</style>
