<template>
  <v-container>
    <h3>Comments</h3>
    <v-textarea
      v-model="currentComment"
      :rules="validations.currentComment"
    />

    <v-row>
      <v-col>
        <v-btn
          :loading="creatingComment"
          color="primary"
          :disabled="!ableToCreate || creatingComment"
          @click="createComment()"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="comments.length"
        cols="12"
        sm="12"
        md="12"
      >
        <OpsTicketDialogCommentCard
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OpsTicketDialogCommentCard from '@/views/Helpdesk/components/OpsTicketDashboard/OpsTicketDialogCommentCard.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'OpsTicketDialogComments',
  components: { OpsTicketDialogCommentCard },

  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentComment: '',
      comments: [],
      validations: {
        currentComment: [(value) => (!value || value.length > 4) || 'Comment must have more that 4 characters'],
      },
      creatingComment: false,
      loadingComments: false,
    };
  },

  computed: {
    ...mapState('currentUser', ['user']),
    ableToCreate() {
      return this.currentComment.length > 4;
    },
  },

  watch: {
    ticket: {
      deep: true,
      handler(value) {
        if (Object.keys(value).length) {
          this.loadTicketComments();
        } else {
          this.cleanComments();
        }
      },
    },
  },

  mounted() {
    this.loadTicketComments();
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    cleanComments() {
      this.currentComment = '';
      this.comments = [];
      this.creatingComment = false;
      this.loadingComments = false;
    },

    loadTicketComments() {
      this.loadingComments = true;

      this.$axios
        .get('api/v1/ops-tickets/comment', {
          params: {
            paginate: false,
            ticket_id: this.ticket.id,
          },
        })
        .then((res) => {
          this.loadingComments = false;

          this.comments = res.data?.data || [];
        })
        .catch((error) => {
          this.loadingComments = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    createComment() {
      this.creatingComment = true;

      this.$axios
        .post('api/v1/ops-tickets/comment', {
          ticket_id: this.ticket.id,
          comment: this.currentComment,
          created_by: this.user.id || 0,
        })
        .then((res) => {
          this.creatingComment = false;
          if (res.data?.data) {
            this.comments.unshift(res.data.data);
          }

          this.currentComment = '';
        })
        .catch((error) => {
          this.creatingComment = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },
  },
};
</script>

<style scoped></style>
