<template>
  <v-dialog
    v-model="dialog"
    width="900"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title
        class="text-body-2"
        v-bind="attrs"
        v-on="on"
      >
        Classification
      </v-list-item-title>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Classification Settings
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col
              class="d-flex"
              cols="12"
              sm="6"
            >
              <v-select
                v-model="classificationTypeSelected"
                :items="classificationTypes"
                label="Classifications"
              />
            </v-col>
          </v-row>

          <v-divider />

          <v-row>
            <v-col
              class="d-flex"
              cols="12"
              sm="12"
            >
              <v-container
                v-if="classificationTypeSelected === 'Classification'"
                class="classification-type"
              >
                <OpsTicketClassificationDatatable />
              </v-container>

              <v-container
                v-else
                class="sub-classification-type"
              >
                <OpsTicketSubClassificationDatatable />
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OpsTicketClassificationDatatable from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketClassificationDatatable.vue';
import OpsTicketSubClassificationDatatable from '@/views/Helpdesk/components/OpsTicketSettings/OpsTicketSubClassificationDatatable.vue';

export default {
  name: 'OpsTicketClassificationDialog',
  components: {
    OpsTicketSubClassificationDatatable,
    OpsTicketClassificationDatatable,
  },

  data: () => ({
    dialog: false,
    classificationTypes: ['Classification', 'Sub-Classification'],
    classificationTypeSelected: 'Classification',
  }),
};
</script>

<style scoped></style>
