<template>
  <v-col>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="mb-0"
          >
            <v-select
              v-model="paymentProfile"
              :items="paymentProfiles"
              item-value="id"
              item-text="title"
              label="Payment Method"
              outlined
              required
              :class="{ 'is-invalid': $v.paymentProfile.$error }"
              :error-messages="paymentProfileErrors"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="mb-0"
          >
            <v-alert
              v-if="isDebitCard"
              prominent
              dense
              dark
              text
              border="left"
              color="primary"
              icon="mdi-account-voice"
            >
              <blockquote class="blockquote">
                Since this payment method is a debit card, you have the option of processing
                this payment immediately.
              </blockquote>
            </v-alert>
            <v-checkbox
              v-if="isDebitCard"
              v-model="immediatePayment"
              :label="'Process this Payment Immediately?'"
            />
            <v-text-field
              v-if="(!immediatePayment && isDebitCard) || !isDebitCard"
              v-model="paymentDate"
              label="Payment Date"
              :max="maxPaymentDate"
              :min="minPaymentDate"
              type="date"
              :class="{ 'is-invalid': $v.paymentDate.$error }"
              :error-messages="paymentDateErrors"
              outlined
              required
              @input="$v.paymentDate.$touch()"
              @blur="$v.paymentDate.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="mb-0"
          >
            <v-select
              v-model="forcePaymentType"
              :items="paymentTypes"
              item-value="id"
              item-text="title"
              label="Payment Type"
              outlined
              required
              :readonly="(forcePaymentType) ? true : false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="mb-0"
          >
            <v-text-field
              v-model="forcePaymentAmount"
              prepend-inner-icon="mdi-currency-usd"
              label="Payment Amount"
              outlined
              required
              type="number"
              :readonly="forcePaymentType === 2"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="loadingModel"
          :loading="loadingModel"
          @click="makePayment()"
        >
          Make Payment
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="loadingModel"
          :to="{ name: 'ticket-show', params: { id: $route.params.id } }"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { DateTime } from 'luxon';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  name: 'RescindAfterFunding',
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    paymentProfiles: {
      type: Array,
      required: true,
    },
    forcePaymentAmount: {
      type: Number,
      default: null,
    },
    forcePaymentType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      paymentModal: false,
      paymentData: {},
      confirmModal: false,
      loadingModel: false,
      paymentProfile: null,
      paymentDate: DateTime.local().plus({ days: 1 }).toISODate(),
      immediatePayment: false,
      paymentTypes: [
        { id: 2, title: 'Principal' },
      ],
      maxPaymentDate: DateTime.local().plus({ days: 7 }).toISODate(),
      minPaymentDate: DateTime.local().toISODate(),
    };
  },
  validations() {
    return {
      paymentProfile: { required },
      paymentDate: { required },
    };
  },
  computed: {
    paymentDateErrors() {
      const errors = [];

      if (!this.$v.paymentDate.$dirty) {
        return errors;
      }
      if (!this.$v.paymentDate.required) {
        errors.push('Payment Date is required.');
      }
      return errors;
    },
    paymentProfileErrors() {
      const errors = [];

      if (!this.$v.paymentProfile.$dirty) {
        return errors;
      }
      if (!this.$v.paymentProfile.required) {
        errors.push('Payment method is required.');
      }
      return errors;
    },
    isDebitCard() {
      return ((this.paymentProfiles || []).find((x) => x.id === this.paymentProfile)?.type || '') === 'paymentAccount.type.credit';
    },
  },
  watch: {
    immediatePayment() {
      if (this.isDebitCard && this.immediatePayment) {
        this.paymentDate = DateTime.local().toISODate();
      } else {
        this.paymentDate = DateTime.local().plus({ days: 1 }).toISODate();
      }
    },
    paymentProfile() {
      if (this.isDebitCard === false) {
        this.minPaymentDate = DateTime.local().plus({ days: 1 }).toISODate();
      } else {
        this.minPaymentDate = DateTime.local().toISODate();
      }
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('loanProLoan', ['rescindLoanAfterFunding']),

    generateRequestObj() {
      if (this.isDebitCard && this.immediatePayment) {
        this.paymentDate = DateTime.local().toISODate();
      }

      return {
        ticketId: this.$route.params.id,
        paymentDate: this.paymentDate,
        paymentAmount: this.forcePaymentAmount,
        paymentTypeId: this.forcePaymentType,
        immediatePayment: this.isDebitCard && this.immediatePayment,
        paymentAccount: this.paymentProfile,
      };
    },
    async makePayment() {
      this.loadingModel = true;
      // result is a boolean
      const result = await this.rescindLoanAfterFunding(this.generateRequestObj());

      this.loadingModel = false;

      if (result === true) {
        this.$emit('success', this.generateRequestObj());
      }
    },
  },
};
</script>
