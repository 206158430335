import Vue from 'vue';
import { datadogRum } from '@datadog/browser-rum';

const initialState = {
  isLoggedIn: false,
  user: {},
};

const getters = {
  sidebarCanAccess(state) {
    // trim everything except the root permission name
    const trim = state.user.permission.map((item) => item.name.split(' ').pop('').split('::')[0]);
    // remove duplicates left after trimming
    const removeDupes = [...new Set(trim)];

    return removeDupes.includes(state);
  },
};

const actions = {
  async logUserIn({ commit }, user) {
    commit('SET_USER', user);
    commit('SET_IS_LOGGED_IN', true);
    datadogRum.setUser({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
    });
  },
  async getUser({ commit }) {
    try {
      const request = await Vue.prototype.$axios.get('/api/v1/user/current-user');
      commit('SET_USER', request.data);
      commit('SET_IS_LOGGED_IN', true);
    } catch (err) {
      commit('SET_USER', {});
      commit('SET_IS_LOGGED_IN', false);
    }
  },
  async logUserOut({ commit }) {
    try {
      Vue.prototype.$axios.post('/logout');
      commit('SET_USER', {});
      commit('SET_IS_LOGGED_IN', false);
    } catch (err) {
      commit('SET_USER', {});
      commit('SET_IS_LOGGED_IN', false);
    }
    // datadogRum.removeUser();
  },
};

const mutations = {
  SET_USER(state, data) {
    state.user = data;
  },
  SET_IS_LOGGED_IN(state, data) {
    state.isLoggedIn = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
