<template>
  <v-container fluid>
    <h3>White Pages Data</h3>

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="whitePagesData.identity_check_score"
          label="Identity Check Score"
          hint="Less than 250 passes"
          persistent-hint
          type="number"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="whitePagesData.ip_address_checks.geolocation.country_code"
          label="Country Code"
          hint="Country code US passes"
          persistent-hint
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="whitePagesData.identity_network_score"
          label="Identity Network Score"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    whitePagesData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
