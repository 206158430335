<template>
  <div class="content-holder">
    <v-row>
      <v-col
        cols="12"
      >
        <v-btn
          class="float-right mt-n10"
          @click="$emit('back-ticket-settlement')"
        >
          back
        </v-btn>
      </v-col>
    </v-row>
    <SettlementConfirmation
      v-if="showConfirmation"
      v-bind="{ ...activeComponentData }"
      :display-id="displayId"
      :full-name="fullName"
    />
    <div
      v-else
    >
      <div>
        <h2 class="header mb-2">
          Pay Your Full Balance
        </h2>
        <h2 class="mb-2 red--text">
          Your current loan balance is {{ todayPayoffBalance | currency }}
        </h2>
        <p>
          Pay off your loan and never worry about it again.
          <i>Avoid long-term negative effects to your credit.</i>
        </p>
      </div>
      <div class="settlement-option mb-s mb-3">
        <h2 class="title">
          SELECT PAYMENT TERMS
        </h2>
        <p class="mt--xxs">
          Select how you want to resolve your debt.
        </p>

        <div class="row">
          <!--
            // TODO: FIX MOBILE CLICK CHANGING BUTTON COLOR ISSUE,
            ON MOBILE THE COLOR WHON'T CHANGE UNTIL FOCUS CHANGE
          -->
          <div class="col-sm-6 mt-xs">
            <v-btn
              class="p-xs"
              button-full
              size="large"
              width="100%"
              :color="isPaymentPlan ? 'settlement' : 'settlement-inverse'"
              @click="clickPaymentPlan"
            >
              PAYMENT PLAN
            </v-btn>
          </div>

          <div class="col-sm-6 mt-xs">
            <v-btn
              class="p-xs"
              button-full
              size="large"
              width="100%"
              :color="isSinglePayment ? 'settlement' : 'settlement-inverse'"
              @click="clickSinglePayment"
            >
              SINGLE PAYMENT
            </v-btn>
          </div>
        </div>
      </div>

      <keep-alive>
        <PayFullBalanceForm
          v-if="isPaymentPlan"
          :today-payoff-balance="todayPayoffBalance"
          :loan-settings-id="loanSettingsId"
          :payment-accounts="paymentAccounts"
          :customer-id="customerId"
          :loan-id="loanId"
          :ticket="ticket"
          :refresh-key="refreshKey"
          @offer-confirmed="onOfferConfirmed"
        />
        <PayFullBalanceSinglePaymentForm
          v-if="isSinglePayment"
          :today-payoff-balance="todayPayoffBalance"
          :loan-settings-id="loanSettingsId"
          :payment-accounts="paymentAccounts"
          :customer-id="customerId"
          :loan-id="loanId"
          :ticket="ticket"
          :refresh-key="refreshKey"
          @offer-confirmed="onOfferConfirmed"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

import PayFullBalanceForm from './PayFullBalanceForm.vue';
import PayFullBalanceSinglePaymentForm from './PayFullBalanceSinglePaymentForm.vue';
import SettlementConfirmation from './SettlementConfirmation.vue';

export default {
  name: 'PayFullBalance',

  components: {
    PayFullBalanceSinglePaymentForm,
    PayFullBalanceForm,
    SettlementConfirmation,
  },

  props: {
    ticket: {
      type: Object,
      required: true,
    },
    todayPayoffBalance: {
      type: Number,
      required: true,
    },
    paymentAccounts: {
      type: Array,
      required: true,
    },
    loanSettingsId: {
      type: Number,
      required: true,
    },
    customerId: {
      type: Number,
      required: true,
    },
    loanId: {
      type: Number,
      required: true,
    },
    displayId: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedResolutionType: null,
      resolutionTypes: {
        PAYMENT_PLAN: 'PAYMENT PLAN',
        SINGLE_PAYMENT: 'SINGLE PAYMENT',
      },
      showError: false,
      showConfirmation: false,
      activeComponentData: {},
      refreshKey: 0,
    };
  },

  computed: {
    isPaymentPlan() {
      return this.selectedResolutionType === this.resolutionTypes.PAYMENT_PLAN;
    },

    isSinglePayment() {
      return this.selectedResolutionType === this.resolutionTypes.SINGLE_PAYMENT;
    },
  },

  methods: {
    ...mapActions('settlement', ['disclosuresNotSent', 'paymentSchedulesNotSent']),
    clickPaymentPlan() {
      this.selectedResolutionType = this.resolutionTypes.PAYMENT_PLAN;
      this.disclosuresNotSent();
      this.paymentSchedulesNotSent();
      this.refreshKey += 1;
    },
    clickSinglePayment() {
      this.selectedResolutionType = this.resolutionTypes.SINGLE_PAYMENT;
      this.disclosuresNotSent();
      this.paymentSchedulesNotSent();
      this.refreshKey += 1;
    },
    onOfferConfirmed(data) {
      this.activeComponentData = data;
      this.showConfirmation = true;
    },
  },
};
</script>
