import Vue from 'vue';

const initialState = {
  sidebarOpen: false,
  appProcessing: false,
  appProcessingMessage: '',
  inMaintenance: process.env.VUE_APP_MAINTENANCE_MODE,
  name: process.env.VUE_APP_PL_NAME,
  brandName: process.env.VUE_APP_PL_BRAND_NAME,
  instanceId: process.env.VUE_APP_PL_INSTANCE_ID,
  env: process.env.NODE_ENV,
  settings: {},
};

const actions = {
  async getSystemSettings({ commit }) {
    try {
      const settings = await Vue.prototype.$axios.get('/api/v1/system-setting/admin');
      const variables = {};
      settings.data.forEach((element) => {
        variables[element.name] = element.value;
      });
      commit('SET_SETTINGS', variables);
    } catch (err) {
      commit('SET_SETTINGS', {});
    }
  },
  toggleSidebar({ commit }) {
    commit('TOGGLE_SIDEBAR');
  },
  toggleAppProcessing({ commit }, { appProcessingState, message }) {
    commit('SET_APP_PROCESSING', appProcessingState);
    commit('SET_APP_PROCESSING_MESSAGE', message);
  },
  setAppProcessingMessage({ commit }, message) {
    commit('SET_APP_PROCESSING_MESSAGE', message);
  },
  setInMaintenance({ commit }, value) {
    commit('SET_IN_MAINTENANCE', value);
  },
};

const mutations = {
  SET_SETTINGS(state, data) {
    state.settings = data;
  },
  TOGGLE_SIDEBAR(state) {
    state.sidebarOpen = !state.sidebarOpen;
  },
  SET_APP_PROCESSING(state, appProcessingState) {
    state.appProcessing = appProcessingState === 'on';
  },
  SET_APP_PROCESSING_MESSAGE(state, message) {
    state.appProcessingMessage = message;
  },
  SET_IN_MAINTENANCE(state, value) {
    state.inMaintenance = value;
  },
};

const getters = {
  assetPath(state) {
    return `assets/${state.instanceId}`;
  },
  lendingTreeEnabled() {
    return process.env.VUE_APP_PL_LENDINGTREE_ENABLED === 'true';
  },
  ofacEnabled() {
    return process.env.VUE_APP_PL_OFAC_ENABLED === 'true';
  },
  fincenEnabled() {
    return process.env.VUE_APP_PL_FINCEN_ENABLED === 'true';
  },
  blockedSsnsEnabled() {
    return process.env.VUE_APP_PL_BLOCKED_SSN_ENABLED === 'true';
  },
  version() {
    return process.env.VUE_APP_VERSION;
  },
  name() {
    return process.env.VUE_APP_PL_NAME;
  },
  brandName() {
    return process.env.VUE_APP_PL_INSTANCE_ID;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
