<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    @click:outside="dialog = !dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        Resolve Ticket
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Resolve Ticket</span>
      </v-card-title>

      <v-card-text>
        <p>Are you sure you want to mark this ticket as solved?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :loading="loadingModel"
          @click="resolveTicket()"
        >
          Resolve Ticket
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          @click="dialog = !dialog"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TicketLogModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingModel: false,
      dialog: false,
    };
  },
  methods: {
    resolveTicket() {
      this.loadingModel = true;
      return this.$axios
        .post('api/v1/zendesk/resolve-ticket', {
          ticketId: this.$route.params.id,
        })
        .then(() => {
          this.loadingModel = false;
          this.$router.push({
            name: 'tickets',
          });
        })
        .catch(() => {
          this.loadingModel = false;
        });
    },
  },
};
</script>
