<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-model="classificationName"
            label="Classification Name"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-textarea
            v-model="classificationDescription"
            name="input-7-1"
            label="Classification Description"
            value=""
            :hint="classificationDescriptionHint"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="d-flex flex-row justify-end"
          cols="12"
          sm="12"
          md="12"
        >
          <v-btn
            v-if="editingClassification"
            :loading="loading"
            color="warning"
            depressed
            :disabled="!ableToAdd"
            @click="editClassification()"
          >
            Edit
          </v-btn>

          <v-btn
            v-if="!editingClassification"
            :loading="loading"
            color="primary"
            depressed
            :disabled="!ableToAdd"
            @click="addNewClassification()"
          >
            Create
          </v-btn>

          <v-btn
            class="ml-2"
            depressed
            @click="showClassificationList()"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {

  props: {
    activeClassification: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    editingClassification: false,
    classificationName: '',
    classificationDescription: '',
    addingClassification: false,
    loading: false,
  }),

  computed: {
    ableToAdd() {
      return !!(this.classificationName.length && this.classificationDescription.length);
    },

    classificationDescriptionHint() {
      return `${256 - this.classificationDescription.length} characters remaining`;
    },
  },

  watch: {
    activeClassification(value) {
      if (value) {
        this.initValues();
      }
    },
  },

  mounted() {
    if (this.activeClassification) {
      this.initValues(this.activeClassification);
    }
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    clearForm() {
      this.editingClassification = false;
      this.classificationName = '';
      this.classificationDescription = '';
      this.addingClassification = false;
      this.loading = false;
    },

    initValues(status) {
      this.editingClassification = true;
      this.classificationName = status.name;
      this.classificationDescription = status.description;
    },

    showClassificationList() {
      this.clearForm();
      this.$emit('classification-list');
    },

    editClassification() {
      this.loading = true;

      return this.$axios
        .patch(`api/v1/ops-tickets/classification/${this.activeClassification.id}`, {
          name: this.classificationName,
          description: this.classificationDescription,
        })
        .then(() => {
          this.loading = false;
          this.showClassificationList();
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },

    addNewClassification() {
      this.loading = true;

      return this.$axios
        .post('api/v1/ops-tickets/classification', {
          name: this.classificationName,
          description: this.classificationDescription,
        })
        .then(() => {
          this.loading = false;
          this.showClassificationList();
        })
        .catch((error) => {
          this.loading = false;
          let errMsg = 'There was an error processing your request';
          if (
            error.response
            && (error.response.status === 404 || error.response.status === 422)
            && error.response.data
          ) {
            errMsg = error.response.data.message;
          }
          this.setNotification({
            message: errMsg,
            color: 'red',
          });
        });
    },
  },
};
</script>

<style scoped>

</style>
