<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Bad Employer List</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <BadEmployerDeleteModal
            v-model="dialogDelete"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogDelete')"
            @item-deleted="itemDeleted()"
            @click:outside="clearState()"
          />

          <BadEmployerFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <v-btn
            v-if="hasPermission('create lending-strategies::bad-employers')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Employer
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Employer"
            permission="create lending-strategies::bad-employers"
            :x-small="false"
            button-class="mb-2"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('edit lending-strategies::bad-employers')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="edit lending-strategies::bad-employers"
        :x-small="false"
        button-class="mr-10"
      />

      <v-icon
        v-if="hasPermission('delete lending-strategies::bad-employers')"
        class="red--text"
        @click="deleteItem(item)"
      >
        mdi-delete-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-delete-outline"
        :text-button="true"
        permission="delete lending-strategies::bad-employers"
        :x-small="false"
        button-class="mr-10"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import BadEmployerDeleteModal from './BadEmployerDeleteModal.vue';
import BadEmployerFormModal from './BadEmployerFormModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    BadEmployerFormModal,
    BadEmployerDeleteModal,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Employer', value: 'employer_name' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],

    dialogDelete: false,
    dialogForm: false,
    editedItem: {
      id: null,
      employer_name: '',
    },
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemDeleted() {
      this.items = [];
      this.updateTable();
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/bad-employer', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
