<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Blocked State List</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <blocked-state-form-modal
            v-model="dialogForm"
            :edited-item="editedItem"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.blocked="{ item }">
      {{ item.blocked ? 'Yes' : 'No' }}
    </template>

    <template v-slot:item.cp_blocked="{ item }">
      {{ item.cp_blocked ? 'Yes' : 'No' }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('edit lending-strategies::bad-employers')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil-outline"
        :text-button="true"
        permission="edit lending-strategies::bad-employers"
        :x-small="false"
        button-class="mr-10"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import BlockedStateFormModal from './BlockedStateFormModal.vue';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  components: {
    BlockedStateFormModal,
    DisabledForLackOfRoleBtn,
  },

  mixins: [
    dataTableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'State', value: 'state_abbr' },
      { text: 'Blocked in Waterfall', value: 'blocked', sortable: false },
      { text: 'Blocked in Customer Portal', value: 'cp_blocked', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],

    dialogDelete: false,
    dialogForm: false,
    editedItem: {
      id: null,
      employer_name: '',
    },
  }),

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemDeleted() {
      this.items = [];
      this.updateTable();
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/blocked-state', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
