<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Lending Strategies</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <LendingStrategyFormModal
            v-model="dialogForm"
            :edited-item="editedItem"
            :scoring-models="scoringModels"
            :product-assignment-models="productAssignmentModels"
            :lead-columns="leadColumns"
            :operators="operators"
            :input-defs="inputDefs"
            @close-modal="closeModal('dialogForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <LendingStrategiesChangeStatusModal
            v-model="statusForm"
            :edited-item="editedItem"
            @close-modal="closeModal('statusForm')"
            @item-saved="itemSaved()"
            @click:outside="clearState()"
          />

          <LendingStrategiesRouteOrderModal
            v-model="orderingForm"
            :active-lending-strategies="activeStrategies"
            @close-modal="closeModal('orderingForm')"
          />

          <ScoringModelSequenceModal
            v-model="dialogScoringModalSequence"
            :edited-item="scoringModel"
            @setCustomVars="onSetCustomVars"
            @close-modal="closeModal('dialogScoringModalSequence')"
            @click:outside="clearState()"
          />

          <v-btn
            v-if="hasPermission('edit lending-strategies::list')"
            dark
            class="mb-2 mr-2 btn-bg-regal-gray"
            @click="orderingForm = true"
          >
            Routes
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="Routes"
            permission="edit lending-strategies::list"
            :x-small="false"
            button-class="mb-2 mr-2"
          />

          <v-btn
            v-if="hasPermission('create lending-strategies::list')"
            dark
            class="mb-2 btn-bg-deep-blue"
            @click="dialogForm = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Lending Strategy
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="New Lending Strategy"
            permission="create lending-strategies::list"
            :x-small="false"
            button-class="mb-2"
          />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('edit lending-strategies::list')"
        :id="item"
        class="mr-10"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-pencil"
        :text-button="true"
        permission="edit lending-strategies::list"
        :x-small="false"
        button-class="mr-10"
      />

      <v-btn
        v-if="hasPermission('edit lending-strategies::list')"
        small
        :class="`${item.active ? 'btn-bg-teal' : 'btn-bg-alert-red'}`"
        text-color="white"
        @click="toggleStrategyStatus(item)"
      >
        {{ item.active ? 'Active' : 'Inactive' }}
      </v-btn>
      <disabled-for-lack-of-role-btn
        v-else
        :text="`${item.active ? 'Active' : 'Inactive'}`"
        permission="edit lending-strategies::list"
        :button="false"
        :chip="true"
        :chip-color="`${item.active ? 'green' : 'red'}`"
      />
    </template>

    <template v-slot:item.scoring_model.name="{ item }">
      <v-btn
        text
        small
        @click="openScoringModelModal(item.scoring_model)"
      >
        {{ item.scoring_model.name }}
      </v-btn>
    </template>

    <template v-slot:item.prod_asgmt_model_name="{ item }">
      <v-btn
        text
        small
        :to="{ 'name': 'product-assignment', 'params': { 'modelId': item.prod_asgmt_id}}"
      >
        {{ item.prod_asgmt_model_name }}
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

import LendingStrategyFormModal from '@/views/LendingStrategies/LendingStrategyFormModal.vue';
import LendingStrategiesChangeStatusModal from '@/views/LendingStrategies/LendingStrategiesChangeStatusModal.vue';
import LendingStrategiesRouteOrderModal from '@/views/LendingStrategies/LendingStrategiesRouteOrderModal.vue';
import ScoringModelSequenceModal from '@/views/LendingStrategies/ScoringModel/ScoringModelSequenceModal.vue';
import DisabledForLackOfRoleBtn from '../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'LendingStrategiesIndex',
  components: {
    LendingStrategyFormModal,
    LendingStrategiesChangeStatusModal,
    LendingStrategiesRouteOrderModal,
    ScoringModelSequenceModal,
    DisabledForLackOfRoleBtn,
  },
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Scoring Model', value: 'scoring_model.name' },
      { text: 'Product Assignment', value: 'prod_asgmt_model_name' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    dialogForm: false,
    editedItem: {
      id: null,
    },
    scoringModels: [],
    scoringModel: {},
    productAssignmentModels: [],
    leadColumns: [],
    operators: [],
    inputDefs: {},
    statusForm: false,
    orderingForm: false,
    dialogScoringModalSequence: false,
  }),

  computed: {
    activeStrategies() {
      // Get list of active strategies sorted by route_order
      return this.items.filter((item) => item.active).sort((a, b) => a.route_order - b.route_order);
    },
  },

  methods: {
    closeModal(modal) {
      this[modal] = false;
      this.clearState();
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogForm = true;
    },
    itemSaved() {
      this.items = [];
      this.updateTable();
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/lending-strategy', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;

          if (this.scoringModels.length === 0) {
            this.getScoringModels();
          }

          if (this.productAssignmentModels.length === 0) {
            this.getProductAssignmentModels();
          }

          this.leadColumns = Object.values(res.data.available_lead_columns);

          this.operators = Object.entries(res.data.operators).map((operator) => ({
            value: operator[0],
            text: operator[1],
          }));

          this.inputDefs = { ...res.data.input_defs };
        });
    },
    clearState() {
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;

        this.scoringModel = {};
      });
    },
    getScoringModels() {
      this.$axios
        .get('api/v1/scoring-model', {
          params: {
            perPage: 100,
          },
        })
        .then((response) => {
          this.scoringModels = response.data.data.map((model) => ({
            value: model.id,
            text: model.name,
          }));
        });
    },
    getProductAssignmentModels() {
      this.$axios
        .get('api/v1/product-assignment-model', {
          params: {
            perPage: 100,
          },
        })
        .then((response) => {
          this.productAssignmentModels = response.data.data.map((model) => ({
            value: model.id,
            text: model.name,
          }));
        });
    },
    toggleStrategyStatus(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.statusForm = true;
    },
    onSetCustomVars(event) {
      this.scoringModel.custom_vars = event;
    },
    openScoringModelModal(model) {
      this.scoringModel = { ...model };

      this.dialogScoringModalSequence = true;
    },
  },
};
</script>
