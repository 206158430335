<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>
        Lead Journey Api Calls
      </v-card-title>
      <v-container class="d-flex justify-space-between flex-wrap">
        <apexchart
          v-for="(item, index) in data"
          :key="index"
          class="mb-6"
          width="500"
          type="area"
          :options="item.options"
          :series="item.series"
        />
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
