<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    persistent
    scrollable
  >
    <v-card
      :loading="formProcessing"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col
                v-if="isNewItem"
                cols="12"
                md="4"
              >
                <v-select
                  v-model="userId"
                  required
                  label="User"
                  :items="availableUsers"
                  :error-messages="userIdErrors"
                  @input="$v.userId.$touch()"
                  @blur="$v.userId.$touch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  required
                  label="Email"
                  type="email"
                  :error-messages="emailErrors"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="active"
                  required
                  label="Status"
                  :items="activeOptions"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="close()"
        >
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required, email,
} from 'vuelidate/lib/validators';

import { mapActions } from 'vuex';

export default {
  name: 'OfacEmailNotificationFormModal',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {
          id: null,
          user_id: null,
          email: '',
          active: 0,
        };
      },
    },
    availableUsers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      formProcessing: false,
      email: null,
      active: 0,
      activeOptions: [
        { text: 'Inactive', value: 0 },
        { text: 'Active', value: 1 },
      ],
      userId: null,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return this.isNewItem ? 'Add New Email' : `Edit ${this.editedItem.user_name}'s Email`;
    },
    isNewItem() {
      return !this.editedItem.id;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.required) {
        errors.push('Email is required.');
      }
      if (!this.$v.email.email) {
        errors.push('Please provide a valid email format.');
      }
      return errors;
    },
    userIdErrors() {
      const errors = [];

      if (!this.$v.userId.$dirty) {
        return errors;
      }
      if (!this.$v.userId.required) {
        errors.push('User is required.');
      }
      return errors;
    },
  },

  watch: {
    editedItem() {
      this.id = this.editedItem.id;
      this.userId = this.editedItem.user_id;
      this.email = this.editedItem.email;
      this.active = this.editedItem.active;
    },
  },

  methods: {
    close() {
      this.$v.$reset();

      this.$emit('close-modal');
    },
    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formProcessing = true;

        let request = '';

        const data = {
          userId: this.userId,
          email: this.email,
          active: this.active || 0,
        };

        if (this.isNewItem) {
          request = this.$axios.post('/api/v1/ofac-email', data);
        } else {
          request = this.$axios.put(`/api/v1/ofac-email/${this.editedItem.id}`, data);
        }

        request
          .then(() => {
            this.$emit('item-saved');

            this.setNotification({
              message: 'OFAC Email has been saved.',
              color: 'green',
            });
          })
          .catch((error) => {
            this.setNotification({
              message: 'There was an error processing your request.',
              color: 'red',
            });

            console.error(error);
          })
          .then(() => {
            this.formProcessing = false;

            this.close();
          });
      }
    },
    ...mapActions('notification', ['setNotification']),
  },
  validations: {
    email: {
      required,
      email,
    },
    userId: {
      required,
    },
  },
};
</script>

<style>

</style>
