<template>
  <v-data-table
    dense
    :headers="headers"
    :loading="loading"
    :items="formattedFiles"
    :options.sync="options"
    :server-items-length="totalItems"
    class="elevation-1"
    :footer-props.sync="footerProps"
    @update:options="updateTable()"
  >
    <template v-slot:top>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Prescreen Files</v-toolbar-title>

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="searchTable()"
          />

          <v-spacer />

          <state-paddings />
        </v-toolbar>
      </v-card-title>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="hasPermission('view prescreen::file')"
        :id="item"
        class="mr-10"
        @click="redirectFileDetailsPage(item)"
      >
        mdi-format-list-bulleted-square
      </v-icon>
      <disabled-for-lack-of-role-btn
        v-else
        text=""
        icon="mdi-format-list-bulleted-square"
        :text-button="true"
        permission="view prescreen::file"
        :x-small="false"
        button-class="mr-10"
      />
    </template>
  </v-data-table>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';
import { formatDateTime } from '@/services/HelperService';
import DisabledForLackOfRoleBtn from '../../../DisabledForLackOfRoleBtn.vue';
import StatePaddings from './StatePaddings.vue';

export default {
  components: {
    StatePaddings,
    DisabledForLackOfRoleBtn,
  },
  mixins: [
    dataTableMixin,
  ],
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'type' },
      { text: 'Import Status', value: 'status' },
      { text: 'Sub Status', value: 'sub_status' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Last Updated At', value: 'updated_at' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'start',
        width: 183,
      },
    ],
  }),

  computed: {
    formattedFiles() {
      return (this.items || []).map((item) => ({
        ...item,
        created_at: formatDateTime(item.created_at),
        updated_at: formatDateTime(item.updated_at),
        type: item.type.toUpperCase(),
        status: item.status.toUpperCase(),
        sub_status: item.sub_status ? item.sub_status.toUpperCase() : null,
      }));
    },
  },
  methods: {
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/prescreen/files', {
          params: {
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
    redirectFileDetailsPage(item) {
      this.$router.push({ name: 'prescreen-file', params: { id: item.id } });
    },
  },
};
</script>
