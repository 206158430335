<template>
  <div class="prospect-info-container">
    <div class="prospect-info__title">
      {{ name }}
    </div>
    <div
      :class="customClass"
      class="prospect-info__content"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProspectInfo',

  props: {
    name: {
      required: true,
      type: String,
    },

    value: {
      required: true,
      type: String || Number,
    },

    customClass: {
      required: false,
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.prospect-info-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 12px;
  border-bottom: solid 1px #2b3243;
}

.prospect-info__title {
  font-weight: bold;
  color: #2b3243;
}

.ft-warning {
  color: #7C5907;
  font-weight: bold;
}

.ft-success {
  color: #1E7C55;
  font-weight: bold;
}

.ft-danger {
  color: #7C172A;
  font-weight: bold;
}
</style>
