<!--
  This component should be utilize when possible for communication macros.
  If your macro does not fit into this component, then just create a separate component.
-->
<template>
  <div
    :style="hide ? 'display: none' : ''"
    class="text-center"
  >
    <v-dialog
      v-model="dialog"
      class="fraud-claim"
      width="800px"
      scrollable
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasPermission('view ticket::communication-macros')"
          v-bind="attrs"
          color="secondary"
          x-large
          block
          class="fraud-claim-dialog-btn"
          v-on="on"
        >
          {{ title }}
        </v-btn>
        <disabled-for-lack-of-role-btn
          v-else
          :text="title"
          permission="view ticket::communication-macros"
          :x-small="false"
          :x-large="true"
          :block="true"
          button-class="fraud-claim-dialog-btn"
        />
      </template>

      <v-card :loading="loadingModel">
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>

        <v-card-text
          class="pa-5"
        >
          <vue-editor
            id="macroEditor"
            v-model="macro"
          />
          <br>
          <v-textarea
            v-model="notes"
            label="Notes (Optional)"
            outlined
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="hasPermission('create ticket::communication-macros')"
            color="primary"
            :loading="loadingModel"
            :disabled="loadingModel"
            @click="processForm"
          >
            Send Email
          </v-btn>
          <disabled-for-lack-of-role-btn
            v-else
            text="Send Email"
            permission="create ticket::communication-macros"
            :x-small="false"
            button-class="mb-2"
          />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
#macroEditor {
  height: 400px;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import { VueEditor } from 'vue2-editor';
import DisabledForLackOfRoleBtn from '../../DisabledForLackOfRoleBtn.vue';

export default {
  name: 'ReusableCommunicationByEmailTemplate',
  components: {
    DisabledForLackOfRoleBtn,
    VueEditor,
  },
  props: {
    ticketId: {
      type: Number,
      required: true,
      default: null,
    },
    customer: {
      type: Object,
      required: true,
      default: null,
    },
    action: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'this_needs_a_tag',
    },
    extra: {
      type: Object,
      default() {
        return {};
      },
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      macro: '',
      notes: '',
      loadingModel: false,
    };
  },
  computed: {
    ...mapState('loanProLoan', ['activeLoan']),
  },
  watch: {
    // only pull in the macro template if it is accessed in order to reduce unnecessary API calls
    async dialog(val) {
      if (val) {
        this.macro = await this.getMacroTemplate();
        this.macro = this.macro.replace(/<br><br>/g, '<p></p>');
      }
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    async getMacroTemplate() {
      const res = await this.$axios
        .post('api/v1/support/communication/template', {
          customer: this.customer,
          action: this.action,
          ticketId: this.ticketId,
          extra: {
            ...this.extra,
            leadGuid: this.activeLoan?.lead_guid || '',
          },
        });
      return res.data.template;
    },
    async processForm() {
      try {
        this.loadingModel = true;
        const res = await this.$axios
          .post('api/v1/support/communication/send-message', {
            ticketId: this.ticketId,
            macro: this.macro.replace(/<p><br><\/p>/g, ''), // remove excessive empty lines from the html string
            tag: this.tag,
            notes: this.notes,
            loanId: this.activeLoan.id,
            formatHTML: this.containsHTML(this.macro),
          });
        this.loadingModel = false;
        if (res.status === 200 && res.data) {
          this.dialog = false;
          this.setNotification({
            message: res.data.message,
            color: 'green',
          });
          this.$emit('success', `The ${this.title} communication macro was sent successfully.`);
        }
      } catch (error) {
        let errMsg = error.response?.data?.message || 'There was an error processing your request';
        if (error.response && error.response.status === 404 && error.response.data) {
          errMsg = error.response.data.message;
        }
        this.loadingModel = false;
        this.setNotification({
          message: errMsg,
          color: 'red',
        });
      }
    },
    containsHTML(str) {
      return /<[a-z][\s\S]*>/i.test(str);
    },
  },
};
</script>
