<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    scrollable
    @click:outside="dialog = !dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="hasPermission('view loan-pro-customers::profile')"
        class="mr-2 btn-bg-deep-blue"
        v-bind="attrs"
        v-on="on"
      >
        TFA Code
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ customerName }}'s 2FA Code
        </span>
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td>
                Two Factor Code:
              </td>
              <td>
                {{ twoFactor.code }}
              </td>
            </tr>
            <tr>
              <td>
                Verified:
              </td>
              <td>
                {{ twoFactor.verified }}
              </td>
            </tr>
            <tr>
              <td>
                Attempts:
              </td>
              <td>
                {{ twoFactor.verif_attempt }}
              </td>
            </tr>
            <tr>
              <td>
                Expired at:
              </td>
              <td>
                {{ createDate(twoFactor.expire_at) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="dialog = !dialog"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTime } from 'luxon';

export default {
  name: 'LoanProCustomersTwoFAuth',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    twoFactor: {
      type: Object,
      default() {
        return {
          id: null,
        };
      },
    },
    customerName: {
      type: String,
      default: 'Unknown',
    },
  },
  data() {
    return {
      id: null,
      dialog: false,
    };
  },

  watch: {
    editedItem() {
      this.id = this.editedItem.id;
    },
  },

  methods: {
    ...mapActions('notification', ['setNotification']),

    createDate(dateString) {
      return DateTime.fromSQL(dateString, { zone: 'America/Chicago' }).toLocaleString(DateTime.DATETIME_MED);
    },
  },
};
</script>
