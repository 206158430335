<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="6">
          <v-card :loading="customerLoading">
            <v-card-title>
              Profile
            </v-card-title>

            <v-card-text>
              <p>Email: {{ customer.email }}</p>
              <p>Status: {{ customer.status }}</p>

              <LoanProCustomerUpdatePasswordModal
                :edited-item="customer"
              />
              <LoanProCustomersPaymentProfilesModal
                :edited-item="customer"
              />

              <LoanProCustomersTwoFAuth
                v-if="twoFactorEnabled"
                :customer-name="`${customer.first_name} ${customer.last_name}`"
                :two-factor="customer.twoFEntry"
              />
              <v-btn
                v-if="hasPermission('view pii')"
                @click="showConfirmButtons"
              >
                Decrypt
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-if="confirmButtons"
        dense
      >
        <v-col cols="6">
          <v-card>
            <template v-if="confirmButtons">
              <v-card-text>
                <p class="mb-1">
                  Please enter the reason for viewing this data:
                </p>
                <v-text-field
                  v-model="reason"
                  required
                  type="text"
                  label="Reason"
                  style="max-width: 80%"
                />
                <v-btn
                  small
                  color="secondary"
                  class="mr-2"
                  :disabled="decryptionProcessing"
                  @click="hideConfirmButtons"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  color="primary"
                  :disabled="decryptionProcessing"
                  @click="getPii"
                >
                  Confirm
                </v-btn>
              </v-card-text>
            </template>
            <template v-if="showPii">
              <v-card-title>
                Decrypted PII
              </v-card-title>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>SSN</th>
                    <td>{{ pii.ssn }}</td>
                  </tr>
                  <tr>
                    <th>Driver's License Number</th>
                    <td>{{ pii.driverLicense }}</td>
                  </tr>
                  <tr>
                    <th>Date of Birth</th>
                    <td>{{ pii.birthDate }}</td>
                  </tr>
                  <tr>
                    <th colspan="2">
                      Accounts
                    </th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Bank / Brand</th>
                            <th>Acct Number / Card Number</th>
                            <th>Routing Number / Expiration</th>
                          </tr>
                          <tr
                            v-for="accts in pii.PaymentAccounts.results"
                            :key="accts.id"
                          >
                            <td>{{ accts.id }}</td>
                            <td>{{ accts.type.substr((accts.type.lastIndexOf('.') + 1)) }}</td>
                            <template
                              v-if="accts.type === 'paymentAccount.type.checking'
                                || accts.type === 'paymentAccount.type.savings'"
                            >
                              <td>{{ accts.CheckingAccount.bankName }}</td>
                              <td>{{ accts.CheckingAccount.accountNumber }}</td>
                              <td>{{ accts.CheckingAccount.routingNumber }}</td>
                            </template>
                            <template v-else>
                              <td>{{ accts.CreditCard.cardData.brand }}</td>
                              <td>{{ accts.CreditCard.cardNumber }}</td>
                              <td>{{ accts.CreditCard.cardExpiration }}</td>
                            </template>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import LoanProCustomersPaymentProfilesModal from './LoanProCustomersPaymentProfilesModal.vue';
import LoanProCustomerUpdatePasswordModal from './LoanProCustomerUpdatePasswordModal.vue';
import LoanProCustomersTwoFAuth from './LoanProCustomersTwoFAuth.vue';

export default {
  components: {
    LoanProCustomersTwoFAuth,
    LoanProCustomersPaymentProfilesModal,
    LoanProCustomerUpdatePasswordModal,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    customerLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reason: '',
      showPii: false,
      confirmButtons: false,
      decryptionProcessing: false,
      pii: {},
    };
  },

  computed: {
    ...mapState('settings', ['settings']),

    twoFactorEnabled() {
      const ableToCheck = this.settings?.brand?.check_customer_two_factor || false;

      return ableToCheck && this.customer.twoFEntry;
    },
  },

  methods: {
    async getPii() {
      this.decryptionProcessing = true;

      const pii = await this.$axios
        .get(`api/v1/loanpro-customer/${this.$route.params.customer_id}/pii`, {
          params: {
            reason: this.reason,
          },
        });
      this.pii = pii.data;
      this.pii.birthDate = DateTime
        .fromSeconds(parseInt(this.pii.birthDate.replace(/\D/g, ''), 10))
        .toFormat('MM/dd/yyyy');
      this.showPii = true;
    },
    showConfirmButtons() {
      this.confirmButtons = true;
    },
    hideConfirmButtons() {
      this.confirmButtons = false;
      this.showPii = false;
      this.decryptionProcessing = false;
    },
  },
};
</script>
