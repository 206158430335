<template>
  <v-dialog
    v-model="show"
    scrollable
    @click:outside="close()"
  >
    <v-card>
      <v-card-title>
        FullStory - Lead GUI : {{ lead.lead_guid }}
      </v-card-title>
      <v-card-text>
        <v-alert
          v-if="sessionsError"
          color="red darken-4"
          class="mt-5"
          dark
        >
          {{ sessions.error }}
        </v-alert>
        <v-simple-table v-else>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Session ID</th>
              <th>Created Time</th>
              <th>FS Url</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(session, index) in sessions.sessions"
              :key="`session_${index}`"
            >
              <td>{{ session.userId || 'N/F' }}</td>
              <td>{{ session.sessionId || 'N/F' }}</td>
              <td>{{ createDate(session.createdTime) || 'N/F' }}</td>
              <td>
                <a
                  :href="session.fsUrl"
                  target="__blank"
                >{{ session.fsUrl || 'N/F' }}</a>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable radix */
import { DateTime } from 'luxon';

export default {
  name: 'LoanProCustomerFullStoryModal',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sessions: {
      type: Object,
      default() {
        return {};
      },
    },
    lead: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    sessionsError() {
      return this.sessions && this.sessions.error;
    },
  },

  methods: {
    createDate(date) {
      return DateTime.fromSeconds(Number(date), { zone: 'America/Chicago' }).toLocaleString(DateTime.DATETIME_MED);
    },

    close() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped>

</style>
