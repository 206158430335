<template>
  <card-layout :style="[ user.mfa ? {'border': '0px'} : {'border': '2px solid red'}]">
    <template v-slot:title>
      Two Factor Authentication <span class="red--text">{{ user.mfa ? '' : 'REQUIRED' }}</span>
    </template>
    <template v-slot:description>
      Add additional security to your account using Two Factor Authentication.
    </template>

    <template v-slot:form>
      <p
        v-if="user.mfa"
        class="subtitle-1"
      >
        You have enabled two factor authentication.
      </p>
      <p
        v-if="!user.mfa"
        class="subtitle-1 red--text"
      >
        You have DISABLED two factor authentication.
      </p>
      <p class="body-2">
        When two factor authentication is enabled, you will be prompted for a secure,
        random token during authentication. You may retrieve this token from your phone's
        Google Authenticator Application. Google Authenticator can be downloaded from the
        Apple App Store or Android Marketplace.
      </p>
      <p
        v-if="user.mfa"
        class="body-2"
      >
        Alternatively, you can also log into your account with any of the recovery
        codes listed below.
      </p>
      <v-row>
        <v-col>
          <div v-html="qrCode" />
        </v-col>
        <v-col>
          <div
            v-for="(code, index) in recoveryCode"
            :key="index"
          >
            {{ code }}
          </div>
        </v-col>
      </v-row>
    </template>

    <template v-slot:button>
      <v-btn
        v-if="!user.mfa"
        absolute
        color="blue"
        right
        dark
        @click="enableAuth"
      >
        Enable
      </v-btn>
    </template>
  </card-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CardLayout from './CardLayout.vue';

export default {
  components: {
    CardLayout,
  },
  data: () => ({
    valid: false,
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => v.length <= 20 || 'Name must be less than 20 characters',
      (v) => v.length >= 2 || 'Name must be more than 1 character',
    ],
    userCopy: {
      first_name: '',
      last_name: '',
    },
    qrCode: '',
    recoveryCode: '',
  }),
  computed: {
    ...mapState('currentUser', ['user']),
  },
  beforeMount() {
    if (this.user.mfa) {
      this.getQrCode();
      this.getRecoveryCode();
    }
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    ...mapActions('currentUser', ['getUser']),
    async enableAuth() {
      const enable = await this.$axios.post('/api/v1/user/two-factor-authentication');
      if (enable.status === 200) {
        this.getUser();
        this.getQrCode();
        this.getRecoveryCode();
        this.setNotification({
          message: 'Two Factor Auth Enabled',
          color: 'green',
        });
      }
    },
    async getQrCode() {
      const qr = await this.$axios.get('/api/v1/user/two-factor-qr-code');
      this.qrCode = qr.data.svg;
    },
    async getRecoveryCode() {
      const rCode = await this.$axios.get('/api/v1/user/two-factor-recovery-codes');
      this.recoveryCode = rCode.data;
    },
  },
};
</script>
