import Vue from 'vue';

const initialState = {
  ticket: {},
  actionButtonsMenu: {
    routerViewCol: 12,
    sidebar: 0,
    sidebarDisplay: 'd-none',
  },
  allowAccess: false,
};

const getters = {
  actionButtonsMenu: (state) => state.actionButtonsMenu,
};

const actions = {
  resetTicket({ commit }) {
    commit('RESET_TICKET');
    commit('SET_ALLOW_ACCESS', false);
  },
  async verifyTicketOwnerStatus({ commit, dispatch }, id) {
    try {
      const allowAccessRes = await Vue.prototype.$axios.post('api/v1/tickets/verify-owner-status', {
        ticketId: id,
      });
      if (allowAccessRes.data.data && allowAccessRes.data.data.verified === true) {
        commit('SET_ALLOW_ACCESS', true);
      } else {
        commit('SET_ALLOW_ACCESS', false);
      }
    } catch (error) {
      // server currently returns 404 when no customer can be found so we should deny access
      // Policy returns a 403
      if (error.response.status === 404 || error.response.status === 403) {
        commit('SET_ALLOW_ACCESS', false);
      }
      const errMsg = 'There was an error when sending an API request to verify ticket ownership. Please try again. If there error persists, please notify your supervisor.';
      dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
    }
  },
  async getTicket({ commit, dispatch, state }, id) {
    if (state.allowAccess) {
      try {
        const res = await Vue.prototype.$axios
          .post('api/v1/support/ticket', {
            ticketId: id,
          });

        commit('SET_TICKET', res.data.data);
      } catch (error) {
        let errMsg = 'There was an error when sending an API request to collect Ticket data. Please try again. If there error persists, please notify your supervisor.';
        if (
          error.response
          && (error.response.status === 404 || error.response.status === 403)
          && error.response.data
        ) {
          errMsg = `${errMsg} Error Details: ${error.response.data.message}`;
        }
        dispatch('notification/setNotification', { message: errMsg, color: 'red' }, { root: true });
      }
    }
  },
  async setLoanId({ commit }, id) {
    commit('SET_LOAN_ID', id);
  },
  async setLoanType({ commit }, loanType) {
    commit('SET_LOAN_TYPE', loanType);
  },
  actionButtonsMenuToSideBar({ commit }, settings) {
    commit('SET_ACTION_BUTTONS_MENU', settings);
  },
  updateCustomerTicket({ commit }, customer) {
    commit('UPDATE_CUSTOMER_TICKET', customer);
  },
  updateCustomerTicketPhone({ commit }, newPhone) {
    commit('UPDATE_CUSTOMER_TICKET_PHONE', newPhone);
  },
  updateCustomerTicketEmail({ commit }, newEmail) {
    commit('UPDATE_CUSTOMER_TICKET_EMAIL', newEmail);
  },
};

const mutations = {
  SET_TICKET(state, ticket) {
    state.ticket = ticket;
  },
  SET_LOAN_ID(state, id) {
    state.ticket.active_loan.id = id;
  },
  SET_LOAN_TYPE(state, loanType) {
    state.ticket.active_loan.loanType = loanType;
  },
  SET_ACTION_BUTTONS_MENU(state, data) {
    state.actionButtonsMenu = data;
  },
  SET_ALLOW_ACCESS(state, data) {
    state.allowAccess = data;
  },
  RESET_TICKET(state) {
    state.ticket = {};
  },
  UPDATE_CUSTOMER_TICKET(state, data) {
    state.ticket.lpCustomer = data;
  },
  UPDATE_CUSTOMER_TICKET_PHONE(state, data) {
    state.ticket.lpCustomer.homephone = data;
  },
  UPDATE_CUSTOMER_TICKET_EMAIL(state, data) {
    state.ticket.lpCustomer.email = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
